import React, { useEffect } from 'react'
import { contentApi, extractedErrorObject } from '../../../api/swagger'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { FetchLearningCenterContentProgramTypeEnum } from '../../../swagger'
import {
  LearningCenterCategories,
  useLearningCenterContext,
} from '../../Context/LearningCenterContext'
import { useAuth } from '../../Routes/AuthProvider'
import { LoadingContext } from '../../Context/LoadingContext'
import { useTranslation } from 'react-i18next'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../../Context/SnackbarContext'
import {
  FilterFileType,
  LearningCenterTabs,
} from '../../../utils/searchAndFilterEnums'

export const useLearningCenterContent = (options: {
  /** If not provided, don't call the endpoint. */
  programType?: FetchLearningCenterContentProgramTypeEnum
  /** If not greater than 0, don't call the endpoint */
  selectedAcademicYear: number
}): {
  loadingId: string
} => {
  const { programType, selectedAcademicYear } = options
  const { t } = useTranslation()
  const { featureAbility } = useAuth()
  const { setSnackbarMessage, setSnackbarState, setSnackbarSeverity } =
    useSnackbarContext()
  const { addLoadingIds } = React.useContext(LoadingContext)
  const {
    searchQuery,
    updateAssets,
    contentLoadingId: loadingId,
    academicYear,
    contentType: filetype,
    level,
    selectedTabKey,
    category,
    includeOnlyFavorites,
    welcomeCenterContent,
  } = useLearningCenterContext()

  const isChallengeTab = selectedTabKey.includes(LearningCenterTabs.Challenge)
  const fetchLearningCenterContent = async () => {
    if (
      (level === undefined && !welcomeCenterContent && isChallengeTab) ||
      !academicYear ||
      !selectedAcademicYear
    ) {
      return
    }
    try {
      const { assets } = await contentApi.fetchLearningCenterContent({
        /**
         * If we're here, this is defined. We prevent calling the endpoint if
         * undefined, unless the level is defined and we're on the Challenge tab.
         */
        programType: (isChallengeTab
          ? level
          : programType) as FetchLearningCenterContentProgramTypeEnum,
        academicYear: academicYear ?? selectedAcademicYear,
        searchQuery: !!searchQuery ? searchQuery : undefined,
        filetype: filetype?.includes(FilterFileType.AllTypes)
          ? undefined
          : filetype,
        category: category?.includes(LearningCenterCategories.AllCategories)
          ? undefined
          : category,
        onlyIncludeFavorites: includeOnlyFavorites,
        welcomeCenterContent: welcomeCenterContent,
        /**
         * IF we want to add sorting, we have pagination capabilities and can use
         * orderBy, but we'd need something from the options to tell us by which
         * columns we can sort.
         */
      })
      updateAssets(assets)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'Endpoints.FetchLearningCenterProgramTypes.Error',
          'An unknown error occurred fetching learning center content.'
        ),
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    loadingId,
    asyncFunction: fetchLearningCenterContent,
  })

  useEffect(() => {
    /** Do call the endpoint
     * - if we do have a programType and we aren't on the Challenge tab,
     * - or we do have a challenge level and are on the Challenge tab,
     * - or we don't have a programType and are fetching welcomeCenterContent
     * - if we can access the feature,
     * - and we have a selectedAcademicYear
     * */
    if (
      ((!!programType && !isChallengeTab) ||
        (!!level && isChallengeTab) ||
        welcomeCenterContent) &&
      featureAbility.can('learningCenter', 'Feature') &&
      selectedAcademicYear > 0
    ) {
      addLoadingIds([loadingId])
    }
  }, [
    addLoadingIds,
    featureAbility,
    isChallengeTab,
    level,
    loadingId,
    programType,
    selectedAcademicYear,
    welcomeCenterContent,
  ])

  return {
    loadingId,
  }
}
