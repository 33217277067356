import React, { useEffect, useState } from 'react'
import { Family } from '../../swagger/models'
import FamiliesTable from '../Families/FamiliesTable'
import { useTranslation } from 'react-i18next'
import { fetchFamilies } from '../../api/Families'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { extractedErrorObject } from '../../api/swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useCommunitiesContext } from '../Context/CommunitiesContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { LoadingContext } from '../Context/LoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import LoadingProgress from '../Elements/LoadingProgress'

export const CommunityFamiliesTab: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const [communityFamilies, setCommunityFamilies] = useState<Family[]>([])
  const familiesSectionTitle = t(
    'Communities.CommunityDetails.Families.Header',
    'Families'
  )
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const { academicYear, selectedCommunityKey } = useCommunitiesContext()

  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const availableLoadingIds = useLoadingIds()

  const fetchFamiliesByCommunityKey = async () => {
    try {
      const fetchedFamilies = await fetchFamilies({
        communityKey: selectedCommunityKey,
        year: academicYear ? academicYear : undefined,
      })

      setCommunityFamilies(fetchedFamilies.families)
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message:
          (error as unknown as Error).message ??
          t(
            'CommunityFamiliesTab.FetchFamilies.Error',
            'Error occurred while retrieving families for community.'
          ),
      }
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: fetchFamiliesByCommunityKey,
    loadingId: availableLoadingIds.CommunityFamiliesTab.fetchAllFamilies,
  })

  useEffect(() => {
    if (academicYear) {
      addLoadingIds([availableLoadingIds.CommunityFamiliesTab.fetchAllFamilies])
    }
  }, [
    academicYear,
    addLoadingIds,
    availableLoadingIds.CommunityFamiliesTab.fetchAllFamilies,
  ])

  if (
    loadingIds.has(availableLoadingIds.CommunityFamiliesTab.fetchAllFamilies)
  ) {
    return <LoadingProgress />
  }

  return (
    <FamiliesTable title={familiesSectionTitle} families={communityFamilies} />
  )
}
export default CommunityFamiliesTab
