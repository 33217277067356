import React from 'react'
import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Hidden from '@mui/material/Hidden'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { UserCommunity, UserProfile as UserInfo } from '../../swagger'
import fullName from '../../utils/fullName'
import Box from '@mui/material/Box'
import RolesInfo from '../Account/Profile/RolesInfo'
import ContactInfo from '../Account/Profile/ContactInfo'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { Can } from '@casl/react'
import { useAuth } from '../Routes/AuthProvider'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

export enum ProfileCardVariant {
  Family,
  Account,
}

interface ProfileCardProps {
  variant: ProfileCardVariant
  userInfo: UserInfo
  userRolesForCommunities: UserCommunity[]
  avatarUrl: string
  rightContent?: React.ReactNode
}

const ProfileCard: React.FunctionComponent<ProfileCardProps> = ({
  variant,
  userInfo,
  userRolesForCommunities,
  avatarUrl,
  rightContent,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isFamily = variant === ProfileCardVariant.Family
  const name = fullName(userInfo, t('util.loading', 'Loading…'))
  const { permissionAbility } = useAuth()
  const isShowOnDesktop = useShowOnDesktop()

  const ProfilePicture = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Avatar
        sx={{
          width: '240px',
          height: '240px',
          border: `5px solid ${theme.palette.primary.light}`,
          [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(5),
          },
          [theme.breakpoints.down('xs')]: {
            marginBottom: 0,
          },
        }}
        alt={t('ProfileCard.Avatar.AltText', 'My Profile Image')}
        src={avatarUrl}
      >
        <Typography variant="h2" fontSize={'6rem'}>
          {`${userInfo?.firstName?.[0] ?? ''}${userInfo?.lastName?.[0] ?? ''}`}
        </Typography>
      </Avatar>
    </Box>
  )

  /** Render this if Family Profile variant... */
  if (isFamily) {
    return (
      <BaseProfileCard
        stackOn="sm"
        headerTitle={
          <Typography
            sx={{
              marginBottom: theme.spacing(3),
            }}
            variant="h4"
            component="h2"
          >
            {name}
          </Typography>
        }
        headerButton={
          <Can I={'accessUsersTab'} on="Admin" ability={permissionAbility}>
            <Box mt={isShowOnDesktop ? 0 : 1}>
              <ContainedButton
                id="userAccount"
                variant={ContainedButtonVariant.UserAccount}
                to={`/admin/user-accounts/${userInfo?.id}`}
                fullWidth={!isShowOnDesktop}
              />
            </Box>
          </Can>
        }
        leftView={ProfilePicture}
        rightView={
          <React.Fragment>
            <ContactInfo
              phone={userInfo?.phone}
              personalEmail={userInfo?.username}
              officialEmail={userInfo?.email}
              address={userInfo?.address}
            />
            <RolesInfo userRolesForCommunities={userRolesForCommunities} />
          </React.Fragment>
        }
      />
    )
  }

  /** ...by default render the Account variant */
  return (
    <BaseProfileCard
      stackOn="md"
      leftView={ProfilePicture}
      rightView={
        <React.Fragment>
          {rightContent}
          <RolesInfo userRolesForCommunities={userRolesForCommunities} />
        </React.Fragment>
      }
    />
  )
}

const BaseProfileCard: React.FC<{
  stackOn: 'sm' | 'md'
  leftView: React.ReactNode
  rightView: React.ReactNode
  headerTitle?: React.ReactNode
  headerButton?: React.ReactNode
}> = ({
  stackOn,
  leftView,
  rightView,
  headerTitle = null,
  headerButton = null,
}) => {
  const theme = useTheme()

  return (
    <Card
      sx={{
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(4),
      }}
    >
      {(!!headerTitle || !!headerButton) && (
        <Hidden smDown>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            {headerTitle}
            {headerButton}
          </Box>
          <Divider />
        </Hidden>
      )}
      <Box
        p={6}
        display="flex"
        flexDirection={{ xs: 'column', [stackOn]: 'row' }}
      >
        <Box
          sx={{
            [theme.breakpoints.up('sm')]: {
              paddingRight: 4,
            },
          }}
          textAlign="center"
        >
          <React.Fragment>
            <Hidden smUp>{headerTitle}</Hidden>
            {leftView}
            <Hidden smUp>{headerButton}</Hidden>
          </React.Fragment>
        </Box>
        <Box flex="1">{rightView}</Box>
      </Box>
    </Card>
  )
}

export default ProfileCard
