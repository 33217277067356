import React from 'react'
import { useLicensingPaymentBreakdownCalculations } from '../../../../hooks/useLicensingPaymentBreakdownCalculations'
import {
  BreakdownLedgerForPDF,
  BreakDownLedgerPDFVariants,
} from './BreakdownLedgerForPDF'
import { useTranslation } from 'react-i18next'

type SemesterOneSubtotalBreakdownProps = Pick<
  ReturnType<typeof useLicensingPaymentBreakdownCalculations>,
  'semester1AdditionalStudent' | 'semester1FirstStudent' | 'semester1Subtotal'
> & {
  isChallenge: boolean
}

export const SemesterOneSubtotalBreakdown: React.FC<
  SemesterOneSubtotalBreakdownProps
> = ({
  isChallenge,
  semester1AdditionalStudent,
  semester1FirstStudent,
  semester1Subtotal,
}) => {
  const { t } = useTranslation()

  const firstSemesterLicensingTuitionLabel = t(
    'Licensing.Table.Breakdown.Header.FirstSemesterLicensingTuition',
    '1st Semester Licensing Fee - Tuition'
  )

  if (isChallenge) {
    return (
      <BreakdownLedgerForPDF
        variant={BreakDownLedgerPDFVariants.Normal}
        headerLabel={firstSemesterLicensingTuitionLabel}
        firstStudent={semester1FirstStudent}
        additionalStudent={semester1AdditionalStudent}
        subtotal={semester1Subtotal}
      />
    )
  }

  return <></>
}
