import React from 'react'
import { LedgerRow } from '../../Table/LedgerTable'
import { TableCell, TableRow, Typography, useTheme } from '@mui/material'
import { BreakDownLedgerVariants } from './BreakdownLedger'

export const Breakdown: React.FC<{
  breakdownRows: LedgerRow[]
  headerLabel?: string
  variant: BreakDownLedgerVariants
}> = ({ breakdownRows, headerLabel, variant }) => {
  const theme = useTheme()

  const normalStyles = {
    label: {},
    subtotal: {
      color: theme.palette.secondary.main,
      fontSize: theme.spacing(2.6),
      paddingX: 1,
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(3),
      },
    },
    medium: {
      color: theme.palette.primary.dark,
    },
  }

  const balanceStyles = {
    label: { padding: theme.spacing(0, 2) },
    subtotal: {
      color: theme.palette.secondary.main,
      fontSize: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        fontSize: theme.spacing(4),
      },
    },
    medium: {
      padding: theme.spacing(0, 2),
      marginLeft: 0,
      borderBottom: 0,
    },
  }

  const styles =
    variant === BreakDownLedgerVariants.Normal ? normalStyles : balanceStyles

  return (
    <>
      {!!headerLabel && (
        <TableRow>
          <TableCell
            style={{
              borderBottom: 0,
              minWidth: '150px',
            }}
          >
            <Typography
              variant="subtitle2"
              component="h5"
              style={{ fontWeight: 'bold' }}
            >
              {headerLabel}
            </Typography>
          </TableCell>
        </TableRow>
      )}
      {breakdownRows.map(({ cells }, index) => (
        <TableRow key={index}>
          {cells.map(({ content, align, color = 'medium', colSpan }, index) => {
            const labelStyle = color === 'main' ? styles.label : styles.medium

            const subtotalStyle = color === 'green' ? styles.subtotal : {}

            return (
              <TableCell
                sx={{
                  ...labelStyle,
                }}
                key={index}
                align={align}
                colSpan={colSpan}
              >
                {
                  <Typography
                    sx={{
                      ...subtotalStyle,
                    }}
                    variant="subtitle2"
                    component="h5"
                  >
                    {content}
                  </Typography>
                }
              </TableCell>
            )
          })}
        </TableRow>
      ))}
    </>
  )
}
