import React, { memo } from 'react'
import { DropDownHandleSelectionEvent } from '../../utils/types'
import DynamicFormField, {
  DynamicFormFieldProps,
} from '../Elements/DynamicFormField'

type YearDropDownProps = Partial<DynamicFormFieldProps> &
  Pick<DynamicFormFieldProps, 'id' | 'disabled'> & {
    /**
     * Handles the selection on the parent component based on the id of the drop
     * down. Different drop downs perform different actions.
     */
    handleSelection: DropDownHandleSelectionEvent

    /** optional value to customize if the element is displayed as dropdown. If false renders a textbox */
    isDropdown?: boolean

    /** optional value to customize the offset */
    offset?: number
  }

export const defaultYearOffset = 24

export const yearOptions = (offset: number = defaultYearOffset): number[] =>
  Array.from({ length: offset }, (_, i) =>
    Math.abs(i - new Date().getFullYear())
  ).sort()

export const YearDropDown: React.FC<YearDropDownProps> = memo(
  ({
    disabled,
    handleSelection,
    id,
    label,
    value,
    offset = defaultYearOffset,
    isDropdown = true,
    required = false,
    isValid = {
      input: true,
      minLength: true,
      maxLength: true,
      email: true,
    },
  }) => {
    const options = yearOptions(offset).map((year) => {
      return {
        name: year.toString(),
        id: year.toString(),
      }
    })

    const defaultOption = 'Select Option'
    options.unshift({ name: defaultOption, id: '' })

    const valueExists = options.find((option) => option.name === value)
    const selectedValue = !!valueExists ? value : options[0].name

    const updateForParent: DynamicFormFieldProps['updateForParent'] = (
      name,
      state,
      required,
      isOverMaxLength,
      isUnderMinLength,
      option
    ): void => {
      handleSelection({ name: state, id: String(option), dropDownId: name })
    }

    return (
      <DynamicFormField
        id={id}
        name={id}
        label={label ?? ''}
        updateForParent={updateForParent}
        value={isDropdown ? selectedValue ?? '' : value ?? ''}
        dropdownOptions={options}
        isValid={isValid}
        required={required}
        inputType={isDropdown ? 'dropdown' : 'text'}
        fullWidth
        disabled={disabled}
      />
    )
  }
)

export default YearDropDown
