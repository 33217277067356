import React from 'react'
import { Page } from '../Elements/PageMargins'
import TitleContext from '../../TitleContext'
import { useTranslation } from 'react-i18next'
import { SnackbarSeverity } from '../../components/Alerts/SnackbarAlert'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { useNavigate, useParams } from 'react-router'
import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import Paper from '@mui/material/Paper'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import Header from '../Elements/Header'
import { contentApi, extractedErrorObject } from '../../api/swagger'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useTheme } from '@mui/material'

export const ContentNDA: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const title = t('Business.NonDisclosureAgreement', 'Non-Disclosure Agreement')
  const navigate = useNavigate()
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)
  const { assetKey } = useParams<{ assetKey: string }>()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const theme = useTheme()

  const NDALink =
    'https://classicalconversations.widen.net/s/mtq7fmkdlj/cc-connected-nondisclosure-agreement-nda'

  const navigateToBusiness = () => {
    navigate(
      {
        pathname: '/business',
      },
      {
        /** Navigation Options */
      }
    )
  }

  const handleAccept = async () => {
    try {
      await contentApi.ndaAcknowledgementPath({
        body: {
          assetKey: +`${assetKey}`,
          ndaAcknowledged: true,
        },
      })
      setSnackbarState(true)
      setSnackbarMessage(
        t(
          'Business.NonDisclosureAgreement.Agreed',
          'You have successfully agreed to the NDA.'
        )
      )
      setSnackbarSeverity(SnackbarSeverity.Success)

      navigate(
        {
          pathname: `/business/business-content/${assetKey}`,
        },
        {
          replace: true,
        }
      )
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'Business.NonDisclosureAgreement.Error',
            'Something went wrong accepting NDA.'
          ),
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  const handleCancel = () => {
    navigate(
      {
        pathname: '/business',
      },
      {
        /** Navigation Options */
      }
    )
  }

  return (
    <Page>
      <DynamicBreadcrumbs
        breadcrumbs={[
          {
            label: t('Business.Content.BreadCrumb', 'Business'),
            onClick: navigateToBusiness,
          },
          {
            label: t(
              'Business.Content.BreadCrumb.NonDisclosureAgreement',
              'Non-Disclosure Agreement'
            ),
          },
        ]}
      />
      <Paper
        sx={{
          padding: theme.spacing(0, 4, 4),
        }}
      >
        <Box
          paddingBottom={{ xs: 3, sm: 3, md: 0 }}
          display="flex"
          justifyContent="space-between"
          flexDirection={useShowOnDesktop() ? 'row' : 'column'}
          alignItems="center"
        >
          <Header
            id="learningPathHeader"
            headerName={t(
              'Business.Content.NonDisclosureAgreement.Instructions',
              'Please read and accept this non-disclosure agreement to continue'
            )}
          />
          <Box
            display="flex"
            width={useShowOnDesktop() ? 'auto' : '100%'}
            justifyContent={!useShowOnDesktop() ? 'space-around' : 'flex-start'}
          >
            <TextButton
              id="cancel"
              variant={TextButtonVariant.Cancel}
              onClick={handleCancel}
            />
            <ContainedButton
              id={'primary'}
              variant={ContainedButtonVariant.Accept}
              onClick={handleAccept}
            />
          </Box>
        </Box>
        <Box display="flex" flex="1" height={!useShowOnDesktop ? 250 : 600}>
          <CardMedia
            component="iframe"
            src={NDALink}
            allowFullScreen={true}
            title={'Non-Disclosure Agreement'}
          />
        </Box>
      </Paper>
    </Page>
  )
}

export default ContentNDA
