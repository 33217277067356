export const ACTING_AS_PARENT = 'parent'

// This is for use with debounced search fields. The user must type in at least this many characters before
// the debounced action will run.
export const MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH = 3

/**
 * This is the default value for the region select field.
 * It is used in the following components:
 * - src/components/Modals/RoleModal.tsx
 * */
export const DEFAULT_SELECTED_REGION = 'United States of America'

// Represents the delay in milliseconds before the  debounced function is actually executed.
export const DEBOUNCE_LIMIT = 500

/**
 * Default page size for pagination on tables.
 */
export const DEFAULT_PAGE_SIZE =
  Number(process.env.REACT_APP_PAGINATION_DEFAULT_PAGE_SIZE) ?? 25
