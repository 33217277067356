import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useTheme } from '@mui/material'

const LoadingProgress: React.FC = () => {
  const showOnDesktop = useShowOnDesktop()
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      sx={{
        marginTop: theme.spacing(15),
      }}
    >
      <CircularProgress size={showOnDesktop ? '25%' : '50%'} />
      <Box pt={4}>
        <Typography variant={showOnDesktop ? 'h4' : 'subtitle1'} component="p">
          {t('LoadingProgress.Message.Loading', 'Loading')}
        </Typography>
      </Box>
    </Box>
  )
}

export default LoadingProgress
