import React, { CSSProperties } from 'react'
import TextField from '@mui/material/TextField'
import FieldProps from '../Interfaces/FieldProps'

interface PasswordFieldProps extends FieldProps {
  css?: CSSProperties
  value: string
  type: string
  id: string
  label: string
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
}

const PasswordField: React.FunctionComponent<PasswordFieldProps> = (props) => {
  return (
    <TextField
      sx={{
        ...props.css,
      }}
      error={props.error}
      helperText={props.helperText}
      inputProps={{
        'data-testid': 'passwordText',
        ...props.inputProps,
      }}
      fullWidth
      id={props.id}
      type={props.type}
      label={props.label}
      margin="normal"
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyPress={props.onKeyPress}
      variant="filled"
      value={props.value}
      InputProps={{
        ...props.InputProps,
      }}
    />
  )
}

export default PasswordField
