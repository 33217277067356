import React, { PropsWithChildren, useCallback, useReducer } from 'react'

enum LoadingStateActions {
  Remove = 'remove',
  Add = 'add',
  EasterEgg = 'easterEgg',
}

/**
 * Dispatch method to process the LoadingStateActions provided.
 *
 * LoadingIds can be added or removed.
 *
 * @param state the current loadingIds Set
 * @param action `LoadingStateActions` to perform
 * @returns the updated loadingIds Set based on the action
 */
const loadingReducer = (
  state: Set<string>,
  action: { type: LoadingStateActions; payload: string[] }
): Set<string> => {
  switch (action.type) {
    case LoadingStateActions.Add:
      return new Set([...state.values(), ...action.payload])
    case LoadingStateActions.Remove:
      const newState = new Set([...state.values()])
      action.payload.forEach((it) => {
        newState.delete(it)
      })
      return newState
    default:
      return state
  }
}

const defaultValue = {
  /**
   * Ids will be based on two things:
   *    OperationIds Enum consisting of unique api calls that can occur
   *    Filename the call is made within
   *
   * example: fetchUsers is the ApiCall. BaseButton is the filename
   *
   *  fetchUsersBaseButton is the full Id passed to the context.
   *
   * In progress ids will append inProgress
   *
   *  fetchUsersBaseButtonInProgress
   */
  loadingIds: new Set<string>(),
  /** Adds a set of loadingIds to the existing loading ids */
  addLoadingIds: (state: string[]) => {
    console.warn(
      `The default value of LoadingContext.addState was called with state=${state}. Did you forget to install a LoadingProvider?`
    )
  },
  /** Removes a set of loadingIds to the existing loading ids */
  removeLoadingIds: (state: string[]) => {
    console.warn(
      `The default value of LoadingContext.removeState was called with state=${state}. Did you forget to install a LoadingProvider?`
    )
  },
}

export const LoadingContext = React.createContext(defaultValue)

export type TestLoadingConfig = Partial<typeof defaultValue>

/**
 * Provider of the Loading Context meant to utilize a common state whence we can
 * communicate the need to show buttons as processing a request. This is utilized
 * for all areas where loading is required.
 *
 * Proper use of the Provider:
 * - Use the custom hook useLoadingContext, providing an asynchronous method to
 * be awaited within the useEffect and a unique loadingId (see above) that the button
 * depends on to disable the button while processing.
 *
 * Provide an optional callback method to perform any actions that can wait until
 * after the async function has completed processing.
 *
 * @param LoadingProviderProps containing the initialLoading value
 * @returns Provider for LoadingContext
 */
export const LoadingProvider: React.FC<
  PropsWithChildren & { testConfig?: TestLoadingConfig }
> = ({ children, testConfig }) => {
  const [loadingIds, dispatch] = useReducer(loadingReducer, new Set<string>())

  const value = {
    loadingIds, // The initial value of this should always be an empty set.
    addLoadingIds: useCallback(
      (state: string[]) =>
        dispatch({
          type: LoadingStateActions.Add,
          payload: state,
        }),
      []
    ),
    removeLoadingIds: useCallback(
      (state: string[]) =>
        dispatch({
          type: LoadingStateActions.Remove,
          payload: state,
        }),
      []
    ),
  }
  return (
    <LoadingContext.Provider value={{ ...value, ...testConfig }}>
      {children}
    </LoadingContext.Provider>
  )
}
