import {
  Card,
  Paper,
  CardActionArea,
  Box,
  Icon,
  CardContent,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export interface NavigationCardProps {
  icons: {
    startIcon: string
    endIcon?: string
  }
  title: string
  subtitle: string
  navigateTo: string
}

export const NavigationCard: React.FC<NavigationCardProps> = ({
  icons,
  title,
  subtitle,
  navigateTo,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  const cardWidth = {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '60%',
    xl: '30%',
  }

  const handleNavigation = () => navigate({ pathname: navigateTo })

  return (
    <CardActionArea
      aria-label="navigation-card"
      sx={{
        width: {
          ...cardWidth,
        },
        marginBottom: theme.spacing(3),
        transition: 'width 0.3s, background-color 0.3s',
      }}
      onClick={handleNavigation}
    >
      <Card
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
        }}
        component={Paper}
      >
        <Box
          flex={1}
          width="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon
            sx={{
              fontSize: '7rem',
              color: theme.palette.borderColor.grey,
              margin: theme.spacing(1),
            }}
          >
            {icons.startIcon}
          </Icon>
        </Box>
        <CardContent
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant="h6" component="p">
              {t('AllBilling.Title.HeaderInfomation', '{{title}}', {
                title,
              })}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.dark,
              }}
              variant="subtitle1"
              component="p"
            >
              {t('AllBilling.Subtitle.BodyInfomation', '{{subtitle}}', {
                subtitle,
              })}
            </Typography>
          </Box>
        </CardContent>
        {icons?.endIcon && (
          <Box display="flex" alignItems="center">
            <Icon
              sx={{
                fontSize: '4rem',
                color: theme.palette.primary.main,
                margin: theme.spacing(1),
              }}
            >
              {icons?.endIcon}
            </Icon>
          </Box>
        )}
      </Card>
    </CardActionArea>
  )
}
