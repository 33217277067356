import {
  LicensingBill,
  LicensingFeePerRateBreakdown,
  LicensingFeePerRateBreakdownFeeEnum,
} from '../swagger'
import useBreakdownDataLicensingFee, {
  StudentInfo,
} from './useBreakdownDataLicensingFee'

export interface LicensingPaymentBreakdownCalculationsProps {
  licensingBill: LicensingBill
}

interface LicensingPaymentBreakdownCalculations {
  balance: number
  licensingFeeAdditionalStudent: StudentInfo
  licensingFeeFirstStudent: StudentInfo
  licensingFeeSubtotal: number
  licensingFeeTotalOfStudents: number
  owed: number
  paid: number
  semester1AdditionalStudent: StudentInfo
  semester1FirstStudent: StudentInfo
  semester1Subtotal: number
  semester1TotalOfStudents: number
  semester2AdditionalStudent: StudentInfo
  semester2FirstStudent: StudentInfo
  semester2Subtotal: number
  semester2TotalOfStudents: number
}

export const useLicensingPaymentBreakdownCalculations = ({
  licensingBill,
}: LicensingPaymentBreakdownCalculationsProps): LicensingPaymentBreakdownCalculations => {
  const licensingBillBreakdown = licensingBill.fees.map(
    (breakdown) => breakdown.breakdown
  )[0]

  const balance = licensingBill.total.amount
  const [{ owed, paid }] = licensingBill.fees.map((fee) => {
    return {
      paid: fee.paid.amount,
      owed: fee.owed.amount,
    }
  })

  const breakdownInitialValue: LicensingFeePerRateBreakdown = {
    count: 0,
    amount: {
      amount: 0,
      currencyCode: '',
    },
  }

  const breakdown = licensingBillBreakdown.reduce(
    (acc, curr) => {
      return {
        semester1FirstStudentsInfo:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.Sem1FirstStudent
            ? { ...acc.semester1FirstStudentsInfo, ...curr }
            : acc.semester1FirstStudentsInfo,
        semester1AdditionalStudentInfo:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.Sem1AdditionalStudent
            ? { ...acc.semester1AdditionalStudentInfo, ...curr }
            : acc.semester1AdditionalStudentInfo,
        semester2FirstStudentsInfo:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.Sem2FirstStudent
            ? { ...acc.semester2FirstStudentsInfo, ...curr }
            : acc.semester2FirstStudentsInfo,
        semester2AdditionalStudentInfo:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.Sem2AdditionalStudent
            ? { ...acc.semester2AdditionalStudentInfo, ...curr }
            : acc.semester2AdditionalStudentInfo,
        firstSpotInvitation:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.FirstSpotInvitation
            ? { ...acc.firstSpotInvitation, ...curr }
            : acc.firstSpotInvitation,
        additionalSpotsInvitation:
          curr.fee ===
          LicensingFeePerRateBreakdownFeeEnum.AdditionalSpotsInvitation
            ? { ...acc.additionalSpotsInvitation, ...curr }
            : acc.additionalSpotsInvitation,
      }
    },
    {
      semester1FirstStudentsInfo: breakdownInitialValue,
      semester1AdditionalStudentInfo: breakdownInitialValue,
      semester2FirstStudentsInfo: breakdownInitialValue,
      semester2AdditionalStudentInfo: breakdownInitialValue,
      firstSpotInvitation: breakdownInitialValue,
      additionalSpotsInvitation: breakdownInitialValue,
    }
  )

  const {
    semester1FirstStudentsInfo,
    semester1AdditionalStudentInfo,
    semester2FirstStudentsInfo,
    semester2AdditionalStudentInfo,
    firstSpotInvitation,
    additionalSpotsInvitation,
  } = breakdown

  const {
    firstStudent: licensingFeeFirstStudent,
    additionalStudent: licensingFeeAdditionalStudent,
    subTotal: licensingFeeSubtotal,
    totalOfStudents: licensingFeeTotalOfStudents,
  } = useBreakdownDataLicensingFee({
    semesterStudentInfo: firstSpotInvitation,
    semesterAdditionalStudentInfo: additionalSpotsInvitation,
  })

  const {
    firstStudent: semester1FirstStudent,
    additionalStudent: semester1AdditionalStudent,
    subTotal: semester1Subtotal,
    totalOfStudents: semester1TotalOfStudents,
  } = useBreakdownDataLicensingFee({
    semesterStudentInfo: semester1FirstStudentsInfo,
    semesterAdditionalStudentInfo: semester1AdditionalStudentInfo,
  })

  const {
    firstStudent: semester2FirstStudent,
    additionalStudent: semester2AdditionalStudent,
    subTotal: semester2Subtotal,
    totalOfStudents: semester2TotalOfStudents,
  } = useBreakdownDataLicensingFee({
    semesterStudentInfo: semester2FirstStudentsInfo,
    semesterAdditionalStudentInfo: semester2AdditionalStudentInfo,
  })

  return {
    balance,
    licensingFeeAdditionalStudent,
    licensingFeeFirstStudent,
    licensingFeeSubtotal,
    licensingFeeTotalOfStudents,
    owed,
    paid,
    semester1AdditionalStudent,
    semester1FirstStudent,
    semester1Subtotal,
    semester1TotalOfStudents,
    semester2AdditionalStudent,
    semester2FirstStudent,
    semester2Subtotal,
    semester2TotalOfStudents,
  }
}
