import React, { PropsWithChildren, useCallback, useState } from 'react'
import { SpeedDialActions } from '../Elements/SpeedDialMenu'
import { Step } from 'react-joyride'

/**
 * We allow for actions to be updated to render the SpeedDialMenu in AppScaffold.
 *
 * The menu should appear on any screen when authenticated and the actions are not empty.
 *
 * It is the responsibility of the component using the menu to add actions on mount and remove
 * them on unmount.
 */
export const defaultSpeedDialMenuContextValue = {
  actions: undefined as SpeedDialActions[] | undefined,
  updateActions: (newActions?: SpeedDialActions[]): void => {
    console.warn(
      `The SpeedDialMenuContext.updateActions was called with value ${newActions}. Did you forget to use a SpeedDialMenuProvider?`
    )
  },
  resetContextToDefaults: (): void => {
    console.warn(
      `The SpeedDialMenuContext.resetContextToDefaults was called. Did you forget to use a SpeedDialMenuProvider?`
    )
  },
  joyrideSteps: [] as Step[],
  updateJoyrideSteps: (newSteps: Step[]): void => {
    console.warn(
      `The SpeedDialMenuContext.updateJoyrideSteps was called with value ${newSteps}. Did you forget to use a SpeedDialMenuProvider?`
    )
  },
  joyrideCompleteId: '',
  updateJoyrideCompleteId: (id: string): void => {
    console.warn(
      `The SpeedDialMenuContext.updateJoyrideCompleteId was called with value ${id}. Did you forget to use a SpeedDialMenuProvider?`
    )
  },
  stepIndex: 0,
  updateStepIndex: (stepNum: number): void => {
    console.warn(
      `The SpeedDialMenuContext.updateStepIndex was called with value ${stepNum}. Did you forget to use a SpeedDialMenuProvider?`
    )
  },
}

export const SpeedDialMenuContext = React.createContext(
  defaultSpeedDialMenuContextValue
)

export type TestSpeedDialMenuConfig = typeof defaultSpeedDialMenuContextValue

export const useSpeedDialMenuContext =
  (): typeof defaultSpeedDialMenuContextValue =>
    React.useContext(SpeedDialMenuContext)

interface TestSpeedDialMenuContextConfig extends PropsWithChildren {
  testConfig?: Partial<TestSpeedDialMenuConfig>
}

const SpeedDialMenuProvider: React.FC<TestSpeedDialMenuContextConfig> = ({
  testConfig,
  children,
}) => {
  const [actions, setActions] = useState<SpeedDialActions[] | undefined>(
    defaultSpeedDialMenuContextValue.actions
  )

  const updateActions = useCallback((newActions?: SpeedDialActions[]) => {
    setActions(newActions)
  }, [])

  const [joyrideSteps, setJoyrideSteps] = useState(
    defaultSpeedDialMenuContextValue.joyrideSteps
  )

  const updateJoyrideSteps = useCallback((newSteps: Step[]) => {
    setJoyrideSteps(newSteps)
  }, [])

  const [joyrideCompleteId, setJoyrideCompleteId] = useState(
    defaultSpeedDialMenuContextValue.joyrideCompleteId
  )

  const updateJoyrideCompleteId = (id: string) => {
    setJoyrideCompleteId(id)
  }

  const [stepIndex, setStepIndex] = useState(
    defaultSpeedDialMenuContextValue.stepIndex
  )

  const updateStepIndex = useCallback((stepNum: number) => {
    setStepIndex(stepNum)
  }, [])

  const resetContextToDefaults = () => {
    setActions(defaultSpeedDialMenuContextValue.actions)
    setJoyrideSteps(defaultSpeedDialMenuContextValue.joyrideSteps)
    setJoyrideCompleteId(defaultSpeedDialMenuContextValue.joyrideCompleteId)
    setStepIndex(defaultSpeedDialMenuContextValue.stepIndex)
  }

  const value = {
    actions,
    updateActions,
    resetContextToDefaults,
    joyrideSteps,
    updateJoyrideSteps,
    joyrideCompleteId,
    updateJoyrideCompleteId,
    stepIndex,
    updateStepIndex,
    ...testConfig,
  }
  return (
    <SpeedDialMenuContext.Provider value={value}>
      {children}
    </SpeedDialMenuContext.Provider>
  )
}

export default SpeedDialMenuProvider
