import { LicensingFeePerRateBreakdown } from '../swagger'

export interface breakdownDataLicensingFeeProps {
  semesterStudentInfo: LicensingFeePerRateBreakdown
  semesterAdditionalStudentInfo: LicensingFeePerRateBreakdown
}

export type StudentInfo = {
  feeAmount: number
  numberOfStudents: number
  studentInfoSubTotal: number
  currencyCode: string
}

type Result = {
  firstStudent: StudentInfo
  additionalStudent: StudentInfo
  subTotal: number
  totalOfStudents: number
}

const useBreakdownDataLicensingFee = ({
  semesterStudentInfo,
  semesterAdditionalStudentInfo,
}: breakdownDataLicensingFeeProps): Result => {
  const semesterStudentInfoSubTotal: number =
    semesterStudentInfo.amount.amount * semesterStudentInfo.count

  const semesterAdditionalStudentInfoSubTotal: number =
    semesterAdditionalStudentInfo.amount.amount *
    semesterAdditionalStudentInfo.count

  const subTotal =
    semesterStudentInfoSubTotal + semesterAdditionalStudentInfoSubTotal

  const totalOfStudents =
    semesterStudentInfo.count + semesterAdditionalStudentInfo.count

  return {
    firstStudent: {
      feeAmount: semesterStudentInfo.amount.amount,
      numberOfStudents: semesterStudentInfo.count,
      studentInfoSubTotal: semesterStudentInfoSubTotal,
      currencyCode: semesterStudentInfo.amount.currencyCode,
    },
    additionalStudent: {
      feeAmount: semesterAdditionalStudentInfo.amount.amount,
      numberOfStudents: semesterAdditionalStudentInfo.count,
      studentInfoSubTotal: semesterAdditionalStudentInfoSubTotal,
      currencyCode: semesterAdditionalStudentInfo.amount.currencyCode,
    },
    subTotal,
    totalOfStudents,
  }
}

export default useBreakdownDataLicensingFee
