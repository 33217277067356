import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import DropDown, { DropDownVariant, MenuOption } from '../Menus/DropDown'
import { FormDivider } from '../Elements/FormDivider'
import { Grades } from '../Modals/PrepopulateClassInformationModal'

interface PrepopulateDropDownProps {
  handleSelection: (selection: string, id: string) => void
  options: PrepopulateDropDownOptions
  dropDownValue: PrepopulateDropDownValue
  grade: Grades
  hideDivider?: boolean
}

export interface PrepopulateDropDownValue {
  yearValue: string
  programValue: string
}

interface PrepopulateDropDownOptions {
  yearsOptions: MenuOption[]
  programOptions: MenuOption[]
}

enum FormIds {
  YearDropDownNinth = 'academicYear1',
  ProgramDropDownNinth = 'transcriptProgram1',
  YearDropDownTenth = 'academicYear2',
  ProgramDropDownTenth = 'transcriptProgram2',
  YearDropDownEleventh = 'academicYear3',
  ProgramDropDownElevnth = 'transcriptProgram3',
  YearDropDownTwelfth = 'academicYear4',
  ProgramDropDownTwelfth = 'transcriptProgram4',
}

const PrepopulateDropDown: React.FC<PrepopulateDropDownProps> = ({
  handleSelection,
  options,
  dropDownValue,
  grade,
  hideDivider,
}) => {
  const { t } = useTranslation()

  const getIdForYearDropDown = (grade: Grades): string => {
    switch (grade) {
      case Grades.Ninth:
        return FormIds.YearDropDownNinth
      case Grades.Tenth:
        return FormIds.YearDropDownTenth
      case Grades.Eleventh:
        return FormIds.YearDropDownEleventh
      case Grades.Twelfth:
        return FormIds.YearDropDownTwelfth
    }
  }

  const getIdForProgramDropDown = (grade: Grades): string => {
    switch (grade) {
      case Grades.Ninth:
        return FormIds.ProgramDropDownNinth
      case Grades.Tenth:
        return FormIds.ProgramDropDownTenth
      case Grades.Eleventh:
        return FormIds.ProgramDropDownElevnth
      case Grades.Twelfth:
        return FormIds.ProgramDropDownTwelfth
    }
  }

  const getLabelForDropDowns = (grade: Grades): string => {
    switch (grade) {
      case Grades.Ninth:
        return t('Transcripts.Prepopulate.Modal.NinthGradeHeader', '9TH GRADE')
      case Grades.Tenth:
        return t('Transcripts.Prepopulate.Modal.TenthGradeHeader', '10TH GRADE')
      case Grades.Eleventh:
        return t(
          'Transcripts.Prepopulate.Modal.EleventhGradeHeader',
          '11TH GRADE'
        )
      case Grades.Twelfth:
        return t(
          'Transcripts.Prepopulate.Modal.TwelfthGradeHeader',
          '12TH GRADE'
        )
    }
  }

  return (
    <>
      <Typography variant="subtitle2">{getLabelForDropDowns(grade)}</Typography>
      <Box display={'flex'} justifyContent={'center'} mt={1}>
        <DropDown
          id={getIdForYearDropDown(grade)}
          label={t(
            'Transcript.ClassInformationCard.DropDown.YearDropDown',
            'Year'
          )}
          handleSelection={handleSelection}
          menuOptions={options.yearsOptions}
          variant={DropDownVariant.FormField}
          value={dropDownValue.yearValue}
          formControlProps={{
            width: '48%',
          }}
          cssProps={{
            height: '70%',
            marginRight: 2,
          }}
          key={`${grade}-year`}
        />
        <DropDown
          id={getIdForProgramDropDown(grade)}
          label={t(
            'Transcript.ClassInformationCard.DropDown.ProgramDropDown',
            'Program'
          )}
          handleSelection={handleSelection}
          menuOptions={options.programOptions}
          variant={DropDownVariant.FormField}
          value={dropDownValue.programValue}
          formControlProps={{
            width: '48%',
          }}
          cssProps={{
            height: '70%',
          }}
          key={`${grade}-program`}
        />
      </Box>
      {!hideDivider && <FormDivider sx={{ margin: 1 }} />}
    </>
  )
}

export default PrepopulateDropDown
