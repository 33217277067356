import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Public from '@mui/icons-material/Public'
import Assessment from '@mui/icons-material/Assessment'
//This is for the program tab, it will possible come back in future
//import Assignment from '@mui/icons-material/Assignment'
import Book from '@mui/icons-material/Book'
import Event from '@mui/icons-material/Event'
import ExitToApp from '@mui/icons-material/ExitToApp'
import Home from '@mui/icons-material/Home'
import Message from '@mui/icons-material/Message'
import ShoppingBag from '@mui/icons-material/ShoppingBag'
import RecentActors from '@mui/icons-material/RecentActors'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import BusinessCenter from '@mui/icons-material/BusinessCenter'
import Flag from '@mui/icons-material/Flag'
import React, { CSSProperties, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import LogoMark from '../../Images/Branding/ClassicalConversations_C_White.png'
import { useAuth } from '../Routes/AuthProvider'
import ListItemLink from './ListItemLink'
import Hidden from '@mui/material/Hidden'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import ClearIcon from '@mui/icons-material/Clear'
import { UserContext, useUser } from '../../UserContext'
import { buildAvatarUrl, fetchUser } from '../../api/user'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { extractedErrorObject, shopifyApi } from '../../api/swagger'
import { useMountEffect } from '../../hooks/useMountEffect'
import ActorSelector from './ActorSelector'
import { useSnackbarContext } from '../Context/SnackbarContext'
import LinkFromZelda from '@mui/material/Link'
import { styled } from '@mui/system'
import { Box, ListItemButton } from '@mui/material'
import { Assignment } from '@mui/icons-material'
import { ACTING_AS_PARENT } from '../../utils/constants'
import ListPrivacyLinks from './ListPrivacyLinks'

const reportsUrl = process.env.REACT_APP_REPORTS_URL ?? ''

const drawerWidth = 240
const toolbarHeight = 64

const royalBlueInactive = 'rgba(5, 31, 66, 0.38)'

const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  // On desktop, we want to pretend the appbar is a single appbar, so we drop the right-border from the drawer itself and apply it only to the scrolling portion below the app bar.
  [theme.breakpoints.up('lg')]: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const ListWrapper = styled(List)({
  width: '94%',
  margin: 'auto',
})

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  borderRadius: 4,
  '&:hover, &:focus': {
    background: theme.palette.hover.sidebar,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
    background: theme.palette.hover.main,
  },
}))

export enum RoutesToOpenInNewWindow {
  Forums = 'Forums',
  Reports = 'Reports',
  TermsOfUse = 'TermsOfUse',
  Bookstore = 'Bookstore',
}

interface SidebarProps {
  open: boolean
  toggleOpen?: () => void
  shiftContent: boolean
}

const Sidebar: React.FunctionComponent<SidebarProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const path = useLocation().pathname
  const {
    permissionAbility,
    featureAbility,
    discourseUrl,
    userDetails,
    defaultAccountPath,
    signout,
    permissions,
  } = useAuth()
  const { actingAs } = userDetails

  const errorMessage = t(
    'UserAccount.Error.FetchUser',
    'An unknown error occurred retrieving user information.'
  )

  const getForumsUrl = () => {
    if (discourseUrl.length > 0) {
      return discourseUrl[0].discourseUrl
    }
    return ''
  }

  const getBookstoreUrl = async () => {
    try {
      const response = await shopifyApi.fetchShopifyMultipassUrl({})

      return response.redirectUrl
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Code',
        message:
          (e as unknown as Error).message ??
          'An error happened getting your Bookstore Sign on link!',
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }
  }

  const { setUser } = useContext(UserContext)
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  useMountEffect(() => {
    const fetch = async () => {
      try {
        const user = await fetchUser()
        setUser(user)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message: (e as unknown as Error).message ?? errorMessage,
        }
        setSnackbarState?.(true)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarSeverity?.(SnackbarSeverity.Error)
      }
    }
    fetch()
  })

  // This function decides which routes should be opened in a new tab and which should not.
  const OpenInSameOrNewTab = (name: string, to: string) => {
    Object.values(RoutesToOpenInNewWindow).includes(
      name as RoutesToOpenInNewWindow
    )
      ? window.open(to, '_blank')
      : window.location.replace(to)
  }

  const drawerItemsTop = [
    {
      name: t('sidebar.icon.communities', 'Communities'),
      icon: Home,
      to: '/communities',
    },
    ////This is for the program tab, it will possible come back in future
    // {
    //   name: t('sidebar.icon.programs', 'Programs'),
    //   icon: Assignment,
    //   to: '/programs',
    // },
    {
      name: t('sidebar.icon.events', 'Events'),
      icon: Event,
      to: '/events',
      requireSomeGrantOf: [{ i: 'events', on: 'Feature' }],
      requireEveryGrantOf: [],
    },
    {
      name: t('sidebar.icon.reports', 'Reports'),
      icon: Assessment,
      to: reportsUrl,
      // For now require at least the family feature default. Permissions can be added
      requireSomeGrantOf: [{ i: 'reports', on: 'Feature' }],
    },
    {
      name: t('sidebar.icon.business', 'Business'),
      icon: BusinessCenter,
      to: '/business',
      requireSomeGrantOf: [{ i: 'business', on: 'Feature' }],
    },
    {
      name: t('sidebar.icon.learningCenter', 'Learning Center'),
      icon: Book,
      to: '/learning',
      // For now require at least the forums feature default. Permissions can be added
      requireSomeGrantOf: [{ i: 'learningCenter', on: 'Feature' }],
    },
    {
      name: t('sidebar.icon.forums', 'Forums'),
      icon: Message,
      to: getForumsUrl(),
      isSelected: false,
      // For now require at least the forums feature default. Permissions can be added
      requireSomeGrantOf: [{ i: 'forums', on: 'Feature' }],
    },
    {
      name: t('sidebar.icon.team', 'Team'),
      icon: Flag,
      to: '/team',
      requiresPermissions: true,
    },
    {
      name: t('sidebar.icon.bookstore', 'Bookstore'),
      icon: ShoppingBag,
      getTo: getBookstoreUrl,
      isSelected: false,
      // This feature default does not exist yet
      requireSomeGrantOf: [{ i: 'bookstore', on: 'Feature' }],
    },
    {
      name: t('sidebar.icon.transcripts', 'Transcripts'),
      // FIGMA has 'Demography' icon, but mui does not, so this is close enough
      icon: Assignment,
      to: '/transcripts/overview',
      /** ALlowing optional feature flags */
      requireSomeGrantOf:
        actingAs !== ACTING_AS_PARENT
          ? [
              { i: 'print', on: 'Transcript' },
              { i: 'view', on: 'Transcript' },
              { i: 'create', on: 'Transcript' },
              { i: 'transcripts', on: 'Feature' },
            ]
          : [{ i: 'transcripts', on: 'Feature' }],
    },
  ]

  const drawerItemsBottom = [
    {
      name: t('sidebar.icon.account', 'Account'),
      icon: AccountBoxIcon,
      to: defaultAccountPath,
      isSelected: path === '/' || path === '/account',
    },
  ]

  const handleLogout = async () => {
    await signout()
    navigate({
      pathname: '/login',
    })
  }

  const { toggleOpen } = props
  const onClose = useCallback(() => toggleOpen?.(), [toggleOpen])

  const variant = props.shiftContent ? 'permanent' : 'temporary'

  const userContext = useUser()

  const firstName = userContext.user?.firstName
  const lastName = userContext.user?.lastName

  const commonListItemProps = {
    paddingLeft: theme.spacing(1),
    borderRadius: 4,
    '&:hover, &:focus': {
      background: theme.palette.hover.sidebar,
    },
    '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
      background: theme.palette.hover.main,
    },
  } as CSSProperties

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        height: toolbarHeight,
        flexShrink: 0,
        '&	.MuiDrawer-paper': {
          width: drawerWidth + 1,
          backgroundColor: theme.palette.customBackground.sidebar,
          color: theme.palette.primary.main,
          [theme.breakpoints.up('lg')]: {
            borderRight: 'none',
          },
        },
      }}
      variant={variant}
      anchor="left"
      open={props.open}
      onClose={onClose}
    >
      <AppBar position="sticky">
        <Toolbar disableGutters>
          <Hidden lgUp>
            <IconButton
              aria-label={t('Sidebar.Header.Close', 'Close')}
              sx={{
                color: theme.palette.textOrIcon.onPrimary,
                padding: theme.spacing(2.625),
                height: theme.spacing(1.75),
                width: theme.spacing(1.75),
              }}
              onClick={props.toggleOpen}
            >
              <ClearIcon />
            </IconButton>
          </Hidden>
          <LinkFromZelda
            sx={{
              padding: '0 !important',
              textTransform: 'none',
            }}
            onClick={() => {
              navigate({
                pathname: '/',
              })
            }}
          >
            <IconButton>
              <img
                style={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2),
                  height: '30px',
                }}
                alt={t(
                  'Sidebar.Logo.AltText',
                  'Classical Conversations Communities'
                )}
                src={LogoMark}
              />
            </IconButton>
          </LinkFromZelda>
        </Toolbar>
      </AppBar>
      <Nav>
        <Divider />

        <AvatarSection
          firstName={firstName}
          lastName={lastName}
          imageSource={buildAvatarUrl()}
        />

        <Divider />
        <ActorSelector />

        <ListWrapper>
          {drawerItemsTop.map(
            ({
              name,
              icon: Icon,
              to,
              getTo,
              isSelected,
              requireSomeGrantOf,
              requireEveryGrantOf,
              requiresPermissions,
            }) => {
              const selected = isSelected ?? path.startsWith(to ?? '')

              let canAccess = true
              /** If we have don't have all the required grants, boo. */
              if (
                !!requireEveryGrantOf &&
                !requireEveryGrantOf.every(
                  ({ i, on }) =>
                    permissionAbility.can(i, on) || featureAbility.can(i, on)
                )
              ) {
                canAccess = requireEveryGrantOf.length === 0
              }
              /** If we require some grant but we don't have one of those either, hoo */
              if (
                !!requireSomeGrantOf &&
                !requireSomeGrantOf.some(
                  ({ i, on }) =>
                    permissionAbility.can(i, on) || featureAbility.can(i, on)
                )
              ) {
                canAccess = false
              }
              /** If we require some permission, but don't have any, boo hoo */
              if (requiresPermissions && permissions.length === 0) {
                canAccess = false
              }

              return canAccess && (!!to || !!getTo) ? (
                <ListItemLink
                  icon={
                    <Icon
                      sx={{
                        ...(selected
                          ? {
                              fill: theme.palette.primary.main,
                            }
                          : {
                              fill: royalBlueInactive,
                            }),
                      }}
                    />
                  }
                  primary={name}
                  onClick={async () => {
                    let linkTo = to
                    if (!linkTo) {
                      if (!getTo) {
                        return
                      }

                      linkTo = await getTo()

                      if (!linkTo) {
                        return
                      }
                    }

                    const isNavigatingAwayFromNC3 = linkTo.startsWith('http')
                    if (!isNavigatingAwayFromNC3) {
                      navigate({
                        pathname: linkTo,
                      })
                    } else {
                      OpenInSameOrNewTab(name, linkTo)
                    }
                  }}
                  sx={{
                    ...commonListItemProps,
                  }}
                  key={name}
                  selected={selected}
                />
              ) : null
            }
          )}
        </ListWrapper>

        {((permissionAbility.can('accessRegionsSection', 'Admin') &&
          (permissionAbility.can('view', 'Region') ||
            permissionAbility.can('create', 'Region') ||
            permissionAbility.can('edit', 'Region') ||
            permissionAbility.can('delete', 'Region'))) ||
          permissionAbility.can('accessRolesTab', 'Admin') ||
          permissionAbility.can('accessUsersTab', 'Admin')) && (
          <>
            <Divider />
            <AdminSection />
          </>
        )}

        <div style={{ flex: 1 }} />
        <div>
          <Divider />
          <ListWrapper>
            {drawerItemsBottom.map(({ name, icon: Icon, to }) => {
              const selected = path.startsWith(to)
              return (
                <ListItemLink
                  icon={
                    <Icon
                      sx={{
                        ...(selected
                          ? {
                              fill: theme.palette.primary.main,
                            }
                          : {
                              fill: royalBlueInactive,
                            }),
                      }}
                    />
                  }
                  primary={name}
                  onClick={() => {
                    navigate({
                      pathname: to,
                    })
                  }}
                  sx={{
                    ...commonListItemProps,
                  }}
                  key={to}
                  selected={selected}
                />
              )
            })}
          </ListWrapper>

          <Divider />

          <ListWrapper>
            <StyledListItemButton
              data-testid="logout"
              // TODO: Update logout to include navigation away blockage
              onClick={handleLogout}
            >
              <ListItemIcon>
                <ExitToApp
                  sx={{
                    fill: royalBlueInactive,
                  }}
                />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography variant="subtitle2" component="p">
                    {t('sidebar.icon.logOut', 'Log Out')}
                  </Typography>
                }
              />
            </StyledListItemButton>
          </ListWrapper>

          <Divider />

          <ListPrivacyLinks />
        </div>
      </Nav>
    </Drawer>
  )
}

const isBlank = (it?: string) => (it?.trim() ?? '') === ''

const formatName = (
  firstName?: string | undefined,
  lastName?: string | undefined
) => {
  const name = [firstName ?? '', lastName ?? ''].filter((it) => !isBlank(it))
  return name.join(' ')
}

export const AvatarSection: React.FunctionComponent<{
  firstName?: string
  lastName?: string
  imageSource?: string
}> = ({ firstName, lastName, imageSource }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const formattedName = formatName(firstName, lastName)
  const { defaultAccountPath } = useAuth()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        height: '144px',
        position: 'relative',
        flexShrink: 0,
      }}
    >
      <Button
        data-testid="sidebar-avatar-button"
        disableRipple
        component={Link}
        to={defaultAccountPath}
        // TODO: Include on Click for avatar button
        sx={{
          width: drawerWidth,
          justifyContent: 'left',
          textTransform: 'none',
          '&:hover, &:focus': {
            background: theme.palette.hover.sidebar,
          },
        }}
      >
        <Avatar
          alt={t('Sidebar.Avatar.AltText', 'View Profile')}
          sx={{
            position: 'absolute',
            bottom: theme.spacing(8),
            left: theme.spacing(2),
            width: theme.spacing(5),
            height: theme.spacing(5),
          }}
          src={imageSource}
        >
          <Typography variant="h2" fontSize={'1rem'}>
            {`${firstName?.[0]}${lastName?.[0]}`}
          </Typography>
        </Avatar>
        <Typography
          variant="h6"
          component="p"
          sx={{
            position: 'absolute',
            bottom: theme.spacing(2),
            left: theme.spacing(2),
            textDecoration: 'none',
            color: theme.palette.primary.main,
          }}
        >
          {formattedName}
        </Typography>
      </Button>
    </Box>
  )
}

const AdminSection: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const path = useLocation().pathname
  const theme = useTheme()
  const { permissionAbility } = useAuth()
  const usersSelected = path.startsWith('/admin/users')
  const regionsSelected = path.startsWith('/admin/regions')
  const navigate = useNavigate()

  const commonStyles = {
    ...(usersSelected
      ? {
          fill: theme.palette.primary.main,
        }
      : {
          fill: royalBlueInactive,
        }),
  }

  return (
    <ListWrapper
      aria-labelledby="admin-section-header"
      subheader={
        <ListSubheader
          id="admin-section-header"
          sx={{
            color: theme.palette.primary.light,
            fontFamily: 'plantin, sans-serif',
            fontWeight: 400,
            backgroundColor: 'transparent',
          }}
        >
          {t('Sidebar.Admin.SectionTitle', 'Admin')}
        </ListSubheader>
      }
    >
      {(permissionAbility.can('accessUsersTab', 'Admin') ||
        permissionAbility.can('accessRolesTab', 'Admin')) && (
        <ListItemLink
          onClick={() => {
            navigate({
              pathname: '/admin/user-accounts',
            })
          }}
          sx={{ paddingLeft: theme.spacing(1) }}
          selected={usersSelected}
          icon={<RecentActors sx={{ ...commonStyles }} />}
          primary={t('Sidebar.Admin.UserAccounts', 'User Accounts')}
        />
      )}
      {permissionAbility.can('accessRegionsSection', 'Admin') &&
        (permissionAbility.can('view', 'Region') ||
          permissionAbility.can('create', 'Region') ||
          permissionAbility.can('edit', 'Region') ||
          permissionAbility.can('delete', 'Region')) && (
          <ListItemLink
            onClick={() => {
              navigate({
                pathname: '/admin/regions',
              })
            }}
            selected={regionsSelected}
            icon={
              <Public
                sx={{
                  ...commonStyles,
                }}
              />
            }
            sx={{ paddingLeft: theme.spacing(1) }}
            primary={t('Sidebar.Admin.Regions', 'Regions')}
          />
        )}
    </ListWrapper>
  )
}

export default Sidebar
