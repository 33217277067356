import i18next from 'i18next'

/**
 * Converts an array of values into a single string joined by
 * locale specific list separators (commas in US) for display purposes
 *
 * @param list array of values to join
 * @returns display string
 */
export const getCommaSeparatedList = (list: string[]): string =>
  new Intl.ListFormat(i18next.language, { style: 'narrow' }).format(list)
