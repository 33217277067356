import {
  Box,
  Card,
  Paper,
  TableContainer,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Header, { HeaderVariant } from '../Elements/Header'
import LedgerTable, { LedgerRow } from '../Table/LedgerTable'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { TextButtonVariant } from '../Buttons/TextButton'
import {
  InviteTabFlowStages,
  useAccountContext,
} from '../Context/AccountContext'
import CardFormHeader from './CardFormHeader'
import AdjacentLabels from '../Labels/AdjacentLabels'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useNavigate } from 'react-router'
import { enrollments, extractedErrorObject } from '../../api/swagger'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import ActionButtons from '../Buttons/ActionButtons'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { AccountTabs } from '../Account/Account'

const EnrollmentSummaryCard: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    selectedEnrollmentInvite,
    updateBreadcrumbs,
    acceptedPrograms,
    resetContextValuesForInvites,
    studentAssignments,
  } = useAccountContext()
  const navigate = useNavigate()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const availableLoadingIds = useLoadingIds()
  const primaryButtonLoadingId =
    availableLoadingIds.EnrollmentSummaryCard.completeEnrollmentInvite

  const discountText = t(
    'EnrollmentSummary.Card.DiscountsText',
    'Discounts apply to multiple children in one program. Families are billed for a maximum of 3 programs.'
  )

  const enrollmentSummaryTableRows: LedgerRow[] = useMemo<LedgerRow[]>(() => {
    return (
      selectedEnrollmentInvite?.programs
        .map((program) => {
          let offeredSpot = 1
          const rows: LedgerRow[] = []
          while (offeredSpot <= program.offeredSpots) {
            const assignedStudentKey = studentAssignments
              .get(program.programKey)
              ?.get(offeredSpot)
            const acceptedProgram = acceptedPrograms.find(
              (acceptedProgram) =>
                acceptedProgram.programKey === program.programKey &&
                acceptedProgram.studentKey === assignedStudentKey
            )
            rows.push({
              cells: [
                {
                  content: program.programType,
                  align: 'left',
                  cssTextProps: {
                    fontWeight: theme.typography.fontWeightBold,
                  },
                },
                {
                  content: (
                    <>
                      {!!acceptedProgram
                        ? acceptedProgram.studentFirstName
                        : t('EnrollmentSummaryCard.Label.Skipped', 'Skipped')}
                    </>
                  ),
                  align: 'left',
                },
              ],
            })
            offeredSpot++
          }
          return rows
        })
        .flat() ?? []
    )
  }, [
    acceptedPrograms,
    selectedEnrollmentInvite?.programs,
    studentAssignments,
    t,
    theme.typography.fontWeightBold,
  ])

  /**
   * TODO use student data stored in context to fill out ledger table
   */

  useMountEffect(() => {
    updateBreadcrumbs(InviteTabFlowStages.EnrollmentSummary)
  })

  const handleCompleteInvitation = async () => {
    try {
      const result = await enrollments.completeEnrollmentInvite({
        enrollmentInviteUuid: `${selectedEnrollmentInvite?.uuid}`,
        body: {
          acceptedPrograms,
        },
      })
      if (!!result.createdEnrollments) {
        resetContextValuesForInvites()
        navigate(
          { pathname: '/account/billing' },
          { state: { selectedTab: AccountTabs.Billing } }
        )
      }
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'EnrollmentSummaryCard.Error.Default',
          'An unknown error occurred completing the invitation.'
        ),
      }
      setSnackbarMessage(errorObj.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: handleCompleteInvitation,
    loadingId: primaryButtonLoadingId,
  })

  return (
    <Card aria-labelledby="enrollmentSummary" component={Paper}>
      <CardFormHeader
        header={
          <Header
            id="enrollmentSummary"
            headerName={t(
              'EnrollmentSummaryCard.Heading.EnrollmentSummary',
              'Enrollment Summary'
            )}
            component="h2"
            variant={HeaderVariant.Card}
          />
        }
      />
      <TableContainer
        id="enrollment-summary-table-container"
        sx={{
          mx: theme.spacing(3),
          width: 'auto',
        }}
      >
        <LedgerTable
          tableHeaders={[
            {
              render: (
                <AdjacentLabels
                  leftLabel={t(
                    'EnrollmentSummary.Table.Header.Community',
                    'Community'
                  )}
                  rightLabel={selectedEnrollmentInvite?.communityName ?? ''}
                />
              ),
              label: '',
              align: 'left',
              id: 'communityName',
              cssProps: {
                paddingLeft: 0,
              },
            },
            {
              label: '',
              align: 'left',
              id: 'studentName',
            },
          ]}
          rows={enrollmentSummaryTableRows}
          ariaLabel="EnrollmentSummaryTable"
        />
      </TableContainer>
      <Box
        m={theme.spacing(3)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          component="p"
          variant="body1"
          textAlign="center"
          sx={{ pb: 4 }}
          width="30%"
        >
          {discountText}
        </Typography>
      </Box>
      <Box justifyContent={'center'} display="flex">
        <Box width={useShowOnDesktop() ? '50%' : '100%'}>
          <ActionButtons
            primaryButtonLabel={ContainedButtonVariant.CompleteInvitation}
            secondaryButtonLabel={TextButtonVariant.Back}
            secondaryClick={() =>
              navigate(
                { pathname: '/account/invites/assign-children' },
                {
                  state: { openNavigationModal: true },
                }
              )
            }
            primaryButtonLoadingId={primaryButtonLoadingId}
            useBaseButton
            alwaysStack
          />
        </Box>
      </Box>
    </Card>
  )
}

export default EnrollmentSummaryCard
