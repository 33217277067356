import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyPage from '../Elements/EmptyPage'

export const EmptyProgramDetails: React.FunctionComponent<{
  isLoading: boolean
}> = ({ isLoading }) => {
  const { t } = useTranslation()

  return (
    <EmptyPage
      isLoading={isLoading}
      message={
        <Typography variant="subtitle1" component="p">
          {t(
            'ProgramDetails.EmptyState.Message',
            `The program either does not exist or you do not have permission to view.`
          )}
        </Typography>
      }
    />
  )
}

export default EmptyProgramDetails
