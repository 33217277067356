import { Page } from '../Elements/PageMargins'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { events, extractedErrorObject } from '../../api/swagger'
import TitleContext from '../../TitleContext'
import EventFormCard from '../Card/EventFormCard'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import EmptyPage from '../Elements/EmptyPage'
import Typography from '@mui/material/Typography'
import { LoadingContext } from '../Context/LoadingContext'
import { OperationIds } from '../../swagger/operationIdEnum'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useSnackbarContext } from '../Context/SnackbarContext'
import LoadingProgress from '../Elements/LoadingProgress'
import { EventFormCardVariants } from './EventEnums'
import { useEventContext } from '../Context/EventContext'

const filename = 'EventDetails'

export const EventDetails: React.FunctionComponent = (props) => {
  const { t } = useTranslation()
  const title = t('Events.EventDetails.Header', 'Events')
  const { breadcrumbs, updateBreadcrumbs, eventDetails, updateEventDetails } =
    useEventContext()

  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)

  const { eventKey } = useParams<{
    eventKey: string
  }>()

  const eventId = parseInt(`${eventKey}`)

  const failedToFetchEventMessage = t(
    'Events.EventDetails.FailedFetchEvent',
    'An unknown error occurred fetching event details.'
  )

  const fetchEventLoadingId = `${filename}${OperationIds.FetchEvent}`
  const { addLoadingIds, removeLoadingIds, loadingIds } =
    React.useContext(LoadingContext)

  useMountEffect(() => {
    addLoadingIds([fetchEventLoadingId])
    const fetchEventDetails = async () => {
      try {
        const fetchedEventDetails = await events.fetchEvent({
          eventKey: eventId,
        })

        updateEventDetails(fetchedEventDetails)
      } catch (error) {
        const errorObject = (await extractedErrorObject(error)) ?? {
          code: 'Unknown',
          message:
            (error as unknown as Error).message ?? failedToFetchEventMessage,
        }
        setSnackbarState?.(true)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarSeverity?.(SnackbarSeverity.Error)
      } finally {
        removeLoadingIds([fetchEventLoadingId])
      }
    }
    fetchEventDetails()
  })

  useEffect(() => {
    updateBreadcrumbs(eventDetails?.name)
  }, [eventDetails?.name, updateBreadcrumbs])

  const updateEventName = (eventName: string) => {
    !!eventDetails?.name &&
      updateEventDetails({
        ...eventDetails,
        name: eventName,
      })
  }

  if (loadingIds.has(fetchEventLoadingId)) {
    return <LoadingProgress />
  }

  if (!eventDetails && !loadingIds.has(fetchEventLoadingId)) {
    return (
      <EmptyPage
        message={
          <Typography variant="subtitle1" component="p">
            {t(
              'Events.EmptyState.Message',
              `The event either does not exist or you do not have permission to view it.`
            )}
          </Typography>
        }
      />
    )
  }

  return (
    <Page>
      <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
      <EventFormCard
        variant={EventFormCardVariants.EditEvent}
        eventDetails={eventDetails}
        eventKey={eventId}
        updateEventName={updateEventName}
        {...props}
      />
    </Page>
  )
}

export default EventDetails
