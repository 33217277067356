import { Box, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { removeStringExtraSpaces } from '../../utils/stringUtility'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import MonthDropDown from '../Menus/MonthDropDown'
import YearDropDown from '../Menus/YearDropDown'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'

interface AddChildrenFieldsProps {
  /** First Name value tracked in useFormFieldReducer */
  firstName: string
  /** Birth Month value tracked in useFormFieldReducer */
  birthMonth: string
  /** Birth Year value tracked in useFormFieldReducer */
  birthYear: string
  /** Edit action from the dispatch */
  editFieldValue?: (params: {
    fieldId: string
    value?: string
    valuePropName?: string
  }) => void
  /**
   * Unique identifier of the component, a dateTime that allows actions
   * to be performed by utilizing the field key + dateTime
   */
  dateTime: number
  /** Triggers a refresh of the fields after an action from the dispatch */
  updateFields?: () => void
  /** Remove action from the dispatch */
  removeField?: (fieldId: string) => void
}

const AddChildrenFields: React.FC<AddChildrenFieldsProps> = ({
  firstName,
  birthMonth,
  birthYear,
  editFieldValue,
  dateTime,
  updateFields,
  removeField,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateFields?.()
    editFieldValue?.({
      /**
       *  The fieldId is the key of the instance of AddChildrenFields.
       *  We update the firstName property of that keyed instance of the value
       *  from this change event.
       */
      fieldId: `new-child-${dateTime}`,
      value: removeStringExtraSpaces(event.target.value),
      valuePropName: 'firstName',
    })
  }

  const handleSelection = (selected: {
    name: string
    id: string
    dropDownId: string
  }): void => {
    const { dropDownId, name: value } = selected
    const [valuePropName] = dropDownId.split('-')

    updateFields?.()
    editFieldValue?.({
      /**
       *  The fieldId is the key of the instance of AddChildrenFields.
       *  We update the dateOfBirth property of that keyed instance of the value
       *  from this change event.
       */
      fieldId: `new-child-${dateTime}`,
      value,
      valuePropName,
    })
  }

  const handleRemoveField = () => {
    updateFields?.()
    removeField?.(`new-child-${dateTime}`)
  }

  return (
    <SpaceBetweenSection
      isCentered
      containerStyles={{
        gap: theme.spacing(1),
        marginBottom: theme.spacing(3),
      }}
    >
      <Box width={'100%'}>
        <TextField
          value={firstName}
          id={`firstNameField-${dateTime}`}
          name={`firstNameField-${dateTime}`}
          variant="filled"
          label={t('AddChildren.FormFields.FirstName', 'First Name')}
          type="text"
          onChange={handleFirstNameChange}
          fullWidth
        />
      </Box>
      <Box width={'100%'}>
        <MonthDropDown
          id={`birthMonth-${dateTime}`}
          value={`${birthMonth}`}
          handleSelection={handleSelection}
          label="Birth Month"
        />
      </Box>
      <Box width={'100%'}>
        <YearDropDown
          id={`birthYear-${dateTime}`}
          value={`${birthYear}`}
          handleSelection={handleSelection}
          label="Birth Year"
        />
      </Box>
      <TextButton
        id={`remove-${dateTime}`}
        variant={TextButtonVariant.Remove}
        onClick={handleRemoveField}
        css={{
          minWidth: 200,
        }}
      />
    </SpaceBetweenSection>
  )
}

export default AddChildrenFields
