import { TranscriptDetails } from '../components/Context/TranscriptContext'
import {
  CreateTranscriptYearCourseWork,
  GradingScale,
  TranscriptYearCourseWork,
} from '../swagger'

type PartialCourseWork = Partial<
  CreateTranscriptYearCourseWork & TranscriptYearCourseWork
>

export const isCourseWorkFilledOut = (course: PartialCourseWork): boolean => {
  return (
    (course.credits ?? 0) !== 0 && !!course.courseName && !!course.letterGrade
  )
}

export const gradePointForCourse = (
  course: PartialCourseWork,
  gradingScale: GradingScale
): number => {
  if (!isCourseWorkFilledOut(course)) {
    return 0
  }

  const gradeCredits =
    (
      gradingScale.gradingScaleDetails?.find((scale) => {
        return scale.letterGrade === course.letterGrade
      }) ?? {}
    ).credit ?? 0

  const courseWeight = +(
    gradingScale.gradingScaleWeight?.find((weight) =>
      course.courseName?.startsWith(`${weight.weightName}:`)
    )?.weight ?? 0
  )

  /*
   * Typescript doesn't understand credits will never be undefined... Yet.
   * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-5-5.html
   */
  return (gradeCredits + courseWeight) * (course.credits ?? 0)
}

export const calculateYearGPA = (
  coursework: PartialCourseWork[],
  gradingScale: GradingScale
): number => {
  let totalCredits = 0
  let totalGP = 0
  for (const course of coursework) {
    totalGP += gradePointForCourse(course, gradingScale)
    totalCredits += isCourseWorkFilledOut(course) ? course.credits ?? 0 : 0
  }

  if (totalCredits === 0) {
    return 0
  }

  return parseFloat((totalGP / totalCredits).toFixed(2))
}

export const calculateYearCredits = (
  coursework: PartialCourseWork[]
): number => {
  return parseFloat(
    coursework
      .reduce((acc, curr) => {
        if (isCourseWorkFilledOut(curr)) {
          return acc + (curr.credits ?? 0)
        }
        return acc
      }, 0)
      .toFixed(1)
  )
}

const collectAllCourses = (
  transcript: TranscriptDetails
): PartialCourseWork[] => {
  const allCourses: PartialCourseWork[] = []

  if (transcript.transcriptYear1?.transcriptYearCourseWork) {
    allCourses.push(...transcript.transcriptYear1?.transcriptYearCourseWork)
  }
  if (transcript.transcriptYear2?.transcriptYearCourseWork) {
    allCourses.push(...transcript.transcriptYear2?.transcriptYearCourseWork)
  }
  if (transcript.transcriptYear3?.transcriptYearCourseWork) {
    allCourses.push(...transcript.transcriptYear3?.transcriptYearCourseWork)
  }
  if (transcript.transcriptYear4?.transcriptYearCourseWork) {
    allCourses.push(...transcript.transcriptYear4?.transcriptYearCourseWork)
  }

  return allCourses
}

export const calculateTranscriptGPA = (
  transcript: TranscriptDetails,
  gradingScale: GradingScale
): number => {
  const allCourses = collectAllCourses(transcript)

  let totalCredits = 0
  let totalGP = 0
  for (const course of allCourses) {
    totalGP += gradePointForCourse(course, gradingScale)
    totalCredits += isCourseWorkFilledOut(course) ? course.credits ?? 0 : 0
  }

  if (totalCredits === 0) {
    return 0
  }

  return parseFloat((totalGP / totalCredits).toFixed(2))
}

export const calculateTranscriptCredits = (
  transcript: TranscriptDetails
): number => {
  const allCourses = collectAllCourses(transcript)

  let totalCredits = 0
  for (const course of allCourses) {
    totalCredits += isCourseWorkFilledOut(course) ? course.credits ?? 0 : 0
  }

  return parseFloat(totalCredits.toFixed(1))
}
