import React from 'react'
import { useTranslation } from 'react-i18next'
import { Student } from '../../swagger'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import YearDropDown from '../Menus/YearDropDown'

interface ChildrenBirthYearFieldProps {
  kid: Student
  isEditMode: boolean
  updateForParent: (name: string, updatedValue: string) => void
  childBirthYearStates: DynamicFieldStates
  isMobile?: boolean
}

const ChildrenBirthYearField: React.FC<ChildrenBirthYearFieldProps> = ({
  kid,
  isEditMode,
  updateForParent,
  childBirthYearStates,
}) => {
  const { t } = useTranslation()

  const value = childBirthYearStates[`${kid.studentKey}-birthYear`]?.state ?? ''

  return (
    <YearDropDown
      label={t('ChildrenSummaryCard.Children.Label.birthYear', 'Birth Year')}
      id={`${kid.studentKey}-birthYear`}
      value={value}
      required={childBirthYearStates[`${kid.studentKey}-birthYear`]?.required}
      handleSelection={(selected: {
        name: string
        id: string
        dropDownId: string
      }): void => {
        const { name: updatedValue } = selected
        return updateForParent(`${kid.studentKey}-birthYear`, updatedValue)
      }}
      isDropdown={isEditMode}
      disabled={!isEditMode}
      isValid={childBirthYearStates[`${kid.studentKey}-birthYear`]?.isValid}
    />
  )
}

export default ChildrenBirthYearField
