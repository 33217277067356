import EnrollmentRowInfo from '../components/Interfaces/EnrollmentRowInfo'
import { Enrollment, Family } from '../swagger'

export const sortFamilyByFamilyLastName = (
  familyA: Family,
  familyB: Family
): number => {
  if (
    familyA.familyLastName.trim().toLowerCase() <
    familyB.familyLastName.trim().toLowerCase()
  ) {
    return -1
  }
  if (
    familyA.familyLastName.trim().toLowerCase() >
    familyB.familyLastName.trim().toLowerCase()
  ) {
    return 1
  }
  return 0
}

//Order by Family and student name
export const sortEnrollmentsByStudentName = (
  enrollmentA: EnrollmentRowInfo | Enrollment,
  enrollmentB: EnrollmentRowInfo | Enrollment
): number => {
  if (
    enrollmentA.familyName.trim().toLowerCase() <
    enrollmentB.familyName.trim().toLowerCase()
  ) {
    return -1
  }

  if (
    enrollmentA.familyName.trim().toLowerCase() >
    enrollmentB.familyName.trim().toLowerCase()
  ) {
    return 1
  }

  if (
    enrollmentA.studentName.trim().toLowerCase() <
    enrollmentB.studentName.trim().toLowerCase()
  ) {
    return -1
  }

  if (
    enrollmentA.studentName.trim().toLowerCase() >
    enrollmentB.studentName.trim().toLowerCase()
  ) {
    return 1
  }

  return 0
}
