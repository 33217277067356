import React, { useState, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography, { TypographyProps } from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableHeader from '../Table/TableHeader'
import TableHeaders from '../Interfaces/TableHeaders'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import BasicModal from '../Modals/BasicModal'
import ActionButtons from '../Buttons/ActionButtons'
import { useTranslation } from 'react-i18next'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import Header, { HeaderVariant } from '../Elements/Header'
import { ReceiverRole } from '../../swagger'
import {
  Modification,
  LocalContractingHierarchy,
  ContractingHierarchyReference,
} from './RegionFormCard'
import { styled } from '@mui/system'

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const AdditionalText = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.textOrIcon.subheader,
})) as typeof Typography

const emptyContractingState = {
  senderRole: '',
  receiverRole: '',
}

const initialContractingRowToRemove: {
  senderRoleKey?: number
  receiverRoleKey?: number
} = {}

const RegionAgreementPermissionCard: React.FC<{
  isFieldDisabled: boolean
  bypassAutomatedAgreements: boolean
  handleRegionInfoChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  senderRoles: ReceiverRole[]
  receiverRoles: ReceiverRole[]
  localContractingHierarchies: LocalContractingHierarchy[]
  setLocalContractingHierarchies: React.Dispatch<
    React.SetStateAction<LocalContractingHierarchy[]>
  >
  setContractingHierarchiesToAdd: React.Dispatch<
    React.SetStateAction<ContractingHierarchyReference[]>
  >
  setContractingHierarchiesToDelete: React.Dispatch<
    React.SetStateAction<ContractingHierarchyReference[]>
  >
}> = ({
  isFieldDisabled,
  bypassAutomatedAgreements,
  handleRegionInfoChange,
  senderRoles,
  receiverRoles,
  localContractingHierarchies,
  setLocalContractingHierarchies,
  setContractingHierarchiesToAdd,
  setContractingHierarchiesToDelete,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)
  const [contractingState, setContractingState] = useState(
    emptyContractingState
  )

  const contractingRowToRemove = useRef(initialContractingRowToRemove)

  const handleContractingChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setContractingState({
      ...contractingState,
      [event.target.name]: event.target.value,
    })
  }

  const handleAdd = () => {
    const selectedSenderRoleKey = +contractingState.senderRole
    const selectedReceiverRoleKey = +contractingState.receiverRole

    /** Add the selected values to our "to add" list for use with API call */
    setContractingHierarchiesToAdd((prevState) => [
      ...prevState,
      {
        senderRoleKey: selectedSenderRoleKey,
        receiverRoleKey: selectedReceiverRoleKey,
      },
    ])

    /** Get role names for the selected roles */
    const { name: selectedSenderRoleName = '' } =
      senderRoles.find((role) => role.roleKey === selectedSenderRoleKey) ?? {}
    const { name: selectedReceiverRoleName = '' } =
      receiverRoles.find((role) => role.roleKey === selectedReceiverRoleKey) ??
      {}

    /** Add the selected values to our local contracting hierarchy list */
    setLocalContractingHierarchies((prevState) => [
      ...prevState,
      {
        senderRole: {
          roleKey: selectedSenderRoleKey,
          roleName: selectedSenderRoleName,
        },
        receiverRole: {
          roleKey: selectedReceiverRoleKey,
          roleName: selectedReceiverRoleName,
        },
        modification: Modification.Add,
      },
    ])

    /** Clear out the input fields */
    setContractingState(emptyContractingState)
  }

  const onCancelAddClick = (rowKey: string) => {
    const [senderRoleKey, receiverRoleKey] = rowKey.split('-')

    /** Remove the specified item from the "to add" list... */
    setContractingHierarchiesToAdd((prevState) => {
      return prevState.filter(
        (contractingHierarchyToAdd) =>
          contractingHierarchyToAdd.senderRoleKey !== +senderRoleKey ||
          contractingHierarchyToAdd.receiverRoleKey !== +receiverRoleKey
      )
    })

    /** ...and from the local hierarchies list */
    setLocalContractingHierarchies(
      localContractingHierarchies.filter(
        (local) =>
          local.senderRole.roleKey !== +senderRoleKey ||
          local.receiverRole.roleKey !== +receiverRoleKey
      )
    )
  }

  const onRemoveClick = (rowKey: string) => {
    const [senderRoleKey, receiverRoleKey] = rowKey.split('-')
    contractingRowToRemove.current = {
      senderRoleKey: +senderRoleKey,
      receiverRoleKey: +receiverRoleKey,
    }
    setIsConfirmationModalVisible(true)
  }

  const handleRemove = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()

    /** Add the selected values to our "to delete" list for use with API call */
    setContractingHierarchiesToDelete((prevState) => [
      ...prevState,
      contractingRowToRemove.current as ContractingHierarchyReference,
    ])

    /** Find the row to remove and update its "modification" status in our local contracting hierarchy list */
    const newLocalContractingHierarchies = localContractingHierarchies.map(
      (local) => {
        const { receiverRole, senderRole } = local
        if (
          receiverRole.roleKey ===
            contractingRowToRemove.current.receiverRoleKey &&
          senderRole.roleKey === contractingRowToRemove.current.senderRoleKey
        ) {
          return {
            senderRole,
            receiverRole,
            modification: Modification.Delete,
          }
        }
        return local
      }
    )
    setLocalContractingHierarchies(newLocalContractingHierarchies)
    setIsConfirmationModalVisible(false)
  }

  const onCancelRemoveClick = (rowKey: string) => {
    const [senderRoleKey, receiverRoleKey] = rowKey.split('-')

    /** Remove the specified item from the "to delete" list... */
    setContractingHierarchiesToDelete((prevState) => {
      return prevState.filter(
        (contractingHierarchyToDelete) =>
          contractingHierarchyToDelete.senderRoleKey !== +senderRoleKey ||
          contractingHierarchyToDelete.receiverRoleKey !== +receiverRoleKey
      )
    })

    /** ...and revert its "modification" status in our local contracting hierarchy list */
    const newLocalContractingHierarchies = localContractingHierarchies.map(
      (local) => {
        const { receiverRole, senderRole } = local
        if (
          receiverRole.roleKey === +receiverRoleKey &&
          senderRole.roleKey === +senderRoleKey
        ) {
          return {
            senderRole,
            receiverRole,
            modification: undefined,
          }
        }
        return local
      }
    )
    setLocalContractingHierarchies(newLocalContractingHierarchies)
  }

  const tableHeaders: TableHeaders[] = [
    {
      label: t(
        'Regions.RegionForm.AgreementPermissions.AllowingRole',
        'Allowing Role'
      ),
      align: 'left',
      cssProps: {
        /** We set this value so the table columns line up with the form inputs */
        width: '34%',
        color: theme.palette.textOrIcon.tableHeader,
      },
    },
    {
      label: t(
        'Regions.RegionForm.AgreementPermissions.RoleToAdd',
        'Role to Add'
      ),
      align: 'left',
      cssProps: {
        /** We set this value so the table columns line up with the form inputs */
        width: '34%',
        color: theme.palette.textOrIcon.tableHeader,
      },
    },
    {
      label: '',
      align: 'right',
    },
  ]

  const isAddButtonDisabled =
    !contractingState.receiverRole || !contractingState.senderRole

  return (
    <>
      <BasicModal
        isOpen={isConfirmationModalVisible}
        dialogTitle={t(
          'Regions.RegionForm.AgreementPermissions.DeleteConfirmation',
          'Are you sure you want to remove this agreement permission?'
        )}
        handleFormSubmit={handleRemove}
        ariaLabel="delete confirmation modal"
        maxWidth="xs"
        dialogContent={null}
        dialogActions={
          <ActionButtons
            primaryButtonLabel={ContainedButtonVariant.YesRemove}
            secondaryButtonLabel={TextButtonVariant.NoCancel}
            secondaryClick={() => setIsConfirmationModalVisible(false)}
          />
        }
      />
      <Card
        sx={{
          padding: theme.spacing(3, 4, 4),
          maxWidth: 1200,
          color: theme.palette.primary.main,
          marginBottom: theme.spacing(3),
        }}
      >
        <section aria-labelledby="regionAgreementPermissionsHeader">
          <Header
            id="regionAgreementPermissionsHeader"
            headerName={t(
              'Regions.RegionForm.Header.AgreementPermissions',
              'Agreement Permissions'
            )}
            component="h2"
            variant={HeaderVariant.Card}
          />

          <form
            noValidate
            autoComplete="off"
            aria-labelledby="regionFormHeader"
          >
            <Grid container spacing={3}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bypassAutomatedAgreements"
                      color="secondary"
                      disabled={isFieldDisabled}
                      sx={{
                        color: theme.palette.textOrIcon.checkbox,
                      }}
                      checked={bypassAutomatedAgreements}
                      onChange={handleRegionInfoChange}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      color={theme.palette.primary.dark}
                    >
                      {t(
                        'Regions.RegionForm.AgreementPermissions.CheckboxLabel',
                        'Bypass automated agreements'
                      )}
                    </Typography>
                  }
                />
                <Box pl={3.625} pb={1}>
                  <Typography
                    variant="body2"
                    color={theme.palette.primary.dark}
                  >
                    {t(
                      'Regions.RegionForm.AgreementPermissions.HelperText',
                      'This allows the region to override automated agreements and handle them outside of the system'
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {((!isFieldDisabled && localContractingHierarchies.length === 0) ||
              localContractingHierarchies.length > 0) && (
              <Table>
                <TableHead>
                  <TableHeader tableHeaders={tableHeaders} />
                </TableHead>
                <TableBody>
                  {localContractingHierarchies.map(
                    ({ senderRole, receiverRole, modification }) => {
                      const uniqueRowKey = `${senderRole.roleKey}-${receiverRole.roleKey}`

                      const CancelRemoveButton = (
                        <TextButton
                          id={`CancelButton${uniqueRowKey}`}
                          variant={TextButtonVariant.Cancel}
                          css={{
                            margin: 0,
                            fontSize: theme.typography.button.fontSize,
                          }}
                          onClick={() => {
                            onCancelRemoveClick(uniqueRowKey)
                          }}
                        />
                      )

                      const CancelAddButton = (
                        <TextButton
                          id={`CancelButton${uniqueRowKey}`}
                          variant={TextButtonVariant.Cancel}
                          css={{
                            margin: 0,
                            fontSize: theme.typography.button.fontSize,
                          }}
                          onClick={() => {
                            onCancelAddClick(uniqueRowKey)
                          }}
                        />
                      )

                      return (
                        <TableRow key={uniqueRowKey}>
                          <TableCell color={theme.palette.primary.main}>
                            <Typography variant="body1" component="p">
                              {senderRole.roleName}
                            </Typography>
                          </TableCell>
                          <TableCell color={theme.palette.primary.main}>
                            <Typography variant="body1" component="p">
                              {receiverRole.roleName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            color={theme.palette.primary.main}
                          >
                            {!isFieldDisabled && (
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                {modification === Modification.Add && (
                                  <>
                                    <AdditionalText variant="subtitle1">
                                      {t(
                                        'Regions.RegionForm.AgreementPermissions.PendingAddition',
                                        'Pending Addition (unsaved)'
                                      )}
                                    </AdditionalText>
                                    {CancelAddButton}
                                  </>
                                )}
                                {modification === Modification.Delete && (
                                  <>
                                    <AdditionalText variant="subtitle1">
                                      {t(
                                        'Regions.RegionForm.AgreementPermissions.PendingRemoval',
                                        'Pending Removal (unsaved)'
                                      )}
                                    </AdditionalText>
                                    {CancelRemoveButton}
                                  </>
                                )}
                                {!modification && (
                                  <TextButton
                                    id={`removeButton${uniqueRowKey}`}
                                    variant={TextButtonVariant.Remove}
                                    onClick={() => {
                                      onRemoveClick(uniqueRowKey)
                                    }}
                                  />
                                )}
                              </Box>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )}
                </TableBody>
              </Table>
            )}
            {isFieldDisabled && localContractingHierarchies.length === 0 && (
              <Box pt={1.5}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.primary.dark}
                >
                  {t(
                    'Regions.RegionForm.AgreementPermissions.EmptyHierarchy',
                    'No agreement permissions have been added'
                  )}
                </Typography>
              </Box>
            )}
            {!isFieldDisabled && (
              <Box p={1.5}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} sm>
                    <TextField
                      id="senderRoleField"
                      name="senderRole"
                      label={t(
                        'Regions.RegionForm.FormField.RoleOfSender',
                        'Role of Sender'
                      )}
                      fullWidth
                      select
                      variant="filled"
                      value={contractingState.senderRole}
                      onChange={handleContractingChange}
                    >
                      {senderRoles.map(({ roleKey, name }) => (
                        <MenuItem key={roleKey} value={roleKey}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm>
                    <TextField
                      id="receiverRoleField"
                      name="receiverRole"
                      label={t(
                        'Regions.RegionForm.FormField.RoleOfReceiver',
                        'Role of Receiver'
                      )}
                      fullWidth
                      select
                      variant="filled"
                      value={contractingState.receiverRole}
                      onChange={handleContractingChange}
                    >
                      {receiverRoles.map(({ roleKey, name }) => (
                        <MenuItem key={roleKey} value={roleKey}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm>
                    <OutlinedButton
                      id="addButton"
                      variant={OutlinedButtonVariant.Add}
                      disabled={isAddButtonDisabled}
                      onClick={handleAdd}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </form>
        </section>
      </Card>
    </>
  )
}

export default React.memo(RegionAgreementPermissionCard)
