import { auth } from './swagger'

export const logout = async (): Promise<boolean> => {
  try {
    await auth.logout({})
    return true
  } catch {
    return false
  }
}
export default logout
