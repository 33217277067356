/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  EnrollmentInviteProgram,
  EnrollmentInviteProgramFromJSON,
  EnrollmentInviteProgramToJSON,
} from './'

/**
 * A single use invitation for a family to enroll students in the specified programs. If the family is already in the system, and can be viewed by the director, the parent user account is immediately bound to the invite via the parentUserKey. If the family is new or unknown to the director, the account is bound at invite acceptance.
 * @export
 * @interface EnrollmentInvite
 */
export interface EnrollmentInvite {
  /**
   * Unique identifier for the invitation, used in the unique invite link for the family.
   * @type {string}
   * @memberof EnrollmentInvite
   */
  uuid: string
  /**
   * The status of the invitation. Can be `InProgress`, `Complete` or `Cancelled`
   * @type {string}
   * @memberof EnrollmentInvite
   */
  status: EnrollmentInviteStatusEnum
  /**
   * Email that the invite was sent to.
   * @type {string}
   * @memberof EnrollmentInvite
   */
  sentToEmail: string
  /**
   * First name of the parent the invitation is being sent to.
   * @type {string}
   * @memberof EnrollmentInvite
   */
  parentFirstName: string
  /**
   * The last name provided by the user.
   * @type {string}
   * @memberof EnrollmentInvite
   */
  parentLastName: string
  /**
   * Unique identifier for the parent user bound to the invitation. If absent, a user account has not yet been bound, and the invited individual can log in or register with a new account to bind to the invitation. An invitation with status of 'Complete' must always have a user account bound.
   * @type {number}
   * @memberof EnrollmentInvite
   */
  parentUserKey?: number
  /**
   * The actor who sent the invitation.
   * @type {number}
   * @memberof EnrollmentInvite
   */
  sentByActorKey: number
  /**
   * The invitation is no longer valid on and after this date and time. If absent, the invitation does not expire.
   * @type {Date}
   * @memberof EnrollmentInvite
   */
  expires?: Date
  /**
   * Unique identifier of the community the enrollment invitation is for.
   * @type {number}
   * @memberof EnrollmentInvite
   */
  communityKey: number
  /**
   * Name of the community the enrollment invitation is for.
   * @type {string}
   * @memberof EnrollmentInvite
   */
  communityName: string
  /**
   * The programs this enrollment invitation pertains to, as well as the number of student spots per program.
   * @type {Array<EnrollmentInviteProgram>}
   * @memberof EnrollmentInvite
   */
  programs: Array<EnrollmentInviteProgram>
  /**
   * The created date of record in the database.
   * @type {Date}
   * @memberof EnrollmentInvite
   */
  invitationDate: Date
  /**
   * The date when the invitation was resent. This corresponds to the Modified date field in the database.
   * @type {Date}
   * @memberof EnrollmentInvite
   */
  resentDate?: Date
}

/**
 * @export
 * @enum {string}
 */
export enum EnrollmentInviteStatusEnum {
  InProgress = 'InProgress',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}

export function EnrollmentInviteFromJSON(json: any): EnrollmentInvite {
  return EnrollmentInviteFromJSONTyped(json, false)
}

export function EnrollmentInviteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentInvite {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uuid: json['uuid'],
    status: json['status'],
    sentToEmail: json['sentToEmail'],
    parentFirstName: json['parentFirstName'],
    parentLastName: json['parentLastName'],
    parentUserKey: !exists(json, 'parentUserKey')
      ? undefined
      : json['parentUserKey'],
    sentByActorKey: json['sentByActorKey'],
    expires: !exists(json, 'expires') ? undefined : new Date(json['expires']),
    communityKey: json['communityKey'],
    communityName: json['communityName'],
    programs: (json['programs'] as Array<any>).map(
      EnrollmentInviteProgramFromJSON
    ),
    invitationDate: new Date(json['invitationDate']),
    resentDate: !exists(json, 'resentDate')
      ? undefined
      : new Date(json['resentDate']),
  }
}

export function EnrollmentInviteToJSON(value?: EnrollmentInvite | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uuid: value.uuid,
    status: value.status,
    sentToEmail: value.sentToEmail,
    parentFirstName: value.parentFirstName,
    parentLastName: value.parentLastName,
    parentUserKey: value.parentUserKey,
    sentByActorKey: value.sentByActorKey,
    expires:
      value.expires === undefined ? undefined : value.expires.toISOString(),
    communityKey: value.communityKey,
    communityName: value.communityName,
    programs: (value.programs as Array<any>).map(EnrollmentInviteProgramToJSON),
    invitationDate: value.invitationDate.toISOString().substr(0, 10),
    resentDate:
      value.resentDate === undefined
        ? undefined
        : value.resentDate.toISOString().substr(0, 10),
  }
}
