import React, { PropsWithChildren } from 'react'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import CenterWithMountainBackground from '../Elements/CenterWithMountainBackground'
import Logo from '../../Images/Branding/cc-combination-blue.png'
import { styled } from '@mui/system'

const StyledBrandCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(5, 1.5),
  margin: theme.spacing(2),
  textAlign: 'center',
  width: 'auto',
  minWidth: `220px`, // 500px
  maxWidth: '540px',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow:
    '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
  [theme.breakpoints.down('sm')]: {
    width: `calc(100vw - ${theme.spacing(4)})`,
    minWidth: `calc(100vw - ${theme.spacing(4)})`,
    padding: theme.spacing(15, 0),
    margin: theme.spacing(4),
  },
}))

interface BrandCardProps extends PropsWithChildren {
  minimumScreenHeight: number
  additionalHeaderContent?: JSX.Element
  cardContent?: JSX.Element | null | false
  cardActions?: JSX.Element | null | false
  display?: 'block' | 'flex'
}

const BrandCard: React.FC<BrandCardProps> = ({
  minimumScreenHeight,
  additionalHeaderContent,
  cardContent,
  cardActions,
  display,
}) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  return (
    <CenterWithMountainBackground minimumScreenHeight={minimumScreenHeight}>
      <StyledBrandCard>
        <Typography variant="h6" component="h1">
          <img alt="Classical Conversations" src={Logo} width="250px" />
        </Typography>
        {additionalHeaderContent}
        <form
          data-testid="login-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <CardContent>{cardContent}</CardContent>
          <CardActions
            sx={{
              display: display ?? 'block',
            }}
          >
            {cardActions}
          </CardActions>
        </form>
      </StyledBrandCard>
    </CenterWithMountainBackground>
  )
}

export default BrandCard
