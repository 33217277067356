import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { FetchEventsResponse } from '../../swagger'
import Logo from '../../Images/Branding/cc-logo-white.svg'
import LogoBlue from '../../Images/Branding/cc-logo-blue.svg'
import { displayFormattedDate } from '../../utils/displayFormattedDate'
import LinkIcon from '@mui/icons-material/Link'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import Divider from '@mui/material/Divider'
import { useNavigate } from 'react-router'
import { createSpacetime } from '../Events/Events'
import { EmptyPageCenter } from '../Elements/PageMargins'
import { Box, CircularProgress, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { timezoneTitleCase } from '../../utils/timeFormatUtilities'

interface EventMobile {
  todaysEvent?: boolean
  isLoading: boolean
  event: FetchEventsResponse
}

const EventCardMobileCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(5, 'auto'),
}))

const EventCardContent = styled(CardContent)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  padding: '0 !important',
  color: theme.palette.primary.main,
}))

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const MainTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.textOrIcon.subheader,
  margin: theme.spacing(1, 0),
})) as typeof Typography

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const IconTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.textOrIcon.subheader,
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: `${theme.spacing(1)} !important`,
})) as typeof Typography

const PublicUrlLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  marginLeft: theme.spacing(2),
  '&:hover': { textDecoration: 'underline' },
}))

const EventCardMobile: React.FC<EventMobile> = ({
  todaysEvent,
  event,
  isLoading,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const publicUrl = event.publicUrl ?? '#'
  const toEventDetailsPage = (id: number, eventName: string) => {
    navigate(
      {
        pathname: `/events/event-details/${id}`,
      },
      {
        state: { eventName },
      }
    )
  }

  if (isLoading)
    return (
      <EmptyPageCenter adjustForMargins>
        <CircularProgress />
      </EmptyPageCenter>
    )

  return (
    <EventCardMobileCard
      data-testid="eventCardMobile"
      variant="elevation"
      onClick={() =>
        toEventDetailsPage((event.eventKey as number) ?? '', event.name)
      }
    >
      <EventCardContent>
        {todaysEvent ? (
          <Box
            sx={{
              background: event.isOnline
                ? theme.palette.customBackground.logo
                : theme.palette.primary.main,
            }}
          >
            <img
              src={event.isOnline ? LogoBlue : Logo}
              alt={t('Events.EventImage.AltText', 'Event Image')}
              style={{
                height: 115,
                padding: theme.spacing(3),
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              height: 12,
              background: event.isOnline
                ? theme.palette.customBackground.logo
                : theme.palette.primary.main,
            }}
          />
        )}
        <Box margin={theme.spacing(2, 2, 1)}>
          <Typography variant="h6" component="h2">
            {event.name}
          </Typography>

          <MainTypography variant="body1" component="p">
            {t('Events.Date', 'Date:')}{' '}
            <span color={theme.palette.primary.main}>
              {displayFormattedDate(
                event.startDate?.toISOString().slice(0, 10),
                event.endDate?.toISOString().slice(0, 10) ?? ''
              )}
            </span>
          </MainTypography>

          <MainTypography variant="body1" component="p">
            {t('Events.Time', 'Event Time:')}{' '}
            <span color={theme.palette.primary.main}>
              {`${createSpacetime(event).time()} - ${createSpacetime(
                event,
                false
              ).time()} ${timezoneTitleCase(event.timezone)}`}
            </span>
          </MainTypography>

          <MainTypography variant="body1" component="p">
            {t('Events.Location', 'Location:')}{' '}
            <span color={theme.palette.primary.main}>
              {event.isOnline
                ? t('Events.NextEvent.Location.Global', 'Global')
                : t('Events.NextEvent.Location.Local', 'Local')}
            </span>
          </MainTypography>

          <MainTypography variant="body1" component="p">
            {t('Events.CreatedBy', 'Created By:')}{' '}
            <span color={theme.palette.primary.main}>{event.createdBy}</span>
          </MainTypography>

          <MainTypography variant="body1" component="p">
            {t('Events.RegistrationStatus', 'Registration:')}{' '}
            <span color={theme.palette.primary.main}>
              {event.registrationOpen
                ? t('Events.NextEvent.RegistrationStatus.Open', 'Open')
                : t('Events.NextEvent.RegistrationStatus.Closed', 'Closed')}
            </span>
          </MainTypography>

          <MainTypography variant="body1" component="p">
            {t('Events.EventType', 'Event Type:')}
            <span color={theme.palette.primary.main}>{event.eventType}</span>
          </MainTypography>

          <MainTypography variant="body1" component="p">
            <>
              <LinkIcon aria-label={t('Events.Link', 'Event Link')} />
              <PublicUrlLink id={publicUrl} href={publicUrl} target="_blank">
                {publicUrl}
              </PublicUrlLink>
            </>
          </MainTypography>
          <Divider
            sx={{
              margin: theme.spacing(1, 0),
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MainTypography variant="body1" component="p">
              {event.isOnline
                ? t('Events.LocationType', 'Global')
                : t('Events.LocationType', 'Local')}
            </MainTypography>
            <IconTypography>
              {event.isOnline ? (
                <DesktopWindowsIcon
                  aria-label={t('Events.LocationType', 'Global')}
                />
              ) : (
                <AddLocationIcon
                  aria-label={t('Events.LocationType', 'Local')}
                />
              )}
            </IconTypography>
          </Box>
        </Box>
      </EventCardContent>
    </EventCardMobileCard>
  )
}

export default EventCardMobile
