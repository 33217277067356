import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import ConfirmationModal from './ConfirmationModal'

interface DropStudentModalProps {
  isOpen: boolean
  handleFormSubmit: (event: React.FormEvent<HTMLDivElement>) => Promise<void>
  handleConfirmationCancel: () => void
  studentName: string
}

const DropStudentModal: React.FC<DropStudentModalProps> = (props) => {
  const { t } = useTranslation()
  const { isOpen, handleFormSubmit, handleConfirmationCancel, studentName } =
    props

  const confirmationBody = (
    <Typography variant="body1" component="p" align="center">
      {t(
        'DropStudentModal.PleaseConfirm',
        'Please confirm that this is the action you intend to complete.'
      )}
    </Typography>
  )

  return (
    <ConfirmationModal
      isOpen={isOpen}
      handleFormSubmit={handleFormSubmit}
      dialogActions={
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.YesDrop}
          secondaryButtonLabel={TextButtonVariant.NoCancel}
          secondaryClick={handleConfirmationCancel}
        />
      }
      dialogContent={confirmationBody}
      dialogTitle={`${t(
        'DropStudentModal.ConfirmationModal.Title.AboutToDrop',
        'You are about to drop'
      )} ${studentName} ${t(
        'DropStudentModal.ConfirmationModal.Title.FromAllPrograms',
        'from all of their programs.'
      )}`}
    />
  )
}

export default DropStudentModal
