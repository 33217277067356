import { TFunction } from 'i18next'
import { FeeType } from '../swagger'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFeeLabelMap = (t: TFunction) => {
  const feeLabelMap: { [key: string]: string } = {
    [FeeType.Application]: t('Fee.Label.Application', 'Application Fee'),
    [FeeType.Enrollment]: t('Fee.Label.Enrollment', 'Enrollment Fee'),
    [FeeType.SupplyFeeFirstStudent]: t(
      'Fee.Label.SupplyFirstStudent',
      'Supply Fee First Student'
    ),
    [FeeType.SupplyFeeAdditionalStudent]: t(
      'Fee.Label.SupplyAdditionalStudent',
      'Supply Fee Additional Students'
    ),
    [FeeType.Tuition]: t('Fee.Label.Tuition', 'Tuition Fee'),
    [FeeType.LocalFeeFirstStudent]: t(
      'Fee.Label.LocalFirstStudent',
      'Local Fee First Student'
    ),
    [FeeType.LocalFeeAdditionalStudent]: t(
      'Fee.Label.LocalAdditionalStudent',
      'Local Fee Additional Students'
    ),
    [FeeType.FacilityFeeFirstStudent]: t(
      'Fee.Label.FacilityFirstStudent',
      'Facility Fee First Student'
    ),
    [FeeType.FacilityFeeAdditionalStudent]: t(
      'Fee.Label.FacilityAdditionalStudent',
      'Facility Fee Additional Students'
    ),
    [FeeType.MiscFeeFirstStudent]: t(
      'Fee.Label.MiscFirstStudent',
      'Misc Fee First Student'
    ),
    [FeeType.MiscFeeAdditionalStudent]: t(
      'Fee.Label.MiscAdditionalStudent',
      'Misc Fee Additional Students'
    ),
    [FeeType.SubLicensedTutorPercentage]: t(
      'Fee.Label.SubLicensedTutorPercentage',
      'Percentage of Tuition Collected by Tutor'
    ),
    [FeeType.SemesterOneLicensingFee]: t(
      'Fee.Label.SemesterOneLicensingFee',
      'Semester One First Student Licensing Fee'
    ),
    [FeeType.DiscountSemesterOneLicensingFee]: t(
      'Fee.Label.DiscountSemesterOneLicensingFee',
      'Semester One Additional Students Licensing Fee'
    ),
    [FeeType.SemesterTwoLicensingFee]: t(
      'Fee.Label.SemesterTwoLicensingFee',
      'Semester Two First Student Licensing Fee'
    ),
    [FeeType.DiscountSemesterTwoLicensingFee]: t(
      'Fee.Label.DiscountSemesterTwoLicensingFee',
      'Semester Two Additional Students Licensing Fee'
    ),
    [FeeType.MultiStudentApplicationDiscount]: t(
      'Fee.Label.MultiStudentApplicationDiscount',
      'Multi-Student Application Fee Discount'
    ),
    [FeeType.InvitationFee]: t(
      'Fee.Label.InvitationFee',
      'First Student Licensing (Application)'
    ),
    [FeeType.DiscountInvitationFee]: t(
      'Fee.Label.DiscountInvitationFee',
      'Additional Students Licensing (Application)'
    ),
  }
  return feeLabelMap
}
