import { Box, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import React, { useState } from 'react'
import { FormDivider } from '../Elements/FormDivider'
import CardActionArea from '@mui/material/CardActionArea'
import UserAvatar from '../Elements/UserAvatar'
import fullName from '../../utils/fullName'
import { UserAccountListing } from '../../swagger'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { useNavigate } from 'react-router'
import { UserListingRoleAssignment } from '../../swagger/models/UserListingRoleAssignment'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'

interface TeamCardProps {
  support: UserAccountListing[]
  updateUserSelection?: (opts: {
    userKey: number
    name: string
    actorKey: number
    role: string
  }) => void
  ariaLabelledBy?: string
  textTypesForRoles: (role: UserListingRoleAssignment) => React.CSSProperties
  //This prop is used to initialize the number of cards to show in tests.
  additionalCardsAmount?: number
  //This prop is used to show or not the pagination
  showPagination?: boolean
}

export const TeamCard: React.FC<TeamCardProps> = ({
  support,
  updateUserSelection,
  ariaLabelledBy,
  textTypesForRoles,
  additionalCardsAmount,
  showPagination,
}) => {
  const navigate = useNavigate()
  const defaultCardAmount = 12
  /** We set the number to 12 because it plays nicely in all views with the flex layout. */
  const additionalMaximumAmountOfCardsToShow = showPagination
    ? additionalCardsAmount ?? defaultCardAmount
    : support.length

  const [teamPerPage, setTeamPerPage] = useState(
    additionalMaximumAmountOfCardsToShow
  )

  const handleViewMoreTeams = () => {
    setTeamPerPage(teamPerPage + additionalMaximumAmountOfCardsToShow)
  }

  const onClickShowProfile = (userKey: number) => {
    navigate(
      {
        pathname: `/admin/user-accounts/${userKey}`,
      },
      {
        /** Navigation options */
      }
    )
  }

  return (
    <section>
      <Card>
        <Box p={2}>
          {support.slice(0, teamPerPage).map((user, index) =>
            user.roles.map((role, roleIndex) => (
              <Box
                key={`${user.userKey}-${role.actorKey}`}
                data-testid={`teamMember-card-${index}`}
              >
                <Box py={3}>
                  <Box pb={4}>
                    <CardActionArea
                      aria-labelledby={ariaLabelledBy ?? ''}
                      onClick={
                        updateUserSelection &&
                        (() =>
                          updateUserSelection?.({
                            userKey: user.userKey,
                            name: fullName({
                              firstName: user.firstName ?? '',
                              lastName: user.lastName ?? '',
                            }),
                            actorKey: role.actorKey ?? -1,
                            role: role.name,
                          }))
                      }
                      style={{ display: 'flex', justifyContent: 'left' }}
                      key={`clickable-card-${role.actorKey}`}
                      disabled={!updateUserSelection}
                    >
                      <Box display="flex" pr={3}>
                        <UserAvatar
                          familyName={fullName({
                            firstName: user.firstName,
                            lastName: user.lastName,
                          })}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body1" component="p">
                          {`${user.firstName} ${user.lastName}`}
                        </Typography>
                        <Typography variant="body1" component="p">
                          {user.username}
                        </Typography>
                        <Typography
                          style={textTypesForRoles(role)}
                          variant="body1"
                          component="p"
                        >
                          {role.name}
                        </Typography>
                        {ariaLabelledBy === 'peopleISupport' && (
                          <>
                            <Typography
                              style={textTypesForRoles(role)}
                              variant="body1"
                              component="p"
                            >
                              {dateToSlashStringReinterpretedAsLocal(
                                role.validFrom
                              )}
                            </Typography>
                            <Typography
                              style={textTypesForRoles(role)}
                              variant="body1"
                              component="p"
                            >
                              {dateToSlashStringReinterpretedAsLocal(
                                role.validTo
                              )}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </CardActionArea>
                  </Box>
                  {updateUserSelection && (
                    <TextButton
                      id="view-profile"
                      onClick={() => onClickShowProfile(user.userKey)}
                      variant={TextButtonVariant.ViewProfile}
                      fullWidth
                    />
                  )}
                </Box>
                {(index !== support.length - 1 ||
                  roleIndex !== user.roles.length - 1) && <FormDivider />}
              </Box>
            ))
          )}
        </Box>
        {support.slice(0, teamPerPage).length < support.length &&
          showPagination && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              my={6}
            >
              <OutlinedButton
                data-testid="viewMoreButton"
                id="viewMoreDraftTeams"
                onClick={handleViewMoreTeams}
                variant={OutlinedButtonVariant.ViewMore}
              />
            </Box>
          )}
      </Card>
    </section>
  )
}

export default TeamCard
