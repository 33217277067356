/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { PropsWithChildren } from 'react'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.25, 0),
}))

interface ContactInfoListItemProps extends PropsWithChildren {
  icon?: React.ReactNode
  listItemText?: React.ReactNode
  secondaryText?: React.ReactNode
  to?: string
}

export const ContactInfoListItem: React.FunctionComponent<
  ContactInfoListItemProps
> = ({ icon, listItemText, secondaryText, children, to }) => {
  return (
    <StyledListItem>
      {icon ? icon : null}
      {icon ? (
        <Box mx={2}>
          <ListItemText
            disableTypography={true}
            primary={[listItemText, children]}
            secondary={secondaryText}
          />
        </Box>
      ) : (
        <ListItemText primary={[listItemText, children]} />
      )}
    </StyledListItem>
  )
}

export default ContactInfoListItem
