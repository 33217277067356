import { useEffect, useState } from 'react'
import {
  DynamicFieldEntries,
  DynamicFieldStates,
} from '../components/Interfaces/DynamicFieldStates'

interface Input {
  isInitialLoad: boolean
  setIsInitialLoad: (value: boolean) => void
  fields?: Array<DynamicFieldEntries>
}

export interface Output {
  fieldStates: DynamicFieldStates
  setFieldStates: (states: DynamicFieldStates) => void
}

// XXX: Maybe generic type the hook so we can pass it anything
export const useDynamicFieldStates = (props: Input): Output => {
  const { isInitialLoad, setIsInitialLoad, fields } = props

  const [fieldStates, setFieldStates] = useState<DynamicFieldStates>({})

  useEffect(() => {
    if (fields && fields.length > 0 && isInitialLoad) {
      let stateObject = {}
      fields.forEach((field) => {
        stateObject = {
          ...stateObject,
          [field.label]: field.state,
        }
      })

      setFieldStates((prevState) => ({ ...prevState, ...stateObject }))
      setIsInitialLoad(false)
    }
  }, [isInitialLoad, setIsInitialLoad, fields])

  return { fieldStates, setFieldStates }
}

export default useDynamicFieldStates
