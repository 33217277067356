import React, { useState, useRef } from 'react'
import FilterModal, { DropDownFilterOption } from './FilterModal'
import { Student, TranscriptParchmentRequestStatus } from '../../swagger'
import { TFunction, useTranslation } from 'react-i18next'
import { MenuOption } from '../Menus/DropDown'

interface TranscriptFilterModalProps {
  isOpen: boolean
  closeModal: () => void
  parseFilters: (output: DropDownFilterOption[]) => void
  students: Student[]
}

const getLabelForRequestStatus = (
  t: TFunction,
  status?: TranscriptParchmentRequestStatus
): string => {
  switch (status) {
    case TranscriptParchmentRequestStatus.NotRequested:
      return t('Transcript.FilterOption.NotRequested', 'Not Requested')
    case TranscriptParchmentRequestStatus.Requested:
      return t('Transcript.FilterOption.Requested', 'Requested')
    case TranscriptParchmentRequestStatus.Sent:
      return t('Transcript.FilterOption.Sent', 'Sent')
    default:
      return t('Transcript.FilterOption.AllStatuses', 'All Statuses')
  }
}

const TranscriptFilterModal: React.FC<TranscriptFilterModalProps> = ({
  isOpen,
  closeModal,
  parseFilters,
  students,
}) => {
  const { t } = useTranslation()

  const [transcriptFilterOptions, setTranscriptFilterOptions] = useState<
    DropDownFilterOption[]
  >([
    {
      label: t('Transcript.Filter.FilterByChild', 'Child Name'),
      id: 'filterChildName',
      menuOptions: [
        {
          name: t('Transcript.FilterOption.AllChildren', 'All Children'),
          id: 'allChildren',
        },
        ...students.map((student) => ({
          name: student.firstName,
          id: student.studentKey,
        })),
      ],
      value: t('Transcript.FilterOption.AllChildren', 'All Children'),
      valueId: 'allChildren',
    },
    {
      label: t('Transcript.Filter.FilterByStatus', 'Parchment Request Status'),
      id: 'filterParchmentRequestStatus',
      menuOptions: [
        {
          name: getLabelForRequestStatus(t),
          id: 'allStatuses',
        },
        ...Object.values(TranscriptParchmentRequestStatus).map(
          (status) =>
            ({
              name: getLabelForRequestStatus(t, status),
              id: status,
            } as MenuOption)
        ),
      ],
      value: getLabelForRequestStatus(t),
      valueId: 'allStatuses',
    },
  ])

  const defaultOptions = useRef(transcriptFilterOptions)

  const selectedOptions = useRef(transcriptFilterOptions)

  const onConfirm = () => {
    closeModal()

    selectedOptions.current = transcriptFilterOptions

    parseFilters(transcriptFilterOptions)
  }

  const clearFilters = () => {
    setTranscriptFilterOptions(defaultOptions.current)
  }

  const onCancel = () => {
    closeModal()

    setTranscriptFilterOptions(selectedOptions.current)
  }

  const handleSelection = (
    selection: string,
    id: string,
    selectedOptionId: string | number
  ) => {
    setTranscriptFilterOptions((prevSelectedOptions) => {
      return prevSelectedOptions.map((option) => {
        if (option.id === id) {
          return { ...option, value: selection, valueId: selectedOptionId }
        }
        return option
      })
    })
  }

  return (
    <FilterModal
      isOpen={isOpen}
      handleSelection={handleSelection}
      onConfirm={onConfirm}
      onClearAllFilters={clearFilters}
      onCancel={onCancel}
      modalTitle={t('Transcript.Filter.Label', 'Filter By')}
      filterOptions={transcriptFilterOptions}
    />
  )
}

export default TranscriptFilterModal
