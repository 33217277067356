import React, { ReactNode } from 'react'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { useTheme } from '@mui/material'

export enum IconTextButtonVariant {
  // FIXME: Change to Add Child
  AddAnotherChild = 'AddAnotherChild',
  LicensingFeeReport = 'Licensing Fee Report',
  Report = 'report',
  StudentAndProgram = 'studentAndProgram',
  Tutor = 'tutor',
  PrintInvoice = 'Print Invoice',
}

interface IconTextButtonProps {
  id: string
  variant: IconTextButtonVariant
  onClick: () => void
  startIcon?: ReactNode
  disabled?: boolean
}

const labelForVariant = (variant: IconTextButtonVariant, t: TFunction) => {
  // Use switch if component has more than one variant/use
  switch (variant) {
    case IconTextButtonVariant.AddAnotherChild:
      return t('Buttons.FormButtons.AddChild', 'Add Another Child')
    case IconTextButtonVariant.LicensingFeeReport:
      return t('Buttons.FormButtons.LicensingFeeReport', 'Licensing Fee Report')
    case IconTextButtonVariant.Report:
      return t('Buttons.FormButtons.Reports', 'Report')
    case IconTextButtonVariant.StudentAndProgram:
      return t('Buttons.FormButtons.StudentAndProgram', 'Student & Program')
    case IconTextButtonVariant.Tutor:
      return t('Buttons.FormButtons.Tutor', 'Tutor')
    case IconTextButtonVariant.PrintInvoice:
      return t('Buttons.FormButtons.PrintInvoice', 'Print Invoice')
  }
}

const IconTextButton: React.FC<IconTextButtonProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Button
      sx={{
        color: theme.palette.secondary.main,
      }}
      variant="text"
      startIcon={props.startIcon}
      onClick={props.onClick}
      id={props.id}
      disabled={props.disabled ?? false}
    >
      {labelForVariant(props.variant, t)}
    </Button>
  )
}

export default IconTextButton
