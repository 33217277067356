/**
 * Workaround YYYY-mm-DD strings parsing as UTC by forcibly relocating to YYY-mm-DD in local timezone.
 * @param date - `Date` that will be reinterpret
 * @param withoutTime -  `boolean` If you do not want time in the result
 * @param isUTC = `boolean` to get the day using getUTCDate or getDate
 * @result a date reinterpreted
 * */
export const reinterpretYearMonthDayAsLocalTime = (
  date: Date,
  withoutTime = false,
  isUTC = true
): Date => {
  const clone = new Date(date)
  clone.setFullYear(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    isUTC ? date.getUTCDate() : date.getDate()
  )
  if (withoutTime) {
    clone.setHours(0)
    clone.setMinutes(0)
    clone.setSeconds(0)
    clone.setMilliseconds(0)
  }
  return clone
}
