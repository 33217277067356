import { dateToSlashString } from './dateUtility'
import { toFormattedDateString } from './toFormattedDateString'

/** We bring in toFormattedDateString which converts yyyy-mm-dd to
 * full date (without time) ie 2021-08-13 --> Fri Aug 13 2021 */
export const displayFormattedDate = (
  startDate: string,
  endDate: string
): string => {
  return startDate === endDate
    ? toFormattedDateString(startDate)
    : /** We do this when we have a date range so that the start date 
    displays without the year and comma. */
      `${toFormattedDateString(startDate).slice(
        0,
        -6
      )} - ${toFormattedDateString(endDate)}`
}
export const displayFormattedSlashDate = (
  startDate: string,
  endDate: string
): string => {
  return startDate === endDate
    ? dateToSlashString(new Date(startDate), true)
    : /** We do this when we have a date range so that the start date 
    displays without the year and comma. */
      `${dateToSlashString(new Date(startDate), true)} - ${dateToSlashString(
        new Date(endDate),
        true
      )}`
}
