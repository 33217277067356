import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import TitleContext from '../../../TitleContext'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'
import RoleCard, { RoleCardVariants } from '../../Card/RoleCard'
import { CanAccess } from '../../Elements/Access'
import { Page } from '../../Elements/PageMargins'
import {
  meta,
  Permission,
  Role,
  extractedErrorObject,
} from '../../../api/swagger'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import EmptyRoles from './EmptyRoles'
import { useSnackbarContext } from '../../Context/SnackbarContext'

const RoleDetails: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const title = t('Roles.Title', 'Roles')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  const { roleKey } = useParams<{
    roleKey: string
  }>()

  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')

  const [permissions, setPermissions] = useState<Permission[]>([])
  const [role, setRole] = useState<Role>()

  /**
   * We use this to trigger the useEffect for fetching data programmatically.
   * The value itself is not significant, however when the value changes it will trigger the useEffect
   */
  const [triggerRefetch, setTriggerRefetch] = useState(false)

  /**
   * Fetch permissions and roles
   */
  useEffect(() => {
    const fetchPermissionsAndRoles = async () => {
      try {
        const emptyRequestParam = {}

        const [fetchedPermissions, fetchedRoles] = await Promise.all([
          meta.fetchPermissions(emptyRequestParam),
          meta.fetchRoles(emptyRequestParam),
        ])

        const foundRole = fetchedRoles.find(
          (role) => role.roleKey === +`${roleKey}`
        )

        if (!foundRole) {
          throw new Error(
            t(
              'Roles.RoleDetails.NoRoleFoundError',
              'No role was found with the specified key, or you do not have permission to view this role.'
            )
          )
        }

        setPermissions(fetchedPermissions)
        setRole(foundRole)
      } catch (err) {
        const errorObject = (await extractedErrorObject(err)) ?? {
          code: 'UnknownError',
          message:
            (err as unknown as Error).message ??
            'Failed to fetch region details.',
        }
        setErrorMessage(errorObject.message)
      } finally {
        setIsLoading(false)
      }
    }
    fetchPermissionsAndRoles()
  }, [roleKey, t, triggerRefetch])

  /**
   * If error fetching permissions and roles, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  const refetchPermissionsAndRolesData = () => {
    // Programmatically trigger a refetch of permissions and roles
    setTriggerRefetch((prevState) => !prevState)
  }

  const navigateToRoles = () => {
    navigate(
      {
        pathname: '/admin/roles',
      },
      {
        /** Navigation Options */
      }
    )
  }

  const breadcrumbs = [
    {
      label: t('Roles.BreadCrumb.Roles', 'Roles'),
      onClick: navigateToRoles,
    },
    {
      label: t('Roles.BreadCrumb.Edit', 'Edit {{- roleName}}', {
        roleName: role?.name ?? '',
      }),
    },
  ]

  return (
    <CanAccess I="view" on="Role">
      <Page>
        {!role || !permissions.length ? (
          <EmptyRoles isLoading={isLoading} />
        ) : (
          <>
            <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
            <RoleCard
              variant={RoleCardVariants.Edit}
              permissions={permissions}
              role={role}
              refetchPermissionsAndRolesData={refetchPermissionsAndRolesData}
            />
          </>
        )}
      </Page>
    </CanAccess>
  )
}

export default RoleDetails
