import { Box, Skeleton, useTheme } from '@mui/material'
import React from 'react'

export const PdfViewerSkeleton: React.FC = () => {
  const theme = useTheme()
  const buttonSkeleton = (
    <Skeleton
      variant="rectangular"
      sx={{
        width: '20px',
        height: '20px',
        backgroundColor: theme.palette.pdfViewerBackground,
      }}
    />
  )
  return (
    <Box aria-label="pdf-viewer-skeleton">
      <Box
        aria-label="pdf-viewer-skeleton-header"
        sx={{
          margin: 0,
          background: theme.palette.pdfViewerToolbar,
          height: 50,
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {buttonSkeleton}
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}
        >
          {buttonSkeleton}
          {buttonSkeleton}
          {buttonSkeleton}
        </Box>
      </Box>

      <Skeleton
        aria-label="pdf-viewer-skeleton-content"
        variant="rectangular"
        style={{
          height: 340,
          backgroundColor: theme.palette.pdfViewerBackground,
        }}
      />
    </Box>
  )
}
