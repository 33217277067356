import React, { useContext } from 'react'
import {
  TranscriptFormCard,
  TranscriptFormCardVariant,
} from './TranscriptFormCard'
import { Page } from '../Elements/PageMargins'
import { useTranscriptContext } from '../Context/TranscriptContext'
import { useFetchTranscript } from '../../hooks/useFetchTranscript'
import { useUnmountEffect } from '../../hooks/useUnmountEffect'
import { useParams } from 'react-router-dom'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useAuth } from '../Routes/AuthProvider'
import { CanAccess } from '../Elements/Access'
import { LoadingContext } from '../Context/LoadingContext'
import LoadingProgress from '../Elements/LoadingProgress'

export const TranscriptDetails: React.FC = () => {
  const {
    updateSelectedTranscriptKey,
    selectedTranscriptKey,
    resetContextToDefaults,
  } = useTranscriptContext()
  const { transcriptKey } = useParams<{ transcriptKey: string }>()
  const { userDetails } = useAuth()
  const { loadingId } = useFetchTranscript({
    transcriptKey: selectedTranscriptKey,
  })
  const { loadingIds } = useContext(LoadingContext)

  useMountEffect(() => {
    if (!!transcriptKey) {
      updateSelectedTranscriptKey(+transcriptKey)
    }
  })

  useUnmountEffect(() => {
    resetContextToDefaults()
  })

  if (loadingIds.has(loadingId)) {
    return <LoadingProgress />
  }

  return (
    <Page>
      {userDetails.actingAs === 'parent' ? (
        <TranscriptFormCard variant={TranscriptFormCardVariant.Edit} />
      ) : (
        /** If they can print, for now, they can view a transcript to print only that transcript. */
        <CanAccess I="print" on="Transcript">
          <TranscriptFormCard variant={TranscriptFormCardVariant.Edit} />
        </CanAccess>
      )}
    </Page>
  )
}

export default TranscriptDetails
