import React, { useMemo } from 'react'
import { AgreementRecipient } from '../../swagger'
import DynamicFormField from '../Elements/DynamicFormField'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import { useTranslation } from 'react-i18next'

interface FormRecipientsProps {
  formRecipients?: AgreementRecipient[]
  recipientStates: DynamicFieldStates
  updateRecipientValueForParent: (
    name: string,
    state: string,
    required: boolean,
    isOverMaxLength: boolean,
    isUnderMinLength: boolean
  ) => void
}

export const FormRecipients: React.FC<FormRecipientsProps> = ({
  formRecipients,
  recipientStates,
  updateRecipientValueForParent,
}) => {
  const { t } = useTranslation()
  const formRecipientsFields = useMemo(() => {
    if (!!formRecipients) {
      return formRecipients.map((recipient) => {
        const fieldId = `${recipient.label}`
        const fieldLabel = `${recipient.label} ${t(
          'Team.FormRecipients.Email',
          'Email'
        )}`
        return (
          <DynamicFormField
            key={recipient.label}
            id={fieldId}
            label={fieldLabel}
            name={fieldId}
            updateForParent={updateRecipientValueForParent}
            value={recipientStates?.[fieldId]?.state}
            isValid={recipientStates?.[fieldId]?.isValid}
            validationMessage={recipientStates?.[fieldId]?.validationMessage}
            required={true}
          />
        )
      })
    } else {
      return []
    }
  }, [formRecipients, updateRecipientValueForParent, recipientStates, t])

  return <>{formRecipientsFields?.map((it) => it)}</>
}

export default FormRecipients
