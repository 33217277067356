import React from 'react'
import { Document, Page } from '@react-pdf/renderer'
import { Html } from 'react-pdf-html'
import { renderToStaticMarkup } from 'react-dom/server'
import TranscriptHtmlMarkup from './TranscriptHtmlMarkup'
import { Transcript } from '../../../swagger'

interface TranscriptPDFProps {
  transcripts: Transcript[]
  actingAs: 'parent' | number
}

const TranscriptPDF: React.FC<TranscriptPDFProps> = ({
  transcripts,
  actingAs,
}) => {
  return (
    <Document>
      {transcripts.map((transcriptDetail) => (
        <Page key={transcriptDetail.transcriptKey}>
          <Html>
            {renderToStaticMarkup(
              <TranscriptHtmlMarkup
                transcriptDetails={transcriptDetail}
                actingAs={actingAs}
              />
            )}
          </Html>
        </Page>
      ))}
    </Document>
  )
}

export default TranscriptPDF
