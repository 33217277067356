import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserProfile } from '../../swagger'
import CardFormHeader from '../Card/CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import CountryDropDown, {
  initialCountrySelected,
} from '../Menus/CountryDropDown'
import { Box, Grid, Paper, useTheme, Typography } from '@mui/material'
import CountryInformationLinks from '../Buttons/CountryInformationLinks'

interface CountryInformationCardProps {
  user: UserProfile
  setUserInformation: (value: React.SetStateAction<UserProfile>) => void
  isEditMode: boolean
}

const CountryInformationCard: React.FC<CountryInformationCardProps> = ({
  user,
  setUserInformation,
  isEditMode,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const handleSelection = (name: string, id: string) => {
    if (id === 'countryOfCitizenship') {
      setUserInformation((currInfo) => ({
        ...currInfo,
        countryOfCitizenship: name,
      }))
    } else {
      setUserInformation((currInfo) => ({
        ...currInfo,
        countryOfResidence: name,
      }))
    }
  }
  const headerContentMain = t(
    'CountryInformation.Header.Content',
    'Enter your country of residence and country of citizenship. This information is collected to ensure your privacy and comply with privacy laws. For more information, please refer to:'
  )

  return (
    <Paper>
      <CardFormHeader
        headerContainerProps={{
          marginX: theme.spacing(4),
        }}
        header={
          <Header
            id="communityLocationHeader"
            headerName={t(
              'Settings.Profile.Location.Header',
              'Country Information'
            )}
            component="h2"
            variant={HeaderVariant.Card}
          />
        }
      />
      <Box
        sx={{
          padding: theme.spacing(0, 4, 5.5),
        }}
      >
        <Grid container spacing={3}>
          <Grid item container spacing={3}>
            <Grid item xs={12} md={3}>
              <CountryDropDown
                ids={['countryOfCitizenship', 'countryOfResidence']}
                labels={['Country of Citizenship', 'Country of Residence']}
                values={[
                  user.countryOfCitizenship ?? initialCountrySelected.name,
                  user.countryOfResidence ?? initialCountrySelected.name,
                ]}
                disabled={!isEditMode}
                handleSelection={handleSelection}
                styles={{
                  backgroundColor: theme.palette.background.paper,
                  textAlign: 'left',
                }}
                fullWidth
              />
              <Box
                sx={{
                  marginTop: theme.spacing(3),
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  component="p"
                  align="center"
                  m={theme.spacing(1)}
                >
                  {headerContentMain}
                </Typography>
                <CountryInformationLinks />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default CountryInformationCard
