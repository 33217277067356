import React, { ReactElement, ReactNode } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'
import { Typography, useTheme } from '@mui/material'
import Logo from '../../Images/Branding/cc-combination-blue.png'

export interface BasicModalProps {
  isOpen: boolean
  handleFormSubmit: (event: React.FormEvent<HTMLDivElement>) => void
  dialogContent: ReactNode
  dialogActions: ReactNode
  dialogTitle?: string
  afterClose?: () => void
  labelledBy?: string
  ariaLabel?: string
  maxWidth: false | 'xs' | 'md' | 'sm' | 'lg' | 'xl' | undefined
  useBrand?: boolean
  headerIcon?: ReactElement
  disableForm?: boolean
}

export const BasicModal: React.FC<BasicModalProps> = ({
  isOpen,
  handleFormSubmit,
  dialogContent,
  dialogActions,
  dialogTitle,
  afterClose,
  labelledBy = '',
  ariaLabel = '',
  maxWidth,
  useBrand = false,
  headerIcon,
  disableForm = false,
}) => {
  const theme = useTheme()

  // always calls but only does something when the SentEmail field is valid and disableForm = false
  const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (disableForm) {
      return
    }
    handleFormSubmit(event)
  }

  return (
    <Dialog
      aria-label={ariaLabel}
      aria-labelledby={labelledBy}
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      PaperProps={{
        /* 
        keeping the line below as reference to what we had before. Now we use the "component" prop to pass in the HTML element to be used as root node
        component: !disableForm ? 'form' : 'dialog',
        */
        // TODO: Investigate the form vs dialog in the Node update
        // component: !disableForm ? 'form' : 'dialog',
        'aria-autocomplete': 'none',
        'aria-labelledby': labelledBy,
        /**
         * Providing no onSubmit method will allow the Authorize.net fields
         * to be loaded during the PaymentModel EnterPaymentInformation step.
         * If the method exists for this step it will be called instead of
         * rendering the fields needed to process credit payments.
         */
        onSubmit: !disableForm ? handleSubmit : undefined,
      }}
      TransitionProps={{ onExited: afterClose }}
    >
      <Box
        py={2}
        px={{ xs: 2, lg: 3 }}
        component={!disableForm ? 'form' : 'div'}
      >
        <DialogTitle
          id={dialogTitle}
          sx={{
            color: theme.palette.primary.main,
            textAlign: 'center',
          }}
        >
          <Box>
            {useBrand && (
              <Box mb={3}>
                <Typography variant="h6" component="h1">
                  <img
                    style={{
                      width: '250px',
                    }}
                    alt="Classical Conversations"
                    src={Logo}
                  />
                </Typography>
              </Box>
            )}
            <Box>{headerIcon}</Box>
            {dialogTitle}
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflowY: 'unset' }}>
          {dialogContent}
        </DialogContent>
        <DialogActions disableSpacing>{dialogActions}</DialogActions>
      </Box>
    </Dialog>
  )
}

export default BasicModal
