import React, { useState } from 'react'
import { useUser } from '../../../UserContext'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../../Table/ActionableTable'
import { useTranslation } from 'react-i18next'
import { GridValueFormatterParams } from '@mui/x-data-grid'
import { Action } from '../../Table/RowActions'
import { UserCommunity } from '../../../swagger/models/UserCommunity'
import { UTCYearString } from '../../../utils/dateUtility'
import Card from '@mui/material/Card'
import Header, { HeaderVariant } from '../../Elements/Header'
import CardFormHeader from '../../Card/CardFormHeader'
import { OrderByDirection, PaginationResponse } from '../../../swagger'
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/Info'

interface UserRole extends UserCommunity {
  supervisor?: string
  activeYear?: string
  actorKey: number
}

export const LicensingBillingExpiredUserRolesTable: React.FC = () => {
  const { t } = useTranslation()
  const { uniqueInactiveUserRoles } = useUser()
  const pageSize = DEFAULT_PAGE_SIZE
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pageSize)
  const navigate = useNavigate()

  const navigateToBillingHistory = (actorKey: number) => {
    navigate({ pathname: `/account/billing/history/${actorKey}` })
  }

  const roles = (uniqueInactiveUserRoles as unknown as UserRole[]).map(
    (userRole) => {
      userRole.supervisor = `${userRole.supervisorFirstName} ${userRole.supervisorLastName}`
      userRole.activeYear =
        userRole.validFrom && userRole.validTo
          ? `${UTCYearString(userRole.validFrom)}-${UTCYearString(
              userRole.validTo
            )}`
          : ''

      return userRole
    }
  ) as unknown as UserRole[]

  const tableData =
    rowsPerPage > 0
      ? roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : roles
  const handlePaginationChange = (pagination: PaginationResponse) => {
    setPage(pagination.page)
    setRowsPerPage(pagination.pageSize)
  }

  const actionableTableColumnsHeader: ActionableTableColumn[] = [
    {
      fieldName: 'actorKey',
      columnHeaderName: t(
        'LicensingBillingExpiredUserRoles.Table.Header.Id',
        'Id'
      ),
    },
    {
      fieldName: 'role',
      columnHeaderName: t(
        'LicensingBillingExpiredUserRolesTable.Header.roleName',
        'Role'
      ),
    },
    {
      fieldName: 'community',
      columnHeaderName: t(
        'LicensingBillingExpiredUserRoles.TableHeader.community',
        'Community'
      ),
    },
    {
      fieldName: 'supervisor',
      columnHeaderName: t(
        'LicensingBillingExpiredUserRoles.Table.Header.supervisor',
        'Supervisor'
      ),
    },
    {
      fieldName: 'region',
      columnHeaderName: t(
        'LicensingBillingExpiredUserRoles.Table.Header.regionName',
        'Region Name'
      ),
    },
    {
      fieldName: 'activeYear',
      columnHeaderName: t(
        'LicensingBillingExpiredUserRoles.Table.Header.activeYear',
        'Active Year'
      ),
    },
    {
      fieldName: 'validNow',
      columnHeaderName: t(
        'LicensingBillingExpiredUserRoles.Table.Header.active',
        'Active'
      ),
      valueFormatter: (
        params: GridValueFormatterParams<string | undefined>
      ) => {
        return params.value ? 'Yes' : 'No'
      },
      align: 'center',
      headerAlign: 'center',
    },
  ]

  const rowActions: Action<UserRole>[] = [
    {
      actionName: t(
        'LicensingBillingExpiredUserRoles.Table.RowAction.ViewMore',
        'View More'
      ),
      actionKey: 'View More',
      actionFunction: (row) => navigateToBillingHistory(row?.actorKey),
      menuItemIcon: <InfoIcon />,
    },
  ]

  return (
    <Card>
      <CardFormHeader
        header={
          <Box>
            <Header
              id="rolesTableHeader"
              headerName={t(
                'LicensingBillingExpiredUserRoles.Header.Roles',
                'My Roles'
              )}
              component="h3"
              variant={HeaderVariant.Card}
            />
          </Box>
        }
      />
      <ActionableTable
        columns={actionableTableColumnsHeader}
        rows={tableData}
        noResultsMessage={t(
          'LicensingBilling.UserRoles.Table.NoUserRoles',
          'The logged-in user does not have an assigned role.'
        )}
        rowActions={rowActions}
        pagination={{
          page,
          pageSize,
          totalCount: roles.length,
          orderBy: [{ activeYear: OrderByDirection.Asc }],
        }}
        handlePaginationChange={handlePaginationChange}
      />
    </Card>
  )
}
