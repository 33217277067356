import { Button } from '@mui/material'
import React, { CSSProperties, ReactElement } from 'react'
import { To } from 'react-router'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { LoadingContext } from '../Context/LoadingContext'
import { styled } from '@mui/system'

interface BaseButtonProps {
  id: string
  label: string
  variant: 'text' | 'outlined' | 'contained' | undefined
  css?: CSSProperties
  fullWidth?: boolean
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  to?: To
  state?: { params: string }
  startIcon?: ReactElement
  color?: 'primary' | 'secondary'
  /**
   * Unique Id denoting which LoadingContext loading id this button is awaiting.
   * Only required when needing to disable the button while an async function
   * is being awaited.
   */
  loadingId?: string
  /** The download prop's assignment. Currently used for downloading a CSV */
  download?: unknown
  /** Allow a button to act like a link. Combined with download means it'll download the object from the url*/
  href?: string
}

const LoadingIndicator = styled(CircularProgress)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: '-12px',
  marginLeft: '-12px',
})

/**
 * Use in conjunction with the LoadingProvider of LoadingContext and whenever we're clicking,
 * update the loading context value isLoading so this button disables until the async method
 * resolves.
 */
const BaseButton: React.FC<BaseButtonProps> = ({
  id,
  label,
  variant,
  css,
  fullWidth,
  type,
  disabled,
  to = undefined,
  startIcon = null,
  color,
  loadingId,
  state,
  download,
  href,
}) => {
  const { loadingIds, addLoadingIds } = React.useContext(LoadingContext)

  /** Yes, the type looks silly but without the type, typescript complains that it's of type `any` */
  const handleClick: () => void = () => {
    if (!!loadingId) {
      addLoadingIds([loadingId])
    }
  }

  return (
    <Button
      id={id}
      color={color}
      variant={variant}
      onClick={handleClick}
      sx={{ ...css }}
      fullWidth={fullWidth}
      type={type}
      disabled={disabled || (!!loadingId && loadingIds.has(loadingId))}
      component={!!to ? Link : 'button'}
      to={to}
      state={state}
      startIcon={startIcon}
      data-testid={'base-button'}
      download={download}
      href={href}
    >
      {label}
      {!!loadingId && loadingIds.has(loadingId) && (
        <LoadingIndicator size={24} />
      )}
    </Button>
  )
}

export default BaseButton
