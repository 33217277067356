import React, { FC } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

type CustomTooltipProps = TooltipProps & { variant: 'default' }

type InfoTooltipProps = {
  variant: 'info'
  message: string
}

type TooltipUnionProps = CustomTooltipProps | InfoTooltipProps

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.textOrIcon.mapButton,
    color: theme.palette.text.primary,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.borderColor.grey}`,
  },
}))

const InfoTooltip: FC<{ message: string }> = ({ message }) => {
  const theme = useTheme()
  return (
    <Tooltip title={message}>
      <InfoIcon
        aria-label="tooltipIcon"
        sx={{
          fontSize: '24px',
          color: theme.palette.textOrIcon.tooltipButton,
        }}
      />
    </Tooltip>
  )
}

/**
 *
 * This component supports two tooltip variants: 'default' and 'info'.
 *
 * - 'default': A styled tooltip (`LightTooltip`) that accepts regular MUI `Tooltip` props
 *   and renders the tooltip around its children.
 * - 'info': A tooltip that displays an `InfoIcon` with a message.
 *
 * Usage examples:
 *
 * -- Default variant with a styled tooltip:
 *
 * ```js
 * <CustomTooltip variant="default" title="This is a default tooltip">
 *   <button>Hover me</button>
 * </CustomTooltip>
 * ```
 *
 * -- Info variant with an InfoIcon and a custom message:
 *
 * ```js
 * <CustomTooltip variant="info" message="This is an info tooltip" />
 * ```
 *
 * Props:
 * - `variant`: Specifies the type of tooltip to display ('default' or 'info').
 * - `title`: Tooltip content for the 'default' variant.
 * - `message`: Message for the 'info' variant.
 */
const CustomTooltip: FC<TooltipUnionProps> = (props) => {
  if (props.variant === 'info') {
    return <InfoTooltip message={props.message} />
  }

  return (
    <LightTooltip title={props.title}>
      <span>{props.children}</span>
    </LightTooltip>
  )
}

export default CustomTooltip
