/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface PublicCommunityLocation
 */
export interface PublicCommunityLocation {
  /**
   *
   * @type {number}
   * @memberof PublicCommunityLocation
   */
  distanceMiles: number
  /**
   *
   * @type {string}
   * @memberof PublicCommunityLocation
   */
  city: string
  /**
   *
   * @type {string}
   * @memberof PublicCommunityLocation
   */
  state?: string
  /**
   *
   * @type {string}
   * @memberof PublicCommunityLocation
   */
  countryCode?: string
  /**
   *
   * @type {string}
   * @memberof PublicCommunityLocation
   */
  postalCode?: string
}

export function PublicCommunityLocationFromJSON(
  json: any
): PublicCommunityLocation {
  return PublicCommunityLocationFromJSONTyped(json, false)
}

export function PublicCommunityLocationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublicCommunityLocation {
  if (json === undefined || json === null) {
    return json
  }
  return {
    distanceMiles: json['distanceMiles'],
    city: json['city'],
    state: !exists(json, 'state') ? undefined : json['state'],
    countryCode: !exists(json, 'countryCode') ? undefined : json['countryCode'],
    postalCode: !exists(json, 'postalCode') ? undefined : json['postalCode'],
  }
}

export function PublicCommunityLocationToJSON(
  value?: PublicCommunityLocation | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    distanceMiles: value.distanceMiles,
    city: value.city,
    state: value.state,
    countryCode: value.countryCode,
    postalCode: value.postalCode,
  }
}
