import React, { useState } from 'react'
import BrandCard from './BrandCard'
import { useTranslation } from 'react-i18next'
import CountryDropDown, {
  initialCountrySelected,
} from '../Menus/CountryDropDown'
import ErrorAlert from '../Alerts/ErrorAlert'
import { Country, extractedErrorObject } from '../../api/swagger'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import useLoadingContext from '../../hooks/useLoadingContext'
import { LoadingApiCall } from '../../api/LoadingApiCall'
import { useAuth } from '../Routes/AuthProvider'
import { updateUserProfile } from '../../api/user'
import { Box, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import CountryInformationLinks from '../Buttons/CountryInformationLinks'
import { LoadingContext } from '../Context/LoadingContext'

const heightOfEnterNameCard = 700
interface CountryInformationProps {
  additionalHeaderContent?: JSX.Element
  handleNext?: () => void
  handleCallback?: () => void
  handleBack?: () => void
  excludeBack?: boolean
  containedButtonVariant?: ContainedButtonVariant
}

const CountryInformation: React.FC<CountryInformationProps> = (props) => {
  const navigate = useNavigate()
  const auth = useAuth()
  const { addLoadingIds } = React.useContext(LoadingContext)
  const { defaultAccountPath } = auth

  /**
   * Provide default values for the case of rendering directly via the router on
   * App.tsx. Can't really wrap App in a router, which useNavigate requires.
   *
   */
  const defaultNext = () => {
    addLoadingIds([loadingId])
  }

  const defaultBack = async () => {
    await auth.signout()
    navigate({ pathname: '/login' })
  }

  const defaultCallback = () => {
    navigate({ pathname: defaultAccountPath })
  }

  const {
    additionalHeaderContent,
    handleNext = defaultNext,
    handleBack = defaultBack,
    excludeBack = false,
    containedButtonVariant = ContainedButtonVariant.Next,
    handleCallback = defaultCallback,
  } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const { isLoggedIn, userDetails, persistedLoginStateDidChange } = useAuth()

  const loadingId = `${LoadingApiCall.countryInformation}CountryInformation`
  const [citizenship, setCitizenship] = useState<Country>(
    initialCountrySelected
  )
  const [residence, setResidence] = useState<Country>(initialCountrySelected)

  const [error, setError] = useState<string>()

  const handleSelection = (
    name: string,
    id: string,
    countryCode: string | number
  ) => {
    if (id === 'citizenship') {
      setCitizenship({ name, countryCode: `${countryCode}` })
    } else {
      setResidence({ name, countryCode: `${countryCode}` })
    }
  }

  const handleClick = async () => {
    try {
      if (isLoggedIn && userDetails.userKey) {
        await updateUserProfile({
          id: userDetails.userKey,
          countryOfResidence: residence.name,
          countryOfCitizenship: citizenship.name,
        })
        localStorage.setItem(
          'countryInformation',
          JSON.stringify({
            countryOfCitizenship: citizenship.name,
            countryOfResidence: residence.name,
          })
        )
        persistedLoginStateDidChange()
        handleNext()
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error)?.message ??
          t(
            'CountryInformation.Submit.Error',
            'Something went wrong while updating the user profile'
          ),
      }
      setError(errorObject.message)
    }
  }

  useLoadingContext({
    asyncFunction: handleClick,
    loadingId: loadingId,
    callback: handleCallback,
  })

  const headerContentMain = t(
    'CountryInformation.Header.Content',
    'Enter your country of residence and country of citizenship. This information is collected to ensure your privacy and comply with privacy laws. For more information, please refer to:'
  )
  return (
    <BrandCard
      minimumScreenHeight={heightOfEnterNameCard}
      additionalHeaderContent={
        <>
          {additionalHeaderContent}
          {!!error && <ErrorAlert error={error} />}
          <Box m={theme.spacing(4)}>
            <Typography
              variant="body1"
              component="h3"
              align="center"
              sx={{
                fontWeight: 700,
                fontSize: '1.2rem',
                fontFamily: 'Arial',
              }}
            >
              {t(
                'CountryInformation.HeaderContent.Main',
                'Account Information'
              )}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              align="center"
              m={theme.spacing(1)}
            >
              {headerContentMain}
            </Typography>
          </Box>
          <CountryInformationLinks />
        </>
      }
      cardContent={
        <CountryDropDown
          ids={['citizenship', 'residence']}
          labels={['Country of Citizenship', 'Country of Residence']}
          values={[citizenship.name, residence.name]}
          handleSelection={handleSelection}
          fullWidth
        />
      }
      cardActions={
        <>
          <ContainedButton
            id="nextCountryInfo"
            variant={containedButtonVariant}
            css={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              flexGrow: 1,
              width: '96.75%',
              padding: theme.spacing(1.5),
              color: 'white',
            }}
            loadingId={loadingId}
            onClick={handleNext}
          />
          {!excludeBack && (
            <TextButton
              id="backCountryInfo"
              variant={TextButtonVariant.Back}
              onClick={handleBack}
              css={{
                width: '96.75%',
              }}
              fullWidth
            />
          )}
        </>
      }
    />
  )
}

export default CountryInformation
