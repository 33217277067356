import { useEffect } from 'react'

declare global {
  interface Window {
    Beacon: (
      command: string,
      options?:
        | string
        | undefined
        | { name: string; email: string; subject: string; text: string }
    ) => void
  }
}

/* Define Help Scout's Beacon Embed. Link to customize Beacon: 
https://secure.helpscout.net/settings/beacons/1a63fe7d-54f7-4b55-a959-0dcad998690f/customize */
export const embedBeacon = (BEACON_ID: string | undefined): void => {
  window.Beacon('init', BEACON_ID)
}

// Define Help Scout's Beacon Reset
export const resetBeacon = (): void => {
  window.Beacon('reset')
}

// Define Help Scout's Beacon Open
export const openBeacon = (): void => {
  window.Beacon('open')
}

// Define Help Scout's Beacon Prefill
export const prefillBeacon = (
  name: string,
  subject: string,
  email: string,
  text: string
): void => {
  window.Beacon('prefill', {
    name,
    subject,
    email,
    text,
  })
}

// Define Help Scout's Beacon Destroy
export const destroyBeacon = (): void => {
  window.Beacon('destroy')
}

export const useBeacon = (beaconId: string | undefined): void => {
  useEffect(() => {
    resetBeacon()
    embedBeacon(beaconId)

    return () => {
      destroyBeacon()
    }
  }, [beaconId])
}
