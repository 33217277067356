import React, { CSSProperties } from 'react'
import Typography from '@mui/material/Typography'
import PlayCircleFilled from '@mui/icons-material/PlayCircleFilled'
import BookIcon from '@mui/icons-material/Book'
import AudioTrackIcon from '@mui/icons-material/Audiotrack'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import GamepadIcon from '@mui/icons-material/Gamepad'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { ReactComponent as ExcelIcon } from '../../Images/excel-logo.svg'
import { ReactComponent as PowerPointIcon } from '../../Images/powerpoint-logo.svg'
import { useTranslation } from 'react-i18next'

export enum FileType {
  Video = 'Video',
  EBook = 'ebook',
  PDF = 'PDF',
  Document = 'Document',
  InteractiveTutorial = 'Interactive Tutorial',
  LearningPath = 'Learning Path',
  Audio = 'Audio',
  OutsideContent = 'Outside Content',
  PPT = 'PPT',
  Excel = 'Excel',
}

interface BaseProps {
  fileType: FileType | null | undefined
}

interface FileTypeIconProps extends BaseProps {
  large?: boolean
}

export const FileTypeIcon: React.FC<FileTypeIconProps> = ({
  fileType,
  large = false,
}) => {
  if (!fileType) return null

  const sharedStyles = { ...(large && { fontSize: 80 }) }

  const resourceMap = {
    [FileType.Video]: <PlayCircleFilled sx={sharedStyles} />,
    [FileType.EBook]: <BookIcon sx={sharedStyles} />,
    [FileType.PDF]: <InsertDriveFileIcon sx={sharedStyles} />,
    [FileType.Document]: <InsertDriveFileIcon sx={sharedStyles} />,
    [FileType.InteractiveTutorial]: <GamepadIcon sx={sharedStyles} />,
    [FileType.LearningPath]: <LocalLibraryIcon sx={sharedStyles} />,
    [FileType.Audio]: <AudioTrackIcon sx={sharedStyles} />,
    [FileType.OutsideContent]: <OpenInNew sx={sharedStyles} />,
    [FileType.PPT]: (
      <PowerPointIcon width={large ? 80 : 21} height={large ? 80 : 21} />
    ),
    [FileType.Excel]: (
      <ExcelIcon width={large ? 80 : 21} height={large ? 80 : 21} />
    ),
  }

  try {
    return resourceMap[fileType] ?? null
  } catch {
    return null
  }
}

interface FileTypeLabelProps extends BaseProps {
  css: CSSProperties
}

export const FileTypeLabel: React.FC<FileTypeLabelProps> = ({
  fileType,
  css,
}) => {
  const { t } = useTranslation()

  if (!fileType) return null

  const labelMap = {
    [FileType.Video]: t('Content.FileType.Video', 'Video'),
    [FileType.EBook]: t('Content.FileType.EBook', 'EBook'),
    [FileType.PDF]: t('Content.FileType.PDF', 'PDF'),
    [FileType.Document]: t('Content.FileType.Document', 'Document'),
    [FileType.InteractiveTutorial]: t(
      'Content.FileType.InteractiveTutorial',
      'Interactive Tutorial'
    ),
    [FileType.LearningPath]: t(
      'Content.FileType.LearningPath',
      'Learning Path'
    ),
    [FileType.Audio]: t('Content.FileType.Audio', 'Audio'),
    [FileType.OutsideContent]: t(
      'Content.FileType.OutsideContent',
      'Outside Content'
    ),
    [FileType.PPT]: t('Content.FileType.PPT', 'PPT'),
    [FileType.Excel]: t('Content.FileType.Excel', 'Excel'),
  }

  try {
    return (
      <Typography sx={{ ...css }} variant="button" component="p">
        {labelMap[fileType]}
      </Typography>
    )
  } catch {
    return null
  }
}
