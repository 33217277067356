import React, { CSSProperties } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import {
  FileType,
  FileTypeIcon,
  FileTypeLabel,
} from '../Elements/FileTypeResource'
import { useNavigate } from 'react-router'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FavoriteButton from '../Buttons/FavoriteButton'
import ShareLinkButton from '../Buttons/ShareLinkButton'
import DownloadButton from '../Buttons/DownloadButton'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

const BusinessContentCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(1.25),
}))

const BusinessContentCardActions = styled(CardActions)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'space-between',
}))

const StyledBusinessContentCard = styled(Card)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 340,
  margin: theme.spacing(1),
  [theme.breakpoints.down('xs')]: {
    width: '100%',
    maxWidth: 340,
    margin: 0,
    marginTop: theme.spacing(2),
    '&:nth-of-type(1)': {
      marginTop: 0,
    },
  },
}))

interface BusinessContentCardProps {
  widenAssetKey: number
  fileType: FileType
  subtitle?: string
  title: string
  description: string
  isLearningPath: boolean
  routerState?: { assetKeysInLearningPath: string[] }
  isFavorite: boolean
  viewOnly: boolean
  isNdaRequired: boolean
  ndaAcknowledged: boolean
  isFavoriteButtonDisabled?: boolean
  handleFavoriteButtonClicked?: () => void
}

const BusinessContentCard: React.FC<BusinessContentCardProps> = (props) => {
  const navigate = useNavigate()
  const {
    widenAssetKey,
    fileType,
    title,
    description,
    isLearningPath,
    routerState,
    isFavorite,
    viewOnly,
    isNdaRequired,
    ndaAcknowledged,
    isFavoriteButtonDisabled,
    handleFavoriteButtonClicked,
  } = props
  const theme = useTheme()
  const { t } = useTranslation()

  const toContentPage = (assetKey: number) => {
    if (isNdaRequired && !ndaAcknowledged) {
      navigate(
        {
          pathname: `/business/nda/${assetKey}`,
        },
        {
          /** Navigation Options */
        }
      )
    } else {
      navigate(
        {
          pathname: `/business/${
            isLearningPath ? 'learning-path' : 'business-content'
          }/${assetKey}`,
        },
        {
          /** Navigation Options */
          state: routerState,
        }
      )
    }
  }

  const sharedStyles = {
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
  } as { [k: string]: unknown } as CSSProperties

  return (
    <StyledBusinessContentCard data-testid="businessCard">
      <CardActionArea onClick={() => toContentPage(widenAssetKey)}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          padding={2}
          bgcolor={isLearningPath ? 'customBackground.logo' : 'initial'}
        >
          <Box
            width={56}
            height={56}
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#fff"
            bgcolor={isLearningPath ? 'textOrIcon.helperText' : 'primary.main'}
          >
            <FileTypeIcon fileType={fileType} />
          </Box>
          <FileTypeLabel
            fileType={fileType}
            css={{
              color: theme.palette.primary.dark,
            }}
          />
        </Box>

        <BusinessContentCardContent>
          <Typography
            sx={{
              ...sharedStyles,
              marginBottom: theme.spacing(0.5),
              /**
               * Set the height to twice the text height to maintain proper spacing.
               * Calculate height using: 2 * lineHeight * fontSize
               */
              height: 'calc(2 * 1.6 * 1.25rem)',
            }}
            variant="h6"
            component="h2"
          >
            {title}
          </Typography>

          <Typography
            sx={{
              ...sharedStyles,
              color: theme.palette.primary.dark,
              margin: theme.spacing(2, 0, 0),
              /**
               * Set the height to twice the text height to maintain proper spacing.
               * Calculate height using: 2 * lineHeight * fontSize
               */
              height: 'calc(2 * 1.5 * 1rem)',
            }}
            variant="body1"
            component="p"
          >
            {description}
          </Typography>
        </BusinessContentCardContent>
      </CardActionArea>

      <Divider />

      <BusinessContentCardActions>
        <Box>
          {!viewOnly && !isLearningPath && (
            <DownloadButton assetKey={widenAssetKey} />
          )}
          <ShareLinkButton assetKey={widenAssetKey} {...props} />
        </Box>
        {!isLearningPath && (
          <FavoriteButton
            isFavorited={isFavorite ?? false}
            assetKey={widenAssetKey}
            disabled={isFavoriteButtonDisabled}
            handleFavoriteButtonClicked={handleFavoriteButtonClicked}
            tooltipOptions={{
              showTooltip: !!isFavoriteButtonDisabled,
              title: t(
                'Favorite.Button.Tooltip',
                'To enable favoriting this asset, un mark all other favorites in this Learning Path.'
              ),
            }}
          />
        )}
      </BusinessContentCardActions>
    </StyledBusinessContentCard>
  )
}

export default BusinessContentCard
