import React, { LegacyRef, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import { parse } from 'querystring'

declare global {
  interface Window {
    AuthorizeNetIFrame?: {
      onReceiveCommunication: (queryStr: string) => Promise<void>
    }
  }
}

export type TransactionResponse = {
  accountNumber: string
  accountType:
    | 'Visa'
    | 'MasterCard'
    | 'Discover'
    | 'AmericanExpress'
    | 'DinersClub'
    | 'JCB'
    | 'eCheck'

  authCode: string
  avsResultCode:
    | 'A'
    | 'B'
    | 'E'
    | 'G'
    | 'N'
    | 'P'
    | 'R'
    | 'S'
    | 'U'
    | 'W'
    | 'X'
    | 'Y'
    | 'Z'
  cvvResultCode: 'M' | 'N' | 'P' | 'S' | 'U'
  errors?: ReadonlyArray<{ errorCode: string; errorText: string }>
  messages: ReadonlyArray<{ code: string; description: string }>
  responseCode: '1' | '2' | '3' | '4'
  transId: string
  refId: string
}

type Message =
  | {
      action: 'resizeWindow'
      width: number
      height: number
    }
  | {
      action: 'cancel'
    }
  | {
      action: 'transactResponse'
      response: string
    }

function isValidTransaction(o: unknown): o is TransactionResponse {
  return o !== null && o !== undefined && typeof o === 'object'
}

interface AcceptHostedProps {
  formToken: string
  formRef: LegacyRef<HTMLFormElement>

  onReceiveCommunication?: (queryStr: string) => Promise<void>
  onMessage?: (message: Message) => Promise<void>

  onCancel: () => Promise<void>
  onResize?: (width: number, height: number) => Promise<void>
  onTransact: (response: TransactionResponse) => void
}

const IFrameBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1',
  width: '400px', // Set at 400px to force the contained document media query to stack (below 450px)
  height: '1000px', // Give enough room to display the content of the iframe without inward scrolling
  overflow: 'hidden',
  position: 'relative',
  margin: 'auto',
  [theme.breakpoints.down(500)]: {
    maxWidth: '350px',
  },
}))

const IFrameWrapper = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  [theme.breakpoints.down(500)]: {
    marginLeft: '-42px', // Adjusts the iframe to fit better on the screen. This essentially negates some of the padding/margin present within the iframe.
  },
}))

export const AcceptHosted: React.FC<AcceptHostedProps> = ({
  formToken,
  formRef,
  onCancel: onCancelFromParent,
  onTransact: onTransactFromParent,
}) => {
  const onCancel = useCallback(onCancelFromParent, [onCancelFromParent])
  const onTransact = useCallback(onTransactFromParent, [onTransactFromParent])

  useEffect(() => {
    window.AuthorizeNetIFrame = {
      onReceiveCommunication: async (querystr) => {
        const params = parse(querystr)
        const ifrm = document.getElementById('add_payment')
        if (!ifrm) {
          return
        }

        switch (params.action) {
          case 'successfulSave':
            break
          case 'cancel':
            await onCancel()
            break
          case 'resizeWindow':
            const w = parseInt(`${params.width}`)
            const h = parseInt(`${params.height}`)
            ifrm.style.width = w.toString() + 'px'
            ifrm.style.height = h.toString() + 'px'
            break
          case 'transactResponse':
            const json = JSON.parse(params.response as string)
            if (isValidTransaction(json)) {
              onTransact && (await onTransact(json))
            }
            ifrm.style.display = 'none'
        }
      },
    }
    return () => {
      delete window.AuthorizeNetIFrame
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submittableReference = formRef as React.RefObject<HTMLFormElement>
  useEffect(() => {
    submittableReference?.current?.submit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IFrameBox>
      <IFrameWrapper id="iframe_holder">
        <iframe
          id="add_payment"
          name="add_payment"
          title="add_payment"
          width="100%"
          height="100%"
        />
      </IFrameWrapper>
      <form
        id="send_token"
        action={process.env.REACT_APP_AUTHORIZE_NET_URL}
        method="post"
        target="add_payment"
        ref={formRef}
        onSubmit={(event) => event?.preventDefault()}
      >
        <input type="hidden" name="token" value={formToken} />
      </form>
    </IFrameBox>
  )
}

export default AcceptHosted
