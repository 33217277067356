import React from 'react'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import ContentOverviewCard, {
  ContentOverviewProps,
} from './ContentOverviewCard'
import EpubViewer from '../Viewer/EpubViewer'
import { FileType } from '../Elements/FileTypeResource'
import ShowFullScreenButton from '../Buttons/ShowFullScreenButton'
import { styled } from '@mui/system'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useTheme } from '@mui/material'
import { useParams } from 'react-router'

const DividerWithMargin = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

interface ContentDetailsProps extends ContentOverviewProps {
  filetype: FileType | null
  viewOnly: boolean
  embedUrl: string | undefined
  showFileByType?: string
}

const ContentDetailsCard: React.FunctionComponent<ContentDetailsProps> = ({
  filetype,
  embedUrl,
  handleFavoriteButtonClicked,
  ...rest
}) => {
  const theme = useTheme()
  const isDesktop = useShowOnDesktop()
  
  const { academicYear } = useParams<{
    academicYear:string
  }>()
  const dontShowIFrame = filetype === FileType.EBook

  const cardMedia = dontShowIFrame ? (
    <Box border={1} borderColor="primary.main">
      <EpubViewer title={rest.title ?? ''} url={embedUrl ?? ''} />
    </Box>
  ) : (
    <Box display="flex" flex="1" height={!isDesktop ? 250 : 600}>
      <CardMedia
        component="iframe"
        src={embedUrl}
        allowFullScreen={true}
        title={rest.title}
      />
    </Box>
  )

  return (
    <Card>
      <Box p={{ xs: 2, sm: 4 }}>
        <ContentOverviewCard
          {...rest}
          handleFavoriteButtonClicked={handleFavoriteButtonClicked}
          mobileMedia={!isDesktop ? cardMedia : undefined}
        />
        <ShowFullScreenButton
          assetKey={rest.assetKey}
          academicYear={academicYear}
          buttonContainerStyles={{
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              justifyContent: 'center',
            },
          }}
        />
        {isDesktop && (
          <>
            <DividerWithMargin />
            {cardMedia}
          </>
        )}
      </Box>
    </Card>
  )
}

export default ContentDetailsCard
