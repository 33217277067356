import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyPage from '../Elements/EmptyPage'

interface EmptyBusinessProps {
  isLoading: boolean
}

const EmptyBusiness: React.FunctionComponent<EmptyBusinessProps> = (props) => {
  const { t } = useTranslation()

  return (
    <EmptyPage
      {...props}
      message={
        <Typography variant="subtitle1" component="p">
          {t(
            'Business.EmptyState.Instructions',
            `There is currently no business content to view.`
          )}
        </Typography>
      }
    />
  )
}

export default EmptyBusiness
