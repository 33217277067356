import React from 'react'
import UserAccountsSummaryTable from './UserAccountsSummaryTable'
import { useLocation } from 'react-router'
import UserAccount from './UserAccount'
import SearchBar from '../../Search/SearchBar'
import DropDown, { DropDownVariant } from '../../Menus/DropDown'
import { OrderByDirection, Role, UserAccountListing } from '../../../swagger'
import { useTranslation } from 'react-i18next'
import { buildFilterOptions } from '../../../utils/buildFilterOptions'
import { TFunction } from 'i18next'
import { Page } from '../../Elements/PageMargins'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import { BlueHelpIcon, LegenWaitForItDary } from '../Roles/Roles'
import { useTheme } from '@mui/material'

export enum UserAccountSortOptions {
  LastNameASC = 'Sort By: Last Name (ASCENDING)',
  LastNameDESC = 'Sort By: Last Name (DESCENDING)',
  FirstNameASC = 'Sort By: First Name (ASCENDING)',
  FirstNameDESC = 'Sort By: First Name (DESCENDING)',
  UserNameASC = 'Sort By: User Name (ASCENDING)',
  UserNameDESC = 'Sort By: User Name (DESCENDING)',
}

export enum UserAccountSortBy {
  FirstNameASC = 'firstName ASC',
  FirstNameDESC = 'firstName DESC',
  LastNameASC = 'lastName ASC',
  LastNameDESC = 'lastName DESC',
  UserNameASC = 'username ASC',
  UserNameDESC = 'username DESC',
}

const labelForSortOption = (
  sortOption: UserAccountSortOptions | OrderByDirection,
  t: TFunction
): string => {
  switch (sortOption) {
    case UserAccountSortOptions.LastNameASC:
      return t(
        'UserAccounts.SortOptions.LastName',
        'Sort By: Last Name (ASCENDING)'
      )
    case UserAccountSortOptions.LastNameDESC:
      return t(
        'UserAccounts.SortOptions.LastName',
        'Sort By: Last Name (DESCENDING)'
      )
    case UserAccountSortOptions.FirstNameASC:
      return t(
        'UserAccounts.SortOptions.FirstName',
        'Sort By: First Name (ASCENDING)'
      )
    case UserAccountSortOptions.FirstNameDESC:
      return t(
        'UserAccounts.SortOptions.FirstName',
        'Sort By: First Name (DESCENDING)'
      )
    case UserAccountSortOptions.UserNameASC:
      return t(
        'UserAccounts.SortOptions.UserName',
        'Sort By: User Name (ASCENDING)'
      )
    case UserAccountSortOptions.UserNameDESC:
      return t(
        'UserAccounts.SortOptions.UserName',
        'Sort By: User Name (DESCENDING)'
      )
    default:
      return t(
        'UserAccounts.SortOptions.LastName',
        'Sort By: Last Name (ASCENDING)'
      )
  }
}

const SearchAndFilterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const FilterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
  },
}))

const LegendWrapper = styled('div')(({ theme }) => ({
  display: 'flex', // inline
  color: theme.palette.primary.main,
  fontFamily: 'plantin, sans-serif',
}))

const SortContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, 4, 0, 0), // 0,3,3,0
  },
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 3),
  },
}))

interface UserAccountsTabProps {
  userAccounts: UserAccountListing[]
  filterOptions?: Role[]
  totalCount: number
  pageSize: number
  handleSortOptions: (property: string[]) => void
  handleSearch: (options: string) => void
  handleRowsPerPage: (rowAmount: number) => void
  onPageChange: (newPage: number) => void
  handleRoleKeySearch: (roleKey: string) => void
}

export const UserAccountsTab: React.FunctionComponent<UserAccountsTabProps> = (
  props
) => {
  const {
    userAccounts,
    filterOptions,
    handleSearch,
    handleSortOptions,
    handleRoleKeySearch,
  } = props
  const { t } = useTranslation()
  const location = useLocation()
  const theme = useTheme()

  const userId = () => {
    const hasNumber = /\d/
    if (hasNumber.test(location.pathname)) {
      return location.pathname.replace(/[^0-9]/g, '')
    } else {
      return ''
    }
  }

  // If there is a number in the url ie /admin/users/accounts/1, then we know that this url is for an individual user
  const hasNumber = /\d/
  const individualUser = hasNumber.test(location.pathname)

  const roles = buildFilterOptions(
    filterOptions?.map((role) => role.name) ?? [],
    t('UserAccounts.FilterOption.Roles', 'All Roles')
  )

  const handleDropDownSelectionProperty = (selection: string, id: string) => {
    let sortByProperty: string[] = []
    switch (id) {
      case 'role':
        handleRoleKeySearch(selection)
        break
      case 'sort':
        if (selection.includes(UserAccountSortOptions.FirstNameASC)) {
          sortByProperty = [
            UserAccountSortBy.FirstNameASC,
            UserAccountSortBy.LastNameASC,
          ]
        } else if (selection.includes(UserAccountSortOptions.FirstNameDESC)) {
          sortByProperty = [
            UserAccountSortBy.FirstNameDESC,
            UserAccountSortBy.LastNameDESC,
          ]
        } else if (selection.includes(UserAccountSortOptions.LastNameASC)) {
          sortByProperty = [
            UserAccountSortBy.LastNameASC,
            UserAccountSortBy.FirstNameASC,
          ]
        } else if (selection.includes(UserAccountSortOptions.LastNameDESC)) {
          sortByProperty = [
            UserAccountSortBy.LastNameDESC,
            UserAccountSortBy.FirstNameDESC,
          ]
        } else if (selection.includes(UserAccountSortOptions.UserNameASC)) {
          sortByProperty = [UserAccountSortBy.UserNameASC]
        } else if (selection.includes(UserAccountSortOptions.UserNameDESC)) {
          sortByProperty = [UserAccountSortBy.UserNameDESC]
        }
        handleSortOptions(sortByProperty)
        break
    }
  }

  return (
    <>
      {individualUser ? (
        <UserAccount userId={userId()} roleOptions={filterOptions} />
      ) : (
        <Page withinTab>
          <SearchAndFilterWrapper>
            <SearchBar handleSearch={handleSearch} />
            <FilterWrapper>
              <SortContainer>
                <DropDown
                  formControlProps={{
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                      margin: theme.spacing(2),
                    },
                  }}
                  id="role"
                  menuOptions={roles.map((role) => {
                    return { name: role.name, id: role.id }
                  })}
                  defaultValue={t(
                    'UserAccounts.FilterOption.Roles',
                    'All Roles'
                  )}
                  handleSelection={handleDropDownSelectionProperty}
                  variant={DropDownVariant.SortAndFilter}
                />
              </SortContainer>
              <SortContainer>
                <DropDown
                  formControlProps={{
                    [theme.breakpoints.down('sm')]: {
                      width: '100%',
                      margin: theme.spacing(2),
                    },
                  }}
                  id="sort"
                  menuOptions={Object.values(UserAccountSortOptions).map(
                    (option) => {
                      return {
                        name: labelForSortOption(option, t),
                        id: option,
                      }
                    }
                  )}
                  defaultValue={t('UserAccounts.SortOption.Sort', 'sort')}
                  handleSelection={handleDropDownSelectionProperty}
                  variant={DropDownVariant.SortAndFilter}
                />
              </SortContainer>
            </FilterWrapper>
          </SearchAndFilterWrapper>
          <LegenWaitForItDary
            component="section"
            aria-labelledby="legend-label"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              margin: theme.spacing(2, 0, 6, 0),
            })}
          >
            <Typography
              variant="body2"
              id="legend-label"
              component="h2"
              display="inline"
            >
              <LegendWrapper>
                <BlueHelpIcon />
                <span
                  style={{
                    margin: theme.spacing(1.5),
                  }}
                >
                  <Typography>
                    {t('UserAccounts.Table.Legend.Title', 'KEY:')}
                  </Typography>
                </span>
                <span
                  style={{
                    margin: theme.spacing(1.5),
                  }}
                >
                  <Typography fontStyle={'italic'}>
                    {t(
                      'UserAccounts.Table.Legend.PendingRole',
                      'Pending Role, '
                    )}
                  </Typography>
                </span>
                <span
                  style={{
                    margin: theme.spacing(1.5),
                  }}
                >
                  <Typography>
                    {t(
                      'UserAccounts.Table.Legend.CurrentRole',
                      ' Current Role, '
                    )}
                  </Typography>
                </span>

                <span
                  style={{
                    margin: theme.spacing(1.5),
                  }}
                >
                  <Typography
                    sx={{
                      textDecoration: 'line-through',
                    }}
                  >
                    {t('UserAccounts.Table.Legend.RecentRole', ' Recent Role')}
                  </Typography>
                </span>
              </LegendWrapper>
            </Typography>
          </LegenWaitForItDary>
          <UserAccountsSummaryTable
            {...props}
            userAccounts={userAccounts ?? []}
          />
        </Page>
      )}
    </>
  )
}
export default UserAccountsTab
