import { useTheme } from '@mui/material/styles'
import {
  CommunityListing,
  OrderByDirection,
  UserCommunity,
} from '../../swagger/models'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SortOptions } from './Communities'
import { EmptyPageCenter, Page } from '../Elements/PageMargins'
import SearchBar from '../Search/SearchBar'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'
import CommunitySummaryTable from './CommunitySummaryTable'
import { TFunction } from 'i18next'
import { useAuth } from '../Routes/AuthProvider'
import { Box } from '@mui/material'
import { useCommunitiesContext } from '../Context/CommunitiesContext'

const labelForSortOption = (sortOption: SortOptions, t: TFunction) => {
  switch (sortOption) {
    case SortOptions.srLastName:
      return t('Communities.SortOptions.SRLastName', 'Sort By: SR Last Name')
    case SortOptions.communityName:
    default:
      return t(
        'Communities.SortOptions.CommunityName',
        'Sort By: Community Name'
      )
  }
}

enum CommunitiesSortBy {
  LastName = 'lastName',
  name = 'name',
  none = 'none',
}

interface CommunitiesTabProps {
  communitiesEnrolled: CommunityListing[]
  communitiesIManage: CommunityListing[]
  academicYears: Array<{ year: number; name: string }>
  updateSelectedYear: (year: number) => void
  memberOfCommunity: boolean
  managerOfCommunity: boolean
  selectedYear: number
  handleAcademicYearSearch: (academicYear: number) => void
  handleSearch: (options: string) => void
  handleSortOptions: (
    property: string,
    order: string,
    sortByLastName: boolean
  ) => void
  handleRowsPerPage: (rowAmount: number) => void
  onPageChange: (newPage: number) => void
  totalCount: number
  pageSize: number
  resetTablePagination: (newPage: number) => void
  tableFooterPage: number
  roles: Array<UserCommunity>
}

export const CommunitiesTab: React.FunctionComponent<CommunitiesTabProps> = (
  props
) => {
  const {
    communitiesEnrolled,
    communitiesIManage,
    academicYears,
    updateSelectedYear,
    handleAcademicYearSearch,
    handleSearch,
    handleSortOptions,
    selectedYear,
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const [filterByYear, setFilterByYear] = useState(0)
  const [sortByProperty, setSortByProperty] = useState(CommunitiesSortBy.none)
  const { userDetails } = useAuth()

  const { search: searchQuery } = useCommunitiesContext()

  const handleDropDownSelection = (selection: string, id: string) => {
    switch (id) {
      case 'sort':
        if (selection.includes('Last')) {
          setSortByProperty(CommunitiesSortBy.LastName)
        } else {
          setSortByProperty(CommunitiesSortBy.name)
        }
        break
      case 'years':
        const yearSelected =
          academicYears.find((year) => year.name === selection)?.year ?? 0
        updateSelectedYear(yearSelected)
        setFilterByYear(yearSelected)
        break
    }
  }

  useEffect(() => {
    const sortByOrder = OrderByDirection.Asc
    let sortByLastName = false
    if (sortByProperty === 'lastName') {
      sortByLastName = true
    }
    handleSortOptions(sortByProperty, sortByOrder, sortByLastName)
  }, [sortByProperty, handleSortOptions])

  useEffect(() => {
    handleAcademicYearSearch(filterByYear)
  }, [filterByYear, handleAcademicYearSearch])
  return (
    <Page>
      <Box display="flex" flexWrap={'wrap'}>
        <SearchBar handleSearch={handleSearch} searchValue={searchQuery} />
        <Box
          id="filterContainer"
          sx={{
            [theme.breakpoints.down('sm')]: {
              margin: theme.spacing(0, 3, 0, 0),
            },
            [theme.breakpoints.up('md')]: {
              margin: theme.spacing(0, 3),
            },
          }}
        >
          <DropDown
            id="years"
            menuOptions={academicYears.map(({ year, name }) => {
              return { name: name, id: year }
            })}
            value={
              academicYears.find((it) => +it.year === selectedYear)?.name ?? ''
            }
            handleSelection={handleDropDownSelection}
            variant={DropDownVariant.SortAndFilter}
          />
        </Box>
        <Box
          id="sortCommunities"
          sx={{
            [theme.breakpoints.down('sm')]: {
              margin: theme.spacing(0, 3, 0, 0),
            },
            [theme.breakpoints.up('md')]: {
              margin: theme.spacing(0, 3, 0, 0),
            },
          }}
        >
          <DropDown
            id="sort"
            menuOptions={Object.values(SortOptions).map((option) => {
              return { name: labelForSortOption(option, t), id: option }
            })}
            defaultValue={t('Communities.SortOption.Sort', 'sort')}
            handleSelection={handleDropDownSelection}
            variant={DropDownVariant.SortAndFilter}
          />
        </Box>
      </Box>

      {communitiesEnrolled.length === 0 && communitiesIManage.length === 0 ? (
        <EmptyPageCenter adjustForMargins>
          <Box
            sx={{
              color: theme.palette.primary.main,
              marginTop: theme.spacing(5),
            }}
          >
            <SearchIcon
              sx={{
                width: 170,
                height: 170,
                color: theme.palette.textOrIcon.noResultsFoundSearchIcon,
                marginBottom: theme.spacing(4),
              }}
            />

            <Typography
              color={theme.palette.textOrIcon.noResultsFound}
              variant="subtitle1"
              component="p"
            >
              {t(
                'Communities.EmptySearchResults.Instructions',
                `No results found.`
              )}
            </Typography>
            <Typography
              color={theme.palette.textOrIcon.noResultsFound}
              variant="subtitle1"
              component="p"
            >
              {t(
                'Communities.EmptySearchResults.Instructions',
                `Try adjusting your search or filters to find what you're looking for.`
              )}
            </Typography>
          </Box>
        </EmptyPageCenter>
      ) : (
        <>
          {
            // If acting as a parent, show member communities
            userDetails.actingAs === 'parent' && (
              <CommunitySummaryTable
                {...props}
                title={t(
                  'Communities.CommunitiesEnrolled.Title',
                  "Communities I'm Enrolled In"
                )}
                id="CommunitiesEnrolled"
                communities={communitiesEnrolled}
              />
            )
          }
          {
            // Else we are acting as an actor/role, so show managed communities
            typeof userDetails.actingAs === 'number' && (
              <CommunitySummaryTable
                {...props}
                title={t(
                  'Communities.CommunitiesIManage.Title',
                  'Communities I Manage'
                )}
                id="CommunitiesIManage"
                communities={communitiesIManage}
                pagination={true}
              />
            )
          }
        </>
      )}
    </Page>
  )
}
export default CommunitiesTab
