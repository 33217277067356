import React, { useEffect, useRef } from 'react'
import TitleContext from '../../TitleContext'
import { Page } from '../Elements/PageMargins'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router'
import Header from '../Elements/Header'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import ContentOverviewCard from '../Card/ContentOverviewCard'
import { FileType, FileTypeIcon } from '../Elements/FileTypeResource'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { styled } from '@mui/system'
import { Roles } from '../../utils/searchAndFilterEnums'
import LearningCenterContentCard from '../Card/LearningCenterContentCard'
import useFetchLearningPath from '../../hooks/useFetchLearningPath'
import { useMountEffect } from '../../hooks/useMountEffect'
import EmptyPage from '../Elements/EmptyPage'
import { Typography } from '@mui/material'
import EmptyLearningCenter from './EmptyLearningCenter'

const LearningPathHeader = styled(Header)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  padding: 0,
  color: theme.palette.primary.dark,
}))

export const LearningPathLC: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const title = t('Learning.LearningPathLC.Title', 'Learning')
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)
  const navigate = useNavigate()
  const location = useLocation()

  const { assetKey } = useParams<{
    assetKey: string
  }>()

  const parsedAssetKey = parseInt(`${assetKey}`)

  const {
    fetchLearningPath,
    learningPath,
    isLoading,
    errorFetchingContent: errorFetchingLearningPath,
    updateLearningPathAssetById,
    favoritedAssets,
  } = useFetchLearningPath({
    assetKey: parsedAssetKey,
    requestType: 'learning',
  })

  useMountEffect(() => {
    fetchLearningPath()
  })

  const isPriorityOneAssetUpdated = useRef(false)

  useEffect(() => {
    if (learningPath) {
      const priorityOneAsset = learningPath.assets.find(
        (asset) => asset.listingPriority === 1
      )

      if (priorityOneAsset) {
        if (
          !!favoritedAssets &&
          favoritedAssets.length === 1 &&
          !priorityOneAsset.isFavorited &&
          !isPriorityOneAssetUpdated.current
        ) {
          updateLearningPathAssetById(priorityOneAsset.assetKey, {
            isFavorited: true,
          })
          isPriorityOneAssetUpdated.current = true
          return
        }

        if (
          !!favoritedAssets &&
          favoritedAssets.length === 0 &&
          priorityOneAsset.isFavorited &&
          isPriorityOneAssetUpdated.current
        ) {
          isPriorityOneAssetUpdated.current = false
          return
        }
      }
    }
  }, [favoritedAssets, learningPath, updateLearningPathAssetById])

  const handleStartLearning = () => {
    navigate(
      {
        pathname: `/learning/learning-content/${assetKey}`,
      },
      {
        state: {
          assetKeysInLearningPath,
          learningPathTitle: learningPath?.title,
        },
      }
    )
  }

  const navigateBack = () => {
    const programType = location.pathname.split('/')[2]
    navigate(`/learning/${programType}`)
  }

  const assetKeysInLearningPath =
    learningPath?.assets.map(({ assetKey }) => assetKey.toString()) ?? []

  const isFavorited = (): boolean => {
    const isFavorite = learningPath?.assets
      .filter((asset) => asset.assetKey === parsedAssetKey)
      .map((asset) => asset.isFavorited)
    return isFavorite?.[0] ?? false
  }

  if (errorFetchingLearningPath) {
    return (
      <EmptyPage
        isLoading={isLoading}
        message={
          <Typography variant="subtitle1" component="p">
            {t(
              'LearningCenter.LearningPathLC.ErrorMessage',
              `Something went wrong while trying to fetch learning path.`
            )}
          </Typography>
        }
      />
    )
  }

  return (
    <Page>
      {isLoading ? (
        <EmptyLearningCenter isLoading={isLoading} />
      ) : (
        <>
          <DynamicBreadcrumbs
            breadcrumbs={[
              {
                label: t(
                  'Learning.LearningPathLC.BreadCrumb',
                  'Learning Center'
                ),
                onClick: navigateBack,
              },
              {
                label: learningPath?.title,
              },
            ]}
          />
          <Card>
            <Box p={{ xs: 2, sm: 4 }}>
              <ContentOverviewCard
                assetKey={parsedAssetKey}
                icon={<FileTypeIcon fileType={FileType.LearningPath} large />}
                iconBackground="light"
                title={
                  learningPath?.title ||
                  t(
                    'LearningCenter.LearningPathLC.Title.Default',
                    'No Title Available'
                  )
                }
                description={
                  learningPath?.description ||
                  t(
                    'LearningCenter.LearningPathLC.Description.Default',
                    'No description information available.'
                  )
                }
                actionArea={
                  <ContainedButton
                    id="startLearning"
                    variant={ContainedButtonVariant.StartLearning}
                    onClick={handleStartLearning}
                  />
                }
                isFavorite={isFavorited()}
                isLearningPath
              />
            </Box>
          </Card>

          <LearningPathHeader
            id="learningPathHeader"
            headerName={learningPath?.title}
          />
          {learningPath?.assets.length ? (
            <Box display="flex" flexWrap="wrap" ml={{ xs: 0, md: -1 }}>
              {learningPath?.assets.map((asset) => {
                const isFavoriteButtonDisabled =
                  asset.listingPriority === 1 &&
                  favoritedAssets &&
                  favoritedAssets.length > 0

                return (
                  <LearningCenterContentCard
                    key={asset.assetKey}
                    id={asset.assetKey}
                    fileType={asset.filetype as FileType}
                    subTitle={`Role: ${asset.role as Roles}`}
                    title={asset.title}
                    description={asset.description ?? ''}
                    isLearningPath={false}
                    isFavorite={!!asset.isFavorited}
                    strand={asset.strand ?? ''}
                    viewOnly={!!asset.viewOnly}
                    learningPathTitle={learningPath?.title}
                    routerState={{ assetKeysInLearningPath }}
                    isFavoriteButtonDisabled={isFavoriteButtonDisabled}
                    handleFavoriteButtonClicked={() => {
                      updateLearningPathAssetById(asset.assetKey, {
                        isFavorited: !asset.isFavorited,
                      })
                    }}
                  />
                )
              })}
            </Box>
          ) : (
            <EmptyPage
              isLoading={isLoading}
              message={
                <Typography variant="subtitle1" component="p">
                  {t(
                    'LearningCenter.LearningPathLC.NoContent',
                    `There are no content available for this Learning Path.`
                  )}
                </Typography>
              }
            />
          )}
        </>
      )}
    </Page>
  )
}

export default LearningPathLC
