import React from 'react'
import Typography from '@mui/material/Typography'
import EmptyPage from './EmptyPage'

const NoPermission: React.FC<{
  isLoading?: boolean
  content: string
}> = (props) => {
  return (
    <EmptyPage
      {...props}
      message={
        <Typography variant="subtitle1" component="p">
          {props.content}
        </Typography>
      }
    />
  )
}

export default NoPermission
