import { Box, Card, Divider, styled, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'
import CardFormHeader from './CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import { DirectorDashboardDetailsVariants } from './DirectorDashboardDetails'
import { LicensingDue } from '../../swagger'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import AdjacentLabels from '../Labels/AdjacentLabels'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useAccountContext } from '../Context/AccountContext'

export enum TextColors {
  Normal = 'Normal',
  Yellow = 'Yellow',
  Red = 'Red',
}

interface DashboardCardProps {
  licensing: LicensingDue | undefined
  pendingEnrollments?: number
  outstandingTuition?: number
  programs?: number
  invitations?: number
}

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  marginBottom: theme.spacing(4),
}))

const DashboardHeader = styled(Header)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(4),
  marginBottom: theme.spacing(4),
}))

const StyledRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  mt: theme.spacing(2),
  ml: theme.spacing(4),
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '80%',
    margin: '0 auto',
  },
}))

const DashboardCard: React.FC<DashboardCardProps> = ({
  pendingEnrollments = 0,
  outstandingTuition = 0,
  programs = 0,
  licensing,
  invitations = 0,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const total = licensing?.total?.amount ?? 0
  const textColor = licensing?.textColor
  const showOnDesktop = useShowOnDesktop()

  const {
    directorDashboard: { dashboardTextColor },
  } = useAccountContext()

  const getColor = (textColor: string | undefined) => {
    if (textColor === TextColors.Yellow) return theme.palette.textOrIcon.warning

    if (textColor === TextColors.Red) return theme.palette.error.main

    if (textColor === TextColors.Normal)
      return theme.palette.textOrIcon.onBackground
  }

  //This method navigate to interstitial pages and Billing page
  const navigateTo = (
    route: string | undefined,
    variant?: DirectorDashboardDetailsVariants
  ) => {
    navigate(
      { pathname: route },
      {
        state: {
          variant,
        },
      }
    )
  }

  const inputsForComponentsInDashboardCard = [
    {
      label: t('Account.DashboardCard.LicensingDue', 'Licensing Due'),
      id: 'licensingDue',
      value: total,
      isCurrency: true,
      navigate: '/account/billing',
      hasDivider: true,
      textColor: textColor,
      variant: DirectorDashboardDetailsVariants.billing,
    },
    {
      label: t(
        'Account.DashboardCard.InProgressInvitations',
        'In Progress Invitations'
      ),
      id: 'outstandingInvitations',
      value: invitations,
      isCurrency: false,
      navigate: '/account/dashboard/in-progress-invitations',
      variant: DirectorDashboardDetailsVariants.outstandingInvitations,
      hasDivider: true,
    },
    {
      label: t(
        'Account.DashboardCard.PendingEnrollments',
        'Pending Enrollments'
      ),
      id: 'pendingEnrollments',
      value: pendingEnrollments,
      isCurrency: false,
      navigate: '/account/dashboard/pending-enrollments',
      variant: DirectorDashboardDetailsVariants.pendingEnrollments,
      hasDivider: true,
      textColor: dashboardTextColor?.pendingEnrollmentsTextColor,
    },
    {
      label: t(
        'Account.DashboardCard.OutstandingTuition',
        'Outstanding Tuition'
      ),
      id: 'outstandingTuition',
      value: outstandingTuition,
      isCurrency: false,
      navigate: '/account/dashboard/outstanding-tuition',
      variant: DirectorDashboardDetailsVariants.outstandingTuition,
      hasDivider: true,
      textColor: dashboardTextColor?.pendingEnrollmentsTextColor,
    },
    {
      label: t('Account.DashboardCard.Programs', 'Programs'),
      id: 'programs',
      value: programs,
      isCurrency: false,
      navigate: '/account/dashboard/programs',
      variant: DirectorDashboardDetailsVariants.programs,
      hasDivider: true,
    },
  ]

  return (
    <Card
      sx={{
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(4),
        mt: theme.spacing(4),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: showOnDesktop ? 'row' : 'column',
          justifyContent: 'space-between',
          maxWidth: showOnDesktop ? '50%' : '100%',
          minWidth: '25%',
        }}
      >
        <CardFormHeader
          header={
            <DashboardHeader
              id="dashboardHeader"
              headerName={t(
                'DashboardCard.Header.CurrentStatistics',
                'Current Statistics'
              )}
              component="h3"
              variant={HeaderVariant.Card}
            />
          }
        />
        <Typography component="p" variant="body1">
          {t(
            'DashboardCard.Header.HyperLinkInstruction',
            'Select CLICK FOR DETAILS button for any of the links below'
          )}
        </Typography>
      </Box>
      <StyledRowContainer>
        {inputsForComponentsInDashboardCard.map(
          ({
            label,
            id,
            isCurrency,
            navigate,
            value,
            hasDivider,
            variant,
            textColor,
          }) => (
            <Box key={label}>
              {hasDivider && <Divider />}
              <StyledBox>
                <SpaceBetweenSection
                  containerStyles={{
                    [theme.breakpoints.down('md')]: {
                      flexDirection: 'row',
                    },
                  }}
                  left={
                    <Box display="flex" alignItems="center">
                      <AdjacentLabels
                        rightStyle={{
                          ...{
                            color: getColor(textColor),
                            typography: 'h5',
                          },
                        }}
                        leftLabel={label}
                        rightLabel={isCurrency ? ` $ ${value}` : `${value}`}
                      />
                    </Box>
                  }
                  right={
                    <ContainedButton
                      id={id}
                      variant={ContainedButtonVariant.ClickForDetails}
                      onClick={() => navigateTo(navigate, variant)}
                    />
                  }
                />
              </StyledBox>
            </Box>
          )
        )}
      </StyledRowContainer>
    </Card>
  )
}

export default DashboardCard
