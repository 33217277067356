/**
 * General function to copy link to clipboard
 *
 * @param {String} value the value that will be stored in the clipboard
 * @param {Function} callback an optional function to run afterwards
 */
export const copyToClipboard = (value: string, callback?: () => void): void => {
  const el = document.createElement('input')
  el.value = `${value}`
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)

  if (callback) {
    callback()
  }
}
