import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyPage from '../Elements/EmptyPage'

interface EmptyLearningCenterProps {
  isLoading?: boolean
}

const EmptyLearningCenter: React.FC<EmptyLearningCenterProps> = (props) => {
  const { t } = useTranslation()

  return (
    <EmptyPage
      {...props}
      message={
        <Typography variant="subtitle1" component="p">
          {t(
            'LearningCenter.EmptyState.Instructions',
            `Program enrollment not found. Please complete enrollment to view Learning Center content.`
          )}
        </Typography>
      }
    />
  )
}

export default EmptyLearningCenter
