import LoginForInviteResponse from '../components/Interfaces/LoginForInviteResponse'
import { LoginResponseStorageData } from '../components/Interfaces/LoginResponseStorageData'
import {
  LoginResponseAuthorizationEnum,
  LoginForInviteRequestBodySignUpTypeEnum,
  ModelError,
  InviteValidity,
} from '../swagger'
import { auth, extractedErrorObject } from './swagger'

export const loginForInvite = async (
  credentials: {
    username: string
    password: string
  },
  signUpType: LoginForInviteRequestBodySignUpTypeEnum,
  typeIdentifier: string
): Promise<LoginForInviteResponse> => {
  try {
    const result = await auth.loginForInvite({
      body: { credentials, signUpType, typeIdentifier },
    })

    const definitePermissions = result.permissions ?? []
    const definiteFeatures = result.featureDefaults ?? []

    return {
      isAuthorized:
        result.authorization === LoginResponseAuthorizationEnum.Success,
      permissions: definitePermissions,
      defaults: definiteFeatures,
      userKey: result.userKey,
      actingAs: result.actingAs,
      countryOfCitizenship: result.countryOfCitizenship,
      countryOfResidence: result.countryOfResidence,
    }
  } catch (e) {
    const errorObject = await extractedErrorObject(e)

    const error = {
      isAuthorized: false,
      agreementValidity: ((e as unknown as ModelError).target ??
        errorObject?.target) as InviteValidity,
      message: (e as unknown as Error).message,
    }
    return error
  }
}

/**
 Returns `undefined` if login fails, or a (possibly empty) array of permissions if successful.
 */
export const login = async (credentials: {
  email: string
  password: string
}): Promise<LoginResponseStorageData> => {
  try {
    const result = await auth.login({
      body: { username: credentials.email, password: credentials.password },
    })
    const success =
      result.authorization === LoginResponseAuthorizationEnum.Success
    if (!success) {
      return {
        permissions: undefined,
        defaults: undefined,
        discourseUrls: undefined,
      }
    }

    // Ensure the return value is not undefined, even if |permissions| is absent, to avoid miscommunicating the logged-in status.
    const definitePermissions = result.permissions ?? []
    const definiteFeatures = result.featureDefaults ?? []
    const currentDiscourseUrl = result.discourseUrls ?? []
    return {
      permissions: definitePermissions,
      defaults: definiteFeatures,
      userKey: result.userKey,
      actingAs: result.actingAs ?? 'parent',
      discourseUrls: currentDiscourseUrl,
      countryOfResidence: result.countryOfResidence,
      countryOfCitizenship: result.countryOfCitizenship,
    }
  } catch {
    return {
      permissions: undefined,
      defaults: undefined,
      discourseUrls: undefined,
    }
  }
}

export const updateLogin = async (
  actorKey?: number
): Promise<LoginResponseStorageData> => {
  try {
    const result = await auth.updateLogin({
      body: {
        actorKey,
      },
    })
    const success =
      result.authorization === LoginResponseAuthorizationEnum.Success
    if (!success) {
      return {
        permissions: undefined,
        defaults: undefined,
        discourseUrls: undefined,
      }
    }

    const definitePermissions = result.permissions ?? []
    const definiteFeatures = result.featureDefaults ?? []
    const currentDiscourseUrl = result.discourseUrls ?? []
    return {
      permissions: definitePermissions,
      defaults: definiteFeatures,
      userKey: result.userKey,
      actingAs: result.actingAs ?? 'parent',
      discourseUrls: currentDiscourseUrl,
    }
  } catch (e) {
    throw e
  }
}

export default login
