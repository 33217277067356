import React from 'react'
import { useLicensingPaymentBreakdownCalculations } from '../../../../hooks/useLicensingPaymentBreakdownCalculations'
import {
  BreakdownLedgerForPDF,
  BreakDownLedgerPDFVariants,
} from './BreakdownLedgerForPDF'
import { useTranslation } from 'react-i18next'

type SemesterTwoSubtotalBreakdownProps = Pick<
  ReturnType<typeof useLicensingPaymentBreakdownCalculations>,
  'semester2Subtotal' | 'semester2FirstStudent' | 'semester2AdditionalStudent'
> & {
  isChallenge: boolean
}

export const SemesterTwoSubtotalBreakdown: React.FC<
  SemesterTwoSubtotalBreakdownProps
> = ({
  isChallenge,
  semester2Subtotal,
  semester2FirstStudent,
  semester2AdditionalStudent,
}) => {
  const { t } = useTranslation()

  const secondSemesterLicensingTuitionLabel = t(
    'Licensing.Table.Breakdown.Header.SecondSemesterLicensingTuition',
    '2nd Semester Licensing Fee - Tuition'
  )

  if (isChallenge) {
    return (
      <BreakdownLedgerForPDF
        variant={BreakDownLedgerPDFVariants.Normal}
        headerLabel={secondSemesterLicensingTuitionLabel}
        firstStudent={semester2FirstStudent}
        additionalStudent={semester2AdditionalStudent}
        subtotal={semester2Subtotal}
      />
    )
  }

  return <></>
}
