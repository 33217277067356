import { OrderByDirection } from '../swagger'

const getOrderByClause = (
  orderByArray: { [key: string]: OrderByDirection | undefined }[]
): string[] | undefined => {
  if (!orderByArray[0]) {
    return undefined
  }

  const [key, value] = Object.entries(orderByArray[0]).pop() ?? []
  return [`${key} ${value}`]
}

export default getOrderByClause
