import { Program } from '../swagger/models/Program'
import { useMemo } from 'react'
import { ProgramStatus, ProgramType } from '../swagger/models'

export type ProgramForSorting = Pick<Program, 'type' | 'status' | 'programsKey'>

const ordinalMapOfProgramTypeEnum = new Map(
  Object.values(ProgramType).map((value, index) => {
    return [value, index]
  })
)

const ordinalMapOfProgramStatusEnum = new Map(
  Object.values(ProgramStatus).map((value, index) => {
    return [value, index]
  })
)

export const sortProgramsByTypeAndStatus = <Program extends ProgramForSorting>(
  programs: Program[]
): Program[] => {
  return programs.sort((a, b) => {
    const statusGrouping =
      (ordinalMapOfProgramStatusEnum.get(a.status) ?? 0) -
      (ordinalMapOfProgramStatusEnum.get(b.status) ?? 0)
    const typeGrouping =
      (ordinalMapOfProgramTypeEnum.get(a.type) ?? 0) -
      (ordinalMapOfProgramTypeEnum.get(b.type) ?? 0)
    return statusGrouping === 0
      ? typeGrouping === 0
        ? a.programsKey - b.programsKey
        : typeGrouping
      : statusGrouping
  })
}

export const useSortedPrograms = (programs: Program[]): Program[] => {
  const communityProgramsByTypeAndStatus = useMemo(
    () => sortProgramsByTypeAndStatus(programs),
    [programs]
  )

  return communityProgramsByTypeAndStatus
}

export default useSortedPrograms
