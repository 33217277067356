import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import { useLocation } from 'react-router'

/**
 * To use this ScrollContext you need to:
 * 1. Identify the screen 1 where you start to navigate (move forward)
 * 2. Screen 2 from which you go back to screen 1
 *
 * In screen 1 you need to call the hook `useScrollContext`:
 *
 * - Establish in which section of the app we are in, providing the location to the context through `updateScrollPathnameForSection`
 *   (This will guarantee that the scroll position is maintained throughout the entire section, once we move to another
 *   section the ScrollContext values are reset).
 *
 * - Locate the function that allows us to navigate from screen 1 to screen 2 and within this function set the current scroll position by using
 *   the function `updatePrevScrollY` (to avoid that the scroll from being established with the previous position in the screen 2 we must reset the value
 *   using the function `updateScrollY(0)`).
 *
 * - On screen 1 we can use `useLayoutEffect` (before the component render) to set the scroll position that we previously stored
 *   on `prevScrollY`, making use of the `updateScrollY` function.
 *
 * In screen 2 when you click on the back arrow button (browser) or any option of react router navigation (Link, breadcrumb, button, etc)
 * to go back to Screen 1.
 * You land on Screen 1 with the scrollY values which you left.
 *
 * Use example:
 *
 *
 *    pathname: /screen/one
 *    export const ScreenOne = () => {
 *      const navigate = useNavigate()
 *      const {pathname} = useLocation()
 *      const { updateScrollY, prevScrollY, updatePrevScrollY, updateScrollPathnameForSection} = useScrollContext()
 *
 *      useMountEffect(() => updateScrollPathnameForSection(pathname))
 *
 *      useLayoutEffect(() => {
 *        updateScrollY(prevScrollY)
 *      }, [])
 *
 *      const navigateTo = () => {
 *        updateScrollY(0) // reset the scroll Y to guarantee the next view will be scrollTo(0,0)
 *        updatePrevScrollY(window.scrollY) // Current Position
 *        navigate('/screen/two')
 *      }
 *
 *      return (
 *        <>
 *          <div>Screen One</div>
 *          <button onClick={navigateTo}>Navigate To Screen Two</button>
 *        </>
 *      )
 *    }
 *
 *    pathname: /screen/two
 *    export const ScreenTwo = () => {
 *
 *      const navigateTo = () => {
 *        navigate('/screen/one')
 *      }
 *
 *      return <div>Screen Two</div>
 *    }
 *
 */

export const defaultScrollContextValues = {
  /** The position on the Y axis that will be set to the scroll position Y. */
  scrollY: 0 as number,
  /** Used to store the value of previous scroll position in the axis Y after navigating to another screen. */
  prevScrollY: 0 as number,
  /**
   * Used to store the main path of the section in which we will be handling the scroll.
   * It is provided as a unique identifier that allows us to handle the scroll by sections.
   * Each time this value change, 'scrollY', 'prevScrollY', 'currentPathName', and 'shouldMaintainScroll'
   * will be reset to their initial values.
   * Ex, if you wish use this context in learningCenter in your route is /learning/scribblers your
   * currentPathName will be "learning"
   */
  currentPathName: '' as string,
  /** If it's true the scroll Y is maintained across the navigation */
  shouldMaintainScroll: true as boolean,
  /** used to update the scrollY state */
  updateScrollY: (scrollY: number): void => {
    console.warn(
      `The ScrollContext.updateScrollY was called with value ${scrollY}. Did you forget to use a ScrollProvider?`
    )
  },
  /** used to update the prevScrollY state */
  updatePrevScrollY: (prevScrollY: number): void => {
    console.warn(
      `The ScrollContext.updatePrevScrollY was called with value ${prevScrollY}. Did you forget to use a ScrollProvider?`
    )
  },
  /** used to update the currentPathName state */
  updateScrollPathnameForSection: (pathname: string): void => {
    console.warn(
      `The ScrollContext.updateScrollPathnameForSection was called with value ${pathname}. Did you forget to use a ScrollProvider?`
    )
  },
  /** used to update the shouldMaintainScroll state */
  updateMaintainScroll: (value: boolean): void => {
    console.warn(
      `The ScrollContext.updateMaintainScroll was called with value ${value}. Did you forget to use a ScrollProvider?`
    )
  },
  /**
   * Restore the prevScrollY, scrollY, currentPathName, shouldMaintainScroll to default values
   */
  resetDefaultContextValues: (): void => {
    console.warn(
      `The ScrollContext.resetDefaultContextValues was called incorrectly. Did you forget to use a ScrollProvider?`
    )
  },
}

/** **************************** Context **************************** */
export const ScrollContext = createContext(defaultScrollContextValues)

export const ScrollContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { pathname } = useLocation()
  const [scrollY, setScrollY] = useState(defaultScrollContextValues.scrollY)
  const [prevScrollY, setPrevScrollY] = useState(
    defaultScrollContextValues.prevScrollY
  )
  const [currentPathName, setCurrentPathName] = useState(
    defaultScrollContextValues.currentPathName
  )
  const [shouldMaintainScroll, setShouldMaintainScroll] = useState(
    defaultScrollContextValues.shouldMaintainScroll
  )

  const updateScrollY = (value: number) => {
    setScrollY(value)
  }

  const updatePrevScrollY = (value: number) => {
    setPrevScrollY(value)
  }

  const updateScrollPathnameForSection = (value: string) => {
    const currentSection = value.split('/')[1]
    setCurrentPathName(currentSection)
  }

  const updateMaintainScroll = (value: boolean) => {
    setShouldMaintainScroll(value)
  }

  const resetDefaultContextValues = () => {
    setPrevScrollY(0)
    setScrollY(0)
    setCurrentPathName('')
    setShouldMaintainScroll(true)
  }

  useEffect(() => {
    if (shouldMaintainScroll) {
      window.scrollTo(0, scrollY)
    }
  }, [scrollY, shouldMaintainScroll])

  useEffect(() => {
    if (!pathname.includes(currentPathName)) {
      resetDefaultContextValues()
    }
  }, [currentPathName, pathname])

  const contextValue = {
    shouldMaintainScroll,
    updateMaintainScroll,
    updateScrollY,
    scrollY,
    prevScrollY,
    updatePrevScrollY,
    updateScrollPathnameForSection,
    currentPathName,
    resetDefaultContextValues,
  } as typeof defaultScrollContextValues

  return (
    <ScrollContext.Provider value={contextValue}>
      {children}
    </ScrollContext.Provider>
  )
}

/** **************************** Hook **************************** */
export const useScrollContext = (): typeof defaultScrollContextValues => {
  const context = useContext(ScrollContext)
  if (!context) {
    throw new Error(
      'useScrollContext must be used within a ScrollContextProvider'
    )
  }
  return context
}
