// Converts yyyy-mm-dd to full date (without time) ie Fri Aug 13 2021
export const toFormattedDateString = (dateStr: string): string => {
  if (dateStr === '' || dateStr === undefined) return ''

  const [year, month, day] = dateStr.split('-')
  const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
    .toDateString()
    .split(' ')

  date[2] = `${date[2]},`

  return date.join(' ')
}
