import React from 'react'
import { transcriptPdfStyles } from './TranscriptPDFStyles'
import { TranscriptYear } from '../../../swagger'
import { useTheme } from '@mui/material/styles'

export interface ClassInformationPDFProps {
  transcriptYear?: TranscriptYear
  yearGPA: string
  yearCredits: string
  grade: number
}
const ClassInformationPDF: React.FC<ClassInformationPDFProps> = ({
  transcriptYear,
  yearGPA,
  yearCredits,
  grade,
}) => {
  const theme = useTheme()
  // This line checks if notes has meaningful text (something more than just spaces)
  const hasNotes = !!transcriptYear?.notes?.trim()

  return (
    <div
      style={{
        ...transcriptPdfStyles.box,
        fontSize: '9px',
        paddingTop: '1px',
        marginTop: 0,
        marginBottom: 0,
        paddingBottom: hasNotes ? '4px' : '12px',
      }}
    >
      <div style={transcriptPdfStyles.informationRow}>
        <div>Grade: {grade}th</div>
        <div
          style={{
            position: 'absolute',
            left: '210px',
          }}
        >
          {transcriptYear?.academicYear ?? ''}-
          {transcriptYear
            ? transcriptYear?.academicYear
              ? transcriptYear.academicYear + 1
              : ''
            : ''}
        </div>
      </div>
      <div
        style={{
          ...transcriptPdfStyles.informationRow,
          textDecoration: 'underline',
        }}
      >
        <div style={transcriptPdfStyles.weightedText}>Class</div>
        <div
          style={{ paddingLeft: '118px', ...transcriptPdfStyles.weightedText }}
        >
          Grade
        </div>
        <div
          style={{ paddingLeft: '55px', ...transcriptPdfStyles.weightedText }}
        >
          Credits
        </div>
      </div>
      <div>
        {transcriptYear?.transcriptYearCourseWork?.map((course, index) => {
          return (
            <div
              style={transcriptPdfStyles.informationRow}
              key={(course.transcriptYearCourseWorkKey ?? index).toString()}
            >
              <div style={{ maxWidth: '130px' }}>{course.courseName}</div>
              <div style={{ position: 'absolute', left: '150px' }}>
                {course.letterGrade}
              </div>
              <div style={{ position: 'absolute', left: '238px' }}>
                {course.credits.toFixed(1)}
              </div>
            </div>
          )
        })}
      </div>
      <div
        style={{
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            ...transcriptPdfStyles.weightedText,
            position: 'absolute',
            left: '105px',
          }}
        >
          GPA
        </div>
        <div
          style={{
            position: 'absolute',
            left: '142px',
            ...transcriptPdfStyles.weightedText,
          }}
        >
          {yearGPA}
        </div>
        <div
          style={{
            position: 'absolute',
            left: '176px',
            ...transcriptPdfStyles.weightedText,
            width: '100%',
          }}
        >
          Total Credits
        </div>
        <div
          style={{
            position: 'absolute',
            left: '233px',
            ...transcriptPdfStyles.weightedText,
          }}
        >
          {yearCredits}
        </div>
      </div>
      <div style={{ marginTop: theme.spacing(2.5), position: 'relative' }}>
        {hasNotes && (
          <div
            style={{
              paddingTop: '1px',
              ...transcriptPdfStyles.weightedText,
            }}
          >
            NOTES:
            <div style={{ paddingTop: '2px' }}>{transcriptYear?.notes}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ClassInformationPDF
