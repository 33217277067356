import {
  Box,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material'
import { Info, Visibility, VisibilityOff } from '@mui/icons-material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useEmailPasswordStates from '../../hooks/useEmailPasswordStates'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { AuthFormInfo } from '../Login/AuthFormCard'
import PasswordField from '../Login/PasswordField'
import BasicModal from './BasicModal'

interface ChangePasswordModalProps {
  isOpen: boolean
  onClose: () => void
  handleChangePassword: (password: string, newPassword?: string) => void
}

const ChangePasswordModal: React.FunctionComponent<
  ChangePasswordModalProps
> = ({ isOpen, onClose, handleChangePassword }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [authenticationInfo, setAuthenticationInfo] = useState<AuthFormInfo>({
    email: '',
    password: '',
    passwordConfirm: '',
    newPassword: '',
    newPasswordConfirm: '',
  })

  const handleAuthInfoUpdate = (id: string, value: string) => {
    setAuthenticationInfo({
      ...authenticationInfo,
      [id]: value,
    })
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword)
  }

  const toggleConfirmNewPasswordVisibility = () => {
    setShowConfirmNewPassword(!showConfirmNewPassword)
  }

  const handleClickFromParent = async (
    _email: string,
    password: string,
    newPassword?: string
  ) => {
    handleChangePassword(password, newPassword)
  }

  const emailPasswordStates = useEmailPasswordStates({
    includePasswordConfirm: false,
    includeNewPasswordConfirm: true,
    handleAuthInfoUpdate,
    authenticationInfo,
    handleClickFromParent,
  })

  const { passwordFieldProps, emailFieldStates } = emailPasswordStates
  const { handleClick } = emailFieldStates
  const {
    password,
    newPasswordIsEmptyOrInvalid,
    emptyPasswordField,
    handleInputEvent,
    handleKeyboardEvent,
    newPassword,
    newPasswordConfirm,
    newPasswordsDontMatch,
    clearErrors,
    newPasswordInvalid,
  } = passwordFieldProps

  function PasswordFieldIcon(isEmpty: boolean, isVisible: boolean) {
    const ToggleIcon = isVisible ? Visibility : VisibilityOff

    if (isEmpty) {
      return (
        <Info
          sx={{
            fill: theme.palette.error.main,
          }}
        />
      )
    }

    return <ToggleIcon />
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    handleClick()
  }

  const handleModalClose = () => {
    setAuthenticationInfo({
      email: '',
      passwordConfirm: '',
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    })
    clearErrors?.()
  }

  const passwordRules = [
    t('PasswordFields.PasswordRule.One', 'At least 1 lower case character'),
    t('PasswordFields.PasswordRule.Two', 'At least 1 upper case character'),
    t('PasswordFields.PasswordRule.Three', '8 characters minimum'),
  ]

  const disableConfirmButton =
    !authenticationInfo.password ||
    !authenticationInfo.newPassword ||
    !authenticationInfo.newPasswordConfirm
      ? true
      : false

  return (
    <>
      <BasicModal
        isOpen={isOpen}
        dialogTitle={t('ChangePassword.Title', 'Change Password')}
        afterClose={handleModalClose}
        handleFormSubmit={handleFormSubmit}
        ariaLabel="Change Password Modal"
        maxWidth="xs"
        dialogContent={
          <>
            <Typography component="p" variant="subtitle2">
              {t(
                'ChangePassword.Modal.Message',
                'After your password has been successfully updated, you will be logged out of all of your devices and redirected to the login page where you can log in with your new password.'
              )}
            </Typography>

            <Box mb={4} width="100%">
              <PasswordField
                id="password"
                label={t(
                  'ChangePassword.FormFields.CurrentPassword',
                  'Current Password'
                )}
                helperText={''}
                type={showPassword ? 'text' : 'password'}
                value={password}
                error={false}
                onChange={handleInputEvent}
                onBlur={handleInputEvent}
                onKeyPress={handleKeyboardEvent}
                inputProps={{ maxLength: 128, autoComplete: 'off' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={
                          !emptyPasswordField
                            ? () => togglePasswordVisibility()
                            : () => null
                        }
                        edge="end"
                        aria-label={t(
                          'Login.PasswordField.IconButton.AriaLabel',
                          'Toggle password visibility'
                        )}
                      >
                        {PasswordFieldIcon(false, showPassword)}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box mb={4} width="100%">
              <PasswordField
                id="newPassword"
                label={t(
                  'ChangePassword.FormFields.NewPassword',
                  'New Password'
                )}
                helperText={`${
                  newPasswordInvalid
                    ? t(
                        'Signup.PasswordField.HelperText',
                        'Please enter a password that meets all criteria.'
                      )
                    : ''
                }`}
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword ?? ''}
                error={newPasswordInvalid}
                onChange={handleInputEvent}
                onBlur={handleInputEvent}
                onKeyPress={handleKeyboardEvent}
                inputProps={{ maxLength: 128, autoComplete: 'off' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={
                          !emptyPasswordField
                            ? () => toggleNewPasswordVisibility()
                            : () => null
                        }
                        edge="end"
                        aria-label={t(
                          'Login.PasswordField.IconButton.AriaLabel',
                          'Toggle password visibility'
                        )}
                      >
                        {PasswordFieldIcon(newPasswordInvalid, showNewPassword)}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  textAlign: 'start',
                  color: theme.palette.primary.main,
                  opacity: 0.65,
                  marginLeft: theme.spacing(1.5),
                }}
              >
                <Typography variant="body2">{`${t(
                  'PasswordFields.PasswordRule.Heading',
                  'Password must contain'
                )}:`}</Typography>
                {passwordRules.map((rule) => (
                  <Typography key={rule} variant="body2">
                    {`- ${rule}`}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box mb={4} width="100%">
              <PasswordField
                id="newPasswordConfirm"
                label={t(
                  'ChangePassword.FormFields.ConfirmPassword',
                  'Confirm New Password'
                )}
                type={showConfirmNewPassword ? 'text' : 'password'}
                value={newPasswordConfirm ?? ''}
                error={newPasswordsDontMatch}
                helperText={`${
                  newPasswordsDontMatch
                    ? t(
                        'PasswordFields.PasswordConfirmField.HelperText',
                        'Passwords must match.'
                      )
                    : ''
                }`}
                onChange={handleInputEvent}
                onBlur={handleInputEvent}
                onKeyPress={handleKeyboardEvent}
                inputProps={{ maxLength: 128, autoComplete: 'off' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={
                          !emptyPasswordField
                            ? () => toggleConfirmNewPasswordVisibility()
                            : () => null
                        }
                        edge="end"
                        aria-label={t(
                          'Login.PasswordField.IconButton.AriaLabel',
                          'Toggle password visibility'
                        )}
                      >
                        {PasswordFieldIcon(
                          !!newPasswordIsEmptyOrInvalid ||
                            newPasswordsDontMatch,
                          showConfirmNewPassword
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </>
        }
        dialogActions={
          <ActionButtons
            primaryButtonLabel={ContainedButtonVariant.Confirm}
            disablePrimaryButton={disableConfirmButton}
            secondaryButtonLabel={TextButtonVariant.Cancel}
            secondaryClick={onClose}
          />
        }
      />
    </>
  )
}

export default ChangePasswordModal
