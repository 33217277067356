/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OrderByDirection } from './'

/**
 *
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
  /**
   * The current page of data being returned.
   * @type {number}
   * @memberof PaginationResponse
   */
  page: number
  /**
   * The length of data being returned on the requested page.
   * @type {number}
   * @memberof PaginationResponse
   */
  pageSize: number
  /**
   *
   * @type {Array<{ [key: string]: OrderByDirection | undefined; }>}
   * @memberof PaginationResponse
   */
  orderBy: Array<{ [key: string]: OrderByDirection | undefined }>
  /**
   * The total count of data available throughout all pages.
   * @type {number}
   * @memberof PaginationResponse
   */
  totalCount: number
}

export function PaginationResponseFromJSON(json: any): PaginationResponse {
  return PaginationResponseFromJSONTyped(json, false)
}

export function PaginationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaginationResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    page: json['page'],
    pageSize: json['pageSize'],
    orderBy: json['orderBy'],
    totalCount: json['totalCount'],
  }
}

export function PaginationResponseToJSON(
  value?: PaginationResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    page: value.page,
    pageSize: value.pageSize,
    orderBy: value.orderBy,
    totalCount: value.totalCount,
  }
}
