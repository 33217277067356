import React, { SyntheticEvent, useEffect, useRef } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Page } from '../Elements/PageMargins'
import TitleContext from '../../TitleContext'
import ContentDetailsCard from '../Card/ContentDetailsCard'
import { FileType, FileTypeIcon } from '../Elements/FileTypeResource'
import { contentApi, extractedErrorObject } from '../../api/swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { CanAccess } from '../Elements/Access'
import EmptyLearningCenter from './EmptyLearningCenter'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import ShowFullScreenButton from '../Buttons/ShowFullScreenButton'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useFetchWidenAsset } from '../../hooks/useFetchWidenAsset'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import { useLearningCenterContext } from '../Context/LearningCenterContext'

const formatFilesize = (value: number): string => {
  const DIVISOR = 1024
  return `${(value / DIVISOR).toFixed(2)} MB`
}

const LEARNING_PATH_TEXT_TRUNCATION_LIMIT = 35

const LearningCenterContent: React.FunctionComponent = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('xs'))

  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const {
    selectedTabKey,
    updateLearningCenterAssetById,
  } = useLearningCenterContext()

  const { useTitleEffect } = React.useContext(TitleContext)
  const title = t('Learning.LearningCenterContent.Title', 'Learning Center')
  useTitleEffect(title)

  const nextAssetKey = useRef<string>()
  const prevAssetKey = useRef<string>()

  const { assetKey, academicYear } = useParams<{
    assetKey: string,
    academicYear:string
  }>()
  const parsedAssetKey = parseInt(`${assetKey}`)
  const parsedAcademicYear = parseInt(`${academicYear}`)

  const {
    LearningCenter: { fetchContentDetail },
  } = useLoadingIds()

  const {
    state,
  }: {
    state: {
      assetKeysInLearningPath: string[]
      learningPathTitle: string
    }
  } = useLocation()

  const { learningPathTitle, assetKeysInLearningPath } = state || {}
  const isViewingInOrder = !!assetKeysInLearningPath

  const handleLearningPathNavigation = (value: 'next' | 'prev') => {
    navigate(
      {
        pathname: `/learning/learning-content/${
          value === 'next' ? nextAssetKey.current : prevAssetKey.current
        }/${parsedAcademicYear}`,
      },
      {
        state: {
          learningPathTitle,
          assetKeysInLearningPath,
        },
      }
    )
  }

  if (isViewingInOrder) {
    const currentIndex = assetKeysInLearningPath.indexOf(`${assetKey}`)
    nextAssetKey.current = assetKeysInLearningPath[currentIndex + 1]
    prevAssetKey.current = assetKeysInLearningPath[currentIndex - 1]
  }

  const {
    contentAsset: learningContent,
    isLoading,
    fetchAsset,
    refetch,
  } = useFetchWidenAsset({
    assetKey: parsedAssetKey,
    loadingId: fetchContentDetail,
    academicYear: parsedAcademicYear
  })

  useEffect(() => {
    fetchAsset()
    /**
     * including the `assetKey` since the Next/Prev button
     * updates the key and that produces refetch/rerender
     */
  }, [fetchAsset, assetKey])

  const averageStarRating = learningContent?.avgStarRating ?? 0

  const handleStarRatingUpdate = async (rating: number) => {
    try {
      await contentApi.starRating({
        body: { widenAssetKey: parsedAssetKey, starRating: rating },
      })
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'UnknownError',
        message:
          (error as unknown as Error).message ??
          t(
            'LearningCenterContent.StarRating.Error',
            'Failed to update Star Rating'
          ),
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    }

    // This is to re-fetch the new value so that the star rating component updates when a user clicks a new rating
    refetch()
  }

  const { filetype, viewOnly, embedUrl, isFavorited } = learningContent ?? {}

  const emptyTitle = t('LearningCenter.EmptyTitle', 'No title available')
  const contentTitle = learningContent?.title || emptyTitle
  const showFileType = filetype

  const programType = selectedTabKey.toLowerCase()
  const breadcrumbLearningCenter = {
    label: t('Learning.LearningContent.BreadCrumb', 'Learning Center'),
    onClick: () => {
      navigate({
        pathname: `/learning/${programType}`,
      })
    },
  }

  const breadcrumbLearningPath = {
    label: t(
      'Learning.LearningContent.LearningPathTitle',
      '{{learningPathTitle}}',
      {
        /**
         * Some learningPathTitle could be too large, so,
         * we need to truncate the text for adjusting better in mobile view.
         */
        learningPathTitle:
          learningPathTitle?.length > LEARNING_PATH_TEXT_TRUNCATION_LIMIT
            ? `${learningPathTitle.slice(0, 32)}...`
            : learningPathTitle,
      }
    ),
    onClick: () => {
      navigate({
        pathname: `/learning/${programType}/learning-path/${assetKeysInLearningPath[0]}`,
      })
    },
  }
  const breadcrumbsContentTitle = {
    label: contentTitle,
  }
  const breadcrumbs = !!learningPathTitle
    ? [
        breadcrumbLearningCenter,
        breadcrumbLearningPath,
        breadcrumbsContentTitle,
      ]
    : [breadcrumbLearningCenter, breadcrumbsContentTitle]

  return (
    <Page>
      <CanAccess I="learningCenter" on="Feature">
        <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
        {!learningContent ? (
          <EmptyLearningCenter isLoading={isLoading} />
        ) : (
          <>
            {isMobileOrSmaller && (
              <ShowFullScreenButton assetKey={parsedAssetKey} />
            )}
            <ContentDetailsCard
              assetKey={parsedAssetKey}
              filetype={filetype as FileType}
              showFileByType={showFileType}
              viewOnly={viewOnly ?? false}
              embedUrl={embedUrl}
              icon={<FileTypeIcon fileType={filetype as FileType} large />}
              iconBackground="dark"
              title={contentTitle}
              description={
                learningContent?.description ||
                t('LearningCenter.EmptyDescription', 'No description available')
              }
              subText={
                !!learningContent?.filesize
                  ? `Size: ${formatFilesize(learningContent.filesize)}`
                  : undefined
              }
              actionArea={
                isViewingInOrder && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    mr={{ xs: 0, sm: -1 }}
                    sx={{
                      [theme.breakpoints.down('sm')]: {
                        width: '100%',
                        gap: theme.spacing(1.5),
                        alignItems: 'center',
                      },
                    }}
                  >
                    {nextAssetKey.current && (
                      <OutlinedButton
                        id="next-asset-learning-path-lc"
                        variant={OutlinedButtonVariant.Next}
                        onClick={() => handleLearningPathNavigation('next')}
                        css={{
                          marginBottom: theme.spacing(1),
                          marginRight: theme.spacing(2),
                          width: theme.spacing(25),
                          [theme.breakpoints.down('sm')]: {
                            marginRight: theme.spacing(1),
                          },
                        }}
                      />
                    )}
                    {prevAssetKey.current && (
                      <OutlinedButton
                        id="prev-asset-learning-path-lc"
                        variant={OutlinedButtonVariant.Previous}
                        onClick={() => handleLearningPathNavigation('prev')}
                        css={{
                          marginBottom: theme.spacing(1),
                          marginRight: theme.spacing(2),
                          width: theme.spacing(25),
                          [theme.breakpoints.down('sm')]: {
                            marginRight: theme.spacing(1),
                          },
                        }}
                      />
                    )}
                  </Box>
                )
              }
              starRating={averageStarRating}
              starRatingOnChange={(
                event: SyntheticEvent<Element, Event>,
                rating: number | null
              ) => {
                handleStarRatingUpdate(rating ?? 0)
              }}
              isFavorite={isFavorited ?? false}
              handleFavoriteButtonClicked={() =>
                updateLearningCenterAssetById(parsedAssetKey, {
                  isFavorited: !isFavorited,
                })
              }
              {...props}
            />
          </>
        )}
      </CanAccess>
    </Page>
  )
}

export default LearningCenterContent
