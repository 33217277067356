import { Typography, TypographyProps, styled } from '@mui/material'
import React from 'react'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import BasicModal from './BasicModal'
import { useTranslation } from 'react-i18next'

interface PreviousInvitationsModalProps {
  showModal: boolean
  onAccept: () => void
  handleConfirmationCancel: () => void
}

const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  margin: theme.spacing(1.2, 0),
})) as typeof Typography

const PreviousInvitationsModal: React.FC<PreviousInvitationsModalProps> = ({
  showModal,
  onAccept,
  handleConfirmationCancel,
}) => {
  const { t } = useTranslation()

  const previousInvitationsModalHeader = t(
    'PreviousInvitationsModal.Header',
    'Previous Invitation'
  )

  const modalBodyText = {
    information: t(
      'PreviousInvitationsModal.Body.Information',
      'You have already sent this family a program invitation. If you send again, it will create an additional invitation and '
    ),
    licensingInformation: t(
      'PreviousInvitationsModal.Body.Information.Licensing',
      'licensing will be owed on those offered spots.'
    ),
    question: t(
      'PreviousInvitationsModal.Body.Question',
      `Are you sure you want to send another invitation to the same family?`
    ),
    instructions: t(
      'PreviousInvitationsModal.Body.Instructions',
      'If not, click Cancel and, if needed, go to the program Invite tab to Edit & Resend.'
    ),
  }

  const previousInvitationsModalBody = (
    <>
      <StyledTypography>
        {modalBodyText.information}
        <Typography component="span" style={{ fontWeight: 'bold' }}>
          {modalBodyText.licensingInformation}
        </Typography>
      </StyledTypography>
      <StyledTypography>{modalBodyText.question}</StyledTypography>
      <StyledTypography>{modalBodyText.instructions}</StyledTypography>
    </>
  )

  const previousInvitationsModalActionButtons = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.Accept}
      secondaryButtonLabel={TextButtonVariant.NoCancel}
      secondaryClick={handleConfirmationCancel}
    />
  )

  const previousInvitationsModalProps = {
    isOpen: showModal,
    dialogTitle: previousInvitationsModalHeader,
    dialogContent: previousInvitationsModalBody,
    dialogActions: previousInvitationsModalActionButtons,
    handleFormSubmit: onAccept,
  }

  return <BasicModal {...previousInvitationsModalProps} maxWidth="xs" />
}

export default PreviousInvitationsModal
