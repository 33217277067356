/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 * Object with totals for outstandingTuition, pendingEnrollments, and myPrograms.
 * @export
 * @interface DashboardStatistics
 */
export interface DashboardStatistics {
  /**
   * Total count of all outstanding tuition.
   * @type {number}
   * @memberof DashboardStatistics
   */
  outstandingTuitionTotal?: number
  /**
   *
   * @type {number}
   * @memberof DashboardStatistics
   */
  pendingEnrollmentsTotal?: number
  /**
   *
   * @type {number}
   * @memberof DashboardStatistics
   */
  outstandingInvitationsTotal?: number
  /**
   *
   * @type {number}
   * @memberof DashboardStatistics
   */
  myProgramsTotal?: number
}

export function DashboardStatisticsFromJSON(json: any): DashboardStatistics {
  return DashboardStatisticsFromJSONTyped(json, false)
}

export function DashboardStatisticsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DashboardStatistics {
  if (json === undefined || json === null) {
    return json
  }
  return {
    outstandingTuitionTotal: !exists(json, 'outstandingTuitionTotal')
      ? undefined
      : json['outstandingTuitionTotal'],
    pendingEnrollmentsTotal: !exists(json, 'pendingEnrollmentsTotal')
      ? undefined
      : json['pendingEnrollmentsTotal'],
    outstandingInvitationsTotal: !exists(json, 'outstandingInvitationsTotal')
      ? undefined
      : json['outstandingInvitationsTotal'],
    myProgramsTotal: !exists(json, 'myProgramsTotal')
      ? undefined
      : json['myProgramsTotal'],
  }
}

export function DashboardStatisticsToJSON(
  value?: DashboardStatistics | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    outstandingTuitionTotal: value.outstandingTuitionTotal,
    pendingEnrollmentsTotal: value.pendingEnrollmentsTotal,
    outstandingInvitationsTotal: value.outstandingInvitationsTotal,
    myProgramsTotal: value.myProgramsTotal,
  }
}
