import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EmptyPage from '../Elements/EmptyPage'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import ConfirmationModal from '../Modals/ConfirmationModal'

const EmptyFamilies: React.FunctionComponent = () => {
  const { t } = useTranslation()

  const [showConfirmationModal, setShowConfirmationModal] = useState(true)

  const familyButtons = (
    <ActionButtons primaryButtonLabel={ContainedButtonVariant.Ok} />
  )

  const handleAgreementConfirmationSubmit = (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
    setShowConfirmationModal(false)
  }

  const headerMessage = `${t(
    'Families.FamilyForm.ConfirmationModal.Header',
    'Looking for Families and Enrollments?'
  )}`

  const familiesConfirmationMessage = t(
    'Families.FamilyForm.ConfirmationModal.Body',
    'Click on a community to see the Families and a program to see the Enrollments.'
  )

  const familiesConfirmationBody = (
    <Typography variant="body1" component="p" align="center">
      {familiesConfirmationMessage}
    </Typography>
  )

  const familiesConfirmationProps = {
    isOpen: showConfirmationModal,
    dialogTitle: headerMessage,
    dialogContent: familiesConfirmationBody,
    dialogActions: familyButtons,
    handleFormSubmit: handleAgreementConfirmationSubmit,
  }

  return (
    <>
      <EmptyPage
        message={
          <>
            <Typography variant="subtitle1" component="p">
              {t(
                'Families.EmptyFamilies.Instructions',
                `Looking for Families and Enrollments?`
              )}
            </Typography>
            <Typography variant="subtitle1" component="p">
              {t(
                'Families.EmptyFamilies.Instructions',
                `Click on a community to see the Families and a program to see the Enrollments.`
              )}
            </Typography>
          </>
        }
      />
      <ConfirmationModal {...familiesConfirmationProps} />
    </>
  )
}

export default EmptyFamilies
