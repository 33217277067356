import { useLocation } from 'react-router'
import {
  useSnackbarContext,
  SnackbarSeverity,
} from '../components/Context/SnackbarContext'
import { useMountEffect } from './useMountEffect'

/**
 *  Used for the cases when we navigate to a new location but want to show a
 *  snackbar. Currently on: Event creation and deletion and Inviting a Family.
 *
 *  To use:
 *    - Push the snackbarState, snackbarMessage, and snackbarSeverity as the
 *      secondary argument to history.push
 *
 *        e.g. history.push('/url/to/push', {
 *               snackbarState: true,
 *               snackbarMessage: 'Message goes here. Don't forget using t()'
 *               snackbarSeverity: SnackbarSeverity (from SnackbarContext)
 *             })
 *
 *    - Use this hook on the top-most component where 'url/to/push' routes.
 */
export const useSnackbarOnNavigation = (): void => {
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const location: {
    state: {
      snackbarState?: boolean
      snackbarMessage?: string
      snackbarSeverity?: SnackbarSeverity
    }
  } = useLocation()
  const { state } = location

  useMountEffect(() => {
    if (
      !!state &&
      state.snackbarState &&
      !!state.snackbarMessage &&
      !!state.snackbarSeverity
    ) {
      setSnackbarSeverity(state.snackbarSeverity)
      setSnackbarMessage(state.snackbarMessage)
      setSnackbarState(state.snackbarState)
      // And remove the state so we don't keep showing a snackbar if we set state information and listen via useSnackbarOnNavigation
      location.state = {}
    }
  })
}
