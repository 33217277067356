import React, { memo } from 'react'
import { addLeadingZero, monthOptions } from '../../helpers/monthsAndDays'
import { DropDownHandleSelectionEvent } from '../../utils/types'
import DynamicFormField, {
  DynamicFormFieldProps,
} from '../Elements/DynamicFormField'

export type RenderListAs = 'numbers' | 'months' | 'abbreviatedMonths'

type MonthDropDownProps = Partial<DynamicFormFieldProps> &
  Pick<DynamicFormFieldProps, 'id' | 'disabled'> & {
    /**
     * Handles the selection on the parent component based on the id of the drop
     * down. Different drop downs perform different actions.
     */
    handleSelection: DropDownHandleSelectionEvent

    /** optional value to customize if the element is displayed as dropdown. If false renders a textbox */
    isDropdown?: boolean

    /** optional value to customize the way the list is displayed */
    renderListAs?: RenderListAs

    /** optional value to start the list items id at 1 */
    startIdAtOne?: boolean
  }

export const MonthDropDown: React.FC<MonthDropDownProps> = memo(
  ({
    disabled,
    handleSelection,
    id,
    label,
    value,
    renderListAs = 'numbers',
    startIdAtOne = false,
    isDropdown = true,
    required = false,
    isValid = {
      input: true,
      minLength: true,
      maxLength: true,
      email: true,
    },
  }) => {
    const options = monthOptions.map((month) => {
      let name = `${addLeadingZero(month.value + 1)}`
      if (renderListAs === 'months') {
        name = month.label
      }
      if (renderListAs === 'abbreviatedMonths') {
        name = month.abbreviation
      }
      const id = startIdAtOne ? `${month.value + 1}` : `${month.value}`
      return {
        name,
        id,
      }
    })

    options.unshift({ name: 'Select Option', id: '' })
    const valueExists = options.find((option) => option.name === value)
    const selectedValue = !!valueExists ? value : options[0].name

    const updateForParent: DynamicFormFieldProps['updateForParent'] = (
      name,
      state,
      required,
      isOverMaxLength,
      isUnderMinLength,
      option
    ): void => {
      handleSelection({ name: state, id: String(option), dropDownId: name })
    }
    return (
      <DynamicFormField
        id={id}
        name={id}
        label={label ?? ''}
        updateForParent={updateForParent}
        value={isDropdown ? selectedValue ?? '' : value ?? ''}
        dropdownOptions={options}
        isValid={isValid}
        required={required}
        inputType={isDropdown ? 'dropdown' : 'text'}
        fullWidth
        disabled={disabled}
      />
    )
  }
)

export default MonthDropDown
