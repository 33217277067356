import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Link } from '@mui/material'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'

const StyledLink = styled(Link)(({ theme }) => ({
  marginRight: theme.spacing(2),
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.textOrIcon.greenButton,
  '&:hover': {
    textDecoration: 'none',
  },
  fontWeight: 400,
  fontSize: '1rem',
  fontFamily: 'Arial',
}))

export const CountryInformationLinks: React.FC<{
  termOfUseLink?: string
  privacyPolicyLink?: string
}> = ({ termOfUseLink, privacyPolicyLink }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  // More information about these links: https://classicalconversations.my.workfront.com/task/63fe2120000b68e8549f42b4dbfde7f5/overview
  const termOfUseUrl = process.env.REACT_APP_TERMS_OF_USE_URL ?? ''
  const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL ?? ''

  const navigateTo = (link: string) => {
    const isNavigatingAwayFromNC3 = link.startsWith('http')

    if (isNavigatingAwayFromNC3) {
      const { href } = new URL(`${link}`, undefined)
      window.open(href, 'blank')
    } else {
      navigate({ pathname: link })
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <StyledLink onClick={() => navigateTo(termOfUseLink ?? termOfUseUrl)}>
        {t('CountryInformationLinks.Link.TermsOfUse', 'Terms of Use')}
      </StyledLink>
      <StyledLink
        onClick={() => navigateTo(privacyPolicyLink ?? privacyPolicyUrl)}
      >
        {t('CountryInformationLinks.Link.PrivacyPolicy', 'Privacy Policy')}
      </StyledLink>
    </Box>
  )
}

export default CountryInformationLinks
