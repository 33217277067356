/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { extractedErrorObject, userAccountsApi } from '../../api/swagger'
import { validateEmail, validateEmailText } from '../../helpers/validateEmail'
import useDynamicFieldStates from '../../hooks/useDynamicFieldStates'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import DynamicFormField from '../Elements/DynamicFormField'
import { DynamicFieldEntries } from '../Interfaces/DynamicFieldStates'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import BasicModal from './BasicModal'
import useEmailMessage from '../../hooks/useEmailMessage'
import { useSnackbarContext } from '../Context/SnackbarContext'

interface AddAccountModalProps {
  isOpen: boolean
  onClose: () => void
  refetchUserAccountData?: () => void
}

const AddAccountModal: React.FC<AddAccountModalProps> = (props) => {
  const { isOpen, onClose, refetchUserAccountData } = props
  const { t } = useTranslation()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { validationMessages } = useEmailMessage()
  const addAccountModalFields: DynamicFieldEntries[] = [
    {
      label: t('AddAccountModal.Field.FirstName', 'First Name'),
      state: {
        state: '',
        isValid: { input: true, maxLength: true, minLength: true, email: true },
        required: true,
      },
    },
    {
      label: t('AddAccountModal.Field.LastName', 'Last Name'),
      state: {
        state: '',
        isValid: { input: true, maxLength: true, minLength: true, email: true },
        required: true,
      },
    },
    {
      label: t('AddAccountModal.Field.Username', 'Username'),
      state: {
        state: '',
        isValid: { input: true, maxLength: true, minLength: true, email: true },
        required: true,
      },
    },
    {
      label: t(
        'AddAccountModal.Field.CCEmailAddress',
        'CC Email Address (optional)'
      ),
      state: {
        state: '',
        isValid: { input: true, maxLength: true, minLength: true, email: true },
        required: false,
      },
    },
  ]
  const [textEmailValid, setTextEmailValid] = useState<string[]>(['', ''])
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const { fieldStates, setFieldStates } = useDynamicFieldStates({
    isInitialLoad,
    setIsInitialLoad,
    fields: addAccountModalFields,
  })

  const handleFormSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (fieldsDoValidate()) {
      try {
        await userAccountsApi.createUser({
          body: {
            firstName: fieldStates[addAccountModalFields[0].label].state,
            lastName: fieldStates[addAccountModalFields[1].label].state,
            username: fieldStates[addAccountModalFields[2].label].state,
            email:
              fieldStates[addAccountModalFields[3].label]?.isValid.email &&
              !!fieldStates[addAccountModalFields[3].label]?.state
                ? fieldStates[addAccountModalFields[3].label].state
                : undefined,
          },
        })
        setSnackbarState?.(true)
        setSnackbarMessage?.(
          `${t(
            'AddAccountModal.ValidationMessage.Success',
            'Successfully added user'
          )} ${fieldStates[addAccountModalFields[0].label]?.state} ${
            fieldStates[addAccountModalFields[1].label]?.state
          }`
        )
        setSnackbarSeverity?.(SnackbarSeverity.Success)

        refetchUserAccountData?.()
        onClose()
      } catch (e) {
        const errorObj = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message:
            (e as unknown as Error).message ??
            t(
              'AddAccountModal.ValidationMessage.AddUser',
              'Something went wrong while adding the user.'
            ),
        }
        setSnackbarState?.(true)
        setSnackbarMessage?.(errorObj.message)
        setSnackbarSeverity?.(SnackbarSeverity.Error)
      }
    }
  }

  const fieldsDoValidate = (): boolean => {
    let fieldsDoValidate = true
    let fieldStateObject = {}
    for (const [key, { state, required }] of Object.entries(fieldStates)) {
      if (required) {
        switch (key) {
          case addAccountModalFields[0].label:
          case addAccountModalFields[1].label:
            fieldsDoValidate = !!state
            fieldStateObject = {
              ...fieldStateObject,
              [key]: {
                ...fieldStates[key],
                isValid: {
                  ...fieldStates[key].isValid,
                  input: !!state,
                },
              },
            }
            break
          case addAccountModalFields[2].label:
            if (!state || (!!state && !validateEmail(state))) {
              fieldsDoValidate = false
            }
            fieldStateObject = {
              ...fieldStateObject,
              [key]: {
                ...fieldStates[key],
                isValid: {
                  ...fieldStates[key].isValid,
                  email: !!state && validateEmail(state),
                },
              },
            }
            textEmailValid[0] = validationMessages(validateEmailText(state))
            setTextEmailValid([...textEmailValid])
            break
        }
      }

      // Handle optional email
      if (addAccountModalFields[3].label === key && !!state) {
        fieldStateObject = {
          ...fieldStateObject,
          [key]: {
            ...fieldStates[key],
            isValid: {
              ...fieldStates[key].isValid,
              email: validateEmail(state),
            },
            validationMessage: validationMessages(validateEmailText(state)),
          },
        }
        textEmailValid[1] = validationMessages(validateEmailText(state))
        setTextEmailValid([...textEmailValid])
      }
      setFieldStates(fieldStateObject)
    }

    return fieldsDoValidate
  }

  const removeSpacesStartAndEndFromEmailField = (
    inputName: string,
    value: string
  ): string => {
    if (
      inputName === addAccountModalFields[2].label ||
      inputName === addAccountModalFields[3].label
    ) {
      return value.trim()
    }
    return value
  }

  const handleInputChange = (
    name: string,
    state: string,
    required: boolean,
    isOverMaxLength: boolean,
    isUnderMinLength: boolean
  ) => {
    setFieldStates({
      ...fieldStates,
      [name]: {
        ...fieldStates[name],
        state: removeSpacesStartAndEndFromEmailField(name, state),
      },
    })
  }

  const isPrimaryButtonEnabled =
    !!fieldStates[addAccountModalFields[0].label]?.state &&
    !!fieldStates[addAccountModalFields[1].label]?.state &&
    !!fieldStates[addAccountModalFields[2].label]?.state

  return (
    <BasicModal
      isOpen={isOpen}
      maxWidth="xs"
      handleFormSubmit={handleFormSubmit}
      dialogTitle={t('AddAccountModal.Title', 'Add an Account')}
      dialogContent={
        <Box>
          <Box mb={3.5}>
            <DynamicFormField
              id="firstName"
              name={addAccountModalFields[0].label}
              label={addAccountModalFields[0].label}
              value={fieldStates[addAccountModalFields[0].label]?.state ?? ''}
              updateForParent={handleInputChange}
              isValid={fieldStates[addAccountModalFields[0].label]?.isValid}
              required={fieldStates[addAccountModalFields[0].label]?.required}
              fullWidth
            />
          </Box>
          <Box mb={3.5}>
            <DynamicFormField
              id="lastName"
              name={addAccountModalFields[1].label}
              label={addAccountModalFields[1].label}
              value={fieldStates[addAccountModalFields[1].label]?.state ?? ''}
              updateForParent={handleInputChange}
              isValid={fieldStates[addAccountModalFields[1].label]?.isValid}
              required={fieldStates[addAccountModalFields[1].label]?.required}
              fullWidth
            />
          </Box>
          <Box mb={3.5}>
            <DynamicFormField
              id="username"
              name={addAccountModalFields[2].label}
              label={addAccountModalFields[2].label}
              value={fieldStates[addAccountModalFields[2].label]?.state ?? ''}
              updateForParent={handleInputChange}
              isValid={fieldStates[addAccountModalFields[2].label]?.isValid}
              validationMessage={textEmailValid[0]}
              required={fieldStates[addAccountModalFields[2].label]?.required}
              fullWidth
            />
          </Box>
          <Box>
            <DynamicFormField
              id="ccEmailAddress"
              name={addAccountModalFields[3].label}
              label={addAccountModalFields[3].label}
              value={fieldStates[addAccountModalFields[3].label]?.state ?? ''}
              updateForParent={handleInputChange}
              isValid={fieldStates[addAccountModalFields[3].label]?.isValid}
              validationMessage={textEmailValid[1]}
              required={fieldStates[addAccountModalFields[3].label]?.required}
              fullWidth
            />
          </Box>
        </Box>
      }
      dialogActions={
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.AddAccount}
          disablePrimaryButton={!isPrimaryButtonEnabled}
          secondaryButtonLabel={TextButtonVariant.Cancel}
          secondaryClick={onClose}
        />
      }
    />
  )
}

export default AddAccountModal
