import React, { useEffect } from 'react'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { useAccountContext } from '../../Context/AccountContext'

const InvitesLayout: React.FC = () => {
  const { breadcrumbs, selectedEnrollmentInvite } = useAccountContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    /** Navigate to the initial page in the flow when we refresh the page */
    if (
      selectedEnrollmentInvite === undefined &&
      pathname !== '/account/invites'
    ) {
      navigate({
        pathname: '/account/invites',
      })
    }
  }, [selectedEnrollmentInvite, pathname, navigate])

  return (
    <>
      <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
      <Outlet />
    </>
  )
}

export default InvitesLayout
