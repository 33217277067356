export enum Roles {
  AllRoles = 'All Roles',
  AR = 'AR',
  Director = 'Director',
  EssentialsTutor = 'Essentials Tutor',
  Parent = 'Parent',
  SR = 'SR',
  SalesManager = 'Sales Manager',
}

export enum Categories {
  AllCategories = 'All Categories',
  AcademicProgramTraining = 'Academic Program Training',
  BinderCoaching = 'Binder Coaching',
  Onboarding = 'Onboarding',
  /** Favorites is now a toggle on Learning Center, so this only applies to Business Center */
  MyFavorites = 'My Favorites',
}

/**
 * Used to provide an accurate list of tabs for the LearningCenter.
 *
 * - Helps determine if we are using a Challenge Tab vs other programs
 * - Provides the displayName of the tabsUserCanAccess in useLearningCenterProgramTypes
 */
export enum LearningCenterTabs {
  WelcomeCenter = 'WelcomeCenter',
  Foundations = 'Foundations',
  Scribblers = 'Scribblers',
  Essentials = 'Essentials',
  Challenge = 'Challenge',
}

export enum FilterFileType {
  AllTypes = 'All Types',
  Audio = 'Audio',
  Document = 'Document',
  EBook = 'EBook',
  InteractiveTutorial = 'Interactive Tutorial',
  LearningPath = 'Learning Path',
  Video = 'Video',
}

export enum FilterFileByChallengeLevel {
  ChallengeA = 'Challenge A',
  ChallengeB = 'Challenge B',
  Challenge1 = 'Challenge I',
  Challenge2 = 'Challenge II',
  Challenge3 = 'Challenge III',
  Challenge4 = 'Challenge IV',
}
