import {
  TranscriptPrograms,
  TranscriptYears,
} from '../components/Modals/PrepopulateClassInformationModal'
import {
  CreateTranscriptYear,
  FetchCoursesOfStudiesProgramTypeEnum,
  FetchCoursesOfStudiesRequest,
  TranscriptYear,
  TranscriptYearCourseWork,
} from '../swagger'
import { extractedErrorObject, transcriptsApi } from '../api/swagger'
import { useTranscriptContext } from '../components/Context/TranscriptContext'
import { useTranslation } from 'react-i18next'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'
import {
  calculateTranscriptCredits,
  calculateTranscriptGPA,
} from '../utils/calculateGPA'

export const useFetchCourseOfStudies = (): {
  fetchCourseOfStudies: (
    transcriptYears: TranscriptYears,
    transcriptPrograms: TranscriptPrograms
  ) => Promise<void>
} => {
  const { transcriptDetails, updateTranscriptDetails, findGradingScale } =
    useTranscriptContext()
  const { t } = useTranslation()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const errorMessage = t(
    'Hooks.FetchCourseOfSudies.Error.Default',
    'An error occurred while attempting to load course of studies.'
  )

  const fetchCourseOfStudies = async (
    transcriptYears: TranscriptYears,
    transcriptPrograms: TranscriptPrograms
  ) => {
    const reqArray: Array<{
      transcriptProgram: TranscriptPrograms
      transcriptYear: TranscriptYears
      yearNumber: string
    }> = []
    for (let i = 0; i < 4; i++) {
      reqArray.push({
        transcriptProgram: Object.values(transcriptPrograms)[i],
        transcriptYear: Object.values(transcriptYears)[i],
        yearNumber: Object.keys(transcriptPrograms)[i].slice(-1),
      })
    }
    let updatedTranscriptDetails = transcriptDetails
    for (const req of reqArray) {
      const transcriptYear = req.transcriptYear
        ? +req.transcriptYear
        : undefined

      const reqParams: FetchCoursesOfStudiesRequest = {
        programType:
          req.transcriptProgram as unknown as FetchCoursesOfStudiesProgramTypeEnum,
        year: transcriptYear,
      }
      if (!!req.transcriptProgram) {
        try {
          const response = await transcriptsApi.fetchCoursesOfStudies(reqParams)
          const yearString = `transcriptYear${req.yearNumber}`
          updatedTranscriptDetails = {
            ...updatedTranscriptDetails,
            [yearString]: {
              ...(updatedTranscriptDetails[
                `transcriptYear${req.yearNumber}` as keyof typeof updatedTranscriptDetails
              ] as Partial<TranscriptYear & CreateTranscriptYear>),
              academicYear: transcriptYear,
              gpa: 0,
              totalCredits: 0,
              transcriptYearCourseWork: response.coursesOfStudies?.map(
                ({ courseName, credits }, index): TranscriptYearCourseWork => {
                  return {
                    transcriptYearCourseWorkKey: (index + 1) * -1,
                    courseName: courseName ?? '',
                    letterGrade: '',
                    credits: credits ?? 0,
                  }
                }
              ),
            },
          }

          updatedTranscriptDetails.totalCredits = calculateTranscriptCredits(
            updatedTranscriptDetails
          )

          updatedTranscriptDetails.gpa = calculateTranscriptGPA(
            updatedTranscriptDetails,
            findGradingScale(
              transcriptDetails.gradingScale?.gradingScaleKey ?? -1
            )
          )

          updateTranscriptDetails(updatedTranscriptDetails)
        } catch (error) {
          const errorObject = (await extractedErrorObject(error)) ?? {
            code: 'Unknown',
            message: (error as unknown as Error).message ?? errorMessage,
          }
          setSnackbarMessage(errorObject.message)
          setSnackbarSeverity(SnackbarSeverity.Error)
          setSnackbarState(true)
        }
      }
    }
  }
  return {
    fetchCourseOfStudies,
  }
}
