import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { LocalPermissionGrant, LocalScopeEnum } from '../../Card/RoleCard'
import PermissionGroup from './PermissionGroup'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'

export interface BasePermissionProps {
  isFieldDisabled: boolean
  handleChange: (
    incomingScopeCode: LocalScopeEnum,
    incomingActionCode: string,
    incomingResourceCode: string
  ) => void
}

interface PermissionAccordionProps extends BasePermissionProps {
  category: string
  permissionGrants: LocalPermissionGrant[]
}

const PermissionAccordionSummary = styled(AccordionSummary)({
  // We set the height to match the height of the fieldSets
  minHeight: 47,
  '&.Mui-expanded': {
    minHeight: 47,
  },
  flexDirection: 'row-reverse',
})

const PermissionAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  marginRight: theme.spacing(2),
}))

const PermissionAccordion: React.FC<PermissionAccordionProps> = ({
  category,
  permissionGrants,
  isFieldDisabled,
  handleChange,
}) => {
  const theme = useTheme()
  return (
    <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
      <PermissionAccordionSummary
        id={`${category}-header`}
        aria-controls={`${category}-content`}
        expandIcon={
          <ExpandMoreIcon
            style={{
              padding: theme.spacing(0),
              marginRight: theme.spacing(2),
            }}
          />
        }
      >
        <Typography variant="subtitle2">{category}</Typography>
      </PermissionAccordionSummary>
      <PermissionAccordionDetails>
        <Divider />
        {permissionGrants.map((permissionGrant, index, arr) => (
          <Box key={index} pl={5.75}>
            <PermissionGroup
              permissionGrant={permissionGrant}
              isFieldDisabled={isFieldDisabled}
              handleChange={handleChange}
            />
            {index !== arr.length - 1 && <Divider />}
          </Box>
        ))}
      </PermissionAccordionDetails>
    </Accordion>
  )
}

function permissionAccordionPropsAreEqual(
  prevProps: PermissionAccordionProps,
  nextProps: PermissionAccordionProps
) {
  const { permissionGrants: prevPermissionGrants } = prevProps
  const { permissionGrants: nextPermissionGrants } = nextProps

  return nextPermissionGrants.every(
    (nextPermissionGrant, i) =>
      nextPermissionGrant.actionCode === prevPermissionGrants[i].actionCode &&
      nextPermissionGrant.permissionName ===
        prevPermissionGrants[i].permissionName &&
      nextPermissionGrant.resourceCode ===
        prevPermissionGrants[i].resourceCode &&
      nextPermissionGrant.scopeCode === prevPermissionGrants[i].scopeCode
  )
}

export default React.memo(PermissionAccordion, permissionAccordionPropsAreEqual)
