import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import TableHeaders from '../../Interfaces/TableHeaders'
import TableHeader from '../../Table/TableHeader'
import TableFooterPagination, {
  defaultPageSize,
} from '../../Pagination/TableFooterPagination'
import NoResultsFound from '../../Table/NoResultsFound'
import { addLeadingZero } from '../../../helpers/monthsAndDays'
import { Region } from '../../../swagger'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'

const ClickableRow = styled(TableRow)({
  '&:hover': {
    cursor: 'pointer',
  },
})

export const RegionsSummaryTable: React.FC<{
  regions: Region[]
}> = ({ regions }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize)

  useEffect(() => {
    /** Reset table pagination to first page when data changes to ensure we're always showing results */
    setPage(0)
  }, [regions])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value))
    setPage(0)
  }

  const navigateToRegionDetail = (id: number) => {
    navigate(
      {
        pathname: `/admin/regions/region-details/${id}`,
      },
      {
        /** Navigation Options */
      }
    )
  }

  const tableHeaders: TableHeaders[] = [
    {
      label: t('Regions.TableHeader.Name', 'Name'),
      align: 'left',
    },
    {
      label: t('Regions.TableHeader.ParentRegion', 'Parent Region'),
      align: 'left',
    },
    {
      label: t('Regions.TableHeader.Manager', 'Manager'),
      align: 'left',
    },
    {
      label: t('Regions.TableHeader.Private', 'Private'),
      align: 'left',
    },
    {
      label: t('Regions.TableHeader.Cycle', 'Cycle'),
      align: 'left',
    },
    {
      label: t(
        'Regions.TableHeader.AYCycleStartYear',
        'Academic Year Cycle Start Year'
      ),
      align: 'left',
    },
    {
      label: t('Regions.TableHeader.Semester1Start', 'Semester 1 Start'),
      align: 'left',
    },
    {
      label: t('Regions.TableHeader.Semester2Start', 'Semester 2 Start'),
      align: 'left',
    },
  ]

  return (
    <TableContainer component={Paper}>
      <Table aria-label={t('Regions.Table.Label', 'Regions Table')}>
        <TableHead>
          <TableHeader tableHeaders={tableHeaders} />
        </TableHead>
        <TableBody>
          {regions.length > 0 ? (
            (rowsPerPage > 0
              ? regions.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : regions
            ).map((record) => (
              <ClickableRow
                key={record.regionsKey}
                onClick={() => navigateToRegionDetail(record.regionsKey)}
              >
                <TableCell color={theme.palette.primary.main}>
                  <Typography variant="body1" component="p">
                    {record.name}
                  </Typography>
                </TableCell>
                <TableCell color={theme.palette.primary.main}>
                  <Typography
                    variant="body1"
                    component="p"
                    fontStyle={!record.parentRegionName ? 'italic' : 'normal'}
                  >
                    {record.parentRegionName ?? t('Regions.EmptyCell', 'none')}
                  </Typography>
                </TableCell>
                <TableCell color={theme.palette.primary.main}>
                  <Typography variant="body1" component="p">
                    {record.regionManagerName}
                  </Typography>
                </TableCell>
                <TableCell color={theme.palette.primary.main}>
                  <Typography variant="body1" component="p">
                    {record.isPrivate
                      ? t('Regions.Private.Yes', 'Yes')
                      : t('Regions.Private.No', 'No')}
                  </Typography>
                </TableCell>
                <TableCell color={theme.palette.primary.main}>
                  <Typography variant="body1" component="p">
                    {record.cycle?.toString() ?? ''}
                  </Typography>
                </TableCell>
                <TableCell color={theme.palette.primary.main}>
                  <Typography variant="body1" component="p">
                    {`${record.aYCycleStartYear}`}
                  </Typography>
                </TableCell>
                <TableCell color={theme.palette.primary.main}>
                  <Typography variant="body1" component="p">
                    {addLeadingZero(record.semesterOneStartMonth + 1)}/
                    {addLeadingZero(record.semesterOneStartDay)}
                  </Typography>
                </TableCell>
                <TableCell color={theme.palette.primary.main}>
                  <Typography variant="body1" component="p">
                    {addLeadingZero(record.semesterTwoStartMonth + 1)}/
                    {addLeadingZero(record.semesterTwoStartDay)}
                  </Typography>
                </TableCell>
              </ClickableRow>
            ))
          ) : (
            <NoResultsFound />
          )}
        </TableBody>
        {regions.length > rowsPerPage ? (
          <TableFooterPagination
            label={t('Regions.TablePagination.ViewAll', 'All')}
            count={regions.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            colSpan={tableHeaders.length}
          />
        ) : null}
      </Table>
    </TableContainer>
  )
}

export default RegionsSummaryTable
