import React from 'react'
import { useUser } from '../../../UserContext'
import { transcriptPdfStyles, border } from './TranscriptPDFStyles'

interface SignatureSectionPDFProps {
  studentFirstName?: string
  dateGraduation?: Date
}

const SignatureSectionPDF: React.FC<SignatureSectionPDFProps> = ({
  studentFirstName,
  dateGraduation,
}) => {
  const { user } = useUser()

  return (
    <div
      style={{
        ...transcriptPdfStyles.box,
        width: Number.parseInt(transcriptPdfStyles.box.width) * 2 + 14,
      }}
    >
      <div>
        I self-certify and affirm that this is the official transcript and
        record of {`${studentFirstName ?? ''} ${user?.lastName ?? ''}`}'s
        academic studies through{' '}
        {`${dateGraduation?.getUTCDate()} ${dateGraduation?.toLocaleString(
          'default',
          { month: 'long' }
        )}`}
        , {dateGraduation?.getUTCFullYear() ?? ''}
      </div>
      <div style={{ paddingTop: '3px' }}>
        Name: {`${user?.firstName ?? ''} ${user?.firstName ?? ''}`}
      </div>
      <div
        style={{ ...transcriptPdfStyles.documentDirection, paddingTop: '3px' }}
      >
        <div>Signature:</div>
        <div
          style={{
            borderBottom: border,
            minWidth: '220px',
            paddingLeft: '1px',
          }}
        >
          {' '}
        </div>
        <div style={{ paddingLeft: '2px' }}>Date:</div>
        <div
          style={{
            borderBottom: border,
            minWidth: '220px',
            paddingLeft: '1px',
          }}
        >
          {' '}
        </div>
      </div>
      <div style={{ paddingTop: '3px' }}>
        [Enter administrator's full name](Administrator of Records)
      </div>
    </div>
  )
}

export default SignatureSectionPDF
