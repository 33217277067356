import Link from '@mui/material/Link'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import React, { CSSProperties } from 'react'
/**
 * Renders a list item as a link button.
 */
export const ListItemLink: React.FunctionComponent<{
  onClick: () => void
  icon?: React.ReactNode
  primary: string
  selected?: boolean
  sx?: CSSProperties
}> = (props) => {
  const { icon, primary, onClick, sx } = props

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement>((itemProps, ref) => (
        <Link ref={ref} {...itemProps} onClick={onClick} />
      )),
    [onClick]
  )

  return (
    <li>
      <ListItemButton
        sx={{ ...sx }}
        component={renderLink}
        selected={props.selected}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText
          primary={
            <Typography variant="subtitle2" component="p">
              {primary}
            </Typography>
          }
        />
      </ListItemButton>
    </li>
  )
}

export default ListItemLink
