import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import EmptyPage from '../../Elements/EmptyPage'

interface EmptyUserAccountProps {
  isLoading: boolean
}

export const EmptyUserAccount: React.FC<EmptyUserAccountProps> = (props) => {
  const { t } = useTranslation()

  return (
    <EmptyPage
      {...props}
      message={
        <Typography variant="subtitle1" component="p">
          {t(
            'UserAccounts.EmptyState.Instructions',
            `This user account cannot be found.`
          )}
        </Typography>
      }
    />
  )
}

export default EmptyUserAccount
