import { useTheme } from '@mui/material'
import React from 'react'
import BackToLoginButton from './BackToLoginButton'
import ContainedButton, { ContainedButtonVariant } from './ContainedButton'
import TextButton, { TextButtonVariant } from './TextButton'

interface InviteFlowButtonsProps {
  onClickNext: () => void
  /**
   * Determines if the back button will be included.
   * The idea is to remove the back button from the invite flow while allowing
   * easy inclusion if we want it.
   */
  onClickBack?: () => void
  isNextDisabled?: boolean
  includeResendCode?: boolean
  onClickResendCode?: () => void
  resendLoadingId?: string
  primaryLoadingId?: string
  primaryUseBaseButton?: boolean
}

export const InviteFlowButtons: React.FC<InviteFlowButtonsProps> = (props) => {
  const {
    onClickNext,
    onClickBack,
    isNextDisabled = false,
    onClickResendCode,
    resendLoadingId = '',
    primaryLoadingId = '',
    primaryUseBaseButton = false,
  } = props
  const theme = useTheme()

  const commonStyles = {
    flexGrow: 1,
    width: '96.75%',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  }

  return (
    <>
      <ContainedButton
        id="primaryButton"
        variant={ContainedButtonVariant.Next}
        disabled={isNextDisabled}
        onClick={onClickNext}
        useBaseButton={primaryUseBaseButton}
        loadingId={primaryLoadingId}
        css={{
          ...commonStyles,
        }}
      />
      {!!onClickResendCode && (
        <TextButton
          id="resendCodeButton"
          css={{
            ...commonStyles,
          }}
          variant={TextButtonVariant.ResendCode}
          onClick={onClickResendCode}
          useBaseButton
          loadingId={resendLoadingId}
        />
      )}
      {onClickBack && (
        <BackToLoginButton
          variant={TextButtonVariant.Back}
          css={{
            ...commonStyles,
            margin: '0 !important',
          }}
          onClick={onClickBack}
        />
      )}
    </>
  )
}
