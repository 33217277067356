import React from 'react'
import { useNavigate } from 'react-router'
import ContainedButton, { ContainedButtonVariant } from './ContainedButton'
import TextButton, { TextButtonVariant } from './TextButton'
import { styled } from '@mui/system'

const ButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column-reverse',
  },
}))
interface TeamMemberButtonsProps {
  isNextDisabled: boolean
  handleNext: () => void
}

export const TeamMemberButtons: React.FC<TeamMemberButtonsProps> = ({
  isNextDisabled,
  handleNext,
}) => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate(
      {
        pathname: `/team`,
      },
      {
        /** Navigation Options */
      }
    )
  }

  return (
    <ButtonContainer>
      <TextButton
        id="cancel"
        onClick={handleCancel}
        variant={TextButtonVariant.Cancel}
      />
      <ContainedButton
        id="next"
        variant={ContainedButtonVariant.Next}
        onClick={handleNext}
        disabled={isNextDisabled}
      />
    </ButtonContainer>
  )
}

export default TeamMemberButtons
