import React from 'react'
import StudentInformationPDF from './StudentInformationPDF'
import SchoolInformationPDF from './SchoolInformationPDF'
import ClassInformationPDF from './ClassInformationPDF'
import GradeScaleTablePDF from './GradeScaleTablePDF'
import GradeScaleWeightTablePDF from './GradeScaleWeightTablePDF'
import SummarySectionPDF from './SummarySectionPDF'
import SignatureSectionPDF from './SignatureSectionPDF'
import { genericBoxWidth, transcriptPdfStyles } from './TranscriptPDFStyles'
import { Transcript } from '../../../swagger'

export interface TranscriptDocumentProps {
  transcriptDetails: Transcript
  actingAs: 'parent' | number
}

const TranscriptHtmlMarkup: React.FC<TranscriptDocumentProps> = ({
  transcriptDetails,
  actingAs,
}) => {
  const canSeeSignature = actingAs === 'parent'

  return (
    <div
      style={{ alignItems: 'center', paddingTop: '40px', marginBottom: '4px' }}
    >
      <div style={{ fontSize: '12px', ...transcriptPdfStyles.weightedText }}>
        Official High School Transcript
      </div>
      <div style={transcriptPdfStyles.documentDirection}>
        <StudentInformationPDF
          studentFirstName={transcriptDetails.student.firstName}
          studentLastName={transcriptDetails.student.lastName}
          studentAddress={transcriptDetails.studentAddress}
          studentKey={transcriptDetails.student.studentKey}
          studentDOB={transcriptDetails.studentDateOfBirth}
          studentEmail={transcriptDetails.studentEmail}
        />
        <SchoolInformationPDF
          dateGraduation={transcriptDetails.dateGraduation}
          schoolName={transcriptDetails.schoolName}
          schoolAddress={transcriptDetails.schoolAddress}
        />
      </div>
      <div style={transcriptPdfStyles.documentDirection}>
        {transcriptDetails.transcriptYear1 && (
          <ClassInformationPDF
            transcriptYear={transcriptDetails.transcriptYear1}
            yearGPA={`${
              transcriptDetails.transcriptYear1.gpa?.toFixed(2) ?? '0.00'
            }`}
            yearCredits={`${
              transcriptDetails.transcriptYear1.totalCredits?.toFixed(2) ??
              '0.00'
            }`}
            grade={9}
          />
        )}
        {transcriptDetails.transcriptYear2 && (
          <ClassInformationPDF
            transcriptYear={transcriptDetails.transcriptYear2}
            yearGPA={`${
              transcriptDetails.transcriptYear2.gpa?.toFixed(2) ?? '0.00'
            }`}
            yearCredits={`${
              transcriptDetails.transcriptYear2.totalCredits?.toFixed(2) ??
              '0.00'
            }`}
            grade={10}
          />
        )}
      </div>
      <div style={transcriptPdfStyles.documentDirection}>
        {transcriptDetails.transcriptYear3 && (
          <ClassInformationPDF
            transcriptYear={transcriptDetails.transcriptYear3}
            yearGPA={`${
              transcriptDetails.transcriptYear3.gpa?.toFixed(2) ?? '0.00'
            }`}
            yearCredits={`${
              transcriptDetails.transcriptYear3.totalCredits?.toFixed(2) ??
              '0.00'
            }`}
            grade={11}
          />
        )}
        {transcriptDetails.transcriptYear4 && (
          <ClassInformationPDF
            transcriptYear={transcriptDetails.transcriptYear4}
            yearGPA={`${
              transcriptDetails.transcriptYear4.gpa?.toFixed(2) ?? '0.00'
            }`}
            yearCredits={`${
              transcriptDetails.transcriptYear4.totalCredits?.toFixed(2) ??
              '0.00'
            }`}
            grade={12}
          />
        )}
      </div>
      <div style={transcriptPdfStyles.documentDirection}>
        <div
          style={{
            ...transcriptPdfStyles.documentDirection,
            maxWidth: genericBoxWidth,
          }}
        >
          <div
            style={{
              position: 'relative',
              right: '12px',
            }}
          >
            <GradeScaleTablePDF
              gradeScale={
                transcriptDetails.gradingScale?.gradingScaleDetails ?? []
              }
            />
          </div>
          <div style={{ position: 'relative', right: '5px' }}>
            <GradeScaleWeightTablePDF
              gradingScaleWeights={
                transcriptDetails.gradingScale?.gradingScaleWeight ?? []
              }
            />
          </div>
        </div>
        <div style={{ maxWidth: genericBoxWidth }}>
          <SummarySectionPDF
            transcriptGPA={`${transcriptDetails.gpa?.toFixed(2) ?? '0.00'}`}
            transcriptCredits={`${
              transcriptDetails.totalCredits?.toFixed(2) ?? '0.00'
            }`}
            transcriptSummary={transcriptDetails?.summary}
          />
        </div>
      </div>
      <div>
        {canSeeSignature && (
          <SignatureSectionPDF
            studentFirstName={transcriptDetails.student.firstName}
            dateGraduation={transcriptDetails.dateGraduation}
          />
        )}
      </div>
    </div>
  )
}

export default TranscriptHtmlMarkup
