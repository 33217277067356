import { Box, Tooltip, useTheme } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import React, { CSSProperties, useState } from 'react'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { DropDownVariant, MenuOption } from './DropDown'

export const setLabelValue = (
  currentSelection: string,
  menuOptionName: string,
  defaultValue: string
): string => {
  return currentSelection !== ''
    ? currentSelection
    : menuOptionName !== ''
    ? menuOptionName
    : defaultValue
}

/** Properties of the Drop Down menu component */
interface IdDropDownProps {
  /** A unique string to determine action in handleSelection and distinguish
   * between other drop downs.
   */
  id: string
  /** List of available options in the drop down menu. On initial load, the
   *  first option in the array is selected .
   */
  menuOptions: Array<MenuOption>
  /**
   * Handles the selection on the parent component based on the id of the drop
   * down. Different drop downs perform different actions.
   */
  handleSelection: (
    /** Name of the MenuOption selected */
    selectionId: string | number,
    /** Id of the drop down to determine which action to perform */
    id: string
  ) => void
  /** Variant to determine styling and behavior */
  variant: DropDownVariant
  /** Optional CSSPro
   */
  cssProps?: CSSProperties
  /** Optional fullWidth.  Currently being used on Events (Mobile)
   */
  fullWidth?: boolean
  /** Optional form control className */
  formControlProps?: CSSProperties
  /** Optional label value for form fields*/
  label?: string
  /** Current selected MenuOption value for the dropdown */
  value: MenuOption
  /** Optional value for error state for the dropdown*/
  error?: boolean
  /** Optional value to disable the dropdown*/
  disabled?: boolean
  /** Optional helper text with instructions to fix errors or selection */
  helperText?: string
  /** Optional: shows a tooltip of the full text of the current selection */
  includeTooltip?: boolean
}

/**
 * Drop Down Menu with selectable options.
 * @param {DropDownProps} props - The properties for the drop down menu
 */
export const IdDropDown: React.FC<IdDropDownProps> = ({
  id,
  menuOptions,
  handleSelection,
  cssProps,
  fullWidth,
  formControlProps,
  variant,
  label,
  value,
  error = false,
  disabled = false,
  helperText,
  includeTooltip = false,
}) => {
  const theme = useTheme()

  const isFormField = variant === DropDownVariant.FormField
  const [, setIsOpen] = useState(false)

  const showOnDesktop = useShowOnDesktop()

  return (
    <FormControl
      variant={!isFormField ? 'outlined' : 'filled'}
      sx={{
        ...((fullWidth || !showOnDesktop) && {
          width: '100%',
        }),
        ...(!!formControlProps
          ? {
              ...formControlProps,
            }
          : {
              width: 'auto',
              height: '36px',
            }),
      }}
      disabled={disabled}
    >
      <InputLabel hidden={!isFormField} id={`${id}SelectLabel`}>
        {!isFormField ? id : label}
      </InputLabel>
      <Select
        style={{
          ...cssProps,
        }}
        id={`${id}Select`}
        name={id}
        labelId={`${id}SelectLabel`}
        value={value.id}
        /** Explicitly set open state for multiple select ease of testing */
        onOpen={() => {
          setIsOpen(true)
        }}
        onClose={() => {
          setIsOpen(false)
        }}
        onBlur={() => {
          setIsOpen(false)
        }}
        /** FIXME: isOpen state is not working for single selects. Commenting out for now to get selects working. */
        // open={isOpen}
        onChange={(e: SelectChangeEvent<unknown>) => {
          e.preventDefault()
          /* SAFETY: we guarantee the value is always a string because we provide
           * string names for the menu options.
           */
          handleSelection(e.target.value as string, id)
        }}
        sx={{
          height: '32px',
          padding: theme.spacing(1, 1, 1, 2),
          textAlign: 'center',
          ...(fullWidth
            ? {
                width: '80vw',
              }
            : {
                width: 'auto',
              }),
          ...cssProps,
        }}
        fullWidth={fullWidth || !showOnDesktop}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiList-root': {
                paddingTop: '0',
                paddingBottom: '0',
              },
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          // Must be nulled out when specifying anchorOrigin.vertical per [issue comment](https://github.com/mui-org/material-ui/issues/7961#issuecomment-326109979)
          // TODO: Verify the nulling anchor element provides an accurate drop down anchorage
          // getContentAnchorEl: null,
          // classes: {
          //   list: classes.selectOptions,
          // },
          /** Options: {
            background: theme.palette.customBackground.dropDownMenu,
            '&:focus': {
              background: theme.palette.customBackground.selectedMenuItem,
            },
            [theme.breakpoints.down('sm')]: {
              whiteSpace: 'normal',
              overflow: 'visible',
            }, 
          }*/
        }}
        renderValue={() => {
          if (includeTooltip) {
            return (
              <Tooltip title={value.name}>
                <div>
                  <Typography
                    variant="button"
                    sx={{
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: theme.palette.textOrIcon.dropDownOptions,
                    }}
                  >
                    {`${value.name}`}
                  </Typography>
                </div>
              </Tooltip>
            )
          } else {
            return (
              <Typography
                variant="button"
                sx={{
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: theme.palette.textOrIcon.dropDownOptions,
                }}
              >
                {`${value.name}`}
              </Typography>
            )
          }
        }}
        error={error}
        inputProps={{
          disabled: disabled,
          'data-testid': 'content-input',
        }}
        disabled={disabled}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              background: `${theme.palette.customBackground.dropDownMenu} !important`,
              '&:hover': { background: theme.palette.grey[300] },
              '&:focus': {
                background: `${theme.palette.customBackground.dropDownMenu} !important`,
              },
              '&.Mui-selected.Mui-disabled': {
                background: `${theme.palette.customBackground.selectedMenuItem} !important`,
                opacity: 1,
              },
              '&.Mui-selected': {
                background: `${theme.palette.customBackground.selectedMenuItem} !important`,
                '&:hover': {
                  background: `${theme.palette.customBackground.hoverSelectedMenuItem} !important`,
                },
              },
              [theme.breakpoints.down('sm')]: {
                whiteSpace: 'normal',
                overflow: 'visible',
              },
            }}
            disabled={option.disabled}
            /** Explicitly set open state for multiple select so we can blur and close the menu */
            onBlur={() => {
              setIsOpen(false)
            }}
            /** Since we choose to close the modal on blur, handle the scenario where slow, methodical clickers actually select something */
            onMouseDown={(event) => {
              event.preventDefault()
              handleSelection(option.id, option.name)
            }}
          >
            <Box display="block" flexDirection={'column'}>
              <Typography
                variant="button"
                color={theme.palette.textOrIcon.dropDownOptions}
              >
                {option.name}
              </Typography>
              {option.subtitle && (
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginTop: '-8px',
                  }}
                >
                  {option.subtitle}
                </Typography>
              )}
            </Box>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default IdDropDown
