import React, { useEffect, useMemo, useState } from 'react'
import useSortedPrograms from '../../hooks/useSortedPrograms'
import { Program, Programs } from '../../swagger/models'
import ProgramRenderer from '../Programs/ProgramRenderer'
import { programs } from '../../api/swagger'
import EmptyProgram from '../Programs/EmptyProgram'
import useLoadingContext from '../../hooks/useLoadingContext'
import { LoadingContext } from '../Context/LoadingContext'
import { useCommunitiesContext } from '../Context/CommunitiesContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { ElevatedCardVariants } from '../Card/ElevatedCard'

const emptyCommunityPrograms: Programs = {
  programs: [
    {
      academicYearBeginning: 2020,
      programs: [],
    },
  ],
}

export const CommunityProgramsTab: React.FunctionComponent = () => {
  const [communityPrograms, setCommunityPrograms] = useState({} as Programs)

  const { addLoadingIds } = React.useContext(LoadingContext)

  const { academicYear, selectedCommunityKey } = useCommunitiesContext()

  const availableLoadingIds = useLoadingIds()

  const fetchCommunityPrograms = async () => {
    const fetchedCommunityPrograms = await programs.fetchAllPrograms({
      communityId: selectedCommunityKey,
      academicYearBeginning: academicYear,
    })

    setCommunityPrograms(
      // If fetchAllPrograms doesn't return the correct object, provide the empty Programs object.
      fetchedCommunityPrograms.programs.length > 0
        ? fetchedCommunityPrograms
        : emptyCommunityPrograms
    )
  }
  // makes sure that the call to fetchAllPrograms contains the academic year
  useEffect(() => {
    if (academicYear) {
      addLoadingIds([availableLoadingIds.CommunityProgramsTab.fetchAllPrograms])
    }
  }, [
    academicYear,
    addLoadingIds,
    availableLoadingIds.CommunityProgramsTab.fetchAllPrograms,
  ])

  useLoadingContext({
    asyncFunction: fetchCommunityPrograms,
    loadingId: availableLoadingIds.CommunityProgramsTab.fetchAllPrograms,
  })

  const allProgramsForCommunity = useMemo(() => {
    const allPrograms: Program[] = []
    communityPrograms.programs?.forEach((ayPrograms) => {
      allPrograms.push(...ayPrograms.programs)
    })
    return allPrograms
  }, [communityPrograms])

  const communityProgramsByType = useSortedPrograms(allProgramsForCommunity)

  if (allProgramsForCommunity.length === 0) {
    return <EmptyProgram />
  }

  return (
    <ProgramRenderer
      type={ElevatedCardVariants.Programs}
      programs={communityProgramsByType ? communityProgramsByType : []}
      academicYear={academicYear}
    />
  )
}

export default CommunityProgramsTab
