import { Avatar, useTheme } from '@mui/material'
import React from 'react'

interface UserAvatarProps {
  familyName: string
  src?: string
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  familyName,
  src = '',
}) => {
  const theme = useTheme()

  return (
    <Avatar
      sx={{
        background: theme.palette.customBackground.avatar,
      }}
      src={src}
    >
      {/** Set the default Avatar to the first character of the last word in the Contractor Name.
       * Ideally this would be the first letter of the last name.
       */}
      {familyName.charAt(familyName.lastIndexOf(' ') + 1)}
    </Avatar>
  )
}

export default UserAvatar
