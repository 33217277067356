import React from 'react'
import { ContentAsset } from '../../swagger'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FileType } from '../Elements/FileTypeResource'
import LearningCenterContentCard from '../Card/LearningCenterContentCard'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import EmptyLearningCenter from './EmptyLearningCenter'
import { isFeaturedLearningPathAsset } from './utils/learningCenterUtils'
import { useLearningCenterContext } from '../Context/LearningCenterContext'

interface WelcomeCenterTabProps {
  content: ContentAsset[]
}

export const WelcomeCenterTab: React.FC<WelcomeCenterTabProps> = ({
  content,
}) => {
  const { updateLearningCenterAssetById } = useLearningCenterContext()
  const { t } = useTranslation()
  const accordionSectionTitle = t(
    'LearningCenter.WelcomeTab.Heading',
    'Welcome To CC Connected'
  )
  const theme = useTheme()
  const showOnDesktop = useShowOnDesktop()

  return (
    <div
      style={{
        margin: theme.spacing(3),
        ...(showOnDesktop && {
          margin: theme.spacing(3, 2),
        }),
      }}
    >
      {content.length > 0 ? (
        <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="welcomeCenter-content"
            id="welcomeCenter-header"
          >
            <Typography variant="button" component="p">
              {accordionSectionTitle}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              ...(showOnDesktop && {
                justifyContent: 'left',
              }),
            }}
            aria-labelledby="welcomeCenter-header"
          >
            {content.map((details) => {
              const isFeaturedLearningCenterPathAsset =
                isFeaturedLearningPathAsset({
                  learningPathTitle: details.learningPathTitle,
                  listingPriority: details.listingPriority,
                })

              return (
                <LearningCenterContentCard
                  key={details.assetKey}
                  id={details.assetKey}
                  fileType={
                    isFeaturedLearningCenterPathAsset
                      ? FileType.LearningPath
                      : (details.filetype as FileType)
                  }
                  title={
                    isFeaturedLearningCenterPathAsset
                      ? (details.learningPathTitle as string)
                      : details.title ||
                        t('LearningCenter.EmptyTitle', 'No title available')
                  }
                  description={
                    isFeaturedLearningCenterPathAsset &&
                    !!details.learningPathDescription
                      ? details.learningPathDescription
                      : details.description ||
                        t(
                          'LearningCenter.EmptyDescription',
                          'No description available'
                        )
                  }
                  subTitle={accordionSectionTitle}
                  strand={details.strand ?? ''}
                  isFavorite={details.isFavorited ?? false}
                  viewOnly={details.viewOnly ?? false}
                  isLearningPath={isFeaturedLearningCenterPathAsset}
                  handleFavoriteButtonClicked={() =>
                    updateLearningCenterAssetById(details.assetKey, {
                      isFavorited: !details.isFavorited,
                    })
                  }
                />
              )
            })}
          </AccordionDetails>
        </Accordion>
      ) : (
        <EmptyLearningCenter />
      )}
    </div>
  )
}

export default WelcomeCenterTab
