import React, { ComponentType, Context, PropsWithChildren } from 'react'
import { ScrollContext, ScrollContextProvider } from './ScrollContext'
import ScrollToTop from '../Routes/ScrollToTop'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import { LoadingContext, LoadingProvider } from './LoadingContext'
import PrivateRoute from '../Routes/PrivateRoute'
import { UserContext, UserProvider } from '../../UserContext'
import TitleContext, { TitleProvider } from '../../TitleContext'
import AccountProvider, { AccountContext } from './AccountContext'
import ProgramDetailsProvider, {
  ProgramDetailsContext,
} from './ProgramDetailsContext'
import SpeedDialMenuProvider, {
  SpeedDialMenuContext,
} from './SpeedDialMenuContext'
import { TranscriptContext, TranscriptProvider } from './TranscriptContext'
import { EventContext, EventProvider } from './EventContext'
import ContextResetter from './ContextResetter'
import { globalStyles, muiTheme } from '../../stylesheet'
import InviteProvider, { InviteContext } from './InviteContext'
import CountryProvider, { CountryContext } from './CountryContext'
import CookieBanner from '../CookieBanner'
import NotistackSnackbarKeyProvider, {
  NotistackSnackbarKeyContext,
  StyledMaterialDesignContent,
} from './NotistackSnackbarKeyProvider'
import LearningCenterProvider, {
  LearningCenterContext,
} from './LearningCenterContext'
import BusinessProvider, { BusinessContext } from './BusinessContext'
import CommunitiesProvider, { CommunitiesContext } from './CommunitiesContext'
import AuthProvider, { AuthContext } from '../Routes/AuthProvider'
import { SnackbarProvider as NotiSnackbarProvider } from 'notistack'
import { createMemoryRouter } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'
import SnackbarProvider, { SnackbarContext } from './SnackbarContext'
import { AppRenderOptions, TestWrapperProps } from '../../testRenderer'

const env = process.env.NODE_ENV

function isContext<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: Context<T>
): value is Context<T> {
  return 'Provider' in value && 'Consumer' in value
}

/** A Higher Order Component to easily mock a basic component or context */
const withMockComponent = <P extends PropsWithChildren, T>({
  Context,
  WrappedComponent,
  useMock,
  contextValue,
  router,
}: {
  Context?: Context<T>
  WrappedComponent?: ComponentType<P>
  useMock?: boolean
  contextValue?: T
  router?: typeof testRouterType | typeof browserRouterType
} = {}) => {
  const MockProvider: React.FC<PropsWithChildren> = ({ children }) => {
    if (!contextValue || !Context) return <>Context Value or Context empty!</>
    if (!!Context && isContext(Context)) {
      return (
        <Context.Provider value={contextValue}>{children}</Context.Provider>
      )
    } else {
      return <>{children}</>
    }
  }

  return (props: P) => {
    if (useMock) {
      if (!!contextValue) {
        return (
          <MockProvider>
            {props.children}
            {WrappedComponent && (
              <WrappedComponent {...props} router={router} />
            )}
          </MockProvider>
        )
      }
      return <>{props.children}</>
    }

    return WrappedComponent ? (
      <WrappedComponent {...props} router={router}>
        {props.children}
      </WrappedComponent>
    ) : (
      <>{props.children}</>
    )
  }
}

export const PublicProviders: React.FC<PropsWithChildren> = ({ children }) => {
  // mock as provided or NODE_ENV is test
  const LoadingProviderWrapper = withMockComponent({
    WrappedComponent: LoadingProvider,
    Context: LoadingContext,
    useMock: false,
  })
  const InviteProviderWrapper = withMockComponent({
    WrappedComponent: InviteProvider,
    Context: InviteContext,
    useMock: false,
  })
  const CountryProviderWrapper = withMockComponent({
    WrappedComponent: CountryProvider,
    Context: CountryContext,
    useMock: false,
  })
  return (
    <ThemeProvider theme={muiTheme}>
      {env !== 'test' && <CssBaseline />}
      <LoadingProviderWrapper>
        <InviteProviderWrapper>
          <CountryProviderWrapper>
            <>
              {children}
              {env !== 'test' && <CookieBanner />}
            </>
          </CountryProviderWrapper>
        </InviteProviderWrapper>
      </LoadingProviderWrapper>
    </ThemeProvider>
  )
}

export const PrivateProviders: React.FC<
  PropsWithChildren & AppRenderOptions
> = ({
  children,
  speedDialMenuConfig,
  accountConfig,
  programConfig,
  transcriptProviderConfig,
  eventProviderConfig,
  loadingProviderConfig,
}) => {
  const ScrollContextProviderWrapper = withMockComponent({
    WrappedComponent: ScrollContextProvider,
    Context: ScrollContext,
    useMock: false,
  })
  const ScrollToTopWrapper = withMockComponent({
    WrappedComponent: ScrollToTop,
    useMock: false,
  })
  const PrivateRouteWrapper = withMockComponent({
    WrappedComponent: PrivateRoute,
    useMock: false,
  })
  const UserProviderWrapper = withMockComponent({
    WrappedComponent: UserProvider,
    Context: UserContext,
    useMock: false,
  })
  const TitleProviderWrapper = withMockComponent({
    WrappedComponent: TitleProvider,
    Context: TitleContext,
    useMock: false,
  })
  const AccountProviderWrapper = withMockComponent({
    WrappedComponent: AccountProvider,
    Context: AccountContext,
    useMock: false,
  })
  const ProgramDetailsProviderWrapper = withMockComponent({
    WrappedComponent: ProgramDetailsProvider,
    Context: ProgramDetailsContext,
    useMock: false,
  })
  const SpeedDialMenuProviderWrapper = withMockComponent({
    WrappedComponent: SpeedDialMenuProvider,
    Context: SpeedDialMenuContext,
    useMock: false,
  })
  const TranscriptProviderWrapper = withMockComponent({
    WrappedComponent: TranscriptProvider,
    Context: TranscriptContext,
    useMock: false,
  })
  const EventProviderWrapper = withMockComponent({
    WrappedComponent: EventProvider,
    Context: EventContext,
    useMock: false,
  })
  const ContextResetterWrapper = withMockComponent({
    WrappedComponent: ContextResetter,
    useMock: false,
  })

  return (
    <ScrollContextProviderWrapper>
      <ScrollToTopWrapper />
      <ThemeProvider theme={muiTheme}>
        {env !== 'test' && <GlobalStyles styles={globalStyles} />}
        {env !== 'test' && <CssBaseline />}
        <LoadingProvider testConfig={loadingProviderConfig}>
          <PrivateRouteWrapper>
            <UserProviderWrapper>
              <TitleProviderWrapper initialTitle="">
                {/* TODO: WF 2099650 reimplement <TestTitle /> */}
                <AccountProviderWrapper testConfig={accountConfig}>
                  <ProgramDetailsProviderWrapper testConfig={programConfig}>
                    <SpeedDialMenuProviderWrapper
                      testConfig={speedDialMenuConfig}
                    >
                      <TranscriptProviderWrapper
                        testConfig={transcriptProviderConfig}
                      >
                        <EventProviderWrapper testConfig={eventProviderConfig}>
                          <ContextResetterWrapper />
                          {children}
                        </EventProviderWrapper>
                      </TranscriptProviderWrapper>
                    </SpeedDialMenuProviderWrapper>
                  </ProgramDetailsProviderWrapper>
                </AccountProviderWrapper>
              </TitleProviderWrapper>
            </UserProviderWrapper>
          </PrivateRouteWrapper>
        </LoadingProvider>
      </ThemeProvider>
    </ScrollContextProviderWrapper>
  )
}

const testRouterType = createMemoryRouter([{}])
const browserRouterType = createBrowserRouter([{}])

export const RouterProviders: React.FC<
  PropsWithChildren & {
    router: typeof testRouterType | typeof browserRouterType
  } & TestWrapperProps
> = ({
  children,
  router,
  auth,
  businessConfig,
  learningConfig,
  communitiesConfig,
  snackbarConfig,
  notistackSnackbarKeyConfig,
  notiSnackbarProviderConfig,
}) => {
  const NotistackSnackbarKeyProviderWrapper = withMockComponent({
    WrappedComponent: NotistackSnackbarKeyProvider,
    Context: NotistackSnackbarKeyContext,
    useMock: false,
  })
  const LearningCenterProviderWrapper = withMockComponent({
    WrappedComponent: LearningCenterProvider,
    Context: LearningCenterContext,
    useMock: false,
  })
  const BusinessProviderWrapper = withMockComponent({
    WrappedComponent: BusinessProvider,
    Context: BusinessContext,
    useMock: false,
  })
  const CommunitiesProviderWrapper = withMockComponent({
    WrappedComponent: CommunitiesProvider,
    Context: CommunitiesContext,
    useMock: false,
  })
  const SnackbarProviderWrapper = withMockComponent({
    WrappedComponent: SnackbarProvider,
    Context: SnackbarContext,
    useMock: false,
  })
  const AuthProviderWrapper = withMockComponent({
    WrappedComponent: AuthProvider,
    Context: AuthContext,
    useMock: false,
  })
  return (
    <ThemeProvider theme={muiTheme}>
      <NotiSnackbarProvider
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
        }}
        maxSnack={50}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={notiSnackbarProviderConfig?.onClose}
      >
        <NotistackSnackbarKeyProviderWrapper
          router={router}
          testConfig={notistackSnackbarKeyConfig}
        >
          <SnackbarProviderWrapper router={router} testConfig={snackbarConfig}>
            <LearningCenterProviderWrapper testConfig={learningConfig}>
              <BusinessProviderWrapper testConfig={businessConfig}>
                <CommunitiesProviderWrapper testConfig={communitiesConfig}>
                  <AuthProviderWrapper testConfig={auth}>
                    {children}
                  </AuthProviderWrapper>
                </CommunitiesProviderWrapper>
              </BusinessProviderWrapper>
            </LearningCenterProviderWrapper>
          </SnackbarProviderWrapper>
        </NotistackSnackbarKeyProviderWrapper>
      </NotiSnackbarProvider>
    </ThemeProvider>
  )
}

export default {
  PrivateProviders,
  PublicProviders,
  RouterProviders,
}
