import { Alert, Typography, useTheme } from '@mui/material'
import React from 'react'
import { SnackbarSeverity, SuccessIcon } from './SnackbarAlert'
import { ErrorIcon } from './ErrorAlert'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'

export interface WrappedAlertProps {
  severity: SnackbarSeverity
  message: string
  onClose?: () => void
  forAction?: JSX.Element //A button
}
const WrappedAlert: React.FC<WrappedAlertProps> = React.forwardRef<
  HTMLDivElement,
  WrappedAlertProps
>((props, ref) => {
  const { severity, message, forAction, onClose } = props
  const theme = useTheme()
  const handleOnClose = () => {
    onClose?.()
  }

  const severityColorAndIcon = {
    [SnackbarSeverity.Success]: {
      icon: <SuccessIcon />,
      color: theme.palette.secondary.main,
    },
    [SnackbarSeverity.Warning]: {
      icon: <WarningIcon />,
      color: theme.palette.textOrIcon.warning,
    },
    [SnackbarSeverity.Error]: {
      icon: <ErrorIcon />,
      color: theme.palette.error.main,
    },
    [SnackbarSeverity.Info]: {
      icon: <InfoIcon />,
      color: theme.palette.info.main,
    },
  }

  return (
    <Alert
      sx={{
        color: severityColorAndIcon[severity].color,
      }}
      ref={ref}
      elevation={6}
      severity={severity}
      icon={severityColorAndIcon[severity].icon}
      onClose={handleOnClose}
      action={!!forAction ? forAction : null}
    >
      <Typography variant="subtitle2" component="p">
        {message}
      </Typography>
    </Alert>
  )
})

export default WrappedAlert
