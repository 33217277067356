import React, { useState } from 'react'
import { Country, State, States } from '../swagger/models'
import { addressApi } from '../api/swagger'
import countriesAndStates from '../helpers/placeholderData/countriesAndStates'
import { LoadingContext } from '../components/Context/LoadingContext'
import useLoadingContext from './useLoadingContext'
import { useMountEffect } from './useMountEffect'
import { OperationIds } from '../swagger/operationIdEnum'

export const sortByName = (
  a: State | Country,
  b: State | Country
): -1 | 0 | 1 => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)

const fallbackStates = countriesAndStates.US.map(({ label, value }) => ({
  name: label,
  stateCode: value,
  countryCode: 'US',
}))

export const useStatesForCountry = (props: {
  countryCode: string
}): { defaultStates: State[]; loadingId: string } => {
  const { countryCode } = props
  const { addLoadingIds } = React.useContext(LoadingContext)
  const loadingId = OperationIds.FetchStates

  const [
    states /* `setStates` is a function provided by the `useState` hook in
  React that is used to update the state value of `states`. In
  this code, it is used to update the list of states fetched from
  the API or the fallback list of states if the API call fails. */,
    setStates,
  ] = useState<State[]>([])
  const fetchStates = async () => {
    if (!!countryCode) {
      let fetchedStates: States
      try {
        fetchedStates = await addressApi.fetchStates({
          countryCode: countryCode,
        })
      } catch {
        fetchedStates = {}
      }

      setStates(fetchedStates?.data ?? fallbackStates)
    }
  }

  useMountEffect(() => {
    if (countryCode !== '') {
      addLoadingIds([loadingId])
    }
  })

  useLoadingContext({
    asyncFunction: fetchStates,
    loadingId: loadingId,
  })

  return { defaultStates: states.sort(sortByName), loadingId }
}

export default useStatesForCountry
