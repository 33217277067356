import { useMediaQuery, useTheme } from '@mui/material'
import Fab from '@mui/material/Fab'
import SvgIcon from '@mui/material/SvgIcon'
import React, { CSSProperties } from 'react'
import { Link } from 'react-router-dom'

const helpers: Record<string, (spacing: string) => Record<string, string>> = {}
const properties = ['margin', 'inset']
const flows = ['inline', 'block']
const ends = ['start', 'end']
const capitalize = (s: string): string =>
  s ? `${s.charAt(0).toUpperCase()}${s.slice(1)}` : s
for (const property of properties) {
  for (const flow of flows) {
    for (const end of ends) {
      const key = `${property}${capitalize(flow)}${capitalize(end)}`
      const css = `${property}-${flow}-${end}`
      const direction =
        flow === 'inline'
          ? end === 'start'
            ? 'left'
            : 'right'
          : end === 'start'
          ? 'top'
          : 'bottom'
      const alternativeKey =
        property === 'inset' ? direction : `${property}${capitalize(direction)}`
      helpers[key] = (spacing: string) =>
        ({
          [`@supports (${css}: 1px)`]: {
            [key]: spacing,
          },
          [`@supports not (${css}: 1px)`]: {
            [alternativeKey]: spacing,
          },
        } as unknown as Record<string, string>) // let's just lie about this type
    }
  }
}

/**
 * A Fab that, when the screen is wide enough, is positioned to overlap the AppBar/Content transition.
 * It handles adjusting for the varying AppBar heights, as well as shrinking and relocating as the screen gets smaller.
 */
export const ResponsiveFab: React.FunctionComponent<{
  icon: typeof SvgIcon
  /** Workaround for the Material icons embedding some space that we have to factor into on a case-by-case basis in order to achieve the intended 12px margin between the icon and label.
   *
   * Unit is px.
   *
   * Default value is 0; it'll be used as `icon-[12+fudge]-label`.
   *
   * You'll have to examine the SVG vs the path sizes and do the math as needed. :\ */
  spacingFudgeFactor?: number
  /** Partial label shown on the icon in the extended variant. */
  iconLabel: string
  /** Partial label shown in the extended variant alongside the icon. The icon is assumed to have a titleAccess property if required. */
  textLabel: string
  /** Localized label used as the aria-label for the overall Fab when not in extended variant. */
  fullLabel: string
  to?: string
  onClick?: () => void
  sx?: CSSProperties
}> = (props) => {
  const {
    icon: Icon,
    spacingFudgeFactor,
    iconLabel,
    textLabel,
    fullLabel,
    to,
    onClick,
    sx,
    ...rest
  } = {
    spacingFudgeFactor: 0,
    ...props,
  }
  const isExtended = useMediaQuery('(min-width: 700px)')
  const theme = useTheme()
  return (
    <Fab
      component={!!to ? Link : 'button'}
      to={!!to ? to : undefined}
      onClick={onClick}
      variant={isExtended ? 'extended' : 'circular'}
      color="secondary"
      sx={{
        color: 'white',
        position: 'fixed',
        ...(isExtended && {
          insetBlockStart: theme.spacing(4.5),
          insetInlineEnd: theme.spacing(10),
        }),
        /**
         * HelpScout beacon uses a zIndex of 1049 so we set the FAB's zIndex lower to avoid overlap.
         */
        zIndex: 1020,
        ...(isExtended && {
          ...helpers.insetInlineEnd(theme.spacing(11)),
          ...helpers.insetBlockStart(theme.spacing(5)),
        }),
        ...(!isExtended && {
          /**
           * These styles come from inspecting styles on the HelpScout Beacon.
           * Using similar styles for positioning/sizing the FAB on the left side for mobile screen sizes.
           * These will need updating if the HelpScout styles change in the future.
           */
          width: theme.spacing(7.5),
          height: theme.spacing(7.5),
          left: theme.spacing(5),
          bottom: theme.spacing(5),
          // eslint-disable-next-line no-useless-computed-key
          ['@media (max-height: 740px)']: {
            left: theme.spacing(2.5),
            bottom: theme.spacing(1.25),
          },
          ...sx,
        }),
      }}
      aria-label={fullLabel}
      {...rest}
    >
      <Icon
        sx={{
          ...(isExtended && {
            ...helpers.marginInlineEnd(
              theme.spacing(1.5) + (spacingFudgeFactor ?? 0)
            ),
          }),
        }}
        titleAccess={isExtended ? iconLabel : undefined}
      />
      {isExtended ? textLabel : null}
    </Fab>
  )
}

export default ResponsiveFab
