import React from 'react'
import { useTheme } from '@mui/material/styles'
import BrandCard from './BrandCard'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const heightOfSmallestMessageCard = 403

export const MessageCard: React.FC<{
  heading: string
  body?: JSX.Element
  additionalHeaderContent?: JSX.Element
  actionBtn?: JSX.Element
}> = ({
  heading,
  body = '',
  additionalHeaderContent,
  actionBtn = undefined,
}) => {
  const theme = useTheme()
  return (
    <BrandCard
      minimumScreenHeight={heightOfSmallestMessageCard}
      additionalHeaderContent={
        <Box
          sx={{
            maxWidth: 380,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{
              color: theme.palette.primary.main,
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(2),
              display: 'block',
            }}
          >
            {heading}
          </Typography>
        </Box>
      }
      cardContent={
        additionalHeaderContent ?? (
          <Box
            sx={{
              maxWidth: 420,
              marginLeft: 'auto',
              marginRight: 'auto',
              color: theme.palette.primary.main,
            }}
          >
            {body}
          </Box>
        )
      }
      cardActions={actionBtn}
      display={'flex'}
    />
  )
}

export default MessageCard
