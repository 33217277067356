import { useMemo, useState } from 'react'
import { AuthFormInfo } from '../components/Login/AuthFormCard'
import {
  PasswordFieldsProps,
  PasswordFieldIds,
} from '../components/Login/PasswordFields'
import { validateEmailText } from '../helpers/validateEmail'

const validatePassword = (str: string): boolean => {
  const contains8chars = str.length >= 8
  const contains128charsOrLess = str.length <= 128
  const containsUppercase = new RegExp('[A-Z]+').test(str)
  const containsLowercase = new RegExp('[a-z]+').test(str)

  return (
    contains8chars &&
    contains128charsOrLess &&
    containsLowercase &&
    containsUppercase
  )
}

interface EmailFieldStates {
  emptyEmailField: boolean
  email: string
  handleClick: () => Promise<void>
  textErrorEmail: string
  handleValidateEmail: () => string
}

export const useEmailPasswordStates = (opts: {
  includePasswordConfirm: boolean
  includeNewPasswordConfirm?: boolean
  handleAuthInfoUpdate: (id: string, value: string) => void
  authenticationInfo: AuthFormInfo
  handleClickFromParent: (
    email: string,
    password: string,
    newPassword?: string
  ) => Promise<void>
  handleKeyboardEvent?: () => Promise<void>
}): {
  passwordFieldProps: PasswordFieldsProps
  emailFieldStates: EmailFieldStates
} => {
  const {
    includePasswordConfirm,
    includeNewPasswordConfirm,
    handleAuthInfoUpdate,
    authenticationInfo,
    handleClickFromParent,
    handleKeyboardEvent: handleKeyboardEventFromParent,
  } = opts
  const [emptyEmailField, setEmptyEmailField] = useState(false)

  const password = useMemo(
    () => authenticationInfo.password,
    [authenticationInfo]
  )
  const passwordConfirm = useMemo(
    () => authenticationInfo.passwordConfirm,
    [authenticationInfo]
  )
  const newPassword = useMemo(
    () => authenticationInfo.newPassword,
    [authenticationInfo]
  )
  const newPasswordConfirm = useMemo(
    () => authenticationInfo.newPasswordConfirm,
    [authenticationInfo]
  )

  const email = useMemo(() => authenticationInfo.email, [authenticationInfo])

  const [textErrorEmail, setTextErrorEmail] = useState('')
  const [passwordInvalid, setPasswordInvalid] = useState(false)
  const [newPasswordInvalid, setNewPasswordInvalid] = useState(false)
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false)
  const [newPasswordsDontMatch, setNewPasswordsDontMatch] = useState(false)
  const [emptyPasswordConfirmField, setEmptyPasswordConfirmField] =
    useState(false)
  const [emptyPasswordField, setEmptyPasswordField] = useState(false)
  const [emptyNewPasswordField, setEmptyNewPasswordField] = useState(false)
  const [emptyNewPasswordConfirmField, setEmptyNewPasswordConfirmField] =
    useState(false)
  const passwordIsEmptyOrInvalid =
    emptyPasswordField || (includePasswordConfirm && passwordInvalid)
  const newPasswordIsEmptyOrInvalid =
    emptyNewPasswordField || (includeNewPasswordConfirm && newPasswordInvalid)

  const handleInputEvent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value, id } = event.target
    interface InputHandlerType {
      [property: string]: (str: string) => void
    }

    const inputEventHandlersById: InputHandlerType = {
      email: (str) => handleAuthInfoUpdate('email', str),
      password: (str) => handleAuthInfoUpdate('password', str),
      passwordConfirm: (str) => handleAuthInfoUpdate('passwordConfirm', str),
      newPassword: (str) => handleAuthInfoUpdate('newPassword', str),
      newPasswordConfirm: (str) =>
        handleAuthInfoUpdate('newPasswordConfirm', str),
    }

    switch (event.type) {
      case 'change':
        inputEventHandlersById[id](value)
        break
      case 'blur':
        inputEventHandlersById[id](value.trim())
        break
    }

    switch (id) {
      case PasswordFieldIds.PasswordConfirm:
        setEmptyPasswordConfirmField(false)
        setPasswordsDontMatch(false)
        break
      case PasswordFieldIds.Password:
        setEmptyPasswordField(false)
        setPasswordsDontMatch(false)
        setPasswordInvalid(false)
        break
      case PasswordFieldIds.NewPassword:
        setNewPasswordsDontMatch(false)
        setNewPasswordInvalid(false)
        setEmptyNewPasswordField(false)
        break
      case PasswordFieldIds.NewPasswordConfirm:
        setNewPasswordsDontMatch(false)
        setEmptyNewPasswordConfirmField(false)
        break
      default:
        setEmptyEmailField(false)
    }
  }

  const handleKeyboardEvent = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter') {
      handleKeyboardEventFromParent?.()
      // Parent is now responsible for calling the handleClick method given the state changes when submitting login. This way we prevent duplicate submissions for endpoints.
    }
  }

  const clearErrors = () => {
    setEmptyPasswordField(false)
    setEmptyNewPasswordField(false)
    setEmptyNewPasswordConfirmField(false)
    setPasswordInvalid(false)
    setNewPasswordInvalid(false)
    setNewPasswordsDontMatch(false)
  }

  //
  const handleValidateEmail = () => {
    const msgError = validateEmailText(email) ?? ''
    setTextErrorEmail(msgError)
    setEmptyEmailField(msgError !== '')
    return msgError
  }
  //

  const handleClick = async () => {
    setEmptyEmailField(!email)

    const msgError = validateEmailText(email) ?? ''
    setTextErrorEmail(msgError)
    setEmptyEmailField(msgError !== '')

    setEmptyPasswordField(!password)
    setEmptyPasswordConfirmField(!passwordConfirm)
    setEmptyNewPasswordField(!newPassword)
    setEmptyNewPasswordConfirmField(!newPasswordConfirm)

    const passwordValid = validatePassword(password)
    setPasswordInvalid(!passwordValid)

    const newPasswordValid = validatePassword(newPassword ?? '')
    setNewPasswordInvalid(!newPasswordValid)

    const passwordsMatch = password === passwordConfirm
    setPasswordsDontMatch(!passwordsMatch)

    const newPasswordsMatch = newPassword === newPasswordConfirm
    setNewPasswordsDontMatch(!newPasswordsMatch)

    let fieldsEmpty = !email || !password

    if (includePasswordConfirm) {
      fieldsEmpty = fieldsEmpty || !passwordConfirm
    }
    if (
      includePasswordConfirm
        ? fieldsEmpty || !passwordValid || !passwordsMatch
        : includeNewPasswordConfirm
        ? !password || !newPasswordValid || !newPasswordsMatch
        : fieldsEmpty
    ) {
      // Validation errors will be shown per the setEmpty… states.
      return
    }

    /* We trim the email/password to account for user mistake. */
    const trimmedEmail = email.trim()
    const trimmedPassword = password.trim()
    const trimmedNewPassword = (newPassword ?? '').trim()

    await handleClickFromParent(
      trimmedEmail,
      trimmedPassword,
      trimmedNewPassword
    )
  }

  let passwordFieldProps: PasswordFieldsProps = {
    handleInputEvent,
    passwordsDontMatch,
    newPasswordsDontMatch,
    emptyPasswordConfirmField,
    emptyPasswordField,
    emptyNewPasswordConfirmField,
    emptyNewPasswordField,
    includePasswordConfirm,
    includeNewPasswordConfirm,
    handleKeyboardEvent,
    passwordIsEmptyOrInvalid,
    newPasswordIsEmptyOrInvalid,
    passwordInvalid,
    newPasswordInvalid,
    password,
    passwordConfirm,
    newPassword,
    newPasswordConfirm,
    clearErrors,
  }

  if (includePasswordConfirm || includeNewPasswordConfirm) {
    passwordFieldProps = {
      ...passwordFieldProps,
      setPasswordInvalid,
      setPasswordsDontMatch,
      setEmptyPasswordConfirmField,
      setEmptyPasswordField,
    }
  }

  const emailFieldStates: EmailFieldStates = {
    emptyEmailField,
    email,
    handleClick,
    textErrorEmail,
    handleValidateEmail,
  }

  return { passwordFieldProps, emailFieldStates }
}

export default useEmailPasswordStates
