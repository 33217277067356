import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import {
  LocalPermissionGrant,
  LocalScopeEnum,
  labelForScope,
} from '../../Card/RoleCard'
import { BasePermissionProps } from './PermissionAccordion'
import { styled } from '@mui/system'

/**
 * !!! Attempting to use props of the styled Element results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const PermissionFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1.5, 0),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(0.5, 0),
  },
})) as typeof FormControl

/**
 * !!! Attempting to use props of the styled Element results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const PermissionFormLabel = styled(FormLabel)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(28),
    marginRight: 32,
  },
  color: theme.palette.primary.main,
})) as typeof FormLabel

const PermissionFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(0, 3),
    width: theme.spacing(12),
    justifyContent: 'center',
  },
})) as typeof FormControlLabel

interface PermissionGroupProps extends BasePermissionProps {
  permissionGrant: LocalPermissionGrant
}

const PermissionGroup: React.FC<PermissionGroupProps> = ({
  permissionGrant,
  isFieldDisabled,
  handleChange,
}) => {
  const isDesktopView = useShowOnDesktop()
  const { t } = useTranslation()
  const theme = useTheme()

  const { permissionName, actionCode, resourceCode, scopeCode } =
    permissionGrant

  return (
    <PermissionFormControl component="fieldset">
      <PermissionFormLabel component="div">
        <Typography variant="body2">{permissionName}</Typography>
      </PermissionFormLabel>
      <RadioGroup
        row={isDesktopView}
        name={permissionName}
        aria-label={permissionName}
        value={scopeCode}
        onChange={(_, value) =>
          handleChange(value as LocalScopeEnum, actionCode, resourceCode)
        }
      >
        {Object.values(LocalScopeEnum).map((scope) => (
          <PermissionFormControlLabel
            key={scope}
            value={scope}
            control={
              <Radio
                disabled={isFieldDisabled}
                inputProps={{
                  'aria-label': labelForScope(scope, t),
                }}
                color="secondary"
              />
            }
            label={
              !isDesktopView && (
                <Typography
                  variant="subtitle2"
                  component="span"
                  color={theme.palette.primary.main}
                >
                  {labelForScope(scope, t)}
                </Typography>
              )
            }
          />
        ))}
      </RadioGroup>
    </PermissionFormControl>
  )
}

export default React.memo(PermissionGroup)
