import { styled } from '@mui/system'
import Paper from '@mui/material/Paper'
import Header, { HeaderVariant } from '../Elements/Header'
import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useState } from 'react'
import CardFormHeader from './CardFormHeader'
import DateField, { DateFieldVariant } from '../Elements/DateField'
import {
  Box,
  Pagination,
  TableContainer,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import { FieldValidity } from '../Interfaces/FieldValidity'
import { dateToDashStringReinterpretedAsLocal } from '../../utils/dateUtility'
import useValidationMessages from '../../hooks/useValidationMessages'
import { useTranscriptContext } from '../Context/TranscriptContext'
import LedgerTable, { LedgerCell, LedgerRow } from '../Table/LedgerTable'
import TableHeaders from '../Interfaces/TableHeaders'
import { useFetchGradingScales } from '../../hooks/useFetchGradingScales'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import {
  GradingScaleWeight,
  TranscriptYear,
  TranscriptYearCourseWork,
} from '../../swagger'
import {
  calculateTranscriptCredits,
  calculateTranscriptGPA,
  calculateYearCredits,
  calculateYearGPA,
} from '../../utils/calculateGPA'
import ConfirmWithButtonsModal from '../Modals/ConfirmWithButtonsModal'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { removeCourseWeight } from '../../utils/transcriptData'

export interface AcademicSummaryCardProps {
  /**
   * Determines if fields should be editable, and, if Edit button for addresses are enabled
   */
  isFieldDisabled?: boolean
}

const AcademicSummaryPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(3),
  padding: theme.spacing(4, 4, 5.5),
}))

enum AcademicSummaryValidationMessageTypes {
  Default = 'default',
  DateHighSchoolEntry = 'dateHighSchoolEntry',
  DateGraduation = 'dateGraduation',
}

export const AcademicSummaryCard: React.FC<AcademicSummaryCardProps> = ({
  isFieldDisabled,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDesktopView = useShowOnDesktop()
  const {
    updateTranscriptDetails,
    transcriptDetails,
    gradingScales,
    transcriptValidity,
  } = useTranscriptContext()

  const [scaleDetails, setScaleDetails] = useState([] as LedgerRow[][])
  const [scaleWeights, setScaleWeights] = useState([] as LedgerRow[][])

  const [currentPage, setCurrentPage] = useState(1)

  useFetchGradingScales()

  const headerLabels = {
    gradingScale: t(
      'AcademicSummaryCard.TableHeader.GradeScale',
      'Grade Scale'
    ),
    letterGrade: t(
      'AcademicSummaryCard.TableHeader.LetterGrade',
      'Letter Grade'
    ),
    credit: t('AcademicSummaryCard.TableHeader.GradePoints', 'Grade Points'),
    weightName: t('AcademicSummaryCard.TableHeader.WeightName', 'Weight Name'),
    weight: t('AcademicSummaryCard.TableHeader.Weight', 'Weight'),
  }

  const gradingScaleDetailHeaders: TableHeaders[] = [
    {
      label: headerLabels.gradingScale,
      align: 'left',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {headerLabels.gradingScale}
        </Typography>
      ),
    },
    {
      label: headerLabels.letterGrade,
      align: 'left',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {headerLabels.letterGrade}
        </Typography>
      ),
    },
    {
      label: headerLabels.credit,
      align: 'left',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {headerLabels.credit}
        </Typography>
      ),
    },
  ]

  const gradingScaleWeightsHeaders: TableHeaders[] = [
    {
      label: headerLabels.weightName,
      align: 'left',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {headerLabels.weightName}
        </Typography>
      ),
    },
    {
      label: headerLabels.weight,
      align: 'left',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
        >
          {headerLabels.weight}
        </Typography>
      ),
    },
  ]

  useEffect(() => {
    setScaleDetails([])
    setScaleWeights([])
    const paddingSpacing = theme.spacing(1)
    gradingScales.map((gradingScale) => {
      if (gradingScale.gradingScaleDetails) {
        const newDetailRow: LedgerRow[] = gradingScale.gradingScaleDetails.map(
          (gradingScaleDetails) => {
            return {
              cells: [
                {
                  content: gradingScaleDetails.scaleRange,
                  align: 'left',
                  cssTextProps: { pl: theme.spacing(2) },
                  cellCssProps: {
                    paddingTop: paddingSpacing,
                    paddingBottom: paddingSpacing,
                  },
                } as LedgerCell,
                {
                  content: gradingScaleDetails.letterGrade,
                  align: 'left',
                  cellCssProps: {
                    paddingTop: paddingSpacing,
                    paddingBottom: paddingSpacing,
                  },
                } as LedgerCell,
                {
                  content: gradingScaleDetails.credit?.toString(),
                  align: 'left',
                  cellCssProps: {
                    paddingTop: paddingSpacing,
                    paddingBottom: paddingSpacing,
                  },
                } as LedgerCell,
              ],
            }
          }
        )
        setScaleDetails((table) => [...table, newDetailRow])
      }
      if (gradingScale.gradingScaleWeight) {
        const newWeightRow: LedgerRow[] = gradingScale.gradingScaleWeight.map(
          (gradingScaleWeights) => {
            return {
              cells: [
                {
                  content: gradingScaleWeights.weightName,
                  align: 'left',
                  cssTextProps: { pl: theme.spacing(2) },
                  cellCssProps: {
                    paddingTop: paddingSpacing,
                    paddingBottom: paddingSpacing,
                  },
                } as LedgerCell,
                {
                  content: gradingScaleWeights.weight.toString(),
                  align: 'left',
                  cellCssProps: {
                    paddingTop: paddingSpacing,
                    paddingBottom: paddingSpacing,
                  },
                } as LedgerCell,
              ],
            }
          }
        )
        setScaleWeights((table) => [...table, newWeightRow])
      }
    })
  }, [theme, gradingScales])

  const [academicSummaryValidity, setAcademicSummaryValidity] = useState<{
    DateHighSchoolEntry: FieldValidity
    DateGraduation: FieldValidity
  }>({
    DateHighSchoolEntry: { input: true, afterMin: true, beforeMax: true },
    DateGraduation: { input: true, afterMin: true, beforeMax: true },
  })

  const validationMessageMap = useValidationMessages([
    {
      field: AcademicSummaryValidationMessageTypes.Default,
      message: t(
        'Transcript.AcademicSummary.ErrorMessage',
        'Something went wrong. Please make sure you have filled out required fields.'
      ),
    },
    {
      field: AcademicSummaryValidationMessageTypes.DateHighSchoolEntry,
      message: t(
        'Transcript.AcademicSummary.DateHighSchoolEntry',
        'Please enter a valid High School Entry Date.'
      ),
    },
    {
      field: AcademicSummaryValidationMessageTypes.DateGraduation,
      message: t(
        'Transcript.AcademicSummary.DateGraduation',
        'Please enter a valid Graduation Date.'
      ),
    },
  ])

  const handleHelperText = useCallback(
    (dateType: FieldValidity) => {
      if (!dateType.input || !transcriptValidity.dateGraduation.input) {
        switch (dateType) {
          case academicSummaryValidity.DateHighSchoolEntry:
            return validationMessageMap.get(
              AcademicSummaryValidationMessageTypes.DateHighSchoolEntry
            )
          case academicSummaryValidity.DateGraduation:
            return validationMessageMap.get(
              AcademicSummaryValidationMessageTypes.DateGraduation
            )
        }
      }
      return null
    },
    [
      academicSummaryValidity,
      validationMessageMap,
      transcriptValidity.dateGraduation.input,
    ]
  )

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
    validity?: FieldValidity
  ) => {
    let valueToSet: string | Date = event.target.value

    if (event.target.name.startsWith('date'))
      valueToSet = new Date(valueToSet as string)

    updateTranscriptDetails({
      ...transcriptDetails,
      [event.target.name]: valueToSet,
    })

    setAcademicSummaryValidity((prevAcademicSummaryValidity) => {
      return {
        ...prevAcademicSummaryValidity,
        [event.target.name]: validity ?? {
          input: true,
          afterMin: true,
          beforeMax: true,
        },
      }
    })
  }

  const changePage = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageToChange(value)
    setIsConfirmModalOpen(true)
  }

  const clearYearGrades = (
    courseWork?: TranscriptYearCourseWork[],
    oldGradingScaleWeights?: GradingScaleWeight[]
  ): TranscriptYearCourseWork[] => {
    return courseWork
      ? courseWork.map((course) => ({
          ...course,
          letterGrade: '',
          courseName: removeCourseWeight(
            course.courseName,
            oldGradingScaleWeights
          ),
        }))
      : []
  }

  const changeGradingScalePage = (page: number) => {
    setCurrentPage(page)

    const newGradingScale = gradingScales[page - 1]

    const clearGradesAndWeights = (
      transcriptYear?: TranscriptYear
    ): TranscriptYear | undefined => {
      const newCourseWork = clearYearGrades(
        transcriptYear?.transcriptYearCourseWork,
        transcriptDetails.gradingScale?.gradingScaleWeight
      )

      return {
        ...transcriptYear,
        transcriptYearCourseWork: newCourseWork,
        gpa: calculateYearGPA(newCourseWork, newGradingScale),
        totalCredits: calculateYearCredits(newCourseWork),
      } as TranscriptYear
    }

    const newTranscript = {
      ...transcriptDetails,
      transcriptYear1: clearGradesAndWeights(transcriptDetails.transcriptYear1),
      transcriptYear2: clearGradesAndWeights(transcriptDetails.transcriptYear2),
      transcriptYear3: clearGradesAndWeights(transcriptDetails.transcriptYear3),
      transcriptYear4: clearGradesAndWeights(transcriptDetails.transcriptYear4),
    }

    updateTranscriptDetails({
      ...newTranscript,
      gpa: calculateTranscriptGPA(newTranscript, newGradingScale),
      totalCredits: calculateTranscriptCredits(newTranscript),
      gradingScaleKey: newGradingScale.gradingScaleKey,
      gradingScale: newGradingScale,
    })
  }

  useEffect(() => {
    if (transcriptDetails.gradingScale?.gradingScaleKey) {
      const foundGradingScaleIndex = gradingScales.findIndex(
        (gradingScale) =>
          gradingScale.gradingScaleKey ===
          transcriptDetails.gradingScale?.gradingScaleKey
      )
      setCurrentPage(foundGradingScaleIndex + 1)
    }
  }, [transcriptDetails, gradingScales])

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [pageToChange, setPageToChange] = useState(0)

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
  }

  return (
    <AcademicSummaryPaper>
      {/* Confirm Changing Grading Scales Modal */}
      <ConfirmWithButtonsModal
        isOpen={isConfirmModalOpen}
        title={t(
          'Transcripts.AcademicSummaryCard.Modal.Title',
          'Change Grading Scale'
        )}
        question={t(
          'Transcripts.AcademicSummaryCard.Modal.Question',
          'Are you sure you want to change the grading scale? All grades that were previously entered will be removed.'
        )}
        confirmVariant={ContainedButtonVariant.YesImSure}
        cancelVariant={TextButtonVariant.NoCancel}
        onConfirm={() => {
          setIsConfirmModalOpen(false)
          changeGradingScalePage(pageToChange)
        }}
        onCancel={() => setIsConfirmModalOpen(false)}
        questionProps={{ textAlign: 'center' }}
      />

      {/* Academic Summary - Header */}

      <CardFormHeader
        maintainRow
        header={
          <Header
            headerName={t(
              'Transcripts.AcademicSummaryCard.Header.AcademicSummary',
              'Academic Summary'
            )}
            component="h2"
            variant={HeaderVariant.Card}
          />
        }
      />

      <Box
        id="boxDates"
        sx={{
          display: 'grid',
          gridColumn: 2,
          gridRow: 2,
          marginTop: 2,
          paddingLeft: 1,
          marginBottom: 1,
        }}
      >
        <DateField
          sx={{
            gridColumn: 1,
            gridRow: 1,
            padding: 1,
          }}
          id="dateHighSchoolEntry"
          inputProps={{
            'aria-label': t(
              'Transcripts.AcademicSummaryCard.DateOfHighSchoolEntry',
              'Date of High School Entry'
            ),
          }}
          disabled={isFieldDisabled}
          variant="filled"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={
            transcriptDetails.dateHighSchoolEntry
              ? dateToDashStringReinterpretedAsLocal(
                  transcriptDetails.dateHighSchoolEntry
                )
              : ''
          }
          name="dateHighSchoolEntry"
          onChangeForParent={handleChange}
          helperTextForParent={handleHelperText(
            academicSummaryValidity.DateHighSchoolEntry
          )}
          error={!academicSummaryValidity.DateHighSchoolEntry.input}
          dateVariant={DateFieldVariant.DateOfHighSchoolEntry}
          onKeyDown={onKeyDown}
        />

        <DateField
          sx={{
            gridColumn: 2,
            gridRow: 1,
            padding: 1,
          }}
          id="dateGraduation"
          inputProps={{
            'aria-label': t(
              'Transcripts.AcademicSummaryCard.DateOfGraduation',
              'Date of Graduation'
            ),
          }}
          disabled={isFieldDisabled}
          variant="filled"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={
            transcriptDetails.dateGraduation
              ? dateToDashStringReinterpretedAsLocal(
                  transcriptDetails.dateGraduation
                )
              : ''
          }
          name="dateGraduation"
          onChangeForParent={handleChange}
          helperTextForParent={handleHelperText(
            academicSummaryValidity.DateGraduation
          )}
          error={
            !academicSummaryValidity.DateGraduation.input ||
            !transcriptValidity.dateGraduation.input
          }
          dateVariant={DateFieldVariant.DateOfGraduation}
          onKeyDown={onKeyDown}
        />
      </Box>

      {/* Select grade scale - Heading */}
      <Box
        display="flex"
        justifyContent="center"
        marginTop={theme.spacing(4.5)}
      >
        <Typography
          variant="button"
          fontSize={theme.spacing(1.75)}
          lineHeight="21px"
          marginRight="3px"
          letterSpacing="1px"
          color={theme.palette.textOrIcon.tableHeader}
        >
          {t(
            'Transcript.AcademicSummaryCard.SelectAGradeScale',
            'Select a Grading Scale'
          )}
        </Typography>
        <Tooltip
          title={t(
            'Transcript.AcademicSummaryCard.SelectAGradeScale.Tooltip',
            'Select a number on the grading scale to determine how the grades are weighted. This will affect the GPA.'
          )}
        >
          <InfoIcon
            aria-label="tooltipIcon"
            sx={{
              fontSize: '24px',
              color: theme.palette.textOrIcon.tooltipButton,
            }}
          />
        </Tooltip>
      </Box>

      {/* Numeric selection - Pagination */}
      <Box display="flex" justifyContent="center" paddingTop="27px">
        <Pagination
          disabled={isFieldDisabled}
          size="medium"
          count={scaleDetails.length}
          boundaryCount={scaleDetails.length}
          page={currentPage}
          onChange={changePage}
        />
      </Box>

      {/* Grade Scale / Letter Grade / Credit - Table */}
      <TableContainer
        sx={{
          mx: theme.spacing(3),
          width: 'auto',
          display: 'flex',
          flexDirection: isDesktopView ? 'row' : 'column',
          gap: isDesktopView ? theme.spacing(2) : undefined,
        }}
      >
        <LedgerTable
          ariaLabel="children-table"
          tableHeaders={gradingScaleDetailHeaders}
          rows={scaleDetails[currentPage - 1]}
        />

        <LedgerTable
          cssProps={{
            maxWidth: isDesktopView ? '30%' : undefined,
            maxHeight: '0px',
          }}
          emptyRowCssProps={{
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          }}
          ariaLabel="grade-scale-weight"
          tableHeaders={gradingScaleWeightsHeaders}
          rows={scaleWeights[currentPage - 1]}
        />
      </TableContainer>
      {/* Total GPA - Total Credits */}
      <Box
        display="flex"
        sx={{
          paddingInline: theme.spacing(3),
          gap: theme.spacing(4),
        }}
      >
        <TextField
          id="gpa"
          aria-label="gpa"
          variant="filled"
          label={t('Transcript.AcademicSummaryCard.TextField.GPA', 'GPA')}
          value={transcriptDetails.gpa}
          InputLabelProps={{ shrink: true }}
          disabled
          fullWidth
        />
        <TextField
          id="totalCredits"
          aria-label="totalCredits"
          variant="filled"
          label={t(
            'Transcript.AcademicSummaryCard.TextField.Credits',
            'Credits'
          )}
          value={transcriptDetails.totalCredits}
          InputLabelProps={{ shrink: true }}
          disabled
          fullWidth
        />
      </Box>
      {/* Summary Field - Textfield */}
      <TextField
        id="summary"
        name="summary"
        inputProps={{
          maxLength: 2500,
        }}
        disabled={isFieldDisabled}
        variant="filled"
        label={t('Transcript.AcademicSummaryCard.Summary', 'Summary')}
        multiline
        rows={12}
        fullWidth
        sx={{
          marginTop: theme.spacing(3),
        }}
        onChange={handleChange}
        value={transcriptDetails.summary}
      />
    </AcademicSummaryPaper>
  )
}
