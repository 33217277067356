import React, { useRef, useState } from 'react'
import ClassInformationHeader from './ClassInformationHeader'
import ClassInformationCard from '../Card/ClassInformationCard'
import { Box } from '@mui/system'
import { useTranscriptContext } from '../Context/TranscriptContext'
import PrepopulateClassInformationModal, {
  Grades,
  TranscriptPrograms,
  TranscriptYears,
} from '../Modals/PrepopulateClassInformationModal'
import { useFetchCourseOfStudies } from '../../hooks/useFetchCourseOfStudies'
import ConfirmWithButtonsModal from '../Modals/ConfirmWithButtonsModal'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'

const classInformationCardCSSProps = {
  display: 'flex',
  flexDirection: { xs: 'column', lg: 'row' },
  gap: '20px',
}

interface ClassInformationProps {
  isFieldDisabled: boolean
}

const ClassInformation: React.FC<ClassInformationProps> = ({
  isFieldDisabled,
}) => {
  const { transcriptDetails, updateTranscriptDetails } = useTranscriptContext()
  const [prepopulateModalOpen, setPrepopulateModalOpen] = useState(false)
  const { fetchCourseOfStudies } = useFetchCourseOfStudies()
  const { t } = useTranslation()

  const allGrades = Object.values(Grades)

  const [prepoulateGrades, setPrepopulateGrades] = useState(allGrades)

  const clearAllFields = () => {
    updateTranscriptDetails({
      ...transcriptDetails,
      transcriptYear1: undefined,
      transcriptYear2: undefined,
      transcriptYear3: undefined,
      transcriptYear4: undefined,
    })
  }

  const openPrepopulate = (grades: Grades[]) => {
    setPrepopulateGrades(grades)
    setPrepopulateModalOpen(true)
  }

  const [showConfirmPrepopulateModal, setShowConfirmPrepopulateModal] =
    useState(false)

  const prepopulateData = useRef<[TranscriptYears, TranscriptPrograms]>()

  return (
    <>
      <ConfirmWithButtonsModal
        isOpen={showConfirmPrepopulateModal}
        title={t(
          'Transcripts.ClassInformation.ConfirmPrepopulateModal.Title',
          'Prepopulate'
        )}
        question={t(
          'Transcripts.ClassInformation.ConfirmPrepopulateModal.Question',
          'Are you sure you want to prepopulate? This will overwrite existing data for the year(s) you are prepopulating.'
        )}
        confirmVariant={ContainedButtonVariant.YesImSure}
        cancelVariant={TextButtonVariant.Cancel}
        onConfirm={async () => {
          if (prepopulateData.current) {
            const [transcriptYears, transcriptPrograms] =
              prepopulateData.current
            await fetchCourseOfStudies(transcriptYears, transcriptPrograms)
            setShowConfirmPrepopulateModal(false)
          }
        }}
        onCancel={() => {
          setShowConfirmPrepopulateModal(false)
        }}
        questionProps={{ textAlign: 'center' }}
      />
      <PrepopulateClassInformationModal
        isOpen={prepopulateModalOpen}
        onPopulate={(transcriptYears, transcriptPrograms) => {
          prepopulateData.current = [transcriptYears, transcriptPrograms]
          setPrepopulateModalOpen(false)
          setShowConfirmPrepopulateModal(true)
        }}
        onCancel={() => setPrepopulateModalOpen(false)}
        inputGrades={prepoulateGrades}
      />
      <Box
        sx={{
          ...classInformationCardCSSProps,
          flexDirection: 'column',
        }}
      >
        <ClassInformationHeader
          prepopulateFields={() => {
            openPrepopulate(allGrades)
          }}
          clearAllFields={clearAllFields}
          isFieldDisabled={isFieldDisabled}
        />

        <Box sx={classInformationCardCSSProps}>
          <ClassInformationCard
            isFieldDisabled={isFieldDisabled}
            transcriptYear={1}
            onPrepopulate={() => {
              openPrepopulate([Grades.Ninth])
            }}
          />
          <ClassInformationCard
            isFieldDisabled={isFieldDisabled}
            transcriptYear={2}
            onPrepopulate={() => {
              openPrepopulate([Grades.Tenth])
            }}
          />
        </Box>
        <Box sx={classInformationCardCSSProps}>
          <ClassInformationCard
            isFieldDisabled={isFieldDisabled}
            transcriptYear={3}
            onPrepopulate={() => {
              openPrepopulate([Grades.Eleventh])
            }}
          />
          <ClassInformationCard
            isFieldDisabled={isFieldDisabled}
            transcriptYear={4}
            onPrepopulate={() => {
              openPrepopulate([Grades.Twelfth])
            }}
          />
        </Box>
      </Box>
    </>
  )
}

export default ClassInformation
