import React, { useMemo } from 'react'
import { AgreementTemplateFormField } from '../../swagger'
import DynamicFormField from '../Elements/DynamicFormField'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import { MenuOption } from '../Menus/DropDown'

export interface ParentFormField
  extends Omit<
    AgreementTemplateFormField,
    'dropdownSelectOptions' | 'tooltip' | 'defaultValue'
  > {
  fieldId: string
  dropdownSelectOptions?: MenuOption[]
}

interface ParentFieldsProps {
  parentFields?: ParentFormField[]
  parentFieldStates: DynamicFieldStates
  updateParentFieldValueForParent: <T = number>(
    name: string,
    state: string,
    required: boolean,
    isOverMaxLength: boolean,
    isUnderMinLength: boolean,
    option?: T
  ) => void
}

export const ParentFields: React.FC<ParentFieldsProps> = ({
  parentFields,
  updateParentFieldValueForParent,
  parentFieldStates,
}) => {
  const parentFormFields = useMemo(() => {
    if (!!parentFields) {
      return parentFields.map((data) => {
        return (
          <DynamicFormField
            key={data.inputName}
            id={data.inputName}
            label={data.displayName}
            name={data.displayName}
            dropdownOptions={data.dropdownSelectOptions}
            updateForParent={updateParentFieldValueForParent}
            value={parentFieldStates?.[data.displayName]?.state ?? ''}
            isValid={parentFieldStates?.[data.displayName]?.isValid}
            required={data.required}
            inputType={data.inputType}
            maxLength={data.maxTextLength}
            minLength={data.minTextLength}
            validationMessage={
              parentFieldStates?.[data.displayName]?.validationMessage
            }
          />
        )
      })
    } else {
      return []
    }
  }, [parentFields, updateParentFieldValueForParent, parentFieldStates])

  return <>{parentFormFields?.map((it) => it)}</>
}
