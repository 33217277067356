import {
  Save,
  Edit,
  Cancel,
  Delete,
  Download,
  Print,
  Send,
} from '@mui/icons-material'
import React, { useRef } from 'react'

export interface SpeedDialActions {
  /** Tooltip title and key for the action. Needs to use `t` */
  name: string
  /** Icon to represent the action. */
  icon: React.ReactNode
  /** On click handler for the SpeedDialAction */
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  /** onClick tour handler */
  onTourClick?: () => void
  /**
   * CSS Selector to add as a className to the action for ease of selection during steps
   *
   * This should be the target without the '.' prefix
   */
  selector?: string
  /** Determines whether to hide the action */
  hidden?: boolean
  /** Pause for load */
  pauseTourBriefly?: boolean
}

/**
 * Provides common actions with the ability to override props for the most common.
 *
 * Edit, Save and Cancel are widely used.
 *
 * @param opts - an options object to override action properties with SpeedDialAction props
 * @returns references to the common actions.
 */
export const useSpeedDialCommonActions = ({
  saveProps,
  cancelProps,
  editProps,
  deleteProps,
  downloadProps,
  printProps,
  sendProps,
}: {
  /** Override props for SpeedDialActions */
  saveProps?: Partial<SpeedDialActions>
  cancelProps?: Partial<SpeedDialActions>
  editProps?: Partial<SpeedDialActions>
  deleteProps?: Partial<SpeedDialActions>
  downloadProps?: Partial<SpeedDialActions>
  printProps?: Partial<SpeedDialActions>
  sendProps?: Partial<SpeedDialActions>
} = {}): {
  saveAction: React.MutableRefObject<SpeedDialActions>
  cancelAction: React.MutableRefObject<SpeedDialActions>
  editAction: React.MutableRefObject<SpeedDialActions>
  deleteAction: React.MutableRefObject<SpeedDialActions>
  downloadAction: React.MutableRefObject<SpeedDialActions>
  printAction: React.MutableRefObject<SpeedDialActions>
  sendAction: React.MutableRefObject<SpeedDialActions>
} => {
  const saveAction = useRef<SpeedDialActions>({
    name: 'Save',
    icon: <Save aria-label="save-action-icon" />,
    ...saveProps,
  })
  const editAction = useRef({
    name: 'Edit',
    icon: <Edit aria-label="edit-action-icon" />,
    ...editProps,
  })
  const cancelAction = useRef<SpeedDialActions>({
    name: 'Cancel',
    icon: <Cancel aria-label="cancel-action-icon" />,
    ...cancelProps,
  })
  const deleteAction = useRef<SpeedDialActions>({
    name: 'Delete',
    icon: <Delete aria-label="delete-action-icon" />,
    ...deleteProps,
  })
  const downloadAction = useRef<SpeedDialActions>({
    name: 'Download',
    icon: <Download aria-label="download-action-icon" />,
    ...downloadProps,
  })
  const printAction = useRef<SpeedDialActions>({
    name: 'Print',
    icon: <Print aria-label="print-action-icon" />,
    ...printProps,
  })
  const sendAction = useRef<SpeedDialActions>({
    name: 'Send',
    icon: <Send aria-label="send-action-icon" />,
    ...sendProps,
  })
  return {
    saveAction,
    editAction,
    cancelAction,
    deleteAction,
    downloadAction,
    printAction,
    sendAction,
  }
}
