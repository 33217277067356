import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import TitleContext from '../../../TitleContext'
import RoleCard, { RoleCardVariants } from '../../Card/RoleCard'
import { CanAccess } from '../../Elements/Access'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'
import { Page } from '../../Elements/PageMargins'
import EmptyRoles from './EmptyRoles'
import { meta, Permission, extractedErrorObject } from '../../../api/swagger'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { LoadingContext } from '../../Context/LoadingContext'
import { useMountEffect } from '../../../hooks/useMountEffect'
import LoadingProgress from '../../Elements/LoadingProgress'

const AddRole: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const title = t('Roles.Title', 'Roles')
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const { Meta } = useLoadingIds()
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [permissions, setPermissions] = useState<Permission[]>([])

  /**
   * Fetch permissions
   */
  const fetchPermissions = async () => {
    try {
      const fetchedPermissions = await meta.fetchPermissions({})

      setPermissions(fetchedPermissions)
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ??
          t('Roles.AddRole.Error', 'Failed to fetch permissions.'),
      }
      setErrorMessage(errorObject.message)
    } finally {
      setIsLoading(false)
    }
  }

  /** Hooks */
  useLoadingContext({
    asyncFunction: fetchPermissions,
    loadingId: Meta.fetchPermissions,
  })

  useMountEffect(() => {
    addLoadingIds([Meta.fetchPermissions])
  })

  /**
   * If error fetching permissions, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  if (loadingIds.has(Meta.fetchPermissions)) {
    return <LoadingProgress />
  }

  const navigateToRoles = () => {
    navigate(
      {
        pathname: '/admin/roles',
      },
      {
        /** Navigation Options */
      }
    )
  }

  const breadcrumbs = [
    {
      label: t('Roles.AddRole.BreadCrumb', 'Roles'),
      onClick: navigateToRoles,
    },
    {
      label: t('Roles.AddRole.BreadCrumb', 'Add Role'),
    },
  ]
  return (
    <CanAccess I="createEditDelete" on="Role">
      <Page>
        {!permissions.length ? (
          <EmptyRoles isLoading={isLoading} />
        ) : (
          <>
            <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
            <RoleCard
              variant={RoleCardVariants.Add}
              permissions={permissions}
            />
          </>
        )}
      </Page>
    </CanAccess>
  )
}

export default AddRole
