import React, { useState, useContext, useEffect } from 'react'
import { Page } from '../Elements/PageMargins'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Outlet, useParams } from 'react-router'
import TitleContext from '../../TitleContext'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { useAuth } from '../Routes/AuthProvider'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import ResponsiveFab from '../Buttons/ResponsiveFab'
import AddIcon from '@mui/icons-material/Add'
import { addIconFudgeFactor } from '../../utils/addIconFudgeFactor'
import { Can } from '@casl/react'
import CommunityDetailsTab from './CommunityDetailsTab'
import CommunityFamiliesTab from './CommunityFamiliesTab'
import CommunityProgramsTab from './CommunityProgramsTab'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import { Box } from '@mui/material'
import { useSnackbarOnNavigation } from '../../hooks/useSnackbarOnNavigation'
import { ICommunityInformation } from '../Card/ProgramFormCard'
import { useAcademicYears } from '../../hooks/useAcademicYears'
import { LoadingContext } from '../Context/LoadingContext'
import { OperationIds } from '../../swagger/operationIdEnum'
import { useCommunitiesContext } from '../Context/CommunitiesContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'

const CommunityDetails: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { permissionAbility } = useAuth()
  let title = t('Communities.CommunityDetails.Header', 'Communities')
  const { useTitleEffect } = useContext(TitleContext)
  useSnackbarOnNavigation()

  const location: {
    state: {
      communityName: string
      isFamilyTabSelected: string
    }
    pathname: string
    search: string
  } = useLocation()

  const shouldRenderProgramsTitle = () => {
    return (
      location.pathname.includes('program-details') ||
      location.pathname.includes('add-program')
    )
  }

  if (shouldRenderProgramsTitle()) {
    /**
     * If the user is on the program details page
     * we want to set the header title to Programs.
     * We verify this here since /programs/program-details/:programKey
     * and /communities/community-details/:communityKey/programs/add-program
     * are child paths of the community details page
     */
    title = t('Programs.ProgramDetails.Header', 'Programs')
  }
  useTitleEffect(title)
  const { communityKey } = useParams()

  const fetchCommunityProgramsLoadingId = OperationIds.FetchAllPrograms
  const { addLoadingIds } = React.useContext(LoadingContext)

  const errorMessageFetchAcademicYears = t(
    'Communities.CommunityDetails.Error.FetchCommunities',
    'An error occurred while retrieving academic years.'
  )

  const {
    academicYear,
    communityName,
    updateAcademicYear,
    selectedCommunityKey,
    fetchCommunityDetails,
    fetchCommunityLoadingId,
    updateSelectedCommunityKey,
  } = useCommunitiesContext()

  useMountEffect(() => {
    if (!!communityKey) {
      updateSelectedCommunityKey(+communityKey)
      addLoadingIds([fetchCommunityLoadingId])
    }
  })

  useLoadingContext({
    asyncFunction: fetchCommunityDetails,
    loadingId: fetchCommunityLoadingId,
  })

  const { academicYears } = useAcademicYears({
    errorMessage: errorMessageFetchAcademicYears,
  })

  const currentAcademicYear = academicYears.find(
    (academicYear) =>
      // This makes sure that either the current or default academic year is selected.
      academicYear.name.includes('CURRENT') || academicYear.isDefault
  )
  useEffect(() => {
    if (currentAcademicYear) {
      // check if academicYear is set in context, if not set it to currentAcademicYear
      const currentYear =
        academicYears.find((year) => year.year === academicYear)?.year ??
        currentAcademicYear.year
      setAcademicYearSelected(currentYear)
      if (academicYear === undefined) {
        updateAcademicYear(currentYear)
      }
    }
  }, [academicYears, currentAcademicYear, academicYear, updateAcademicYear])

  const handleRedirect = () => {
    navigate({
      pathname: '/communities',
    })
  }

  // const prefersReducedMotion = usePrefersReducedMotion()
  // const transitionDuration = {
  //   enter: prefersReducedMotion ? 0 : theme.transitions.duration.enteringScreen,
  //   exit: prefersReducedMotion ? 0 : theme.transitions.duration.leavingScreen,
  // }

  const [academicYearSelected, setAcademicYearSelected] = useState<number>(
    academicYear ?? new Date().getFullYear()
  )

  /*const [avatarURL, setAvatarURL] = useState('')
  // Fetch Avatar Image
  useMountEffect(() => {
    const fetchAvatar = () => {
      const fetchedAvatar = buildAvatarUrl()
        setAvatarURL(fetchedAvatar)
    }
    fetchAvatar()
  }, [])
  */

  /*  const tableHeaders: TableHeaders[] = [
    {
      label: '', // Empty label
      align: 'left',
    },
    {
      label: t(
        'Communities.CommunityDetails.AgreementStatus.ContractorName.Header',
        'Contractor Name'
      ),
      align: 'left',
    },
    {
      label: t(
        'Communities.CommunityDetails.AgreementStatus.Role.Header',
        'Role'
      ),
      align: 'left',
    },
    {
      label: t(
        'Communities.CommunityDetails.AgreementStatus.PhoneNumber.Header',
        'Phone Number'
      ),
      align: 'left',
    },
    {
      label: t(
        'Communities.CommunityDetails.AgreementStatus.Email.Header',
        'Email'
      ),
      align: 'left',
    },
    {
      label: t(
        'Communities.CommunityDetails.AgreementStatus.AgreementStatus.Header',
        'Agreement Status'
      ),
      align: 'right',
    },
  ]
  */
  // We pass the contents of the state in history which contains the following,
  // an object with the properties communityKey, communityName
  const addProgram = () => {
    const selectedYearQueryString = academicYear
      ? new URLSearchParams({
          year: academicYear.toString(),
        }).toString()
      : ''
    navigate(
      {
        pathname: `/communities/community-details/${selectedCommunityKey}/programs/add-program`,
        search: selectedYearQueryString,
      },
      {
        state: {
          communityKey: `${selectedCommunityKey}`,
          communityName,
        } as ICommunityInformation,
      }
    )
  }

  const initialPath = `/communities/community-details/${selectedCommunityKey}`

  let selectedTab = '1'
  if (location.pathname === `${initialPath}/overview`) {
    selectedTab = '1'
  } else if (location.pathname === `${initialPath}/programs`) {
    selectedTab = '2'
  } else if (location.pathname === `${initialPath}/families`) {
    selectedTab = '3'
  }

  const shouldRenderCommunityDetailsTabs = () => {
    // Paths containing add-program or program-details should not render the community tab
    return !['add-program', 'program-details'].some((path) =>
      location.pathname.includes(path)
    )
  }

  // change the tabs in the community details
  const switchTabs = React.useCallback(
    (newValue: string) => {
      switch (newValue) {
        case '1':
          navigate(
            {
              pathname: `/communities/community-details/${selectedCommunityKey}/overview`,
            },
            {
              replace: true,
            }
          )
          return
        case '2':
          navigate(
            {
              pathname: `programs`,
            },
            {
              replace: true,
            }
          )
          return
        case '3':
          navigate(
            {
              pathname: `families`,
            },
            {
              replace: true,
            }
          )
      }
    },
    [selectedCommunityKey, navigate]
  )

  const detailsTabTitle = 'Details'
  const programTabTitle = 'Programs'
  const familyTabTitle = 'Families'
  const tabs = [
    <Tab key="1" label={detailsTabTitle} value="1" />,
    <Tab key="2" label={programTabTitle} value="2" />,
    <Tab key="3" label={familyTabTitle} value="3" />,
  ]
  const handleDropDownSelection = (selection: string) => {
    const yearSelected =
      academicYears.find((year) => year.name === selection)?.year ??
      academicYears[0].year
    setAcademicYearSelected(yearSelected)
    updateAcademicYear(yearSelected)
    addLoadingIds([fetchCommunityProgramsLoadingId])
  }

  const familyInviteFabHandler = () => {
    navigate(
      {
        pathname: '/invite-new-family',
      },
      {
        state: {
          academicYear,
          communityId: selectedCommunityKey,
          communityName,
        },
      }
    )
  }

  const CommunitiesBreadcrumb = (
    <DynamicBreadcrumbs
      breadcrumbs={[
        {
          label: t('Communities.CommunityDetails.BreadCrumb', 'Communities'),
          onClick: handleRedirect,
        },
        {
          label: communityName,
        },
      ]}
    />
  )

  if (shouldRenderCommunityDetailsTabs()) {
    return (
      <TabContext value={selectedTab}>
        <TabList
          value={selectedTab}
          onChange={(_, newValue) => switchTabs(newValue)}
          textColor="primary"
        >
          {tabs}
        </TabList>
        <Can I="create" on="Program" ability={permissionAbility}>
          {selectedTab === '2' && (
            <Box
              sx={{
                animation: 'fadeIn 250ms ease-in',
                zIndex: '1500',
                position: 'fixed',
              }}
            >
              <ResponsiveFab
                onClick={addProgram}
                icon={AddIcon}
                spacingFudgeFactor={addIconFudgeFactor}
                iconLabel={t(
                  'Community.ProgramsTab.AddProgramButton.IconLabel',
                  'Add'
                )}
                textLabel={t(
                  'Community.ProgramsTab.AddProgramButton.TextLabel',
                  'Program'
                )}
                fullLabel={t(
                  'Community.ProgramsTab.AddProgramButton.FullLabel',
                  'Add Program'
                )}
              />
            </Box>
          )}
        </Can>
        <Can I="invite" on="Family" ability={permissionAbility}>
          {selectedTab === '3' && (
            <Box
              sx={{
                animation: 'fadeIn 250ms ease-in',
                zIndex: '1500',
                position: 'fixed',
              }}
            >
              <ResponsiveFab
                onClick={familyInviteFabHandler}
                icon={AddIcon}
                spacingFudgeFactor={addIconFudgeFactor}
                iconLabel={t(
                  'Community.FamiliesTab.InviteFamilyButton.IconLabel',
                  'Add'
                )}
                textLabel={t(
                  'Community.FamiliesTab.InviteFamilyButton.TextLabel',
                  'Family'
                )}
                fullLabel={t(
                  'Community.FamiliesTab.InviteFamilyButton.Label',
                  'Add Family'
                )}
              />
            </Box>
          )}
        </Can>
        <Page withinTab>
          <Box display="flex" alignItems="baseline" flexDirection="column">
            {CommunitiesBreadcrumb}
            <Box marginBottom="5px">
              <DropDown
                id="filters"
                menuOptions={academicYears.map(({ year, name }) => {
                  return { name: name, id: year }
                })}
                handleSelection={handleDropDownSelection}
                value={
                  academicYears.find(
                    (year) => year.year === academicYearSelected
                  )?.name ?? ''
                }
                variant={DropDownVariant.SortAndFilter}
              />
            </Box>
          </Box>
          <TabPanel value={selectedTab}>
            <Outlet />
          </TabPanel>
        </Page>
      </TabContext>
    )
  }
  return <Outlet />
}

export const CommunityProgramLayout: React.FC = () => {
  return <CommunityProgramsTab />
}

export const CommunityFamilyLayout: React.FC = () => {
  return <CommunityFamiliesTab />
}

export const CommunityDetailsLayout: React.FC = () => {
  return <CommunityDetailsTab />
}

export default CommunityDetails
