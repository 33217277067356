import React, { CSSProperties } from 'react'
import Button from '@mui/material/Button'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import Create from '@mui/icons-material/Create'
import { useTheme } from '@mui/material'

export enum OutlinedButtonVariant {
  Draft = 'Draft',
  EditProfile = 'editProfile',
  ViewMore = 'viewMore',
  UploadImage = 'uploadImage',
  AddPayment = 'addPayment',
  Next = 'next',
  Previous = 'previous',
  ConfirmEnrollmentAndPayLater = 'confirmEnrollmentAndPayLater',
  EditLocation = 'editLocation',
  RevertToDraft = 'revertToDraft',
  Add = 'add',
  ChangeLoginEmail = 'changeLoginEmail',
  ChangePassword = 'changePassword',
  AddTransaction = 'addTransaction',
  DuplicateRole = 'duplicateRole',
  EnterPayment = 'enterPayment',
  EnterDiscount = 'enterDiscount',
  CopyEvent = 'copyEvent',
  ShowDetails = 'showDetails',
  HideDetails = 'hideDetails',
  AddChild = 'AddChild',
  Archive = 'Archive',
  ClearAllFilters = 'clearAllFilters',
  GoBack = 'goBack',
}

interface OutlinedButtonProps {
  id: string
  fullWidth?: boolean
  variant: OutlinedButtonVariant
  disabled?: boolean
  onClick?: () => void
  css?: CSSProperties
}

const labelForVariant = (variant: OutlinedButtonVariant, t: TFunction) => {
  switch (variant) {
    case OutlinedButtonVariant.Draft:
      return t('Buttons.FormButtons.DraftButton', 'Draft')
    case OutlinedButtonVariant.Archive:
      return t('Buttons.FormButtons.ArchiveButton', 'Archive')
    case OutlinedButtonVariant.EditProfile:
      return t('Buttons.FormButtons.EditProfileButton', 'Edit Profile')
    case OutlinedButtonVariant.ViewMore:
      return t('Buttons.FormButtons.ViewMoreButton', 'View More')
    case OutlinedButtonVariant.UploadImage:
      return t('Buttons.FormButtons.UploadImageButton', 'Upload Image')
    case OutlinedButtonVariant.AddPayment:
      return t('Buttons.FormButtons.AddPayment', 'Add Payment')
    case OutlinedButtonVariant.Next:
      return t('Buttons.FormButtons.Next', 'Next')
    case OutlinedButtonVariant.Previous:
      return t('Buttons.FormButtons.Previous', 'Previous')
    case OutlinedButtonVariant.ConfirmEnrollmentAndPayLater:
      return t(
        'Buttons.FormButtons.ConfirmEnrollmentAndPayLater',
        'Confirm Enrollment And Pay Later'
      )
    case OutlinedButtonVariant.EditLocation:
      return t('Buttons.FormButtons.EditLocation', 'Edit Location')
    case OutlinedButtonVariant.RevertToDraft:
      return t('Buttons.FormButtons.RevertToDraft', 'Revert To Draft')
    case OutlinedButtonVariant.Add:
      return t('Buttons.FormButtons.Add', 'Add')
    case OutlinedButtonVariant.ChangeLoginEmail:
      return t('Buttons.FormButtons.ChangeLoginEmail', 'Change Login Email')
    case OutlinedButtonVariant.ChangePassword:
      return t('Buttons.FormButtons.ChangePassword', 'Change Password')
    case OutlinedButtonVariant.AddTransaction:
      return t('Buttons.FormButtons.AddTransaction', 'Add Transaction')
    case OutlinedButtonVariant.DuplicateRole:
      return t('Buttons.FormButtons.DuplicateRole', 'Duplicate Role')
    case OutlinedButtonVariant.EnterPayment:
      return t('Buttons.FormButtons.EnterPayment', 'Enter Payment')
    case OutlinedButtonVariant.EnterDiscount:
      return t('Buttons.FormButtons.EnterDiscount', 'Enter Discount')
    case OutlinedButtonVariant.CopyEvent:
      return t('Buttons.FormButtons.CopyEvent', 'Copy Event')
    case OutlinedButtonVariant.ShowDetails:
      return t('Buttons.FormButtons.ShowDetails', 'Show Details')
    case OutlinedButtonVariant.HideDetails:
      return t('Buttons.FormButtons.HideDetails', 'Hide Details')
    case OutlinedButtonVariant.AddChild:
      return t('Buttons.FormButtons.AddChild', 'Add Child')
    case OutlinedButtonVariant.ClearAllFilters:
      return t('Buttons.FormButtons.ResetFilters', 'Clear All Filters')
    case OutlinedButtonVariant.GoBack:
      return t('Buttons.FormButtons.GoBack', 'Go Back')
  }
}

const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  variant,
  id,
  css,
  fullWidth,
  onClick,
  disabled,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  let startIcon = null
  // let buttonClass = classes.outlinedButton
  let buttonStyles = {} as CSSProperties

  if (variant === OutlinedButtonVariant.UploadImage)
    buttonStyles = {
      [theme.breakpoints.up('sm')]: {
        height: theme.spacing(4.5),
        fontSize: '14px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        margin: theme.spacing(3, 'auto', 5),
      },
    }
  else if (variant === OutlinedButtonVariant.EditProfile) startIcon = <Create />
  else if (variant === OutlinedButtonVariant.ViewMore)
    buttonStyles = {
      padding: theme.spacing(1, 8),
    }
  else if (variant === OutlinedButtonVariant.CopyEvent)
    buttonStyles = {
      fontSize: '14px',
      margin: theme.spacing(0, 1),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0.5, 0),
      },
      minWidth: '152px', // Specific class to Copy Event button to have the "Copy Event" label on one line
    }
  else if (
    variant === OutlinedButtonVariant.Next ||
    variant === OutlinedButtonVariant.Previous ||
    variant === OutlinedButtonVariant.Add
  )
    buttonStyles = {
      fontSize: '14px',
      margin: theme.spacing(0, 1),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0.5, 0),
        width: '100%',
      },
    }

  return (
    <Button
      id={id}
      color="secondary"
      variant="outlined"
      sx={{
        ...buttonStyles,
        ...css,
      }}
      fullWidth={fullWidth}
      onClick={onClick}
      startIcon={startIcon}
      disabled={disabled ?? false}
    >
      {labelForVariant(variant, t)}
    </Button>
  )
}

export default OutlinedButton
