import React, { useRef, useState } from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router'
import { useAuth } from '../Routes/AuthProvider'
import { useBeacon } from '../../api/beacon'
import { useTranslation } from 'react-i18next'
import AuthFormCard from './AuthFormCard'
import { user } from '../../api/swagger'
import { useEffect } from 'react'
import PartialPageLoadingSpinner from '../Elements/PartialPageLoadingSpinner'
import { LoadingContext } from '../Context/LoadingContext'
import { validateEmail } from '../../helpers/validateEmail'
import CountryInformation from '../Card/CountryInformation'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { fullScreenAssetPath } from '../../App'

interface LocationState {
  from?: string
  hasChangedPassword?: boolean
}

const Login: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { loadingIds, addLoadingIds } = React.useContext(LoadingContext)

  const [loginError, setLoginError] = useState('')

  useBeacon(process.env.REACT_APP_HELP_SCOUT_BEACON_LOGIN_ID)

  const location: {
    pathname: string
    search: string
    hash: string
    state: LocationState
    key: string
  } = useLocation()

  const auth = useAuth()
  const { defaultAccountPath } = auth
  const discourseUrl = useRef<string>()
  const [citizenship, setCitizenship] = useState<string>('')
  const [residence, setResidence] = useState<string>('')

  const [sso, setSso] = useState('')
  const [sig, setSig] = useState('')
  const [returnUrl, setReturnUrl] = useState('')
  const [showCountryInformationForm, setShowCountryInformationForm] =
    useState(false)
  const { Login } = useLoadingIds()
  const [localStorageRef, setLocalStorageRef] = useState(false)

  const params = new URLSearchParams(location.search)
  useMountEffect(() => {
    addLoadingIds([Login.login])
  })
  const [navigateToDefaultRoute, setNavigateToDefaultRoute] = useState(false)

  const fetch = async () => {
    if (params.has('sso') && params.has('sig')) {
      try {
        const ssoFromParams = params.get('sso') ?? ''
        setSso(ssoFromParams)
        const sigFromParams = params.get('sig') ?? ''
        setSig(sigFromParams)
        const returnUrlFromParams = params.get('ReturnURL') ?? ''
        setReturnUrl(returnUrlFromParams)

        // Only run if user is logged in to prevent user from being directed to /login with no sso/sig params
        if (localStorage.loggedIn === 'true') {
          const result = await user.fetchDiscourseConnectRedirectUrl({
            userId: 'me',
            body: {
              sso: ssoFromParams,
              sig: sigFromParams,
              returnURL:
                returnUrlFromParams === '' ? undefined : returnUrlFromParams,
            },
          })
          const redirectUrl = result.redirectTo
          window.location.replace(redirectUrl)
        }
      } catch (error) {
        return
      }
    } else {
      // when the user is logged in
      if (auth.isLoggedIn) {
        /**
         * if the pathname contains the word `fullScreenAsset`
         * we will redirect the user to the fullscreen asset route
         */
        if (
          !!location.state.from &&
          location.state.from.includes(fullScreenAssetPath)
        ) {
          return navigate(location.state.from, {
            replace: true,
          })
        }

        // if not, we keep the default route
        setNavigateToDefaultRoute(true)
      }
    }
  }

  useLoadingContext({
    asyncFunction: fetch,
    loadingId: Login.login,
  })
  const { from, hasChangedPassword } = location.state ?? {
    hasChangedPassword: false,
  }

  useEffect(() => {
    const { countryOfCitizenship, countryOfResidence } = auth.countryInformation
    setCitizenship(countryOfCitizenship)
    setResidence(countryOfResidence)
  }, [auth.countryInformation, localStorageRef])

  useEffect(() => {
    if (navigateToDefaultRoute) {
      /**
       * Moved default from initialization to when used because defaultAccountPath
       * the from would change but not actually be updated for navigation
       */
      navigate(
        from ?? {
          pathname: defaultAccountPath,
        },
        {
          replace: true,
        }
      )
      setNavigateToDefaultRoute(true)
    }
  }, [defaultAccountPath, from, navigate, navigateToDefaultRoute])

  // If a user is going to be directed to discourse after logging in, we disable the
  // login button after the user clicks login to prevent a race condition
  const handleLogin = async (email: string, password: string) => {
    const isEmailValid = validateEmail(email)

    const isAuthenticated =
      isEmailValid &&
      (await auth.signin({
        email,
        password,
      }))

    if (isAuthenticated) setLocalStorageRef(true)
    if (isAuthenticated) {
      const hasCountryInfo = citizenship !== '' && residence !== ''
      const hasSSOandSIG = sig !== '' && sso !== ''

      if (hasSSOandSIG) {
        const { redirectTo } = await user.fetchDiscourseConnectRedirectUrl({
          userId: 'me',
          body: {
            sso,
            sig,
            returnURL: returnUrl === '' ? undefined : returnUrl,
          },
        })
        discourseUrl.current = redirectTo

        if (hasCountryInfo) {
          window.location.replace(discourseUrl.current)
          return
        } else {
          setShowCountryInformationForm(true)
          return
        }
      }

      if (!hasCountryInfo) {
        setNavigateToDefaultRoute(false)
        navigate({ pathname: '/country-information' })
      } else {
        setNavigateToDefaultRoute(true)
        localStorage.setItem('username', email)
      }
    } else {
      isEmailValid &&
        setLoginError(
          t('Login.Error', 'We do not recognize the email or password.')
        )
    }
  }

  const [authenticationInfo, setAuthenticationInfo] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
  })

  const handleAuthInfoUpdate = (id: string, value: string) => {
    setAuthenticationInfo({
      ...authenticationInfo,
      [id]: value,
    })
  }

  /** render CountryInformation when the user navigates directly to discourse but has no country info in our DB*/
  if (showCountryInformationForm) {
    const navigateTo = () => {
      if (!!discourseUrl.current) {
        /**
         * uses location replace instead of navigate since
         * we route the user to an external site
         */
        window.location.replace(discourseUrl.current)
        return
      }
    }

    const logoutHandler = async () => {
      await auth.signout()
      navigate({ pathname: '/login' })
    }

    return (
      <CountryInformation handleNext={navigateTo} handleBack={logoutHandler} />
    )
  }

  return loadingIds.has(Login.login) ||
    (params.has('sig') && params.has('sso') && auth.isLoggedIn) ? (
    <PartialPageLoadingSpinner isLoading height={600} />
  ) : (
    // Provide the loading context to disable primary buttons within AuthFormCard
    <>
      {location.pathname === '/login' ? (
        <AuthFormCard
          authenticationInfo={authenticationInfo}
          handleAuthInfoUpdate={handleAuthInfoUpdate}
          handleClick={handleLogin}
          errorMessage={loginError}
          hasChangedPassword={hasChangedPassword}
          name={AuthFormCard.name}
        />
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default Login
