/**
 * @param {Object} opts time in string format example "13:00"
 * @returns the time in standard example 1:00 PM
 * convertToStandardFromMilitaryTime("14:30") returns "2:30 PM"
 */

export const convertToStandardFromMilitaryTime = (opts: {
  time: string
}): string => {
  const { time } = opts
  const [hours, minutes] = time.split(':')

  let hoursConverted = +hours % 12

  if (hours === '12') {
    hoursConverted = 12
  }

  if (hours === '0' || hours === '00') {
    hoursConverted = 12
  }

  const amPm = `${+hours >= 12 ? 'P' : 'A'}M`

  return `${hoursConverted}:${minutes} ${amPm}`
}
