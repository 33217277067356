/**
 * Escapes special characters in a given string to be provided to a RegExp
 * @param input string to be escaped
 * @returns input with special characters replaces with escaped versions of
 *  themselves
 */
export const escapeString = (input: string): string => {
  return input.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

/**
 * Check if two strings are equal
 * @param first string to be compared
 * @param second string to be compared
 * @returns true if the strings equal
 */
export const equals = (first?: string, second?: string): boolean => {
  return first === second
}

/**
 * Check if a string has no length or is undefined
 * @param input string to compare length or existence
 * @returns true if the string is empty or undefined
 */
export const isEmpty = (input?: string): boolean => {
  return input?.length === 0 || input === undefined
}

/**
 * Replaces leading, trailing and more than 2 spaces between each word in a string.
 * @param input string with extra spaces
 * @returns input without extra spaces
 */
export const removeStringExtraSpaces = (input: string): string =>
  input.replace(/\s{2,}/g, ' ')

/**
 * capitalizes each word in the received string
 * @param input string with extra spaces
 * @returns input with each word capitalized
 */
export const capitalizeEachWord = (input: string): string => {
  const words = input.toLowerCase().split(' ')

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1)
    })
    .join(' ')
}
/**
 * Removes all spaces from a string
 * @param input string with spaces
 * @returns input with no extra spaces
 */
export const removeSpacesFromString = (input: string): string =>
  input.replace(/\s+/g, '')

/**
 * replace all "-" characters with "/" characters
 * @param input string with "-"
 * @return input with "/" characters
 */
export const replaceUnderscoreWithSlash = (input: string): string => {
  return input.replace('-', '/')
}

/**
 * removes segments from a string according to the provided indexes
 * @param input the String we will eliminate
 * @param leftPosition from the left position
 * @param rightPosition from the right position
 * @return new string without the elements covered by the provided indices 
 * @example We have the following string "Elimination test" and we want to delete the word "test" and the space between words 
            removeTextFromAStringUsingIndexes("Elimination test", 0, -5)
            the returned value will be "Elimination".
 */
export const removeTextFromAStringUsingIndexes = (
  input: string,
  leftPosition: number,
  rightPosition: number
): string => {
  return input.slice(leftPosition, rightPosition)
}

/**
 * Converts a camelCase string to Pascal Case with spaces.
 *
 * Example: `camelCase` -> `Pascal Case`
 *
 * @param {string} input The input string to convert to pascal case.
 *
 * @returns {string} The input string converted to pascal case.
 */
export const toPascalCase = (input: string): string => {
  return input
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()
}
