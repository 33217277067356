import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyPage from '../../Elements/EmptyPage'

interface EmptyRolesProps {
  isLoading: boolean
}

const EmptyRoles: React.FC<EmptyRolesProps> = (props) => {
  const { t } = useTranslation()

  return (
    <EmptyPage
      {...props}
      message={
        <Typography variant="subtitle1" component="p">
          {t('Roles.EmptyState.Instructions', 'No Role information available.')}
        </Typography>
      }
    />
  )
}

export default EmptyRoles
