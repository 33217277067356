import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import {
  FileType,
  FileTypeIcon,
  FileTypeLabel,
} from '../Elements/FileTypeResource'
import { useNavigate } from 'react-router'
import CardActionArea from '@mui/material/CardActionArea'

interface LearningPathContentMobileProps {
  widenAssetKey: number
  fileType: FileType
  title: string
  routerState?: { assetKeysInLearningPath: string[] }
}

export const LearningPathContentMobile: React.FC<
  LearningPathContentMobileProps
> = ({ widenAssetKey, fileType, title, routerState }) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const toContentPage = (assetKey: number) => {
    navigate(
      {
        pathname: `/business/business-content/${assetKey}`,
      },
      {
        state: routerState,
      }
    )
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: 88,
        margin: theme.spacing(1, 0),
      }}
      data-testid="businessCard"
    >
      <CardActionArea
        sx={{
          padding: theme.spacing(1),
        }}
        onClick={() => toContentPage(widenAssetKey)}
      >
        <Box display="flex" padding={1} bgcolor={'initial'}>
          <Box
            width={56}
            height={56}
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#fff"
            bgcolor={'primary.main'}
          >
            <FileTypeIcon fileType={fileType} />
          </Box>
          <Box ml={1}>
            <Typography variant="subtitle2" component="p">
              {title}
            </Typography>
            <FileTypeLabel
              fileType={fileType}
              css={{
                color: theme.palette.primary.dark,
              }}
            />
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}

export default LearningPathContentMobile
