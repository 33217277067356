import React from 'react'
import { useTranslation } from 'react-i18next'
import { Student } from '../../swagger'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import DynamicFormField from './DynamicFormField'

interface ChildrenNameFieldProps {
  kid: Student
  isEditMode: boolean
  updateForParent: (name: string, updatedValue: string) => void
  childNameStates: DynamicFieldStates
  isMobile?: boolean
}

const ChildrenNameField: React.FC<ChildrenNameFieldProps> = ({
  kid,
  isEditMode,
  updateForParent,
  childNameStates,
  isMobile,
}) => {
  const { t } = useTranslation()

  return (
    <DynamicFormField
      key={`${kid.studentKey}-name`}
      id={`${kid.studentKey}-name`}
      label={t('ChildrenSummaryCard.Children.Label.Name', 'Name')}
      name={`${kid.studentKey}-name`}
      updateForParent={(name, updatedValue) =>
        updateForParent(name, updatedValue)
      }
      value={childNameStates[`${kid.studentKey}-name`]?.state ?? ''}
      isValid={childNameStates[`${kid.studentKey}-name`]?.isValid}
      required={true}
      disabled={!isEditMode}
      fullWidth={isMobile}
    />
  )
}

export default ChildrenNameField
