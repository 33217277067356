import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router'
import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import CardFormHeader from '../Card/CardFormHeader'
import Hidden from '@mui/material/Hidden'
import { useAuth } from '../Routes/AuthProvider'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { Permission, Role, GrantScopeCodeEnum } from '../../swagger'
import { rolesApi, extractedErrorObject } from '../../api/swagger'
import PermissionAccordion from '../Admin/Roles/PermissionAccordion'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { groupByKey } from '../../utils/groupByKey'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { styled } from '@mui/system'

const Form = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(4),
}))

const HeaderColumn = styled(Typography)<TypographyProps>(({ theme }) => ({
  margin: theme.spacing(0, 3),
  width: theme.spacing(12),
})) as typeof Typography

export enum LocalScopeEnum {
  Global = 'global',
  Restricted = 'restricted',
  Disabled = 'disabled',
}

export interface AccessGrantLabels {
  global: string
  restricted: string
  disabled: string
}

export interface LocalPermissionGrant {
  [key: string]: string
  permissionName: string
  resourceCode: string
  actionCode: string
  scopeCode: LocalScopeEnum
}

interface RoleNameAndNotes {
  name: string
  nameIsValid: boolean
  notes: string
}

export function labelForScope(scope: LocalScopeEnum, t: TFunction): string {
  switch (scope) {
    case LocalScopeEnum.Global:
      return t('Roles.Grant.EnabledGlobally', 'Enabled Globally')
    case LocalScopeEnum.Restricted:
      return t('Roles.Grant.RestrictedToHierarchy', 'Restricted to Hierarchy')
    case LocalScopeEnum.Disabled:
    default:
      return t('Roles.Grant.Disabled', 'Disabled')
  }
}

export enum RoleCardVariants {
  Add,
  Edit,
}

export interface RoleCardProps {
  variant: RoleCardVariants
  permissions: Permission[]
  role?: Role
  refetchPermissionsAndRolesData?: () => void
}

const RoleCard: React.FunctionComponent<RoleCardProps> = ({
  variant,
  permissions,
  role,
  refetchPermissionsAndRolesData,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { permissionAbility } = useAuth()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const [roleNameAndNotes, setRoleNameAndNotes] = useState<RoleNameAndNotes>({
    name: '',
    nameIsValid: true,
    notes: '',
  })
  const [permissionGrants, setPermissionGrants] = useState<
    LocalPermissionGrant[]
  >([])

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const isEditVariant = variant === RoleCardVariants.Edit

  const errorMessage = t(
    'Roles.RoleForm.ValidationMessage.Default',
    'Something went wrong. Please make sure you have filled out the required fields.'
  )

  /**
   * Populate the role name text field with the passed in role
   */
  useEffect(() => {
    if (!role) return
    const { name, notes } = role
    setRoleNameAndNotes({ name, nameIsValid: true, notes })
  }, [role])

  /**
   * Populate all radio selects according to the role's grants.
   * If no role, then populate all as 'disabled'.
   */
  useEffect(() => {
    if (!permissions) return

    const permissionGrantsForThisRole = permissions.map(
      ({ name = '', actionCode, resourceCode }): LocalPermissionGrant => {
        let scopeCode: LocalScopeEnum
        const grant = role?.grants.find(
          (grant) =>
            grant.actionCode === actionCode &&
            grant.resourceCode === resourceCode
        )

        switch (grant?.scopeCode) {
          case GrantScopeCodeEnum.Any:
            scopeCode = LocalScopeEnum.Global
            break
          case GrantScopeCodeEnum.TheirTeams:
            scopeCode = LocalScopeEnum.Restricted
            break
          default:
            scopeCode = LocalScopeEnum.Disabled
            break
        }

        return {
          permissionName: name,
          resourceCode,
          actionCode,
          scopeCode,
        }
      }
    )
    setPermissionGrants(permissionGrantsForThisRole)
  }, [role, permissions])

  const handleRoleNameAndNotesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoleNameAndNotes((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleRadioChange = useCallback(
    (
      incomingScopeCode: LocalScopeEnum,
      incomingActionCode: string,
      incomingResourceCode: string
    ) => {
      setPermissionGrants((prevState) =>
        prevState.map((permissionGrant) => {
          if (
            permissionGrant.actionCode !== incomingActionCode ||
            permissionGrant.resourceCode !== incomingResourceCode
          ) {
            return permissionGrant
          }

          return {
            ...permissionGrant,
            scopeCode: incomingScopeCode,
          }
        })
      )
    },
    []
  )

  const handleCancel = () => {
    navigate(
      {
        pathname: '/admin/roles',
      },
      {
        /** Navigation Options */
      }
    )
  }

  const handleDuplicate = () => {
    alert(
      'Coming soon!  See https://projekt202.atlassian.net/browse/CCP1-2298 for more information.'
    )
  }

  const fieldsDoValidate = () => {
    const nameIsValid = !!roleNameAndNotes.name
    setRoleNameAndNotes({
      ...roleNameAndNotes,
      nameIsValid,
    })
    return nameIsValid
  }

  const handleSave = async () => {
    // Are required fields filled in?
    if (!fieldsDoValidate()) {
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorMessage)
      setSnackbarState?.(true)
      return
    }

    const { name, notes } = roleNameAndNotes

    // Convert our LocalPermissionGrant[] to a Grant[] to send in the request body
    const roleGrantsForRequestBody = permissionGrants
      .filter(
        (permissionGrant) =>
          permissionGrant.scopeCode !== LocalScopeEnum.Disabled
      )
      .map(({ resourceCode, actionCode, scopeCode }) => {
        return {
          resourceCode,
          actionCode,
          scopeCode:
            scopeCode === LocalScopeEnum.Global
              ? GrantScopeCodeEnum.Any
              : GrantScopeCodeEnum.TheirTeams,
        }
      })

    // Prepare the request body
    const reqBody = {
      name: name.trim(),
      notes: notes.trim(),
      grants: roleGrantsForRequestBody,
    }

    if (isEditVariant) {
      if (!role) return
      setSaveButtonDisabled(true)

      // try updating the role
      try {
        await rolesApi.updateRole({
          body: {
            ...role,
            ...reqBody,
          },
        })
        setSnackbarSeverity?.(SnackbarSeverity.Success)
        setSnackbarMessage?.(
          t('Roles.RoleForm.SuccessMessage.Saved', 'Role successfully saved.')
        )
        setSnackbarState?.(true)
        refetchPermissionsAndRolesData?.()
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'UnknownError',
          message: (e as unknown as Error).message,
        }
        setSnackbarSeverity?.(SnackbarSeverity.Error)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarState?.(true)
      } finally {
        setSaveButtonDisabled(false)
      }
    } else {
      setSaveButtonDisabled(true)

      // try creating a new role
      try {
        const response = await rolesApi.createRole({
          body: reqBody,
        })
        /**
         * It should be noted navigate processing before snackbar state will allow testing
         * to validate navigating to a route AND THEN seeing an alert. Putting snackbar
         * before navigation will not show the alert.
         */
        navigate(
          {
            pathname: `/admin/roles/role-details/${response.roleKey}`,
          },
          {
            /** Navigation Options */
          }
        )
        setSaveButtonDisabled(false)

        setSnackbarSeverity?.(SnackbarSeverity.Success)
        setSnackbarMessage?.(
          t(
            'Roles.RoleForm.SuccessMessage.Created',
            'Role successfully created.'
          )
        )
        setSnackbarState?.(true)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'UnknownError',
          message: (e as unknown as Error).message,
        }
        setSnackbarSeverity?.(SnackbarSeverity.Error)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarState?.(true)
        setSaveButtonDisabled(false)
      }
    }
  }

  const groupedPermissionGrants = groupByKey(permissionGrants, 'resourceCode')

  const canCreateEditDeleteRole = permissionAbility.can(
    'createEditDelete',
    'Role'
  )

  const isFieldDisabled = !canCreateEditDeleteRole

  const ViewOnlyButton = (
    <ContainedButton
      id="closeRoleDetails"
      variant={ContainedButtonVariant.Close}
      onClick={handleCancel}
    />
  )

  const CreateEditButtons = (
    <>
      <TextButton
        id="cancelRoleDetails"
        variant={TextButtonVariant.Cancel}
        onClick={handleCancel}
      />
      {/* Temporarily hiding the Duplicate button. To be completed in https://projekt202.atlassian.net/browse/CCP1-2298 */}
      {false && isEditVariant && (
        <OutlinedButton
          id="duplicateRoleDetails"
          variant={OutlinedButtonVariant.DuplicateRole}
          onClick={handleDuplicate}
        />
      )}
      <ContainedButton
        id="saveRoleDetails"
        disabled={saveButtonDisabled}
        variant={ContainedButtonVariant.SaveRole}
        onClick={handleSave}
      />
    </>
  )

  const RoleCardButtons = (
    <CardFormHeader
      header={null}
      buttons={canCreateEditDeleteRole ? CreateEditButtons : ViewOnlyButton}
    />
  )

  return (
    <>
      <Card
        sx={{
          padding: theme.spacing(3, 4, 4),
          maxWidth: 1200,
          color: theme.palette.primary.main,
          marginBottom: theme.spacing(3),
        }}
      >
        {RoleCardButtons}
        <Form noValidate autoComplete="off">
          <TextField
            id="roleName"
            label={t('Roles.RoleForm.FormField.RoleName', 'Name of Role')}
            name="name"
            variant="filled"
            margin="normal"
            disabled={isFieldDisabled}
            fullWidth
            value={roleNameAndNotes.name}
            onChange={handleRoleNameAndNotesChange}
            error={!roleNameAndNotes.nameIsValid}
            helperText={
              !roleNameAndNotes.nameIsValid
                ? t(
                    'Roles.RoleForm.ValidationMessage.RoleNameBlank',
                    'Name of Role cannot be blank'
                  )
                : null
            }
          />
          <TextField
            id="roleNotes"
            label={t(
              'Roles.RoleForm.FormField.Notes',
              'Additional Notes (optional)'
            )}
            name="notes"
            variant="filled"
            margin="normal"
            disabled={isFieldDisabled}
            fullWidth
            value={roleNameAndNotes.notes}
            onChange={handleRoleNameAndNotesChange}
          />

          <Box my={3}>
            <Hidden smDown>
              <Box
                sx={{
                  paddingLeft: theme.spacing(37.75),
                  paddingBottom: theme.spacing(1),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <HeaderColumn variant="subtitle2" align="center">
                  {labelForScope(LocalScopeEnum.Global, t)}
                </HeaderColumn>

                <HeaderColumn variant="subtitle2" align="center">
                  {labelForScope(LocalScopeEnum.Restricted, t)}
                </HeaderColumn>

                <HeaderColumn variant="subtitle2" align="center">
                  {labelForScope(LocalScopeEnum.Disabled, t)}
                </HeaderColumn>
              </Box>
            </Hidden>

            {Object.keys(groupedPermissionGrants).map((category) => {
              const permissionGrants = groupedPermissionGrants[category]

              return (
                <PermissionAccordion
                  key={category}
                  isFieldDisabled={isFieldDisabled}
                  category={category}
                  permissionGrants={permissionGrants}
                  handleChange={handleRadioChange}
                />
              )
            })}
          </Box>
        </Form>
        {RoleCardButtons}
      </Card>
    </>
  )
}

export default RoleCard
