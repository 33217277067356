import React from 'react'
import { transcriptPdfStyles, border } from './TranscriptPDFStyles'
import { GradingScaleWeight } from '../../../swagger'

const weightNameWidth = '55px'
const weightWidth = '1px'

const GradeScaleWeightTablePDF: React.FC<{
  gradingScaleWeights: GradingScaleWeight[]
}> = ({ gradingScaleWeights }) => {
  return (
    <div
      style={{
        ...transcriptPdfStyles.tableDiv,
        maxWidth: '90px',
      }}
    >
      <table>
        <tbody>
          <tr style={{ border }}>
            <th
              style={{
                ...transcriptPdfStyles.tableData,
                maxWidth: weightNameWidth,
              }}
            >
              Weight Name
            </th>
            <th
              style={{
                maxWidth: weightWidth,
                paddingLeft: '4px',
              }}
            >
              Weight
            </th>
          </tr>
          {/* Replace with real data */}
          {gradingScaleWeights.map((weight) => {
            return (
              <tr
                key={weight.weightName}
                style={{
                  borderLeft: border,
                  borderRight: border,
                  borderBottom: border,
                }}
              >
                <td
                  style={{
                    ...transcriptPdfStyles.tableData,
                    maxWidth: weightNameWidth,
                  }}
                >
                  {weight.weightName}
                </td>
                <td
                  style={{
                    maxWidth: weightWidth,
                    paddingLeft: '12px',
                  }}
                >
                  {weight.weight.toFixed(1)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default GradeScaleWeightTablePDF
