import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import BasicModal from './BasicModal'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { Box, TextField } from '@mui/material'
import DropDown, { DropDownVariant, MenuOption } from '../Menus/DropDown'
import { useTranslation } from 'react-i18next'
import { useTranscriptContext } from '../Context/TranscriptContext'
import { GradingScaleWeight, TranscriptYearCourseWork } from '../../swagger'
import {
  calculateTranscriptCredits,
  calculateTranscriptGPA,
  calculateYearCredits,
  calculateYearGPA,
} from '../../utils/calculateGPA'
import { removeCourseWeight } from '../../utils/transcriptData'

export interface AddEditRowModalProps {
  /** Determines if the modal is visible */
  open: boolean
  /** Handles closure of the modal */
  onClose: () => void
  /** Transcript Year */
  transcriptYear: 1 | 2 | 3 | 4
  /**  Optional coursework details. If present it means the coursework has already been created. */
  courseworkDetails?: TranscriptYearCourseWork
}

const getCourseWeight = (
  courseName?: string,
  gradingScaleWeights?: GradingScaleWeight[]
) => {
  if (!courseName || !gradingScaleWeights) {
    return ''
  }
  for (const { weightName } of gradingScaleWeights) {
    if (courseName.startsWith(weightName)) {
      return weightName
    }
  }
  return ''
}

export const AddEditRowModal: React.FC<AddEditRowModalProps> = ({
  open,
  onClose,
  transcriptYear,
  courseworkDetails,
}) => {
  const { t } = useTranslation()
  const { transcriptDetails, updateTranscriptDetails, findGradingScale } =
    useTranscriptContext()

  const defaultCourseWork = useRef({
    courseName: '',
    credits: 0,
    letterGrade: '',
  })

  const noneMenuOption = useRef({
    id: 'None',
    name: t('Transcripts.AddEditRowModal.Weights.None', 'None'),
  })

  const [courseWork, setCourseWork] = useState(defaultCourseWork.current)

  const [courseWeight, setCourseWeight] = useState('')

  const [isCreditsValid, setIsCreditsValid] = useState(true)

  const resetFields = () => {
    setCourseWork(defaultCourseWork.current)
    setCourseWeight('')
  }

  useEffect(() => {
    if (!!courseworkDetails)
      setCourseWork({
        ...courseworkDetails,
        courseName: removeCourseWeight(
          courseworkDetails.courseName,
          transcriptDetails.gradingScale?.gradingScaleWeight
        ),
      })
  }, [courseworkDetails, transcriptDetails.gradingScale?.gradingScaleWeight])

  const handleFormSubmit = () => {
    /** Validate the credits are within the numerical bounds and not 0 */
    if (!+courseWork.credits && courseWork.credits !== 0) {
      setIsCreditsValid(false)
      return
    }

    /** Pick out the transcript year */
    const transcriptYearIndex = `transcriptYear${transcriptYear}`
    /** Build the existing coursework from that year */
    const existingCoursework =
      (transcriptDetails[`transcriptYear${transcriptYear}`]
        ?.transcriptYearCourseWork as TranscriptYearCourseWork[]) ?? []
    /**
     * Find whether the current coursework has a key
     *
     * If it does, we need to update based on the key, not replace it in place
     */
    const index = existingCoursework.findIndex(
      ({ transcriptYearCourseWorkKey }) =>
        transcriptYearCourseWorkKey ===
        courseworkDetails?.transcriptYearCourseWorkKey
    )

    const updatedCoursework: TranscriptYearCourseWork = {
      /** Use the existing key if provided, otherwise set it to the (length + 1) negated. */
      transcriptYearCourseWorkKey:
        existingCoursework?.[index]?.transcriptYearCourseWorkKey ??
        (existingCoursework.length + 1) * -1,
      courseName: !!courseWeight
        ? `${courseWeight}: ` + courseWork.courseName
        : courseWork.courseName,
      credits: +courseWork.credits,
      letterGrade: courseWork.letterGrade,
    }
    if (index >= 0) {
      existingCoursework.splice(index, 1, { ...updatedCoursework })
    } else {
      existingCoursework.push({ ...updatedCoursework })
    }

    /** Update the transcript details with the existing coursework + changes, since we're submitting. */
    updateTranscriptDetails({
      ...transcriptDetails,
      gpa: calculateTranscriptGPA(
        transcriptDetails,
        findGradingScale(transcriptDetails.gradingScale?.gradingScaleKey ?? -1)
      ),
      totalCredits: calculateTranscriptCredits(transcriptDetails),
      [transcriptYearIndex]: {
        ...transcriptDetails[`transcriptYear${transcriptYear}`],
        transcriptYearCourseWork: [...existingCoursework],
        gpa: calculateYearGPA(
          transcriptDetails[`transcriptYear${transcriptYear}`]
            ?.transcriptYearCourseWork ?? [],
          findGradingScale(
            transcriptDetails.gradingScale?.gradingScaleKey ?? -1
          )
        ),
        totalCredits: calculateYearCredits(
          transcriptDetails[`transcriptYear${transcriptYear}`]
            ?.transcriptYearCourseWork ?? []
        ),
      },
    })
    /** Reset the modal fields */
    /** Call the passed closure method */
    handleClose()
  }

  const handleClose = () => {
    /** Reset the state of the modal */
    resetFields()
    /** close the modal */
    onClose()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    name === 'credits' && setIsCreditsValid(true)

    setCourseWork((prevState) => ({
      ...prevState,
      [name]:
        name === 'courseName'
          ? removeCourseWeight(
              value,
              transcriptDetails.gradingScale?.gradingScaleWeight
            )
          : value,
    }))
  }

  const handleSelection = (selection: string, id: string) => {
    if (id === 'letterGrade') {
      setCourseWork((prevState) => ({
        ...prevState,
        letterGrade: selection,
      }))
    } else {
      setCourseWeight(
        selection === noneMenuOption.current.name ? '' : selection
      )
    }
  }

  const courseNameWeight = getCourseWeight(
    courseworkDetails?.courseName,
    transcriptDetails.gradingScale?.gradingScaleWeight
  )

  const classWeightOptions = [noneMenuOption.current].concat(
    transcriptDetails.gradingScale?.gradingScaleWeight?.map((weight) => ({
      id: weight.weightName,
      name: weight.weightName,
    })) ?? []
  )

  const DialogContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
      }}
    >
      <TextField
        variant="filled"
        label={t('Transcripts.AddEditRowModal.Field.CourseName', 'Course Name')}
        name="courseName"
        value={courseWork.courseName}
        onChange={handleChange}
        fullWidth
      />
      {!!classWeightOptions?.length && (
        <DropDown
          id={'classWeight'}
          label={t('Transcripts.AddEditRowModal.Field.Weight', 'Weight')}
          menuOptions={classWeightOptions}
          handleSelection={handleSelection}
          variant={DropDownVariant.FormField}
          value={courseWeight}
          defaultValue={
            courseNameWeight === ''
              ? noneMenuOption.current.name
              : courseNameWeight
          }
          fullWidth
          formControlProps={{ maxWidth: '1' }}
          cssProps={{
            padding: 0,
            height: 'auto',
            width: '100%',
            textAlign: 'left',
          }}
        />
      )}
      <DropDown
        id={'letterGrade'}
        label={t('Transcripts.AddEditRowModal.Field.Grade', 'Grade')}
        menuOptions={(
          findGradingScale(
            transcriptDetails.gradingScale?.gradingScaleKey ?? -1
          )?.gradingScaleDetails ?? []
        ).map(
          ({ letterGrade }) =>
            ({
              id: letterGrade,
              name: letterGrade,
            } as MenuOption)
        )}
        value={courseWork.letterGrade ?? ''}
        handleSelection={handleSelection}
        variant={DropDownVariant.FormField}
        fullWidth
        formControlProps={{ maxWidth: '1' }}
        cssProps={{
          padding: 0,
          height: 'auto',
          width: '100%',
          textAlign: 'left',
        }}
      />
      <TextField
        variant="filled"
        label={t('Transcripts.AddEditRowModal.Field.Credits', 'Credits')}
        name="credits"
        onChange={handleChange}
        value={courseWork.credits === 0 ? '' : courseWork.credits}
        fullWidth
        error={!isCreditsValid}
        helperText={!isCreditsValid ? 'Credits must be a valid number.' : ''}
      />
    </Box>
  )

  /**
   * Basic Modal
   * - Header "Add Course Information"
   * - Course Name field
   * - Credits field (numeric with following decimal place)
   * - Grade - dropdown from provided Grading Scale
   */
  return (
    <BasicModal
      isOpen={open}
      handleFormSubmit={handleFormSubmit}
      dialogTitle={t(
        'Transcripts.AddEditRowModal.Title.AddCourseInformation',
        'Add Course Information'
      )}
      dialogContent={DialogContent}
      dialogActions={
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.Add}
          secondaryButtonLabel={TextButtonVariant.Cancel}
          secondaryClick={handleClose}
        />
      }
      maxWidth={'xs'}
    />
  )
}

export default AddEditRowModal
