import { LoginResponseStorageData } from '../components/Interfaces/LoginResponseStorageData'
import {
  SignUpSendTokenRequestBodySignUpTypeEnum,
  LoginResponseAuthorizationEnum,
  SignUpConfirmTokenRequestBody,
} from '../swagger'
import { auth } from './swagger'

export const sendTokenForSignup = async (payload: {
  email: string
  signUpType: SignUpSendTokenRequestBodySignUpTypeEnum
  agreementId: string
}): Promise<boolean | undefined> => {
  await auth.sendTokenForSignup({
    body: {
      username: payload.email,
      signUpType: payload.signUpType,
      typeIdentifier: payload.agreementId,
    },
  })
  return true
}

export const confirmTokenForSignup = async (
  payload: SignUpConfirmTokenRequestBody
): Promise<LoginResponseStorageData> => {
  try {
    const result = await auth.confirmTokenForSignup({ body: payload })
    const success =
      result.authorization === LoginResponseAuthorizationEnum.Success
    if (!success) {
      return { permissions: undefined, defaults: undefined }
    }
    // Ensure the return value is not undefined, even if |permissions| is absent, to avoid miscommunicating the logged-in status.
    const definitePermissions = result.permissions ?? []
    const definiteFeatures = result.featureDefaults ?? []
    return {
      permissions: definitePermissions,
      defaults: definiteFeatures,
      userKey: result.userKey,
      /**
       * This should still return actingAs, however on first login it should be parent
       */
      actingAs: result.actingAs,
    }
  } catch (e) {
    throw e
  }
}

export default confirmTokenForSignup
