import React, { useContext } from 'react'
import { Page } from '../Elements/PageMargins'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import TitleContext from '../../TitleContext'
import EventFormCard from '../Card/EventFormCard'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { Event } from '../../swagger'
import { EventFormCardVariants } from './EventEnums'

interface AddEventProps {
  /**
   * Optional eventDetails to make testing easier.
   * By providing details during a test, we spend less computation selecting
   * data and typing it.
   */
  eventDetails?: Event
}

const AddEvent: React.FC<AddEventProps> = ({ eventDetails }) => {
  const { t } = useTranslation()
  const title = t('Events.AddEvent.Header', 'Events')
  const navigate = useNavigate()

  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const navigateToEvents = () => {
    navigate({
      pathname: '/events',
    })
  }

  return (
    <Page>
      <DynamicBreadcrumbs
        breadcrumbs={[
          {
            label: t('Events.AddEvent.BreadCrumb', 'Events'),
            onClick: navigateToEvents,
          },
          {
            label: t('Events.AddEvent.BreadCrumb', 'Add Event'),
          },
        ]}
      />
      <EventFormCard
        variant={EventFormCardVariants.CreateEvent}
        eventDetails={eventDetails}
      />
    </Page>
  )
}

export default AddEvent
