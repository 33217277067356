import { IconButton, Typography, styled } from '@mui/material'
import React, { CSSProperties, useEffect, useState } from 'react'
import CookieConsent, {
  resetCookieConsentValue,
  getCookieConsentValue,
} from 'react-cookie-consent'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/system'
import { useShowOnDesktop } from '../hooks/useShowOnDesktop'
import { useLocation } from 'react-router'
import { Close, Cookie } from '@mui/icons-material'

interface Styles {
  buttonStyles: CSSProperties
  bannerStyle: CSSProperties
  contentStyle: CSSProperties
}

const WrapperContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.cookieBanner.main,
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'stretch',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  zIndex: 9999,
}))

const CookieBanner: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const location = useLocation()
  const isShowOnDesktop = useShowOnDesktop()
  const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL ?? ''

  const styles: Styles = {
    buttonStyles: {
      backgroundColor: theme.palette.cookieBanner.backgroundButton,
      color: 'black',
      borderRadius: '5px',
      fontSize: '16px',
      marginRight: '5px',
      marginLeft: '5px',
      border: 0,
      padding: 6,
      cursor: 'pointer',
      marginBottom: isShowOnDesktop ? 0 : 10,
      fontFamily: theme.typography.body2.fontFamily,
    },
    bannerStyle: {
      background: theme.palette.cookieBanner.main,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: isShowOnDesktop ? '92%' : '100%',
      position: 'relative',
      paddingBottom: 5,
      paddingTop: 5,
      bottom: 0,
      left: 0,
    },
    contentStyle: {
      textAlign: 'justify',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 15,
    },
  }

  /** used to display the banner whether the cookie has already been saved or not */
  const [showBanner, setShowBanner] = useState<
    'show' | 'hidden' | 'byCookieValue'
  >('byCookieValue')

  /**
   * used to hide the banner and show it again if the cookie has not been saved,
   * essentially by clicking on the 'x' button to close the banner
   **/
  const [displayBanner, setDisplayBanner] = useState(false)

  useEffect(() => {
    setDisplayBanner(!(getCookieConsentValue('CookieConsent') === 'true'))
  }, [location.pathname])

  const navigateToPrivacyPolicy = () => {
    setShowBanner('show')
    /**
     * It's necessary to delete the cookie which was set with a `false` value,
     * because when doing a full refresh the banner will no longer appear if the user has not accepted
     */
    resetCookieConsentValue()

    const { href } = new URL(privacyPolicyUrl, undefined)
    window.open(href, 'blank')
  }

  const onAccept = () => {
    setShowBanner('byCookieValue')
    setDisplayBanner(false)
  }

  return (
    <>
      {displayBanner && (
        <WrapperContainer>
          <CookieConsent
            disableStyles
            enableDeclineButton
            flipButtons
            visible={showBanner}
            onAccept={onAccept}
            onDecline={navigateToPrivacyPolicy}
            buttonText={t('CookieBanner.Accept', 'Accept')}
            buttonId="acceptId"
            declineButtonId="privacyPolicyId"
            declineButtonText={t(
              'CookieBanner.PrivacyPolicy',
              'Privacy Policy'
            )}
            style={styles.bannerStyle}
            contentStyle={styles.contentStyle}
            buttonStyle={styles.buttonStyles}
            declineButtonStyle={styles.buttonStyles}
          >
            <Cookie
              sx={{
                height: 32,
                width: 32,
                color: theme.palette.primary.contrastText,
                marginRight: theme.spacing(0.8),
              }}
            />
            <Typography
              variant="body1"
              component="p"
              fontSize={16}
              marginTop={isShowOnDesktop ? 0 : 2}
              sx={{
                color: theme.palette.primary.contrastText,
                fontFamily: theme.typography.body2.fontFamily,
              }}
            >
              {t(
                'CookieBanner.Message',
                'This site utilizes essential and enhanced cookies in order to optimize your experience. By clicking "Accept" and continuing use of the site, you are agreeing to the Classical Conversations privacy policy.'
              )}
            </Typography>
          </CookieConsent>
          <Box
            display="flex"
            alignItems={isShowOnDesktop ? 'center' : 'start'}
            sx={{
              position: isShowOnDesktop ? 'none' : 'absolute',
              width: '50px',
            }}
          >
            <IconButton
              onClick={onAccept}
              color="inherit"
              aria-label="Close Banner"
            >
              <Close
                sx={{
                  color: theme.palette.customBackground.searchbar.main,
                }}
              />
            </IconButton>
          </Box>
        </WrapperContainer>
      )}
    </>
  )
}

export default CookieBanner
