import React from 'react'
import { transcriptPdfStyles, border } from './TranscriptPDFStyles'

interface SummarySectionPDFProps {
  transcriptGPA: string
  transcriptCredits: string
  transcriptSummary?: string
}

const SummarySectionPDF: React.FC<SummarySectionPDFProps> = ({
  transcriptGPA,
  transcriptCredits,
  transcriptSummary,
}) => {
  return (
    <div
      style={{
        ...transcriptPdfStyles.box,
        border: '',
        padding: 0,
      }}
    >
      <div style={transcriptPdfStyles.documentDirection}>
        <div>
          <div style={{ marginLeft: '3px' }}>Total Credits</div>
          <div
            style={{
              marginLeft: '18px',
              marginTop: '2px',
              ...transcriptPdfStyles.weightedText,
            }}
          >
            {transcriptCredits}
          </div>
        </div>
        <div>
          <div style={{ marginLeft: '20px' }}>Cumulative GPA</div>
          <div
            style={{
              marginLeft: '44px',
              marginTop: '2px',
              ...transcriptPdfStyles.weightedText,
            }}
          >
            {transcriptGPA}
          </div>
        </div>
      </div>
      <div style={{ border, marginTop: '1px' }}>
        <div style={{ padding: '3px', paddingBottom: 0 }}>NOTES:</div>
        <div style={{ padding: '3px', paddingTop: '4px', minHeight: '86px' }}>
          {transcriptSummary}
        </div>
      </div>
    </div>
  )
}

export default SummarySectionPDF
