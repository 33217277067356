import { useMemo } from 'react'
import { ProgramType } from '../swagger/models'
import { ProgramData } from '../components/Card/DirectorDashboardDetails'

const ordinalMapOfProgramTypeEnum = new Map(
  Object.values(ProgramType).map((value, index) => {
    return [value, index]
  })
)

export function sortProgramsByType(programs: ProgramData[]): ProgramData[] {
  return programs.sort((a, b) => {
    const typeGrouping =
      (ordinalMapOfProgramTypeEnum.get(a.programType) ?? 0) -
      (ordinalMapOfProgramTypeEnum.get(b.programType) ?? 0)
    return typeGrouping === 0 ? a.programKey - b.programKey : typeGrouping
  })
}

export function useSortedByTypeDashboardPrograms(
  programs: ProgramData[]
): ProgramData[] {
  const DashboardProgramsStatus = useMemo(
    () => sortProgramsByType(programs),
    [programs]
  )

  return DashboardProgramsStatus
}

export default useSortedByTypeDashboardPrograms
