import { Middleware, ResponseContext } from '../swagger/runtime'
export class LogoutSessionMiddleware implements Middleware {
  constructor(
    public logout: () => Promise<boolean>,
    public shouldIgnore: (url: string, init: RequestInit) => boolean
  ) {}
  async post?(context: ResponseContext): Promise<Response | void> {
    if (
      !this.shouldIgnore(context.url, context.init) &&
      context.response.status === 401
    ) {
      await this.logout()
    }
  }
}
