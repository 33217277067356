import React from 'react'
import TablePagination from '@mui/material/TablePagination'
import TablePaginationActions from '../Pagination/TablePaginationActions'
import { TableFooter, TableRow } from '@mui/material'

export const defaultPageSize =
  Number(process.env.REACT_APP_PAGINATION_DEFAULT_PAGE_SIZE) ?? 25

interface TablePaginationProps {
  label?: string
  useDefaultPageSize?: boolean
  count: number
  page: number
  rowsPerPage: number
  colSpan: number
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void
  onChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

const TableFooterPagination: React.FC<TablePaginationProps> = (
  props: TablePaginationProps
) => {
  const {
    count,
    page,
    rowsPerPage,
    colSpan,
    onChangeRowsPerPage,
    onChangePage,
    useDefaultPageSize = true,
  } = props

  //If the table that uses the TableFooterPagination sends the label property or not will build a different array to avoid errors
  //with the material ui TablePagination

  const rowsPerPageOptions = useDefaultPageSize
    ? [defaultPageSize, 50, 100]
    : [rowsPerPage, 20, 30]

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={colSpan}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  )
}

export default TableFooterPagination
