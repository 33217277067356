/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface State
 */
export interface State {
  /**
   * Full name of a state or province.
   * @type {string}
   * @memberof State
   */
  name: string
  /**
   * ISO 3166-1 Alpha-2 two-letter ISO state code as the primary key. However, there are exceptions to this rule like New Zealand which uses 3 letters or Ireland which uses only one.
   * @type {string}
   * @memberof State
   */
  stateCode: string
  /**
   * An ISO 3166-1 alpha-2 country code like US, CA, or FR.
   * @type {string}
   * @memberof State
   */
  countryCode: string
}

export function StateFromJSON(json: any): State {
  return StateFromJSONTyped(json, false)
}

export function StateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): State {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    stateCode: json['stateCode'],
    countryCode: json['countryCode'],
  }
}

export function StateToJSON(value?: State | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    stateCode: value.stateCode,
    countryCode: value.countryCode,
  }
}
