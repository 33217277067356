import { Box, Paper, Collapse, useTheme, Typography } from '@mui/material'
import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ChildrenBirthMonthField from '../Elements/ChildrenBirthMonthField'
import ChildrenNameField from '../Elements/ChildrenNameField'
import { StudentDynamicFieldProps } from '../Table/StudentEnrollmentsRows'
import ChildrenEnrollmentStatusField from '../Elements/ChildrenEnrollmentStatusField'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'
import ChildrenBirthYearField from '../Elements/ChildrenBirthYearField'
import CheckboxControl from '../Checkboxes/CheckboxControl'
import { useAuth } from '../Routes/AuthProvider'

const ChildrenEnrollmentCard: React.FC<StudentDynamicFieldProps> = ({
  kid,
  isEditMode,
  handleFieldValueChange,
  childNameStates,
  childHideStates,
  childBirthMonthStates,
  childBirthYearStates,
  canUserUpdateAnyEnrollmentStatus,
  isAccountVariant,
  handleEnrollmentStatusChange,
  directorOnlyEnrollmentStatuses,
  enrollmentStates,
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(true)
  const { permissionAbility } = useAuth()

  const enrollmentStatusForStudents = (status: string): string => {
    return enrollmentStates?.[status]
      ? enrollmentStates?.[status].state.replace('_', ' ')
      : ''
  }

  const canHideChild = permissionAbility.can('hide', 'Student')

  const enrollmentStatusRows = kid.programs.map((program) => {
    const disabled =
      (!canUserUpdateAnyEnrollmentStatus &&
        // So disable the field if the value is something we cannot see (PENDING, CHECK, INVITED)
        !directorOnlyEnrollmentStatuses.some(
          (status) =>
            status ===
            enrollmentStatusForStudents(
              `${program.programKey}-${kid.studentKey}`
            )
        )) ||
      !isEditMode

    const studentEnrollmentStatus = enrollmentStatusForStudents(
      `${program.programKey}-${kid.studentKey}`
    )
    if (enrollmentStates) {
      return (
        <Box key={program.programKey}>
          <ChildrenEnrollmentStatusField
            kid={kid}
            program={program}
            updateForParent={handleEnrollmentStatusChange}
            enrollmentStates={enrollmentStates}
            studentEnrollmentStatus={studentEnrollmentStatus}
            isEditMode={isEditMode}
            disabled={disabled}
            isMobile
            key={`${program.programKey}-${kid.studentKey}`}
          />
          <Typography
            variant="body1"
            component="p"
            sx={{
              paddingLeft: theme.spacing(3.5),
            }}
          >
            {!new RegExp(/Invalid Date/i).test(
              program.enrollmentStatusValidFrom.toString()
            )
              ? dateToSlashStringReinterpretedAsLocal(
                  program.enrollmentStatusValidFrom
                )
              : ''}
          </Typography>
        </Box>
      )
    } else {
      return null
    }
  })

  return (
    <Box pb={2}>
      {(!kid.hidden || canHideChild) && (
        <Paper
          sx={{
            ...(isAccountVariant && {
              marginTop: theme.spacing(3),
              padding: theme.spacing(4, 4, 5.5),
            }),
          }}
        >
          <Box alignItems="center" width="100%" pt={1}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{
                width: '100%',
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
          <Box padding={2} color="primary.main" key={`${kid.studentKey}-name`}>
            <ChildrenNameField
              kid={kid}
              isEditMode={isEditMode}
              updateForParent={handleFieldValueChange}
              childNameStates={childNameStates}
              isMobile
            />
            <Collapse in={open} unmountOnExit timeout="auto">
              <ChildrenBirthMonthField
                kid={kid}
                isEditMode={isEditMode}
                updateForParent={handleFieldValueChange}
                childBirthMonthStates={childBirthMonthStates}
                isMobile
              />
            </Collapse>
            <Collapse in={open} unmountOnExit timeout="auto">
              <ChildrenBirthYearField
                kid={kid}
                isEditMode={isEditMode}
                updateForParent={handleFieldValueChange}
                childBirthYearStates={childBirthYearStates}
                isMobile
              />
            </Collapse>
            {canHideChild && (
              <Collapse in={open} unmountOnExit timeout="auto">
                <CheckboxControl
                  label="Hide Child"
                  name="hideChild"
                  checkboxValue={
                    childHideStates[`${kid.studentKey}-hide`]
                      ? JSON.parse(
                          childHideStates[`${kid.studentKey}-hide`].state
                        )
                      : false
                  }
                  disabled={!isEditMode}
                  OnChange={(event) =>
                    handleFieldValueChange(
                      `${kid.studentKey}-hide`,
                      event.toString()
                    )
                  }
                />
              </Collapse>
            )}
            <Collapse in={open} unmountOnExit timeout="auto">
              {enrollmentStatusRows}
            </Collapse>
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export default ChildrenEnrollmentCard
