/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CashAmount,
  CashAmountFromJSON,
  CashAmountToJSON,
  EnrollmentDiscount,
  EnrollmentDiscountFromJSON,
  EnrollmentDiscountToJSON,
  EnrollmentFee,
  EnrollmentFeeFromJSON,
  EnrollmentFeeToJSON,
  EnrollmentInvoiceStub,
  EnrollmentInvoiceStubFromJSON,
  EnrollmentInvoiceStubToJSON,
} from './'

/**
 *
 * @export
 * @interface EnrollmentBill
 */
export interface EnrollmentBill {
  /**
   *
   * @type {EnrollmentInvoiceStub}
   * @memberof EnrollmentBill
   */
  _for: EnrollmentInvoiceStub
  /**
   *
   * @type {CashAmount}
   * @memberof EnrollmentBill
   */
  total: CashAmount
  /**
   *
   * @type {Array<EnrollmentFee>}
   * @memberof EnrollmentBill
   */
  fees: Array<EnrollmentFee>
  /**
   *
   * @type {Array<EnrollmentDiscount>}
   * @memberof EnrollmentBill
   */
  discounts?: Array<EnrollmentDiscount>
  /**
   * Amount Due text color when total due is greater than 0 and it is after the region start date
   * @type {string}
   * @memberof EnrollmentBill
   */
  textColor?: string
}

export function EnrollmentBillFromJSON(json: any): EnrollmentBill {
  return EnrollmentBillFromJSONTyped(json, false)
}

export function EnrollmentBillFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EnrollmentBill {
  if (json === undefined || json === null) {
    return json
  }
  return {
    _for: EnrollmentInvoiceStubFromJSON(json['for']),
    total: CashAmountFromJSON(json['total']),
    fees: (json['fees'] as Array<any>).map(EnrollmentFeeFromJSON),
    discounts: !exists(json, 'discounts')
      ? undefined
      : (json['discounts'] as Array<any>).map(EnrollmentDiscountFromJSON),
    textColor: !exists(json, 'textColor') ? undefined : json['textColor'],
  }
}

export function EnrollmentBillToJSON(value?: EnrollmentBill | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    for: EnrollmentInvoiceStubToJSON(value._for),
    total: CashAmountToJSON(value.total),
    fees: (value.fees as Array<any>).map(EnrollmentFeeToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(EnrollmentDiscountToJSON),
    textColor: value.textColor,
  }
}
