import React, { CSSProperties } from 'react'
import DropDown, { DropDownVariant } from './DropDown'
import { Box, useTheme } from '@mui/material'
import { useCountryContext } from '../Context/CountryContext'

interface CountryDropDownProps {
  /** A unique string to distinguish between other drop downs  */
  ids: string[]
  /** Optional label value for form fields*/
  labels: string[]
  /** Variant to determine styling and behavior */
  variant?: DropDownVariant
  /** Optional fullWidth.  Currently being used on Events (Mobile)
   */
  fullWidth?: boolean
  /** Current selected MenuOption value for each dropdown */
  values: string[]
  /**
   * Handles the selection on the parent component based on the id of the drop
   * down. Different drop downs perform different actions.
   */
  handleSelection: (
    /** Name of the MenuOption selected */
    name: string,
    /** Id of the drop down to determine which action to perform */
    id: string,
    selectOptionId: string | number
  ) => void
  /** Optional value to disable the dropdown*/
  disabled?: boolean

  /** optional value to customize input styles when is disabled */
  styles?: CSSProperties
}

export const initialCountrySelected = {
  name: 'United States',
  countryCode: 'US',
}

const CountryDropDown: React.FC<CountryDropDownProps> = ({
  disabled,
  handleSelection,
  ids,
  labels,
  values,
  variant = DropDownVariant.FormField,
  styles,
}) => {
  const theme = useTheme()

  const [citizenshipLabel, residenceLabel] = labels
  const [citizenshipIds, residenceIds] = ids
  const [citizenshipValue, residenceValue] = values
  const { countryOptions } = useCountryContext()

  // Set US as default value, make sure it's always at position One
  const countryOptionsSorted = countryOptions.filter(
    (country) => country.countryCode !== 'US'
  )
  countryOptionsSorted.unshift(initialCountrySelected)

  return (
    <>
      {countryOptions.length > 0 && (
        <>
          <Box mt={theme.spacing(2)}>
            <DropDown
              label={residenceLabel}
              id={residenceIds}
              menuOptions={countryOptionsSorted.map(({ name, countryCode }) => {
                return { name, id: countryCode }
              })}
              handleSelection={handleSelection}
              variant={variant}
              value={residenceValue}
              defaultValue={countryOptionsSorted[0].name} // Provide a default value (US) and prevent material UI warnings
              disabled={disabled}
              formControlProps={{
                height: theme.spacing(8),
                width: '100%',
              }}
              cssProps={{
                height: theme.spacing(8),
                ...(disabled && {
                  ...styles,
                }),
              }}
            />
          </Box>
          <Box mt={theme.spacing(2)}>
            <DropDown
              label={citizenshipLabel}
              id={citizenshipIds}
              menuOptions={countryOptionsSorted.map(({ name, countryCode }) => {
                return { name, id: countryCode }
              })}
              handleSelection={handleSelection}
              variant={variant}
              value={citizenshipValue}
              defaultValue={countryOptionsSorted[0].name} // Provide a default value (US) and prevent material UI warnings
              disabled={disabled}
              formControlProps={{
                height: theme.spacing(8),
                width: '100%',
              }}
              cssProps={{
                height: theme.spacing(8),
                ...(disabled && {
                  ...styles,
                }),
              }}
            />
          </Box>
        </>
      )}
    </>
  )
}

export default CountryDropDown
