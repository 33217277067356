import React from 'react'
import { useTranslation } from 'react-i18next'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import BasicModal from './BasicModal'

interface EnrollmentFeeInfoModalProps {
  isOpen: boolean
  onClose: () => void
}

const EnrollmentFeeInfoModal: React.FunctionComponent<EnrollmentFeeInfoModalProps> =
  (props) => {
    const { t } = useTranslation()

    return (
      <BasicModal
        isOpen={props.isOpen}
        dialogTitle={t('EnrollmentFee.MoreInfo', 'Coming Soon')}
        afterClose={() => null}
        handleFormSubmit={() => null}
        ariaLabel="Enrollment Fee Info Modal"
        maxWidth="md"
        dialogContent={<>CCP1-2540</>}
        dialogActions={
          <ContainedButton
            id="enrollmentFeeInfoModalClose"
            variant={ContainedButtonVariant.Close}
            onClick={props.onClose}
          />
        }
      />
    )
  }

export default EnrollmentFeeInfoModal
