import React, { useState, useEffect } from 'react'
import { useTheme, SxProps } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { styled } from '@mui/system'
import { debounce } from '@mui/material'
import { DEBOUNCE_LIMIT } from '../../utils/constants'

const Form = styled('form')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: '400px',
  },
}))

interface SearchBarProps {
  handleSearch: (searchText: string) => void
  placeholder?: string
  searchValue?: string
  clearAndHandleSearch?: () => void
  isDisable?: boolean
  sx?: SxProps
  searchDebounce?: boolean
}

const SearchBar: React.FC<SearchBarProps> = ({
  handleSearch,
  placeholder = 'Search',
  searchValue = '',
  clearAndHandleSearch: clearAndHandleSearchFromParent,
  isDisable = false,
  sx,
  searchDebounce,
}) => {
  const theme = useTheme()
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    setSearchText(searchValue)
  }, [searchValue])

  const clearAndHandleSearch = () => {
    setSearchText('')
    handleSearch('')
    clearAndHandleSearchFromParent?.()
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSearch(searchText)
  }

  const showClearButton = !!searchText

  return (
    <Form autoComplete="off" noValidate role="search" onSubmit={onSubmit}>
      <TextField
        id="search-input"
        type="text"
        variant="outlined"
        fullWidth
        hiddenLabel
        disabled={isDisable}
        inputProps={{ 'aria-label': placeholder }}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-root': {
            minHeight: theme.spacing(4.5),
            borderRadius: 0,
            marginBottom: theme.spacing(3),
            backgroundColor: theme.palette.customBackground.searchbar.main,
            '&:hover': {
              borderColor: 'transparent',
            },
          },
          '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: theme.spacing(1.5),
          },
          '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
          },
          '& .MuiOutlinedInput-input': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            '&.MuiInputBase-input': {
              height: 'initial',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          ...sx,
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                color: theme.palette.textOrIcon.searchIcon,
              }}
            >
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: showClearButton && (
            <IconButton
              id="clearSearch"
              aria-label="clear search"
              sx={{
                padding: theme.spacing(0.9375),
                color: theme.palette.textOrIcon.searchIcon,
              }}
              onClick={clearAndHandleSearch}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
        onChange={(e) => setSearchText(e.target.value)}
        onInput={debounce(() => {
          if (searchDebounce) handleSearch(searchText)
        }, DEBOUNCE_LIMIT)}
        value={searchText}
      />
    </Form>
  )
}

export default SearchBar
