import React from 'react'
import TableHeaders from '../Interfaces/TableHeaders'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material'

interface TableHeaderProps {
  tableHeaders: TableHeaders[]
}

export const TableHeader: React.FC<TableHeaderProps> = ({ tableHeaders }) => {
  const theme = useTheme()
  return (
    <TableRow>
      {tableHeaders.map(
        ({
          id,
          label,
          align,
          cssProps,
          render,
          htmlProps = {},
          colSpan = 1,
        }) => (
          <TableCell
            key={id ?? label}
            sx={{
              color: theme.palette.textOrIcon.tableHeader,
              ...cssProps,
            }}
            align={align}
            id={id ?? label}
            {...htmlProps}
            colSpan={colSpan}
          >
            {render || (
              <Typography variant="subtitle2" component="p">
                {label}
              </Typography>
            )}
          </TableCell>
        )
      )}
    </TableRow>
  )
}

export default TableHeader
