export const useValidationMessages = (
  validationFields: { field: string; message: string }[]
): Map<string, string> => {
  const validationStringMap = new Map<string, string>()

  // Setting up a default message that can be overridden if the validation fields contain 'default'
  validationStringMap.set(
    'default',
    'Something went wrong. This is a general warning'
  )

  // Set each validation message to the empty string, since it should not be an error just yet.
  validationFields.forEach(({ field, message }) => {
    validationStringMap.set(field, message)
  })

  return validationStringMap
}

export default useValidationMessages
