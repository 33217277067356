import React from 'react'
import { filterAllowedEnrollmentDropDownOptions } from '../../helpers/enrollmentHelper'
import { EnrollmentStatus, Program3, Student } from '../../swagger'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import { MenuOption } from '../Menus/DropDown'
import { useAuth } from '../Routes/AuthProvider'
import DynamicFormField from './DynamicFormField'
import { getLabelForEnrollmentStatus } from '../Families/EnrollmentsTable'
import { useTranslation } from 'react-i18next'

interface ChildrenEnrollmentStatusFieldProps {
  kid: Student
  program: Program3
  updateForParent: (opts: {
    fieldId: string
    enrollmentStatus: string
    studentKey: number
    programKey: number
    studentName: string
  }) => void
  isEditMode: boolean
  isMobile?: boolean
  enrollmentStates: DynamicFieldStates
  studentEnrollmentStatus: string
  disabled: boolean
}

const ChildrenEnrollmentStatusField: React.FC<
  ChildrenEnrollmentStatusFieldProps
> = ({
  kid,
  program,
  updateForParent,
  isEditMode,
  isMobile,
  enrollmentStates,
  disabled,
  studentEnrollmentStatus,
}) => {
  const { permissionAbility } = useAuth()
  const { t } = useTranslation()
  const canUserUpdateAnyEnrollmentStatus = permissionAbility.can(
    'updateToAnyStatus',
    'Enrollment'
  )
  const enrollmentStatusDropdownOptions: MenuOption[] =
    filterAllowedEnrollmentDropDownOptions(
      program.enrollmentStatuses,
      canUserUpdateAnyEnrollmentStatus
    )
  // It ensures 2 things
  // Change the enrollment status from DROP to DROPPED
  // ensures the translation of the values displayed in the dropdown
  enrollmentStatusDropdownOptions.map((enrollmentStatus) => {
    return (enrollmentStatus.name = getLabelForEnrollmentStatus(
      enrollmentStatus.name,
      t
    ))
  })

  const programType = program.programType

  return (
    <DynamicFormField
      key={`${program.programKey}-${kid.studentKey}`}
      id={`${program.programKey}-${kid.studentKey}`}
      name={`${program.programKey}-${kid.studentKey}`}
      label={
        isMobile
          ? t('childrenField.label.programType', 'Program: {{programType}}', {
              programType,
            })
          : ''
      }
      updateForParent={(name, updatedValue) =>
        updateForParent({
          fieldId: `${program.programKey}-${kid.studentKey}`,
          enrollmentStatus:
            updatedValue === 'DROPPED' ? EnrollmentStatus.Drop : updatedValue,
          studentKey: kid.studentKey,
          programKey: program.programKey,
          studentName: kid.firstName,
        })
      }
      value={getLabelForEnrollmentStatus(studentEnrollmentStatus, t)}
      isValid={
        enrollmentStates[`${program.programKey}-${kid.studentKey}`]?.isValid
      }
      required={true}
      inputType={isEditMode ? 'dropdown' : 'text'}
      dropdownOptions={enrollmentStatusDropdownOptions}
      //If no in edit mode field should be disabled otherwise set the prop value
      disabled={!isEditMode ? true : disabled}
      fullWidth={isMobile}
    />
  )
}

export default ChildrenEnrollmentStatusField
