/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  CancelEnrollmentRequestParams,
  CancelEnrollmentRequestParamsToJSON,
  CompleteEnrollmentInviteResponse,
  CompleteEnrollmentInviteResponseFromJSON,
  CreateLicensingEnrollment,
  CreateLicensingEnrollmentToJSON,
  EnrollmentInfo,
  EnrollmentInfoFromJSON,
  EnrollmentInviteCompleteRequestBody,
  EnrollmentInviteCompleteRequestBodyToJSON,
  EnrollmentInvitesResponse,
  EnrollmentInvitesResponseFromJSON,
  EnrollmentStatusesResponse,
  EnrollmentStatusesResponseFromJSON,
  EnrollmentStatusUpdate,
  EnrollmentStatusUpdateToJSON,
  RemoveLicensingEnrollmentRequestBody,
  RemoveLicensingEnrollmentRequestBodyToJSON,
  RemoveLicensingEnrollmentRequestResponse,
  RemoveLicensingEnrollmentRequestResponseFromJSON,
  TransferEnrollmentRequestParams,
  TransferEnrollmentRequestParamsToJSON,
  UpdateLicensingEnrollmentRequestBody,
  UpdateLicensingEnrollmentRequestBodyToJSON,
  ValidatePotentialEnrollmentsRequestBody,
  ValidatePotentialEnrollmentsRequestBodyToJSON,
  ValidatePotentialEnrollmentsResponse,
  ValidatePotentialEnrollmentsResponseFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface CancelEnrollmentRequest extends runtime.BaseRequestParameters {
  body?: CancelEnrollmentRequestParams
}

export interface CompleteEnrollmentInviteRequest
  extends runtime.BaseRequestParameters {
  enrollmentInviteUuid: string
  body?: EnrollmentInviteCompleteRequestBody
}

export interface CreateLicensingEnrollmentRequest
  extends runtime.BaseRequestParameters {
  body?: CreateLicensingEnrollment
}

export interface FetchEnrollmentInvitesRequest
  extends runtime.BaseRequestParameters {
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  programKey?: number
  status?: FetchEnrollmentInvitesStatusEnum
  sentToEmail?: string
  search?: string
}

export interface FetchEnrollmentStatusesRequest
  extends runtime.BaseRequestParameters {
  programKey?: number
  year?: number
  transfersOnly?: boolean
  enrollmentStatus?: string
  tutorActorKey?: number
  search?: string
}

export interface FetchEnrollmentsRequest extends runtime.BaseRequestParameters {
  page?: number
  pageSize?: number
  orderBy?: Array<string>
  programKey?: number
  year?: number
  transfersOnly?: boolean
  enrollmentStatus?: string
  tutorActorKey?: number
  search?: string
}

export interface RemoveLicensingEnrollmentsRequest
  extends runtime.BaseRequestParameters {
  body?: RemoveLicensingEnrollmentRequestBody
}

export interface TransferEnrollmentRequest
  extends runtime.BaseRequestParameters {
  body?: TransferEnrollmentRequestParams
}

export interface UpdateEnrollmentStatusRequest
  extends runtime.BaseRequestParameters {
  body?: EnrollmentStatusUpdate
}

export interface UpdateLicensingEnrollmentsRequest
  extends runtime.BaseRequestParameters {
  body?: UpdateLicensingEnrollmentRequestBody
}

export interface ValidatePotentialEnrollmentsRequest
  extends runtime.BaseRequestParameters {
  body?: ValidatePotentialEnrollmentsRequestBody
}

/**
 *
 */
export class EnrollmentsApi extends runtime.BaseAPI {
  /**
   * Canceling an enrollment:       * Cancel an enrollment indicates a permanent end for the enrollment record, so it should not be counted toward any new billing/invoicing and should not affect any historical transactions.       * Enrollment with any status can be canceled.       * StudentKey and ProgramKey are required parameters and should exists in the app; otherwise a 404 error will occur.       * Enrollment should exists in the app; otherwise a 404 error will occur.       ## PERMISSIONS:       - The requester or someone in their downline must have the following permission to cancel enrollments:  Enrollment/cancel[their team\'s], else a 403 status code will occur.       - If the requester has active permissions:  Enrollment/cancel[any], they can cancel any enrollment.`,
   * cancel an enrollment.
   */
  async cancelEnrollmentRaw(
    requestParameters: CancelEnrollmentRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollments/cancel`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: CancelEnrollmentRequestParamsToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Canceling an enrollment:       * Cancel an enrollment indicates a permanent end for the enrollment record, so it should not be counted toward any new billing/invoicing and should not affect any historical transactions.       * Enrollment with any status can be canceled.       * StudentKey and ProgramKey are required parameters and should exists in the app; otherwise a 404 error will occur.       * Enrollment should exists in the app; otherwise a 404 error will occur.       ## PERMISSIONS:       - The requester or someone in their downline must have the following permission to cancel enrollments:  Enrollment/cancel[their team\'s], else a 403 status code will occur.       - If the requester has active permissions:  Enrollment/cancel[any], they can cancel any enrollment.`,
   * cancel an enrollment.
   */
  async cancelEnrollment(
    requestParameters: CancelEnrollmentRequest
  ): Promise<void> {
    await this.cancelEnrollmentRaw(requestParameters)
  }

  /**
   *  Completes an enrollment invitation and creates the enrollments for student/program combinations specified.  For each enrollment specified, if a studentKey is provided, the existing student pertaining to that key will be used for the new enrollment in the program. If a studentKey is not provided, a new student will be created where the requester is the parent, and the first name will be the specified studentFirstName. No age will be assigned at this point.  Each enrollment will be created with a status of PENDING (meaning that the parent must pay the enrollment fee to gain access to program content) unless the specified program has an enrollment fee of 0. In this case, the student\'s enrollment will be automatically set to ENROLLED, as no payment is due to gain access.
   * Complete an enrollment invitation and create enrollments.
   */
  async completeEnrollmentInviteRaw(
    requestParameters: CompleteEnrollmentInviteRequest
  ): Promise<runtime.ApiResponse<CompleteEnrollmentInviteResponse>> {
    if (
      requestParameters.enrollmentInviteUuid === null ||
      requestParameters.enrollmentInviteUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'enrollmentInviteUuid',
        'Required parameter requestParameters.enrollmentInviteUuid was null or undefined when calling completeEnrollmentInvite.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollments/invite/{enrollmentInviteUuid}/complete`.replace(
        `{${'enrollmentInviteUuid'}}`,
        encodeURIComponent(String(requestParameters.enrollmentInviteUuid))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: EnrollmentInviteCompleteRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CompleteEnrollmentInviteResponseFromJSON(jsonValue)
    )
  }

  /**
   *  Completes an enrollment invitation and creates the enrollments for student/program combinations specified.  For each enrollment specified, if a studentKey is provided, the existing student pertaining to that key will be used for the new enrollment in the program. If a studentKey is not provided, a new student will be created where the requester is the parent, and the first name will be the specified studentFirstName. No age will be assigned at this point.  Each enrollment will be created with a status of PENDING (meaning that the parent must pay the enrollment fee to gain access to program content) unless the specified program has an enrollment fee of 0. In this case, the student\'s enrollment will be automatically set to ENROLLED, as no payment is due to gain access.
   * Complete an enrollment invitation and create enrollments.
   */
  async completeEnrollmentInvite(
    requestParameters: CompleteEnrollmentInviteRequest
  ): Promise<CompleteEnrollmentInviteResponse> {
    const response = await this.completeEnrollmentInviteRaw(requestParameters)
    return await response.value()
  }

  /**
   * allows the creation of multiple licensing enrollments by assigning the corresponding feeTypeKey and feeAmount.
   * create licensing enrollment records
   */
  async createLicensingEnrollmentRaw(
    requestParameters: CreateLicensingEnrollmentRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollments/createLicensingEnrollment`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: CreateLicensingEnrollmentToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * allows the creation of multiple licensing enrollments by assigning the corresponding feeTypeKey and feeAmount.
   * create licensing enrollment records
   */
  async createLicensingEnrollment(
    requestParameters: CreateLicensingEnrollmentRequest
  ): Promise<void> {
    await this.createLicensingEnrollmentRaw(requestParameters)
  }

  /**
   * If user is actingAs a parent returns all enrollment invites with InProgress status along with the programs associated with that enrollmentInvite, otherwise returns all enrollment invites the user can see.
   * Fetches all enrollment invites a user can see, potentially filtered by program, status, or sentToEmail
   */
  async fetchEnrollmentInvitesRaw(
    requestParameters: FetchEnrollmentInvitesRequest
  ): Promise<runtime.ApiResponse<EnrollmentInvitesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.programKey !== undefined) {
      queryParameters['programKey'] = requestParameters.programKey
    }

    if (requestParameters.status !== undefined) {
      queryParameters['status'] = requestParameters.status
    }

    if (requestParameters.sentToEmail !== undefined) {
      queryParameters['sentToEmail'] = requestParameters.sentToEmail
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/invites`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnrollmentInvitesResponseFromJSON(jsonValue)
    )
  }

  /**
   * If user is actingAs a parent returns all enrollment invites with InProgress status along with the programs associated with that enrollmentInvite, otherwise returns all enrollment invites the user can see.
   * Fetches all enrollment invites a user can see, potentially filtered by program, status, or sentToEmail
   */
  async fetchEnrollmentInvites(
    requestParameters: FetchEnrollmentInvitesRequest
  ): Promise<EnrollmentInvitesResponse> {
    const response = await this.fetchEnrollmentInvitesRaw(requestParameters)
    return await response.value()
  }

  /**
   * These are the statuses that a student enrollment for a program can be.  PENDING: The family has accepted the invitation with a new or existing account, but has not paid or indicated they will pay by check. Family has no access to program content.  ENROLLED: Family has paid their enrollment fee online, or check has been received by CC and deposited. Family has access to program content for weeks 1-6.  DROP: The student has been dropped from the program. Family has no access to program content.  * A director can manually set a student to DROP at any time.  * If tuition has not been paid for an ENROLLED student after 6 weeks, the student is automatically set to DROP.  * If tuition for semester 2 of a challenge program hasn\'t been paid after 4 weeks of semester 2 start date, the student is automatically set to DROP.  * A director can reverse a status of DROP back to a MEMBER status at their discretion if the family has paid.  * An enrollment can also be marked as DROP if the enrollment is in a PENDING state when it passes the end date.  COMPLETE: The enrollment has naturally naturally passed its end date, and has been marked as COMPLETE. It\'s marked as COMPLETE if it is in the ENROLLED, MEMBER_HALF or MEMBER_FULL status.   Additional Info: https://basecamp.com/2434584/projects/17523922/uploads/50818971   NOTE ABOUT ORDER OF STATUSES: Going from PENDING to ENROLLED will be either handled via an automatic process or by a manual adjustment. To update from PENDING to ENROLLED or from any status back to PENDING, the calling user will need to have Enrollment/manuallyEnroll. After this, these status changes can go in any order. To be able to update the statuses for an enrollment, the calling user will need Enrollment/update. Enrollment/update/any will the actor to update any Enrollment record. Enrollment/update/their team\'s will allow the actor to edit any student\'s enrollment record for the programs they manage.
   * Lists all enrollment statuses.
   */
  async fetchEnrollmentStatusesRaw(
    requestParameters: FetchEnrollmentStatusesRequest
  ): Promise<runtime.ApiResponse<EnrollmentStatusesResponse>> {
    const queryParameters: any = {}

    if (requestParameters.programKey !== undefined) {
      queryParameters['programKey'] = requestParameters.programKey
    }

    if (requestParameters.year !== undefined) {
      queryParameters['year'] = requestParameters.year
    }

    if (requestParameters.transfersOnly !== undefined) {
      queryParameters['transfersOnly'] = requestParameters.transfersOnly
    }

    if (requestParameters.enrollmentStatus !== undefined) {
      queryParameters['enrollmentStatus'] = requestParameters.enrollmentStatus
    }

    if (requestParameters.tutorActorKey !== undefined) {
      queryParameters['tutorActorKey'] = requestParameters.tutorActorKey
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollmentStatuses`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnrollmentStatusesResponseFromJSON(jsonValue)
    )
  }

  /**
   * These are the statuses that a student enrollment for a program can be.  PENDING: The family has accepted the invitation with a new or existing account, but has not paid or indicated they will pay by check. Family has no access to program content.  ENROLLED: Family has paid their enrollment fee online, or check has been received by CC and deposited. Family has access to program content for weeks 1-6.  DROP: The student has been dropped from the program. Family has no access to program content.  * A director can manually set a student to DROP at any time.  * If tuition has not been paid for an ENROLLED student after 6 weeks, the student is automatically set to DROP.  * If tuition for semester 2 of a challenge program hasn\'t been paid after 4 weeks of semester 2 start date, the student is automatically set to DROP.  * A director can reverse a status of DROP back to a MEMBER status at their discretion if the family has paid.  * An enrollment can also be marked as DROP if the enrollment is in a PENDING state when it passes the end date.  COMPLETE: The enrollment has naturally naturally passed its end date, and has been marked as COMPLETE. It\'s marked as COMPLETE if it is in the ENROLLED, MEMBER_HALF or MEMBER_FULL status.   Additional Info: https://basecamp.com/2434584/projects/17523922/uploads/50818971   NOTE ABOUT ORDER OF STATUSES: Going from PENDING to ENROLLED will be either handled via an automatic process or by a manual adjustment. To update from PENDING to ENROLLED or from any status back to PENDING, the calling user will need to have Enrollment/manuallyEnroll. After this, these status changes can go in any order. To be able to update the statuses for an enrollment, the calling user will need Enrollment/update. Enrollment/update/any will the actor to update any Enrollment record. Enrollment/update/their team\'s will allow the actor to edit any student\'s enrollment record for the programs they manage.
   * Lists all enrollment statuses.
   */
  async fetchEnrollmentStatuses(
    requestParameters: FetchEnrollmentStatusesRequest
  ): Promise<EnrollmentStatusesResponse> {
    const response = await this.fetchEnrollmentStatusesRaw(requestParameters)
    return await response.value()
  }

  /**
   * List all enrollments the user can see. The user can see the enrollments for a program in which their downline actors are the program director or tutor, potentially further filtered by program, and filtered to the current year if one isn\'t provided.
   * List of all enrollments a user can see, potentially filtered by program, and filtered to the current year if one isn\'t provided.
   */
  async fetchEnrollmentsRaw(
    requestParameters: FetchEnrollmentsRequest
  ): Promise<runtime.ApiResponse<EnrollmentInfo>> {
    const queryParameters: any = {}

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters['pageSize'] = requestParameters.pageSize
    }

    if (requestParameters.orderBy) {
      queryParameters['orderBy'] = requestParameters.orderBy.join(
        runtime.COLLECTION_FORMATS['csv']
      )
    }

    if (requestParameters.programKey !== undefined) {
      queryParameters['programKey'] = requestParameters.programKey
    }

    if (requestParameters.year !== undefined) {
      queryParameters['year'] = requestParameters.year
    }

    if (requestParameters.transfersOnly !== undefined) {
      queryParameters['transfersOnly'] = requestParameters.transfersOnly
    }

    if (requestParameters.enrollmentStatus !== undefined) {
      queryParameters['enrollmentStatus'] = requestParameters.enrollmentStatus
    }

    if (requestParameters.tutorActorKey !== undefined) {
      queryParameters['tutorActorKey'] = requestParameters.tutorActorKey
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollments`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnrollmentInfoFromJSON(jsonValue)
    )
  }

  /**
   * List all enrollments the user can see. The user can see the enrollments for a program in which their downline actors are the program director or tutor, potentially further filtered by program, and filtered to the current year if one isn\'t provided.
   * List of all enrollments a user can see, potentially filtered by program, and filtered to the current year if one isn\'t provided.
   */
  async fetchEnrollments(
    requestParameters: FetchEnrollmentsRequest
  ): Promise<EnrollmentInfo> {
    const response = await this.fetchEnrollmentsRaw(requestParameters)
    return await response.value()
  }

  /**
   * Receives an array of licensingEnrollment to be removed in the database.     At least one element within the array to remove must be passed.   ## Permissions:   - The requester must have an active removeTuitionPayment grant, otherwise a 403 status code will be returned.
   * Remove licensing enrollment based on studentKey, programKey and feeTypeKey
   */
  async removeLicensingEnrollmentsRaw(
    requestParameters: RemoveLicensingEnrollmentsRequest
  ): Promise<runtime.ApiResponse<RemoveLicensingEnrollmentRequestResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollments/removeLicensingEnrollment`,
      method: 'DELETE',
      headers: headerParameters,
      query: queryParameters,
      body: RemoveLicensingEnrollmentRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RemoveLicensingEnrollmentRequestResponseFromJSON(jsonValue)
    )
  }

  /**
   * Receives an array of licensingEnrollment to be removed in the database.     At least one element within the array to remove must be passed.   ## Permissions:   - The requester must have an active removeTuitionPayment grant, otherwise a 403 status code will be returned.
   * Remove licensing enrollment based on studentKey, programKey and feeTypeKey
   */
  async removeLicensingEnrollments(
    requestParameters: RemoveLicensingEnrollmentsRequest
  ): Promise<RemoveLicensingEnrollmentRequestResponse> {
    const response = await this.removeLicensingEnrollmentsRaw(requestParameters)
    return await response.value()
  }

  /**
   * This end point is only visible for user with grant \"allowTransfer\" at this moment the    only roles that have this grant are Admin and Customer Service. This endpoint will transfer a enrollment    from a list of dropped student in the program. The end point will need three parameters (studentKey, programKey, target).   This endpoint will:   * Verify the student we are requesting to transfer is dropped   * create a new enrollment record with source studentKey and the target programKey and status \"ENROLLED\"     only if there is an associated payment regarding that enrollment (for the kid being transferred) otherwise set the status \"PENDING\".   * The new field sourceProgramKey should be populated with the programKey from the source enrollment   * Steps 3 and 4 will happen only if there was an associated payment previously for this kid being transferred.    3. create a new enrollmentPayment and paymentDiscountKey set for paymentDiscountCode=1PP    4. create a new payment with paymentMethodKey \"ADJ\" for $0 and a description of \"Transferring <student name>\" and status of \"PAID\"  The goal is we get a new enrollment based off the original and the parent sees the new enrollment but is not charged for it, or required to click \"confirm enrollment\" for the $0 charge.     .  .
   * Transfer a dropped enrollment to another program.
   */
  async transferEnrollmentRaw(
    requestParameters: TransferEnrollmentRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollment/transfer`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: TransferEnrollmentRequestParamsToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * This end point is only visible for user with grant \"allowTransfer\" at this moment the    only roles that have this grant are Admin and Customer Service. This endpoint will transfer a enrollment    from a list of dropped student in the program. The end point will need three parameters (studentKey, programKey, target).   This endpoint will:   * Verify the student we are requesting to transfer is dropped   * create a new enrollment record with source studentKey and the target programKey and status \"ENROLLED\"     only if there is an associated payment regarding that enrollment (for the kid being transferred) otherwise set the status \"PENDING\".   * The new field sourceProgramKey should be populated with the programKey from the source enrollment   * Steps 3 and 4 will happen only if there was an associated payment previously for this kid being transferred.    3. create a new enrollmentPayment and paymentDiscountKey set for paymentDiscountCode=1PP    4. create a new payment with paymentMethodKey \"ADJ\" for $0 and a description of \"Transferring <student name>\" and status of \"PAID\"  The goal is we get a new enrollment based off the original and the parent sees the new enrollment but is not charged for it, or required to click \"confirm enrollment\" for the $0 charge.     .  .
   * Transfer a dropped enrollment to another program.
   */
  async transferEnrollment(
    requestParameters: TransferEnrollmentRequest
  ): Promise<void> {
    await this.transferEnrollmentRaw(requestParameters)
  }

  /**
   * Update enrollment status based on student and program key
   */
  async updateEnrollmentStatusRaw(
    requestParameters: UpdateEnrollmentStatusRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollments`,
      method: 'PUT',
      headers: headerParameters,
      query: queryParameters,
      body: EnrollmentStatusUpdateToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Update enrollment status based on student and program key
   */
  async updateEnrollmentStatus(
    requestParameters: UpdateEnrollmentStatusRequest
  ): Promise<void> {
    await this.updateEnrollmentStatusRaw(requestParameters)
  }

  /**
   * Receives an array of licensingEnrollment to be updated in the database.     At least 1 element within the array to update must be passed.   ## Permissions:   - The requester must have an active manageLicensingEnrollment grant, otherwise a 403 status code will be returned.
   * Update licensing enrollment based on studentKey, programKey and feeTypeKey
   */
  async updateLicensingEnrollmentsRaw(
    requestParameters: UpdateLicensingEnrollmentsRequest
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollments/updateLicensingEnrollment`,
      method: 'PATCH',
      headers: headerParameters,
      query: queryParameters,
      body: UpdateLicensingEnrollmentRequestBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Receives an array of licensingEnrollment to be updated in the database.     At least 1 element within the array to update must be passed.   ## Permissions:   - The requester must have an active manageLicensingEnrollment grant, otherwise a 403 status code will be returned.
   * Update licensing enrollment based on studentKey, programKey and feeTypeKey
   */
  async updateLicensingEnrollments(
    requestParameters: UpdateLicensingEnrollmentsRequest
  ): Promise<void> {
    await this.updateLicensingEnrollmentsRaw(requestParameters)
  }

  /**
   *  Validates enrollment invite by checking if programKey + studentKey is already used in enrollments table.  returns a list of rejected studentKey + programKey combinations
   * Validate an enrollment invitation.
   */
  async validatePotentialEnrollmentsRaw(
    requestParameters: ValidatePotentialEnrollmentsRequest
  ): Promise<runtime.ApiResponse<ValidatePotentialEnrollmentsResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/enrollments/invite/validate`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: ValidatePotentialEnrollmentsRequestBodyToJSON(
        requestParameters.body
      ),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ValidatePotentialEnrollmentsResponseFromJSON(jsonValue)
    )
  }

  /**
   *  Validates enrollment invite by checking if programKey + studentKey is already used in enrollments table.  returns a list of rejected studentKey + programKey combinations
   * Validate an enrollment invitation.
   */
  async validatePotentialEnrollments(
    requestParameters: ValidatePotentialEnrollmentsRequest
  ): Promise<ValidatePotentialEnrollmentsResponse> {
    const response = await this.validatePotentialEnrollmentsRaw(
      requestParameters
    )
    return await response.value()
  }
}

/**
 * @export
 * @enum {string}
 */
export enum FetchEnrollmentInvitesStatusEnum {
  InProgress = 'InProgress',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}
