import {
  AcademicYearPrograms,
  CommunitySpecificProgramOptions,
  Programs,
  ProgramOptions,
  ProgramCreate,
  ProgramUpdate,
  ProgramType,
  ProgramInviteFamilyOptionsResponsePrograms,
  ProgramInviteFamilyOptionsResponse,
} from '../swagger'
import { users, programs } from './swagger'

export const fetchPrograms = async (): Promise<AcademicYearPrograms[]> => {
  try {
    const result = await users.fetchProgramsForUser({ userId: 'me' })
    return result
  } catch (e) {
    throw e
  }
}

export const fetchAllPrograms = async (
  communityId: number
): Promise<Programs> => {
  try {
    const result = await programs.fetchAllPrograms({ communityId })
    return result
  } catch {
    return { programs: [] }
  }
}

export const addUpdateProgram = async (
  program: ProgramUpdate // Always use the more inclusive of ProgramCreate/ProgramUpdate
): Promise<number> => {
  try {
    // Hardcoded to a known-working communityId to pass fkey constraint - using the intended communityId is blocked by the /program/options endpoint https://projekt202.atlassian.net/browse/CCP1-829
    const knownWorkingCommunityKey = 1
    // Use communityId if it is greater than 0 when adding programs for a new community, removed the void of param

    const isEdit = !!program.programsKey && program.programsKey > 0
    const communityId =
      program.communityId > 0 ? program.communityId : knownWorkingCommunityKey

    const programCreateBody: ProgramCreate = {
      ...program,
      tutorActorId:
        (program.tutorActorId ?? 0) > 0 ? program.tutorActorId : undefined,
      type: program.type as ProgramType,
      communityId,
    }
    const programUpdateBody: ProgramUpdate = {
      ...(program as ProgramUpdate),
      tutorActorId:
        (program.tutorActorId ?? 0) > 0 ? program.tutorActorId : undefined,
      communityId,
    }

    const result = isEdit
      ? await programs.updateProgram({ body: programUpdateBody })
      : await programs.createProgram({ body: programCreateBody })

    // Return the resulting programsKey to verify we made or updated the program
    return result ? result.programsKey : program.programsKey ?? -1
  } catch (error) {
    throw error //new Error('Something went wrong. Verify the fields have valid data.')
  }
}

export const fetchProgramOptions = async (): Promise<ProgramOptions> => {
  try {
    const result = await programs.fetchProgramOptions({})
    return result
  } catch {
    return { communities: [], directors: [], tutors: [], programTypes: [] }
  }
}

export const fetchCommunitySpecificProgramOptions = async (
  communityKey: number
): Promise<CommunitySpecificProgramOptions> => {
  try {
    const result = await programs.fetchCommunitySpecificProgramOptions({
      communityKey,
    })
    return result
  } catch {
    return {
      programTypes: [],
      fees: { programFeeStructures: [] },
      defaultStartDates: {
        semesterOneStartDate: new Date(),
        semesterTwoStartDate: new Date(),
      },
      directors: [],
      tutors: [],
      expiredDirectors: [],
      expiredTutors: [],
    }
  }
}

export const fetchInviteFamilyPrograms = async (
  communityKey: number
): Promise<ProgramInviteFamilyOptionsResponsePrograms[]> => {
  let result: ProgramInviteFamilyOptionsResponse = {
    programs: [],
  }

  try {
    result = await programs.fetchInviteFamilyPrograms({
      communityKey,
    })

    return result.programs
  } catch {
    return result.programs
  }
}
