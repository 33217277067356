import React, { FormEvent } from 'react'
import ConfirmationModal from './ConfirmationModal'
import { Box, TextField, Typography, styled, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { InputProperties } from '../Programs/EnrollmentsInviteTable'

const ItalicSpan = styled('span')({
  fontStyle: 'italic',
})

export enum EnrollmentInviteConfirmationModalVariant {
  Resend = 'Resend',
  Cancel = 'Cancel',
}

export interface EnrollmentsInviteConfirmationModalProps {
  isOpen: boolean
  variant: EnrollmentInviteConfirmationModalVariant
  familyNameActingOn: string
  sentEmail: InputProperties
  handleChangeSentEmail: (email: string) => void
  handleConfirmationCancel: () => void
  resendInvitationLoadingId: string
  cancelInvitationLoadingId: string
}

const EnrollmentsInviteConfirmationModal: React.FC<
  EnrollmentsInviteConfirmationModalProps
> = ({
  variant,
  isOpen,
  handleConfirmationCancel,
  familyNameActingOn,
  sentEmail,
  handleChangeSentEmail,
  resendInvitationLoadingId,
  cancelInvitationLoadingId,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const preventEventDefault = (event: FormEvent<HTMLDivElement>) =>
    event.preventDefault()

  const resendMessage = t(
    'EnrollmentsInviteTable.ConfirmationModal.Header.Resend',
    'Resend'
  )
  const cancelMessage = t(
    'EnrollmentsInviteTable.ConfirmationModal.Header.Cancel',
    'Cancel'
  )
  const baseConfirmationHeaderMessage = `${t(
    'EnrollmentsInviteTable.ConfirmationModal.Header.Base',
    'this invitation to'
  )} ${familyNameActingOn}?`

  const cancelConfirmationProps = {
    dialogTitle: `${cancelMessage} ${baseConfirmationHeaderMessage}`,
    dialogContent: (
      <Typography variant="body1" component="p" align="center">
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Cancel',
          'Are you sure you wish to cancel invitation? All programs associated with this invitation will be cancelled. Do you wish to continue?'
        )}
      </Typography>
    ),
    dialogActions: (
      <ActionButtons
        primaryButtonLoadingId={cancelInvitationLoadingId}
        primaryButtonLabel={ContainedButtonVariant.YesCancel}
        secondaryButtonLabel={TextButtonVariant.NoClose}
        secondaryClick={handleConfirmationCancel}
        useBaseButton
      />
    ),
    handleFormSubmit: preventEventDefault,
  }

  const sentEmailIdName = 'SentEmail'
  const sentEmailLabel = t(
    'Program.EnrollmentsInviteTable.Field.SentEmail',
    'Sent Email'
  )
  const sentEmailField = (
    <Box display="flex" justifyContent={'center'} alignContent="center">
      <TextField
        fullWidth
        id={sentEmailIdName}
        label={sentEmailLabel}
        inputProps={{
          'aria-label': sentEmailLabel,
        }}
        variant="filled"
        value={sentEmail.value ?? ''}
        name={sentEmailIdName}
        onChange={(e) => handleChangeSentEmail(e.target.value)}
        error={!sentEmail.valid}
        helperText={sentEmail.error}
      />
    </Box>
  )

  const resendConfirmationBody = (
    <>
      <Typography
        variant="body1"
        component="p"
        align="center"
        margin={theme.spacing(1)}
      >
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.Invitation',
          'This will resend the same invitation to the family.'
        )}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        align="center"
        margin={theme.spacing(1)}
      >
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.ReviewEmail',
          'Please review and/or modify the email address below to ensure it is correct.'
        )}
      </Typography>
      <Typography
        variant="body1"
        component="p"
        align="center"
        margin={theme.spacing(1)}
      >
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.Label',
          'For '
        )}
        <ItalicSpan>
          {t(
            'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.Span',
            'returning families, '
          )}
        </ItalicSpan>
        {t(
          'EnrollmentsInviteTable.ConfirmationModal.Body.Resend.CCEmail',
          'please ensure the email address matches their CC Connected account.'
        )}
      </Typography>
      {sentEmailField}
    </>
  )

  const resendConfirmationProps = {
    dialogTitle: `${resendMessage} ${baseConfirmationHeaderMessage}`,
    dialogContent: resendConfirmationBody,
    dialogActions: (
      <ActionButtons
        primaryButtonLoadingId={resendInvitationLoadingId}
        primaryButtonLabel={ContainedButtonVariant.YesResend}
        secondaryButtonLabel={TextButtonVariant.NoClose}
        secondaryClick={handleConfirmationCancel}
        useBaseButton
      />
    ),
    handleFormSubmit: preventEventDefault,
    handleInputChange: handleChangeSentEmail,
    viewEntrySentToEmail: true,
    nameInput: 'Sent Email',
  }

  const confirmationProps =
    variant === EnrollmentInviteConfirmationModalVariant.Resend
      ? resendConfirmationProps
      : cancelConfirmationProps

  return <ConfirmationModal {...confirmationProps} isOpen={isOpen} />
}

export default EnrollmentsInviteConfirmationModal
