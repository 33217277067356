import { Enrollment, EnrollmentStatus } from '../swagger'

export const allowedStatusesForEmail = [
  EnrollmentStatus.Enrolled,
  EnrollmentStatus.Pending,
]

/**
 * @param enrollments Enrollment Array from which the emails will be extracted
 *
 * @returns array with families emails with enrollments allowed statuses
 */
export const uniqueEmailsFromAllowedEnrollmentStatuses = ({
  enrollments,
}: {
  enrollments: Enrollment[]
}): string[] => {
  return Array.from(
    new Set(
      enrollments
        ?.filter((enrollment) =>
          allowedStatusesForEmail.includes(enrollment.status)
        )
        .map((enrollment) => enrollment.familyEmail)
    )
  )
}
