import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { dateToDashString } from '../../utils/dateUtility'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import { FormDivider } from '../Elements/FormDivider'
import { Box, Divider } from '@mui/material'
import Header, { HeaderVariant } from '../Elements/Header'
import { AvailableSpots } from './InviteFamilyFormCard'

interface InviteFamilyProgramCapacityCardProps {
  availableSpots: AvailableSpots[]
  showOnDesktop: boolean
}

/**
 * The will display the program capacity section which contain the available spots
 * and amount of spots offered in the current invite, incase there is no available
 * spots in no program it will all the programs the community has.
 * @param props
 * @returns
 */
const InviteFamilyProgramCapacityContentCard: React.VFC<
  InviteFamilyProgramCapacityCardProps
> = (props) => {
  const { t } = useTranslation()
  const { availableSpots, showOnDesktop } = props

  const programCapacity = t(
    'Invite.InviteFamilyProgramCapacityContentCard.ProgramCapacity',
    'Program Capacity'
  )

  const availableSpotsText = t(
    'Invite.InviteFamilyProgramCapacityContentCard.AvailableSpots',
    'Available Spots'
  )

  const SpotsOfferedInCurrentInviteText = t(
    'Invite.InviteFamilyProgramCapacityContentCard.SpotsOfferedInCurrentInviteText',
    'Spots Offered In Current Invite'
  )

  return (
    <section aria-label="sectionProgramCapacity">
      <FormDivider />
      <Header
        id="programCapacity"
        headerName={programCapacity}
        component="h2"
        variant={HeaderVariant.Card}
      />
      {availableSpots.map((program) => (
        <Box
          aria-label={`grid-row-container`}
          key={`grid-container-${program.programKey}`}
        >
          <Grid
            container
            spacing={1}
            style={{
              paddingLeft: '2rem',
              paddingBottom: showOnDesktop ? '0.2rem' : '1rem',
            }}
          >
            <Grid xs={12} md={4} item>
              <Typography variant="subtitle1">
                {program.programType} (
                {dateToDashString(
                  reinterpretYearMonthDayAsLocalTime(program.dateProgram)
                )}
                )
              </Typography>
            </Grid>
            <Grid xs={12} md={4} item>
              <Typography variant="subtitle1">
                {availableSpotsText}: {program.availableSpots}
              </Typography>
            </Grid>
            <Grid xs={12} md={4} item>
              <Typography variant="subtitle1">
                {SpotsOfferedInCurrentInviteText}:{' '}
                {program.spotsOfferedInCurrentInvite}
              </Typography>
            </Grid>
            <Divider />
          </Grid>
        </Box>
      ))}
    </section>
  )
}

export default InviteFamilyProgramCapacityContentCard
