import React, { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router'
import { useAuth } from './AuthProvider'

const PrivateRoute: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { isLoggedIn, countryInformation } = useAuth()
  const location = useLocation()

  const hasCountryInfo =
    !!countryInformation.countryOfCitizenship &&
    !!countryInformation.countryOfResidence

  if (!isLoggedIn)
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{ from: location.pathname }}
        replace
      />
    )

  return hasCountryInfo ? (
    <>{children}</>
  ) : (
    <Navigate
      to={{
        pathname: '/country-information',
      }}
      state={{ from: location.pathname }}
      replace
    />
  )
}

export default PrivateRoute
