import { ExpandMore, ExpandLess } from '@mui/icons-material'
import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Joyride, {
  CallBackProps,
  ACTIONS,
  EVENTS,
  STATUS,
  Step,
} from 'react-joyride'
import { JoyrideTooltip } from './JoyrideTooltip'
import { useSpeedDialMenuContext } from '../Context/SpeedDialMenuContext'

export interface SpeedDialActions {
  /** Tooltip title and key for the action. Needs to use `t` */
  name: string
  /** Icon to represent the action. */
  icon: React.ReactNode
  /** On click handler for the SpeedDialAction */
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  /** onClick tour handler */
  onTourClick?: () => void
  /**
   * CSS Selector to add as a className to the action for ease of selection during steps
   *
   * This should be the target without the '.' prefix
   */
  selector?: string
  /** Determines whether to hide the action */
  hidden?: boolean
  /** Pause for load */
  pauseTourBriefly?: boolean
}

interface SpeedDialMenuProps {
  /** Which step to render */
  stepIndex: number
  /** Label of the SpeedDial menu. Defaults to 'action-menu' */
  ariaLabel?: string
  /**
   * (optional) actions whose existence will render the SpeedDialMenu.
   */
  actions?: SpeedDialActions[]
  /** steps to take the user on a tour of the SpeedDial features */
  joyrideSteps?: Step[]
  /** An id which if in the localStorage will not run the tour */
  joyrideCompleteId?: string
}

/**
 * Add to any screen where actions should be page-wide and rid yourselves
 * of the pesky buttons-only-at-the-top-of-pages-or-in-cards problem.
 *
 * It is the responsibility of the component using the menu to add actions on mount and remove
 * them on unmount.
 */
const SpeedDialMenu: React.FC<SpeedDialMenuProps> = ({
  ariaLabel = 'action-menu',
  actions,
  joyrideSteps = [],
  joyrideCompleteId = '',
  stepIndex,
}) => {
  /** This state is useful for testing, and persistent opening beyond hovering */
  const [open, setOpen] = useState(false)
  const [runTour, setRunTour] = useState(true)
  const { updateStepIndex } = useSpeedDialMenuContext()

  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const theme = useTheme()

  /**
   * Modular callback method to process steps in the tour and actions.
   *
   * Utilize the selector prop of the SpeedDialActions to match
   * the target of a given step. If they match and an onTourCLick
   * exists for the step, we'll perform the action for the step
   * in the tour so the buttons in the SpeedDialMenu will do what
   * they actually do.
   */
  const joyrideCallback = (data: CallBackProps) => {
    const { action, status, index, type: joyrideType } = data

    const actionMatchingTarget = actions?.filter(
      (it) => `.${it.selector}` === joyrideSteps[index].target
    )[0]

    switch (status) {
      case STATUS.FINISHED:
        /**
         * If non-empty, set the localStorage for this complete id.
         *
         * FIXME: This can be addressed in the Transcripts project
         * where we track tours in a UserSettings table and enable
         * them through the user information retrieved.
         */
        if (!!joyrideCompleteId) {
          localStorage.setItem(joyrideCompleteId, 'true')
        }
        toggleOpen()
        break
      default:
        break
    }
    switch (joyrideType) {
      case EVENTS.STEP_AFTER:
        updateStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
        if (index === 0) {
          toggleOpen()
        } else {
          /** If there is an action whose selector matches the current index
           *  target and it has an onTourClick method, call it.
           */
          actionMatchingTarget?.onTourClick?.()
          if (actionMatchingTarget?.pauseTourBriefly) {
            setRunTour(false)
            /** I hate setTimeout since sometimes it doesn't work on some browsers, but we'll give it a try for now */
            setTimeout(() => {
              setRunTour(true)
            }, 400)
          }
        }
        break
      case EVENTS.TARGET_NOT_FOUND:
        console.log(`target not found`)
        break
      default:
        break
    }
  }

  /**
   * If we don't have actions, make sure the SpeedDial is closed.
   * Next time we show it it might be open
   */
  useEffect(() => {
    if (!actions) {
      setOpen(false)
    }
  }, [actions])

  /** If we don't have actions, don't render the speed dial. */
  if (!actions) {
    return null
  }

  return (
    <>
      {/* A demo of Joyride can be found here: https://codesandbox.io/p/devbox/react-joyride-demo-c0xm7q?file=%2Fsrc%2FBasic%2Findex.tsx */}
      <Joyride
        stepIndex={stepIndex}
        run={runTour && localStorage.getItem(joyrideCompleteId) !== 'true'}
        steps={[...joyrideSteps]}
        tooltipComponent={JoyrideTooltip}
        callback={joyrideSteps.length > 0 ? joyrideCallback : undefined}
      />
      <SpeedDial
        ariaLabel={ariaLabel}
        icon={<SpeedDialIcon openIcon={<ExpandMore />} icon={<ExpandLess />} />}
        // Provides the best position above the help beacon
        sx={{ position: 'fixed', bottom: 128, right: 42 }}
        onClick={toggleOpen}
        open={open}
      >
        {actions.map(({ name, icon, onClick, selector, hidden }) => {
          return (
            <SpeedDialAction
              /** Used for easier target selection */
              className={selector ?? ''}
              sx={{
                color: theme.palette.primary.light,
                backgroundColor: theme.palette.background.default,
                display: hidden ? 'none' : 'auto',
              }}
              key={name}
              icon={icon}
              tooltipTitle={name}
              onClick={(e) => {
                /**
                 * Note: when providing an action and submitting the action, the SpeedDialMenu would close without stopPropagation
                 */
                e.stopPropagation()
                onClick?.(e)
              }}
            />
          )
        })}
      </SpeedDial>
    </>
  )
}

export default SpeedDialMenu
