import React, { useEffect, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import TitleContext from '../../TitleContext'
import { Page } from '../Elements/PageMargins'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BusinessContentCard from '../Card/BusinessContentCard'
import Header from '../Elements/Header'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import ContentOverviewCard from '../Card/ContentOverviewCard'
import { FileType, FileTypeIcon } from '../Elements/FileTypeResource'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import useMediaQuery from '@mui/material/useMediaQuery'
import LearningPathContentMobile from '../Card/LearningPathContentMobile'
import NoPermission from '../Elements/NoPermission'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { styled } from '@mui/system'
import { Roles } from '../../utils/searchAndFilterEnums'
import useFetchLearningPath from '../../hooks/useFetchLearningPath'
import { useMountEffect } from '../../hooks/useMountEffect'

const LearningPathHeader = styled(Header)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  padding: 0,
  color: theme.palette.primary.dark,
}))

export const LearningPath: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const title = t('Business.LearningPath.Title', 'Business')
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)
  const navigate = useNavigate()
  const theme = useTheme()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'))

  const { assetKey } = useParams<{
    assetKey: string
  }>()

  const parsedAssetKey = parseInt(`${assetKey}`)

  const {
    fetchLearningPath,
    learningPath,
    isLoading,
    errorFetchingContent: errorFetchingLearningPath,
    updateLearningPathAssetById,
    favoritedAssets,
  } = useFetchLearningPath({
    assetKey: parsedAssetKey,
    requestType: 'business',
  })

  const isPriorityOneAssetUpdated = useRef(false)

  useMountEffect(() => {
    fetchLearningPath()
  })

  const handleStartLearning = () => {
    navigate(
      {
        pathname: `/business/business-content/${assetKey}`,
      },
      {
        state: {
          assetKeysInLearningPath,
        },
      }
    )
  }

  useEffect(() => {
    if (learningPath) {
      const priorityOneAsset = learningPath.assets.find(
        (asset) => asset.listingPriority === 1
      )

      if (priorityOneAsset) {
        if (
          !!favoritedAssets &&
          favoritedAssets.length === 1 &&
          !priorityOneAsset.isFavorited &&
          !isPriorityOneAssetUpdated.current
        ) {
          updateLearningPathAssetById(priorityOneAsset.assetKey, {
            isFavorited: true,
          })
          isPriorityOneAssetUpdated.current = true
          return
        }

        if (
          !!favoritedAssets &&
          favoritedAssets.length === 0 &&
          priorityOneAsset.isFavorited &&
          isPriorityOneAssetUpdated.current
        ) {
          isPriorityOneAssetUpdated.current = false
          return
        }
      }
    }
  }, [favoritedAssets, learningPath, updateLearningPathAssetById])

  const navigateToBusiness = () => {
    navigate(
      {
        pathname: `/business`,
      },
      {
        /** Navigation Options */
      }
    )
  }

  const assetKeysInLearningPath =
    learningPath?.assets.map(({ assetKey }) => assetKey.toString()) ?? []

  const isFavorited = (): boolean => {
    const isFavorite = learningPath?.assets
      .filter((it) => it.assetKey === parsedAssetKey)
      .map((it) => it.isFavorited)
    return isFavorite?.[0] ?? false
  }

  return (
    <Page>
      {!!learningPath ? (
        <>
          {!errorFetchingLearningPath ? (
            <>
              <DynamicBreadcrumbs
                breadcrumbs={[
                  {
                    label: t('Business.LearningPath.BreadCrumb', 'Business'),
                    onClick: navigateToBusiness,
                  },
                  {
                    label: learningPath?.title,
                  },
                ]}
              />
              <Card>
                <Box p={{ xs: 2, sm: 4 }}>
                  <ContentOverviewCard
                    assetKey={parsedAssetKey}
                    icon={
                      <FileTypeIcon fileType={FileType.LearningPath} large />
                    }
                    iconBackground="light"
                    title={learningPath?.title || 'No Title Available'}
                    description={
                      learningPath?.description ||
                      'No description information available.'
                    }
                    actionArea={
                      <ContainedButton
                        id="startLearning"
                        variant={ContainedButtonVariant.StartLearning}
                        onClick={handleStartLearning}
                      />
                    }
                    isFavorite={isFavorited()}
                    isLearningPath={true}
                  />
                </Box>
              </Card>

              <LearningPathHeader
                id="learningPathHeader"
                headerName={learningPath?.title}
              />
              <Box display="flex" flexWrap="wrap" ml={{ xs: 0, md: -1 }}>
                {learningPath.assets.map((asset) => {
                  const isFavoriteButtonDisabled =
                    asset.listingPriority === 1 &&
                    favoritedAssets &&
                    favoritedAssets.length > 0

                  return isMobileOrSmaller ? (
                    <LearningPathContentMobile
                      key={asset.assetKey}
                      widenAssetKey={asset.assetKey}
                      fileType={asset.filetype as FileType}
                      title={asset.title}
                      routerState={{ assetKeysInLearningPath }}
                    />
                  ) : (
                    <BusinessContentCard
                      key={asset.assetKey}
                      widenAssetKey={asset.assetKey}
                      fileType={asset.filetype as FileType}
                      subtitle={`Role: ${asset.role as Roles}`}
                      title={asset.title}
                      description={asset.description ?? ''}
                      isLearningPath={false}
                      isFavorite={asset.isFavorited ?? false}
                      routerState={{ assetKeysInLearningPath }}
                      viewOnly={asset.viewOnly ?? false}
                      isNdaRequired={asset.isNdaRequired ?? false}
                      ndaAcknowledged={asset.ndaAcknowledged ?? false}
                      isFavoriteButtonDisabled={isFavoriteButtonDisabled}
                      handleFavoriteButtonClicked={() => {
                        updateLearningPathAssetById(asset.assetKey, {
                          isFavorited: !asset.isFavorited,
                        })
                      }}
                    />
                  )
                })}
              </Box>
            </>
          ) : (
            <NoPermission
              isLoading={isLoading}
              content={t(
                'Business.LearningPath.NoPermission',
                `Sorry, you do not have permission to view this content.`
              )}
            />
          )}
        </>
      ) : (
        <NoPermission
          isLoading={isLoading}
          content={t(
            'Business.LearningPath.NoPermission',
            `Sorry, you do not have permission to view this content.`
          )}
        />
      )}
    </Page>
  )
}

export default LearningPath
