import React from 'react'
import ConfirmationModal from './ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface DeclineInvitationModal {
  handleFormSubmit: (event: React.FormEvent<HTMLDivElement>) => void
  isOpen?: boolean
  handleCancel?: () => void
}

const DeclineInvitationModal: React.FC<DeclineInvitationModal> = ({
  isOpen = false,
  handleFormSubmit,
  handleCancel,
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      dialogActions={
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.YesProceed}
          secondaryButtonLabel={TextButtonVariant.NoCancel}
          secondaryClick={handleCancel}
          alwaysStack
        />
      }
      dialogTitle={t(
        'DeclineInvitationModal.Title.Label',
        'Offered Spots were Skipped'
      )}
      dialogContent={
        <Typography variant="body1" component="p" align="center">
          {t(
            'DeclineInvitationModal.Content.Label',
            `You are about to decline one or more spots offered to your family. These offered spots will no longer be available, 
            and the application fee associated with these spots is non-refundable. If you intend to use that spot for your student, do not skip the offered spot.`
          )}
        </Typography>
      }
      isOpen={isOpen}
      handleFormSubmit={handleFormSubmit}
    />
  )
}

export default DeclineInvitationModal
