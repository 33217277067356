import React from 'react'
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import BrandCard from './BrandCard'
import { InviteFlowButtons } from '../Buttons/InviteFlowButtons'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useInviteContext } from '../Context/InviteContext'

const heightOfEnterNameCard = 700
const PHONE_NUMBER_MIN_CHARACTERS = 10

export const SignUpInformationCard: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    userInfo,
    userInfoValidity,
    handleUserInfoChange,
    goToNextStep,
    updateUserInfoValidity,
  } = useInviteContext()

  const { firstName, lastName, phone } = userInfo
  const {
    firstName: firstNameValidity,
    lastName: lastNameValidity,
    phone: phoneValidity,
  } = userInfoValidity

  const handleSignUpNameSubmit = () => {
    const isValidPhoneNumber =
      !!phone && phone.length >= PHONE_NUMBER_MIN_CHARACTERS

    if (!!firstName && !!lastName && !!phone && isValidPhoneNumber) {
      goToNextStep()
    } else {
      updateUserInfoValidity({
        ...userInfoValidity,
        firstName: { input: !!firstName },
        lastName: { input: !!lastName },
        phone: { input: isValidPhoneNumber },
      })
    }
  }

  const firstNameId = 'firstName'
  const lastNameId = 'lastName'
  const phoneNumberId = 'phone'

  return (
    <BrandCard
      minimumScreenHeight={heightOfEnterNameCard}
      additionalHeaderContent={
        <Typography
          variant="h6"
          component="h2"
          sx={{
            color: theme.palette.primary.main,
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'block',
          }}
        >
          {t('SignUpInformation.Title.EnterYourName', 'Enter Your Name')}
        </Typography>
      }
      cardContent={
        <>
          <Box>
            <Typography
              variant="body1"
              component="p"
              color={theme.palette.primary.main}
            >
              {t(
                'SignUpInformation.Title.EnterNameCard',
                'Enter the name as you would like it to appear to others within CC Connected. This can be updated later in your profile.'
              )}
            </Typography>
          </Box>
          <Box display="flex" flexDirection={'column'}>
            <TextField
              id={firstNameId}
              label={t('SignUpInformation.Field.FirstName', 'First Name')}
              variant="filled"
              sx={{
                margin: theme.spacing(1),
              }}
              onChange={(e) =>
                handleUserInfoChange(firstNameId, e.target.value)
              }
              value={firstName}
              error={!firstNameValidity.input}
              helperText={
                !firstNameValidity.input
                  ? t(
                      'SignUpInformation.Error.FirstName',
                      'First Name cannot be empty'
                    )
                  : null
              }
            />
            <TextField
              id="lastName"
              label={t('SignUpInformation.Field.LastName', 'Last Name')}
              variant="filled"
              sx={{
                margin: theme.spacing(1),
              }}
              onChange={(e) => handleUserInfoChange(lastNameId, e.target.value)}
              value={lastName}
              error={!lastNameValidity.input}
              helperText={
                !lastNameValidity.input
                  ? t(
                      'SignUpInformation.Error.LastName',
                      'Last Name cannot be empty'
                    )
                  : null
              }
            />
            <TextField
              id="phone"
              label={t('SignUpInformation.Field.PhoneNumber', 'Phone Number')}
              variant="filled"
              sx={{
                margin: theme.spacing(1),
              }}
              onChange={(e) =>
                handleUserInfoChange(phoneNumberId, e.target.value)
              }
              value={phone}
              error={!phoneValidity.input}
              helperText={
                !phoneValidity.input
                  ? t(
                      'SignUpInformation.Error.Phone',
                      'Phone number required minimum {{amountOfCharacters}} digits',
                      {
                        amountOfCharacters: PHONE_NUMBER_MIN_CHARACTERS,
                        interpolation: { escapeValue: false },
                      }
                    )
                  : null
              }
            />
          </Box>
        </>
      }
      cardActions={
        <InviteFlowButtons
          onClickNext={handleSignUpNameSubmit}
          // Should we wish to include this in the future, for some reason, it should be the reset method.
          // onClickBack={resetContextToDefaults}
        />
      }
    />
  )
}

export default SignUpInformationCard
