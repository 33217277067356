import {
  FormControlLabel,
  Checkbox,
  Typography,
  useTheme,
  SxProps,
  Theme,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface CheckBoxProps {
  /**
   *  Label value for checkbox
   */
  label: string
  /** Optional value to disable the checkbox*/
  disabled?: boolean
  /**
   * Current value of the checkbox
   */
  checkboxValue: boolean
  /**
   * Change action that will trigger our checkox,
   can be a function that receives a boolean parameter or a boolean state modifier.
   */
  OnChange: (e: boolean) => void | React.SetStateAction<boolean>
  /**
   * Name attribute of the input element.
   */
  name: string
  /**
   * Optional value to customize the label styles
   */
  sxLabel?: SxProps<Theme>
  /** Optional value to customize styles, the checkbox and its label will be treated as a single element*/
  sx?: SxProps<Theme>
}

const CheckboxControl: React.FC<CheckBoxProps> = ({
  label,
  disabled,
  checkboxValue,
  OnChange,
  name,
  sxLabel,
  sx,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const labelStyles = !!sxLabel
    ? sxLabel
    : ({
        paddingLeft: 0.5,
        textOverflow: 'ellipsis',
        fontFamily: 'sweet-sans-pro',
        letterSpacing: '0.15px',
        opacity: '0.65',
      } as React.CSSProperties)
  const formControlStyles = !!sx
    ? sx
    : ({
        color: theme.palette.textOrIcon.checkbox,
        marginLeft: 1.4,
        marginRight: 0,
      } as React.CSSProperties)

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={checkboxValue}
          name={name}
          onChange={(e) => OnChange(e.target.checked)}
          sx={{
            ...formControlStyles,
          }}
        />
      }
      label={
        <Typography
          variant="subtitle2"
          component="p"
          sx={{
            ...labelStyles,
          }}
        >
          {t(`CheckboxControl.Checkbox.{{key}}`, '{{value}}', {
            key: name.replace(/ /g, ''),
            value: label,
          })}
        </Typography>
      }
    />
  )
}

export default CheckboxControl
