import { UpdateUserRequestBody, UserAccountDetails } from '../swagger'
import { userAccountsApi } from './swagger'

export const fetchUserAccount = async (
  userKey: number
): Promise<UserAccountDetails> => {
  try {
    const userAccount = await userAccountsApi.fetchUser({ userKey })

    return userAccount
  } catch (e) {
    throw e
  }
}

export const updateUser = async (opts: {
  userId: string
  body: UpdateUserRequestBody
}): Promise<void> => {
  try {
    const { userId, body } = opts
    await userAccountsApi.updateUser({ userId, body })
  } catch (e) {
    throw e
  }
}
