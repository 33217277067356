import React, { ReactElement, useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Header, { HeaderVariant } from '../Elements/Header'
import { useTranslation } from 'react-i18next'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import MenuItem from '@mui/material/MenuItem'
import {
  ActorSelectOption,
  Program,
  ProgramCommunityOption,
  ProgramType,
} from '../../swagger'
import useValidationMessages from '../../hooks/useValidationMessages'
import {
  dateToDashString,
  dateToSlashStringReinterpretedAsLocal,
} from '../../utils/dateUtility'
import { ProgramFormValidFields } from '../Card/ProgramFormCard'
import programTypeMap from '../../utils/programTypeMap'
import DropdownSelectOptions from '../Interfaces/DropdownSelectOptions'
import { TextFieldVariant } from '../Interfaces/FieldProps'
import IconTextButton, {
  IconTextButtonVariant,
} from '../Buttons/IconTextButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { ProgramTutors } from '../../swagger/models/ProgramTutors'
import DateField, { DateFieldVariant } from '../Elements/DateField'
import { useAuth } from '../Routes/AuthProvider'
import { SelectChangeEvent } from '@mui/material/Select'
import { Can } from '@casl/react'
import CardFormHeader from '../Card/CardFormHeader'
import { InputLabel, styled, useTheme } from '@mui/material'
import { useNavigate } from 'react-router'
import { convertToStandardFromMilitaryTime } from '../../utils/convertToStandardTime'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  minHeight: 74,
  paddingBottom: theme.spacing(1),
}))

const DirectorLabel = styled(InputLabel)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  fontSize: theme.spacing(1.2),
}))

const DirectorLinkToUserProfile = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  marginTop: 2,
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(4),
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  overflow: 'hidden',
  fontWeight: theme.spacing(4),
  fontSize: theme.spacing(1.7),
  textTransform: 'none',
  backgroundColor: 'white',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderBottom: `${theme.spacing(0.1)} solid ${
    theme.palette.customBackground.logo
  } `,
  '&:hover': {
    cursor: 'pointer',
  },
}))

export interface ProgramInformationDetails
  extends Pick<
    Program,
    | 'startTime'
    | 'endTime'
    | 'dayOfTheWeek'
    | 'capacity'
    | 'director'
    | 'directorEmail'
    | 'directorActorId'
    | 'tutorName'
    | 'communityName'
    | 'availableSpots'
    | 'directorUserId'
    | 'directorPhone'
  > {
  programType: ProgramType | undefined
  firstSemesterStartDate: string
  secondSemesterStartDate: string
}

export interface ProgramInformationFieldsProps {
  programInformationDetails: ProgramInformationDetails
  programTypes: ProgramType[]
  isFieldDisabled: boolean
  minimumSemesterDate: Date
  maximumSemesterDate: Date
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  labelForDayOfTheWeek: (numericalDay: number) => void
  filteredTutorOptions: ActorSelectOption[]
  filteredDirectors: Array<ActorSelectOption & { disabled?: boolean }>
  validFields: ProgramFormValidFields
  isProgramTypeDisabled: boolean
  daysOfTheWeek: DropdownSelectOptions[]
  communitiesMap: Map<string, ProgramCommunityOption>
  useAddProgramVariant: boolean
  tutors?: ProgramTutors[]
  onTutorChange: (tutorKey: number, event: SelectChangeEvent<unknown>) => void
  onTutorRemoveField: (tutorFormKey: string) => void
  isCancel: boolean
  tutorFormFields: ReactElement[]
  addTutorField: () => void
}

enum ProgramInformationFieldsValidationMessageTypes {
  Default = 'default',
  ValidDate = 'validDate',
  MaxCapacity = 'maxCapacity',
  Director = 'director',
  Tutor = 'tutor',
  CommunityName = 'communityName',
  FirstSemesterDate = 'firstSemesterDate',
  SecondSemesterDate = 'secondSemesterDate',
  ValidTime = 'validTime',
  EndTime = 'endTime',
  NoResultsForDate = 'noResultsForDate',
  AfterDate = 'afterDate',
  BeforeDate = 'beforeDate',
  SameDate = 'sameDate',
  BeforeMax = 'beforeMax',
  AfterMin = 'afterMin',
  HasNoOptions = 'hasNoOptions',
}

export const ProgramInformationFields: React.FC<
  ProgramInformationFieldsProps
> = (props) => {
  const { t } = useTranslation()
  const {
    programInformationDetails,
    programTypes,
    isFieldDisabled,
    minimumSemesterDate,
    maximumSemesterDate,
    handleInputChange,
    labelForDayOfTheWeek,
    filteredDirectors,
    validFields,
    isProgramTypeDisabled,
    daysOfTheWeek,
    communitiesMap,
    useAddProgramVariant,
    tutorFormFields,
    addTutorField,
  } = props

  const { permissionAbility } = useAuth()
  const theme = useTheme()

  // used to disable option to edit program start dates
  const canEditProgramDates = permissionAbility.can(
    'editProgramDates',
    'Program'
  )
  const canViewUser = permissionAbility.can('view', 'User')
  const navigate = useNavigate()

  /** Hooks */
  const validationMessageMap = useValidationMessages([
    {
      field: ProgramInformationFieldsValidationMessageTypes.Default,
      message: t(
        'Programs.ProgramForm.ErrorMessage',
        'Something went wrong. Please make sure you have filled out the required fields.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.MaxCapacity,
      message: t(
        'Programs.ProgramForm.ValidationMessage.MaxCapacity',
        'Max Capacity must be between 1 and 999.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.Director,
      message: t(
        'Programs.ProgramForm.ValidationMessage.Director',
        'Please select a Director.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.Tutor,
      message: t(
        'Programs.ProgramForm.ValidationMessage.Tutor',
        'Please select a Tutor.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.CommunityName,
      message: t(
        'Programs.ProgramForm.ValidationMessage.CommunityName',
        'Please select a Community.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.ValidDate,
      message: t(
        'Programs.ProgramForm.ValidationMessage.ValidDate',
        'Please enter a valid date in the form of mm-dd-yyyy.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.ValidTime,
      message: t(
        'Programs.ProgramForm.ValidationMessage.ValidTime',
        'Please enter a valid time.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.EndTime,
      message: t(
        'Programs.ProgramForm.ValidationMessage.AfterTime',
        'End Time must be after Start Time.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.NoResultsForDate,
      message: t(
        'Programs.ProgramForm.ValidationMessage.NoResultsForDate',
        'No Results for specified 1st Semester Start Date.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.SameDate,
      message: t(
        'Programs.ProgramForm.ValidationMessage.SameDate',
        'Semester dates cannot be the same.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.AfterDate,
      message: t(
        'Programs.ProgramForm.ValidationMessage.AfterDate',
        '2nd Semester Start Date must be after 1st Semester Start Date.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.BeforeDate,
      message: t(
        'Programs.ProgramForm.ValidationMessage.BeforeDate',
        '1st Semester Start Date must be before 2nd Semester Start Date.'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.BeforeMax,
      message: t(
        'Programs.ProgramForm.ValidationMessage.BeforeMax',
        'Please enter a valid date on or before'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.AfterMin,
      message: t(
        'Programs.ProgramForm.ValidationMessage.AfterMin',
        'Please enter a valid date on or after'
      ),
    },
    {
      field: ProgramInformationFieldsValidationMessageTypes.HasNoOptions,
      message: t(
        'Programs.ProgramForm.ValidationMessage.HasNoOptions',
        'has no available options.'
      ),
    },
  ])

  /** State Objects */
  const filteredDirectorsIsEmpty = useMemo(() => {
    return !!filteredDirectors && filteredDirectors.length === 0
  }, [filteredDirectors])

  const directors = useMemo(() => {
    return filteredDirectors?.map((director) => ({
      label: `${director.name} - ${director.actorKey}`,
      id: director.actorKey,
      disabled: director.disabled,
    }))
  }, [filteredDirectors])

  /** Other Objects */
  const uniqueCommunitiesDropDownOptions: Array<{
    name: string
    id: number
  }> = []
  communitiesMap.forEach((value, key) => {
    uniqueCommunitiesDropDownOptions.push({
      name: key,
      id: value.communityKey,
    })
  })

  const sharedFieldProps = {
    variant: TextFieldVariant.Filled,
    onChange: handleInputChange,
  }

  /** Boolean determinations */

  const isDirectorFieldDisabled =
    isFieldDisabled ||
    !programInformationDetails.communityName ||
    !programInformationDetails.programType

  /** Helper Text */
  const firstSemesterStartDateHelperText = useMemo(() => {
    if (!validFields.semesterOneStartDate.input) {
      return validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.ValidDate
      )
    } else if (!validFields.semesterOneStartDate.afterMin) {
      return `${validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.AfterMin
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(minimumSemesterDate)
      )}.`
    } else if (!validFields.semesterOneStartDate.beforeMax) {
      return `${validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.BeforeMax
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(maximumSemesterDate)
      )}.`
    } else if (validFields.semesterOneStartDate.sameDate) {
      return validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.SameDate
      )
    } else {
      return null
    }
  }, [
    validFields.semesterOneStartDate,
    validationMessageMap,
    maximumSemesterDate,
    minimumSemesterDate,
  ])

  const secondSemesterStartDateHelperText = useMemo(() => {
    if (!validFields.semesterTwoStartDate.input) {
      return validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.ValidDate
      )
    } else if (!validFields.semesterTwoStartDate.afterMin) {
      return `${validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.AfterMin
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(minimumSemesterDate)
      )}.`
    } else if (!validFields.semesterTwoStartDate.beforeMax) {
      return `${validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.BeforeMax
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(maximumSemesterDate)
      )}.`
    } else if (!validFields.semesterTwoStartDate.afterPrevious) {
      return validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.AfterDate
      )
    } else if (validFields.semesterTwoStartDate.sameDate) {
      return validationMessageMap.get(
        ProgramInformationFieldsValidationMessageTypes.SameDate
      )
    } else {
      return null
    }
  }, [
    validFields.semesterTwoStartDate,
    validationMessageMap,
    maximumSemesterDate,
    minimumSemesterDate,
  ])

  const getDirectorLabel = () => {
    return programInformationDetails.director
      ? `${programInformationDetails.director} - ${programInformationDetails.directorActorId}`
      : ''
  }

  const programStartTime = isFieldDisabled
    ? convertToStandardFromMilitaryTime({
        time: programInformationDetails.startTime as string,
      })
    : programInformationDetails.startTime ?? ''

  const programEndTime = isFieldDisabled
    ? convertToStandardFromMilitaryTime({
        time: programInformationDetails.endTime as string,
      })
    : programInformationDetails.endTime ?? ''

  return (
    <section aria-labelledby="programInformationHeader">
      <CardFormHeader
        header={
          <Header
            id="programInformationHeader"
            headerName={t(
              'Programs.ProgramFormCard.ProgramInformation.Header',
              'Program Information'
            )}
            component="h2"
            variant={HeaderVariant.Card}
          />
        }
        headerContainerProps={{ margin: 0 }}
      />
      <form
        noValidate
        autoComplete="off"
        aria-labelledby="programInformationHeader"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" pb={5}>
              <StyledTextField
                {...sharedFieldProps}
                id="communityName"
                name="communityName"
                label={t(
                  'Programs.ProgramFormCard.FormField.Community',
                  'Community'
                )}
                select={!isFieldDisabled && communitiesMap.size > 0}
                value={programInformationDetails.communityName ?? ''}
                error={!validFields.communityName.input}
                disabled={isFieldDisabled || communitiesMap.size === 0}
                helperText={
                  communitiesMap.size > 0 && !validFields.communityName.input
                    ? validationMessageMap.get(
                        ProgramInformationFieldsValidationMessageTypes.CommunityName
                      )
                    : communitiesMap.size === 0 &&
                      !programInformationDetails.communityName &&
                      `Community ${validationMessageMap.get(
                        ProgramInformationFieldsValidationMessageTypes.HasNoOptions
                      )}`
                }
              >
                {uniqueCommunitiesDropDownOptions?.map((community) => (
                  <MenuItem key={community.id} value={community.name}>
                    {community.name}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                {...sharedFieldProps}
                id="programType"
                name="type"
                label={t(
                  'Programs.ProgramFormCard.FormField.ProgramType',
                  'Program Type'
                )}
                select={!isProgramTypeDisabled && !!programTypes}
                value={programInformationDetails.programType ?? programTypes[0]}
                error={!validFields.type.input}
                disabled={isProgramTypeDisabled || !useAddProgramVariant}
                fullWidth
              >
                {programTypes?.map((programType) => (
                  <MenuItem
                    key={programType}
                    value={programTypeMap.get(programType)}
                  >
                    {programTypeMap.get(programType)}
                  </MenuItem>
                )) ?? []}
              </StyledTextField>
              <Can
                I="editProgramDates"
                on="Program"
                ability={permissionAbility}
              >
                <DateField
                  dateVariant={DateFieldVariant.FirstSemesterStartDate}
                  data-testid="firstSemesterStartDate"
                  sx={{
                    minHeight: 74,
                    paddingBottom: theme.spacing(1),
                  }}
                  variant={TextFieldVariant.Filled}
                  onChangeForParent={handleInputChange}
                  id="firstSemesterStartDate"
                  name="firstSemesterStartDate"
                  type={isFieldDisabled ? 'text' : 'date'}
                  InputLabelProps={!isFieldDisabled ? { shrink: true } : {}}
                  inputProps={{
                    'aria-label': '1st Semester Start Date',
                  }}
                  value={
                    !!programInformationDetails.communityName
                      ? isFieldDisabled
                        ? dateToSlashStringReinterpretedAsLocal(
                            new Date(
                              programInformationDetails.firstSemesterStartDate
                            )
                          )
                        : programInformationDetails.firstSemesterStartDate
                      : ''
                  }
                  error={
                    !validFields.semesterOneStartDate.input ||
                    !validFields.semesterOneStartDate.afterMin ||
                    !validFields.semesterOneStartDate.beforeMax ||
                    validFields.semesterOneStartDate.sameDate
                  }
                  disabled={isFieldDisabled || !canEditProgramDates}
                  helperTextForParent={firstSemesterStartDateHelperText}
                />
                <DateField
                  dateVariant={DateFieldVariant.SecondSemesterStartDate}
                  sx={{
                    minHeight: 74,
                    paddingBottom: theme.spacing(1),
                  }}
                  id="secondSemesterStartDate"
                  data-testid="secondSemesterStartDate"
                  variant={TextFieldVariant.Filled}
                  onChangeForParent={handleInputChange}
                  name="secondSemesterStartDate"
                  type={isFieldDisabled ? 'text' : 'date'}
                  InputLabelProps={!isFieldDisabled ? { shrink: true } : {}}
                  value={
                    !!programInformationDetails.communityName
                      ? isFieldDisabled
                        ? dateToSlashStringReinterpretedAsLocal(
                            new Date(
                              programInformationDetails.secondSemesterStartDate
                            )
                          )
                        : programInformationDetails.secondSemesterStartDate
                      : ''
                  }
                  error={
                    !validFields.semesterTwoStartDate.input ||
                    !validFields.semesterTwoStartDate.afterMin ||
                    !validFields.semesterTwoStartDate.beforeMax ||
                    !validFields.semesterTwoStartDate.afterPrevious ||
                    validFields.semesterTwoStartDate.sameDate
                  }
                  disabled={isFieldDisabled || !canEditProgramDates}
                  helperTextForParent={secondSemesterStartDateHelperText}
                />
              </Can>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    {...sharedFieldProps}
                    id="startTime"
                    name="startTime"
                    label={t(
                      'Programs.ProgramFormCard.FormField.StartTime',
                      'Start Time'
                    )}
                    type={isFieldDisabled ? 'text' : 'time'}
                    value={programStartTime}
                    error={!validFields.startTime.input}
                    disabled={isFieldDisabled}
                    InputLabelProps={!isFieldDisabled ? { shrink: true } : {}}
                    helperText={
                      !validFields.startTime.input
                        ? validationMessageMap.get(
                            ProgramInformationFieldsValidationMessageTypes.ValidTime
                          )
                        : null
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    {...sharedFieldProps}
                    id="endTime"
                    name="endTime"
                    label={t(
                      'Programs.ProgramFormCard.FormField.EndTime',
                      'End Time'
                    )}
                    type={isFieldDisabled ? 'text' : 'time'}
                    value={programEndTime}
                    error={
                      !validFields.endTime.input ||
                      !validFields.endTime.afterPrevious
                    }
                    disabled={isFieldDisabled}
                    InputLabelProps={!isFieldDisabled ? { shrink: true } : {}}
                    helperText={
                      !validFields.endTime.input
                        ? validationMessageMap.get(
                            ProgramInformationFieldsValidationMessageTypes.ValidTime
                          )
                        : !validFields.endTime.afterPrevious
                        ? validationMessageMap.get(
                            ProgramInformationFieldsValidationMessageTypes.EndTime
                          )
                        : null
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
              <StyledTextField
                {...sharedFieldProps}
                id="dayOfTheWeek"
                name="dayOfTheWeek"
                label={t(
                  'Programs.ProgramFormCard.FormField.DayOfTheWeek',
                  'Day of the Week'
                )}
                select={!isFieldDisabled && !!daysOfTheWeek}
                value={labelForDayOfTheWeek(
                  programInformationDetails.dayOfTheWeek ?? 0
                )}
                error={!validFields.dayOfTheWeek.input}
                disabled={isFieldDisabled}
              >
                {daysOfTheWeek.map((day) => (
                  <MenuItem key={day.id} value={day.label}>
                    {day.label}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gridGap: 8,
                width: '100%',
              }}
            >
              <Grid spacing={2} container>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    {...sharedFieldProps}
                    id="maximumCapacity"
                    name="capacity"
                    label={t(
                      'Programs.ProgramFormCard.FormField.MaximumCapacity',
                      'Maximum Capacity'
                    )}
                    value={programInformationDetails.capacity ?? 0}
                    onChange={handleInputChange}
                    error={!validFields.capacity.input}
                    disabled={isFieldDisabled}
                    helperText={
                      !validFields.capacity.input
                        ? validationMessageMap.get(
                            ProgramInformationFieldsValidationMessageTypes.MaxCapacity
                          )
                        : null
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    {...sharedFieldProps}
                    id="availableSpots"
                    name="availableSpots"
                    label={t(
                      'Programs.ProgramFormCard.FormField.AvailableSpots',
                      'Available Spots'
                    )}
                    value={programInformationDetails.availableSpots ?? 0}
                    disabled={true}
                    fullWidth
                  />
                </Grid>
              </Grid>

              {canViewUser && isDirectorFieldDisabled ? (
                <Box>
                  <DirectorLabel>
                    {t('ProgramInformationFields.Label.Director', 'Director')}
                  </DirectorLabel>
                  <DirectorLinkToUserProfile
                    style={{ paddingLeft: theme.spacing(1.8) }}
                    role="button"
                    onClick={() => {
                      navigate({
                        pathname: `/admin/user-accounts/${programInformationDetails.directorUserId}`,
                      })
                    }}
                  >
                    {getDirectorLabel()}
                  </DirectorLinkToUserProfile>
                </Box>
              ) : (
                <StyledTextField
                  {...sharedFieldProps}
                  id="director"
                  name="director"
                  label={t(
                    'Programs.ProgramFormCard.FormField.Director',
                    'Director'
                  )}
                  select={!isDirectorFieldDisabled && !!filteredDirectors}
                  value={getDirectorLabel()}
                  onChange={handleInputChange}
                  error={!validFields.director.input}
                  disabled={isDirectorFieldDisabled}
                  helperText={
                    !validFields.director.input
                      ? validationMessageMap.get(
                          ProgramInformationFieldsValidationMessageTypes.Director
                        )
                      : null
                  }
                >
                  {!filteredDirectorsIsEmpty ? (
                    directors?.map((director) => (
                      <MenuItem
                        key={director.id}
                        value={director.label}
                        disabled={director.disabled}
                      >
                        {director.label}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>
                      {validationMessageMap.get(
                        ProgramInformationFieldsValidationMessageTypes.NoResultsForDate
                      )}
                    </MenuItem>
                  )}
                </StyledTextField>
              )}

              {!useAddProgramVariant ? (
                <TextField
                  {...sharedFieldProps}
                  id="directorEmail"
                  sx={{
                    marginTop: theme.spacing(1.5),
                  }}
                  name="directorEmail"
                  label={t(
                    'Programs.ProgramFormCard.FormField.DirectorEmail',
                    'Director Email'
                  )}
                  value={programInformationDetails.directorEmail ?? ''}
                  disabled
                  type="url"
                />
              ) : null}
              <TextField
                {...sharedFieldProps}
                sx={{
                  marginTop: theme.spacing(1.6),
                }}
                id="directorPhone"
                name="directorPhone"
                label={t(
                  'Programs.ProgramFormCard.FormField.DirectorPhone',
                  'Director Phone Number'
                )}
                value={programInformationDetails.directorPhone ?? ''}
                error={!validFields.directorPhone.input}
                onChange={handleInputChange}
                disabled={isFieldDisabled}
              />
              {tutorFormFields.map((it) => it)}
              {!isFieldDisabled && tutorFormFields.length < 100 && (
                <Box alignItems="left">
                  <IconTextButton
                    id="addTutorButton"
                    variant={IconTextButtonVariant.Tutor}
                    startIcon={
                      <AddCircleIcon
                        sx={{
                          height: '32px',
                          width: '32px',
                        }}
                      />
                    }
                    onClick={addTutorField}
                  />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </section>
  )
}

export default ProgramInformationFields
