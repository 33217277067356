import { MenuOption } from '../components/Menus/DropDown'
import { EnrollmentStatus } from '../swagger'

// Enrollment statuses that director can handle
export const directorOnlyEnrollmentStatuses = [
  EnrollmentStatus.Enrolled,
  EnrollmentStatus.MemberFull.replace('_', ' '),
  EnrollmentStatus.MemberHalf.replace('_', ' '),
  EnrollmentStatus.Drop,
  EnrollmentStatus.Pending,
]

export const filterAllowedEnrollmentDropDownOptions = (
  enrollmentOptions: string[],
  canUserUpdateAnyEnrollmentStatus: boolean
): MenuOption[] => {
  const options =
    enrollmentOptions.map((it) => {
      const spacedEnrollmentStatus = it.replace('_', ' ')
      const option: MenuOption = {
        id: spacedEnrollmentStatus,
        name: spacedEnrollmentStatus,
        disabled:
          !canUserUpdateAnyEnrollmentStatus &&
          ![
            directorOnlyEnrollmentStatuses[1],
            directorOnlyEnrollmentStatuses[2],
            directorOnlyEnrollmentStatuses[3],
          ].some((status) => status === spacedEnrollmentStatus),
      }
      return option
    }) ?? ''

  return options
}
