/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ErrorDetails, ErrorDetailsFromJSON, ErrorDetailsToJSON } from './'

/**
 * An error of some sort. Cf. ["Error condition responses"](https://github.com/microsoft/api-guidelines/blob/e0ba179c0a066f96657a5d24e952c28f87ac0ca8/Guidelines.md#7102-error-condition-responses).
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   * One of a server-defined set of error codes.
   * @type {string}
   * @memberof ModelError
   */
  code: string
  /**
   * A human-readable representation of the error.
   * @type {string}
   * @memberof ModelError
   */
  message: string
  /**
   * The target of the error, e.g., the name of the property in error.
   * @type {string}
   * @memberof ModelError
   */
  target?: string
  /**
   * These errors usually represent distinct, related errors that occurred during the request.
   * @type {Array<ErrorDetails>}
   * @memberof ModelError
   */
  details?: Array<ErrorDetails>
}

export function ModelErrorFromJSON(json: any): ModelError {
  return ModelErrorFromJSONTyped(json, false)
}

export function ModelErrorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ModelError {
  if (json === undefined || json === null) {
    return json
  }
  return {
    code: json['code'],
    message: json['message'],
    target: !exists(json, 'target') ? undefined : json['target'],
    details: !exists(json, 'details')
      ? undefined
      : (json['details'] as Array<any>).map(ErrorDetailsFromJSON),
  }
}

export function ModelErrorToJSON(value?: ModelError | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    code: value.code,
    message: value.message,
    target: value.target,
    details:
      value.details === undefined
        ? undefined
        : (value.details as Array<any>).map(ErrorDetailsToJSON),
  }
}
