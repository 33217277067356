import React from 'react'
import {
  transcriptPdfStyles,
  informationOffset,
  informationRowTextWidth,
} from './TranscriptPDFStyles'
import { Address } from '../../../swagger/models'
import { dateToSlashStringReinterpretedAsLocal } from '../../../utils/dateUtility'

interface SchoolInformationPDFProps {
  schoolName?: string
  schoolAddress?: Address
  dateGraduation?: Date
}

const SchoolInformationPDF: React.FC<SchoolInformationPDFProps> = ({
  schoolName,
  schoolAddress,
  dateGraduation,
}) => {
  return (
    <div style={transcriptPdfStyles.box}>
      <div>School Information</div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>School Name:</div>
        <div
          style={{
            paddingLeft: `${17 + informationOffset}`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {schoolName ?? ''}
        </div>
      </div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>Address:</div>
        <div
          style={{
            paddingLeft: `${41 + informationOffset}`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {schoolAddress?.streetAddress1 ?? ''}
        </div>
      </div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>City, State, Zip:</div>
        <div
          style={{
            paddingLeft: `${13 + informationOffset}`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {schoolAddress?.city ?? ''}
          {', '}
          {schoolAddress?.state ?? ''}
          {', '}
          {schoolAddress?.zip ?? ''}
        </div>
      </div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>Graduation Date:</div>
        <div
          style={{
            paddingLeft: `${5 + informationOffset}`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {dateGraduation &&
            dateToSlashStringReinterpretedAsLocal(dateGraduation)}
        </div>
      </div>
    </div>
  )
}

export default SchoolInformationPDF
