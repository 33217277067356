import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { TableHeaderForPDF as ITableHeaderForPDF } from '../Interfaces/TableHeadersForPDF'

export interface TableHeaderForPDFProps {
  tableHeaders: ITableHeaderForPDF[]
}

export const TableHeaderForPDF: React.FC<TableHeaderForPDFProps> = ({
  tableHeaders,
}) => {
  return (
    <TableRow>
      {tableHeaders.map(({ label, align, colSpan = 1, cssProps }) => (
        <TableCell
          key={label}
          colSpan={colSpan}
          style={{
            ...cssProps,
            textAlign: align,
          }}
        >
          {label}
        </TableCell>
      ))}
    </TableRow>
  )
}
