import { useEffect, useState } from 'react'
import {
  contentApi,
  ContentAsset,
  extractedErrorObject,
  LearningPath,
} from '../api/swagger'
import useLoadingContext, {
  UseLoadingContextOptions,
} from './useLoadingContext'
import { useLoadingIds } from './useLoadingIds'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'
import { useTranslation } from 'react-i18next'

type UseFetchLearningPathProps = {
  /** LearningPath asset Key */
  assetKey: number
  /** indicator of the type of fetch request to be used in the hook */
  requestType: 'business' | 'learning'
}

type UseFetchLearningPath = {
  /**
   * Indicates whether the fetch operation is in progress.
   **/
  isLoading: boolean

  learningPath: LearningPath | undefined
  /**
   * Indicates whether an error occurred while fetching the content
   **/
  errorFetchingContent: boolean
  /**
   * Initiates the fetch process and adds the loading ID to the context.
   **/
  fetchLearningPath: () => void

  /**
   * Updates the state of a specific asset within the learning path by its ID.
   * This function is used to modify the details of an asset, such as changing its favorited status.
   **/
  updateLearningPathAssetById: (
    assetKey: number,
    newState: Partial<ContentAsset>
  ) => void

  /**
   * Contains a list of assets that have been marked as favorited by the user.
   * This list is fetched or updated based on user interaction with the content.
   **/
  favoritedAssets: ContentAsset[]
}

const useFetchLearningPath = ({
  assetKey,
  requestType: type,
}: UseFetchLearningPathProps): UseFetchLearningPath => {
  const { t } = useTranslation()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const {
    ContentApi: { fetchBusinessLearningPath, fetchLearningCenterLearningPath },
  } = useLoadingIds()

  const [learningPath, setLearningPath] = useState<LearningPath>()
  const [favoritedAssets, setFavoritedAssets] = useState<ContentAsset[]>()
  const [errorFetchingContent, setErrorFetchingContent] = useState(false)

  useEffect(() => {
    setFavoritedAssets(() => {
      const updatedValues = learningPath?.assets.filter(
        (asset) =>
          !!asset.isFavorited &&
          asset.listingPriority &&
          asset.listingPriority > 1
      )

      return updatedValues
    })
  }, [learningPath?.assets])

  const fetchLearningPathFn = async (
    fetchFunction: (params: { assetKey: number }) => Promise<LearningPath>,
    assetKey: number
  ) => {
    try {
      const fetchedLearningPath = await fetchFunction({ assetKey })

      setLearningPath(fetchedLearningPath)
      setErrorFetchingContent(false)
    } catch (error) {
      setErrorFetchingContent(true)

      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'UnknownError',
        message:
          (error as unknown as Error).message ??
          t(
            'UseFetchLearningPath.FetchLearningPathFn.Error',
            'Failed to fetch the Learning Path'
          ),
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    }
  }

  const updateLearningPathAssetById = (
    assetKey: number,
    newState: Partial<ContentAsset>
  ) => {
    if (learningPath) {
      const updatedAssets = learningPath.assets.map((asset) =>
        asset.assetKey === assetKey ? { ...asset, ...newState } : asset
      )

      setLearningPath({
        ...learningPath,
        assets: updatedAssets,
      })
    }
  }

  const loadingId =
    type == 'business'
      ? fetchBusinessLearningPath
      : fetchLearningCenterLearningPath

  const business: UseLoadingContextOptions = {
    asyncFunction: () =>
      fetchLearningPathFn(
        (param) => contentApi.fetchBusinessLearningPath(param),
        assetKey
      ),
    loadingId,
  }

  const learning: UseLoadingContextOptions = {
    asyncFunction: () =>
      fetchLearningPathFn(
        (param) => contentApi.fetchLearningCenterLearningPath(param),
        assetKey
      ),
    loadingId,
  }

  const { isLoading, triggerFetch } = useLoadingContext(
    type === 'business' ? business : learning
  )

  return {
    isLoading,
    learningPath,
    errorFetchingContent,

    fetchLearningPath: triggerFetch,
    updateLearningPathAssetById,
    favoritedAssets: favoritedAssets ?? [],
  }
}

export default useFetchLearningPath
