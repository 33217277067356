import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { isRouteErrorResponse, useRouteError } from 'react-router'
import CC_Seal from '../../Images/Branding/cc-seal.png'
import { Typography } from '@mui/material'

const ErrorElementTextContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  minHeight: '100vh',
}))

const ErrorElement = (): React.JSX.Element => {
  const { t } = useTranslation()
  const error = useRouteError()

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <ErrorElementTextContainer>
          <img
            src={CC_Seal}
            alt={t(
              'EmptyPage.EmptyState.AltText',
              'Classical Conversations Seal'
            )}
          />
          <Typography component="p">
            {t('ErrorElement.404.message', "This page doesn't exist!")}
          </Typography>
        </ErrorElementTextContainer>
      )
    }
    return (
      <ErrorElementTextContainer>
        <img
          src={CC_Seal}
          alt={t(
            'EmptyPage.EmptyState.AltText',
            'Classical Conversations Seal'
          )}
        />
        <Typography component="h1">Oops!</Typography>
        <Typography component="h2">{error.status}</Typography>
        <Typography component="p">{error.statusText}</Typography>
        {error.data?.message && (
          <Typography component="p">{error.data.message}</Typography>
        )}
      </ErrorElementTextContainer>
    )
  }
  return (
    <ErrorElementTextContainer>
      <img
        src={CC_Seal}
        alt={t('EmptyPage.EmptyState.AltText', 'Classical Conversations Seal')}
      />
      <Typography component="h1">
        {t('ErrorElement.message', 'Something went wrong')}
      </Typography>
    </ErrorElementTextContainer>
  )
}

export default ErrorElement
