import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserCommunity } from '../../../swagger'
import Header, { HeaderVariant } from '../../Elements/Header'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ContactInfoListItem from './ContactInfoListItem'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'
import { Link, Typography } from '@mui/material'

// const RolesInfoLink = styled('a')(({ theme }) => ({
const RolesInfoLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const RolesInfo: React.FunctionComponent<{
  userRolesForCommunities: UserCommunity[]
}> = ({ userRolesForCommunities }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const communityDetailLink = (
    communityKey: number,
    communityName: string,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    e.preventDefault()
    navigate(
      {
        pathname: `/communities/community-details/${communityKey}/overview`,
      },
      {
        state: { communityKey, communityName },
      }
    )
  }

  const generateLink = (communityKey: number, community: string) => {
    return (
      <RolesInfoLink
        key={communityKey}
        role="link"
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
          communityDetailLink(communityKey, community, e)
        }
      >
        {community}
      </RolesInfoLink>
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={4}
      maxWidth={500}
      flexGrow={1}
    >
      <Header
        id="rolesAndCommunity"
        headerName={t(
          'RolesInfo.ContactInfo.RolesAndCommunity.Header',
          'Roles & Communities'
        )}
        component="h4"
        variant={HeaderVariant.Card}
      />
      {userRolesForCommunities.length === 0 ? (
        t('RolesInfo.ContactInfo.RolesAndCommunity.NoRoles', 'None')
      ) : (
        <List>
          {userRolesForCommunities.map(
            ({ role, community, actorKey, communityKey, region }) => {
              return (
                <ContactInfoListItem
                  // If there is no role present then we default to "Parent"
                  listItemText={
                    role
                      ? !!community
                        ? `${role} - `
                        : role
                      : t('RolesInfo.ContactInfo.Role.Parent', 'Parent - ')
                  }
                  key={`${role}-${community}-${actorKey}`}
                  children={[
                    generateLink(communityKey ? communityKey : 0, community),
                    !!region ? ' - ' : '',
                    <Typography
                      key={`${role}-region-${communityKey}`}
                      sx={{
                        fontWeight: 'bold',
                      }}
                      component="span"
                    >
                      {region}
                    </Typography>,
                  ]}
                />
              )
            }
          )}
        </List>
      )}
    </Box>
  )
}

export default RolesInfo
