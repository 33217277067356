import {
  FetchBillingRequest,
  FetchBillingResponse,
  PayByAchRequest,
  PaymentResponse,
  StartPaymentRequest,
  UpdatePaymentAddressRequest,
} from '../swagger'
import { paymentsApi } from './swagger'

export const fetchBilling = async (
  requestParameters: FetchBillingRequest
): Promise<FetchBillingResponse> => {
  try {
    return await paymentsApi.fetchBilling(requestParameters)
  } catch (e) {
    throw e
  }
}

export const startPayment = async (
  requestParameters: StartPaymentRequest
): Promise<PaymentResponse> => {
  try {
    return await paymentsApi.startPayment(requestParameters)
  } catch (e) {
    throw e
  }
}

export const updatePaymentAddress = async (
  requestParameters: UpdatePaymentAddressRequest
): Promise<PaymentResponse> => {
  try {
    return await paymentsApi.updatePaymentAddress(requestParameters)
  } catch (e) {
    throw e
  }
}

export const payByAch = async (
  requestParameters: PayByAchRequest
): Promise<PaymentResponse> => {
  try {
    return await paymentsApi.payByAch(requestParameters)
  } catch (e) {
    throw e
  }
}
