import {
  EnrollmentInvite,
  EnrollmentInviteCreate,
  Families,
  Students,
} from '../swagger'
import { families } from './swagger'

// FIXME: We should probably push the error to the UI and, I dunno, run an alert or toast or something with the message it sent down?
// For now, just pretend we got nothing.

export const fetchFamilies = async (args: {
  programKey?: number
  year?: number
  communityKey?: number
}): Promise<Families> => {
  const { programKey, year, communityKey } = args
  try {
    const result = await families.fetchAllFamilies({
      ...(programKey && { programKey }),
      ...(year && { year }),
      ...(communityKey && { communityKey }),
    })
    return result
  } catch {
    return { families: [] }
  }
}

export const fetchStudentsForFamily = async (
  userId: string
): Promise<Students> => {
  try {
    const result = await families.fetchStudentsForFamily({ userId })
    return result
  } catch {
    return { students: [] }
  }
}

export const inviteFamily = async (
  body: EnrollmentInviteCreate
): Promise<EnrollmentInvite> => {
  try {
    return await families.inviteFamily({ body })
  } catch (e) {
    throw e
  }
}
