import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import React, { PropsWithChildren, ReactElement, CSSProperties } from 'react'
import Error from '@mui/icons-material/Error'
import { useTheme } from '@mui/material'

interface ErrorAlertBoxProps extends PropsWithChildren {
  css?: CSSProperties
}

const ErrorAlertBox: React.FC<ErrorAlertBoxProps> = ({ css, children }) => {
  const theme = useTheme()
  return (
    <Box
      role="alert"
      aria-atomic="true"
      sx={{
        ...css,
        color: theme.palette.error.main,
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        background: theme.palette.customBackground.error,
        cursor: 'default',
        minHeight: theme.spacing(6),
        paddingRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
      }}
    >
      {children}
    </Box>
  )
}

interface ErrorAlertProps {
  error: string
  button?: ReactElement
  /** Optional CSSProperties to change the appearance of the Alert */
  css?: CSSProperties
}

export const ErrorIcon: React.FC = () => {
  const theme = useTheme()
  return (
    <Error
      style={{
        fill: theme.palette.error.main,
      }}
    />
  )
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ error, button, css }) => {
  return (
    <ErrorAlertBox css={css}>
      <InputAdornment
        style={{
          marginInlineStart: '16px',
        }}
        position="start"
      >
        {<ErrorIcon />}
      </InputAdornment>
      <Box m={1} pr={7} justifyContent="flex-start">
        <Typography variant="subtitle1" component="h3">
          {error}
        </Typography>
      </Box>
      {button}
    </ErrorAlertBox>
  )
}

export default ErrorAlert
