import { Box, MenuItem, SelectChangeEvent, TextField } from '@mui/material'
import React, { ReactElement, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import DropdownSelectOptions from '../Interfaces/DropdownSelectOptions'

export interface TutorFormFieldProps {
  tutors: DropdownSelectOptions[]
  isDisabled: boolean
  tutorFormKey: string
  tutorFormId: string
  removeTutorField: (fieldId: string, tutorFieldKey: string) => void
  tutorName: string
  onTutorChange: (tutorKey: number, event: SelectChangeEvent<unknown>) => void
  error?: boolean
}

const TutorFormField: React.FC<TutorFormFieldProps> = (props) => {
  const { t } = useTranslation()
  const { error = false } = props

  const [tutorName, setTutorName] = useState(props.tutorName)

  const onTutorChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    const updatedValue = event.target.value as string
    setTutorName(updatedValue)

    const actorKey: number = +((child as ReactElement).key
      ?.toString()
      .slice(2) as string)

    props.onTutorChange(actorKey, event)
  }

  const validationMessage = t(
    'Programs.ProgramForm.ValidationMessage.Tutor',
    'Please select a tutor.'
  )

  return (
    <>
      <Box display="flex">
        <Box width={props.isDisabled ? '100%' : '75%'}>
          <TextField
            variant="filled"
            select={!props.isDisabled && !!props.tutors}
            disabled={props.isDisabled}
            label={t('Programs.ProgramFormCard.FormField.NewTutor', 'Tutor')}
            key={props.tutorFormKey}
            id={props.tutorFormId}
            SelectProps={{ onChange: onTutorChange }}
            fullWidth
            value={tutorName}
            name={props.tutorFormKey}
            helperText={error ? validationMessage : null}
            error={error}
          >
            {props.tutors.map((tutor) => (
              <MenuItem
                key={tutor.id}
                value={tutor.label}
                disabled={tutor.disabled}
                style={{
                  textDecoration: tutor.disabled ? 'line-through' : 'none'
                }}
              >
                {tutor.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        {!props.isDisabled && (
          <Box width="25%" alignItems="center" justifyContent="flex-end">
            <TextButton
              id={`removeButton-${props.tutorFormKey}`}
              variant={TextButtonVariant.Remove}
              onClick={() =>
                props.removeTutorField(props.tutorFormId, props.tutorFormKey)
              }
              fullWidth
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default TutorFormField
