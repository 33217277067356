import React from 'react'
import { GradingScaleDetails } from '../../../swagger'
import { transcriptPdfStyles, border } from './TranscriptPDFStyles'

const GradeScaleTablePDF: React.FC<{ gradeScale: GradingScaleDetails[] }> = ({
  gradeScale,
}) => {
  return (
    <div style={{ ...transcriptPdfStyles.tableDiv }}>
      <table>
        <tbody>
          <tr style={{ border }}>
            <th style={transcriptPdfStyles.tableData}>Grade Scale</th>
            <th style={transcriptPdfStyles.tableData}>Letter Grade</th>
            <th style={{ alignItems: 'center' }}>Grade Points</th>
          </tr>
          {gradeScale.map((row) => {
            return (
              <tr
                key={row.sortOrder.toString()}
                style={{
                  borderLeft: border,
                  borderRight: border,
                  borderBottom: border,
                }}
              >
                <td style={transcriptPdfStyles.tableData}>{row.scaleRange}</td>
                <td style={transcriptPdfStyles.tableData}>{row.letterGrade}</td>
                <td
                  style={{
                    paddingLeft: '1px',
                    alignItems: 'center',
                  }}
                >
                  {row.credit?.toFixed(2)}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default GradeScaleTablePDF
