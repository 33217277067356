import React, { CSSProperties } from 'react'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'

interface BackToLoginButtonProps {
  css?: CSSProperties
  variant?: TextButtonVariant
  onClick?: () => void
}

const BackToLoginButton: React.FC<BackToLoginButtonProps> = ({
  css,
  variant,
  onClick,
}) => {
  return (
    <TextButton
      id="backToLoginLink"
      onClick={onClick}
      to={onClick ? undefined : '/login'}
      variant={!!variant ? variant : TextButtonVariant.BackToLogin}
      css={css}
      fullWidth
    />
  )
}
export default BackToLoginButton
