import { Address } from '../swagger/models/Address'

export const formatAddress = (address: Address): string => {
  let fullAddress = ''
  const city = address.city ? address.city : '',
    state = address.state ? address.state : '',
    zip = address.zip ? address.zip : ''

  if (city !== '' && state !== '') {
    fullAddress = [[city, state].join(', '), zip].join(' ')
  } else if (city !== '' && state === '') {
    fullAddress = [city, zip].join(' ')
  }

  return fullAddress
}

export default formatAddress
