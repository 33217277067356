interface Item<T = string | number> {
  [key: string]: T
}

interface ItemGroup<T> {
  [key: string]: T[]
}

/**
 * Utility function to group an array of objects by a specified key.
 *
 * @template T
 * @param {T[]} arr - The array of objects.
 * @param {keyof T} key - The key to group by
 * @returns {ItemGroup}
 */
export function groupByKey<T extends Item>(
  arr: T[],
  key: keyof T
): ItemGroup<T> {
  return arr.reduce<ItemGroup<T>>(
    (acc, curr) => ({
      ...acc,
      [curr[key]]: [...(acc[curr[key]] || []), curr],
    }),
    {}
  )
}
