import React, { useContext } from 'react'
import { Page } from '../Elements/PageMargins'
import CommunityFormCard, {
  CommunityFormCardVariants,
} from '../Card/CommunityFormCard'
import { useTranslation } from 'react-i18next'
import { CommunityDetail } from '../../swagger/models/CommunityDetail'
import TitleContext from '../../TitleContext'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { useNavigate } from 'react-router'

const emptyCommunityDetails: CommunityDetail = {
  communityKey: 0,
  name: '',
  address: {
    locationName: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    countryCode: '',
  },
  region: '',
  tutorModel: '',
  supportRepresentative: '',
  feePercentage: undefined,
  hideFromPublicSearch: false,
}

interface AddCommunityProps {
  /**
   * Optional community details to make testing easier
   * By providing details during a test, we spend less computation selecting
   * data and typing it.
   */
  communityDetails?: CommunityDetail
}

const AddCommunity: React.FC<AddCommunityProps> = ({ communityDetails }) => {
  const { t } = useTranslation()
  const title = t('Communities.AddCommunity.Header', 'Communities')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const navigate = useNavigate()

  const handleRedirect = () => {
    // Block outgoing navigation, etc while the user confirms their choice
    navigate({
      pathname: '/communities',
    })
  }

  return (
    <Page>
      <DynamicBreadcrumbs
        breadcrumbs={[
          {
            label: t('Communities.AddCommunity.BreadCrumb', 'Communities'),
            onClick: handleRedirect,
          },
          {
            label: t('Communities.AddCommunity.BreadCrumb', 'Create Community'),
          },
        ]}
      />
      <CommunityFormCard
        title={t('Communities.AddCommunity.Header', 'Create a Community')}
        variant={CommunityFormCardVariants.AddCommunity}
        handleCancel={handleRedirect}
        communityDetails={communityDetails ?? emptyCommunityDetails}
      />
    </Page>
  )
}

export default AddCommunity
