/**
 * Temporary Enum to provide unique Ids for LoadingContext when clicking certain buttons.
 * Meant to be replaced by Enum from https://classicalconversations.my.workfront.com/task/62ea863a005aa3b38573a8c147d8ff63/updates
 */
export enum LoadingApiCall {
  AddTuitionPayment = 'addTuitionPayment',
  UpdateTuitionPayment = 'updateTuitionPayment',
  RemoveTuitionPayment = 'removeTuitionPayment',
  Signin = 'signin',
  SendTokenForSignup = 'sendTokenForSignup',
  countryInformation = 'countryInformation',
}
