import React from 'react'
import BasicModal from './BasicModal'
import DropDown, { DropDownVariant, DropDownProps } from '../Menus/DropDown'
import { Box } from '@mui/system'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { OutlinedButtonVariant } from '../Buttons/OutlinedButton'
import ActionButtons from '../Buttons/ActionButtons'
import { TextButtonVariant } from '../Buttons/TextButton'
import { useTheme } from '@mui/material/styles'

export interface DropDownFilterOption
  extends Omit<DropDownProps, 'handleSelection' | 'variant'> {
  valueId: number | string
}

export interface FilterModalProps {
  /** Whether FilterModal is open */
  isOpen: boolean
  /** Function ran whenever a selection is from any dropdown is chosen */
  handleSelection: (
    selection: string,
    dropDownId: string,
    selectedOptionId: string | number
  ) => void
  /** Function ran whenever 'Confirm' is pressed */
  onConfirm: () => void
  /** Function ran whenever 'Clear All Filters' is pressed */
  onClearAllFilters: () => void
  /** Function ran whenever 'Cancel' is pressed */
  onCancel: () => void
  /** Label shown at the top of the modal */
  modalTitle: string
  /** An array of DropDownProps, each representing a DropDown present
   *  on the FilterModal */
  filterOptions: DropDownFilterOption[]
}

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  handleSelection,
  onConfirm,
  onClearAllFilters: clearFilters,
  onCancel,
  modalTitle,
  filterOptions,
}) => {
  const theme = useTheme()

  const dialogContent = () => {
    return (
      <Box>
        {filterOptions.map((dropDownProp) => {
          return (
            <Box p={theme.spacing(2)} key={dropDownProp.id}>
              <DropDown
                {...dropDownProp}
                handleSelection={handleSelection}
                variant={DropDownVariant.FormField}
                fullWidth
                formControlProps={{ maxWidth: '1' }}
                cssProps={{
                  padding: 0,
                  height: 'auto',
                  width: '100%',
                  textAlign: 'left',
                }}
                isClearFilters
              />
            </Box>
          )
        })}
      </Box>
    )
  }

  const dialogActions = () => {
    return (
      <ActionButtons
        primaryButtonLabel={ContainedButtonVariant.Confirm}
        secondaryButtonLabel={OutlinedButtonVariant.ClearAllFilters}
        secondaryClick={clearFilters}
        tertiaryButtonLabel={TextButtonVariant.Cancel}
        tertiaryClick={onCancel}
        fullWidthButtons
        alwaysStack
      />
    )
  }

  return (
    <BasicModal
      isOpen={isOpen}
      handleFormSubmit={onConfirm}
      dialogTitle={modalTitle}
      dialogContent={dialogContent()}
      dialogActions={dialogActions()}
      maxWidth="sm"
    />
  )
}

export default FilterModal
