import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../Buttons/ActionButtons'
import BasicModal from './BasicModal'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import PasswordField from '../Login/PasswordField'
import useEmailPasswordStates from '../../hooks/useEmailPasswordStates'
import { AuthFormInfo } from '../Login/AuthFormCard'
import Info from '@mui/icons-material/Info'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import EmailField from '../Login/EmailField'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import ErrorAlert from '../Alerts/ErrorAlert'
import useValidationMessages from '../../hooks/useValidationMessages'

enum ChangeEmailSteps {
  EnterNewEmail,
  EnterVerificationCode,
}
export enum EmailFieldsValidationMessageTypes {
  Default = 'default',
  NoAtSign = 'noAtSign',
  NoExtension = 'noExtension',
  NoSpaces = 'noSpace',
  NoEmail = 'noEmail',
  NoEmailNoSign = 'noEmailNoSign',
}
interface ChangeEmailModalProps {
  isOpen: boolean
  onClose: () => void
}

const emailVerificationCodeLength = 16

const ChangeEmailModal: React.FunctionComponent<ChangeEmailModalProps> = (
  props
) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [showPassword, setShowPassword] = useState(false)
  const [authenticationInfo, setAuthenticationInfo] = useState<AuthFormInfo>({
    email: '',
    password: '',
    passwordConfirm: '',
  })

  /** Hooks */
  const validationMessageMap = useValidationMessages([
    {
      field: EmailFieldsValidationMessageTypes.Default,
      message: t(
        'Email.EmailForm.ErrorMessage',
        'Email must be in a valid form (e.g. email@domain.com).'
      ),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoAtSign,
      message: t('Email.EmailForm.NOAtSign', 'The email must contain @.'),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoExtension,
      message: t(
        'Email.EmailForm.NoExtension',
        'The extension is missing (e.g. .com).'
      ),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoSpaces,
      message: t('Email.EmailForm.NoSpaces', 'Must not contain spaces.'),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoEmail,
      message: t(
        'Email.EmailForm.NoEmail',
        'Please enter a valid email address.'
      ),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoEmailNoSign,
      message: t(
        'Email.EmailForm.NoEmailNoSign',
        'The email must contain @ and extension is missing (e.g. .com).'
      ),
    },
  ])

  const [step, setStep] = useState(ChangeEmailSteps.EnterNewEmail)

  const handleAuthInfoUpdate = (id: string, value: string) => {
    setAuthenticationInfo({
      ...authenticationInfo,
      [id]: value,
    })
  }

  const emailPasswordStates = useEmailPasswordStates({
    includePasswordConfirm: false,
    handleAuthInfoUpdate,
    authenticationInfo,
    handleClickFromParent: async () => void {},
  })

  const { passwordFieldProps, emailFieldStates } = emailPasswordStates
  const {
    password,
    passwordIsEmptyOrInvalid,
    emptyPasswordField,
    handleInputEvent,
    handleKeyboardEvent,
  } = passwordFieldProps

  const { email, emptyEmailField, textErrorEmail, handleValidateEmail } =
    emailFieldStates

  const handleToggle = () => {
    setShowPassword(!showPassword)
    document.getElementById('password')?.focus()
  }

  function PasswordFieldIcon(isEmpty: boolean, isVisible: boolean) {
    const ToggleIcon = isVisible ? Visibility : VisibilityOff

    if (isEmpty) {
      return (
        <Info
          sx={{
            fill: theme.palette.error.main,
          }}
        />
      )
    }
    return <ToggleIcon />
  }

  const isSendVerificationCodeEnabled = !!email && !!password

  const onClose = () => {
    setAuthenticationInfo({
      email: '',
      password: '',
      passwordConfirm: '',
    })
    setStep(ChangeEmailSteps.EnterNewEmail)
    setEmailVerificationToken('')
    setShowPassword(false)
    setIsLoading(false)
    setError('')
    props.onClose()
  }

  const [emailVerificationToken, setEmailVerificationToken] = useState('')
  const isVerificationInputLongEnough =
    emailVerificationToken.length >= emailVerificationCodeLength

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const handleVerificationCodeKeyboardEvent = (
    event: React.KeyboardEvent<Element>,
    condition: boolean
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (condition) {
        // TODO: call confirm Verification Token in https://projekt202.atlassian.net/browse/CCP1-2274
        // And update if error occurs
        // And set isLoading when attempting to call endpoint
      }
    }
  }

  const DialogContent = () => {
    if (step === ChangeEmailSteps.EnterNewEmail) {
      return (
        <>
          <Box mb={5} width="100%">
            <EmailField
              value={email}
              label={t('ChangeEmail.FormFields.NewEmail', 'New Email Address')}
              error={emptyEmailField || textErrorEmail !== ''}
              helperText={`
              ${
                textErrorEmail === EmailFieldsValidationMessageTypes.NoEmail
                  ? validationMessageMap.get(
                      EmailFieldsValidationMessageTypes.NoEmail
                    )
                  : textErrorEmail ===
                    EmailFieldsValidationMessageTypes.NoExtension
                  ? validationMessageMap.get(
                      EmailFieldsValidationMessageTypes.NoExtension
                    )
                  : ''
              }`}
              onChange={handleInputEvent}
              onBlur={handleInputEvent}
              onKeyPress={handleKeyboardEvent}
              inputProps={{ 'data-testid': 'emailText' }}
              InputProps={
                emptyEmailField
                  ? {
                      endAdornment: (
                        <InputAdornment
                          id="emailIcon"
                          disablePointerEvents={true}
                          position="end"
                        >
                          <Info
                            sx={{
                              fill: theme.palette.error.main,
                            }}
                          />
                        </InputAdornment>
                      ),
                    }
                  : {}
              }
            />
          </Box>
          <Box width="100%">
            <PasswordField
              id="password"
              label={t(
                'ChangeEmail.FormFields.CurrentPassword',
                'Current Password'
              )}
              helperText=""
              type={showPassword ? 'text' : 'password'}
              value={password}
              error={passwordIsEmptyOrInvalid}
              onChange={handleInputEvent}
              onBlur={handleInputEvent}
              onKeyPress={handleKeyboardEvent}
              inputProps={{ maxLength: 128 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={passwordIsEmptyOrInvalid}
                      aria-label={t(
                        'Login.PasswordField.IconButton.AriaLabel',
                        'Toggle password visibility'
                      )}
                      onClick={
                        !emptyPasswordField ? () => handleToggle() : () => null
                      }
                      edge="end"
                    >
                      {PasswordFieldIcon(
                        passwordIsEmptyOrInvalid,
                        showPassword
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </>
      )
    } else {
      return (
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            color={theme.palette.primary.main}
          >
            {t(
              'ChangeEmailModal.EmailVerification.Instructions.Part1',
              'If you do not see the email sent to'
            )}
            &nbsp;
            <Typography
              variant="body1"
              component="span"
              fontWeight={700}
              color={theme.palette.primary.main}
            >
              {email}
            </Typography>
            {t(
              'ChangeEmailModal.EmailVerification.Instructions.Part2',
              ', please check your Spam folder. Do not close this window. The code will expire in a few minutes'
            )}
          </Typography>
          <Box p={3}>
            {isLoading && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            )}
            {!!error && <ErrorAlert error={error} />}
            <Box display="flex" justifyContent="center" alignItems="baseline">
              <TextField
                id="emailVerificationField"
                value={emailVerificationToken}
                inputProps={{ maxLength: 16 }}
                label={t(
                  'VerificationCodeCard.Field.SignUpVerification',
                  '16 character code'
                )}
                onChange={(event) =>
                  setEmailVerificationToken(event.target.value)
                }
                onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  handleVerificationCodeKeyboardEvent(
                    e,
                    isVerificationInputLongEnough
                  )
                }}
                variant="filled"
                fullWidth
              />
            </Box>
          </Box>
        </Box>
      )
    }
  }

  const handleFormSubmit = async (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    const IsvalidEmail = handleValidateEmail()
    // TODO: Add change email call here. https://projekt202.atlassian.net/browse/CCP1-2274
    if (step === ChangeEmailSteps.EnterNewEmail) {
      IsvalidEmail === '' && setStep((step) => step + 1)
    } else {
      onClose()
    }
  }

  const handleBackToEnterNewEmail = () => {
    setEmailVerificationToken('')
    setStep((step) => step - 1)
  }

  const handleResendEmailVerificationCode = () => {
    // TODO: Add resend email token here. https://projekt202.atlassian.net/browse/CCP1-2274
    return
  }

  const DialogTitle = () => {
    return step === ChangeEmailSteps.EnterNewEmail
      ? t(
          'ChangeEmailModal.EnterNewEmail.Title',
          'Enter a new Login Email Address'
        )
      : t(
          'ChangeEmailModal.EmailVerificationCode.Title',
          'Copy and paste the code found in your new email'
        )
  }

  const DialogActions = () => {
    if (step === ChangeEmailSteps.EnterNewEmail) {
      return (
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.SendVerificationCode}
          disablePrimaryButton={!isSendVerificationCodeEnabled}
          secondaryButtonLabel={TextButtonVariant.Cancel}
          secondaryClick={onClose}
          alwaysStack
        />
      )
    } else {
      return (
        <Box
          px={2}
          py={1}
          width="100%"
          display="flex"
          flexDirection={{ xs: 'column', md: 'row-reverse' }}
          justifyContent="flex-end"
          alignItems="center"
        >
          <ContainedButton
            id="primary"
            type="submit"
            variant={ContainedButtonVariant.Confirm}
            disabled={!isVerificationInputLongEnough}
            fullWidth
          />
          <TextButton
            id="secondary"
            variant={TextButtonVariant.ResendCode}
            onClick={handleResendEmailVerificationCode}
            fullWidth
          />
          <TextButton
            id="tertiary"
            variant={TextButtonVariant.Back}
            onClick={handleBackToEnterNewEmail}
            fullWidth
          />
        </Box>
      )
    }
  }

  return (
    <BasicModal
      isOpen={props.isOpen}
      dialogTitle={DialogTitle()}
      handleFormSubmit={handleFormSubmit}
      ariaLabel="Change Email Modal"
      maxWidth="xs"
      dialogContent={DialogContent()}
      dialogActions={DialogActions()}
    />
  )
}

export default ChangeEmailModal
