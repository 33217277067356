import React, { useMemo, useRef } from 'react'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import { useTranslation } from 'react-i18next'
import useValidationMessages from '../../hooks/useValidationMessages'
import DateField, { DateFieldVariant } from '../Elements/DateField'
import { dateToDashString, dateToSlashString } from '../../utils/dateUtility'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'

interface StandardizedFieldsProps {
  updateStandardizedFieldStatesForParent: (
    fieldId: string,
    state: string,
    required: boolean
  ) => void
  standardizedFieldStates: DynamicFieldStates
}

export enum StandardizedFieldNames {
  EffectiveDate = 'Effective Date',
  EndDate = 'End Date',
}

enum StandardizedFieldsValidationMessageTypes {
  CannotBeEmpty = 'cannotBeEmpty',
  ValidDate = 'validDate',
  BeforeMax = 'beforeMax',
  AfterMin = 'afterMin',
  DateRange = 'dateRange',
  HasNoOptions = 'hasNoOptions',
  RequiredEndDateInSomeRolesIfValueIsEmpty = 'RequiredEndDateInSomeRolesIfValueIsEmpty',
}

export const StandardizedFields: React.FC<StandardizedFieldsProps> = ({
  updateStandardizedFieldStatesForParent,
  standardizedFieldStates,
}) => {
  const { t } = useTranslation()
  /** The most we really want to set for the future is right before Y10K. The world might end like Y2K... */
  const maximumDate = useRef(new Date(Date.UTC(9999, 11, 31, 23, 59, 59)))
  const minimumRoleDate = useRef(
    new Date(Date.UTC(new Date().getFullYear() - 2, 0, 1))
  )

  const {
    [StandardizedFieldNames.EffectiveDate]: effectiveDate,
    [StandardizedFieldNames.EndDate]: endDate,
  } = standardizedFieldStates

  const validationMessageMap = useValidationMessages([
    {
      field: StandardizedFieldsValidationMessageTypes.CannotBeEmpty,
      message: t(
        'StandardizedFields.ValidationMessage.CannotBeEmpty',
        'cannot be empty.'
      ),
    },
    {
      field: StandardizedFieldsValidationMessageTypes.ValidDate,
      message: t(
        'StandardizedFields.ValidationMessage.ValidDate',
        'must be a valid date in the form mm-dd-yyyy.'
      ),
    },
    {
      field: StandardizedFieldsValidationMessageTypes.BeforeMax,
      message: t(
        'Roles.RoleModal.ValidationMessage.BeforeMax',
        'Please enter a valid date on or before'
      ),
    },
    {
      field: StandardizedFieldsValidationMessageTypes.AfterMin,
      message: t(
        'Roles.RoleModal.ValidationMessage.AfterMin',
        'Please enter a valid date on or after'
      ),
    },
    {
      field: StandardizedFieldsValidationMessageTypes.DateRange,
      message: t(
        'StandardizedFields.ValidationMessage.DateRange',
        'End Date must be after Effective Date'
      ),
    },
    {
      field: StandardizedFieldsValidationMessageTypes.HasNoOptions,
      message: t(
        'StandardizedFields.ValidationMessage.HasNoOptions',
        'has no available options.'
      ),
    },
    {
      field:
        StandardizedFieldsValidationMessageTypes.RequiredEndDateInSomeRolesIfValueIsEmpty,
      message: t(
        'StandardizedFields.ValidationMessage.RequiredEndDate',
        'End date is required for this role.'
      ),
    },
  ])

  const effectiveDateHelperText = useMemo(() => {
    if (!effectiveDate.isValid.input) {
      return validationMessageMap.get(
        StandardizedFieldsValidationMessageTypes.ValidDate
      )
    } else if (!effectiveDate.isValid.beforeMax) {
      return `${validationMessageMap.get(
        StandardizedFieldsValidationMessageTypes.BeforeMax
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(maximumDate.current)
      )}`
    } else if (!effectiveDate.isValid.afterMin) {
      return `${validationMessageMap.get(
        StandardizedFieldsValidationMessageTypes.AfterMin
      )} ${dateToSlashString(
        reinterpretYearMonthDayAsLocalTime(minimumRoleDate.current)
      )}`
    }
  }, [
    effectiveDate.isValid.afterMin,
    effectiveDate.isValid.beforeMax,
    effectiveDate.isValid.input,
    maximumDate,
    minimumRoleDate,
    validationMessageMap,
  ])

  // If the end date is not required, does not show beforeMax and afterMin errors text
  const endDateHelperText = useMemo(() => {
    if (!endDate.isValid.input) {
      return validationMessageMap.get(
        StandardizedFieldsValidationMessageTypes.ValidDate
      )
    } else if (!endDate.isValid.beforeMax && endDate.required) {
      return `${validationMessageMap.get(
        StandardizedFieldsValidationMessageTypes.BeforeMax
      )} ${dateToSlashString(
        reinterpretYearMonthDayAsLocalTime(maximumDate.current)
      )}`
    } else if (!endDate.isValid.afterMin && endDate.required) {
      return `${validationMessageMap.get(
        StandardizedFieldsValidationMessageTypes.BeforeMax
      )} ${dateToSlashString(
        reinterpretYearMonthDayAsLocalTime(minimumRoleDate.current)
      )}`
    } else if (!endDate.isValid.dateRange) {
      return validationMessageMap.get(
        StandardizedFieldsValidationMessageTypes.DateRange
      )
    }
  }, [
    endDate.isValid.afterMin,
    endDate.isValid.beforeMax,
    endDate.isValid.dateRange,
    endDate.isValid.input,
    endDate.required,
    maximumDate,
    minimumRoleDate,
    validationMessageMap,
  ])

  const onValueChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string
  ) => {
    const eventValue = event.target.value
    const fieldId = event.target.name

    updateStandardizedFieldStatesForParent(fieldId, value ?? eventValue, true)
  }

  return (
    <>
      <DateField
        id={StandardizedFieldNames.EffectiveDate}
        dateVariant={DateFieldVariant.EffectiveDate}
        name={StandardizedFieldNames.EffectiveDate}
        type="date"
        onChangeForParent={onValueChange}
        value={effectiveDate.state}
        error={
          !effectiveDate.isValid.input ||
          !effectiveDate.isValid.afterMin ||
          !effectiveDate.isValid.beforeMax
        }
        maxDate={maximumDate.current}
        helperTextForParent={effectiveDateHelperText}
      />
      <DateField
        id={StandardizedFieldNames.EndDate}
        dateVariant={DateFieldVariant.EndDate}
        type="date"
        name={StandardizedFieldNames.EndDate}
        onChangeForParent={onValueChange}
        value={endDate.state}
        // Shows afterMin and beforeMax errors if the date is required
        error={
          !endDate.isValid.input ||
          (!endDate.isValid.afterMin && endDate.required) ||
          (!endDate.isValid.beforeMax && endDate.required) ||
          !endDate.isValid.isRequiredInSomeRolesIfValueIsEmpty ||
          !endDate.isValid.dateRange
        }
        maxDate={maximumDate.current}
        helperTextForParent={endDateHelperText}
      />
    </>
  )
}

export default StandardizedFields
