enum Month {
  'January' = 0,
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
}

/**
 * Utility function to generate month days
 *
 * @param n total number of values in the array
 * @returns array containing [1...n] numbers
 */
const generateNumberArray = (n: number): number[] =>
  Array.from({ length: n }, (_, i) => i + 1)

/**
 * Month options to populate a dropdown
 */
export const monthOptions = Object.keys(Month)
  .filter((key) => key !== String(parseFloat(key)))
  .map((m, i) => ({
    value: i,
    label: m,
    abbreviation: m.slice(0, 3),
  }))

/**
 * Utiltiy function to calculate number of days in a month
 * @param m the given numerical month (1-12)
 * @returns list of days in the month
 */
export const generateDayOptions = (m: number | string): number[] => {
  if (!m) return generateNumberArray(31)
  switch (m as Month) {
    case Month.February:
      return generateNumberArray(28)
    case Month.April:
    case Month.June:
    case Month.September:
    case Month.November:
      return generateNumberArray(30)
    default:
      return generateNumberArray(31)
  }
}

/**
 * Utility function to add leading zero to numerical month/day values for display purposes
 *
 * @param n month or day number
 * @returns display string with a leading zero
 */
export const addLeadingZero = (n?: number): string => {
  if (!n) return ''
  return ('0' + n).slice(-2)
}
