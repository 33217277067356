import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TitleContext from '../../TitleContext'
import ResponsiveFab from '../Buttons/ResponsiveFab'
import AddIcon from '@mui/icons-material/Add'
import { addIconFudgeFactor } from '../../utils/addIconFudgeFactor'
import { standardSort } from '../../utils/standardSort'
import {
  Events1,
  EventEventTypeEnum,
  UserAccountListing,
  FetchEventsResponse,
} from '../../swagger'
import {
  events,
  extractedErrorObject,
  userAccountsApi,
} from '../../api/swagger'
import spacetime, { Spacetime } from 'spacetime'
import { todaysDateFormattedLocal } from '../../utils/todaysDateFormatted'
import { Can } from '@casl/react'
import { useAuth } from '../Routes/AuthProvider'
import Box from '@mui/material/Box'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { dateToDashString } from '../../utils/dateUtility'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useSnackbarOnNavigation } from '../../hooks/useSnackbarOnNavigation'
import EventsTab from './EventsTab'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { defaultPageSize } from '../Pagination/TableFooterPagination'
import { EventSortBy } from './EventEnums'
import { MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH } from '../../utils/constants'

const EventTypes = {
  AllEvents: 'All Event Types',
  ...EventEventTypeEnum,
}

export const createSpacetime = (
  event: FetchEventsResponse,
  useStartTime = true
): Spacetime => {
  // [year, month, date]
  const dateArray = [
    event.startDate.getUTCFullYear(),
    event.startDate.getUTCMonth(),
    event.startDate.getUTCDate(),
  ]
  switch (useStartTime) {
    case true:
      return spacetime(dateArray, event.timezone).time(event.startTime)
    default:
      return spacetime(dateArray, event.timezone).time(
        event.endTime ?? new Date().toISOString()
      )
  }
}

export const sortEventsByDate = (
  a: FetchEventsResponse,
  b: FetchEventsResponse
): number => {
  const epochA = createSpacetime(a).epoch
  const epochB = createSpacetime(b).epoch

  return standardSort(epochA, epochB)
}

export const getTodaysEarliestEvent = (
  array: FetchEventsResponse[]
): FetchEventsResponse => {
  return array
    .filter((event) => {
      const eventStartDateTime = createSpacetime(event)
      return spacetime.now().startOf('day').isBefore(eventStartDateTime)
    })
    .sort((a, b) => {
      return sortEventsByDate(a, b)
    })[0]
}

const defaultSortProperty = EventSortBy.StartDate
const defaultSortOrder = 'ASC'
const firstFooterPage = 0
const eventDefaultCreatorOption = 'All Event Creators'

export interface EventFilter {
  fromDate: string
  page: number
  pageSize: number
  orderBy: Array<string>
  search: string
  eventType: string
  eventCreator: string
}

export const UserEvents: React.FunctionComponent = () => {
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { t } = useTranslation()
  const title = t('Events.Title', 'Events')
  const { permissionAbility } = useAuth()
  useSnackbarOnNavigation()

  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)

  const { addLoadingIds } = React.useContext(LoadingContext)
  const availableLoadingIds = useLoadingIds()

  const [isLoading, setIsLoading] = useState(true)

  const [eventsResponse, setEventsResponse] = useState<Events1>({ events: [] })

  const [minDateFilter, setMinDateFilter] = useState(String)
  const [tableFooterPage, setTableFooterPage] = useState(firstFooterPage)
  const [eventCreators, setEventCreators] = useState<UserAccountListing[]>([])
  const [eventFilters, setEventFilters] = useState<EventFilter>({
    fromDate: todaysDateFormattedLocal(),
    page: 1,
    pageSize: defaultPageSize,
    orderBy: [`${defaultSortProperty} ${defaultSortOrder}`],
    search: '',
    eventType: EventTypes.AllEvents,
    eventCreator: eventDefaultCreatorOption,
  })

  const [searchTerm, setSearchTerm] = useState('')

  const fetchEventsErrorMessage = t(
    'Events.Error.FetchEvents',
    'An error occurred while retrieving event details.'
  )

  const fetchEventDirectorsErrorMessage = t(
    'Events.Error.FetchUsers',
    'Unknown Error occurred fetching users'
  )

  const fetchEvents = useCallback(
    async (eventFilter: EventFilter): Promise<Events1> => {
      try {
        const fetchedEvents = await events.fetchEvents({
          ...eventFilter,
          search: eventFilter.search === '' ? undefined : eventFilter.search,
          eventType:
            eventFilter.eventType === EventTypes.AllEvents
              ? undefined
              : eventFilter.eventType,
          eventCreator:
            eventFilter.eventCreator === eventDefaultCreatorOption
              ? undefined
              : eventFilter.eventCreator,
        })
        setEventsResponse(fetchedEvents)
        setIsLoading(false)
        //init the min and max date of datePicker
        initMinDateFilter()
        return fetchedEvents
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message: (e as unknown as Error).message ?? fetchEventsErrorMessage,
        }
        setSnackbarState?.(true)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarSeverity?.(SnackbarSeverity.Error)
      }

      return { events: [] }
    },
    [
      fetchEventsErrorMessage,
      setSnackbarMessage,
      setSnackbarSeverity,
      setSnackbarState,
    ]
  )

  const updateSearchResults = (search: string) => {
    // set state of search term
    setSearchTerm(search)

    // call and update the event creators based on the search term
    addLoadingIds([availableLoadingIds.Events.fetchUsers])
  }

  const fetchEventCreators = async (opts?: {
    search?: string
  }): Promise<void> => {
    const {
      search = searchTerm.length < MINIMUM_DEBOUNCED_SEARCH_QUERY_LENGTH
        ? undefined
        : searchTerm,
    } = opts ?? {}
    try {
      const { userAccounts: eventCreators } = await userAccountsApi.fetchUsers({
        //downlineOnly is set to true when the actor lacks the accessUsersTab admin permission.
        downlineOnly: !permissionAbility.can('accessUsersTab', 'Admin'),
        validNowOnly: true,
        search,
      })
      setEventCreators(eventCreators)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message:
          (e as unknown as Error).message ?? fetchEventDirectorsErrorMessage,
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: fetchEventCreators,
    loadingId: availableLoadingIds.Events.fetchUsers,
  })

  useLoadingContext({
    asyncFunction: async () => {
      await fetchEvents(eventFilters)
    },
    loadingId: availableLoadingIds.Events.fetchEvents,
  })

  useMountEffect(() => {
    addLoadingIds([availableLoadingIds.Events.fetchEvents])
  })

  const resetTablePagination = (newPage: number): void => {
    setTableFooterPage(newPage)
  }

  const resetEventCreators = () => {
    setEventCreators([])
  }

  const initMinDateFilter = () => {
    const today = new Date()
    const minDate = new Date()

    minDate.setDate(1)
    minDate.setMonth(0)
    minDate.setFullYear(today.getFullYear() - 2)

    const minDateFormatted = dateToDashString(
      reinterpretYearMonthDayAsLocalTime(minDate)
    )
    setMinDateFilter(minDateFormatted)
  }

  const newFilter = useRef<EventFilter>({ ...eventFilters })
  const handleEventFilterChange = useCallback(
    <K extends keyof EventFilter>(
      filter: K,
      value: EventFilter[K],
      isMobile?: boolean,
      sortValue?: string
    ) => {
      const pageSize = 'pageSize'
      const page = 'page'

      if (filter && sortValue !== 'none') {
        if (isMobile) {
          newFilter.current[pageSize] = defaultPageSize
        }

        if (filter !== 'page') {
          newFilter.current[page] = 1
        }

        newFilter.current[filter] = value
        setTableFooterPage(firstFooterPage)
        setEventFilters(newFilter.current)

        fetchEvents(newFilter.current)
      }
    },
    [fetchEvents, newFilter]
  )

  const handleClose = () => {
    setSnackbarState(false)
  }

  const AddEventFAB = () => (
    <Can I="create" on="Event" ability={permissionAbility}>
      <Box component="span" onClick={handleClose}>
        <ResponsiveFab
          to="/events/add-event"
          icon={AddIcon}
          spacingFudgeFactor={addIconFudgeFactor}
          iconLabel={t('Events.AddEventButton.IconLabel', 'Add')}
          textLabel={t('Events.AddEventButton.TextLabel', 'Event')}
          fullLabel={t('Events.AddEventButton.FullLabel', 'Add Event')}
        />
      </Box>
    </Can>
  )

  return (
    <>
      {AddEventFAB()}
      <EventsTab
        events={eventsResponse.events}
        isLoading={isLoading}
        minDateFilter={minDateFilter}
        dateToFilter={eventFilters.fromDate}
        pageSize={eventsResponse.pagination?.pageSize ?? eventFilters.pageSize}
        totalCount={eventsResponse.pagination?.totalCount ?? -1}
        resetTablePagination={resetTablePagination}
        tableFooterPage={tableFooterPage}
        handleEventFilterChange={handleEventFilterChange}
        eventCreators={eventCreators}
        updateSearchResults={updateSearchResults}
        resetEventCreators={resetEventCreators}
      />
    </>
  )
}
export default UserEvents
