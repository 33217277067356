import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import Box from '@mui/material/Box'
import Error from '@mui/icons-material/Error'
import Info from '@mui/icons-material/Info'
import EmailField from './EmailField'
import BrandCard from '../Card/BrandCard'
import { useTranslation } from 'react-i18next'
import PasswordFields from './PasswordFields'
import useEmailPasswordStates from '../../hooks/useEmailPasswordStates'
import useEmailMessage from '../../hooks/useEmailMessage'
import { Alert } from '@mui/material'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { LoadingApiCall } from '../../api/LoadingApiCall'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useInviteContext } from '../Context/InviteContext'

/**
 * Rendered height (in px) of the login/signup cards.
 * Used for conditional container styles if the screen is tall enough to fully show this card
 */
const defaultHeightOfLoginCard = 510
const defaultHeightOfSignupCard = 672

export interface AuthFormInfo {
  email: string
  password: string
  passwordConfirm: string
  newPassword?: string
  newPasswordConfirm?: string
}
interface Props {
  /**
   * Information pertaining to the fields rendered by AuthFormCard
   * At a minimum, the email, password, and password confirm should be included.
   */
  authenticationInfo?: AuthFormInfo
  /**
   * Method to update values of authenticationInfo.
   *
   * @param id string identifier of the property within authenticationInfo
   * @param value string value for the property being updated.
   */
  handleAuthInfoUpdate?: (id: string, value: string) => void
  heightOfLoginCard?: number
  heightOfSignupCard?: number
  /** Error to be displayed on the card. */
  errorMessage: string
  /** Click handler for the primary button. */
  handleClick: (email: string, password: string) => Promise<void>
  secondaryBtn?: JSX.Element | false
  additionalHeaderContent?: JSX.Element
  isSignUp?: boolean
  hasChangedPassword?: boolean
  /** Optional callback to be completed after handleClick completes */
  callback?: () => void
  /** Name of component, for use in LoadingContext ids. */
  name: string
}

const AuthFormCard: React.FunctionComponent<Props> = ({
  authenticationInfo: authenticationInfoFromParent,
  handleAuthInfoUpdate: handleAuthInfoUpdateFromParent,
  heightOfLoginCard = defaultHeightOfLoginCard,
  heightOfSignupCard = defaultHeightOfSignupCard,
  errorMessage,
  handleClick: handleClickFromParent,
  secondaryBtn,
  additionalHeaderContent,
  isSignUp = false,
  hasChangedPassword = false,
  callback,
  name,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    authenticationInfo: authenticationInfoFromContext,
    handleAuthInfoUpdate: handleAuthInfoUpdateFromContext,
  } = useInviteContext()

  const authenticationInfo =
    authenticationInfoFromParent ?? authenticationInfoFromContext
  const handleAuthInfoUpdate =
    handleAuthInfoUpdateFromParent ?? handleAuthInfoUpdateFromContext

  const { validationMessages } = useEmailMessage()
  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds } = React.useContext(LoadingContext)

  const loadingId = !isSignUp
    ? `${LoadingApiCall.Signin}${name}`
    : `${availableLoadingIds.AuthFormCard.sendTokenForSignup}${name}`

  const emailPasswordStates = useEmailPasswordStates({
    includePasswordConfirm: isSignUp,
    handleAuthInfoUpdate,
    authenticationInfo,
    handleClickFromParent,
    handleKeyboardEvent: async () => addLoadingIds([loadingId]),
  })

  const { passwordFieldProps, emailFieldStates } = emailPasswordStates
  const { emptyEmailField, email, handleClick, textErrorEmail } =
    emailFieldStates
  const {
    emptyPasswordField,
    emptyPasswordConfirmField,
    handleInputEvent,
    handleKeyboardEvent,
  } = passwordFieldProps

  let inputFieldsEmpty = emptyEmailField || emptyPasswordField

  if (isSignUp) {
    inputFieldsEmpty = inputFieldsEmpty || emptyPasswordConfirmField
  }

  const errorToShow = inputFieldsEmpty ? null : errorMessage

  /**
   *  When we have updated the loading context that we are logging in and the button
   *  is disabled, then call the handleClick method. This handles the keyboardEvent.
   *  The BaseButton handles onClick.
   */
  useLoadingContext({
    asyncFunction: handleClick,
    callback,
    /** TODO: Use hook useLoadingIds */
    loadingId,
  })

  const iconsStyles = {
    fill: theme.palette.error.main,
  }

  return (
    <BrandCard
      minimumScreenHeight={isSignUp ? heightOfSignupCard : heightOfLoginCard}
      additionalHeaderContent={additionalHeaderContent}
      cardContent={
        <>
          {errorToShow && (
            <Box
              role="alert"
              aria-atomic="true"
              sx={{
                color: theme.palette.error.main,
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                background: theme.palette.customBackground.error,
                cursor: 'default',
                minHeight: theme.spacing(6),
              }}
            >
              <InputAdornment
                sx={{
                  marginInlineStart: '18px',
                }}
                position="start"
              >
                <Error
                  sx={{
                    ...iconsStyles,
                  }}
                />
              </InputAdornment>
              <Typography
                variant="subtitle1"
                component="h3"
                justifyContent={'flex-start'}
              >
                {errorMessage}
              </Typography>
            </Box>
          )}
          {hasChangedPassword && (
            <Alert severity="success">
              {t(
                'Login.PasswordChanged.Message',
                'Your password has been successfully changed. Please login again using your new password.'
              )}
            </Alert>
          )}
          <EmailField
            value={email}
            error={emptyEmailField || textErrorEmail !== ''}
            helperText={validationMessages(textErrorEmail)}
            inputProps={{ 'data-testid': 'emailText' }}
            label={t('Login.EmailField.Label', 'Email')}
            onChange={handleInputEvent}
            onBlur={handleInputEvent}
            onKeyPress={handleKeyboardEvent}
            InputProps={
              emptyEmailField
                ? {
                    endAdornment: (
                      <InputAdornment
                        id="emailIcon"
                        disablePointerEvents={true}
                        position="end"
                      >
                        <Info
                          sx={{
                            ...iconsStyles,
                          }}
                        />
                      </InputAdornment>
                    ),
                  }
                : {}
            }
          />
          <PasswordFields {...passwordFieldProps} />
        </>
      }
      cardActions={
        <>
          <ContainedButton
            id="loginButton"
            /** Variant shall also determine the label */
            variant={
              isSignUp
                ? ContainedButtonVariant.CreateAccount
                : ContainedButtonVariant.Login
            }
            fullWidth
            loadingId={loadingId}
            useBaseButton
            css={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              flexGrow: 1,
              width: '96.75%',
              padding: theme.spacing(1.5),
              color: 'white',
            }}
          />
          {secondaryBtn}
          {!isSignUp && (
            <TextButton
              id="forgotPasswordLink"
              variant={TextButtonVariant.ForgotPassword}
              to={'/login/reset'}
              state={{ params: location.pathname }}
              fullWidth
              useBaseButton
            />
          )}
        </>
      }
    />
  )
}

export default AuthFormCard
