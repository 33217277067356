/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  InvitationType,
  InvitationTypeFromJSON,
  InvitationTypeToJSON,
  InviteValidity,
  InviteValidityFromJSON,
  InviteValidityToJSON,
} from './'

/**
 *
 * @export
 * @interface FetchInviteValidityResponse
 */
export interface FetchInviteValidityResponse {
  /**
   *
   * @type {InviteValidity}
   * @memberof FetchInviteValidityResponse
   */
  validity: InviteValidity
  /**
   * Email address associated to an already existing user. This must be used as the login username if provided. Sign up requires the email to which the invitation was sent, but this is not that.
   * @type {string}
   * @memberof FetchInviteValidityResponse
   */
  acceptedEmail?: string
  /**
   *
   * @type {InvitationType}
   * @memberof FetchInviteValidityResponse
   */
  invitationType?: InvitationType
}

export function FetchInviteValidityResponseFromJSON(
  json: any
): FetchInviteValidityResponse {
  return FetchInviteValidityResponseFromJSONTyped(json, false)
}

export function FetchInviteValidityResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchInviteValidityResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    validity: InviteValidityFromJSON(json['validity']),
    acceptedEmail: !exists(json, 'acceptedEmail')
      ? undefined
      : json['acceptedEmail'],
    invitationType: !exists(json, 'invitationType')
      ? undefined
      : InvitationTypeFromJSON(json['invitationType']),
  }
}

export function FetchInviteValidityResponseToJSON(
  value?: FetchInviteValidityResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    validity: InviteValidityToJSON(value.validity),
    acceptedEmail: value.acceptedEmail,
    invitationType: InvitationTypeToJSON(value.invitationType),
  }
}
