import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { CSSProperties, useMemo, useState } from 'react'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

export const setLabelValue = (
  currentSelection: string,
  menuOptionName: string,
  defaultValue: string
): string => {
  return currentSelection !== ''
    ? currentSelection
    : menuOptionName !== ''
    ? menuOptionName
    : defaultValue
}

/** Properties of a menu option in the list of available options */
export interface MenuOption {
  /** Name of menu option */
  name: string
  /** Id for menu options to provide uniqueness in a list */
  id: string | number
  /** Is the menu option disabled? */
  disabled?: boolean
  /** Secondary line to a menu item */
  subtitle?: string
}

/** Properties of the Drop Down menu component */
export interface DropDownProps {
  /** A unique string to determine action in handleSelection and distinguish
   * between other drop downs.
   */
  id: string
  /** List of available options in the drop down menu. On initial load, the
   *  first option in the array is selected .
   */
  menuOptions: Array<MenuOption>
  /** Default value to display in the dropdown if not displaying the selected
   *  value. Showing the default value is determined by breakpoints.
   */
  defaultValue?: string
  /**
   * Handles the selection on the parent component based on the id of the drop
   * down. Different drop downs perform different actions.
   */
  handleSelection: (
    /** Name of the MenuOption selected */
    selection: string,
    /** Id of the drop down to determine which action to perform */
    id: string,
    selectOptionId: string | number
  ) => void
  /** Variant to determine styling and behavior */
  variant: DropDownVariant
  /** Optional CSSProperties
   */
  cssProps?: CSSProperties
  /** Optional fullWidth.  Currently being used on Events (Mobile)
   */
  fullWidth?: boolean
  /** Optional form control CSSProperties */
  formControlProps?: CSSProperties
  /** Optional label value for form fields*/
  /** Optional menuOptions and render value CSSProperties */
  menuItemsAndRenderProps?: CSSProperties
  label?: string
  /** Optional initial value for the dropdown */
  value?: string
  /** Optional value for error state for the dropdown*/
  error?: boolean
  /** Optional value to disable the dropdown*/
  disabled?: boolean
  /** Optional helper text with instructions to fix errors or selection */
  helperText?: string
  /** Optional boolean to allow the Select to choose multiple options */
  multiple?: boolean
  /** optional boolean to allow the user to use open properties in a select
   * true  when you want to use the 'open' false when you don't want to use the open property
   * setting use to false can be useful if you have a dropdown with a scrollbar that disappear and don't permit scrolling
   */
  useOpen?: boolean
  /**
   * An optional boolean flag indicating whether the clear all filters button triggers changes in the dropdown
   */
  isClearFilters?: boolean
  /** Optional flag to make selection required */
  required?: boolean
}

export enum DropDownVariant {
  SortAndFilter,
  FormField,
}

/**
 * Drop Down Menu with selectable options.
 * @param {DropDownProps} props - The properties for the drop down menu
 */
export const DropDown: React.FC<DropDownProps> = ({
  id,
  menuOptions,
  defaultValue,
  handleSelection,
  cssProps,
  fullWidth,
  formControlProps,
  menuItemsAndRenderProps,
  variant,
  label,
  value,
  error = false,
  disabled = false,
  helperText,
  multiple = false,
  useOpen = false,
  isClearFilters = false,
  required = false,
}) => {
  const theme = useTheme()

  const [selectedOption, setSelectedOption] = useState('')
  const isFormField = variant === DropDownVariant.FormField

  const [isOpen, setIsOpen] = useState(false)

  const labelValueForDropDown = useMemo<string | string[]>(() => {
    /**
     * Verify whether the selectedOption differs from the prop value(new selected value). This condition is applicable when
     * the filter state is reset with the clear all filters button, and the dropdown selection requires an update.
     */
    if (isClearFilters && (value as string) !== selectedOption) {
      setSelectedOption(value as string)
    }

    return multiple
      ? value
        ? value.split(',')
        : []
      : setLabelValue(
          isFormField ? '' : selectedOption,
          typeof value === 'string' ? (value as string) : menuOptions[0]?.name,
          typeof defaultValue === 'string' ? (defaultValue as string) : ''
        )
  }, [
    isClearFilters,
    value,
    selectedOption,
    multiple,
    isFormField,
    menuOptions,
    defaultValue,
  ])

  const showOnDesktop = useShowOnDesktop()

  const handleInputChange = (
    selection: string,
    selectOptionId: string | number
  ) => {
    if (!multiple) {
      // Does not apply for multiple selections
      setSelectedOption(selection)
    }
    handleSelection(selection, id, selectOptionId)
  }

  const selectProperties = useOpen
    ? {
        /** Explicitly set open state for multiple select ease of testing */
        onOpen: () => {
          setIsOpen(true)
        },
        onClose: () => {
          setIsOpen(false)
        },
        open: isOpen,
      }
    : {}

  return (
    <FormControl
      variant={!isFormField ? 'outlined' : 'filled'}
      sx={{
        ...((fullWidth || !showOnDesktop) && {
          width: '100%',
        }),
        ...(!!formControlProps
          ? { ...formControlProps }
          : {
              width: 'auto',
              height: '36px',
            }),
      }}
      disabled={disabled}
    >
      <InputLabel hidden={!isFormField} id={`${id}SelectLabel`}>
        {!isFormField ? id : required ? `${label} *` : label}
      </InputLabel>
      <Select
        id={`${id}Select`}
        name={id}
        labelId={`${id}SelectLabel`}
        value={labelValueForDropDown}
        {...selectProperties}
        onChange={(
          e: SelectChangeEvent<unknown>,
          child
        ) => /* SAFETY: we guarantee the value is always a string because we provide
         * string names for the menu options.
         */ {
          useOpen && setIsOpen(false)
          const dataId =
            child &&
            child instanceof Object &&
            'props' in child &&
            'data-id' in child.props
              ? child.props['data-id']
              : ''
          return handleInputChange(e.target.value as string, dataId)
        }}
        sx={{
          height: '32px',
          padding: theme.spacing(1, 1, 1, 2),
          textAlign: 'center',
          ...(fullWidth
            ? {
                width: '80vw',
              }
            : {
                width: 'auto',
              }),
          ...cssProps,
        }}
        fullWidth={fullWidth || !showOnDesktop}
        required={required}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiList-root': {
                paddingTop: '0',
                paddingBottom: '0',
              },
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          // Must be nulled out when specifying anchorOrigin.vertical per [issue comment](https://github.com/mui-org/material-ui/issues/7961#issuecomment-326109979)
          // TODO: Verify the nulling anchor element provides an accurate drop down anchorage
          // getContentAnchorEl: null,
          // TODO: Pass selectOptions styling to the MenuProps
          // classes: {
          //   list: classes.selectOptions,
          // },
          /** Options: {
            background: theme.palette.customBackground.dropDownMenu,
            '&:focus': {
              background: theme.palette.customBackground.selectedMenuItem,
            },
            [theme.breakpoints.down('sm')]: {
              whiteSpace: 'normal',
              overflow: 'visible',
            }, 
          }*/
        }}
        renderValue={
          multiple
            ? (selected) => {
                return (
                  <Typography
                    variant="button"
                    color={theme.palette.textOrIcon.dropDownOptions}
                  >
                    {(selected as string[]).length === 0
                      ? ''
                      : (selected as string[]).join(',')}
                  </Typography>
                )
              }
            : (value) => {
                return (
                  <Typography
                    variant="button"
                    color={theme.palette.textOrIcon.dropDownOptions}
                    sx={{ ...menuItemsAndRenderProps }}
                  >
                    {`${value}`}
                  </Typography>
                )
              }
        }
        error={error}
        inputProps={{
          disabled: disabled,
          'data-testid': 'content-input',
        }}
        disabled={disabled}
        multiple={multiple}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.id}
            value={option.name}
            data-id={option.id}
            sx={{
              background: `${theme.palette.customBackground.dropDownMenu} !important`,
              '&:hover': { background: theme.palette.grey[300] },
              '&:focus': {
                background: `${theme.palette.customBackground.dropDownMenu} !important`,
              },
              '&.Mui-selected.MuiDisabled': {
                background: `${theme.palette.customBackground.selectedMenuItem} !important`,
                opacity: 1,
              },
              '&.Mui-selected': {
                background: `${theme.palette.customBackground.selectedMenuItem} !important`,
                '&:hover': {
                  background: `${theme.palette.customBackground.hoverSelectedMenuItem} !important`,
                },
              },
              sm: {
                whiteSpace: 'normal',
                overflow: 'visible',
              },
              margin: 0,
            }}
            disabled={option.disabled}
            /** Since we choose to close the modal on blur, handle the scenario where slow, methodical clickers actually select something */
            onMouseDown={(event) => {
              event.preventDefault()
              return handleInputChange(option.name, option.id)
            }}
          >
            <Typography
              variant="button"
              color={theme.palette.textOrIcon.dropDownOptions}
              sx={{ ...menuItemsAndRenderProps }}
            >
              {option.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default DropDown
