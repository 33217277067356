import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { TextButtonVariant } from '../Buttons/TextButton'
import { AddressBox, EditAddressButton } from './AddressSelect'
import { useTranslation } from 'react-i18next'
import { Address } from '../../swagger'

export enum InvalidAddressVariant {
  Unverified,
  Denied,
}

const InvalidAddress: React.FC<{
  enteredAddress: Address
  variant: InvalidAddressVariant
  onEditAddressClick?: () => void
  withEditAddressButton?: boolean
}> = ({
  enteredAddress,
  variant,
  onEditAddressClick,
  withEditAddressButton = true,
}) => {
  const { t } = useTranslation()

  const isUnverifiedVariant = variant === InvalidAddressVariant.Unverified

  const message = isUnverifiedVariant
    ? t(
        'Address.Invalid.UnverifiedMessage',
        'We could not verify the address you entered. If you are sure the address is correct, select “Use Anyway” to continue.'
      )
    : t(
        'Address.Invalid.DeniedMessage',
        'We could not get the geographical coordinates for your address. Please verify your address was entered correctly.'
      )

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth={500}
      alignItems="center"
      ml="auto"
      mr="auto"
    >
      <Box color="primary.main" textAlign="center" mb={3}>
        <Typography variant="subtitle2">{message}</Typography>
      </Box>
      <Box textAlign="center" color="primary.main">
        <Typography variant="subtitle1">
          {t('Address.YouEntered', 'You Entered')}
        </Typography>
      </Box>
      <Box minWidth={{ xs: '100%', md: 340 }}>
        <AddressBox address={enteredAddress} />
      </Box>
      {isUnverifiedVariant && withEditAddressButton && (
        <EditAddressButton
          id="useAnyway"
          variant={TextButtonVariant.EditAddress}
          onClick={onEditAddressClick}
        />
      )}
    </Box>
  )
}

export default InvalidAddress
