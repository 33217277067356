import React from 'react'
import {
  TranscriptFormCard,
  TranscriptFormCardVariant,
} from './TranscriptFormCard'
import { Page } from '../Elements/PageMargins'
import { useUnmountEffect } from '../../hooks/useUnmountEffect'
import { useTranscriptContext } from '../Context/TranscriptContext'
import { useAuth } from '../Routes/AuthProvider'
import NoPermission from '../Elements/NoPermission'
import { useTranslation } from 'react-i18next'

export const AddTranscript: React.FC = () => {
  const auth = useAuth()
  const { t } = useTranslation()

  const canCreateTranscript =
    auth.permissionAbility.can('create', 'Transcript') ||
    auth.userDetails.actingAs === 'parent'

  const { cancelTranscriptEdit } = useTranscriptContext()
  useUnmountEffect(() => {
    cancelTranscriptEdit()
  })

  return (
    <Page>
      {canCreateTranscript ? (
        <TranscriptFormCard variant={TranscriptFormCardVariant.Add} />
      ) : (
        <NoPermission
          content={t(
            'GeneralError.NoPermission',
            'Sorry, you do not have permission to add a transcript.'
          )}
        />
      )}
    </Page>
  )
}

export default AddTranscript
