import React from 'react'
import { useTheme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TableFooterPagination from '../Pagination/TableFooterPagination'
import { useTranslation } from 'react-i18next'
import TableHeader from '../Table/TableHeader'
import UserAvatar from '../Elements/UserAvatar'
import fullName from '../../utils/fullName'
import { UserAccountListing, UserListingRoleAssignment } from '../../swagger'
import { useNavigate } from 'react-router'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import NoResultsFound from '../Table/NoResultsFound'
import { styled } from '@mui/system'
import TableHeaders from '../Interfaces/TableHeaders'
import { Typography } from '@mui/material'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

interface TeamTableProps {
  support: UserAccountListing[]
  /**
   * Update the information surrounding user selection: userId, full name of user, and actorKey of the row in our downline.
   * The presence of this method also lets us know if this table is a 'People I Support' or 'My Support' variant.
   */
  updateUserSelection?: (opts: {
    userKey: number
    name: string
    actorKey: number
    role: string
  }) => void
  ariaLabelledBy?: string
  textTypesForRoles: (role: UserListingRoleAssignment) => React.CSSProperties
  totalCount?: number
  pageSize?: number
  isPaginated?: boolean
  isClickable?: boolean
  handleRowsPerPage: (rowAmount: number) => void
  onPageChange: (newPage: number) => void
  tableFooterPage: number
}

const baseTen = 10

export const TeamTable: React.FC<TeamTableProps> = ({
  support,
  updateUserSelection,
  ariaLabelledBy,
  textTypesForRoles,
  totalCount,
  pageSize,
  isPaginated,
  isClickable = true,
  handleRowsPerPage,
  onPageChange,
  tableFooterPage,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChange(newPage)
  }

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleRowsPerPage(parseInt(event.target.value, baseTen))
  }

  const viewProfileButtonHeader: TableHeaders = {
    id: 'view-profile-button',
    label: '',
    align: 'left',
  }

  const tableHeaders: TableHeaders[] = [
    {
      id: 'avatar',
      label: '',
      align: 'left',
    },
    {
      label: t('Team.Header.FullName', 'Full Name'),
      align: 'left',
    },
    {
      label: t('Team.Header.Email', 'Email'),
      align: 'left',
    },
    {
      label: t('Team.Header.Role', 'Role'),
      align: 'left',
    },
    //Show role start and end date for people I Support table
    ...(ariaLabelledBy && ariaLabelledBy === 'peopleISupport'
      ? ([
          {
            label: t('Team.Header.RoleStartDate', 'Start Date'),
            align: 'left',
          },
          {
            label: t('Team.Header.RoleEndDate', 'End Date'),
            align: 'left',
          },
        ] as TableHeaders[])
      : []),
  ]

  const navigate = useNavigate()

  const onClickRow = (
    row: UserAccountListing,
    role: UserListingRoleAssignment
  ) => {
    // Reset page to first when clicking a new actor/user
    updateUserSelection?.({
      userKey: row.userKey,
      name: fullName({
        firstName: row.firstName ?? '',
        lastName: row.lastName ?? '',
      }),
      actorKey: role.actorKey ?? -1,
      role: role.name,
    })
  }
  const onClickShowProfile = (row: UserAccountListing) => {
    navigate(`/admin/user-accounts/${row.userKey}`, {
      /** Navigation options, as applicable */
    })
  }

  const supportRows = support.flatMap((row) =>
    row.roles.map((role) => (
      <TableRow
        sx={{
          '&:hover': {
            cursor: isClickable ? 'pointer' : 'auto',
          },
        }}
        key={`${row.userKey}-${role.actorKey}`}
        onClick={() => onClickRow(row, role)}
      >
        <TableCell>
          <UserAvatar
            familyName={fullName({
              firstName: row.firstName,
              lastName: row.lastName,
            })}
          />
        </TableCell>
        <StyledTableCell>
          <Typography variant="body1" component="p">
            {`${row.firstName} ${row.lastName}`}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography variant="body1" component="p">
            {row.username}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography
            style={textTypesForRoles(role)}
            variant="body1"
            component="p"
          >
            {role.name}
          </Typography>
        </StyledTableCell>
        {ariaLabelledBy === 'peopleISupport' && (
          <>
            <StyledTableCell>
              <Typography
                style={textTypesForRoles(role)}
                variant="body1"
                component="p"
              >
                {dateToSlashStringReinterpretedAsLocal(role.validFrom)}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography
                style={textTypesForRoles(role)}
                variant="body1"
                component="p"
              >
                {dateToSlashStringReinterpretedAsLocal(role.validTo)}
              </Typography>
            </StyledTableCell>
          </>
        )}
        {updateUserSelection && (
          <StyledTableCell>
            <TextButton
              id="view-profile"
              onClick={() => onClickShowProfile(row)}
              variant={TextButtonVariant.ViewProfile}
              fullWidth
            />
          </StyledTableCell>
        )}
      </TableRow>
    ))
  )

  return (
    <TableContainer
      component={Paper}
      sx={{
        margin: theme.spacing(3, 0),
      }}
    >
      <Table aria-labelledby={ariaLabelledBy ?? ''}>
        <TableHead>
          <TableHeader
            tableHeaders={
              updateUserSelection
                ? [...tableHeaders, viewProfileButtonHeader]
                : tableHeaders
            }
          />
        </TableHead>
        <TableBody>
          {supportRows.length > 0 ? supportRows : <NoResultsFound />}
        </TableBody>
        {!!totalCount && !!pageSize && isPaginated && totalCount > pageSize && (
          <TableFooterPagination
            label={t('Team.TablePagination.ViewAll', 'All')}
            count={totalCount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            page={tableFooterPage}
            rowsPerPage={pageSize}
            colSpan={tableHeaders.length}
          />
        )}
      </Table>
    </TableContainer>
  )
}

export default TeamTable
