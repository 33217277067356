import React from 'react'
import { useTheme } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Hidden from '@mui/material/Hidden'
import { useTranslation } from 'react-i18next'
import Header, { HeaderVariant } from '../Elements/Header'
import { ProgramType } from '../../swagger'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { FeeInformation, ProgramFeeNames } from './RegionFormCard'
import { useFeeLabelMap } from '../../hooks/useFeeLabelMap'
import { feeStructure } from '../../mockData/MockRegionFees'
//keeping as reference
// const useStyles = makeStyles((theme: Theme) => {
//   return createStyles({
//     regionFormCard: {
//       padding: theme.spacing(3, 4, 4),
//       maxWidth: 1200,
//       color: theme.palette.primary.main,
//       marginBottom: theme.spacing(3),
//     },
//     accordionDetails: {
//       padding: theme.spacing(1, 1, 5),
//     },
//     enrollmentMessage: {
//       color: theme.palette.textOrIcon.tableHeader,
//     },
//   })
// })

export const sortFeeByFeeLabel = (
  feeStructure: feeStructure[]
): feeStructure[] => {
  const expectedOrder = [
    'Additional Students Licensing (Application)',
    'Application Fee',
    'Enrollment Fee',
    'Facility Fee First Student',
    'Facility Fee Additional Students',
    'First Student Licensing (Application)',
    'Local Fee First Student',
    'Local Fee Additional Students',
    'Misc Fee First Student',
    'Misc Fee Additional Students',
    'Multi-Student Application Fee Discount',
    'Semester One First Student Licensing Fee',
    'Semester One Additional Students Licensing Fee',
    'Semester Two First Student Licensing Fee',
    'Semester Two Additional Students Licensing Fee',
    'Percentage of Tuition Collected by Tutor',
    'Supply Fee First Student',
    'Supply Fee Additional Students',
    'Tuition Fee',
  ]

  const sortedFees: feeStructure[] = new Array(expectedOrder.length)

  feeStructure.forEach((fee) => {
    const index = expectedOrder.indexOf(fee.feeLabel)
    sortedFees.splice(index, 1, fee)
  })

  return sortedFees
}

const RegionFeeStructureCard: React.FC<{
  programTypes: string[]
  feeTypes: string[]
  isFieldDisabled: boolean
  currencyCode: string
  handleCurrencyCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  scribblersFeeInformation: FeeInformation
  foundationsFeeInformation: FeeInformation
  essentialsFeeInformation: FeeInformation
  challengeAFeeInformation: FeeInformation
  challengeBFeeInformation: FeeInformation
  challengeIFeeInformation: FeeInformation
  challengeIIFeeInformation: FeeInformation
  challengeIIIFeeInformation: FeeInformation
  challengeIVFeeInformation: FeeInformation
  handleScribblersFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleFoundationsFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleEssentialsFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChallengeAFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChallengeBFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChallengeIFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChallengeIIFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChallengeIIIFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChallengeIVFeeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({
  programTypes,
  feeTypes,
  isFieldDisabled,
  currencyCode,
  handleCurrencyCodeChange,
  scribblersFeeInformation,
  foundationsFeeInformation,
  essentialsFeeInformation,
  challengeAFeeInformation,
  challengeBFeeInformation,
  challengeIFeeInformation,
  challengeIIFeeInformation,
  challengeIIIFeeInformation,
  challengeIVFeeInformation,
  handleScribblersFeeChange,
  handleFoundationsFeeChange,
  handleEssentialsFeeChange,
  handleChallengeAFeeChange,
  handleChallengeBFeeChange,
  handleChallengeIFeeChange,
  handleChallengeIIFeeChange,
  handleChallengeIIIFeeChange,
  handleChallengeIVFeeChange,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const programStateMap: {
    [key: string]: {
      value: FeeInformation
      handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    }
  } = {
    [ProgramType.Scribblers]: {
      value: scribblersFeeInformation,
      handleChange: handleScribblersFeeChange,
    },
    [ProgramType.Foundations]: {
      value: foundationsFeeInformation,
      handleChange: handleFoundationsFeeChange,
    },
    [ProgramType.Essentials]: {
      value: essentialsFeeInformation,
      handleChange: handleEssentialsFeeChange,
    },
    [ProgramType.ChallengeA]: {
      value: challengeAFeeInformation,
      handleChange: handleChallengeAFeeChange,
    },
    [ProgramType.ChallengeB]: {
      value: challengeBFeeInformation,
      handleChange: handleChallengeBFeeChange,
    },
    [ProgramType.ChallengeI]: {
      value: challengeIFeeInformation,
      handleChange: handleChallengeIFeeChange,
    },
    [ProgramType.ChallengeIi]: {
      value: challengeIIFeeInformation,
      handleChange: handleChallengeIIFeeChange,
    },
    [ProgramType.ChallengeIii]: {
      value: challengeIIIFeeInformation,
      handleChange: handleChallengeIIIFeeChange,
    },
    [ProgramType.ChallengeIv]: {
      value: challengeIVFeeInformation,
      handleChange: handleChallengeIVFeeChange,
    },
  }

  const programFeeLabelMap = useFeeLabelMap(t)

  const programFeeStructure = programTypes.map((programType) => {
    return {
      programType,
      handleChange: programStateMap[programType].handleChange,
      feeStructures: feeTypes.map((feeType) => {
        return {
          feeType,
          feeLabel: programFeeLabelMap[feeType],
          minimumAmount: programStateMap[programType].value[
            `${feeType}-minimum`
          ] as number,
          defaultTotalAmount: programStateMap[programType].value[
            `${feeType}-default`
          ] as number,
          allowEditTotal: programStateMap[programType].value[
            `${feeType}-toggle`
          ] as boolean,
          isValid: programStateMap[programType].value[
            `${feeType}-isValid`
          ] as boolean,
          ...(feeType === ProgramFeeNames.SubLicensedTutorPercentage
            ? {
                isValidPercentage: programStateMap[programType].value[
                  `${feeType}-isValidPercentage`
                ] as boolean,
              }
            : {}),
        }
      }),
    }
  })

  return (
    <Card
      sx={{
        padding: theme.spacing(3, 4, 4),
        maxWidth: 1200,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(3),
      }}
    >
      <section aria-labelledby="regionFeeHeader">
        <Header
          id="regionFeeHeader"
          headerName={t(
            'Regions.RegionForm.Header.FeeStructures',
            'Fee Structures'
          )}
          component="h2"
          variant={HeaderVariant.Card}
        />
        <form noValidate autoComplete="off" aria-labelledby="regionFormHeader">
          <Box mb={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="regionCurrencyField"
                  name="currencyCode"
                  label={t(
                    'Regions.RegionForm.FormField.CurrencyCode',
                    'Currency Code'
                  )}
                  fullWidth
                  variant="filled"
                  helperText={t(
                    'Regions.RegionForm.FormField.CurrencyCode.HelperText',
                    'Defaults to USD'
                  )}
                  disabled={isFieldDisabled}
                  value={currencyCode}
                  onChange={handleCurrencyCodeChange}
                />
              </Grid>
            </Grid>
          </Box>
          {programFeeStructure.map(
            ({ programType, feeStructures, handleChange }) => {
              return (
                <Accordion key={programType} defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${programType}-content`}
                    id={`${programType}-header`}
                  >
                    <Typography variant="button" component="p">
                      {programType}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: theme.spacing(1, 1, 5),
                    }}
                  >
                    <Grid direction="column" container spacing={3}>
                      <Hidden smDown>
                        <Grid container item spacing={3}>
                          <Grid item xs={12} md={2} />
                          <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                              {t(
                                'Regions.RegionForm.ProgramFee.MinimumAmount',
                                'Minimum Amount'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Typography variant="body1">
                              {t(
                                'Regions.RegionForm.ProgramFee.DefaultAmount',
                                'Default Amount'
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography variant="body1">
                              {t(
                                'Regions.RegionForm.ProgramFee.AllowAdjustAmounts',
                                'Allow Programs to Adjust Amounts'
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Hidden>

                      {feeStructures.map(
                        ({
                          feeType,
                          feeLabel,
                          minimumAmount,
                          defaultTotalAmount,
                          allowEditTotal,
                          isValid,
                          isValidPercentage,
                        }) => {
                          return (
                            <MemoizedFeeFields
                              key={`${programType}-${feeType}`}
                              isFieldDisabled={isFieldDisabled}
                              programType={programType}
                              feeType={feeType}
                              feeLabel={feeLabel}
                              minimumAmount={minimumAmount}
                              defaultTotalAmount={defaultTotalAmount}
                              allowEditTotal={allowEditTotal}
                              isValid={isValid}
                              isValidPercentage={isValidPercentage}
                              handleFeeInfoChange={handleChange}
                            />
                          )
                        }
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            }
          )}
        </form>
      </section>
    </Card>
  )
}

const FeeFields: React.FC<{
  isFieldDisabled: boolean
  programType: string
  feeType: string
  feeLabel: string
  minimumAmount: number
  defaultTotalAmount: number
  allowEditTotal: boolean
  isValid: boolean
  isValidPercentage?: boolean
  handleFeeInfoChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = ({
  isFieldDisabled,
  programType,
  feeType,
  feeLabel,
  minimumAmount,
  defaultTotalAmount,
  allowEditTotal,
  isValid,
  isValidPercentage,
  handleFeeInfoChange,
}) => {
  const { t } = useTranslation()
  const isMobileOrSmaller = !useShowOnDesktop()
  const theme = useTheme()

  const minLabel = t('Regions.RegionForm.ProgramFee.Minimum', 'Minimum')
  const defaultLabel = t('Regions.RegionForm.ProgramFee.Default', 'Default')

  const minimumFeeValidationMessage = t(
    'Regions.RegionForm.ValidationMessage.MinimumProgramFee',
    'Minimum must be less than or equal to the default amount'
  )

  const defaultFeeValidationMessage = t(
    'Regions.RegionForm.ValidationMessage.DefaultProgramFee',
    'Default must be greater than or equal to the minimum amount'
  )

  const invalidPercentageMessage = t(
    'Regions.RegionForm.ValidationMessage.InvalidPercentage',
    'Amount must be between 0 and 100'
  )

  const doNotShowTogglesForTheseFees = [
    ProgramFeeNames.Application,
    ProgramFeeNames.Enrollment,
    ProgramFeeNames.SemesterOneLicensingFee,
    ProgramFeeNames.DiscountSemesterOneLicensingFee,
    ProgramFeeNames.SemesterTwoLicensingFee,
    ProgramFeeNames.DiscountSemesterTwoLicensingFee,
  ].some((it) => it === feeType)

  return (
    <Grid container item alignItems="center" spacing={3}>
      <Grid item xs={12} md={2}>
        <Typography variant="body1">{feeLabel}</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          id={`${feeType}-minimum`}
          name={`${feeType}-minimum`}
          inputProps={{
            'aria-label': `${minLabel} ${feeLabel}`,
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="filled"
          error={!isValid || isValidPercentage === false}
          helperText={
            !isValid
              ? minimumFeeValidationMessage
              : isValidPercentage === false
              ? invalidPercentageMessage
              : null
          }
          disabled={isFieldDisabled}
          value={minimumAmount}
          onChange={handleFeeInfoChange}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          id={`${feeType}-default`}
          name={`${feeType}-default`}
          inputProps={{
            'aria-label': `${defaultLabel} ${feeLabel}`,
          }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="filled"
          error={!isValid || isValidPercentage === false}
          helperText={
            !isValid
              ? defaultFeeValidationMessage
              : isValidPercentage === false
              ? invalidPercentageMessage
              : null
          }
          disabled={isFieldDisabled}
          value={defaultTotalAmount}
          onChange={handleFeeInfoChange}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {doNotShowTogglesForTheseFees ? (
          <Typography
            variant="body2"
            color={theme.palette.textOrIcon.tableHeader}
          >
            {`${feeLabel} ${t(
              'Regions.RegionForm.NoToggleMessage',
              'is in USD'
            )}`}
          </Typography>
        ) : (
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                inputProps={{
                  'aria-label': `Allow program to adjust amounts for ${programType} ${feeType} fee`,
                }}
                name={`${feeType}-toggle`}
                checked={allowEditTotal}
                disabled={isFieldDisabled}
                onChange={handleFeeInfoChange}
              />
            }
            label={
              isMobileOrSmaller
                ? t(
                    'Regions.RegionForm.AllowProgramToAdjustAmounts',
                    'Allow Program to Adjust Amounts'
                  )
                : ''
            }
          />
        )}
      </Grid>
    </Grid>
  )
}

const MemoizedFeeFields = React.memo(FeeFields)

export default React.memo(RegionFeeStructureCard)
