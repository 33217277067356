import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Registrant, UpdateRegistrantRequestBody } from '../../swagger'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import BasicModal from './BasicModal'
import { Box, TextField } from '@mui/material'
import { TextButtonVariant } from '../Buttons/TextButton'
import useLoadingContext from '../../hooks/useLoadingContext'
import { FieldValidity } from '../Interfaces/FieldValidity'
import { validateEmail } from '../../helpers/validateEmail'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import { registrantsApi, extractedErrorObject } from '../../api/swagger'
import { LoadingContext } from '../Context/LoadingContext'

interface RegistrantModalProps {
  isOpen: boolean
  onClose: (options?: { refetch?: boolean }) => void
  initialRegistrant?: Pick<
    Registrant,
    'id' | 'firstName' | 'lastName' | 'email' | 'phone' | 'attending'
  >
  primaryButtonLoadingId: string
}

const emptyRegistrant: RegistrantModalProps['initialRegistrant'] = {
  id: NaN,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  attending: false,
}

const initialFormValidity: {
  firstName: FieldValidity
  lastName: FieldValidity
  email: FieldValidity
  phone: FieldValidity
} = {
  firstName: { input: true },
  lastName: { input: true },
  email: { input: true },
  phone: { input: true },
}

export const RegistrantModal: React.FC<RegistrantModalProps> = ({
  isOpen,
  onClose,
  primaryButtonLoadingId,
  initialRegistrant,
}) => {
  const { t } = useTranslation()
  const [registrant, setRegistrant] = useState(emptyRegistrant)
  const [formValidity, setFormValidity] = useState(initialFormValidity)
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const { addLoadingIds } = React.useContext(LoadingContext)

  const { firstName, lastName, email } = registrant

  const dialogTitle = t(
    'RegistrantModal.Title.EditRegistrant',
    'Edit Registrant'
  )

  const resetModalState = () => {
    setRegistrant(emptyRegistrant)
    setFormValidity(initialFormValidity)
  }

  // Initialize our local state with the passed in initialRegistrant
  useEffect(() => {
    if (initialRegistrant) {
      setRegistrant(initialRegistrant)
    }
  }, [initialRegistrant])

  const checkFieldValidity = () => {
    const isValidEmail = !!email && validateEmail(email)
    setFormValidity((prevState) => ({
      ...prevState,
      firstName: { input: !!firstName },
      lastName: { input: !!lastName },
      email: {
        input: isValidEmail,
      },
    }))

    return {
      areAllFieldsValid: !!firstName && !!lastName && isValidEmail,
    }
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    addLoadingIds([primaryButtonLoadingId])
  }

  const handlePrimaryButtonClick = async () => {
    const { areAllFieldsValid } = checkFieldValidity()

    if (areAllFieldsValid) {
      await handleUpdateRegistrant({
        ...registrant,
        attending: !!registrant?.attending,
      })
    }
  }

  const handleUpdateRegistrant = async ({
    firstName,
    lastName,
    email,
    phone,
    attending,
  }: Pick<
    UpdateRegistrantRequestBody,
    'firstName' | 'lastName' | 'email' | 'phone' | 'attending'
  >) => {
    try {
      await registrantsApi.updateRegistrant({
        body: {
          firstName,
          lastName,
          email,
          phone,
          attending,
          registrantKey: Number(initialRegistrant?.id),
        },
      })

      setSnackbarState?.(true)
      setSnackbarMessage?.(
        t(
          'RegistrantModal.UpdateRegistrant.SuccessMessage',
          'Registrant successfully updated.'
        )
      )
      setSnackbarSeverity?.(SnackbarSeverity.Success)
      onClose({ refetch: true })
    } catch (e) {
      const errorMessage = t(
        'RegistrantModal.UpdateRegistrant.UnknownErrorMessage',
        'Something went wrong while updating the registrant.'
      )
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: handlePrimaryButtonClick,
    loadingId: primaryButtonLoadingId,
  })

  const handleRegistrantChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    !!registrant &&
      setRegistrant({
        ...registrant,
        [event.target.name]: event.target.value,
      })
  }

  const disablePrimaryButton = !(
    !!registrant?.firstName &&
    !!registrant?.lastName &&
    !!registrant?.email
  )

  const DialogContent = () => {
    return (
      <Box>
        <TextField
          id="firstName"
          name="firstName"
          label={t('RegistrantModal.Label.FirstName', 'First Name')}
          variant="filled"
          value={registrant?.firstName ?? ''}
          onChange={handleRegistrantChange}
          fullWidth
          margin="normal"
          error={!formValidity.firstName.input}
          helperText={
            !formValidity.firstName.input &&
            t('RegistrantModal.Error.FirstName', 'First Name is required')
          }
        />

        <TextField
          id="lastName"
          name="lastName"
          label={t('RegistrantModal.Label.LastName', 'Last Name')}
          variant="filled"
          value={registrant?.lastName ?? ''}
          onChange={handleRegistrantChange}
          fullWidth
          margin="normal"
          error={!formValidity.lastName.input}
          helperText={
            !formValidity.lastName.input &&
            t('RegistrantModal.Error.LastName', 'Last Name is required')
          }
        />

        <TextField
          id="email"
          name="email"
          label={t('RegistrantModal.Label.Email', 'Email')}
          variant="filled"
          type="email"
          value={registrant?.email ?? ''}
          onChange={handleRegistrantChange}
          fullWidth
          margin="normal"
          error={!formValidity.email.input}
          helperText={
            !formValidity.email.input &&
            t('RegistrantModal.Error.Email', 'A valid Email is required')
          }
        />

        <TextField
          id="phone"
          name="phone"
          label={t('RegistrantModal.Label.Phone', 'Phone Number')}
          variant="filled"
          value={registrant?.phone ?? ''}
          onChange={handleRegistrantChange}
          fullWidth
          margin="normal"
        />
      </Box>
    )
  }

  return (
    <BasicModal
      isOpen={isOpen}
      handleFormSubmit={handleFormSubmit}
      disableForm
      maxWidth="xs"
      dialogContent={DialogContent()}
      dialogActions={
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.Save}
          secondaryButtonLabel={TextButtonVariant.Cancel}
          secondaryClick={onClose}
          primaryButtonLoadingId={primaryButtonLoadingId}
          useBaseButton
          disablePrimaryButton={disablePrimaryButton}
        />
      }
      dialogTitle={dialogTitle}
      afterClose={resetModalState}
    />
  )
}

export default RegistrantModal
