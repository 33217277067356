import React from 'react'
import { Box, Card, CardActionArea, Typography, useTheme } from '@mui/material'
import CheckCircle from '@mui/icons-material/CheckCircle'

interface PaymentSelectionProps {
  header: string
  onClick: () => void
  subheader?: string
  isSelected: boolean
  image?: JSX.Element
}

const PaymentSelection: React.FC<PaymentSelectionProps> = ({
  header,
  subheader,
  onClick,
  isSelected,
  image,
}) => {
  const theme = useTheme()
  return (
    <CardActionArea onClick={onClick} name={header} id={header}>
      <Card
        sx={{
          border: '1px solid',
          borderColor: theme.palette.borderColor.mediumEmphasis,
          borderRadius: theme.spacing(1),
          boxSizing: 'border-box',
          height: '120px',
          ...(isSelected && {
            borderColor: theme.palette.borderColor.green,
            backgroundColor: theme.palette.customBackground.greenBackground,
          }),
        }}
        raised={false}
        elevation={0}
      >
        <Box display="flex" alignItems="center" height="100%">
          {!!image && <Box mx={{ xs: 1, lg: 3 }}>{image}</Box>}
          <Box p={{ xs: 1, lg: 3 }}>
            <Typography
              component="p"
              variant="subtitle1"
              align="left"
              color={theme.palette.primary.main}
              fontWeight={400}
            >
              {header}
            </Typography>
            {!!subheader && (
              <Typography component="p" variant="caption" align="left">
                {subheader}
              </Typography>
            )}
          </Box>
          {isSelected && (
            <CheckCircle
              sx={{
                color: theme.palette.borderColor.green,
                position: 'absolute',
                right: '16px',
                top: '16px',
              }}
              data-testid="selection"
            />
          )}
        </Box>
      </Card>
    </CardActionArea>
  )
}

export default PaymentSelection
