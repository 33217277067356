import React from 'react'
import { useTranslation } from 'react-i18next'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import ConfirmWithButtonsModal from './ConfirmWithButtonsModal'

interface TranscriptConfirmDeleteModalProps {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
}

const TranscriptConfirmDeleteModal: React.FC<
  TranscriptConfirmDeleteModalProps
> = ({ isOpen, onConfirm, onCancel }) => {
  const { t } = useTranslation()
  return (
    <ConfirmWithButtonsModal
      isOpen={isOpen}
      title={t(
        'Transcript.TranscriptFormCard.DeleteModal.Title',
        'Delete Transcript'
      )}
      question={t(
        'Transcript.TranscriptFormCard.DeleteModal.Question',
        'Are you sure you want to delete this transcript?'
      )}
      questionProps={{ variant: 'body1', align: 'center' }}
      confirmVariant={ContainedButtonVariant.YesDelete}
      cancelVariant={TextButtonVariant.NoCancel}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default TranscriptConfirmDeleteModal
