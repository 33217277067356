import React, { useMemo } from 'react'
import { AgreementTemplateFormField } from '../../swagger'
import DynamicFormField from '../Elements/DynamicFormField'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'

interface FormDataProps {
  formData?: AgreementTemplateFormField[]
  formDataStates: DynamicFieldStates
  updateFormDataValueForParent: (
    name: string,
    state: string,
    required: boolean,
    isOverMaxLength: boolean,
    isUnderMinLength: boolean
  ) => void
}

export const FormData: React.FC<FormDataProps> = ({
  formData,
  formDataStates,
  updateFormDataValueForParent,
}) => {
  const formDataFields = useMemo(() => {
    if (!!formData) {
      return formData.map((data) => {
        const name = !!data.displayName ? data.displayName : data.inputName
        return (
          <DynamicFormField
            key={data.inputName}
            id={data.inputName}
            label={name}
            name={name}
            dropdownOptions={data.dropdownSelectOptions?.map((it) => {
              return { id: it, name: it }
            })}
            updateForParent={updateFormDataValueForParent}
            value={formDataStates?.[name]?.state ?? ''}
            isValid={formDataStates?.[name]?.isValid}
            validationMessage={formDataStates?.[name]?.validationMessage}
            required={data.required}
            inputType={data.inputType}
            maxLength={
              data.maxTextLength !== -1 ? data.maxTextLength : undefined
            }
            minLength={
              data.minTextLength !== -1 ? data.minTextLength : undefined
            }
          />
        )
      })
    } else {
      return []
    }
  }, [formData, updateFormDataValueForParent, formDataStates])

  return <>{formDataFields?.map((it) => it)}</>
}

export default FormData
