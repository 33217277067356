import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import './i18n'
import App from './App'

/**
 * Switching from ReactDom.render to ReactDOMClient.createRoot
 *
 * Ref: https://github.com/reactwg/react-18/discussions/5
 */
const container = document.getElementById('root') as HTMLElement

const root = ReactDOMClient.createRoot(container)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
