import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { useTranslation } from 'react-i18next'
import { Address } from '../../swagger'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'

interface AddressSelectFormControlLabelProps {
  addressStr: string
  selection: string
  validAddress: Address
}

const RadioShack = styled(Radio)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} !important`,
  color: '#D7D5D5',
}))

const FormattedAddressLabel = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  color: theme.palette.primary.main,
}))

const AddressContainer = styled(Box)(({ theme }) => ({
  paddingLeft: `${theme.spacing(3)} !important`,
  paddingRight: `${theme.spacing(3)} !important`,
  marginBottom: theme.spacing(2),
  width: '100%',
  margin: theme.spacing(1, 0),
  padding: theme.spacing(1.5, 0),
  border: '1px solid #D7D5D5',
  borderRadius: 6,
}))

const AddressSelectFormControlLabel: React.FC<
  AddressSelectFormControlLabelProps
> = ({ addressStr, selection, validAddress }) => {
  const theme = useTheme()
  return (
    <FormControlLabel
      key={addressStr}
      value={addressStr}
      control={<RadioShack />}
      label={<FormattedAddress address={validAddress} />}
      sx={{
        margin: theme.spacing(1, 0),
        padding: theme.spacing(1.5, 0),
        border: '1px solid #D7D5D5',
        borderRadius: 6,
        ...(selection === addressStr && {
          backgroundColor: theme.palette.secondary.light,
          borderColor: theme.palette.secondary.main,
        }),
      }}
    />
  )
}

export const EditAddressButton = styled(TextButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  padding: theme.spacing(1, 3),
  margin: 0,
}))

const AddressSelect: React.FC<{
  enteredAddress: Address
  validAddresses: Address[]
  selection: string
  onSelectionChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onEditAddressClick: () => void
  withEditAddressButton?: boolean
}> = ({
  enteredAddress,
  validAddresses,
  onEditAddressClick,
  selection,
  onSelectionChange,
  withEditAddressButton = true,
}) => {
  const { t } = useTranslation()
  const showOnDesktop = useShowOnDesktop()

  return (
    <Grid container spacing={showOnDesktop ? 4 : 0}>
      <Grid item xs={12} md={6}>
        <Box display="flex" flexDirection="column">
          <Box textAlign="center" color="primary.main">
            <Typography variant="subtitle1">
              {t('Address.YouEntered', 'You Entered')}
            </Typography>
          </Box>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="entered-address"
              name="entered-address"
              value={selection}
              onChange={onSelectionChange}
            >
              <AddressSelectFormControlLabel
                addressStr={JSON.stringify(enteredAddress)}
                selection={selection}
                validAddress={enteredAddress}
              />
            </RadioGroup>
          </FormControl>
          {withEditAddressButton && (
            <EditAddressButton
              id="editAddress"
              variant={TextButtonVariant.EditAddress}
              onClick={onEditAddressClick}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box display="flex" flexDirection="column">
          <Box textAlign="center" color="primary.main">
            <Typography variant="subtitle1">
              {t('Address.ChooseOneInstead', 'Use This Instead')}
            </Typography>
          </Box>
          <FormControl component="fieldset">
            {validAddresses.map((validAddress, key) => {
              const addressStr = JSON.stringify(validAddress)
              return (
                <RadioGroup
                  aria-label="valid-addresses"
                  name="valid-addresses"
                  value={selection}
                  onChange={onSelectionChange}
                  key={key}
                >
                  <AddressSelectFormControlLabel
                    addressStr={addressStr}
                    selection={selection}
                    validAddress={validAddress}
                  />
                </RadioGroup>
              )
            })}
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  )
}

const FormattedAddress: React.FC<{ address: Address }> = ({ address }) => {
  const {
    streetAddress1,
    streetAddress2 = '',
    city,
    state = '',
    zip = '',
  } = address
  return (
    <FormattedAddressLabel variant="subtitle1">
      {streetAddress1}
      <br />
      {streetAddress2 && (
        <>
          {' '}
          {streetAddress2}
          <br />{' '}
        </>
      )}
      {`${city}, ${state} ${zip}`}
    </FormattedAddressLabel>
  )
}

export const AddressBox: React.FC<{ address: Address }> = ({ address }) => {
  return (
    <AddressContainer>
      <FormattedAddress address={address} />
    </AddressContainer>
  )
}

export default AddressSelect
