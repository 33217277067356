import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyPage from '../Elements/EmptyPage'

interface EmptyInvoiceProps {
  isLoading: boolean
}

const EmptyInvoice: React.FC<EmptyInvoiceProps> = (props) => {
  const { t } = useTranslation()

  return (
    <EmptyPage
      {...props}
      message={
        <Typography variant="subtitle1" component="p">
          {t(
            'Invoice.EmptyState.Instructions',
            'No Invoice information available.'
          )}
        </Typography>
      }
    />
  )
}

export default EmptyInvoice
