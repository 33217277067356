import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyPage from '../Elements/EmptyPage'
import { useAuth } from '../Routes/AuthProvider'

export const EmptyProgram: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const { permissionAbility } = useAuth()

  const userDetails = localStorage.getItem('userDetails') ?? ''
  const { actingAs } =
    userDetails !== '' ? JSON.parse(userDetails) : { actingAs: 'parent' }

  return (
    <EmptyPage
      message={
        <>
          <Typography variant="subtitle1" component="p">
            {actingAs !== 'parent'
              ? t(
                  'Programs.EmptyState.Instructions',
                  `You are currently not part of any program.`
                )
              : t(
                  'Programs.EmptyState.Instructions',
                  `Your family is currently not part of any program.`
                )}
          </Typography>
          <Typography variant="subtitle1" component="p">
            {permissionAbility.can('create', 'Program')
              ? t(
                  'Programs.EmptyState.Instructions',
                  `Click or tap on “+ Program” to start one.`
                )
              : t(
                  'Programs.EmptyState.Instructions',
                  `Please contact your director about enrollment.`
                )}
          </Typography>
        </>
      }
    />
  )
}

export default EmptyProgram
