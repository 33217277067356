import React, { PropsWithChildren } from 'react'
import { useAuth } from '../Routes/AuthProvider'
import NoPermission from './NoPermission'
import { useTranslation } from 'react-i18next'

interface CanAccessProps extends PropsWithChildren {
  I: string
  on: string
}

export const CanAccess: React.FC<CanAccessProps> = ({ I, on, children }) => {
  const { permissionAbility, featureAbility } = useAuth()
  const { t } = useTranslation()

  return permissionAbility.can(I, on) || featureAbility.can(I, on) ? (
    <>{children}</>
  ) : (
    <NoPermission
      content={t(
        'GeneralError.NoPermission',
        `Sorry, you do not have permission to view this content.`
      )}
    />
  )
}
