import React, { useContext } from 'react'
import { Page } from '../Elements/PageMargins'
import TitleContext from '../../TitleContext'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import InviteFamilyFormCard from '../Card/InviteFamilyFormCard'
import { CanAccess } from '../Elements/Access'

export const InviteFamily: React.FC = () => {
  const { t } = useTranslation()
  const title = t('Families.InviteFamily.Header', 'Families')

  const { userId } = useParams<{ userId: string }>()

  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  return (
    <Page>
      <CanAccess I="families" on="Feature">
        <CanAccess I="invite" on="Family">
          <InviteFamilyFormCard userId={userId} />
        </CanAccess>
      </CanAccess>
    </Page>
  )
}

export default InviteFamily
