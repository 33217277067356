export const buildFilterOptions = (
  array: string[],
  name: string,
  favorite?: string
): Array<{ name: string; id: number }> => {
  const filteredContent = array.sort().map((option, index) => {
    return {
      name: option,
      id: index,
    }
  })

  // We use -1 & -2 to ensure we have a unique id
  const filterOptions = [{ name, id: -1 }, ...filteredContent]

  if (favorite) {
    return [...filterOptions, { name: favorite, id: -2 }]
  }

  return filterOptions
}
