import { ReactElement, useCallback, useReducer } from 'react'

export enum FormFieldActions {
  'Add',
  'Remove',
  'Edit',
}

/**
 * Dispatch method to process the FormFieldActions provided.
 *
 * ReactElements can be added, removed, or their values modified.
 *
 * The payload can include:
 *  - fieldId: Id of the element, generally a field, of which to update in the list.
 *      This is compared against the field key since most of these will require
 *      one to be in a list.
 *  - value: string value of the field to be updated
 *  - element: element provided for the FormFieldActions.Add
 *  - valuePropName: name of the prop to apply the value. If this is not provided,
 *      the default is 'value'
 *
 * @param state the current ReactElement Array
 * @param action `FormFieldActions` to perform
 * @returns the updated ReactElement Array based on the action
 */
const formFieldReducer = (
  state: Set<ReactElement>,
  action: {
    type: FormFieldActions
    payload: {
      fieldId?: string
      value?: string
      element?: ReactElement
      valuePropName?: string
    }
  }
): Set<ReactElement> => {
  switch (action.type) {
    case FormFieldActions.Add:
      if (!!action.payload.element) {
        state.add(action.payload.element)
      }

      return state
    case FormFieldActions.Remove:
      const indexToRemove = [...state.values()].findIndex(
        (field) => field.key === action.payload.fieldId
      )
      const modifiedFields = [...state]
      modifiedFields.splice(indexToRemove, 1)
      return new Set(modifiedFields)
    case FormFieldActions.Edit:
      const fieldIndexToUpdate = [...state.values()].findIndex((field) => {
        return field.key === action.payload.fieldId
      })
      const updatedFields = [...state]
      updatedFields[fieldIndexToUpdate] = {
        ...updatedFields[fieldIndexToUpdate],
        props: {
          ...updatedFields[fieldIndexToUpdate].props,
          /**
           * For basic mui elements, value will work, however, there is a use case
           * where we can render components which render mui elements
           * e.g. (TutorFormField). In this case we should allow for properties
           * to be set like tutorName which are passed to TutorFormField.
           */
          [action.payload.valuePropName ?? 'value']: action.payload.value ?? '',
        },
      }
      return new Set(updatedFields)

    /** If no action provided, return the current state */
    default:
      return state
  }
}

/**
 * A VERY IMPORTANT USE NOTE:
 *
 * When using, because react requires a trigger to refresh elements, the Set of
 * elements is best rerendered in a useEffect triggered by methods that use the
 * actions to add, remove, and edit the Set of ReactElements. It is desired to
 * keep a state object of an array of these elements and set them as needed from
 * the fields within the useEffect, should a refresh be triggered by an action.
 *
 * @param initialElements initial set of ReactElements desired to render
 * @returns the current list of fields and pure methods to modify the list
 */
export const useFormFieldReducer = (
  initialElements: Set<ReactElement>
): {
  /** Current state of the fields */
  fields: Set<ReactElement>
  /** Method to call the dispatch with action FormFieldActions.Add */
  addField: (element: ReactElement) => void
  /** Method to call the dispatch with action FormFieldActions.Remove */
  removeField: (fieldId: string) => void
  /** Method to call the dispatch with action FormFieldActions.Edit */
  editFieldValue: (params: {
    fieldId: string
    value?: string
    valuePropName?: string
  }) => void
} => {
  const [fields, dispatch] = useReducer(formFieldReducer, initialElements)

  const addField = useCallback(
    (element: ReactElement) =>
      dispatch({
        type: FormFieldActions.Add,
        payload: { element },
      }),
    []
  )

  const removeField = useCallback(
    (fieldId: string) =>
      dispatch({
        type: FormFieldActions.Remove,
        payload: { fieldId },
      }),
    []
  )

  const editFieldValue = useCallback(
    (params: { fieldId: string; value?: string; valuePropName?: string }) =>
      dispatch({
        type: FormFieldActions.Edit,
        payload: {
          fieldId: params.fieldId,
          value: params.value,
          valuePropName: params.valuePropName,
        },
      }),
    []
  )

  return {
    fields,
    addField,
    removeField,
    editFieldValue,
  }
}
