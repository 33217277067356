import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { extractedErrorObject, teams } from '../../api/swagger'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useTablePagination } from '../../hooks/useTablePagination'
import {
  AgreementRecord,
  AgreementRecordInviteTypeEnum,
  AgreementRecordStatus,
} from '../../swagger'
import { useUser } from '../../UserContext'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'
import { LoadingContext } from '../Context/LoadingContext'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import LoadingProgress from '../Elements/LoadingProgress'
import TableHeaders from '../Interfaces/TableHeaders'
import TableFooterPagination from '../Pagination/TableFooterPagination'
import TableHeader from '../Table/TableHeader'
import { minSearchChars } from './TeamTab'
import DropDown, { DropDownVariant, MenuOption } from '../Menus/DropDown'
import NoResultsFound from '../Table/NoResultsFound'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'

/**
 * The acceptable list of values to orderBy.
 *
 * When ordering, pagination props `page` and `pageSize` are required. We will default
 * these to the page number on the table and the amount of rows shown in the table.
 */
type acceptableOrderByValues =
  | 'dateSent ASC'
  | 'dateSent DESC'
  | 'signerName ASC'
  | 'signerName DESC'
interface SortByProps {
  /** Value to pass to the sort dropdown */
  value: string
  /** Optional orderBy parameter to track when calling fetchTeamAgreementsForUser */
  orderBy: acceptableOrderByValues
}
interface FilterByProps {
  /** Value to pass to the filter dropdown */
  value: string
  /** Optional filter parameter to track when calling fetchTeamAgreementsForUser */
  invitationStatus: string | undefined
}
interface TeamAgreementsTableProps {
  /** UserKey of the selected user */
  userKey?: number
  /**
   * ActorKey from the selected user's actors.
   *
   * Generally this is within one's downline unless an admin is viewing a user's team.
   */
  actorKey?: number
  /** LoadingId to call fetchTeamAgreementsForUser */
  fetchTeamAgreementsForUserLoadingId: string
  /**
   * The search params to optionally include within fetchTeamAgreementsForUser
   */
  searchQuery?: string
}

export const TeamAgreementsTable: React.FC<TeamAgreementsTableProps> = ({
  userKey,
  actorKey,
  fetchTeamAgreementsForUserLoadingId,
  searchQuery = '',
}) => {
  const { t } = useTranslation()
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { user } = useUser()
  const theme = useTheme()

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    useTablePagination()

  const [totalCount, setTotalCount] = useState<number | undefined>(undefined)

  const [agreementsForActor, setAgreementsForActor] = useState<
    AgreementRecord[]
  >([])

  const loadTeamAgreementsForUser = async () => {
    try {
      // Given the userKey
      const { agreements, pagination } = await teams.fetchTeamAgreementsForUser(
        {
          // If we're requesting an actor that the current user also has in their downline and can act as, don't provide 'me'
          userId: `${
            userKey === user?.id && !!actorKey && actorKey < 0 ? 'me' : userKey
          }`,
          actorKey: !!actorKey && actorKey > 0 ? actorKey : undefined,
          search:
            !!searchQuery && searchQuery.length >= minSearchChars
              ? searchQuery
              : undefined,
          invitationStatus: selectedFilterBy.invitationStatus,
          ...(selectedSortBy.orderBy && {
            page: page + 1, // Though pages are 0 indexed for the ui table pagination, they are 1 indexed for backend pagination.
            pageSize: rowsPerPage,
            orderBy: [selectedSortBy.orderBy],
          }),
        }
      )

      /** These agreements come from the endpoint sorted in DATE SENT order. */
      setAgreementsForActor(agreements)
      setTotalCount(pagination?.totalCount ?? agreements.length)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        error: 'Unknown',
        message: t(
          'TeamAgreementsTable.FetchTeamAgreementsForUser.Error.Default',
          'An unknown error occurred fetching team agreements for user.'
        ),
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  /** On initial load, fetch the agreements for the selected user/actorKey */
  useMountEffect(() => {
    addLoadingIds([fetchTeamAgreementsForUserLoadingId])
  })

  useLoadingContext({
    loadingId: fetchTeamAgreementsForUserLoadingId,
    asyncFunction: loadTeamAgreementsForUser,
  })

  const tableHeaders: TableHeaders[] = [
    {
      label: t('TeamAgreementsTable.Header.Name', 'Name'),
      align: 'left',
    },
    {
      label: t('TeamAgreementsTable.Header.Email', 'Email'),
      align: 'left',
    },
    {
      label: t('TeamAgreementsTable.Header.Role', 'Role'),
      align: 'left',
    },
    {
      label: t('TeamAgreementsTable.Header.DateSent', 'Date Sent'),
      align: 'left',
    },
    {
      label: t('TeamAgreementsTable.Header.TypeOfInvite', 'Type of Invite'),
      align: 'left',
    },
    {
      label: t('TeamAgreementsTable.Header.StatusOfInvite', 'Status of Invite'),
      align: 'left',
    },
    {
      label: '',
      align: 'left',
      id: 'resendInvitationEmailButton',
    },
  ]

  const filterOptions: MenuOption[] = [
    {
      id: 'all statuses',
      name: t('TeamAgreementsTable.FilterBy.Label.AllStatuses', 'All Statuses'),
    } as MenuOption,
    ...Object.values(AgreementRecordStatus).map((it) => {
      return {
        id: `${it}`,
        name: `${it}`,
      } as MenuOption
    }),
  ]

  const [selectedFilterBy, setSelectedFilterBy] = useState<FilterByProps>({
    invitationStatus: undefined,
    value: filterOptions[0].name,
  })

  const sortOptions: MenuOption[] = [
    {
      id: 'dateSent ASC',
      name: t(
        'TeamTab.Sort.Label.DateSent.Ascending',
        'Sort By: Date Sent (Ascending)'
      ),
    },
    {
      id: 'dateSent DESC',
      name: t(
        'TeamTab.Sort.Label.DateSent.Descending',
        'Sort By: Date Sent (Descending)'
      ),
    },
    {
      id: 'signerName ASC',
      name: t(
        'TeamTab.Sort.Label.SignerName.Ascending',
        'Sort By: Name (Ascending)'
      ),
    },
    {
      id: 'signerName DESC',
      name: t(
        'TeamTab.Sort.Label.SignerName.Descending',
        'Sort By: Name (Descending)'
      ),
    },
  ]

  const [selectedSortBy, setSelectedSortBy] = useState<SortByProps>({
    orderBy: sortOptions[1].id as acceptableOrderByValues,
    value: sortOptions[1].name,
  })

  const handleSelection = (
    selection: string,
    id: string,
    selectOptionId: string | number
  ) => {
    /**
     * Handle the filtering and sorting cases separately.
     * Explicitly define the cases to handle in case we want to add some other dropdown later.
     */
    const isAllStatuses = new RegExp('All Statuses').test(selection)
    if (new RegExp(/filter/i).test(id)) {
      setSelectedFilterBy({
        invitationStatus: isAllStatuses ? undefined : `${selectOptionId}`,
        value: selection,
      })
    } else if (new RegExp(/sort/i).test(id)) {
      setSelectedSortBy({
        orderBy: selectOptionId as acceptableOrderByValues,
        value: selection,
      })
    }
  }

  const handleResendInviteEmail = async (agreementId: string) => {
    try {
      const { error } = await teams.resendTeamMember({ body: { agreementId } })
      const isSuccess = error.message.includes('success')
      setSnackbarMessage(error.message)
      setSnackbarSeverity(
        isSuccess ? SnackbarSeverity.Success : SnackbarSeverity.Error
      )
      setSnackbarState(true)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        error: 'Unknown',
        message: t(
          'TeamAgreementsTable.FetchTeamAgreementsForUser.Error.Default',
          'An unknown error occurred resending invitation email.'
        ),
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useEffect(() => {
    addLoadingIds([fetchTeamAgreementsForUserLoadingId])
  }, [
    addLoadingIds,
    fetchTeamAgreementsForUserLoadingId,
    selectedFilterBy.invitationStatus,
    selectedSortBy.orderBy,
    rowsPerPage,
    page,
  ])

  const filterAndSortButtons = (
    <Box flexDirection={'row'}>
      <Box>
        <DropDown
          formControlProps={{
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              margin: theme.spacing(1, 0),
            },
          }}
          id={'filter-invitations'}
          menuOptions={filterOptions}
          value={selectedFilterBy.value}
          handleSelection={handleSelection}
          variant={DropDownVariant.SortAndFilter}
          defaultValue={t('TeamTab.DropDown.FilterBy.Default', 'Filter By:')}
        />
      </Box>
      <Box mt={2}>
        <DropDown
          formControlProps={{
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              margin: theme.spacing(1, 0),
            },
          }}
          id={'sort-invitations'}
          menuOptions={sortOptions}
          value={selectedSortBy.value}
          handleSelection={handleSelection}
          variant={DropDownVariant.SortAndFilter}
          defaultValue={t('TeamTab.DropDown.SortBy.Default', 'Sort By:')}
        />
      </Box>
    </Box>
  )

  const rows = agreementsForActor.map((row) => (
    <TableRow key={row.agreementId}>
      <TableCell>
        <Typography variant="body1" component="p">
          {`${row.signerName ?? ''}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" component="p">
          {`${row.signerEmail}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" component="p">
          {`${row.roleName ?? ''}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" component="p">
          {`${
            row.dateSent
              ? dateToSlashStringReinterpretedAsLocal(row.dateSent)
              : ''
          }`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" component="p">
          {`${row.inviteType}`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body1" component="p">
          {`${row.status}`}
        </Typography>
      </TableCell>
      <TableCell>
        {(`${row.status}` === AgreementRecordStatus.Signed &&
          `${row.inviteType}` !== AgreementRecordInviteTypeEnum.Bypass) ||
        (`${row.status}` === AgreementRecordStatus.Sent &&
          `${row.inviteType}` === AgreementRecordInviteTypeEnum.Bypass) ? (
          <TextButton
            id="resendInvitationEmail"
            variant={TextButtonVariant.Resend}
            onClick={() => handleResendInviteEmail(row.agreementId)}
          />
        ) : null}
      </TableCell>
    </TableRow>
  ))

  /** If we are loading the agreements, present a loading progress indicator to the user. */
  if (loadingIds.has(fetchTeamAgreementsForUserLoadingId)) {
    return <LoadingProgress />
  }

  return (
    <>
      {filterAndSortButtons}
      {!!searchQuery && (
        <Box mt={2}>
          <Typography
            id="searchResults"
            variant="button"
            component="h2"
            color={theme.palette.textOrIcon.tableHeader}
            fontSize={'14px'}
            lineHeight={'16px'}
          >
            {t(
              'Team.Header.SearchResults',
              `Search Results for "{{searchQuery}}"`,
              { searchQuery }
            )}
          </Typography>
        </Box>
      )}
      <Box my={3}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableHeader tableHeaders={tableHeaders} />
            </TableHead>
            <TableBody>
              {agreementsForActor.length === 0 ? <NoResultsFound /> : rows}
            </TableBody>
            {agreementsForActor.length > 0 && (
              <TableFooterPagination
                label={t('TeamAgreementsTable.TablePagination.ViewAll', 'All')}
                count={totalCount ?? agreementsForActor.length ?? 1}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                colSpan={tableHeaders.length}
              />
            )}
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}

export default TeamAgreementsTable
