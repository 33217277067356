import React, { useState, useEffect } from 'react'
import { ReactReader } from 'react-reader'
import Box from '@mui/material/Box'
import LoadingProgressBar from '../Elements/LoadingProgress'

const DEFAULT_HEIGHT = '600px'

interface EpubViewerProps {
  title?: string
  url: string
  height?: string
}

const EpubViewer: React.FunctionComponent<EpubViewerProps> = ({
  title = '',
  url,
  height = DEFAULT_HEIGHT,
}) => {
  const [location, setLocation] = useState<string | number | undefined>(
    localStorage.getItem('epub-location') ?? undefined
  )

  useEffect(() => {
    if (!!title && title !== localStorage.getItem('epub-title')) {
      localStorage.removeItem('epub-location')
      localStorage.setItem('epub-title', title)
    }
  }, [title])

  const onLocationChanged = (location: string) => {
    setLocation(location)
    localStorage.setItem('epub-location', location)
  }

  return (
    <Box height={height} position="relative" aria-label="Epub viewer">
      <ReactReader
        title={title}
        location={location}
        locationChanged={onLocationChanged}
        url={url}
        loadingView={<LoadingProgressBar />}
      />
    </Box>
  )
}

export default EpubViewer
