import React, { PropsWithChildren } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Mountains from '../../Images/mountains.png'
import { Box, useTheme } from '@mui/material'

interface Props extends PropsWithChildren {
  minimumScreenHeight?: number
}

const CenterWithMountainBackground: React.FunctionComponent<Props> = ({
  minimumScreenHeight = 0,
  children,
}) => {
  const theme = useTheme()
  const isScreenTallEnough = useMediaQuery(
    `(min-height: ${minimumScreenHeight.toString()}px)`
  )

  return (
    <Box
      sx={{
        backgroundImage: `url(${Mountains})`,
        backgroundColor: theme.palette.customBackground.searchbar.main,
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom',
        width: '100vw !important',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...(!isScreenTallEnough && {
          backgroundAttachment: 'fixed',
          height: 'unset',
        }),
      }}
    >
      {children}
    </Box>
  )
}

export default CenterWithMountainBackground
