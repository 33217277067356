import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useNavigate, useLocation, Outlet, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import Tab from '@mui/material/Tab'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import TitleContext from '../../TitleContext'
import ProfileTab from './Profile/ProfileTab'
import { UserContext, useUser } from '../../UserContext'
import { Page } from '../Elements/PageMargins'
import AccountSettingsTab from './AccountSettingsTab'
import { UserProfile } from '../../swagger'
import { useAuth } from '../Routes/AuthProvider'
import DashboardTab from './Dashboard/DashboardTab'
import EnrollmentSummaryCard from '../Card/EnrollmentSummaryCard'
import DynamicBreadcrumbs, { Breadcrumb } from '../Elements/DynamicBreadcrumbs'

export enum AccountTabs {
  Dashboard = 'Dashboard',
  Profile = 'Profile',
  Billing = 'Billing',
  Settings = 'Settings',
  Invites = 'Invites',
}
export const Account: React.FunctionComponent = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const { featureAbility, userDetails, canViewDashboard } = useAuth()

  const title = t('Account.Title', 'Account')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const profile = t('Account.TabTitle.Profile', 'Profile')
  const billing = t('Account.TabTitle.Billing', 'Billing')
  const account = t('Account.TabTitle.Account', 'Account Settings')
  const directorDashboard = t('Account.TabTitle.DirectorDashboard', 'Dashboard')
  const canSeeBillingTab = featureAbility.can('billing', 'Feature')

  const invites = t('Account.TabTitle.Invites', 'Invites')

  const getTabFromLocationPath = useCallback(() => {
    if (location.pathname.match(/\/profile/)) {
      return AccountTabs.Profile
    } else if (location.pathname.match(/\/billing/)) {
      return AccountTabs.Billing
    } else if (location.pathname.match(/\/settings/)) {
      return AccountTabs.Settings
    } else if (location.pathname.match(/\/invites/)) {
      return AccountTabs.Invites
    } else {
      return canViewDashboard ? AccountTabs.Dashboard : AccountTabs.Profile
    }
  }, [location, canViewDashboard])

  const [selectedTab, setSelectedTab] = useState<AccountTabs>(
    getTabFromLocationPath()
  )

  const updateSelectedTab = React.useCallback(
    (newValue: string) => {
      switch (newValue) {
        case AccountTabs.Profile:
          setSelectedTab(AccountTabs.Profile)
          navigate(
            {
              pathname: `/account/profile`,
            },
            {
              replace: true,
            }
          )
          return
        case AccountTabs.Billing:
          setSelectedTab(AccountTabs.Billing)
          navigate(
            {
              pathname: `/account/billing`,
            },
            {
              replace: true,
            }
          )
          return
        case AccountTabs.Settings:
          setSelectedTab(AccountTabs.Settings)
          navigate(
            {
              pathname: `/account/settings`,
            },
            {
              replace: true,
            }
          )
          return
        case AccountTabs.Dashboard:
          setSelectedTab(
            canViewDashboard ? AccountTabs.Dashboard : AccountTabs.Profile
          )
          navigate(
            {
              pathname: '/account/dashboard',
            },
            {
              replace: true,
            }
          )
          return
        case AccountTabs.Invites:
          setSelectedTab(AccountTabs.Invites)
          navigate(
            {
              pathname: '/account/invites',
            },
            {
              replace: true,
            }
          )
          return
      }
    },
    [navigate, canViewDashboard]
  )

  useEffect(() => {
    if (!!location.state?.selectedTab) {
      updateSelectedTab(`${location.state?.selectedTab}`)
      return
    }

    /**
     * if the location state has a variant, update the selected tab, this is used when navigating to billing
     * from within the dashboard
     */
    if (!!location.state?.variant) {
      updateSelectedTab(`${location.state?.variant}`)
      return
    }

    if (!new RegExp(selectedTab, 'i').test(location.pathname)) {
      updateSelectedTab(getTabFromLocationPath())
    }
  }, [
    location.pathname,
    location.state,
    selectedTab,
    updateSelectedTab,
    getTabFromLocationPath,
  ])

  const profileTab = (
    <Tab label={profile} value={AccountTabs.Profile} key={profile} />
  )
  const billingTab = (
    <Tab label={billing} value={AccountTabs.Billing} key={billing} />
  )
  const accountTab = (
    <Tab label={account} value={AccountTabs.Settings} key={account} />
  )
  const dashboardTab = (
    <Tab
      label={directorDashboard}
      value={AccountTabs.Dashboard}
      key={directorDashboard}
    />
  )

  const invitesTab = (
    <Tab label={invites} value={AccountTabs.Invites} key={invites} />
  )

  const tabs: ReactNode[] = [
    ...(canViewDashboard ? [dashboardTab] : []),
    profileTab,
    ...(canSeeBillingTab ? [billingTab] : []),
    accountTab,
    ...(userDetails.actingAs === 'parent' ? [invitesTab] : []),
  ]

  return (
    <TabContext value={selectedTab}>
      <TabList
        value={selectedTab}
        onChange={(_, newValue) => updateSelectedTab(newValue)}
        textColor="primary"
      >
        {tabs}
      </TabList>
      <TabPanel value={selectedTab}>
        {/* To render a child element: https://reactrouter.com/en/main/components/outlet */}
        <Page withinTab>
          <Outlet />
        </Page>
      </TabPanel>
    </TabContext>
  )
}

/**TODO: Research about the use of Outlets and Layouts to refactor this section, for more info
 *  see: https://classicalconversations.my.workfront.com/task/64a6e2ad000e754b523b2a5bba454026/updates
 * */

export const ProfileTabLayout: React.FC = () => {
  return <ProfileTab />
}

export const BillingTabLayout: React.FC = () => {
  const baseAccountBillingPath = '/account/billing'
  const { t } = useTranslation()
  const { actorKey: actorKeyFromPath } = useParams<{ actorKey: string }>()
  const navigate = useNavigate()

  const location = useLocation()

  const { uniqueInactiveUserRoles } = useUser()

  const currentRoleViewed = !!actorKeyFromPath
    ? uniqueInactiveUserRoles.find(
        (role) => role.actorKey === +actorKeyFromPath
      )?.role ?? ''
    : ''

  const availableBreadcrumbs = {
    billing: {
      label: t('Billing.BillingTab.Breadcrumb.Billing', 'BILLING'),

      onClick: () => navigate(baseAccountBillingPath),
    },

    history: {
      label: t('Billing.BillingTab.Breadcrumb.MyRoles', 'MY ROLES'),

      onClick: () => navigate(`${baseAccountBillingPath}/roles`),
    },

    roleHistoryReport: {
      label: t(
        'Billing.BillingTab.Breadcrumb.MyRoles.Report',
        '{{currentRoleViewed}} HISTORY',
        { currentRoleViewed, interpolation: { escapeValue: false } }
      ),

      onClick: () =>
        navigate(`${baseAccountBillingPath}/history/${actorKeyFromPath}`),
    },

    report: {
      label: t('Billing.BillingTab.Breadcrumb.BillingReport', 'REPORT'),

      onClick: () =>
        navigate(`${baseAccountBillingPath}/${actorKeyFromPath}/report`),
    },
  }

  const historyBreadcrumb = [
    availableBreadcrumbs.billing,

    { ...availableBreadcrumbs.history, onClick: undefined },
  ]

  const rolesBreadcrumb = [
    availableBreadcrumbs.billing,
    availableBreadcrumbs.history,

    { ...availableBreadcrumbs.roleHistoryReport, onClick: undefined },
  ]

  const billingReportBreadcrumb = [
    availableBreadcrumbs.billing,

    { ...availableBreadcrumbs.report, onClick: undefined },
  ]

  const historyBreadcrumbReport = [
    availableBreadcrumbs.billing,
    availableBreadcrumbs.history,
    availableBreadcrumbs.roleHistoryReport,
    { ...availableBreadcrumbs.report, onClick: undefined },
  ]

  let breadcrumbs: Breadcrumb[] = []

  switch (location.pathname) {
    case `${baseAccountBillingPath}/roles`:
      breadcrumbs = historyBreadcrumb
      break

    case `${baseAccountBillingPath}/history/${actorKeyFromPath}`:
      breadcrumbs = rolesBreadcrumb
      break

    case `${baseAccountBillingPath}/${actorKeyFromPath}/report`:
      breadcrumbs = billingReportBreadcrumb
      break

    case `${baseAccountBillingPath}/history/${actorKeyFromPath}/report`:
      breadcrumbs = historyBreadcrumbReport
      break
  }

  return (
    <>
      <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
      <Outlet />
    </>
  )
}

export const EnrollmentCardLayout: React.FC = () => {
  return <EnrollmentSummaryCard />
}

export const AccountSettingsTabLayout: React.FC = () => {
  const { user } = useContext(UserContext)
  const [userInformation, setUserInformation] = useState<UserProfile>({
    id: user?.id ?? 0,
    username: user?.username ?? '',
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    isProfilePrivate: user?.isProfilePrivate,
    isPhoneNumberPrivate: user?.isPhoneNumberPrivate,
    isLoginEmailPrivate: user?.isLoginEmailPrivate,
  })

  useEffect(() => {
    if (!!user) {
      setUserInformation({
        id: user?.id,
        username: user?.username,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phone: user?.phone,
        email: user?.email,
        isProfilePrivate: user?.isProfilePrivate,
        isPhoneNumberPrivate: user?.isPhoneNumberPrivate,
        isLoginEmailPrivate: user?.isLoginEmailPrivate,
      })
    }
  }, [user])
  return <AccountSettingsTab userInfo={userInformation} />
}
export const DashboardTabLayout: React.FC = () => {
  return <DashboardTab />
}

export default Account
