import { useEffect, useState } from 'react'
import { EnrollmentStatusesResponse } from '../swagger/models'
import { fetchEnrollmentStatuses } from '../api/enrollments'
import { extractedErrorObject } from '../api/swagger'
import { SnackbarSeverity } from '../components/Alerts/SnackbarAlert'
import { Ability } from '@casl/ability'
import { useSnackbarContext } from '../components/Context/SnackbarContext'

export const useEnrollmentOptions = (props: {
  permissionAbility: Ability
  programKey?: number
}): EnrollmentStatusesResponse | undefined => {
  const { permissionAbility, programKey } = props
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const [enrollmentOptions, setEnrollmentOptions] =
    useState<EnrollmentStatusesResponse>()
  const canViewEnrollments = permissionAbility.can('view', 'Enrollment')

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const enrollmentStatuses = await fetchEnrollmentStatuses(programKey)
        setEnrollmentOptions(enrollmentStatuses)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'Unknown Code',
          message: (e as unknown as Error).message,
        }
        setSnackbarSeverity?.(SnackbarSeverity.Error)
        setSnackbarMessage?.(errorObject.message)
        setSnackbarState?.(true)
      }
    }

    if (canViewEnrollments) fetchOptions()
  }, [
    canViewEnrollments,
    setSnackbarSeverity,
    setSnackbarMessage,
    setSnackbarState,
    programKey,
  ])

  return enrollmentOptions
}
