import React, { PropsWithChildren, useCallback, useRef, useState } from 'react'
import { fetchCommunity } from '../../api/communities'
import { CommunityDetail } from '../../swagger/models/CommunityDetail'
import {
  ActorSelectOption,
  CommunitySpecificProgramOptionsFees,
} from '../../swagger'
import { fetchCommunitySpecificProgramOptions } from '../../api/programs'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { Ability } from '@casl/ability'
import useSetSnackbarProps from '../../hooks/useSetSnackbarProps'
import { SnackbarSeverity } from './SnackbarContext'
import { useTranslation } from 'react-i18next'

export const defaultCommunitiesContextValue = {
  selectedCommunityKey: undefined as number | undefined,
  /** The search value for the filter in communities  */
  search: undefined as string | undefined,

  /** The academicYear for the filter in communities  */
  academicYear: undefined as number | undefined,

  /** The community name to use in breadcrumbs  */
  communityName: undefined as string | undefined,

  communityDetails: undefined as CommunityDetail | undefined,

  /** The director options to use in dropdowns  */
  directorOptions: undefined as ActorSelectOption[] | undefined,

  /** The tutor options to use in dropdowns  */
  tutorOptions: [],

  /** The Community specific program options fees */
  programTypesFeeOptions: undefined as
    | CommunitySpecificProgramOptionsFees
    | undefined,

  /** Method to allow updates to the academicYear in Communities */
  updateAcademicYear: (academicYear: number): void => {
    console.warn(
      `The CommunitiesContext.updateAcademicYear was called with value ${academicYear}. Did you forget to use a CommunitiesProvider?`
    )
  },

  /** Method to allow updates to the communityName in Communities */
  updateCommunityName: (communityName: string): void => {
    console.warn(
      `The CommunitiesContext.updateCommunityName was called with value ${communityName}. Did you forget to use a CommunitiesProvider?`
    )
  },
  fetchCommunityDetails: async (): Promise<void> => {
    console.warn(
      `The CommunitiesContext.fetchCommunityDetails was called. Did you forget to use a CommunitiesProvider?`
    )
  },

  /** Method to set the directors and tutors for a specific community  */
  setDirectorsAndTutorsForCommunitySelection: async (
    communityKey: number
  ): Promise<void> => {
    console.warn(
      `The CommunitiesContext.setDirectorsAndTutorsForCommunitySelection was called with value ${communityKey}. Did you forget to use a CommunitiesProvider?`
    )
  },

  setTutorOptions: (tutorOptions: ActorSelectOption[]): void => {
    console.warn(
      `The CommunitiesContext.setTutorOptions was called with value ${tutorOptions}. Did you forget to use a CommunitiesProvider?`
    )
  },

  setDirectorOptions: (directorOptions: ActorSelectOption[]): void => {
    console.warn(
      `The CommunitiesContext.setTutorOptions was called with value ${directorOptions}. Did you forget to use a CommunitiesProvider?`
    )
  },

  /** Method to allow updates to the search filter in communities */
  updateSearch: (search: string): void => {
    console.warn(
      `The CommunitiesContext.updateSearch was called with value ${search}. Did you forget to use a CommunitiesProvider?`
    )
  },

  resetContextToDefaults: (): void => {
    console.warn(
      `The CommunitiesContext.resetContextToDefaults was called by default. Did you forget to use a CommunitiesProvider?`
    )
  },

  updateSelectedCommunityKey: (id: number | undefined): void => {
    console.warn(
      `The CommunitiesContext.updateSelectedCommunityKey(${id}) was called by default. Did you forget to use a CommunitiesProvider?`
    )
  },
  fetchCommunityLoadingId: 'fetchCommunity',
  communityAbility: undefined as Ability | undefined,
  resetFieldsForCancel: (): void => {
    console.warn(
      `The CommunitiesContext.resetFieldsForCancel was called by default. Did you forget to use a CommunitiesProvider?`
    )
  },
  handleCancel: (): void => {
    console.warn(
      `The CommunitiesContext.handleCancel was called by default. Did you forget to use a CommunitiesProvider?`
    )
  },
}

export const CommunitiesContext = React.createContext(
  defaultCommunitiesContextValue
)

export const useCommunitiesContext =
  (): typeof defaultCommunitiesContextValue =>
    React.useContext(CommunitiesContext)

export type TestCommunitiesContextConfig = typeof defaultCommunitiesContextValue

export interface TestCommunitiesContextAuth extends PropsWithChildren {
  testConfig?: Partial<TestCommunitiesContextConfig>
}

export const CommunitiesProvider: React.FC<TestCommunitiesContextAuth> = ({
  testConfig,
  children,
}) => {
  /** Hooks */
  const { CommunityDetailsTab } = useLoadingIds()
  const { t } = useTranslation()

  /** Refs */
  const initialCommunityDetails = useRef<CommunityDetail | undefined>()

  /** State Objects */
  const [academicYear, setAcademicYear] = useState<number | undefined>()
  const [communityAbility, setCommunityAbility] = useState<Ability>()
  const [communityDetails, setCommunityDetails] = useState<
    CommunityDetail | undefined
  >(undefined)
  const [communityName, setCommunityName] = useState<string | undefined>()
  const [directorOptions, setDirectorOptions] = useState<ActorSelectOption[]>()
  const [message, setMessage] = useState('')
  const [programTypesFeeOptions, setProgramTypesFeeOptions] =
    useState<CommunitySpecificProgramOptionsFees>()
  const [search, setSearch] = useState<string | undefined>()
  const [selectedCommunityKey, setSelectedCommunityKey] = useState<
    number | undefined
  >(defaultCommunitiesContextValue.selectedCommunityKey)
  const [severity, setSeverity] = useState<SnackbarSeverity>(
    SnackbarSeverity.Success
  )
  const [tutorOptions, setTutorOptions] = useState<ActorSelectOption[]>()

  /** Hooks dependent on State */
  useSetSnackbarProps({
    snackbarContents: {
      snackbarMessage: message,
      snackbarSeverity: severity,
    },
  })

  /** Values dependent on Hooks */
  const fetchCommunityLoadingId = CommunityDetailsTab.fetchCommunity

  /** Methods */
  const fetchCommunityDetails = async (): Promise<void> => {
    // No message
    setMessage('')
    if (!selectedCommunityKey) return
    try {
      const fetchedCommunityDetails = await fetchCommunity(selectedCommunityKey)
      setCommunityDetails(fetchedCommunityDetails)
      setCommunityName(fetchedCommunityDetails?.name ?? '')
      initialCommunityDetails.current = fetchedCommunityDetails
      setCommunityAbility(
        new Ability(
          fetchedCommunityDetails?.meta?.permissions?.map(
            ({ resourceCode: subject, actionCode: action }) => ({
              subject,
              action,
            })
          )
        )
      )
    } catch (e) {
      setMessage(
        t(
          'Communities.CommunitiesContext.FetchCommunity.DefaultError',
          `Unable to fetch community details for selected community.`
        )
      )
      setSeverity(SnackbarSeverity.Error)
    }
  }

  const resetContextToDefaults = () => {
    setSearch(defaultCommunitiesContextValue.search)
    setCommunityName(defaultCommunitiesContextValue.communityName)
    setAcademicYear(defaultCommunitiesContextValue.academicYear)
    setDirectorOptions(defaultCommunitiesContextValue.directorOptions)
    setTutorOptions(defaultCommunitiesContextValue.tutorOptions)
    setProgramTypesFeeOptions(
      defaultCommunitiesContextValue.programTypesFeeOptions
    )
    setCommunityDetails(defaultCommunitiesContextValue.communityDetails)
    setSelectedCommunityKey(defaultCommunitiesContextValue.selectedCommunityKey)
  }

  const resetFieldsForCancel = () => {
    setCommunityDetails(initialCommunityDetails.current)
  }

  const setDirectorsAndTutorsForCommunitySelection = useCallback(
    async (communityKey: number) => {
      const fetchedCommunityProgramOptions =
        await fetchCommunitySpecificProgramOptions(communityKey)
      setDirectorOptions([
        ...fetchedCommunityProgramOptions.directors,
        ...(fetchedCommunityProgramOptions.expiredDirectors ?? []),
      ])
      setTutorOptions([
        ...fetchedCommunityProgramOptions.tutors,
        ...(fetchedCommunityProgramOptions.expiredTutors ?? []),
      ])
      setProgramTypesFeeOptions(fetchedCommunityProgramOptions.fees)
    },
    []
  )

  const updateSelectedCommunityKey = (id: number | undefined) => {
    setSelectedCommunityKey(id)
  }

  const updateAcademicYear = (academicYear: number) => {
    setAcademicYear(academicYear)
  }

  const value = {
    academicYear,
    communityAbility,
    communityDetails,
    communityName,
    directorOptions,
    fetchCommunityDetails,
    fetchCommunityLoadingId,
    programTypesFeeOptions,
    resetContextToDefaults,
    resetFieldsForCancel,
    search,
    selectedCommunityKey,
    setDirectorOptions,
    setDirectorsAndTutorsForCommunitySelection,
    setTutorOptions,
    tutorOptions: tutorOptions === undefined ? [] : tutorOptions,
    updateAcademicYear,
    updateCommunityName: setCommunityName,
    updateSearch: setSearch,
    updateSelectedCommunityKey,
    ...testConfig,
  } as typeof defaultCommunitiesContextValue

  return (
    <CommunitiesContext.Provider value={value}>
      {children}
    </CommunitiesContext.Provider>
  )
}

export default CommunitiesProvider
