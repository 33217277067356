import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { extractedErrorObject, transcriptsApi } from '../api/swagger'
import { LoadingContext } from '../components/Context/LoadingContext'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'
import useLoadingContext from './useLoadingContext'
import { useLoadingIds } from './useLoadingIds'

export interface DeleteTranscriptHookProps {
  transcriptKey?: number
}

export const useDeleteTranscript = ({
  transcriptKey,
}: DeleteTranscriptHookProps): {
  loadingId: string
} => {
  const { t } = useTranslation()
  const { addLoadingIds } = useContext(LoadingContext)
  const { UseDeleteTranscript } = useLoadingIds()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const navigate = useNavigate()

  let deleteSucceeded = false

  const loadingId = UseDeleteTranscript.deleteTranscript

  const errorMessage = t(
    'Hooks.FetchTranscript.Error.Default',
    'An error occurred while attempting to delete transcript.'
  )

  const deleteTranscript = async () => {
    try {
      if (!transcriptKey) {
        setSnackbarMessage(errorMessage)
        setSnackbarSeverity(SnackbarSeverity.Error)
        setSnackbarState(true)
        return
      }
      await transcriptsApi.deleteTranscript({
        transcriptKey,
      })
      deleteSucceeded = !deleteSucceeded
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: deleteTranscript,
    loadingId,
    callback: () => {
      if (deleteSucceeded) navigate('/transcripts/overview')
    },
  })

  useEffect(() => {
    if (!!transcriptKey) {
      addLoadingIds([loadingId])
    }
  }, [transcriptKey, addLoadingIds, loadingId])
  return { loadingId }
}
