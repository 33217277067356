import React, { useState, useEffect, useCallback, useRef } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Can } from '@casl/react'
import { useNavigate, useParams } from 'react-router'
import { useAuth } from '../Routes/AuthProvider'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { useTranslation } from 'react-i18next'
import CardFormHeader from '../Card/CardFormHeader'
import RegionInformationCard from './RegionInformationCard'
import RegionFeeStructureCard from './RegionFeeStructureCard'
import RegionAgreementPermissionCard from './RegionAgreementPermissionCard'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import BasicModal from '../Modals/BasicModal'
import ActionButtons from '../Buttons/ActionButtons'
import {
  RegionOptions,
  ReceiverRole,
  Region,
  RegionContractingHierarchy,
  RegionFeeSetting,
  ProgramType,
  RegionUpdate,
  RegionContentTag,
} from '../../swagger'
import {
  regionsApi,
  meta,
  extractedErrorObject,
  contentApi,
} from '../../api/swagger'
import ContentTagsCard from './ContentTagsCard'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useMountEffect } from '../../hooks/useMountEffect'
import { FieldValidity } from '../Interfaces/FieldValidity'
import { LoadingContext } from '../Context/LoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import useLoadingContext from '../../hooks/useLoadingContext'

let forumsLink = process.env.REACT_APP_FORUMS_URL ?? ''

/** Minimum and maximum value allowed for Licensing Due Date Ranges */
export const licensingDueDateRangeMaximum = 180
export const licensingDueDateRangeMinimum = -180

//When PROD Discourse is setted up, it could be deleted
const endPartToExcludeToForumsLink = '/session/sso'
if (!!forumsLink) {
  forumsLink = forumsLink.slice(
    0,
    forumsLink.length - endPartToExcludeToForumsLink.length
  )
}

export enum TypeRegion {
  Private = 'private',
  Public = 'public',
}

export enum ProgramFeeNames {
  Enrollment = 'enrollment',
  Application = 'application',
  Tuition = 'tuition',
  SupplyFeeFirstStudent = 'supplyFeeFirstStudent',
  LocalFeeFirstStudent = 'localFeeFirstStudent',
  FacilityFeeFirstStudent = 'facilityFeeFirstStudent',
  MiscFeeFirstStudent = 'miscFeeFirstStudent',
  SubLicensedTutorPercentage = 'subLicensedTutorPercentage',
  MultiStudentApplicationDiscount = 'multiStudentApplicationDiscount',
  SemesterOneLicensingFee = 'semesterOneLicensingFee',
  DiscountSemesterOneLicensingFee = 'discountSemesterOneLicensingFee',
  SemesterTwoLicensingFee = 'semesterTwoLicensingFee',
  DiscountSemesterTwoLicensingFee = 'discountSemesterTwoLicensingFee',
  LocalFeeAdditionalStudent = 'localFeeAdditionalStudent',
  MiscFeeAdditionalStudent = 'miscFeeAdditionalStudent',
  SupplyFeeAdditionalStudent = 'supplyFeeAdditionalStudent',
  FacilityFeeAdditionalStudent = 'facilityFeeAdditionalStudent',
  InvitationFee = 'invitationFee',
  DiscountInvitationFee = 'discountInvitationFee',
}

export interface RegionInformationValidity {
  regionNameIsValid: FieldValidity
  actorKeyIsValid: FieldValidity
  semesterOneStartMonthIsValid: FieldValidity
  semesterOneStartDayIsValid: FieldValidity
  semesterTwoStartMonthIsValid: FieldValidity
  semesterTwoStartDayIsValid: FieldValidity
  discourseUrlIsValid: FieldValidity
  academicYearCycleStartYearIsValid: FieldValidity
  s1LicensingDueOffset: FieldValidity
  s1LicensingLateOffset: FieldValidity
  s2LicensingDueOffset: FieldValidity
  s2LicensingLateOffset: FieldValidity
}

const initialRegionValidity = {
  regionNameIsValid: { input: true },
  actorKeyIsValid: { input: true },
  semesterOneStartMonthIsValid: { input: true, sameDate: false },
  semesterOneStartDayIsValid: { input: true, sameDate: false },
  semesterTwoStartMonthIsValid: { input: true, sameDate: false },
  semesterTwoStartDayIsValid: { input: true, sameDate: false },
  discourseUrlIsValid: { input: true },
  academicYearCycleStartYearIsValid: { input: true },
  s1LicensingDueOffset: { input: true, afterMin: true, beforeMax: true },
  s1LicensingLateOffset: { input: true, afterMin: true, beforeMax: true },
  s2LicensingDueOffset: { input: true, afterMin: true, beforeMax: true },
  s2LicensingLateOffset: { input: true, afterMin: true, beforeMax: true },
}

const minValueOfaACycleStartYear = Number.parseInt(
  process.env.REACT_APP_MIN_VALUE_OF_AY_CYCLE_START_YEAR ?? '2000'
)

const emptyRegionInformation: Region = {
  regionsKey: -1,
  currency: '',
  name: '',
  actorKey: -1,
  parentRegionsKey: -1,
  semesterOneStartMonth: -1,
  semesterOneStartDay: -1,
  semesterTwoStartMonth: -1,
  semesterTwoStartDay: -1,
  isPrivate: false,
  cycle: -1,
  regionManagerName: '',
  bypassAutomatedAgreements: false,
  discourseUrl: forumsLink,
  aYCycleStartYear: minValueOfaACycleStartYear,
  s1LicensingDueOffset: -60,
  s1LicensingLateOffset: 35,
  s2LicensingDueOffset: -60,
  s2LicensingLateOffset: 24,
}

const emptyFeeInformation = Object.values(ProgramFeeNames).reduce(
  (acc, key) => ({
    ...acc,
    [`${key}-minimum`]: 0,
    [`${key}-default`]: 0,
    [`${key}-isValid`]: true,
    [`${key}-toggle`]: false,
  }),
  {}
) as FeeInformation

const genericEventHandler = (
  event: React.ChangeEvent<HTMLInputElement>,
  value: FeeInformation,
  updaterFn: React.Dispatch<React.SetStateAction<FeeInformation>>
) => {
  /** Handle toggle event separately */
  if (event.target.name.includes('toggle')) {
    updaterFn({
      ...value,
      [event.target.name]: event.target.checked,
    })
    return
  }

  const newValue = +event.target.value.replace(/[^0-9]/g, '')

  updaterFn({
    ...value,
    [event.target.name]: newValue,
  })
}

const isValidSelection = (value?: number) => value !== -1

const isValidLicensingDueDateRanges = (value: number) => {
  if (!value) return false
  return (
    value >= licensingDueDateRangeMinimum &&
    value <= licensingDueDateRangeMaximum
  )
}

function shallowEqual(
  object1: Region | FeeInformation,
  object2: Region | FeeInformation
): boolean {
  const keys = Object.keys(object1)
  for (const key of keys) {
    if (
      object1[key as keyof typeof object1] !==
      object2[key as keyof typeof object2]
    ) {
      return false
    }
  }
  return true
}

export interface FeeInformation {
  [key: string]: number | boolean
  'application-minimum': number
  'application-default': number
  'application-isValid': boolean
  'application-toggle': boolean
  'enrollment-minimum': number
  'enrollment-default': number
  'enrollment-isValid': boolean
  'enrollment-toggle': boolean
  'supplyFeeFirstStudent-minimum': number
  'supplyFeeFirstStudent-default': number
  'supplyFeeFirstStudent-isValid': boolean
  'supplyFeeFirstStudent-toggle': boolean
  'tuition-minimum': number
  'tuition-default': number
  'tuition-isValid': boolean
  'tuition-toggle': boolean
  'localFeeFirstStudent-minimum': number
  'localFeeFirstStudent-default': number
  'localFeeFirstStudent-isValid': boolean
  'localFeeFirstStudent-toggle': boolean
  'facilityFeeFirstStudent-minimum': number
  'facilityFeeFirstStudent-default': number
  'facilityFeeFirstStudent-isValid': boolean
  'facilityFeeFirstStudent-toggle': boolean
  'miscFeeFirstStudent-minimum': number
  'miscFeeFirstStudent-default': number
  'miscFeeFirstStudent-isValid': boolean
  'miscFeeFirstStudent-toggle': boolean
  'subLicensedTutorPercentage-minimum': number
  'subLicensedTutorPercentage-default': number
  'subLicensedTutorPercentage-isValid': boolean
  'subLicensedTutorPercentage-isValidPercentage': boolean
  'subLicensedTutorPercentage-toggle': boolean
  'semesterOneLicensingFee-minimum': number
  'semesterOneLicensingFee-default': number
  'semesterOneLicensingFee-isValid': boolean
  'semesterOneLicensingFee-toggle': boolean
  'discountSemesterOneLicensingFee-minimum': number
  'discountSemesterOneLicensingFee-default': number
  'discountSemesterOneLicensingFee-isValid': boolean
  'discountSemesterOneLicensingFee-toggle': boolean
  'semesterTwoLicensingFee-minimum': number
  'semesterTwoLicensingFee-default': number
  'semesterTwoLicensingFee-isValid': boolean
  'semesterTwoLicensingFee-toggle': boolean
  'discountSemesterTwoLicensingFee-minimum': number
  'discountSemesterTwoLicensingFee-default': number
  'discountSemesterTwoLicensingFee-isValid': boolean
  'discountSemesterTwoLicensingFee-toggle': boolean
  'multiStudentApplicationDiscount-minimum': number
  'multiStudentApplicationDiscount-default': number
  'multiStudentApplicationDiscount-isValid': boolean
  'multiStudentApplicationDiscount-toggle': boolean
  'localFeeAdditionalStudent-minimum': number
  'localFeeAdditionalStudent-default': number
  'localFeeAdditionalStudent-isValid': boolean
  'localFeeAdditionalStudent-toggle': boolean
  'miscFeeAdditionalStudent-minimum': number
  'miscFeeAdditionalStudent-default': number
  'miscFeeAdditionalStudent-isValid': boolean
  'miscFeeAdditionalStudent-toggle': boolean
  'supplyFeeAdditionalStudent-minimum': number
  'supplyFeeAdditionalStudent-default': number
  'supplyFeeAdditionalStudent-isValid': boolean
  'supplyFeeAdditionalStudent-toggle': boolean
  'facilityFeeAdditionalStudent-minimum': number
  'facilityFeeAdditionalStudent-default': number
  'facilityFeeAdditionalStudent-isValid': boolean
  'facilityFeeAdditionalStudent-toggle': boolean
  'invitationFee-minimum': number
  'invitationFee-default': number
  'invitationFee-isValid': boolean
  'invitationFee-toggle': boolean
  'discountInvitationFee-minimum': number
  'discountInvitationFee-default': number
  'discountInvitationFee-isValid': boolean
  'discountInvitationFee-toggle': boolean
}

export interface ContractingHierarchyReference {
  senderRoleKey: number
  receiverRoleKey: number
}

export enum Modification {
  Add = 'add',
  Delete = 'delete',
}

export interface LocalContractingHierarchy extends RegionContractingHierarchy {
  modification?: Modification
}
export enum RegionFormCardVariants {
  AddRegion,
  EditRegion,
}

interface RegionFormCardProps {
  variant: RegionFormCardVariants
  regionDetails?: Region
  regionFees?: RegionFeeSetting[]
  regionContractingHierarchies?: RegionContractingHierarchy[]
  handleCancel?: () => void
  handleSave?: () => void
}

const RegionFormCard: React.FC<RegionFormCardProps> = ({
  variant,
  regionDetails,
  regionFees,
  regionContractingHierarchies,
  handleCancel: handleCancelFromProps,
  handleSave: handleSaveFromProps,
}) => {
  const { permissionAbility } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const { addLoadingIds, removeLoadingIds } = React.useContext(LoadingContext)
  const availableLoadingIds = useLoadingIds()

  const isAddRegionVariant = variant === RegionFormCardVariants.AddRegion

  const { regionKey } = useParams<{
    regionKey: string
  }>()

  const [isFieldDisabled, setIsFieldDisabled] = useState(!isAddRegionVariant)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)

  const [regionInformationValidity, setRegionInformationValidity] =
    useState<RegionInformationValidity>(initialRegionValidity)

  /** Store reference to initial region info values so when user clicks 'Cancel' we can reset the text inputs. */
  const initialRegionInformation = useRef<Region>(emptyRegionInformation)
  const initialCurrencyCode = useRef('')
  const initialScribblersFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialFoundationsFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialEssentialsFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialChallengeAFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialChallengeBFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialChallengeIFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialChallengeIIFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialChallengeIIIFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialChallengeIVFeeInformation =
    useRef<FeeInformation>(emptyFeeInformation)
  const initialContractingHierarchies = useRef<RegionContractingHierarchy[]>([])

  const [regionInformation, setRegionInformation] = useState(
    emptyRegionInformation
  )
  const [regionOptions, setRegionOptions] = useState<RegionOptions>()
  const [senderRoleOptions, setSenderRoleOptions] = useState<ReceiverRole[]>()

  const [currencyCode, setCurrencyCode] = useState('')
  const [scribblersFeeInformation, setScribblersFeeInformation] =
    useState(emptyFeeInformation)
  const [foundationsFeeInformation, setFoundationsFeeInformation] =
    useState(emptyFeeInformation)
  const [essentialsFeeInformation, setEssentialsFeeInformation] =
    useState(emptyFeeInformation)
  const [challengeAFeeInformation, setChallengeAFeeInformation] =
    useState(emptyFeeInformation)
  const [challengeBFeeInformation, setChallengeBFeeInformation] =
    useState(emptyFeeInformation)
  const [challengeIFeeInformation, setChallengeIFeeInformation] =
    useState(emptyFeeInformation)
  const [challengeIIFeeInformation, setChallengeIIFeeInformation] =
    useState(emptyFeeInformation)
  const [challengeIIIFeeInformation, setChallengeIIIFeeInformation] =
    useState(emptyFeeInformation)
  const [challengeIVFeeInformation, setChallengeIVFeeInformation] =
    useState(emptyFeeInformation)

  const [localContractingHierarchies, setLocalContractingHierarchies] =
    useState<LocalContractingHierarchy[]>([])

  const [contractingHierarchiesToAdd, setContractingHierarchiesToAdd] =
    useState<ContractingHierarchyReference[]>([])
  const [contractingHierarchiesToDelete, setContractingHierarchiesToDelete] =
    useState<ContractingHierarchyReference[]>([])

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

  const errorMessage = t(
    'Regions.RegionForm.ValidationMessage.Default',
    'Something went wrong. Please make sure you have filled out the required fields.'
  )

  /**
   * Initialize text inputs with passed in region info and update the stored reference.
   */
  useEffect(() => {
    if (regionDetails) {
      const regionData = {
        regionsKey: regionDetails.regionsKey,
        name: regionDetails.name,
        actorKey: regionDetails.actorKey,
        regionManagerName: regionDetails.regionManagerName ?? '',
        parentRegionsKey: regionDetails.parentRegionsKey ?? -1,
        semesterOneStartMonth: regionDetails.semesterOneStartMonth,
        semesterOneStartDay: regionDetails.semesterOneStartDay,
        semesterTwoStartMonth: regionDetails.semesterTwoStartMonth,
        semesterTwoStartDay: regionDetails.semesterTwoStartDay,
        isPrivate: regionDetails.isPrivate,
        cycle: regionDetails.cycle ?? -1,
        currency: regionDetails.currency,
        bypassAutomatedAgreements: regionDetails.bypassAutomatedAgreements,
        discourseUrl: regionDetails.discourseUrl ?? forumsLink,
        aYCycleStartYear: regionDetails.aYCycleStartYear,
        achAllowed: regionDetails.achAllowed ?? false,
        s1LicensingDueOffset: regionDetails.s1LicensingDueOffset,
        s1LicensingLateOffset: regionDetails.s1LicensingLateOffset,
        s2LicensingDueOffset: regionDetails.s2LicensingDueOffset,
        s2LicensingLateOffset: regionDetails.s2LicensingLateOffset,
      }

      const regionValidity = {
        actorKeyIsValid: { input: true },
        regionNameIsValid: { input: true },
        managerIsValid: { input: true },
        semesterOneStartMonthIsValid: { input: true },
        semesterOneStartDayIsValid: { input: true },
        semesterTwoStartMonthIsValid: { input: true },
        semesterTwoStartDayIsValid: { input: true },
        discourseUrlIsValid: { input: true },
        academicYearCycleStartYearIsValid: { input: true },
        s1LicensingDueOffset: { input: true, afterMin: true, beforeMax: true },
        s1LicensingLateOffset: { input: true, afterMin: true, beforeMax: true },
        s2LicensingDueOffset: { input: true, afterMin: true, beforeMax: true },
        s2LicensingLateOffset: { input: true, afterMin: true, beforeMax: true },
      }
      setRegionInformation(regionData)
      initialRegionInformation.current = regionData

      setRegionInformationValidity(regionValidity)

      const currencyData = regionDetails.currency ?? ''
      setCurrencyCode(currencyData)
      initialCurrencyCode.current = currencyData
    }
  }, [regionDetails])

  /**
   * Initialize text inputs with passed in region fees and update the stored reference.
   */
  useEffect(() => {
    const programToStateUpdateFnMap = {
      [ProgramType.Scribblers]: setScribblersFeeInformation,
      [ProgramType.Foundations]: setFoundationsFeeInformation,
      [ProgramType.Essentials]: setEssentialsFeeInformation,
      [ProgramType.ChallengeA]: setChallengeAFeeInformation,
      [ProgramType.ChallengeB]: setChallengeBFeeInformation,
      [ProgramType.ChallengeI]: setChallengeIFeeInformation,
      [ProgramType.ChallengeIi]: setChallengeIIFeeInformation,
      [ProgramType.ChallengeIii]: setChallengeIIIFeeInformation,
      [ProgramType.ChallengeIv]: setChallengeIVFeeInformation,
    }

    const programToInitialRefMap = {
      [ProgramType.Scribblers]: initialScribblersFeeInformation,
      [ProgramType.Foundations]: initialFoundationsFeeInformation,
      [ProgramType.Essentials]: initialEssentialsFeeInformation,
      [ProgramType.ChallengeA]: initialChallengeAFeeInformation,
      [ProgramType.ChallengeB]: initialChallengeBFeeInformation,
      [ProgramType.ChallengeI]: initialChallengeIFeeInformation,
      [ProgramType.ChallengeIi]: initialChallengeIIFeeInformation,
      [ProgramType.ChallengeIii]: initialChallengeIIIFeeInformation,
      [ProgramType.ChallengeIv]: initialChallengeIVFeeInformation,
    }

    if (!!regionFees && regionFees.length > 0) {
      // For each program in regionFees...
      regionFees.forEach(({ programType, defaultFeeStructures }) => {
        // Get it's setState function
        const setProgramFeeState = programToStateUpdateFnMap[programType]
        // Iterate through the array of fees
        let stateObj = emptyFeeInformation
        defaultFeeStructures.forEach(
          ({ allowEditTotal, defaultTotalAmount, feeType, minimumAmount }) => {
            // Set the fee state values to the passed in values
            const feeObj = {
              [`${feeType}-minimum`]: minimumAmount ?? 0,
              [`${feeType}-default`]: defaultTotalAmount ?? 0,
              [`${feeType}-toggle`]: allowEditTotal ?? false,
              [`${feeType}-isValid`]: true,
            }
            // Build the entire state obj
            stateObj = { ...stateObj, ...feeObj }
          }
        )
        // Finally, set the fee state for the program
        setProgramFeeState((prevState) => ({
          ...prevState,
          ...stateObj,
        }))
        // And update the initial reference
        const initialFeeInformation = programToInitialRefMap[programType]
        initialFeeInformation.current = stateObj
      })
    }
  }, [regionFees])

  /**
   * Initialize contracting hierarchy table with passed in info and update the stored reference.
   */
  useEffect(() => {
    if (regionContractingHierarchies) {
      setLocalContractingHierarchies(regionContractingHierarchies)
      initialContractingHierarchies.current = regionContractingHierarchies
    }
  }, [regionContractingHierarchies])

  /**
   * Fetch Region Options
   */
  useMountEffect(() => {
    addLoadingIds([availableLoadingIds.Regions.fetchRegionOptions])
  })

  const fetchRegionOptions = async () => {
    try {
      const emptyRequestParam = {}

      /** Ensure both endpoints that populate region options are successful */
      const [regionOptions, senderRoleOptions] = await Promise.all([
        regionsApi.fetchRegionOptions(emptyRequestParam),
        meta.fetchRoles(emptyRequestParam),
      ])

      const cleanedSenderRoleOptions: ReceiverRole[] = senderRoleOptions.map(
        ({ roleKey, name }) => ({
          roleKey: roleKey as number,
          name,
        })
      )
      const cleanedRegionOptions = {
        ...regionOptions,
        // Filter out the unused fee types coming from back end
        feeTypes: regionOptions.feeTypes.filter(
          (feeType) => feeType !== 'membership' && feeType !== 'misc2'
        ),
      }

      cleanedRegionOptions.regionManagers = [
        {
          actorKey: -1,
          name: t('RegionFormCard.Manager.Options.NoManager', 'No Manager'),
        },
        ...cleanedRegionOptions.regionManagers,
      ]
      setRegionOptions(cleanedRegionOptions)
      setSenderRoleOptions(cleanedSenderRoleOptions)
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ??
          'Failed to fetch region options.',
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    } finally {
      removeLoadingIds([availableLoadingIds.Regions.fetchRegionOptions])
    }
  }

  useLoadingContext({
    asyncFunction: fetchRegionOptions,
    loadingId: availableLoadingIds.Regions.fetchRegionOptions,
  })

  const [refetchContentTags, setRefetchContentTags] = useState<boolean>(true)

  const [initialContentTags, setInitialContentTags] =
    useState<RegionContentTag[]>()
  const [contentTagsCopy, setContentTagsCopy] = useState<RegionContentTag[]>()
  const [doContentTagsExist, setDoContentTagsExist] = useState<boolean>(false)

  useEffect(() => {
    if (isAddRegionVariant) return

    if (refetchContentTags) {
      addLoadingIds([availableLoadingIds.ContentApi.fetchContentTagOptions])

      setRefetchContentTags(false)
    }
  }, [
    addLoadingIds,
    availableLoadingIds.ContentApi.fetchContentTagOptions,
    isAddRegionVariant,
    refetchContentTags,
    regionKey,
    setSnackbarMessage,
    setSnackbarSeverity,
    setSnackbarState,
    t,
  ])

  const fetchContentTags = async () => {
    try {
      const regionContentTags = await contentApi.fetchContentTagOptions({
        regionKey: +`${regionKey}`,
      })
      const { contentTags } = regionContentTags
      setInitialContentTags(contentTags as RegionContentTag[])
      setContentTagsCopy({ ...(contentTags as RegionContentTag[]) })
      setDoContentTagsExist(true)
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ??
          t(
            'RegionDetails.RegionFormCard.FetchContentTags',
            'Failed to fetch content tags.'
          ),
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setDoContentTagsExist(false)
    } finally {
      removeLoadingIds([availableLoadingIds.ContentApi.fetchContentTagOptions])
    }
  }

  useLoadingContext({
    asyncFunction: fetchContentTags,
    loadingId: availableLoadingIds.ContentApi.fetchContentTagOptions,
  })

  const prepareUpdateTagsRequest = () => {
    const tagsToUpdate: { contentTagKey: number }[] = []
    if (!!initialContentTags) {
      initialContentTags
        .filter((tag) => tag.enabledInRegion === true)
        .forEach((tag) => {
          if (!!tag.contentTagKey) {
            tagsToUpdate.push({ contentTagKey: tag.contentTagKey })
          }
        })
      return tagsToUpdate
    }
  }

  const tagsHaveNotChanged = () => {
    const tagsToUpdate: { contentTagKey: number }[] = []
    if (!!initialContentTags && contentTagsCopy) {
      initialContentTags
        .filter(
          (tag, i) => tag.enabledInRegion === contentTagsCopy[i].enabledInRegion
        )
        .forEach((tag) => {
          if (!!tag.contentTagKey) {
            return tagsToUpdate.push({ contentTagKey: tag.contentTagKey })
          }
        })
      return tagsToUpdate.length === contentTagsCopy.length
    }
  }

  const postContentTags = async (): Promise<boolean> => {
    if (doContentTagsExist) {
      if (tagsHaveNotChanged()) return true // if there were no changes in the previous selection we simply return true

      const updateRegionContentTagsParams = prepareUpdateTagsRequest()
      if (!!updateRegionContentTagsParams) {
        try {
          if (updateRegionContentTagsParams.length === 0) {
            throw new Error(
              t(
                'RegionDetails.RegionFormCard.SaveContentTags',
                'At least one content tag must be checked'
              )
            )
          }
          await regionsApi.updateRegionContentTags({
            regionId: +`${regionKey}`,
            body: { contentTags: updateRegionContentTagsParams },
          })
          // if an option has been selected for Content Tags we return true to save the changes
          return true
        } catch (err) {
          const errorObject = (await extractedErrorObject(err)) ?? {
            code: 'UnknownError',
            message:
              (err as unknown as Error).message ??
              t(
                'RegionDetails.RegionFormCard.SaveContentTags',
                'Failed to save content tags.'
              ),
          }
          setSnackbarState(true)
          setSnackbarMessage(errorObject.message)
          setSnackbarSeverity(SnackbarSeverity.Error)
          // if we do not select any Content Tags option we will generate an error and avoid saving
          return false
        }
      }
    }
    return false
  }

  const handleContentTagsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!!initialContentTags) {
        const userSelectedTags = initialContentTags
        const index = userSelectedTags.findIndex(
          (tag) => tag.contentTagKey === +event.target.value
        )
        if (index !== -1) {
          userSelectedTags[index].enabledInRegion =
            !userSelectedTags[index].enabledInRegion
        }
        setInitialContentTags([...userSelectedTags])
      }
    },
    [initialContentTags]
  )

  const resetContentTags = () => {
    if (!regionKey) return
    setRefetchContentTags(true)
  }

  const handleRegionInfoChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const monthDayMap = {
        semester1StartMonth: 'semester1StartDay',
        semester2StartMonth: 'semester2StartDay',
      }

      switch (event.target.name) {
        /** Reset day fields when month changes to avoid MUI warning. */
        case 'semester1StartMonth':
        case 'semester2StartMonth':
          setRegionInformation({
            ...regionInformation,
            [event.target.name]: event.target.value,
            [monthDayMap[event.target.name]]: -1,
          })
          break
        case 'bypassAutomatedAgreements':
          setRegionInformation({
            ...regionInformation,
            [event.target.name]: event.target.checked,
          })
          break
        case 'isPrivate':
          setRegionInformation({
            ...regionInformation,
            [event.target.name]: event.target.value === TypeRegion.Private,
          })
          break
        case 'achAllowed':
          setRegionInformation({
            ...regionInformation,
            [event.target.name]: event.target.checked,
          })
          break
        default:
          setRegionInformation({
            ...regionInformation,
            [event.target.name]: event.target.value,
          })
      }
    },
    [regionInformation]
  )

  const handleCurrencyCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrencyCode(e.target.value)
    },
    []
  )

  const handleScribblersFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        scribblersFeeInformation,
        setScribblersFeeInformation
      )
    },
    [scribblersFeeInformation]
  )

  const handleFoundationsFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        foundationsFeeInformation,
        setFoundationsFeeInformation
      )
    },
    [foundationsFeeInformation]
  )

  const handleEssentialsFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        essentialsFeeInformation,
        setEssentialsFeeInformation
      )
    },
    [essentialsFeeInformation]
  )

  const handleChallengeAFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        challengeAFeeInformation,
        setChallengeAFeeInformation
      )
    },
    [challengeAFeeInformation]
  )

  const handleChallengeBFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        challengeBFeeInformation,
        setChallengeBFeeInformation
      )
    },
    [challengeBFeeInformation]
  )

  const handleChallengeIFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        challengeIFeeInformation,
        setChallengeIFeeInformation
      )
    },
    [challengeIFeeInformation]
  )

  const handleChallengeIIFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        challengeIIFeeInformation,
        setChallengeIIFeeInformation
      )
    },
    [challengeIIFeeInformation]
  )

  const handleChallengeIIIFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        challengeIIIFeeInformation,
        setChallengeIIIFeeInformation
      )
    },
    [challengeIIIFeeInformation]
  )

  const handleChallengeIVFeeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      genericEventHandler(
        e,
        challengeIVFeeInformation,
        setChallengeIVFeeInformation
      )
    },
    [challengeIVFeeInformation]
  )

  const resetFields = () => {
    setRegionInformation(initialRegionInformation.current)
    if (!isAddRegionVariant) {
      setCurrencyCode(initialCurrencyCode.current)
      setScribblersFeeInformation(initialScribblersFeeInformation.current)
      setFoundationsFeeInformation(initialFoundationsFeeInformation.current)
      setEssentialsFeeInformation(initialEssentialsFeeInformation.current)
      setChallengeAFeeInformation(initialChallengeAFeeInformation.current)
      setChallengeBFeeInformation(initialChallengeBFeeInformation.current)
      setChallengeIFeeInformation(initialChallengeIFeeInformation.current)
      setChallengeIIFeeInformation(initialChallengeIIFeeInformation.current)
      setChallengeIIIFeeInformation(initialChallengeIIIFeeInformation.current)
      setChallengeIVFeeInformation(initialChallengeIVFeeInformation.current)
      setLocalContractingHierarchies(initialContractingHierarchies.current)
    }
  }

  const handleEdit = () => {
    setIsFieldDisabled(false)
  }

  const handleCancel = () => {
    setIsFieldDisabled(true)
    setContractingHierarchiesToAdd([])
    setContractingHierarchiesToDelete([])
    resetContentTags()
    resetFields()
    handleCancelFromProps?.()
    setRegionInformationValidity(initialRegionValidity)
  }

  const handleDelete = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    /** TODO: Connect to delete region API call */
    alert(
      'Coming Soon! See https://projekt202.atlassian.net/browse/CCP1-2187 for more information'
    )
    setIsConfirmationModalVisible(false)
    setIsFieldDisabled(true)
    resetFields()
  }

  const handleSave = async () => {
    if ((regionInformation.actorKey ?? 0) <= 0) {
      regionInformation.actorKey = undefined
    }
    if (!fieldsDoValidate()) {
      const e = {
        code: 'UnknownError',
        message: errorMessage,
      }
      setSnackbarState(true)
      setSnackbarMessage(e.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      return
    }

    if (validateSemesterStartDates(regionInformation)) {
      setRegionInformationValidity({
        ...regionInformationValidity,
        semesterOneStartMonthIsValid: { input: true, sameDate: true },
        semesterOneStartDayIsValid: { input: true, sameDate: true },
        semesterTwoStartMonthIsValid: { input: true, sameDate: true },
        semesterTwoStartDayIsValid: { input: true, sameDate: true },
      })
      const errorMessage = t(
        'RegionDetails.RegionFormCard.SemesterDates.SameDate',
        'Semester start dates cannot be the same date'
      )
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
      return
    }

    try {
      setSaveButtonDisabled(true)
      if (isAddRegionVariant) {
        const createRegionResponse = await regionsApi.createRegion({
          body: {
            name: regionInformation.name,
            semesterOneStartMonth: regionInformation.semesterOneStartMonth,
            semesterOneStartDay: regionInformation.semesterOneStartDay,
            semesterTwoStartMonth: regionInformation.semesterTwoStartMonth,
            semesterTwoStartDay: regionInformation.semesterTwoStartDay,
            // Parent region is optional so if it has no value (-1) then we send 'undefined' in the request body
            parentRegionsKey:
              regionInformation.parentRegionsKey === -1
                ? undefined
                : regionInformation.parentRegionsKey,
            isPrivate: regionInformation.isPrivate,
            // Cycle is optional so if it has no value (-1) then we send 'undefined' in the request body
            cycle:
              regionInformation.cycle === -1
                ? undefined
                : regionInformation.cycle,
            actorKey: regionInformation.actorKey as number,
            bypassAutomatedAgreements: false,
            discourseUrl: regionInformation.discourseUrl,
            aYCycleStartYear: regionInformation.aYCycleStartYear,
            achAllowed: regionInformation.achAllowed,
            s1LicensingDueOffset: regionInformation.s1LicensingDueOffset,
            s1LicensingLateOffset: regionInformation.s1LicensingLateOffset,
            s2LicensingDueOffset: regionInformation.s2LicensingDueOffset,
            s2LicensingLateOffset: regionInformation.s2LicensingLateOffset,
          },
        })
        setIsFieldDisabled(true)
        setSnackbarState(true)
        setSnackbarMessage(
          t(
            'Regions.RegionForm.SuccessMessage.Created',
            'Region successfully created.'
          )
        )
        setSnackbarSeverity?.(SnackbarSeverity.Success)
        navigate(
          {
            pathname: `/admin/regions/region-details/${createRegionResponse.regionsKey}`,
          },
          {
            /** Navigation */
          }
        )
      } else {
        const isCreatedContentTags = await postContentTags()
        if (!isCreatedContentTags) return

        /** 1. Handle region contracting hierarchy additions */
        if (contractingHierarchiesToAdd.length > 0) {
          const arrayOfPostCalls = contractingHierarchiesToAdd.map(
            ({ senderRoleKey, receiverRoleKey }) =>
              regionsApi.createRegionContractingHierarchies({
                regionKey: +`${regionKey}`,
                body: {
                  senderRoleKey,
                  receiverRoleKey,
                },
              })
          )

          // Make all calls to add a contracting hierarchy in parallel
          await Promise.all(arrayOfPostCalls)

          // Clear the list upon success
          setContractingHierarchiesToAdd([])
        }

        /** 2. Handle region contracting hierarchy removals */
        if (contractingHierarchiesToDelete.length > 0) {
          const arrayOfDeleteCalls = contractingHierarchiesToDelete.map(
            ({ senderRoleKey, receiverRoleKey }) =>
              regionsApi.deleteRegionContractingHierarchies({
                regionKey: +`${regionKey}`,
                body: {
                  senderRoleKey,
                  receiverRoleKey,
                },
              })
          )

          // Make all calls to delete a contracting hierarchy in parallel
          await Promise.all(arrayOfDeleteCalls)

          // Clear the list upon success
          setContractingHierarchiesToDelete([])
        }

        /** 3. Handle region info update */
        if (
          !shallowEqual(initialRegionInformation.current, regionInformation) ||
          initialCurrencyCode.current !== currencyCode ||
          regionInformation.cycle === 0
        ) {
          let updateRegionInfo: RegionUpdate = {
            regionsKey: +`${regionKey}`,
            name: regionInformation.name,
            currency: currencyCode || undefined,
            semesterOneStartMonth: regionInformation.semesterOneStartMonth,
            semesterOneStartDay: regionInformation.semesterOneStartDay,
            semesterTwoStartMonth: regionInformation.semesterTwoStartMonth,
            semesterTwoStartDay: regionInformation.semesterTwoStartDay,
            // Parent region is optional so if it has no value (-1) then we send 'undefined' in the request body
            parentRegionsKey:
              regionInformation.parentRegionsKey === -1
                ? undefined
                : regionInformation.parentRegionsKey,
            isPrivate: regionInformation.isPrivate,
            // Cycle is optional so if it has no value (-1) then we send 'undefined' in the request body
            cycle:
              regionInformation.cycle === -1
                ? undefined
                : regionInformation.cycle,
            actorKey: regionInformation.actorKey,
            bypassAutomatedAgreements:
              regionInformation.bypassAutomatedAgreements,
            discourseUrl: regionInformation.discourseUrl,
            achAllowed: regionInformation.achAllowed,
            aYCycleStartYear: regionInformation.aYCycleStartYear,
            s1LicensingDueOffset: regionInformation.s1LicensingDueOffset,
            s1LicensingLateOffset: regionInformation.s1LicensingLateOffset,
            s2LicensingDueOffset: regionInformation.s2LicensingDueOffset,
            s2LicensingLateOffset: regionInformation.s2LicensingLateOffset,
          }

          // Handle edge case where region may have an invalid cycle of 0
          if (regionInformation.cycle === 0) {
            updateRegionInfo = { ...updateRegionInfo, cycle: 1 }
          }

          await regionsApi.updateRegion({ body: updateRegionInfo })
        }

        /** 4. Handle region fees update */
        if (
          !shallowEqual(
            initialScribblersFeeInformation.current,
            scribblersFeeInformation
          ) ||
          !shallowEqual(
            initialFoundationsFeeInformation.current,
            foundationsFeeInformation
          ) ||
          !shallowEqual(
            initialEssentialsFeeInformation.current,
            essentialsFeeInformation
          ) ||
          !shallowEqual(
            initialChallengeAFeeInformation.current,
            challengeAFeeInformation
          ) ||
          !shallowEqual(
            initialChallengeBFeeInformation.current,
            challengeBFeeInformation
          ) ||
          !shallowEqual(
            initialChallengeIFeeInformation.current,
            challengeIFeeInformation
          ) ||
          !shallowEqual(
            initialChallengeIIFeeInformation.current,
            challengeIIFeeInformation
          ) ||
          !shallowEqual(
            initialChallengeIIIFeeInformation.current,
            challengeIIIFeeInformation
          ) ||
          !shallowEqual(
            initialChallengeIVFeeInformation.current,
            challengeIVFeeInformation
          )
        ) {
          // Use the feeTypes and programTypes from the region options call, fallback to empty
          const feeTypes = regionOptions?.feeTypes ?? []
          const programTypes = regionOptions?.programTypes ?? []

          const programToStateMap = {
            [ProgramType.Scribblers]: scribblersFeeInformation,
            [ProgramType.Foundations]: foundationsFeeInformation,
            [ProgramType.Essentials]: essentialsFeeInformation,
            [ProgramType.ChallengeA]: challengeAFeeInformation,
            [ProgramType.ChallengeB]: challengeBFeeInformation,
            [ProgramType.ChallengeI]: challengeIFeeInformation,
            [ProgramType.ChallengeIi]: challengeIIFeeInformation,
            [ProgramType.ChallengeIii]: challengeIIIFeeInformation,
            [ProgramType.ChallengeIv]: challengeIVFeeInformation,
          }

          // Build the region fees update array
          const regionFees = programTypes.map((programType) => {
            const defaultFeeStructures = feeTypes.map((feeType) => {
              return {
                feeType,
                minimumAmount: programToStateMap[programType][
                  `${feeType}-minimum`
                ] as number,
                defaultTotalAmount: programToStateMap[programType][
                  `${feeType}-default`
                ] as number,
                allowEditTotal: programToStateMap[programType][
                  `${feeType}-toggle`
                ] as boolean,
              }
            })
            return {
              programType,
              defaultFeeStructures,
            }
          })

          await regionsApi.setRegionFees({
            regionKey: +`${regionKey}`,
            body: {
              regionFees,
            },
          })
        }

        setIsFieldDisabled(true)
        setSnackbarState(true)
        setSnackbarMessage(
          t(
            'Regions.RegionForm.SuccessMessage.Updated',
            'Region successfully updated.'
          )
        )
        setSnackbarSeverity?.(SnackbarSeverity.Success)
        handleSaveFromProps?.()
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message ?? errorMessage,
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    } finally {
      setSaveButtonDisabled(false)
    }
  }

  const validateFees = (programFeeState: FeeInformation) => {
    const maxPercentage = 100
    return {
      'application-isValid':
        programFeeState['application-minimum'] <=
        programFeeState['application-default'],
      'enrollment-isValid':
        programFeeState['enrollment-minimum'] <=
        programFeeState['enrollment-default'],
      'supplyFeeFirstStudent-isValid':
        programFeeState['supplyFeeFirstStudent-minimum'] <=
        programFeeState['supplyFeeFirstStudent-default'],
      'supplyFeeAdditionalStudent-isValid':
        programFeeState['supplyFeeAdditionalStudent-minimum'] <=
        programFeeState['supplyFeeAdditionalStudent-default'],
      'tuition-isValid':
        programFeeState['tuition-minimum'] <=
        programFeeState['tuition-default'],
      'localFeeFirstStudent-isValid':
        programFeeState['localFeeFirstStudent-minimum'] <=
        programFeeState['localFeeFirstStudent-default'],
      'localFeeAdditionalStudent-isValid':
        programFeeState['localFeeAdditionalStudent-minimum'] <=
        programFeeState['localFeeAdditionalStudent-default'],
      'facilityFeeFirstStudent-isValid':
        programFeeState['facilityFeeFirstStudent-minimum'] <=
        programFeeState['facilityFeeFirstStudent-default'],
      'facilityFeeAdditionalStudent-isValid':
        programFeeState['facilityFeeAdditionalStudent-minimum'] <=
        programFeeState['facilityFeeAdditionalStudent-default'],
      'miscFeeFirstStudent-isValid':
        programFeeState['miscFeeFirstStudent-minimum'] <=
        programFeeState['miscFeeFirstStudent-default'],
      'miscFeeAdditionalStudent-isValid':
        programFeeState['miscFeeAdditionalStudent-minimum'] <=
        programFeeState['miscFeeAdditionalStudent-default'],
      'subLicensedTutorPercentage-isValid':
        programFeeState['subLicensedTutorPercentage-minimum'] <=
        programFeeState['subLicensedTutorPercentage-default'],
      'subLicensedTutorPercentage-isValidPercentage':
        programFeeState['subLicensedTutorPercentage-minimum'] <=
          maxPercentage &&
        programFeeState['subLicensedTutorPercentage-default'] <= maxPercentage,
      'semesterOneLicensingFee-isValid':
        programFeeState['semesterOneLicensingFee-minimum'] <=
        programFeeState['semesterOneLicensingFee-default'],
      'discountSemesterOneLicensingFee-isValid':
        programFeeState['discountSemesterOneLicensingFee-minimum'] <=
        programFeeState['discountSemesterOneLicensingFee-default'],
      'semesterTwoLicensingFee-isValid':
        programFeeState['semesterTwoLicensingFee-minimum'] <=
        programFeeState['semesterTwoLicensingFee-default'],
      'discountSemesterTwoLicensingFee-isValid':
        programFeeState['discountSemesterTwoLicensingFee-minimum'] <=
        programFeeState['discountSemesterTwoLicensingFee-default'],
      'multiStudentApplicationDiscount-isValid':
        programFeeState['multiStudentApplicationDiscount-minimum'] <=
        programFeeState['multiStudentApplicationDiscount-default'],
    }
  }

  const setFeeValidityPerProgram = (
    programFeeState: FeeInformation,
    setProgramFeeState: React.Dispatch<React.SetStateAction<FeeInformation>>
  ) => {
    const validationObj = validateFees(programFeeState)
    setProgramFeeState({
      ...programFeeState,
      ...validationObj,
    })
  }

  const setFieldValidity = () => {
    setRegionInformationValidity({
      regionNameIsValid: { input: !!regionInformation.name },
      actorKeyIsValid: { input: isValidSelection(regionInformation.actorKey) },
      semesterOneStartMonthIsValid: {
        input: isValidSelection(regionInformation.semesterOneStartMonth),
      },
      semesterOneStartDayIsValid: {
        input: isValidSelection(regionInformation.semesterTwoStartDay),
      },
      semesterTwoStartMonthIsValid: {
        input: isValidSelection(regionInformation.semesterTwoStartMonth),
      },
      semesterTwoStartDayIsValid: {
        input: isValidSelection(regionInformation.semesterTwoStartDay),
      },
      discourseUrlIsValid: { input: !!regionInformation.discourseUrl },
      academicYearCycleStartYearIsValid: {
        input: !!regionInformation.aYCycleStartYear,
      },
      s1LicensingDueOffset: {
        input: !!regionInformation.s1LicensingDueOffset,
        afterMin:
          regionInformation.s1LicensingDueOffset >=
          licensingDueDateRangeMinimum,
        beforeMax:
          regionInformation.s1LicensingDueOffset <=
          licensingDueDateRangeMaximum,
      },
      s1LicensingLateOffset: {
        input: !!regionInformation.s1LicensingLateOffset,
        afterMin:
          regionInformation.s1LicensingLateOffset >=
          licensingDueDateRangeMinimum,
        beforeMax:
          regionInformation.s1LicensingLateOffset <=
          licensingDueDateRangeMaximum,
      },
      s2LicensingDueOffset: {
        input: !!regionInformation.s2LicensingDueOffset,
        afterMin:
          regionInformation.s2LicensingDueOffset >=
          licensingDueDateRangeMinimum,
        beforeMax:
          regionInformation.s2LicensingDueOffset <=
          licensingDueDateRangeMaximum,
      },
      s2LicensingLateOffset: {
        input: !!regionInformation.s2LicensingLateOffset,
        afterMin:
          regionInformation.s2LicensingLateOffset >=
          licensingDueDateRangeMinimum,
        beforeMax:
          regionInformation.s2LicensingLateOffset <=
          licensingDueDateRangeMaximum,
      },
    })
    if (!isAddRegionVariant) {
      setFeeValidityPerProgram(
        scribblersFeeInformation,
        setScribblersFeeInformation
      )
      setFeeValidityPerProgram(
        foundationsFeeInformation,
        setFoundationsFeeInformation
      )
      setFeeValidityPerProgram(
        essentialsFeeInformation,
        setEssentialsFeeInformation
      )
      setFeeValidityPerProgram(
        challengeAFeeInformation,
        setChallengeAFeeInformation
      )
      setFeeValidityPerProgram(
        challengeBFeeInformation,
        setChallengeBFeeInformation
      )
      setFeeValidityPerProgram(
        challengeIFeeInformation,
        setChallengeIFeeInformation
      )
      setFeeValidityPerProgram(
        challengeIIFeeInformation,
        setChallengeIIFeeInformation
      )
      setFeeValidityPerProgram(
        challengeIIIFeeInformation,
        setChallengeIIIFeeInformation
      )
      setFeeValidityPerProgram(
        challengeIVFeeInformation,
        setChallengeIVFeeInformation
      )
    }
  }

  const fieldsDoValidate = () => {
    setFieldValidity()
    const programFeeStates = [
      scribblersFeeInformation,
      foundationsFeeInformation,
      essentialsFeeInformation,
      challengeAFeeInformation,
      challengeBFeeInformation,
      challengeIFeeInformation,
      challengeIIFeeInformation,
      challengeIIIFeeInformation,
      challengeIVFeeInformation,
    ]

    const allFeesValidInProgram = programFeeStates.map((state) => {
      const validationObj = validateFees(state)
      return Object.values(validationObj).every(Boolean)
    })

    return (
      !!regionInformation.name &&
      isValidSelection(regionInformation.actorKey) &&
      isValidSelection(regionInformation.semesterOneStartMonth) &&
      isValidSelection(regionInformation.semesterOneStartDay) &&
      isValidSelection(regionInformation.semesterTwoStartMonth) &&
      isValidSelection(regionInformation.semesterTwoStartDay) &&
      isValidLicensingDueDateRanges(regionInformation.s1LicensingDueOffset) &&
      isValidLicensingDueDateRanges(regionInformation.s1LicensingLateOffset) &&
      isValidLicensingDueDateRanges(regionInformation.s2LicensingDueOffset) &&
      isValidLicensingDueDateRanges(regionInformation.s2LicensingLateOffset) &&
      (isAddRegionVariant || allFeesValidInProgram.every(Boolean))
    )
  }

  const validateSemesterStartDates = (regionInformation: Region): boolean => {
    const {
      semesterOneStartDay,
      semesterTwoStartDay,
      semesterOneStartMonth,
      semesterTwoStartMonth,
    } = regionInformation

    const isSemesterStartDayEqual = semesterOneStartDay === semesterTwoStartDay

    const isSemesterStartMonthEqual =
      semesterOneStartMonth === semesterTwoStartMonth

    return isSemesterStartMonthEqual && isSemesterStartDayEqual
  }

  const PageButtons = (
    <Box mb={2} maxWidth={1200}>
      <CardFormHeader
        header={null}
        buttons={
          isFieldDisabled ? (
            <Can I="edit" on="Region" ability={permissionAbility}>
              <ContainedButton
                id="edit"
                variant={ContainedButtonVariant.Edit}
                onClick={handleEdit}
              />
            </Can>
          ) : (
            <>
              <TextButton
                id="cancel"
                onClick={handleCancel}
                variant={TextButtonVariant.Cancel}
              />
              {!isAddRegionVariant && (
                <Can I="delete" on="Region" ability={permissionAbility}>
                  <ContainedButton
                    id="delete"
                    variant={ContainedButtonVariant.Delete}
                    onClick={() => setIsConfirmationModalVisible(true)}
                  />
                </Can>
              )}
              <ContainedButton
                id="save"
                disabled={saveButtonDisabled}
                variant={ContainedButtonVariant.Save}
                onClick={handleSave}
              />
            </>
          )
        }
      />
    </Box>
  )

  const dialogTitle = `${t(
    'Regions.RegionForm.Delete.Confirmation.Header',
    'Are you sure you want to delete the'
  )} ${regionDetails?.name} ${t(
    'Regions.RegionForm.Delete.Confirmation.HeaderExtra',
    'region'
  )}?`

  return (
    <>
      <BasicModal
        isOpen={isConfirmationModalVisible}
        dialogTitle={dialogTitle}
        handleFormSubmit={handleDelete}
        ariaLabel="delete confirmation modal"
        maxWidth="xs"
        dialogContent={
          <Box maxWidth={300} mx="auto" textAlign="center">
            <Typography variant="body1">
              {t(
                'Regions.RegionForm.Delete.Confirmation.Body',
                'All Fee information and Agreement Permissions will be permanently lost.'
              )}
            </Typography>
          </Box>
        }
        dialogActions={
          <ActionButtons
            primaryButtonLabel={ContainedButtonVariant.YesDelete}
            secondaryButtonLabel={TextButtonVariant.NoCancel}
            secondaryClick={() => setIsConfirmationModalVisible(false)}
          />
        }
      />
      {PageButtons}
      <RegionInformationCard
        isFieldDisabled={isFieldDisabled}
        regionInformation={regionInformation}
        regionInformationValidity={regionInformationValidity}
        parentRegions={regionOptions?.parentRegions ?? []}
        regionManagers={regionOptions?.regionManagers ?? []}
        handleRegionInfoChange={handleRegionInfoChange}
      />

      {!isAddRegionVariant && (
        <ContentTagsCard
          contentTags={initialContentTags}
          isFieldDisabled={isFieldDisabled}
          handleContentTagsChange={handleContentTagsChange}
        />
      )}

      {!isAddRegionVariant && (
        <>
          <RegionFeeStructureCard
            programTypes={regionOptions?.programTypes ?? []}
            feeTypes={regionOptions?.feeTypes ?? []}
            isFieldDisabled={isFieldDisabled}
            currencyCode={currencyCode}
            handleCurrencyCodeChange={handleCurrencyCodeChange}
            scribblersFeeInformation={scribblersFeeInformation}
            foundationsFeeInformation={foundationsFeeInformation}
            essentialsFeeInformation={essentialsFeeInformation}
            challengeAFeeInformation={challengeAFeeInformation}
            challengeBFeeInformation={challengeBFeeInformation}
            challengeIFeeInformation={challengeIFeeInformation}
            challengeIIFeeInformation={challengeIIFeeInformation}
            challengeIIIFeeInformation={challengeIIIFeeInformation}
            challengeIVFeeInformation={challengeIVFeeInformation}
            handleScribblersFeeChange={handleScribblersFeeChange}
            handleFoundationsFeeChange={handleFoundationsFeeChange}
            handleEssentialsFeeChange={handleEssentialsFeeChange}
            handleChallengeAFeeChange={handleChallengeAFeeChange}
            handleChallengeBFeeChange={handleChallengeBFeeChange}
            handleChallengeIFeeChange={handleChallengeIFeeChange}
            handleChallengeIIFeeChange={handleChallengeIIFeeChange}
            handleChallengeIIIFeeChange={handleChallengeIIIFeeChange}
            handleChallengeIVFeeChange={handleChallengeIVFeeChange}
          />
          <RegionAgreementPermissionCard
            isFieldDisabled={isFieldDisabled}
            bypassAutomatedAgreements={
              regionInformation.bypassAutomatedAgreements
            }
            handleRegionInfoChange={handleRegionInfoChange}
            senderRoles={senderRoleOptions ?? []}
            receiverRoles={regionOptions?.receiverRoles ?? []}
            localContractingHierarchies={localContractingHierarchies}
            setLocalContractingHierarchies={setLocalContractingHierarchies}
            setContractingHierarchiesToAdd={setContractingHierarchiesToAdd}
            setContractingHierarchiesToDelete={
              setContractingHierarchiesToDelete
            }
          />
          {PageButtons}
        </>
      )}
    </>
  )
}

export default RegionFormCard
