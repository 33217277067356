import React, { useCallback } from 'react'
import Snackbar from '@mui/material/Snackbar'
import CheckCircle from '@mui/icons-material/CheckCircle'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'
import WrappedAlert from './WrappedAlert'

export enum SnackbarSeverity {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  marginTop: theme.spacing(6),
}))

export const SuccessIcon = (): JSX.Element => {
  const theme = useTheme()

  return (
    <CheckCircle
      style={{
        fill: theme.palette.secondary.main,
      }}
    />
  )
}

const SnackbarAlert: React.FC = () => {
  const { open, message, severity, handleClose } = useSnackbarContext()

  const onClose = useCallback(() => handleClose(), [handleClose])

  return (
    <StyledSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
    >
      <WrappedAlert severity={severity} message={message} onClose={onClose} />
    </StyledSnackbar>
  )
}

export default SnackbarAlert
