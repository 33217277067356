import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

interface NoResultsFoundProps {
  message?: string
}

export const NoResultsFound: React.FC<NoResultsFoundProps> = (props) => {
  const { t } = useTranslation()
  return (
    <TableRow>
      <TableCell
        sx={{
          borderBottom: 'none',
        }}
      >
        <Typography variant="body1" component="p">
          {props.message
            ? props.message
            : t('NoResultsFound.Default', 'No Results Found')}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default NoResultsFound
