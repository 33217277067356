import { EmailFieldsValidationMessageTypes } from '../components/Modals/ChangeEmailModal'

/* I put this in a folder called helpers but once MR!18 
(https://gitlab.classicaltesting.net/bnr/project-one-frontend/-/merge_requests/18)
is merged, there will be a utilities folder that I will put this in. */
export function validateEmail(email: string): boolean {
  const re = /^[^@]+@[^@]+\.[a-zA-Z0-9]{2,}$/
  return !/\s/.test(email) && re.test(email)
}

export function validateEmailText(str: string): string {
  let error = ''
  str = str.trim()
  if (str === '') {
    return (error = 'noEmail')
  }
  //  Spaces are not accepted in the string
  if (/\s/.test(str)) {
    return (error = `${EmailFieldsValidationMessageTypes.NoSpaces}`)
  }
  if (!validateEmail(str)) {
    // if the @ does not exist in the string
    if (str.search('@') === -1) {
      error = `${error} noAtSign`
    }
    // Search for the extension .com,net .etc
    !/\.([a-zA-Z0-9]{2,})+$/.test(str) && (error = `${error} noExtension`)

    error = error.trim()
    const doubleMessage: string[] = error.split(' ')
    if (doubleMessage.length > 1) {
      doubleMessage[0] === 'noAtSign' &&
        doubleMessage[1] === 'noExtension' &&
        (error = 'noEmailNoSign')
    }
    error === '' ? (error = 'default') : (error = `${error}`)
  }

  return error
}

export function isEmailValid(email: string): boolean {
  return !validateEmailText(email)
}
