import { Middleware, ResponseContext } from '../swagger/runtime'

export class ChangeActorMiddleware implements Middleware {
  constructor(public logout: () => Promise<boolean>) {}
  async post?(context: ResponseContext): Promise<Response | void> {
    const body = await context.response.text()

    if (body.includes('You have been changed to a parent role')) {
      await this.logout()
    }
  }
}
