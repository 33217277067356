import { useTranslation } from 'react-i18next'
import { extractedErrorObject, transcriptsApi } from '../api/swagger'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'
import useLoadingContext from './useLoadingContext'
import { useLoadingIds } from './useLoadingIds'
import { useTranscriptContext } from '../components/Context/TranscriptContext'
import { useMountEffect } from './useMountEffect'
import { useContext } from 'react'
import { LoadingContext } from '../components/Context/LoadingContext'
import { FetchTranscriptsRequest, PaginationResponse } from '../swagger'
import { useAuth } from '../components/Routes/AuthProvider'

export const useFetchTranscripts = (
  { ...fetchTranscriptParams }: FetchTranscriptsRequest,
  updatePagination: (pagination: PaginationResponse) => void
): {
  loadingId: string
} => {
  const { UseFetchTranscripts } = useLoadingIds()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { updateTranscripts } = useTranscriptContext()
  const loadingId = UseFetchTranscripts.fetchTranscripts
  const { addLoadingIds } = useContext(LoadingContext)
  const { t } = useTranslation()
  const { userDetails, permissionAbility } = useAuth()
  const errorMessage = t(
    'Hooks.FetchTranscripts.Error',
    'An error occurred while attempting to load transcripts'
  )

  const fetchTranscripts = async () => {
    try {
      const { transcripts, pagination } = await transcriptsApi.fetchTranscripts(
        {
          ...fetchTranscriptParams,
        }
      )
      updateTranscripts(transcripts)
      pagination && updatePagination(pagination)
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: fetchTranscripts,
    loadingId,
  })

  useMountEffect(() => {
    if (
      userDetails.actingAs === 'parent' ||
      permissionAbility.can('view', 'Transcript')
    )
      addLoadingIds([loadingId])
  })

  return { loadingId }
}
