import Card from '@mui/material/Card'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CardFormHeader from './CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import Logo from '../../Images/Branding/cc-logo-white.svg'
import LogoBlue from '../../Images/Branding/cc-logo-blue.svg'
import Typography from '@mui/material/Typography'
import LinkIcon from '@mui/icons-material/Link'
import { FetchEventsResponse } from '../../swagger'
import { displayFormattedDate } from '../../utils/displayFormattedDate'
import { FormDivider } from '../Elements/FormDivider'
import { createSpacetime } from '../Events/Events'
import { Box, CardActionArea, CardMedia, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import LoadingProgress from '../Elements/LoadingProgress'
import { PublicUrlLink } from '../Events/EventInformationFields'

const Span = styled('span')(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

export const TodaysEventCard: React.FunctionComponent<{
  todaysEvent: FetchEventsResponse
  headerLabel: string
  isLoading: boolean
  onClick: () => void
}> = ({ todaysEvent, headerLabel, onClick, isLoading }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const publicUrl = todaysEvent?.publicUrl ?? '#'

  if (isLoading) return <LoadingProgress />

  return (
    <Box mb={3}>
      <CardFormHeader
        headerContainerProps={{ margin: 0 }}
        header={
          <Header
            id="eventsFormHeader"
            variant={HeaderVariant.Card}
            headerName={headerLabel}
            component="h2"
          />
        }
      />
      <Card>
        <CardActionArea
          onClick={onClick}
          sx={{
            padding: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CardMedia
            component="img"
            image={todaysEvent?.isOnline ? LogoBlue : Logo}
            alt={t('Events.EventImage.AltText', 'Event Image')}
            sx={{
              width: 240,
              padding: theme.spacing(6, 5),
              background: todaysEvent?.isOnline
                ? theme.palette.customBackground.logo
                : theme.palette.primary.main,
            }}
          />
          <Box
            sx={{
              flexDirection: 'column',
              margin: theme.spacing(0, 3),
              flex: 1,
            }}
          >
            <Typography
              color={theme.palette.primary.main}
              variant="h5"
              component={'h3'}
            >
              {todaysEvent?.name}
            </Typography>
            <Typography
              color={theme.palette.primary.main}
              variant={'body1'}
              component={'h4'}
            >
              <Span>{`${t(
                'Events.NextEvent.EventType',
                'Event Type'
              )}: `}</Span>
              {todaysEvent?.eventType}
              <br />
              <Span>{`${t('Events.NextEvent.EventDate', 'Date')}: `}</Span>
              {displayFormattedDate(
                todaysEvent?.startDate?.toISOString(),
                todaysEvent?.endDate?.toISOString() ?? ''
              )}{' '}
              <br />
              <Span>{`${t(
                'Events.NextEvent.EventTime',
                'Event Time'
              )}: `}</Span>
              {`${createSpacetime(todaysEvent).time()} - ${createSpacetime(
                todaysEvent,
                false
              ).time()} ${todaysEvent?.timezone}`}{' '}
              <br />
              <Span>{`${t(
                'Events.NextEvent.RegistrationOpen',
                'Registration'
              )}: `}</Span>
              {todaysEvent?.registrationOpen
                ? t('Events.NextEvent.RegistrationStatus.Open', 'Open')
                : t(
                    'Events.NextEvent.RegistrationStatus.Closed',
                    'Closed'
                  )}{' '}
              <br />
              <Span>{`${t(
                'Events.NextEvent.EventLocation',
                'Location'
              )}: `}</Span>
              {todaysEvent?.isOnline
                ? t('Events.NextEvent.Location.Global', 'Global')
                : t('Events.NextEvent.Location.Local', 'Local')}{' '}
              <br />
              <Span>{`${t(
                'Events.NextEvent.CreatedBy',
                'Created By'
              )}: `}</Span>
              {todaysEvent?.createdBy}
            </Typography>
            <FormDivider />
            <Typography
              color={theme.palette.primary.main}
              variant={'body1'}
              component={'h5'}
            >
              <Box>
                <LinkIcon
                  aria-label={t(
                    'Events.NextEvent.Link.Eventbrite',
                    'Eventbrite Link'
                  )}
                />
                <PublicUrlLink id={publicUrl} href={publicUrl} target="_blank">
                  {publicUrl}
                </PublicUrlLink>
              </Box>
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  )
}

export default TodaysEventCard
