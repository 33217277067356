import React from 'react'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import { TooltipRenderProps } from 'react-joyride'
import Header, { HeaderVariant } from './Header'
import Close from '@mui/icons-material/Close'
import CardFormHeader from '../Card/CardFormHeader'

export const JoyrideTooltip: React.FC<TooltipRenderProps> = ({
  primaryProps,
  step,
  tooltipProps,
}) => {
  return (
    <Box
      {...tooltipProps}
      border={'none'}
      maxWidth={420}
      minWidth={290}
      overflow="hidden"
      component={Paper}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Box py={2} px={{ xs: 2, lg: 3 }}>
        <>
          {step.title && (
            <CardFormHeader
              headerContainerProps={{
                marginTop: 0,
                marginLeft: 0,
              }}
              header={
                <Header
                  css={{
                    padding: 0,
                  }}
                  variant={HeaderVariant.Card}
                  headerName={`${step.title}`}
                />
              }
            />
          )}
          <Box pb={1}>
            {step.content && (
              <Typography variant="body1">{step.content}</Typography>
            )}
          </Box>
        </>
      </Box>
      <IconButton
        aria-label="close"
        sx={{
          position: 'relative',
          right: 4,
          top: 4,
          height: '30px',
        }}
        onClick={primaryProps.onClick}
      >
        <Close />
      </IconButton>
    </Box>
  )
}

export default JoyrideTooltip
