import { Card, TextField, Box, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserProfile } from '../../swagger'
import Header, { HeaderVariant } from '../Elements/Header'
import ChangeEmailModal from '../Modals/ChangeEmailModal'
import CardFormHeader from './CardFormHeader'

interface UserEmailAddressCardProps {
  userInfo: UserProfile
}

const UserEmailAddressCard: React.FunctionComponent<
  UserEmailAddressCardProps
> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isChangeEmailModalOpen, setIsChangeEmailModalOpen] = useState(false)
  const { userInfo } = props

  const onChangeEmailModalClose = () => {
    setIsChangeEmailModalOpen(false)
  }

  return (
    <>
      <ChangeEmailModal
        isOpen={isChangeEmailModalOpen}
        onClose={onChangeEmailModalClose}
      />
      <Card>
        <CardFormHeader
          header={
            <Header
              variant={HeaderVariant.Card}
              id="emailAddress"
              headerName={t('AccountSettingsTab.EmailAddress', 'Email Address')}
              component="h3"
            />
          }
          headerContainerProps={{ paddingX: 1 }}
        />
        <Box
          mb={4}
          sx={{
            padding: theme.spacing(0, 3, 3),
          }}
        >
          <Box display="flex">
            <Box width="100%" pr={4}>
              <TextField
                fullWidth
                id="loginEmailField"
                label={t('AccountSettingsTab.LoginEmail', 'Login Email')}
                variant="filled"
                disabled={true}
                value={userInfo.username}
              />
            </Box>
            <Box width="100%">
              {userInfo.email && (
                <TextField
                  fullWidth
                  id="classicalConversationsEmail"
                  label={t(
                    'AccountSettingsTab.ClassicalConversationsEmail',
                    'Classical Conversations Email'
                  )}
                  variant="filled"
                  helperText={t(
                    'AccountSettingsTab.EmailHelperText',
                    'This secondary email was created for you by the CC team'
                  )}
                  disabled={true}
                  defaultValue={userInfo.email}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default UserEmailAddressCard
