import AppBar from '@mui/material/AppBar'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Menu from '@mui/icons-material/Menu'
// import Notifications from '@mui/icons-material/Notifications'
import React from 'react'
//import { useTranslation } from 'react-i18next'
import Logo from '../../Images/Branding/cc-logo-white.svg'
import { useTheme } from '@mui/material'

const drawerWidth = 240

interface AppbarProps {
  title: string
  open: boolean
  toggleOpen: () => void
}

const Appbar: React.FunctionComponent<AppbarProps> = (props) => {
  // commented out because of linting uncomment when notification icon is being used again
  //  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <AppBar
      data-testid="appbar"
      position="fixed"
      sx={{
        /**
         * HelpScout beacon uses a zIndex of 1049 so we set the appbar's zIndex lower to avoid overlap.
         */
        zIndex: 1020,
        ...(props.open && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        }),
      }}
    >
      <Toolbar
        sx={{
          background: theme.palette.primary.main,
          color: 'white',
          padding: 0,
          height: theme.spacing(8),
          [theme.breakpoints.down('sm')]: {
            height: theme.spacing(7),
          },
        }}
      >
        <Hidden lgUp>
          <IconButton
            style={{
              padding: theme.spacing(2),
            }}
            data-testid="appbarMenuIcon"
            onClick={props.toggleOpen}
            color="inherit"
            aria-label="Toggle drawer"
          >
            <Menu />
          </IconButton>
          <img
            alt="Classical Conversations"
            src={Logo}
            width={theme.spacing(3)}
          />
        </Hidden>

        <Typography
          variant="h6"
          component="h1"
          noWrap
          style={{
            marginLeft: theme.spacing(4),
          }}
        >
          {props.title}
        </Typography>
        {/* commenting out notifications for now since there is no current functionality 
        <IconButton
          style={{
            padding: 0,
            marginLeft: 'auto',
            [theme.breakpoints.down('md')]: {
              marginRight: theme.spacing(2),
            },
            [theme.breakpoints.up('lg')]: {
              marginRight: theme.spacing(4),
            },
          }}

          aria-label={t(
            'AppBar.Button.SpokenLabel.Notifications',
            'Notifications'
          )}
        >
          <Notifications style={{
            fill: 'white',
          }} />
        </IconButton> */}
      </Toolbar>
    </AppBar>
  )
}

export default Appbar
