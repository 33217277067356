import { Box, Card, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CardFormHeader from './CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import { useMountEffect } from '../../hooks/useMountEffect'
import {
  InviteTabFlowStages,
  useAccountContext,
} from '../Context/AccountContext'
import { useUser } from '../../UserContext'
import { UserProfile } from '../../swagger'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { useNavigate } from 'react-router'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { updateUserProfile } from '../../api/user'
import { extractedErrorObject } from '../../api/swagger'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import LoadingProgress from '../Elements/LoadingProgress'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

export const emptyUserInfo = {
  id: -1,
  firstName: '',
  lastName: '',
  phone: '',
}

const UpdateParentInfoCard: React.FC = () => {
  const { t } = useTranslation()
  const { user, setUser } = useUser()
  const navigate = useNavigate()
  const isDesktop = useShowOnDesktop()
  const { UpdateParentInfoCard } = useLoadingIds()

  const { updateBreadcrumbs } = useAccountContext()
  const { addLoadingIds, loadingIds } = useContext(LoadingContext)
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const [userInfo, setUserInfo] =
    useState<Pick<UserProfile, 'id' | 'firstName' | 'lastName' | 'phone'>>(
      emptyUserInfo
    )

  const saveParentInfoLoadingId = UpdateParentInfoCard.editUserProfile

  useMountEffect(() => {
    updateBreadcrumbs(InviteTabFlowStages.UpdateParentInfo)
  })

  /**
   * Initialize fields with user info
   */
  useEffect(() => {
    if (!!user && userInfo.id < 0) {
      const userInfo = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
      }

      setUserInfo(userInfo)
    }
  }, [user, userInfo.id])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const newValue = name === 'phone' ? value.replace(/[A-Z]/gi, '') : value

    setUserInfo((currInfo) => ({
      ...currInfo,
      [name]: newValue,
    }))
  }

  const checkFieldValidity = () => {
    const { firstName, lastName, phone } = userInfo

    return [firstName, lastName, phone].every((value) => !!value)
  }

  const handleBackClick = () => {
    navigate({ pathname: '/account/invites' })
  }

  const handleSubmission = () => {
    if (checkFieldValidity()) {
      addLoadingIds([saveParentInfoLoadingId])
    }
  }

  const saveParentInfo = async () => {
    try {
      await updateUserProfile(userInfo)
      setUser({
        ...(user as UserProfile),
        phone: userInfo.phone,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
      })

      navigate({ pathname: '/account/invites/invitation-summary' })
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'UpdateParentInfo.Error.SaveChanges',
            'Error occurred attempting to save changes to user info.'
          ),
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObj.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: saveParentInfo,
    loadingId: saveParentInfoLoadingId,
  })

  const disablePrimaryButton = !(
    !!userInfo?.firstName &&
    !!userInfo?.lastName &&
    !!userInfo?.phone
  )

  if (loadingIds.has(saveParentInfoLoadingId)) {
    return <LoadingProgress />
  }

  return (
    <Card>
      <CardFormHeader
        header={
          <Header
            id="childrenHeader"
            headerName={t(
              'UpdateParentInfoCard.Header.Title',
              'Update Account Information'
            )}
            component="h3"
            variant={HeaderVariant.Card}
          />
        }
      />

      <Box m={3}>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmission()
          }}
        >
          <Grid container spacing={3}>
            <Grid item container>
              <Grid item xs={12} lg={8} xl={6}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: isDesktop ? 'left' : 'center',
                  }}
                >
                  {t(
                    'UpdateParentInfoCard.Form.SubTitle',
                    'Before proceeding, we would like to ask you for some additional information.'
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} lg={8} xl={6}>
                <TextField
                  id="firstName"
                  name="firstName"
                  label={t('UpdateParentInfo.Field.FirstName', 'First Name')}
                  variant="filled"
                  value={userInfo?.firstName}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={12} lg={8} xl={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label={t('UpdateParentInfo.Field.LastName', 'Last Name')}
                  variant="filled"
                  value={userInfo?.lastName}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={12} lg={8} xl={6}>
                <TextField
                  id="phone"
                  name="phone"
                  label={t(
                    'UpdateParentInfo.Field.PhoneNumber',
                    'Phone Number'
                  )}
                  variant="filled"
                  value={userInfo?.phone}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item justifyContent={'flex-end'} xs={12} lg={8} xl={6}>
                <ActionButtons
                  primaryButtonLabel={ContainedButtonVariant.Save}
                  secondaryButtonLabel={TextButtonVariant.Cancel}
                  secondaryClick={handleBackClick}
                  primaryButtonLoadingId={saveParentInfoLoadingId}
                  disablePrimaryButton={disablePrimaryButton}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Card>
  )
}

export default UpdateParentInfoCard
