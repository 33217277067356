import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbarContext } from '../components/Context/SnackbarContext'
import { SnackbarSeverity } from '../components/Alerts/SnackbarAlert'
import { ContentAsset, contentApi, extractedErrorObject } from '../api/swagger'
import useLoadingContext from './useLoadingContext'

export const useFetchWidenAsset = ({
  /** Content asset unique identifier */
  assetKey,
  /** Unique Id of the operationId + component name being awaited. */
  loadingId,
  academicYear,
}: {
  assetKey: number
  loadingId: string
  academicYear?: number
}): {
  /** An object with all the parameter specified in {@link ContentAsset} */
  contentAsset: ContentAsset | undefined
  /**
   * Updates the content asset state with a new value.
   * @param {ContentAsset} newValue - The new value for the content asset state.
   */
  updateContentAssetState: (newValue: ContentAsset) => void
  /** Indicates whether the fetch operation is in progress. */
  isLoading: boolean
  /** Indicates whether an error occurred while fetching the content */
  errorFetchingContent: boolean
  /** Initiates the fetch process and adds the loading ID to the context. */
  fetchAsset: () => void
  /** Initiates a re-fetch process by adding the loading ID to the context. */
  refetch: () => void
} => {
  const { t } = useTranslation()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const [contentAsset, setContentAsset] = useState<ContentAsset>()
  const [errorFetchingContent, setErrorFetchingContent] = useState(false)

  const fetchWidenAsset = async () => {
    try {
      const fetchedWidenAsset = await contentApi.fetchContentDetail({
        assetKey,
        academicYear
      })

      setContentAsset(fetchedWidenAsset)
      setErrorFetchingContent(false)
    } catch (error) {
      setErrorFetchingContent(true)
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'UnknownError',
        message:
          (error as unknown as Error).message ??
          t(
            'UseFetchWidenAsset.FetchContentDetail.Error',
            'Failed to fetch the Asset'
          ),
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
      throw errorObject // Re-throw the error to propagate it up
    }
  }

  const updateContentAssetState = (newValue: ContentAsset) => {
    setContentAsset(newValue)
  }

  const { isLoading, triggerFetch } = useLoadingContext({
    asyncFunction: fetchWidenAsset,
    loadingId,
  })

  return {
    isLoading,
    contentAsset,
    updateContentAssetState,
    fetchAsset: triggerFetch,
    refetch: triggerFetch,
    errorFetchingContent,
  }
}
