import React, { useState, useMemo, useEffect, useCallback } from 'react'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import { fetchCommunityOptions } from '../../api/communities'
import { useParams } from 'react-router'
import { CommunityDetail } from '../../swagger/models/CommunityDetail'
import { CommunityOptions } from '../../swagger/models/CommunityOptions'
import fullName from '../../utils/fullName'
import { communities, extractedErrorObject } from '../../api/swagger'
import { ProgramStatus, ProgramType, Address, ModelError } from '../../swagger'
import Header, { HeaderVariant } from '../Elements/Header'
import { useNavigate } from 'react-router'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { addUpdateProgram } from '../../api/programs'
import programTypeMap from '../../utils/programTypeMap'
import { styled, InputLabel, Typography } from '@mui/material'
import { Ability } from '@casl/ability'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import CardFormHeader from '../Card/CardFormHeader'
import AddressModal from '../Address/AddressModal'
import ErrorAlert from '../Alerts/ErrorAlert'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import { TFunction } from 'i18next'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useNotistackSnackbarKeyContext } from '../Context/NotistackSnackbarKeyProvider'
import { removeStringExtraSpaces } from '../../utils/stringUtility'
import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import { OperationIds } from '../../swagger/operationIdEnum'
import { useMountEffect } from '../../hooks/useMountEffect'
import useLoadingContext from '../../hooks/useLoadingContext'
import { LoadingContext } from '../Context/LoadingContext'
import { FieldValidity } from '../Interfaces/FieldValidity'
import LoadingProgress from '../Elements/LoadingProgress'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import CheckboxControl from '../Checkboxes/CheckboxControl'
import { useCommunitiesContext } from '../Context/CommunitiesContext'

export enum Regions {
  Canada = 'Canada',
  California = 'California',
  Chile = 'Chile',
  USA = 'United States of America',
}

const ProgramTypeForm = styled('form')({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto auto',
  gridTemplateRows: 'auto auto',
})

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 4, 4),
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3),
}))

const Form = styled('form')(({ theme }) => ({
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(1)})`,
  },
  display: 'grid',
  // Two columns
  gridTemplateColumns: 'repeat(2, 1fr)',
  // Three rows
  gridTemplateRows: 'auto auto auto',
}))

export const GridForm = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'grid',
    // One column
    gridTemplateColumns: 'repeat(1, 1fr)',
    // Three rows
    gridTemplateRows: 'auto auto auto',
  },
  display: 'grid',
  // Two columns
  gridTemplateColumns: 'repeat(2, 1fr)',
  // Three rows
  gridTemplateRows: 'auto auto auto',
}))
// styles for Support Representative
const RepresentativeLabel = styled(InputLabel)(({ theme }) => ({
  paddingLeft: theme.spacing(1.6),
  fontSize: theme.spacing(1.3),
  letterSpacing: '0.15px',
}))

const RepresentativeLinkToUserProfile = styled(Box)(({ theme }) => ({
  fontFamily: 'sweet-sans-pro',
  marginTop: -1,
  paddingBottom: theme.spacing(0.4),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(4),
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: theme.spacing(4),
  fontSize: theme.spacing(1.76),
  textTransform: 'none',
  backgroundColor: 'white',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  letterSpacing: '0.1px',
  borderBottom: `${theme.spacing(0.1)} solid ${
    theme.palette.customBackground.logo
  } `,
  '&:hover': {
    cursor: 'pointer',
  },
}))

export enum CommunityFormCardVariants {
  AddCommunity = 'Add Community',
  EditCommunity = 'Edit Community',
}

const isAddCommunityForm = (variant: string) => {
  return variant === CommunityFormCardVariants.AddCommunity
}

export enum programTypes {
  Foundations = 'Foundations',
  ChallengeA = 'Challenge A',
  ChallengeI = 'Challenge I',
  ChallengeIII = 'Challenge III',
  Essentials = 'Essentials',
  ChallengeB = 'Challenge B',
  ChallengeII = 'Challenge II',
  ChallengeIV = 'Challenge IV',
}

interface RepInfo {
  name: string
  actorKey?: number
  roleName?: string
  userId?: number
}

const getRepLabel = (repInfo: RepInfo) => {
  return `${repInfo.name}${!!repInfo.roleName ? ` - ${repInfo.roleName}` : ''}${
    !!repInfo.actorKey ? ` - ${repInfo.actorKey}` : ''
  }`
}

interface CommunityFormCardProps {
  title: string
  variant: CommunityFormCardVariants
  handleCancel?: () => void
  getInitialName?: (
    name: string,
    academicYearBeginning: number | undefined
  ) => void
  communityDetails: CommunityDetail
  handleSave?: () => void
  communityAbility?: Ability
  academicYear?: number
  resetFieldsForCancel?: () => void
  updateSelectedTab?: (newTab: string) => void
}

const CommunityFormCard: React.FC<CommunityFormCardProps> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const useAddCommunityFormVariant = isAddCommunityForm(props.variant)
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { addSnackbarKey, enqueueSnackbar } = useNotistackSnackbarKeyContext()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const { updateCommunityName, fetchCommunityLoadingId } =
    useCommunitiesContext()

  const filename = 'CommunityFormCard'
  const fetchCommunityOptionsLoadingId = `${filename}-${OperationIds.FetchCommunityOptions}`
  const createCommunityLoadingId = `${filename}-${OperationIds.CreateCommunity}`
  const updateCommunityLoadingId = `${filename}-${OperationIds.UpdateCommunity}`

  const showOnDesktop = useShowOnDesktop()
  const { addLoadingIds, removeLoadingIds, loadingIds } =
    React.useContext(LoadingContext)

  const errorMessage = t(
    'Communities.CommunityForm.ErrorMessage',
    'Something went wrong.  Please make sure you have filled out every field.'
  )

  // If we are adding a community, we are able to edit the fields,
  // Otherwise we are just viewing the community details at first
  const [isFieldDisabled, setIsFieldDisabled] = useState(
    useAddCommunityFormVariant ? false : true
  )

  const communityDetails = useMemo(
    () => props.communityDetails,
    [props.communityDetails]
  )

  const {
    address: communityAddress,
    hideFromPublicSearch: communityHideFromPublicSearch,
  } = props.communityDetails

  const initialAddress: Address = useMemo(
    () => ({
      ...communityAddress,
      streetAddress2: communityAddress.streetAddress2 ?? '',
      state: communityAddress.state ?? '',
      zip: communityAddress.zip ?? '',
    }),
    [communityAddress]
  )

  const [address, setAddress] = useState<Address>(initialAddress)
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)

  const [communityOptions, setCommunityOptions] = useState<CommunityOptions>()

  const [reset, setReset] = useState(false)

  // Using a negative id will prevent us from returning results when we fetch
  const { communityKey } = useParams<{
    communityKey: string
  }>()

  const communityId = useMemo(() => {
    return useAddCommunityFormVariant ? -1 : +`${communityKey}`
  }, [useAddCommunityFormVariant, communityKey])

  // We only want to see the "Tutor Model" field if I am able to create/edit the community or I can create programs within the community.
  const canEditCreateCommunitiesOrProgramsWithin = [
    props.communityAbility?.can('edit', 'Community'),
    props.communityAbility?.can('create', 'Community'),
    props.communityAbility?.can('edit', 'Program'),
    props.communityAbility?.can('create', 'Program'),
  ].some(Boolean)

  // Fetch Community Options
  const errorMessageFetchCommunity = t(
    'Communities.AddCommunityForm.ErrorMessage',
    'Something went wrong while loading the form. Please reload the page'
  )
  useMountEffect(() => {
    if (
      (!isFieldDisabled && !useAddCommunityFormVariant) ||
      useAddCommunityFormVariant
    )
      addLoadingIds([fetchCommunityOptionsLoadingId])
  })

  const fetchUserCommunityOptions = async () => {
    try {
      const fetchedCommunitiesOptions = await fetchCommunityOptions()
      setCommunityOptions(fetchedCommunitiesOptions)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Code',
        message: (e as unknown as Error).message ?? errorMessageFetchCommunity,
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    } finally {
      removeLoadingIds([fetchCommunityOptionsLoadingId])
    }
  }

  useLoadingContext({
    asyncFunction: fetchUserCommunityOptions,
    loadingId: fetchCommunityOptionsLoadingId,
  })

  useEffect(() => {
    if (
      useAddCommunityFormVariant ||
      (canEditCreateCommunitiesOrProgramsWithin && !isFieldDisabled)
    ) {
      addLoadingIds([fetchCommunityOptionsLoadingId])
    } else {
      removeLoadingIds([fetchCommunityOptionsLoadingId])
    }
  }, [
    addLoadingIds,
    canEditCreateCommunitiesOrProgramsWithin,
    fetchCommunityOptionsLoadingId,
    removeLoadingIds,
    useAddCommunityFormVariant,
    isFieldDisabled,
  ])

  const [name, setName] = useState(communityDetails.name ?? '')
  const [hideFromPublicSearch, setHideFromPublicSearch] = useState<boolean>(
    communityHideFromPublicSearch ?? false
  )

  // Get initial name for Community Details breadcrumb
  useEffect(() => {
    if (!!name) {
      props.getInitialName?.(name, props.academicYear)
    }
  }, [name, props])

  const tutorModels = useMemo<{ value: string; label: string }[]>(() => {
    return (communityOptions?.tutorModels ?? []).map((tutor) => {
      return { value: tutor, label: tutor }
    })
  }, [communityOptions])

  const handleCommunityNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setName(removeStringExtraSpaces(event.target.value))
    updateCommunityName(event.target.value)
  }

  const [region, setRegion] = useState(communityDetails.region ?? '')

  const handleRegionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegion(event.target.value)
  }

  const [tutorModel, setTutorModel] = useState(
    communityDetails.tutorModel ?? ''
  )

  const handleTutorsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTutorModel(event.target.value)
  }

  const [communityValidity, setCommunityValidity] = useState<{
    name: FieldValidity
    address: FieldValidity
    region: FieldValidity
    tutorModel: FieldValidity
    supportRepresentative: FieldValidity
    program: FieldValidity
  }>({
    name: {
      input: true,
    },
    address: { input: true },
    region: { input: true },
    tutorModel: { input: true },
    supportRepresentative: { input: true },
    program: { input: true },
  })

  const regions = useMemo<{ value: number; label: string }[]>(() => {
    return (communityOptions?.regions ?? []).map(
      (region: { name: string; id: number }) => {
        return { value: region.id, label: region.name }
      }
    )
  }, [communityOptions])

  const selectedRegion = communityOptions?.regions?.find(
    (it) => it.name === region
  )

  const supportRepresentatives = useMemo<
    { id: number; label: string }[]
  >(() => {
    // Dynamically update list of SR's based on the Region selected
    if (!selectedRegion) return []
    return selectedRegion.communityManagers
      .map((it) => ({
        user: selectedRegion.users.find((user) => user.id === it.userId),
        actorKey: it.id,
        role: it.role,
      }))
      .map(({ user, actorKey, role }) => {
        const label =
          !!user && !!role && !!actorKey
            ? `${fullName({
                firstName: user?.firstName,
                lastName: user?.lastName,
              })} - ${role} - ${actorKey}`
            : ''

        return {
          id: actorKey,
          label,
        }
      })
      .sort((a, b) => (a > b ? 1 : -1))
  }, [selectedRegion])

  const [supportRepresentative, setSupportRepresentative] = useState<RepInfo>({
    name: communityDetails.supportRepresentative ?? '',
    actorKey: communityDetails.actorKey,
    roleName: communityDetails.roleName,
    userId: communityDetails.supportRepresentativeUserKey,
  })

  const handleSupportRepresentativeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const [name, roleName, actorKey] = event.target.value.split(' - ')
    const actorKeyNumber = parseInt(actorKey)
    setSupportRepresentative({
      name,
      roleName,
      actorKey: !isNaN(actorKeyNumber) ? actorKeyNumber : undefined,
    })
  }
  //handle success for redirect to community details
  const handleSuccess = (communityId?: number) => {
    setSnackbarState(true)

    setIsFieldDisabled(true)

    navigate(
      {
        pathname: `/communities/community-details/${communityId}/overview`,
      },
      {
        state: { communityKey: communityId, name: communityDetails.name },
      }
    )
  }

  const confirmationButtons =
    props.variant === CommunityFormCardVariants.EditCommunity ? (
      <ActionButtons
        alwaysStack={true}
        primaryButtonLabel={ContainedButtonVariant.YesPushAddressToAllPrograms}
        secondaryButtonLabel={TextButtonVariant.NoOnlyUpdateCommunity}
        secondaryClick={() => setShowConfirmationModal(false)}
      />
    ) : (
      <ActionButtons
        alwaysStack={true}
        primaryButtonLabel={ContainedButtonVariant.Ok}
        secondaryButtonLabel={TextButtonVariant.Cancel}
        secondaryClick={() => setShowConfirmationModal(false)}
      />
    )

  const headerMessage = `${t(
    'CommunityFormCard.ConfirmationModal.Header.updatePrograms',
    'You are updating the community address.'
  )}`

  const representativeLabel = useAddCommunityFormVariant
    ? t(
        'Communities.CommunityFormCard.FormField.SupportRepresentative',
        'Representative'
      )
    : t(
        'Communities.CommunityFormCard.FormField.AssignedRepresentative',
        'Assigned Representative'
      )

  const confirmationBodyMessage = (
    <Typography variant="body1" component="p" align="center">
      {t(
        'CommunityFormCard.ConfirmationModal.Body',
        'Do you also want to update the address in all programs in this community?'
      )}
    </Typography>
  )

  const [updatePrograms, setUpdatePrograms] = useState(false)

  const confirmationProps = {
    isOpen: showConfirmationModal,
    dialogContent: confirmationBodyMessage,
    dialogActions: confirmationButtons,
    dialogTitle: headerMessage,
    handleFormSubmit: () => {
      props.variant === CommunityFormCardVariants.EditCommunity
        ? setUpdatePrograms(true)
        : addLoadingIds([createCommunityLoadingId])
      setShowConfirmationModal(false)
    },
  }

  const handleSave = async () => {
    if (isAddCommunityForm(props.variant)) {
      addLoadingIds([createCommunityLoadingId])
    } else {
      addLoadingIds([updateCommunityLoadingId])
    }
  }

  const setFieldValidity = (overrideValue = false) => {
    setCommunityValidity({
      name: { input: !!name.trim() || overrideValue },
      address: { input: !!address.streetAddress1 || overrideValue },
      tutorModel: { input: !!tutorModel || overrideValue },
      region: { input: !!region || overrideValue },
      supportRepresentative: {
        input: !!supportRepresentative.name || overrideValue,
      },
      program: { input: !!isAtLeastOneProgramChecked || overrideValue },
    })
  }

  const [draftProgramsToCreate, setDraftProgramsToCreate] = useState(
    new Map<string, boolean>()
      .set(ProgramType.Essentials, false)
      .set(ProgramType.Foundations, false)
      .set(ProgramType.ChallengeA, false)
      .set(ProgramType.ChallengeB, false)
      .set(ProgramType.ChallengeI, false)
      .set(ProgramType.ChallengeIi, false)
      .set(ProgramType.ChallengeIii, false)
      .set(ProgramType.ChallengeIv, false)
  )

  /** Validates community information and responds with a boolean determining if the information is valid enough to proceed. */
  const getSupportRepresentativeActorKey = (): { actorKey?: number } => {
    const actorKeys = supportRepresentatives
      .filter(
        (supportRep) => supportRep.label === getRepLabel(supportRepresentative)
      )
      .map((it) => it.id)
    const actorKey = actorKeys[0]
    const didParsingFail = isNaN(actorKey)

    setFieldValidity()

    if (didParsingFail) return {}

    return { actorKey }
  }

  const handleCreateCommunity = async () => {
    const { actorKey } = getSupportRepresentativeActorKey()
    const availableRegions = communityOptions?.regions
    if (availableRegions === undefined || availableRegions.length <= 0) {
      // XXX: NO REGIONS TO SELECT: We shouldn't even be able to get to this point.
      return
    }

    //if no programs are selected, show error message
    if (!isAtLeastOneProgramChecked) {
      setSnackbarState(true)
      const message = t(
        'Communities.CommunityForm.ErrorMessage.NoProgramsSelected',
        'Select at least one program.'
      )
      setSnackbarMessage(message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      return
    }

    if (fieldsDoValidate(actorKey)) {
      const regionsKey = selectedRegion?.id

      try {
        const addCommunityResponse = await communities.createCommunity({
          body: {
            communityKey: communityId,
            name: name.trim(),
            regionsKey: regionsKey ?? 0,
            address: {
              ...address,
            },
            actorKey: actorKey ?? 0,
            tutorModel,
            hideFromPublicSearch,
          },
        })

        // Create selected programs
        let createDraftProgramsErrorMessage = ''
        try {
          if (selectedRegion) {
            if (
              selectedRegion.defaultProgramStartDates.semesterTwoStartDate <
              selectedRegion.defaultProgramStartDates.semesterOneStartDate
            ) {
              createDraftProgramsErrorMessage = t(
                'Communities.CommunityForm.CreateProgram.ErrorMessage.NotCreated',
                'Semester two start date must be after Semester one.'
              )
            } else if (
              [...draftProgramsToCreate.values()].some((draft) => draft)
            ) {
              await createDraftProgramsForCommunity(
                draftProgramsToCreate,
                address,
                addCommunityResponse.communityKey ?? communityId,
                addCommunityResponse.actorKey,
                selectedRegion?.defaultProgramStartDates,
                enqueueSnackbar,
                addSnackbarKey,
                t
              )
            }
          }
        } catch (createDraftProgramsError) {
          // Errors already logged - just scoop the results for presentation to the user.
          const e = (await extractedErrorObject(createDraftProgramsError)) ?? {
            code: 'UnknownError',
            message:
              (createDraftProgramsError as unknown as Error).message ??
              errorMessage,
          }
          createDraftProgramsErrorMessage = `${e.message} (${e.code})`
        }

        handleSuccess(addCommunityResponse.communityKey)
        updateCommunityName(name)
        setSnackbarState(true)
        const message = createDraftProgramsErrorMessage
          ? t(
              'Communities.CommunityForm.SuccessMessage.CreatedWithProgramProblems',
              'Community successfully created. However, there were problems adding one or more programs: {{createDraftProgramsErrorMessage}}',
              { createDraftProgramsErrorMessage }
            )
          : t(
              'Communities.CommunityForm.SuccessMessage.Created',
              'Community successfully created.'
            )
        setSnackbarMessage(message)
        setSnackbarSeverity(
          createDraftProgramsErrorMessage
            ? SnackbarSeverity.Error
            : SnackbarSeverity.Success
        )
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'UnknownError',
          message: errorMessage,
        }
        setSnackbarState(true)
        setSnackbarMessage(errorObject.message)
        setSnackbarSeverity(SnackbarSeverity.Error)
      } finally {
        removeLoadingIds([createCommunityLoadingId])
      }
    }
  }

  useLoadingContext({
    loadingId: createCommunityLoadingId,
    asyncFunction: handleCreateCommunity,
  })

  const fieldsDoValidate = (actorKey: number | undefined) => {
    return !!actorKey && !!selectedRegion && !!name.trim()
  }

  const handleUpdateCommunity = async () => {
    const { actorKey } = getSupportRepresentativeActorKey()
    const availableRegions = communityOptions?.regions
    if (availableRegions === undefined || availableRegions.length <= 0) {
      // XXX: NO REGIONS TO SELECT: We shouldn't even be able to get to this point.
      return
    }
    if (fieldsDoValidate(actorKey)) {
      const regionsKey = selectedRegion?.id
      try {
        await communities.updateCommunity({
          body: {
            communityKey: communityId,
            regionsKey: regionsKey ?? 0,
            actorKey: actorKey ?? 0,
            name: name.trim(),
            tutorModel,
            address: {
              ...address,
            },
            updateProgramAddresses: updatePrograms,
            hideFromPublicSearch,
          },
        })
        setName(name.trim())
        setIsFieldDisabled(true)
        //refetch the community details when editing
        addLoadingIds([fetchCommunityLoadingId])
        updateCommunityName(name)
        setSnackbarState(true)
        setSnackbarMessage(
          t(
            'Communities.CommunityForm.SuccessMessage.Updated',
            'Community successfully Updated.'
          )
        )
        setSnackbarSeverity(SnackbarSeverity.Success)
      } catch (e) {
        const errorObject = (await extractedErrorObject(e)) ?? {
          code: 'UnknownError',
          message: errorMessage,
        }
        setSnackbarState(true)
        setSnackbarMessage(errorObject.message)
        setSnackbarSeverity(SnackbarSeverity.Error)
      } finally {
        removeLoadingIds([updateCommunityLoadingId])
      }
    } else {
      setSnackbarState(true)
      setSnackbarMessage(
        t(
          'Communities.CommuntyForm.NoActorOrRegion',
          'You must have a Region and Assigned Support Representative selected'
        )
      )
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    loadingId: updateCommunityLoadingId,
    asyncFunction: handleUpdateCommunity,
  })

  const handleEdit = () => {
    setIsFieldDisabled(false)
  }

  const resetFields = useCallback(() => {
    setName(communityDetails.name ?? '')
    setRegion(communityDetails.region ?? '')
    setSupportRepresentative({
      name: communityDetails.supportRepresentative ?? '',
      actorKey: communityDetails.actorKey,
      roleName: communityDetails.roleName ?? '',
    })
    setTutorModel(communityDetails.tutorModel ?? '')
    setAddress(initialAddress)
    return
  }, [
    communityDetails.actorKey,
    communityDetails.name,
    communityDetails.region,
    communityDetails.roleName,
    communityDetails.supportRepresentative,
    communityDetails.tutorModel,
    initialAddress,
  ])

  // When we need to reset the fields, do so.
  useEffect(() => {
    reset && resetFields()
  }, [reset, resetFields])

  const handleCancel = () => {
    if (props.variant === CommunityFormCardVariants.EditCommunity) {
      setIsFieldDisabled(true)
      setReset(true)
      setFieldValidity(true)
      resetFields()
    } else if (props.variant === CommunityFormCardVariants.AddCommunity) {
      props.handleCancel?.()
    }
  }

  const [isEssentialsChecked, setIsEssentialsChecked] = useState(
    draftProgramsToCreate.get('Essentials')
  )
  const [isFoundationsChecked, setIsFoundationsChecked] = useState(
    draftProgramsToCreate.get('Foundations')
  )
  const [isChallengeAChecked, setIsChallengeAChecked] = useState(
    draftProgramsToCreate.get('Challenge A')
  )
  const [isChallengeBChecked, setIsChallengeBChecked] = useState(
    draftProgramsToCreate.get('Challenge B')
  )
  const [isChallengeIChecked, setIsChallengeIChecked] = useState(
    draftProgramsToCreate.get('Challenge I')
  )
  const [isChallengeIIChecked, setIsChallengeIIChecked] = useState(
    draftProgramsToCreate.get('Challenge II')
  )
  const [isChallengeIIIChecked, setIsChallengeIIIChecked] = useState(
    draftProgramsToCreate.get('Challenge III')
  )
  const [isChallengeIVChecked, setIsChallengeIVChecked] = useState(
    draftProgramsToCreate.get('Challenge IV')
  )

  const isProgramTypeChecked = (type: string) => {
    switch (type) {
      case programTypes.ChallengeA:
        return isChallengeAChecked
      case programTypes.ChallengeB:
        return isChallengeBChecked
      case programTypes.ChallengeI:
        return isChallengeIChecked
      case programTypes.ChallengeII:
        return isChallengeIIChecked
      case programTypes.ChallengeIII:
        return isChallengeIIIChecked
      case programTypes.ChallengeIV:
        return isChallengeIVChecked
      case programTypes.Foundations:
        return isFoundationsChecked
      case programTypes.Essentials:
        return isEssentialsChecked
      default:
        throw new Error('Unexpected Program Type check.')
    }
  }

  // Validate that at least one program is checked
  const isAtLeastOneProgramChecked =
    isFoundationsChecked ||
    isEssentialsChecked ||
    isChallengeAChecked ||
    isChallengeBChecked ||
    isChallengeIChecked ||
    isChallengeIIChecked ||
    isChallengeIIIChecked ||
    isChallengeIVChecked

  const handleProgramSelections = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setDraftProgramsToCreate(
      draftProgramsToCreate.set(event.currentTarget.name, checked)
    )
    switch (event.currentTarget.name) {
      case programTypes.ChallengeA:
        setIsChallengeAChecked(!isChallengeAChecked)
        break
      case programTypes.ChallengeB:
        setIsChallengeBChecked(!isChallengeBChecked)
        break
      case programTypes.ChallengeI:
        setIsChallengeIChecked(!isChallengeIChecked)
        break
      case programTypes.ChallengeII:
        setIsChallengeIIChecked(!isChallengeIIChecked)
        break
      case programTypes.ChallengeIII:
        setIsChallengeIIIChecked(!isChallengeIIIChecked)
        break
      case programTypes.ChallengeIV:
        setIsChallengeIVChecked(!isChallengeIVChecked)
        break
      case programTypes.Foundations:
        setIsFoundationsChecked(!isFoundationsChecked)
        break
      case programTypes.Essentials:
        setIsEssentialsChecked(!isEssentialsChecked)
        break
      default:
        throw new Error('Unexpected Program Type selection.')
    }
  }

  if (
    loadingIds.has(fetchCommunityOptionsLoadingId) ||
    loadingIds.has(createCommunityLoadingId) ||
    loadingIds.has(updateCommunityLoadingId)
  )
    return <LoadingProgress />

  const handleConfirmationModal = (isAddCommunityFormSubmit?: boolean) => {
    if (props.variant === CommunityFormCardVariants.EditCommunity) {
      setShowConfirmationModal(true)
    } else if (
      props.variant === CommunityFormCardVariants.AddCommunity &&
      isAddCommunityFormSubmit
    ) {
      setShowConfirmationModal(true)
    }
  }

  const getRepresentativeLabel = () => {
    return !!supportRepresentative.name &&
      !!supportRepresentative.actorKey &&
      !!supportRepresentative.roleName
      ? getRepLabel(supportRepresentative)
      : ''
  }

  return (
    <>
      <ConfirmationModal {...confirmationProps} />
      <AddressModal
        isOpen={isAddressModalOpen}
        onClose={() => {
          setIsAddressModalOpen(false)
        }}
        initialAddress={address}
        onAddressConfirm={(value) => {
          handleConfirmationModal()
          setAddress(value)
        }}
      />
      <Box mb={2}>
        <CardFormHeader
          header={null}
          buttons={
            isFieldDisabled ? (
              props.communityAbility?.can('edit', 'Community') && (
                <ContainedButton
                  id="edit"
                  variant={ContainedButtonVariant.Edit}
                  onClick={handleEdit}
                />
              )
            ) : (
              <>
                <TextButton
                  id="cancel"
                  onClick={handleCancel}
                  variant={TextButtonVariant.Cancel}
                />
                <ContainedButton
                  id="save"
                  variant={ContainedButtonVariant.Save}
                  onClick={handleSave}
                />
              </>
            )
          }
        />
      </Box>
      <StyledCard>
        <section aria-labelledby="communityFormHeader">
          <Header
            id="communityFormHeader"
            headerName={props.title}
            component="h2"
            variant={HeaderVariant.Card}
          />

          <Form
            noValidate
            autoComplete="off"
            aria-labelledby="communityFormHeader"
          >
            <TextField
              id="communityNameField"
              label={t(
                'Communities.CommunityForm.FormField.CommunityName',
                'Community Name'
              )}
              variant="filled"
              onChange={handleCommunityNameChange}
              error={!communityValidity.name.input}
              sx={{
                gridColumn: 1,
                gridRow: 1,
              }}
              disabled={isFieldDisabled}
              value={name}
              helperText={
                !communityValidity.name.input &&
                t(
                  'CommunityFormCard.ValidationMessage.Name',
                  'Community name cannot be empty'
                )
              }
            />
            {(useAddCommunityFormVariant ||
              canEditCreateCommunitiesOrProgramsWithin) && (
              <TextField
                id="tutorModelSelect"
                label={t(
                  'Communities.CommunityForm.FormField.TutorModel',
                  'Tutor Model'
                )}
                variant="filled"
                sx={{
                  gridColumn: 1,
                  gridRow: 2,
                }}
                select={!isFieldDisabled && tutorModels.length > 0}
                value={tutorModel}
                onChange={handleTutorsChange}
                disabled={isFieldDisabled}
                error={!communityValidity.tutorModel.input}
              >
                {!isFieldDisabled
                  ? tutorModels.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  : ''}
              </TextField>
            )}
            <TextField
              id="regionSelect"
              label={t('Communities.CommunityForm.FormField.Region', 'Region')}
              variant="filled"
              sx={{
                gridColumn: 2,
                gridRow: 1,
              }}
              select={!isFieldDisabled && regions.length > 0}
              value={region}
              onChange={handleRegionChange}
              disabled={isFieldDisabled}
              error={!communityValidity.region.input}
            >
              {!isFieldDisabled && regions.length > 0
                ? regions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.label}
                      data-testid="RegionOption"
                    >
                      {option.label}
                    </MenuItem>
                  ))
                : ''}
            </TextField>
            {!isFieldDisabled && supportRepresentatives.length > 0 ? (
              // Support Representative Selector
              <TextField
                id="representativeSelect"
                label={representativeLabel}
                variant="filled"
                sx={{
                  gridColumn: 2,
                  gridRow: 2,
                }}
                select={true}
                value={getRepresentativeLabel()}
                onChange={handleSupportRepresentativeChange}
                disabled={isFieldDisabled || region === ''}
                error={!communityValidity.supportRepresentative.input}
                helperText={
                  !communityValidity.supportRepresentative.input &&
                  t(
                    'CommunityFormCard.ValidationMessage.SupportRepresentative',
                    'Support Representative cannot be blank'
                  )
                }
              >
                {!isFieldDisabled && supportRepresentatives.length > 0
                  ? supportRepresentatives?.map((option) => (
                      <MenuItem key={option.id} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))
                  : ''}
              </TextField>
            ) : (
              // Support Representative Link
              <Box
                id="boxSupportRepresentativeLink"
                sx={{
                  display: 'grid',
                  gridColumn: 2,
                  gridRow: 2,
                  marginTop: 2,
                  paddingLeft: 1,
                  marginBottom: 1,
                }}
              >
                <RepresentativeLabel id="SupportRepresentativeLabel">
                  {representativeLabel}
                </RepresentativeLabel>
                <RepresentativeLinkToUserProfile
                  id="supportRepresentative"
                  aria-label={
                    !!supportRepresentative.actorKey
                      ? getRepresentativeLabel()
                      : 'SupportRepresentative'
                  }
                  role="button"
                  onClick={() => {
                    navigate({
                      pathname: `/admin/user-accounts/${supportRepresentative.userId}`,
                    })
                  }}
                >
                  {getRepresentativeLabel()}
                </RepresentativeLinkToUserProfile>
              </Box>
            )}
            <CheckboxControl
              label="Hide From Public Search"
              disabled={isFieldDisabled}
              name="hideFromPublicSearch"
              checkboxValue={hideFromPublicSearch}
              OnChange={setHideFromPublicSearch}
            />
            <TextField
              id="communityIdField"
              label={t(
                'Communities.CommunityForm.FormField.CommunityID',
                'Community ID'
              )}
              sx={{
                gridRow: 3,
                gridColumn: 2,
              }}
              variant="filled"
              disabled
              value={communityDetails.communityKey}
            />
          </Form>
        </section>
      </StyledCard>

      <StyledCard>
        <section aria-labelledby="communityLocationHeader">
          <CardFormHeader
            headerContainerProps={{ margin: 0 }}
            header={
              <Header
                id="communityLocationHeader"
                headerName={t(
                  'Communities.CommunityForm.Location.Header',
                  'Location'
                )}
                component="h2"
                variant={HeaderVariant.Card}
              />
            }
            buttons={
              !isFieldDisabled && (
                <OutlinedButton
                  id="editLocation"
                  variant={OutlinedButtonVariant.EditLocation}
                  onClick={() => setIsAddressModalOpen(true)}
                />
              )
            }
          />
          {!communityValidity.address.input && (
            <ErrorAlert
              error={t(
                'Communities.CommunityForm.Location.Error',
                'Location cannot be empty.'
              )}
            />
          )}
          <GridForm>
            <Box
              pr={showOnDesktop ? 3 : 0}
              display="flex"
              flexDirection="column"
            >
              <TextField
                id="streetAddress1Field"
                inputProps={{ 'aria-label': 'Street Address 1' }}
                label={t(
                  'Communities.CommunityForm.FormField.StreetAddress1',
                  'Street Address'
                )}
                variant="filled"
                sx={{
                  gridColumn: 1,
                  gridRow: 1,
                }}
                disabled
                value={address.streetAddress1}
              />
              <TextField
                id="streetAddress2Field"
                inputProps={{ 'aria-label': 'Street Address 2' }}
                label={t(
                  'Communities.CommunityForm.FormField.StreetAddress2',
                  'Building, Suite, etc.'
                )}
                variant="filled"
                sx={{
                  gridColumn: 1,
                  gridRow: 2,
                }}
                disabled
                value={address.streetAddress2}
              />
              <TextField
                id="cityField"
                inputProps={{ 'aria-label': 'City' }}
                label={t('Communities.CommunityForm.FormField.City', 'City')}
                variant="filled"
                sx={{
                  gridColumn: 1,
                  gridRow: 3,
                }}
                disabled
                value={address.city}
              />
            </Box>
            <Box
              pr={showOnDesktop ? 3 : 0}
              display="flex"
              flexDirection="column"
            >
              <TextField
                id="countrySelect"
                label={t(
                  'Communities.CommunityForm.FormField.Country',
                  'Country'
                )}
                variant="filled"
                sx={{
                  gridColumn: 2,
                  gridRow: 1,
                }}
                value={address.countryCode}
                disabled
              />
              <TextField
                id="stateSelect"
                label={t(
                  'Communities.CommunityForm.FormField.State',
                  'State/Province'
                )}
                variant="filled"
                sx={{
                  gridColumn: 2,
                  gridRow: 2,
                }}
                value={address.state}
                disabled
              />
              <TextField
                id="zipField"
                inputProps={{ 'aria-label': 'Zip', maxLength: 12 }}
                label={t(
                  'Communities.CommunityForm.FormField.PostalCode',
                  'Postal Code'
                )}
                variant="filled"
                sx={{
                  gridColumn: 2,
                  gridRow: 3,
                }}
                disabled
                value={address.zip}
              />
            </Box>
          </GridForm>
        </section>
      </StyledCard>

      {useAddCommunityFormVariant && (
        <StyledCard>
          <section aria-labelledby="addProgramsHeader">
            <FormControl fullWidth>
              <Header
                id="addProgramsHeader"
                headerName={t(
                  'Communities.CommunityForm.AddPrograms.Header',
                  'Add Programs'
                )}
                component="h3"
                variant={HeaderVariant.Divider}
              />
              {!communityValidity.program.input && (
                <ErrorAlert
                  error={t(
                    'Communities.CommunityForm.Program.Error',
                    'Select at least one program'
                  )}
                />
              )}
              <ProgramTypeForm>
                {Object.values(programTypes).map((programType) => {
                  return (
                    <FormControlLabel
                      key={`${programType}CheckboxLabel`}
                      control={
                        <Checkbox
                          name={programType}
                          color="secondary"
                          onChange={handleProgramSelections}
                          checked={isProgramTypeChecked(programType)}
                        />
                      }
                      label={
                        <Typography variant="caption" component="p">
                          {programType}
                        </Typography>
                      }
                    />
                  )
                })}
              </ProgramTypeForm>
            </FormControl>
          </section>
        </StyledCard>
      )}
    </>
  )
}

export async function createDraftProgramsForCommunity(
  draftProgramsToCreate: Map<string, boolean>,
  address: Address,
  createdCommunityId: number,
  defaultDirectorActorId: number,
  regionDates: { semesterOneStartDate: Date; semesterTwoStartDate: Date },
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined
  ) => SnackbarKey,
  addSnackbarKey: (keyToAdd: SnackbarKey) => void,
  t: TFunction
): Promise<void> {
  let results: PromiseSettledResult<number>[]
  const programsToCreate = Array.from(draftProgramsToCreate.entries())
    .filter(([, isChecked]) => !!isChecked)
    .map(([program]) => program)
  try {
    const programCreationToAwait: Promise<number>[] = programsToCreate.map(
      (program) => {
        const type = programTypeMap.get(program) ?? ProgramType.Scribblers
        return addUpdateProgram({
          programsKey: -1,
          type,
          status: ProgramStatus.Draft,
          communityId: createdCommunityId,
          semesterOneStartDate: regionDates.semesterOneStartDate,
          semesterTwoStartDate: regionDates.semesterTwoStartDate,
          address: {
            ...address,
          },
          directorActorId: defaultDirectorActorId,
          startTime: '08:00',
          endTime: '15:00',
          dayOfTheWeek: 7, // 7 is Sunday per ProgramCreate
        })
      }
    )
    results = await Promise.allSettled(programCreationToAwait)

    // Separate rejections from success messages
    const rejections = results.filter((it) => it.status === 'rejected')
    const errorMessage: string[] = []
    for (const rejection of rejections) {
      errorMessage.push(`${(rejection as PromiseRejectedResult).reason} \n`)
    }

    for (const msg of errorMessage) {
      addSnackbarKey(enqueueSnackbar(msg, { variant: 'error' }))
    }

    const successMessage: string[] = []
    const successes = results.filter((it) => it.status === 'fulfilled')
    if (successes.length === results.length) {
      successMessage.push(
        t(
          'CommunityFormCard.SuccessMessage.All',
          'All programs were successfully created.'
        )
      )
    } else {
      successMessage.push(
        t(
          'CommunityFormCard.SuccessMessage.Some',
          'Some programs were successfully created.'
        )
      )
    }

    for (const msg of successMessage) {
      addSnackbarKey(enqueueSnackbar(msg, { variant: 'success' }))
    }
  } catch (e) {
    console.error(
      'creating draft programs for new community failed: synchronous error encountered',
      {
        rawError: e,
        arguments: {
          createdCommunityId,
          defaultDirectorActorId,
          regionDates,
        },
      }
    )
    throw e
  }

  // Aggregate errors and actually include some info from the errors.
  const rejections = results
    .map((it, i) => [programsToCreate[i], it] as const)
    .filter(
      (it): it is [string, PromiseRejectedResult] => it[1].status === 'rejected'
    )
    .map((it) => [it[0], it[1].reason])
  if (rejections.length === 0) {
    return
  }

  const extractions: Record<string, ModelError> = {}
  for (const [program, rejection] of rejections) {
    const extraction = await extractedErrorObject(rejection)
    extractions[program] = extraction ?? { code: 'UNKNOWN', message: '?' }
  }
  const message = Object.entries(extractions)
    .map(([program, error]) => `${program}: ${error.message} (${error.code})`)
    .join('\n')

  throw new Error(message)
}

export default CommunityFormCard
