/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface ActorSelectOption
 */
export interface ActorSelectOption {
  /**
   * Unique identifier for the actor.
   * @type {number}
   * @memberof ActorSelectOption
   */
  actorKey: number
  /**
   * The name of this actor.
   * @type {string}
   * @memberof ActorSelectOption
   */
  name: string
  /**
   * The email for this actor.
   * @type {string}
   * @memberof ActorSelectOption
   */
  email?: string
  /**
   * The phone number for this actor.
   * @type {string}
   * @memberof ActorSelectOption
   */
  phone?: string
  /**
   * The date in which the actor is valid from in date-time format.
   * @type {Date}
   * @memberof ActorSelectOption
   */
  validFrom: Date
  /**
   * The date in which the actor becomes invalid on and after in date-time format.
   * @type {Date}
   * @memberof ActorSelectOption
   */
  validTo: Date
  /**
   * The keys of another resource that this actor applies to. For example, this can be a list of community keys in which this actor belongs to.
   * @type {Array<number>}
   * @memberof ActorSelectOption
   */
  applicableToResourceKeys?: Array<number>
  /**
   * The types of another resource that this actor applies to. For example, this can be the list of Program Types that this actor can be assigned to.
   * @type {Array<string>}
   * @memberof ActorSelectOption
   */
  applicableToResourceTypes?: Array<string>
}

export function ActorSelectOptionFromJSON(json: any): ActorSelectOption {
  return ActorSelectOptionFromJSONTyped(json, false)
}

export function ActorSelectOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ActorSelectOption {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actorKey: json['actorKey'],
    name: json['name'],
    email: !exists(json, 'email') ? undefined : json['email'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    validFrom: new Date(json['validFrom']),
    validTo: new Date(json['validTo']),
    applicableToResourceKeys: !exists(json, 'applicableToResourceKeys')
      ? undefined
      : json['applicableToResourceKeys'],
    applicableToResourceTypes: !exists(json, 'applicableToResourceTypes')
      ? undefined
      : json['applicableToResourceTypes'],
  }
}

export function ActorSelectOptionToJSON(value?: ActorSelectOption | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actorKey: value.actorKey,
    name: value.name,
    email: value.email,
    phone: value.phone,
    validFrom: value.validFrom.toISOString(),
    validTo: value.validTo.toISOString(),
    applicableToResourceKeys: value.applicableToResourceKeys,
    applicableToResourceTypes: value.applicableToResourceTypes,
  }
}
