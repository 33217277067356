import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { dateToDashString } from '../../utils/dateUtility'
import { FieldValidity } from '../Interfaces/FieldValidity'
import { useDateRangeValidity } from '../../hooks/useDateRangeValidity'

export enum DateFieldVariant {
  RoleStartDate = 'roleStartDate',
  RoleEndDate = 'roleEndDate',
  FirstSemesterStartDate = 'firstSemesterStartDate',
  SecondSemesterStartDate = 'secondSemesterStartDate',
  EventStartDate = 'eventStartDate',
  EventEndDate = 'eventEndDate',
  EffectiveDate = 'effectiveDate',
  EndDate = 'endDate',
  EventsByDate = 'eventsByDate',
  DateOfBirth = 'dateOfBirth',
  // For DateField Test
  DateOfToday = 'dateOfToday',
  RegistrationCloseDate = 'registrationCloseDate',
  DateOfHighSchoolEntry = 'dateOfHighSchoolEntry',
  DateOfGraduation = 'dateOfGraduation',
}

const getLabelForVariant = (
  variant: DateFieldVariant,
  t: TFunction
): string => {
  switch (variant) {
    case DateFieldVariant.RoleStartDate:
      return t('DateField.Label.RoleStartDate', 'Role Start Date')
    case DateFieldVariant.RoleEndDate:
      return t('DateField.Label.RoleEndDate', 'Role End Date')
    case DateFieldVariant.FirstSemesterStartDate:
      return t(
        'DateField.Label.FirstSemesterStartDate',
        '1st Semester Start Date'
      )
    case DateFieldVariant.SecondSemesterStartDate:
      return t(
        'DateField.Label.SecondSemesterStartDate',
        '2nd Semester Start Date'
      )
    case DateFieldVariant.EventStartDate:
      return t('DateField.Label.EventStartDate', 'Event Start Date')
    case DateFieldVariant.EventEndDate:
      return t('DateField.Label.EventEndDate', 'Event End Date')
    case DateFieldVariant.EffectiveDate:
      return t('DateField.Label.EffectiveDate', 'Effective Date')
    case DateFieldVariant.EndDate:
      return t('DateField.Label.EndDate', 'End Date')
    case DateFieldVariant.EventsByDate:
      return t('DateField.Label.EventsByDate', 'Events By Date')
    case DateFieldVariant.DateOfBirth:
      return t('DateField.Label.DateOfBirth', 'Date of Birth')
    // For DateField Test
    case DateFieldVariant.DateOfToday:
      return t('DateField.Label.DateOfToday', 'Date of Today')
    case DateFieldVariant.RegistrationCloseDate:
      return t('DateField.Label.EarlyCloseDate', 'Early Close Date')
    case DateFieldVariant.DateOfHighSchoolEntry:
      return t(
        'DateField.Label.DateOfHighSchoolEntry',
        'Date of High School Entry'
      )
    case DateFieldVariant.DateOfGraduation:
      return t('DateField.Label.DateOfGraduation', 'Date of Graduation')
    // We should NEVER have this default because all variants are accounted for, but the return value requires it
    default:
      return t('DateField.Label.BadDate', 'Bad Date')
  }
}

type DateFieldProps = {
  minDate?: Date
  maxDate?: Date
  onChangeForParent: (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
    validity?: FieldValidity
  ) => void
  helperTextForParent?: React.ReactNode
  /**
   * Determines whether or not the field is invalid
   */
  error?: boolean
  /**
   * Determines id, label, and inputProps aria-label for a given field.
   */
  dateVariant: DateFieldVariant
  /**
   * Name of the field generally used to additionally index a property of state
   * for easy update. Explicitly defined so it can be provided by the user but
   * if not defined, defaults to label.
   */
  name?: string
  /**
   * Value of the field
   */
  value: string
  /**
   * Optionally allow the field to be fullWidth
   */
  fullWidth?: boolean
  /**
   * Determines if the label should shrink to properly show the value and have no overlap
   */
  shrink?: boolean
  /**
   * Optionally disable the field
   */
  disabled?: boolean
  /**
   * Optional override method for onKeyDown
   */
  onKeyDown?: (Event: React.KeyboardEvent<HTMLInputElement>) => void
  ref?: React.RefObject<React.ReactElement>
} & Partial<TextFieldProps>

const DateField: React.FC<DateFieldProps> = ({
  minDate = new Date(Date.UTC(new Date().getFullYear() - 2, 0, 1)),
  maxDate = new Date(Date.UTC(new Date().getFullYear() + 2, 11, 31)),
  onChangeForParent,
  helperTextForParent,
  error = false,
  dateVariant,
  shrink = true,
  fullWidth = true,
  disabled = false,
  onKeyDown,
  ...additionalProps
}) => {
  const { t } = useTranslation()
  const label = getLabelForVariant(dateVariant, t)
  const { validateDateRange } = useDateRangeValidity({
    minDate,
    maxDate,
  })

  function onChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (!!e.target.value && e.target.value.length === 10) {
      const selectedDate = new Date(e.target.value)
      if (!validateDateRange(selectedDate)) {
        const validity: FieldValidity = {
          input: true,
          beforeMax: selectedDate <= maxDate,
          afterMin: selectedDate >= minDate,
        }
        onChangeForParent(e, dateToDashString(selectedDate, true), validity)
      } else {
        const validity: FieldValidity = {
          input: true,
          beforeMax: true,
          afterMin: true,
        }
        onChangeForParent(e, dateToDashString(selectedDate, true), validity)
      }
    } else {
      const validity: FieldValidity = {
        input: !!e.target.value,
        beforeMax: false,
        afterMin: true,
      }
      onChangeForParent(e, e.target.value, validity)
    }
  }

  return (
    <TextField
      // Allow property overrides if we want.
      {...additionalProps}
      variant="filled"
      label={label}
      inputProps={{ 'aria-label': label }}
      onChange={onChange}
      error={error}
      InputLabelProps={{ shrink }}
      fullWidth={fullWidth}
      disabled={disabled}
      onKeyDown={onKeyDown}
      helperText={error ? helperTextForParent : ''}
      FormHelperTextProps={{ disabled: false }}
    />
  )
}

export default DateField
