/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  FetchShopifyMultipassIdentifierBody,
  FetchShopifyMultipassIdentifierBodyToJSON,
  FetchShopifyMultipassIdentifierResponse,
  FetchShopifyMultipassIdentifierResponseFromJSON,
  FetchShopifyMultipassUrlResponse,
  FetchShopifyMultipassUrlResponseFromJSON,
} from '../models'
import * as runtime from '../runtime'

export interface FetchShopifyMultipassIdentifierRequest
  extends runtime.BaseRequestParameters {
  body?: FetchShopifyMultipassIdentifierBody
}

export interface SyncShopifyDiscountsRequest
  extends runtime.BaseRequestParameters {
  userKey: number
}

/**
 *
 */
export class ShopifyApi extends runtime.BaseAPI {
  /**
   * Fetches the shopify multipass identifer based on the email in Shopify.
   */
  async fetchShopifyMultipassIdentifierRaw(
    requestParameters: FetchShopifyMultipassIdentifierRequest
  ): Promise<runtime.ApiResponse<FetchShopifyMultipassIdentifierResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/shopify/multipass-identifier`,
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      body: FetchShopifyMultipassIdentifierBodyToJSON(requestParameters.body),
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchShopifyMultipassIdentifierResponseFromJSON(jsonValue)
    )
  }

  /**
   * Fetches the shopify multipass identifer based on the email in Shopify.
   */
  async fetchShopifyMultipassIdentifier(
    requestParameters: FetchShopifyMultipassIdentifierRequest
  ): Promise<FetchShopifyMultipassIdentifierResponse> {
    const response = await this.fetchShopifyMultipassIdentifierRaw(
      requestParameters
    )
    return await response.value()
  }

  /**
   * Confirms/signs login information to connect users from NC3 to Shopify.
   */
  async fetchShopifyMultipassUrlRaw(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<runtime.ApiResponse<FetchShopifyMultipassUrlResponse>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/shopify/multipass-login`,
      method: 'GET',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FetchShopifyMultipassUrlResponseFromJSON(jsonValue)
    )
  }

  /**
   * Confirms/signs login information to connect users from NC3 to Shopify.
   */
  async fetchShopifyMultipassUrl(
    requestParameters: runtime.BaseRequestParameters
  ): Promise<FetchShopifyMultipassUrlResponse> {
    const response = await this.fetchShopifyMultipassUrlRaw(requestParameters)
    return await response.value()
  }

  /**
   * Syncs shopify discounts to Shopify based on criteria in NC3. This endpoint is called by Shopify.
   */
  async syncShopifyDiscountsRaw(
    requestParameters: SyncShopifyDiscountsRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.userKey === null ||
      requestParameters.userKey === undefined
    ) {
      throw new runtime.RequiredError(
        'userKey',
        'Required parameter requestParameters.userKey was null or undefined when calling syncShopifyDiscounts.'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Cookie'] = this.configuration.apiKey('Cookie') // sessionIdCookie authentication
    }

    const response = await this.request({
      path: `/shopify/discounts/{userKey}`.replace(
        `{${'userKey'}}`,
        encodeURIComponent(String(requestParameters.userKey))
      ),
      method: 'POST',
      headers: headerParameters,
      query: queryParameters,
      $signal: requestParameters.$signal,
    })

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Syncs shopify discounts to Shopify based on criteria in NC3. This endpoint is called by Shopify.
   */
  async syncShopifyDiscounts(
    requestParameters: SyncShopifyDiscountsRequest
  ): Promise<void> {
    await this.syncShopifyDiscountsRaw(requestParameters)
  }
}
