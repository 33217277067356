/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The status of the parent's request for an admin to send the transcript to parchment.
 * @export
 * @enum {string}
 */
export enum TranscriptParchmentRequestStatus {
  NotRequested = 'Not Requested',
  Requested = 'Requested',
  Sent = 'Sent',
}

export function TranscriptParchmentRequestStatusFromJSON(
  json: any
): TranscriptParchmentRequestStatus {
  return TranscriptParchmentRequestStatusFromJSONTyped(json, false)
}

export function TranscriptParchmentRequestStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TranscriptParchmentRequestStatus {
  return json as TranscriptParchmentRequestStatus
}

export function TranscriptParchmentRequestStatusToJSON(
  value?: TranscriptParchmentRequestStatus | null
): any {
  return value as any
}
