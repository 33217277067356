/**
 * The SignUp Flow was previously: FamilyInvite (and soon to be TeamInvite)
 *
 * !!! IMPORTANT !!!
 *  WE DO NOT WISH TO CREATE MULTIPLE ACCOUNTS FROM THE SAME INVITE
 *
 * HOW DO WE DETERMINE WHICH SIGN UP PROCESS TO VALIDATE?
 *  - An invitationType returned from fetchInviteValidity
 *
 * All sign-ups require a valid uuid, otherwise we'll disallow sign-up.
 *
 * Validation comes through fetchInviteValidity which returns
 *  - a validity enum, determining whether the invite is valid
 *  - an acceptedEmail, provided if the username already exists when invited
 *  - invitationType, determining to follow the Team or Family invitation process
 *
 *
 * If the `acceptedEmail` is provided , we require that email to be used to
 * sign-in. The `acceptedEmail` is not sent for new users.
 *
 * Based on whether acceptedEmail is provided determines if the Login or SignUp
 * flow is presented. Because this is the determinant, the Login/SignUp toggle
 * button is removed.
 *
 * After Logging in, the country-information flow is followed if not yet set.
 * In most cases this is already setup for existing users.
 *
 * After signing up, the user is prompted the verify the email with a
 * verification code to input First and Last names, followed by Country
 * Information, and finally, a Go To Your Account button which navigates to
 * the account invites tab.
 *
 * SignUp includes:
 *  - Validating then email provided in the username field
 *    - Calling sendTokenForSignup
 *  - Calling signup with the credentials, first/last names, and email
 *      verification token
 *  - Allowing resend of the code via sendTokenForSignup
 *    IMPORTANT NOTE: This requires a signUpType, but perhaps we won't need that
 *      when including TEAM INVITES in this flow
 *
 * FOR FAMILY INVITATIONS
 *
 * After SignUp/Login the user is navigated to /account/invites (the Account > Invites)
 *
 * FOR TEAM INVITATIONS
 *
 * After SignUp/Login the agreement status is checked, and the agreement is
 * completed if signed or the agreement is bypassed.
 *
 */
import React from 'react'
import AuthFormCard from './AuthFormCard'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import { useNavigate, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useInvitationMethods } from './hooks/useInvitationMethods'
import VerificationCodeCard from '../Card/VerificationCodeCard'
import { LoadingContext } from '../Context/LoadingContext'
import SignUpInformationCard from '../Card/SignUpInformationCard'
import { InviteStep, useInviteContext } from '../Context/InviteContext'
import CountryInformation from '../Card/CountryInformation'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { useMountEffect } from '../../hooks/useMountEffect'
import useLoadingContext from '../../hooks/useLoadingContext'
import LoadingProgress from '../Elements/LoadingProgress'
import CenterWithMountainBackground from '../Elements/CenterWithMountainBackground'
import MessageCard from '../Card/MessageCard'
import BackToLoginButton from '../Buttons/BackToLoginButton'
import {
  InvitationType,
  InviteValidity,
  LoginResponseFeatureDefaults,
  Permission,
} from '../../swagger'
import { useLoadingIds } from '../../hooks/useLoadingIds'

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'block',
  margin: theme.spacing(1.5, 8, 0),
})) as typeof Typography

const StyledBackToLoginButton = styled(BackToLoginButton)(({ theme }) => ({
  flexGrow: 1,
  width: '96.75%',
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(2),
  margin: '0 !important',
}))

const InviteForm: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { inviteKey } = useParams<{ inviteKey: string }>()
  const {
    handleSignUp,
    error,
    emailVerificationToken,
    resendLoadingId,
    signUpVerificationLoadingId,
    handleLogin,
    isWrongAccount,
    backToLoginForInvite,
    backToLogin,
  } = useInvitationMethods({
    inviteKey,
  })
  const {
    step,
    verificationCodeHasBeenResent,
    isVerificationInputLongEnough,
    updateEmailVerificationToken: setEmailVerificationTokenForParent,
    fetchInviteValidity,
    fetchInviteValidityLoadingId,
    emailForInvitation,
    invitationType,
    inviteValidity,
    completeAgreementLoadingId,
    completeAgreement,
  } = useInviteContext()

  const { InviteForm } = useLoadingIds()
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)

  useLoadingContext({
    asyncFunction: async () => {
      if (!!inviteKey) {
        await fetchInviteValidity(inviteKey)
      }
    },
    loadingId: fetchInviteValidityLoadingId,
  })

  const goToAccount = () => {
    /**
     * Since at this point the states in AuthProvider aren't updated yet, we are getting `permissions` and `defaults` values from the local storage.
     */
    const permissions = JSON.parse(
      localStorage.getItem('permissions') ?? ''
    ) as unknown as Permission[]
    const defaults = JSON.parse(
      localStorage.getItem('defaults') ?? ''
    ) as unknown as LoginResponseFeatureDefaults[]
    const hasDashboardDirectorPermission = permissions.some((permission) => {
      return (
        permission.resourceCode === 'User' &&
        permission.actionCode === 'viewDirectorDashboard'
      )
    })

    const hasDashboardFeature = defaults.some((defaultFeature) => {
      return (
        defaultFeature.resourceCode === 'Feature' &&
        defaultFeature.actionCode === 'viewDashboard'
      )
    })

    const canViewDirectorDashboard =
      hasDashboardDirectorPermission && hasDashboardFeature
    navigate(
      {
        pathname: `/account/${
          invitationType === InvitationType.Family
            ? 'invites'
            : canViewDirectorDashboard
            ? 'dashboard'
            : 'profile'
        }`,
      },
      { replace: true }
    )
  }

  useLoadingContext({
    loadingId: completeAgreementLoadingId,
    asyncFunction: async () => {
      if (!!inviteKey && invitationType === InvitationType.Team) {
        await completeAgreement(inviteKey)
      }
    },
    callback: goToAccount,
  })

  useMountEffect(() => {
    if (!!inviteKey) {
      addLoadingIds([fetchInviteValidityLoadingId])
    }
  })

  /**
   * Show a spinner for any loads. For now, signing out is considered a load in this context
   */
  if (
    loadingIds.has(fetchInviteValidityLoadingId) ||
    loadingIds.has(InviteForm.logout)
  ) {
    return (
      <CenterWithMountainBackground>
        <LoadingProgress />
      </CenterWithMountainBackground>
    )
  }

  if (isWrongAccount) {
    return (
      <MessageCard
        heading={t(
          'FamilyInvite.WrongAccount.Heading',
          'This invitation is not associated with this account'
        )}
        body={t(
          'FamilyInvite.WrongAccount.Body',
          'Please log out and log back in with the account associated with the invitation you signed up.'
        )}
        actionBtn={<StyledBackToLoginButton onClick={backToLoginForInvite} />}
      />
    )
  }

  if (
    inviteValidity !== InviteValidity.Valid &&
    inviteValidity !== InviteValidity.Bypassed
  )
    return (
      <MessageCard
        heading={
          inviteValidity !== InviteValidity.Completed
            ? t(
                'FamilyInvite.InvitationValidity.Heading.Default',
                'This Invitation No Longer Exists'
              )
            : t(
                'FamilyInvite.InvitationValidity.Heading.Completed',
                'This Invitation is Already Complete'
              )
        }
        body={
          inviteValidity !== InviteValidity.Completed
            ? t(
                'FamilyInvite.InvitationValidity.Body.Default',
                'This invitation no longer exists in our system. Please reach out to the person who sent the invitation for more information.'
              )
            : t(
                'FamilyInvite.InvitationValidity.Body.Completed',
                'You have already completed this invitation. If you need to login, click Back to Login below.'
              )
        }
        actionBtn={<StyledBackToLoginButton onClick={backToLogin} />}
      />
    )

  if (step === InviteStep.SignUp) {
    return (
      <AuthFormCard
        isSignUp={!emailForInvitation}
        errorMessage={error}
        handleClick={!!emailForInvitation ? handleLogin : handleSignUp}
        /** No secondaryButton because we don't care to toggle */
        additionalHeaderContent={
          <HeaderText variant="h6" component="h2">
            {!!emailForInvitation
              ? t('InviteForm.Steps.Title.Login', 'Sign In')
              : t('InviteForm.Steps.Title.SignUp', "Let's get started!")}
          </HeaderText>
        }
        name={AuthFormCard.name}
      />
    )
  }

  if (step === InviteStep.SignUpInformation) {
    return <SignUpInformationCard />
  }

  if (step === InviteStep.EmailVerification) {
    return (
      <VerificationCodeCard
        error={error}
        setEmailVerificationTokenForParent={setEmailVerificationTokenForParent}
        handleSignUpConfirmVerificationCode={() => {
          addLoadingIds([signUpVerificationLoadingId])
        }}
        isVerificationInputLongEnough={isVerificationInputLongEnough}
        emailVerificationToken={emailVerificationToken}
        onClickResendCode={() => {
          addLoadingIds([resendLoadingId])
        }}
        verificationCodeHasBeenResent={verificationCodeHasBeenResent}
        resendLoadingId={resendLoadingId}
        primaryLoadingId={signUpVerificationLoadingId}
      />
    )
  }

  if (step === InviteStep.AccountInfo) {
    return (
      <CountryInformation
        handleCallback={() => {
          if (invitationType === InvitationType.Team) {
            addLoadingIds([completeAgreementLoadingId])
          } else {
            goToAccount()
          }
        }}
        containedButtonVariant={ContainedButtonVariant.GoToYourAccount}
        excludeBack
      />
    )
  }

  return null
}

export default InviteForm
