import React, { CSSProperties, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import PasswordField from './PasswordField'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Info from '@mui/icons-material/Info'
import { Box } from '@mui/material'

enum FieldType {
  Password,
  PasswordConfirm,
}

export enum PasswordFieldIds {
  Password = 'password',
  PasswordConfirm = 'passwordConfirm',
  NewPassword = 'newPassword',
  NewPasswordConfirm = 'newPasswordConfirm',
}

export interface PasswordFieldsProps {
  handleKeyboardEvent: (event: React.KeyboardEvent<Element>) => void
  passwordsDontMatch: boolean
  newPasswordsDontMatch: boolean
  handleInputEvent: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  emptyPasswordConfirmField: boolean
  emptyPasswordField: boolean
  emptyNewPasswordConfirmField: boolean
  emptyNewPasswordField: boolean
  includePasswordConfirm?: boolean
  includeNewPasswordConfirm?: boolean
  passwordIsEmptyOrInvalid: boolean
  newPasswordIsEmptyOrInvalid?: boolean
  newPasswordConfirmIsEmptyOrInvalid?: boolean
  passwordInvalid: boolean
  newPasswordInvalid: boolean
  password: string
  passwordConfirm: string
  newPassword?: string
  newPasswordConfirm?: string
  setPasswordInvalid?: (isValid: boolean) => void
  setPasswordsDontMatch?: (isMatch: boolean) => void
  setEmptyPasswordConfirmField?: (isEmpty: boolean) => void
  setEmptyPasswordField?: (isEmpty: boolean) => void
  clearErrors?: () => void
}

export const PasswordFields: React.FC<PasswordFieldsProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    passwordsDontMatch,
    handleInputEvent,
    emptyPasswordConfirmField,
    emptyPasswordField,
    includePasswordConfirm = false,
    handleKeyboardEvent,
    passwordIsEmptyOrInvalid,
    passwordInvalid,
    password,
    passwordConfirm,
  } = props

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  const passwordRules = [
    t('PasswordFields.PasswordRule.One', 'At least 1 lower case character'),
    t('PasswordFields.PasswordRule.Two', 'At least 1 upper case character'),
    t('PasswordFields.PasswordRule.Three', '8 characters minimum'),
  ]

  function PasswordFieldIcon(isEmpty: boolean, isVisible: boolean) {
    const ToggleIcon = isVisible ? Visibility : VisibilityOff

    if (isEmpty) {
      return (
        <Info
          sx={{
            fill: theme.palette.error.main,
          }}
        />
      )
    }
    return <ToggleIcon />
  }

  const handleToggle = (fieldType: FieldType) => {
    if (fieldType === FieldType.Password) {
      setShowPassword(!showPassword)
      document.getElementById('password')?.focus()
    } else {
      setShowPasswordConfirm(!showPasswordConfirm)
      document.getElementById('passwordConfirm')?.focus()
    }
  }

  const commonProps = {
    marginTop: theme.spacing(4),
  } as CSSProperties

  return (
    <>
      <PasswordField
        id={PasswordFieldIds.Password}
        css={{
          ...commonProps,
        }}
        error={passwordIsEmptyOrInvalid}
        helperText={`${
          emptyPasswordField
            ? t(
                'PasswordFields.PasswordField.HelperText',
                'Please enter a password.'
              )
            : includePasswordConfirm && passwordInvalid
            ? t(
                'Signup.PasswordField.HelperText',
                'Please enter a password that meets all criteria.'
              )
            : ''
        }`}
        value={password}
        label={t('Login.PasswordField.Label', 'Password')}
        type={showPassword ? 'text' : 'password'}
        onChange={handleInputEvent}
        onBlur={handleInputEvent}
        onKeyPress={handleKeyboardEvent}
        inputProps={{ maxLength: 128 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={passwordIsEmptyOrInvalid}
                aria-label={t(
                  'Login.PasswordField.IconButton.AriaLabel',
                  'Toggle password visibility'
                )}
                onClick={
                  !emptyPasswordField
                    ? () => handleToggle(FieldType.Password)
                    : () => null
                }
                edge="end"
              >
                {PasswordFieldIcon(passwordIsEmptyOrInvalid, showPassword)}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {includePasswordConfirm && (
        <>
          <Box
            sx={{
              textAlign: 'start',
              color: theme.palette.primary.main,
              opacity: 0.65,
              marginLeft: theme.spacing(1.5),
            }}
          >
            <Typography variant="body2">{`${t(
              'PasswordFields.PasswordRule.Heading',
              'Password must contain'
            )}:`}</Typography>
            {passwordRules.map((rule) => (
              <Typography key={rule} variant="body2">
                {`- ${rule}`}
              </Typography>
            ))}
          </Box>
          <PasswordField
            id={PasswordFieldIds.PasswordConfirm}
            css={{
              ...commonProps,
            }}
            error={emptyPasswordConfirmField || passwordsDontMatch}
            helperText={`${
              emptyPasswordConfirmField || passwordsDontMatch
                ? t(
                    'PasswordFields.PasswordConfirmField.HelperText',
                    'Passwords must match.'
                  )
                : ''
            }`}
            value={passwordConfirm}
            label={t(
              'PasswordFields.PasswordConfirmField.Label',
              'Confirm Password'
            )}
            type={showPasswordConfirm ? 'text' : 'password'}
            onChange={handleInputEvent}
            onBlur={handleInputEvent}
            onKeyPress={handleKeyboardEvent}
            inputProps={{ maxLength: 128 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={emptyPasswordConfirmField}
                    aria-label={t(
                      'PasswordFields.PasswordField.IconButton.AriaLabel',
                      'toggle password visibility'
                    )}
                    onClick={
                      !emptyPasswordConfirmField
                        ? () => handleToggle(FieldType.PasswordConfirm)
                        : () => null
                    }
                    edge="end"
                  >
                    {PasswordFieldIcon(
                      emptyPasswordConfirmField || passwordsDontMatch,
                      showPasswordConfirm
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
    </>
  )
}

export default PasswordFields
