import React from 'react'
import IconButton from '@mui/material/IconButton'
import GetAppIcon from '@mui/icons-material/GetApp'
import { contentApi, extractedErrorObject } from '../../api/swagger'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

interface DownloadButtonProps {
  assetKey: number
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ assetKey }) => {
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const { t } = useTranslation()
  const theme = useTheme()

  const handleDownload = async () => {
    try {
      const { downloadUrl } = await contentApi.fetchDownloadUrl({
        assetKey,
      })
      window.open(downloadUrl, '_self')
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'UnknownError',
        message:
          (error as unknown as Error).message ??
          t(
            'DownloadButton.FetchDownloadUrl.Error',
            'Failed to fetch download link'
          ),
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    }
  }

  return (
    <IconButton
      aria-label="download content"
      sx={{
        color: theme.palette.primary.dark,
      }}
      onClick={handleDownload}
    >
      <GetAppIcon />
    </IconButton>
  )
}

export default DownloadButton
