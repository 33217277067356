import {
  TableRow,
  TableCell,
  Collapse,
  Table,
  TableHead,
  TableBody,
  IconButton,
} from '@mui/material'
import React, { useState } from 'react'
import { Student } from '../../swagger'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import TableHeaders from '../Interfaces/TableHeaders'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TableHeader from './TableHeader'
import ChildrenBirthMonthField from '../Elements/ChildrenBirthMonthField'
import ChildrenNameField from '../Elements/ChildrenNameField'
import ChildrenEnrollmentStatusField from '../Elements/ChildrenEnrollmentStatusField'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'
import { styled } from '@mui/system'
import ChildrenBirthYearField from '../Elements/ChildrenBirthYearField'
import CheckboxControl from '../Checkboxes/CheckboxControl'
import { useAuth } from '../Routes/AuthProvider'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  color: theme.palette.primary.main,
  paddingBottom: '10px',
  paddingTop: '10px',
  verticalAlign: 'top',
}))

export interface StudentDynamicFieldProps {
  kid: Student
  isEditMode: boolean
  handleFieldValueChange: (fieldId: string, value: string) => void
  childNameStates: DynamicFieldStates
  childHideStates: DynamicFieldStates
  childBirthMonthStates: DynamicFieldStates
  childBirthYearStates: DynamicFieldStates
  enrollmentStates?: DynamicFieldStates
  tableHeaders?: TableHeaders[]
  canUserUpdateAnyEnrollmentStatus: boolean
  handleEnrollmentStatusChange: (opts: {
    fieldId: string
    enrollmentStatus: string
    studentKey: number
    programKey: number
    studentName: string
  }) => void
  directorOnlyEnrollmentStatuses: string[]
  isAccountVariant?: boolean
}

const StudentEnrollmentsRows: React.FC<StudentDynamicFieldProps> = ({
  kid,
  isEditMode,
  handleFieldValueChange,
  childNameStates,
  childHideStates,
  childBirthMonthStates,
  childBirthYearStates,
  tableHeaders,
  canUserUpdateAnyEnrollmentStatus,
  handleEnrollmentStatusChange,
  directorOnlyEnrollmentStatuses,
  enrollmentStates,
}) => {
  const [open, setOpen] = useState(true)
  const { permissionAbility } = useAuth()

  const enrollmentStatusForStudents = (status: string): string => {
    return enrollmentStates?.[status]
      ? enrollmentStates?.[status].state.replace('_', ' ')
      : ''
  }

  const canHideChild = permissionAbility.can('hide', 'Student')

  const enrollmentStatusRows = kid.programs.map((program) => {
    const disabled =
      (!canUserUpdateAnyEnrollmentStatus &&
        // So disable the field if the value is something we cannot see (PENDING, CHECK, INVITED)
        !directorOnlyEnrollmentStatuses.some(
          (status) =>
            status ===
            enrollmentStatusForStudents(
              `${program.programKey}-${kid.studentKey}`
            )
        )) ||
      !isEditMode

    const studentEnrollmentStatus = enrollmentStatusForStudents(
      `${program.programKey}-${kid.studentKey}`
    )
    return (
      <TableRow
        key={`${kid.studentKey}-${program.programKey}-${program.programType}`}
      >
        <StyledTableCell>
          {!new RegExp(/Invalid Date/i).test(
            program.semesterOneStartDate.toString()
          )
            ? `${program.programType} (${dateToSlashStringReinterpretedAsLocal(
                program.semesterOneStartDate
              )}) - ${program.communityName}`
            : ''}
        </StyledTableCell>
        <StyledTableCell>
          {enrollmentStates ? (
            <ChildrenEnrollmentStatusField
              kid={kid}
              program={program}
              updateForParent={handleEnrollmentStatusChange}
              enrollmentStates={enrollmentStates}
              studentEnrollmentStatus={studentEnrollmentStatus}
              isEditMode={isEditMode}
              disabled={disabled}
            />
          ) : null}
        </StyledTableCell>
        <StyledTableCell>
          {!new RegExp(/Invalid Date/i).test(
            program.enrollmentStatusValidFrom.toString()
          )
            ? dateToSlashStringReinterpretedAsLocal(
                program.enrollmentStatusValidFrom
              )
            : ''}
        </StyledTableCell>
      </TableRow>
    )
  })

  return (
    <>
      {(!kid.hidden || canHideChild) && (
        <>
          <TableRow key={`${kid.studentKey}`}>
            <StyledTableCell colSpan={1}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                sx={{
                  width: '100%',
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </StyledTableCell>
            <StyledTableCell
              scope="row"
              colSpan={2}
              sx={{
                paddingRight: 2,
                width: '25%',
              }}
            >
              <ChildrenNameField
                kid={kid}
                isEditMode={isEditMode}
                updateForParent={handleFieldValueChange}
                childNameStates={childNameStates}
              />
            </StyledTableCell>
            <StyledTableCell
              sx={{
                paddingRight: 2,
                width: '30%',
              }}
            >
              <ChildrenBirthMonthField
                kid={kid}
                isEditMode={isEditMode}
                updateForParent={handleFieldValueChange}
                childBirthMonthStates={childBirthMonthStates}
                isMobile
              />
            </StyledTableCell>
            <StyledTableCell
              sx={{
                paddingRight: 2,
                width: '30%',
              }}
            >
              <ChildrenBirthYearField
                kid={kid}
                isEditMode={isEditMode}
                updateForParent={handleFieldValueChange}
                childBirthYearStates={childBirthYearStates}
                isMobile
              />
            </StyledTableCell>
            {canHideChild && (
              <StyledTableCell
                sx={{
                  paddingRight: 2,
                  width: '30%',
                }}
              >
                <CheckboxControl
                  label="Hide Child"
                  name="hideChild"
                  checkboxValue={
                    childHideStates[`${kid.studentKey}-hide`]
                      ? JSON.parse(
                          childHideStates[`${kid.studentKey}-hide`].state
                        )
                      : false
                  }
                  disabled={!isEditMode}
                  OnChange={(event) =>
                    handleFieldValueChange(
                      `${kid.studentKey}-hide`,
                      event.toString()
                    )
                  }
                />
              </StyledTableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                paddingLeft: '25%',
              }}
              colSpan={6}
            >
              <Collapse in={open} unmountOnExit timeout="auto">
                <Table
                  aria-label={`enrollments-student-${kid.firstName}-${kid.studentKey}`}
                >
                  <TableHead>
                    <TableHeader tableHeaders={tableHeaders ?? []} />
                  </TableHead>
                  <TableBody>{enrollmentStatusRows}</TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  )
}

export default StudentEnrollmentsRows
