import React from 'react'
import BasicModal, { BasicModalProps } from './BasicModal'

export const ConfirmationModal: React.FC<Omit<BasicModalProps, 'maxWidth'>> = (
  props
) => {
  return <BasicModal {...props} maxWidth="xs" />
}

export default ConfirmationModal
