import React, { PropsWithChildren, useState } from 'react'
import {
  EnrollmentInvite,
  EnrollmentInviteStatusEnum,
  EnrollmentStatus,
  FetchEnrollmentInvitesStatusEnum,
} from '../../swagger'
import { enrollments as enrollmentsApi } from '../../api/swagger'
import { defaultPageSize } from '../Pagination/TableFooterPagination'
import EnrollmentInviteRowInfo from '../Interfaces/EnrollmentInviteRowInfo'
import EnrollmentRowInfo from '../Interfaces/EnrollmentRowInfo'
import { PaginationResponse } from '../../swagger/models/PaginationResponse'

export const ENROLLMENT_INVITES_MIN_SEARCH_LENGTH = 2
export const ENROLLMENT_MIN_SEARCH_LENGTH = 3

export const enrollmentInviteToEnrollmentInviteRowInfo = (
  enrollmentInvite: EnrollmentInvite,
  programKey: number
): EnrollmentInviteRowInfo => {
  const program = enrollmentInvite.programs.find(
    (program) => program.programKey === programKey
  )
  return {
    rowId: enrollmentInvite.uuid,
    familyName: enrollmentInvite.parentLastName,
    studentKey: -1,
    inviteDate: enrollmentInvite.invitationDate,
    resentDate: enrollmentInvite.resentDate,
    enrollmentInviteStatus: enrollmentInvite.status,
    sentToEmail: enrollmentInvite.sentToEmail,
    offeredSpots: program?.offeredSpots ?? 0,
    skippedSpots: program?.skippedSpots ?? 0,
    primaryContactId: enrollmentInvite.parentUserKey,
  }
}

export enum ApplicationTypeFilterOptions {
  AllTypes = 'All Application Types',
  New = 'New',
  Returning = 'Returning',
  Invitation = 'Invitation',
}

export interface EnrollmentFilters {
  applicationType: ApplicationTypeFilterOptions
  enrollmentStatus: string
  tutorId: string | number
}

interface EnrollmentInviteFilters {
  inviteStatus: string
}

export const enrollmentStatusFilterOptions = {
  AllEnrollmentStatuses: 'All Enrollment Statuses',
  ...EnrollmentStatus,
}

export const enrollmentInviteStatusFilterOptions = {
  AllStatus: 'All Invite Statuses',
  ...EnrollmentInviteStatusEnum,
}

export const defaultTutorFilterOption = 'All Tutors'

export const defaultProgramDetailsContextValue = {
  /** Available filters for the enrollments. */
  enrollmentFilters: {
    applicationType: ApplicationTypeFilterOptions.AllTypes,
    enrollmentStatus: enrollmentStatusFilterOptions.AllEnrollmentStatuses,
    tutorId: defaultTutorFilterOption as EnrollmentFilters['tutorId'],
  },

  /** Available filters for the enrollment invites. */
  enrollmentInviteFilters: {
    inviteStatus: enrollmentInviteStatusFilterOptions.AllStatus,
  },

  /** Indicates if the user has enrollment invites with in progress status. */
  hasEnrollmentInvitesWithInProgressStatus: false,

  /** Indicates if the user is searching or filtering the enrollment invites. */
  isSearchingOrFilteringEnrollmentInvites: false,

  /** Search value for the enrollments. */
  searchEnrollments: '',

  /** Search value for the enrollment invites. */
  searchEnrollmentInvites: '',

  /** Pagination info for the enrollments. */
  enrollmentsPagination: {
    totalCount: 0,
    pageSize: defaultPageSize,
    page: 1,
  },

  /** Pagination info for the enrollment invites. */
  enrollmentInvitesPagination: {
    totalCount: 0,
    pageSize: defaultPageSize,
    page: 1,
    orderBy: ['parentLastName ASC'],
  },

  /** Enrollments for a program. */
  enrollments: [] as EnrollmentRowInfo[],

  /** Enrollments invites for a program. */
  enrollmentInvites: [] as EnrollmentInviteRowInfo[],

  fetchEnrollments: async (programKey: number): Promise<void> => {
    console.warn(
      `The ProgramDetailsContext.fetchEnrollments was called with ${programKey}. Did you forget to use a ProgramDetailsProvider?`
    )
  },

  fetchEnrollmentInvites: async (programKey: number): Promise<void> => {
    console.warn(
      `The ProgramDetailsContext.fetchEnrollmentInvites was called with ${programKey}. Did you forget to use a ProgramDetailsProvider?`
    )
  },

  handleEnrollmentsPageChange: (page: number): void => {
    console.warn(
      `The ProgramDetailsContext.handleEnrollmentsPageChange was called with ${page} value. Did you forget to use a ProgramDetailsProvider?`
    )
  },

  handleEnrollmentsPageSizeChange: (pageSize: number): void => {
    console.warn(
      `The ProgramDetailsContext.handleEnrollmentsPageSizeChange was called with ${pageSize} value. Did you forget to use a ProgramDetailsProvider?`
    )
  },

  updateEnrollmentFilters: (filters: EnrollmentFilters): void => {
    console.warn(
      `The ProgramDetailsContext.updateEnrollmentFilters was called with ${filters} value. Did you forget to use a ProgramDetailsProvider?`
    )
  },

  updateEnrollmentInviteFilters: (filters: EnrollmentInviteFilters): void => {
    console.warn(
      `The ProgramDetailsContext.updateEnrollmentInviteFilters was called with ${filters} value. Did you forget to use a ProgramDetailsProvider?`
    )
  },

  updateSearchEnrollments: (search: string): void => {
    console.warn(
      `The ProgramDetailsContext.updateSearchEnrollments was called with ${search} value. Did you forget to use a ProgramDetailsProvider?`
    )
  },

  updateSearchEnrollmentInvites: (search: string): void => {
    console.warn(
      `The ProgramDetailsContext.updateSearchEnrollmentInvites was called with ${search} value. Did you forget to use a ProgramDetailsProvider?`
    )
  },
  handleEnrollmentInvitesPagination: (
    pageInformation: PaginationResponse
  ): void => {
    console.warn(
      `The ProgramDetailsContext.handleEnrollmentInvitesPagination was called with ${pageInformation} value. Did you forget to use a ProgramDetailsProvider?`
    )
  },

  resetContextToDefaults: (): void => {
    console.warn(
      `The ProgramDetailsContext.resetContextToDefaults was called. Did you forget to use a ProgramDetailsProvider?`
    )
  },
}

export const ProgramDetailsContext = React.createContext(
  defaultProgramDetailsContextValue
)

export type TestProgramDetailsContextConfig =
  typeof defaultProgramDetailsContextValue

export interface TestProgramDetailsContextProps extends PropsWithChildren {
  testConfig?: Partial<TestProgramDetailsContextConfig>
}

export const useProgramDetailsContext =
  (): typeof defaultProgramDetailsContextValue =>
    React.useContext(ProgramDetailsContext)

const ProgramDetailsProvider: React.FC<TestProgramDetailsContextProps> = ({
  testConfig,
  children,
}) => {
  const [searchEnrollments, setSearchEnrollments] = useState('')
  const [searchEnrollmentInvites, setSearchEnrollmentInvites] = useState('')

  const [enrollments, setEnrollments] = useState<EnrollmentRowInfo[]>([])

  const [enrollmentInvites, setEnrollmentInvites] = useState<
    EnrollmentInviteRowInfo[]
  >([])

  const [enrollmentsPagination, setEnrollmentsPagination] = useState({
    totalCount: 0,
    pageSize: defaultPageSize,
    page: 1,
  })

  const [enrollmentInvitesPagination, setEnrollmentInvitesPagination] =
    useState({
      totalCount: 0,
      pageSize: defaultPageSize,
      page: 1,
      orderBy:
        defaultProgramDetailsContextValue.enrollmentInvitesPagination.orderBy,
    })

  const [enrollmentFilters, setEnrollmentFilters] = useState<EnrollmentFilters>(
    {
      applicationType: ApplicationTypeFilterOptions.AllTypes,
      enrollmentStatus: enrollmentStatusFilterOptions.AllEnrollmentStatuses,
      tutorId: defaultTutorFilterOption,
    }
  )

  const [enrollmentInviteFilters, setEnrollmentInviteFilters] =
    useState<EnrollmentInviteFilters>({
      inviteStatus: enrollmentInviteStatusFilterOptions.AllStatus,
    })

  const fetchEnrollments = async (programKey: number) => {
    const tutorActorKey =
      enrollmentFilters.tutorId !== defaultTutorFilterOption
        ? Number(enrollmentFilters.tutorId)
        : undefined

    const enrollmentStatus = (
      enrollmentFilters.enrollmentStatus !==
      enrollmentStatusFilterOptions.AllEnrollmentStatuses
        ? enrollmentFilters.enrollmentStatus
        : undefined
    ) as EnrollmentStatus

    const search =
      searchEnrollments.trim().length >= ENROLLMENT_MIN_SEARCH_LENGTH
        ? searchEnrollments
        : undefined

    const fetchedEnrollments = await enrollmentsApi.fetchEnrollments({
      programKey,
      tutorActorKey,
      enrollmentStatus,
      search,
      page: enrollmentsPagination.page,
      pageSize: enrollmentsPagination.pageSize,
      year: undefined,
      /**
       * Since the sortEnrollmentsByStudentName helper was removed
       * from the EnrollmentsTable component and the order of the enrollments
       * is now determined by the backend, we need to pass the sortBy property
       * to the endpoint to get the same order as before.
       */
      orderBy: ['familyName ASC', 'studentName ASC'],
    })

    const enrollments = fetchedEnrollments.enrollments.map((enrollment) => {
      return {
        rowId: `${enrollment.studentKey}${enrollment.programKey}`,
        ...enrollment,
      }
    })

    setEnrollments(enrollments)

    if (fetchedEnrollments.pagination) {
      const { page, pageSize, totalCount } = fetchedEnrollments.pagination
      setEnrollmentsPagination({
        page,
        pageSize,
        totalCount,
      })
    }
  }

  const fetchEnrollmentInvites = async (programKey: number) => {
    const search =
      searchEnrollmentInvites.trim().length >= 2
        ? searchEnrollmentInvites
        : undefined

    const status =
      enrollmentInviteFilters.inviteStatus !==
      enrollmentInviteStatusFilterOptions.AllStatus
        ? (enrollmentInviteFilters.inviteStatus as FetchEnrollmentInvitesStatusEnum)
        : undefined

    const fetchedEnrollmentInvites =
      await enrollmentsApi.fetchEnrollmentInvites({
        programKey,
        page: enrollmentInvitesPagination.page,
        pageSize: enrollmentInvitesPagination.pageSize,
        search,
        status,
        orderBy: enrollmentInvitesPagination.orderBy,
      })

    if (fetchedEnrollmentInvites.enrollmentInvites) {
      const enrollmentInvites = fetchedEnrollmentInvites.enrollmentInvites.map(
        (enrollmentInvite) =>
          enrollmentInviteToEnrollmentInviteRowInfo(
            enrollmentInvite,
            programKey
          )
      )
      setEnrollmentInvites(enrollmentInvites)
    }

    if (fetchedEnrollmentInvites.pagination) {
      const { page, pageSize, totalCount } = fetchedEnrollmentInvites.pagination
      setEnrollmentInvitesPagination({
        ...enrollmentInvitesPagination,
        page,
        pageSize,
        totalCount,
      })
    }
  }

  const handleEnrollmentsPageChange = (page: number) => {
    setEnrollmentsPagination({
      ...enrollmentsPagination,
      page: page + 1,
    })
  }

  const handleEnrollmentInvitesPagination = (
    pageInformation: PaginationResponse
  ) => {
    const orderByQuery = pageInformation.orderBy.map((query) => {
      const [key, value] = Object.entries(query)[0]
      return `${key == 'inviteDate' ? 'CreatedDate' : key} ${value}`
    })

    setEnrollmentInvitesPagination({
      ...pageInformation,
      orderBy: orderByQuery,
      page: pageInformation.page + 1,
    })
  }

  const handleEnrollmentsPageSizeChange = (pageSize: number) => {
    setEnrollmentsPagination({ ...enrollmentsPagination, pageSize })
  }

  const isSearchingEnrollmentInvites = (): boolean => {
    return (
      searchEnrollmentInvites.length >= ENROLLMENT_INVITES_MIN_SEARCH_LENGTH
    )
  }

  const isFilteringEnrollmentInvites = (): boolean => {
    return (
      enrollmentInviteFilters.inviteStatus !==
      enrollmentInviteStatusFilterOptions.AllStatus
    )
  }

  const isSearchingOrFilteringEnrollmentInvites =
    isSearchingEnrollmentInvites() || isFilteringEnrollmentInvites()

  const hasEnrollmentInvitesWithInProgressStatus =
    enrollmentInvites &&
    enrollmentInvites.some(
      (enrollmentInvite) =>
        enrollmentInvite.enrollmentInviteStatus ===
        EnrollmentInviteStatusEnum.InProgress
    )

  const resetContextToDefaults = () => {
    setEnrollmentFilters(defaultProgramDetailsContextValue.enrollmentFilters)
    setEnrollmentInviteFilters(
      defaultProgramDetailsContextValue.enrollmentInviteFilters
    )
    setSearchEnrollments(defaultProgramDetailsContextValue.searchEnrollments)
    setSearchEnrollmentInvites(
      defaultProgramDetailsContextValue.searchEnrollmentInvites
    )
    setEnrollmentInvites(defaultProgramDetailsContextValue.enrollmentInvites)
    setEnrollmentsPagination(
      defaultProgramDetailsContextValue.enrollmentsPagination
    )
    setEnrollmentInvitesPagination(
      defaultProgramDetailsContextValue.enrollmentInvitesPagination
    )
    setEnrollments(defaultProgramDetailsContextValue.enrollments)
  }

  const value = {
    enrollmentFilters,
    enrollmentInviteFilters,
    enrollmentInvites,
    enrollmentInvitesPagination,
    enrollments,
    enrollmentsPagination,
    hasEnrollmentInvitesWithInProgressStatus,
    isSearchingOrFilteringEnrollmentInvites,
    searchEnrollmentInvites,
    searchEnrollments,

    /** functions */
    fetchEnrollmentInvites,
    fetchEnrollments,
    handleEnrollmentsPageChange,
    handleEnrollmentsPageSizeChange,
    updateEnrollmentFilters: setEnrollmentFilters,
    updateEnrollmentInviteFilters: setEnrollmentInviteFilters,
    updateSearchEnrollmentInvites: setSearchEnrollmentInvites,
    updateSearchEnrollments: setSearchEnrollments,
    handleEnrollmentInvitesPagination,
    resetContextToDefaults,
    ...testConfig,
  }

  return (
    <ProgramDetailsContext.Provider value={value}>
      {children}
    </ProgramDetailsContext.Provider>
  )
}

export default ProgramDetailsProvider
