import { Box, TextField, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import BasicModal from './BasicModal'
import { useTranslation } from 'react-i18next'

interface ExemptReasonModalProps {
  showExemptReasonModal: boolean
  onAccept: (exemptReason: string) => void
  handleConfirmationCancel: () => void
}

const MAX_CHAR_LIMIT_FOR_EXEMPT_REASON = 255

const ExemptReasonModal: React.FC<ExemptReasonModalProps> = ({
  showExemptReasonModal,
  onAccept,
  handleConfirmationCancel,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [exemptReason, setExemptReason] = useState('')

  const exemptReasonModalHeader = t(
    'ExemptReason.Modal.Header',
    'Exempt Reason'
  )

  const handleSubmit = () => {
    onAccept(exemptReason)
    setExemptReason('')
  }

  const handleCancel = () => {
    handleConfirmationCancel()
    setExemptReason('')
  }

  const exemptReasonModalBody = (
    <>
      <Typography variant="body1" component="h2" my={theme.spacing(2)}>
        {t(
          'ExemptReason.Modal.Body',
          'Please provide a reason for exemption from licensing'
        )}
      </Typography>
      <TextField
        id="exemptReason"
        name="exemptReason"
        variant="filled"
        fullWidth
        multiline
        margin="normal"
        rows={5}
        inputProps={{ maxLength: MAX_CHAR_LIMIT_FOR_EXEMPT_REASON }}
        helperText={t('ExemptReason.Comment.HelperText', '255 character max')}
        value={exemptReason}
        onChange={(event) => setExemptReason(event.target.value)}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <WarningRoundedIcon
          color="error"
          sx={{ marginRight: theme.spacing(1) }}
        />
        <Typography
          variant="body1"
          component="h2"
          mt={theme.spacing(2)}
          fontWeight="bold"
        >
          {t(
            'ExemptReason.Modal.Warning',
            "Exemption and reason will not save until clicking 'Save' on next screen"
          )}
        </Typography>
      </Box>
    </>
  )

  const ExemptReasonModalActionButtons = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.AddReason}
      disablePrimaryButton={!(exemptReason.length >= 3)}
      secondaryButtonLabel={TextButtonVariant.NoCancel}
      secondaryClick={handleCancel}
    />
  )

  const exemptReasonModalProps = {
    isOpen: showExemptReasonModal,
    dialogTitle: exemptReasonModalHeader,
    dialogContent: exemptReasonModalBody,
    dialogActions: ExemptReasonModalActionButtons,
    handleFormSubmit: handleSubmit,
  }

  return <BasicModal {...exemptReasonModalProps} maxWidth="xs" />
}

export default ExemptReasonModal
