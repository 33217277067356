import React, { useEffect, useState } from 'react'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { contentApi, extractedErrorObject } from '../../api/swagger'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'
import { Typography, useTheme } from '@mui/material'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import CustomTooltip from '../Elements/CustomTooltip'

interface FavoriteButtonProps {
  isFavorited: boolean
  assetKey: number
  handleFavoriteButtonClicked?: ({
    isFavorite,
  }: {
    isFavorite: boolean
  }) => void
  disabled?: boolean
  tooltipOptions?: {
    showTooltip: boolean
    title: string
  }
}

const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  isFavorited,
  assetKey,
  handleFavoriteButtonClicked,
  disabled = false,
  tooltipOptions,
}) => {
  const { t } = useTranslation()
  const [isFavorite, setIsFavorite] = useState(isFavorited)
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const theme = useTheme()

  useEffect(() => {
    setIsFavorite(isFavorited)
  }, [isFavorited, assetKey])

  // API call to update "isFavorited"
  const handleSetFavorite = async () => {
    const isFavoritedToggled = !isFavorite
    try {
      await contentApi.favoritePath({
        body: {
          assetKey,
          isFavorited: isFavoritedToggled,
        },
      })
      if (handleFavoriteButtonClicked) {
        handleFavoriteButtonClicked({ isFavorite: isFavoritedToggled })
      }
      setIsFavorite(isFavoritedToggled)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'FavoriteButton.FavoritePath.ErrorMessage',
            'An unknown error occurred setting favorite.'
          ),
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }
  const iconButton = (
    <IconButton
      aria-label="add to favorites"
      sx={{
        color: isFavorite
          ? theme.palette.primary.main
          : theme.palette.primary.dark,
      }}
      onClick={handleSetFavorite}
      disabled={disabled}
    >
      {isFavorite ? (
        <FavoriteIcon data-testid="favorite-icon" />
      ) : (
        <FavoriteBorderIcon data-testid="favorite-border-icon" />
      )}
    </IconButton>
  )

  return tooltipOptions?.showTooltip ? (
    <CustomTooltip
      variant="default"
      title={<Typography color="inherit">{tooltipOptions.title}</Typography>}
    >
      {iconButton}
    </CustomTooltip>
  ) : (
    iconButton
  )
}

export default FavoriteButton
