import React, { useContext } from 'react'
import TranscriptsCard from '../Card/TranscriptsCard'
import { Page } from '../Elements/PageMargins'
import { useTranslation } from 'react-i18next'
import TitleContext from '../../TitleContext'
import { CanAccess } from '../Elements/Access'
import { useAuth } from '../Routes/AuthProvider'

export const Transcripts: React.FC = () => {
  const { t } = useTranslation()
  const { userDetails } = useAuth()

  const title = t('Transcripts.Title', 'Transcripts')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  return (
    <Page>
      {userDetails.actingAs === 'parent' ? (
        <TranscriptsCard />
      ) : (
        <CanAccess I="print" on="Transcript">
          <TranscriptsCard />
        </CanAccess>
      )}
    </Page>
  )
}

export default Transcripts
