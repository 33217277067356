import { Theme, Typography } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'
import { Property } from 'csstype'
import React from 'react'

interface FormatYearsInTextProps {
  color?:
    | ResponsiveStyleValue<string[] | Property.Color | undefined>
    | ((
        theme: Theme
      ) => ResponsiveStyleValue<string[] | Property.Color | undefined>)
  text: string
}

/**
 * Splits the input text into parts and applies specific formatting to any year found in parentheses.
 *
 * @param text - The input text containing years in parentheses, such as (2024), (1999), (2025), etc.
 * @param color - (Optional) The color to apply to the years in parentheses. Can be a string, array of
 *  strings, or a function that returns a responsive style value based on the theme.
 * @returns An array of React nodes with formatted years.
 */
const formatYearsInText = ({
  color,
  text,
}: FormatYearsInTextProps): React.ReactNode[] => {
  // Split the input text into parts using a regular expression that matches years in parentheses.
  const parts = text.split(/(\(\d{4}\))/g)
  const result: React.ReactNode[] = []

  // Iterate over each part of the split text.
  parts.forEach((part, index) => {
    // If the part matches a year in parentheses, apply specific formatting.
    if (/^\(\d{4}\)$/.test(part)) {
      // Add the formatted year to the result array using a Typography component with specific styles.
      result.push(
        <Typography
          fontWeight="bold"
          color={color}
          variant="body1"
          component="span"
          key={index}
        >
          {part}
        </Typography>
      )
    } else {
      // If the part does not match a year, add it as is to the result array.
      result.push(part)
    }
  })

  // Return the array of React nodes.
  return result
}

export default formatYearsInText
