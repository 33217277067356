import React, { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

/**
 * See FamilyProfile.tsx for an example use case.
 */

interface PartialPageLoadingSpinnerProps extends PropsWithChildren {
  /**
   * Determines whether to show the loading spinner or content
   */
  isLoading: boolean
  /**
   * The vertical height (in px) of the loading container.
   * @default 200
   */
  height?: number
}

/**
 * A utility component that easily switches between a loading spinner and your content.
 * Useful when you have multiple sections on a page that rely on different async requests.
 */
const PartialPageLoadingSpinner: React.FC<PartialPageLoadingSpinnerProps> = ({
  isLoading,
  height = 200,
  children,
}) => {
  if (isLoading) {
    return (
      <Box
        maxHeight={height}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    )
  }
  return <>{children}</>
}

export default PartialPageLoadingSpinner
