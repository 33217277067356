import React from 'react'
import { informationRowTextWidth } from './TranscriptPDFStyles'
import { informationOffset } from './TranscriptPDFStyles'
import { transcriptPdfStyles } from './TranscriptPDFStyles'
import { Address } from '../../../swagger/models'
import studentKeyToStudentIdForTranscript from '../../../utils/studentKeyToStudentIdForTranscript'
import { dateToSlashStringReinterpretedAsLocal } from '../../../utils/dateUtility'

interface StudentInformationPDFProps {
  studentFirstName?: string
  studentLastName?: string
  studentAddress?: Address
  studentKey?: number
  studentDOB?: Date
  studentEmail?: string
}

const StudentInformationPDF: React.FC<StudentInformationPDFProps> = ({
  studentFirstName,
  studentLastName,
  studentAddress,
  studentKey,
  studentDOB,
  studentEmail,
}) => {
  return (
    <div style={transcriptPdfStyles.box}>
      <div>Student Information</div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>Name:</div>
        <div
          style={{
            paddingLeft: `${46 + informationOffset}px`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {`${studentFirstName} ${studentLastName ?? ''}`}
        </div>
      </div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>Student Id:</div>
        <div
          style={{
            paddingLeft: `${27 + informationOffset}px`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {`${studentKeyToStudentIdForTranscript(studentKey ?? 0)}`}
        </div>
      </div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>Address:</div>
        <div
          style={{
            paddingLeft: `${36 + informationOffset}px`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {studentAddress?.streetAddress1}
        </div>
      </div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>City, State, Zip:</div>
        <div
          style={{
            paddingLeft: `${9 + informationOffset}px`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {studentAddress?.city ?? ''}
          {', '}
          {studentAddress?.state ?? ''}
          {', '}
          {studentAddress?.zip ?? ''}
        </div>
      </div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>Date of Birth:</div>
        <div
          style={{
            paddingLeft: `${17 + informationOffset}px`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {studentDOB ? dateToSlashStringReinterpretedAsLocal(studentDOB) : ''}
        </div>
      </div>
      <div style={transcriptPdfStyles.informationRow}>
        <div>Email:</div>
        <div
          style={{
            paddingLeft: `${48 + informationOffset}`,
            maxWidth: informationRowTextWidth,
          }}
        >
          {studentEmail}
        </div>
      </div>
    </div>
  )
}

export default StudentInformationPDF
