// We need this to convert dd/mm/yyyy into yyyy-mm-dd
export const todaysDateFormatted = (): string => {
  return new Date().toISOString().slice(0, 10)
}

/**
 * Formats todays date like an ISO string, but accounts for the local
 * timezone offset. Since toISOString() converts to UTC, the date can change
 * around midnight depending on your local timezone's GMT offset.
 *
 * @returns {String} date string in yyyy-mm-dd format
 */
export const todaysDateFormattedLocal = (): string => {
  const today = new Date()
  const dd = ('0' + today.getDate()).slice(-2)
  const mm = ('0' + (today.getMonth() + 1)).slice(-2)
  const yyyy = today.getFullYear().toString()

  return `${yyyy}-${mm}-${dd}`
}
