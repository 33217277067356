import Box from '@mui/material/Box'
import CardMedia from '@mui/material/CardMedia'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TitleContext from '../../TitleContext'
import { Page } from '../Elements/PageMargins'

const TOULink =
  'https://classicalconversations.widen.net/s/zwzrm2hp5q/cc-inc-content-terms-of-use-policy'

const TermsOfUse: React.FunctionComponent = () => {
  const { t } = useTranslation()

  const title = t('TermsOfUse.Header', 'Terms of Use')

  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)

  return (
    <Page>
      <Box height={'90vh'} display="flex" flex="1">
        <CardMedia
          component="iframe"
          src={TOULink}
          allowFullScreen={true}
          title={'Terms of Use'}
        />
      </Box>
    </Page>
  )
}

export default TermsOfUse
