import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TitleContext from '../../../TitleContext'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'
import { useLocation, useNavigate } from 'react-router'
import {
  EnrollmentDiscount,
  EnrollmentFee,
  EnrollmentFeeStatusEnum,
  FetchBillingResponse,
  LicensingBill,
  PaymentConfiguration,
  PaymentConfigurationCodeEnum,
  Role,
  UserAccountDetails,
  UserAccountListing,
} from '../../../swagger'
import UserAccountCard from '../../Card/UserAccountCard'
import { fullName } from '../../../utils/fullName'
import {
  extractedErrorObject,
  meta,
  paymentsApi,
  userAccountsApi,
} from '../../../api/swagger'
import EmptyUserAccount from './EmptyUserAccount'
import RolesTable from '../Roles/RolesTable'
import { Page } from '../../Elements/PageMargins'
import { Payment, PaymentMade } from '../../Interfaces/Payment'
import Card from '@mui/material/Card'
import BillingHistorySummaryTable, {
  BillingHistorySummaryTableVariant,
} from '../../Account/Billing/BillingHistorySummaryTable'
import { useAuth } from '../../Routes/AuthProvider'
import { Can } from '@casl/react'
import ProgramOption from '../../Interfaces/ProgramOption'
import getLocaleCurrencyForAmount from '../../../utils/getLocaleCurrencyForAmount'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import { dateToSlashStringReinterpretedAsLocal } from '../../../utils/dateUtility'
import LicensingPaymentsCard from '../../Account/Billing/LicensingPaymentsCard'
import { styled } from '@mui/system'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { LoadingContext } from '../../Context/LoadingContext'
import useLoadingContext from '../../../hooks/useLoadingContext'

const TransactionCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    // Don't let the Enrollment Payment Transactions table get overlaid by buttons on mobile
    marginBottom: theme.spacing(12),
  },
}))

export interface IlicensingProgramOptionsWithOwedValue {
  name: string
  programKey: number
  studentKey: number | undefined
}
interface UserAccountProps {
  userId: string
  roleOptions?: Role[]
}

export const UserAccount: React.FunctionComponent<UserAccountProps> = (
  props
) => {
  const { permissionAbility } = useAuth()
  const { t, i18n } = useTranslation()
  const title = t('UserAccount.User.Title', 'User Accounts')
  const navigate = useNavigate()
  // TODO: Validate if useLocation correctly replaces our history.location.pathname check
  const location = useLocation()
  const { userId } = props
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const auth = useAuth()

  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds } = React.useContext(LoadingContext)

  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)

  const [userAccount, setUserAccount] = useState<UserAccountDetails>()
  const { roles } = userAccount ?? { roles: [] }

  const errorMessage = t('UserAccount.Error', 'Something went wrong.')

  const [isLoading, setIsLoading] = useState(true)

  const [userAccountsForRoleModal, setUserAccountsForRoleModal] = useState<
    UserAccountListing[]
  >([])

  const [roleOptions, setRoleOptions] = useState<Role[]>([])

  useEffect(() => {
    addLoadingIds([
      availableLoadingIds.UserAccounts.fetchUser,
      availableLoadingIds.Meta.fetchRoles,
    ])
    /** It's not efficient but it fixes the thing. Ideally I'd rather combine the first loadingIds array */
    if (auth.permissionAbility.can('editRoleAssignment', 'User')) {
      addLoadingIds([availableLoadingIds.UserAccounts.fetchUsers])
    }
  }, [
    auth.permissionAbility,
    location.pathname,
    availableLoadingIds.UserAccounts.fetchUser,
    availableLoadingIds.UserAccounts.fetchUsers,
    availableLoadingIds.Meta.fetchRoles,
    addLoadingIds,
  ])

  const fetchUser = async () => {
    try {
      const fetchedUserAccount = await userAccountsApi.fetchUser({
        userKey: parseInt(userId),
      })
      setUserAccount(fetchedUserAccount)
      setNameForBreadCrumb(
        fullName({
          firstName: fetchedUserAccount?.firstName,
          lastName: fetchedUserAccount?.lastName,
        })
      )
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    } finally {
      setIsLoading(false)
    }
  }

  useLoadingContext({
    asyncFunction: fetchUser,
    loadingId: availableLoadingIds.UserAccounts.fetchUser,
  })

  /**
   *  TODO: Remove the call to fetchUserAccountsForRoleModal when handling pagination in RoleModal:
   *  https://classicalconversations.my.workfront.com/task/62f17918000f7cb31d4a6f4f8686f356/overview
   */

  const fetchUserAccountsForRoleModal = async () => {
    try {
      const userAccountsForRoleModal = await userAccountsApi.fetchUsers({})
      setUserAccountsForRoleModal(userAccountsForRoleModal.userAccounts)
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: fetchUserAccountsForRoleModal,
    loadingId: availableLoadingIds.UserAccounts.fetchUsers,
  })

  const failedFetchingRolesMessage = t(
    'UserAccounts.Error.FetchRoles',
    'An unknown error occurred fetching roles.'
  )

  const fetchRoles = async () => {
    const abortController = new AbortController()
    try {
      const _roles = await meta.fetchRoles({
        $signal: abortController.signal,
      })
      if (!abortController.signal.aborted) {
        setRoleOptions(
          _roles.sort((a, b) =>
            new Intl.Collator(i18n.language).compare(a.name, b.name)
          )
        )
      }
    } catch (err) {
      const errorObj = (await extractedErrorObject(err)) ?? {
        code: 'Unknown',
        message:
          (err as unknown as Error).message ?? failedFetchingRolesMessage,
      }
      setSnackbarState(true)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObj.message)
    } finally {
      abortController.abort()
    }
  }

  useLoadingContext({
    asyncFunction: fetchRoles,
    loadingId: availableLoadingIds.Meta.fetchRoles,
  })

  const generateKeyForFeeMaps = (program: number, studentKey?: number) => {
    if (!!studentKey) {
      return `${studentKey}-${program}`
    }
    return `${program}`
  }

  const generateKeyForEnrollmentProgramOptionsMap = (
    program: number,
    studentKey: number
  ): string => {
    return `${program}-${studentKey}`
  }

  const [billingResponse, setBillingResponse] = useState(
    {} as FetchBillingResponse
  )

  const [licensingBills, setLicensingBills] = useState<LicensingBill[]>([])
  const [configurations, setConfigurations] = useState<PaymentConfiguration[]>(
    []
  )
  const [achAllowed, setAchAllowed] = useState(true)

  // Fetch Billing
  useEffect(() => {
    if (permissionAbility.can('viewBilling', 'Payment')) {
      addLoadingIds([availableLoadingIds.UserAccounts.fetchBilling])
    }
  }, [
    userId,
    addLoadingIds,
    availableLoadingIds.UserAccounts.fetchBilling,
    permissionAbility,
  ])

  const fetchBilling = async () => {
    const fetchedBilling = await paymentsApi.fetchBilling({
      userKey: parseInt(userId),
    })
    const {
      licensing,
      configurations: fetchedConfigs,
      achAllowed,
    } = fetchedBilling
    setConfigurations(fetchedConfigs)
    setAchAllowed(achAllowed)

    if (!!licensing && !!licensing.programs) {
      setLicensingBills(licensing.programs)
    }
    setBillingResponse(fetchedBilling)
  }

  useLoadingContext({
    asyncFunction: fetchBilling,
    loadingId: availableLoadingIds.UserAccounts.fetchBilling,
  })

  const achDiscount =
    configurations.find(
      (configuration) =>
        configuration.code === PaymentConfigurationCodeEnum.AchDiscount
    )?.amount ?? 0

  const [licensingProgramOptions, setLicensingProgramOptions] =
    useState<ProgramOption[]>()

  const [enrollmentProgramOptions, setEnrollmentProgramOptions] =
    useState<ProgramOption[]>()

  // A user must be able to edit licensing and enrollment payments and view programs and payments before being allowed to fetchPaymentOptions
  const hasPermissionToFetchPaymentOptions =
    auth.permissionAbility.can('updateEnrollmentPayment', 'Payment') &&
    auth.permissionAbility.can('updateLicensingPayment', 'Payment') &&
    auth.permissionAbility.can('createEnrollmentPayment', 'Payment') &&
    auth.permissionAbility.can('createLicensingPayment', 'Payment') &&
    auth.permissionAbility.can('view', 'Program') &&
    auth.permissionAbility.can('view', 'Payment')

  useEffect(() => {
    if (
      !licensingProgramOptions &&
      !enrollmentProgramOptions &&
      hasPermissionToFetchPaymentOptions
    ) {
      addLoadingIds([availableLoadingIds.UserAccounts.fetchPaymentOptions])
    }
  }, [
    licensingProgramOptions,
    enrollmentProgramOptions,
    hasPermissionToFetchPaymentOptions,
    addLoadingIds,
    availableLoadingIds.UserAccounts.fetchPaymentOptions,
  ])

  const fetchPaymentOptions = async () => {
    try {
      const { programs, enrollments } = await paymentsApi.fetchPaymentOptions({
        userKey: parseInt(userId),
      })
      setLicensingProgramOptions(
        programs.map((program) => {
          const name = `${
            program.programType
          } (${dateToSlashStringReinterpretedAsLocal(
            program.semesterOneStartDate
          )}) - ${program.communityName}`
          const programOption: ProgramOption = {
            name,
            programKey: program.programKey,
          }
          return programOption
        })
      )
      const enrollmentMap = new Map<
        string /** `programKey-studentKey`*/,
        string /** programType, studentName, semesterOneStartDate */
      >()
      setEnrollmentProgramOptions(
        enrollments.map((enrollment) => {
          const name = `${enrollment.studentFirstName} - ${
            enrollment.programType
          } (${dateToSlashStringReinterpretedAsLocal(
            enrollment.semesterOneStartDate
          )})`
          const programOption: ProgramOption = {
            name,
            programKey: enrollment.programKey,
            studentKey: enrollment.studentKey,
          }
          const key = generateKeyForEnrollmentProgramOptionsMap(
            enrollment.programKey,
            enrollment.studentKey
          )
          enrollmentMap.set(key, name)
          return programOption
        })
      )
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: fetchPaymentOptions,
    loadingId: availableLoadingIds.UserAccounts.fetchPaymentOptions,
  })

  const enrollmentPaymentHistory =
    billingResponse.enrollment?.history?.map((it) => {
      const paymentHistory: Payment = {
        ...it,
        paymentsMade: it.enrollmentPayments.map((ep) => {
          return {
            paymentKey: it.paymentKey,
            studentKey: ep.studentKey,
            programKey: ep.programKey,
          } as PaymentMade
        }),
      }
      return paymentHistory
    }) ?? []

  const enrollmentTotalDue = billingResponse.enrollment?.owed?.total.amount ?? 0

  const licensingPaymentHistory =
    billingResponse.licensing?.history?.map((it) => {
      const paymentHistory: Payment = {
        ...it,
        paymentsMade: [
          {
            ...it.licensingPayment,
            paymentKey: it.licensingPayment.licensingPaymentKey,
            studentKey: -1,
          },
        ],
      }
      return paymentHistory
    }) ?? []

  const licensingTotalDue =
    billingResponse.licensing?.programs
      ?.map((it) => it.total)
      .map((it) => it.amount)
      .reduce((a, b) => a + b, 0) ?? 0

  const enrollmentFeeMap = new Map<string, EnrollmentFee>()
  const licensingFeeMap = new Map<string, LicensingBill>()
  const discountMap = new Map<string, EnrollmentDiscount>()

  billingResponse.enrollment?.owed?.fees.forEach((fee) => {
    if (!!fee.total) {
      enrollmentFeeMap.set(
        generateKeyForFeeMaps(fee.programKey, fee.studentKey),
        fee
      )
    }
  })

  billingResponse.licensing?.programs?.forEach((item) => {
    if (!!item.fees) {
      licensingFeeMap.set(generateKeyForFeeMaps(item._for.programKey), item)
    }
  })

  billingResponse.enrollment?.owed?.discounts?.forEach((discount) => {
    discountMap.set(
      generateKeyForFeeMaps(discount.programKey ?? 0, discount.studentKey ?? 0),
      discount
    )
  })

  const enrollmentProgramOptionsWithOwedValue = enrollmentProgramOptions?.map(
    (enrollment) => {
      const key = generateKeyForFeeMaps(
        enrollment.programKey,
        enrollment.studentKey
      )
      const total = enrollmentFeeMap.get(key)
      let cashAmount = 'N/A'

      const discount = discountMap.get(key)

      const totalWithDiscount =
        (total?.total.amount ?? 0) - (discount?.total.amount ?? 0)

      if (total) {
        cashAmount =
          total.status === EnrollmentFeeStatusEnum.Unpaid
            ? getLocaleCurrencyForAmount(
                totalWithDiscount,
                total?.total.currencyCode
              )
            : EnrollmentFeeStatusEnum.Paid
      }

      return {
        name: `${enrollment.name} ${cashAmount}`,
        programKey: enrollment.programKey,
        studentKey: enrollment.studentKey,
      }
    }
  )

  const licensingProgramOptionsWithOwedValue:
    | IlicensingProgramOptionsWithOwedValue[]
    | undefined = licensingProgramOptions?.map((licensing) => {
    const key = generateKeyForFeeMaps(licensing.programKey)
    const total = licensingFeeMap.get(key)
    const fees = total?.total
    let cashAmount = 'N/A'

    if (!!fees) {
      cashAmount = getLocaleCurrencyForAmount(fees.amount, fees.currencyCode)
    }
    return {
      name: `${licensing.name} ${cashAmount}`,
      programKey: licensing.programKey,
      studentKey: licensing.studentKey,
    }
  })

  const [nameForBreadcrumb, setNameForBreadCrumb] = useState('')

  const updateName = (fullName: string) => {
    setNameForBreadCrumb(fullName)
  }

  const navigateToUserAccounts = () => {
    navigate(
      {
        pathname: '/admin/user-accounts',
      },
      {
        /** Navigation Options */
      }
    )
  }

  if (!userAccount) {
    return <EmptyUserAccount isLoading={isLoading} />
  }

  return (
    <Page withinTab>
      {!!userAccount && (
        <>
          <DynamicBreadcrumbs
            breadcrumbs={[
              {
                label: title,
                onClick: navigateToUserAccounts,
              },
              {
                label: nameForBreadcrumb,
              },
            ]}
          />
          <UserAccountCard
            initialUserAccountDetails={userAccount}
            updateNameForParent={updateName}
            {...props}
          />
          <RolesTable
            roles={roles}
            userKey={parseInt(userId)}
            userAccount={userAccount}
            removeRole={fetchUser}
            triggerRefresh={fetchUser}
            userAccounts={userAccountsForRoleModal}
            {...props}
            roleOptions={props.roleOptions ?? roleOptions}
          />
          <Can I={'viewBilling'} on="Payment" ability={permissionAbility}>
            <TransactionCard>
              <BillingHistorySummaryTable
                enrollmentProgramOptions={enrollmentProgramOptionsWithOwedValue}
                variant={
                  BillingHistorySummaryTableVariant.EnrollmentPaymentsCSR
                }
                ariaLabelledBy="enrollmentPaymentTransactionsTableHeader"
                paymentHistory={enrollmentPaymentHistory}
                amountDue={enrollmentTotalDue}
                userKey={parseInt(userId)}
                triggerRefresh={() =>
                  addLoadingIds([availableLoadingIds.UserAccounts.fetchBilling])
                }
                hasPermissionToFetchPaymentOptions={
                  hasPermissionToFetchPaymentOptions
                }
                {...props}
              />
            </TransactionCard>
          </Can>
          <Can I={'viewBilling'} on="Payment" ability={permissionAbility}>
            <TransactionCard>
              <LicensingPaymentsCard
                licensingProgramOptions={licensingProgramOptionsWithOwedValue}
                licensingPaymentHistory={licensingPaymentHistory}
                licensingBills={licensingBills}
                achDiscount={achDiscount}
                amountDue={licensingTotalDue}
                refetch={() =>
                  addLoadingIds([availableLoadingIds.UserAccounts.fetchBilling])
                }
                userKey={parseInt(userId)}
                hasPermissionToFetchPaymentOptions={
                  hasPermissionToFetchPaymentOptions
                }
                variantForBillingHistorySummaryTable={
                  BillingHistorySummaryTableVariant.LicensingPaymentsCSR
                }
                achAllowed={achAllowed}
                {...props}
              />
            </TransactionCard>
          </Can>
        </>
      )}
    </Page>
  )
}

export default UserAccount
