import { Typography, useTheme, Box } from '@mui/material'
import React, { useState } from 'react'
import { Enrollment } from '../../swagger'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import BasicModal from './BasicModal'
import { useTranslation } from 'react-i18next'
import DebouncedAutocomplete from '../Elements/DebouncedAutocomplete'

interface TransferEnrollmentsModalProps {
  showTransferEnrollmentModal: boolean
  handleEnrollmentTransfer: (
    e: React.FormEvent<HTMLDivElement>
  ) => Promise<void>
  transferEligibleEnrollments: Enrollment[]
  handleTransferEnrollmentSelection: (enrollment: Enrollment) => void
  handleTransferConfirmationCancel: () => void
  updateSearchResults: (s: string) => void
  updateSearchResultsLoadingId: string
  resetTransferEligibleEnrollments: () => void
}

const TransferEnrollmentsModal: React.FC<TransferEnrollmentsModalProps> = ({
  showTransferEnrollmentModal,
  handleEnrollmentTransfer,
  transferEligibleEnrollments,
  handleTransferEnrollmentSelection,
  handleTransferConfirmationCancel,
  updateSearchResults,
  resetTransferEligibleEnrollments,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const TransferEnrollmentModalHeader = t(
    'Families.EnrollmentTable.TransferEnrollmentModal.Header',
    'Available enrollments'
  )

  const [selectedEnrollmentOption, setSelectedEnrollmentOption] =
    useState<Enrollment>()

  const getOptionLabelForEnrollment = (enrollment: Enrollment) => {
    return `${enrollment.studentName} ${enrollment.familyName} - ${enrollment.communityName} - ${enrollment.programType}`
  }

  const getKeyForRenderOptionsForEnrollment = (
    enrollment: Enrollment
  ): string => {
    return `${enrollment.studentKey}-${enrollment.programKey}-${enrollment.studentName}-${enrollment.familyName}-${enrollment.communityName}-${enrollment.programType}-${enrollment.inviteDate}`
  }

  const getRenderOptionsForEnrollment = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Enrollment
  ) => (
    <Box
      component="li"
      {...props}
      key={getKeyForRenderOptionsForEnrollment(option)}
    >
      {getOptionLabelForEnrollment(option)}
    </Box>
  )

  const handleChangeAutocomplete = (
    selection: Enrollment | null,
    reason: string
  ) => {
    if (!selection) {
      if (reason === 'clear') {
        resetTransferEligibleEnrollments()
      }
      return
    }

    setSelectedEnrollmentOption(selection)
    handleTransferEnrollmentSelection(selection)
  }

  const TransferEnrollmentModalBody = (
    <>
      <DebouncedAutocomplete
        id={'available-enrollment'}
        options={transferEligibleEnrollments}
        handleChange={handleChangeAutocomplete}
        noOptionsTextValue={t(
          'TransferEnrollmentModal.FamilySearch.NoResults',
          'No Family with that name found'
        )}
        optionsLabel={getOptionLabelForEnrollment}
        renderOptions={getRenderOptionsForEnrollment}
        updateSearchResults={updateSearchResults}
        textFieldLabelValue={t(
          'TransferEnrollmentModal.FamilySearch.Label',
          'Search Families'
        )}
        textFieldStyle={{}}
      />

      <Typography variant="body1" component="h2" marginTop={theme.spacing(2)}>
        {t(
          'Families.EnrollmentTable.TransferEnrollment.TransferEnrollmentModal.Body',
          'You are about to transfer a student to this program, do you want to proceed?'
        )}
      </Typography>
    </>
  )

  const isTransferEnrollmentModalPrimaryBtnDisabled = (): boolean => {
    return !selectedEnrollmentOption
  }

  const TransferEnrollmentModalActionButtons = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.YesTransfer}
      secondaryButtonLabel={TextButtonVariant.NoCancel}
      secondaryClick={handleTransferConfirmationCancel}
      disablePrimaryButton={isTransferEnrollmentModalPrimaryBtnDisabled()}
    />
  )

  const TransferEnrollmentModalProps = {
    isOpen: showTransferEnrollmentModal,
    dialogTitle: TransferEnrollmentModalHeader,
    dialogContent: TransferEnrollmentModalBody,
    dialogActions: TransferEnrollmentModalActionButtons,
    handleFormSubmit: handleEnrollmentTransfer,
  }

  return <BasicModal {...TransferEnrollmentModalProps} maxWidth="xs" />
}

export default TransferEnrollmentsModal
