import React, { useState } from 'react'
export interface UseCalculate {
  result: string
  inputValues: {
    [key: string]: string
  }
  changeValueForCalculate: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  resetCalculatedAmount?: () => void
  updateResult?: (value: string) => void
  updateInputValues?: (value: { [key: string]: string }) => void
}

const isValidDecimalInput = (input: string): boolean => {
  // We consider an empty input as valid (allows clearing the input)
  if (!input) return true

  const regexp = /^\d+(\.\d{0,2})?$/
  return regexp.test(input)
}

/**
 * Handles the change in input values.
 *
 * Validates the input, updates the input values and recalculates the result.
 *
 * Input change management
 *
 * 1- add the function changeValueForCalculate to the onChange function
 * (this allows us to control the input changes).
 *
 * 2- add inputValues to the value property of the input (keep in mind
 * that with the name of the input you will access to the value)
 *
 * Shows the value of the sum obtained
 *
 * use the `result` property in the field in which you want to display the result
 *
 * @example
 * ```tsx
 * import React from 'react';
 * import { useCalculateTotalAmount } from './useCalculateTotalAmount';
 *
 * // Example component
 * const SumInputs = () => {
 * const { inputValues, changeValueForCalculate, result } =
 *  useCalculateTotalAmount()
 *   return (
 *     <div>
 *       <input
 *         name="input1"
 *         value={inputValues['input1'] ?? ''}
 *         onChange={changeValueForCalculate}
 *       />
 *       <input
 *         name="input2"
 *         value={inputValues['input2'] ?? ''}
 *         onChange={changeValueForCalculate}
 *       />
 *       <input
 *         name="result"
 *         value={result}
 *       />
 *     </div>
 *   );
 * };
 *
 * export default SumInputs;
 * ```
 */
export const useCalculateTotalAmount = (): UseCalculate => {
  const [result, setResult] = useState('')
  const [inputValues, setInputValues] = useState<{
    [key: string]: string
  }>({})

  const changeValueForCalculate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target
    // prevents the entry of unsupported values such as letters or spacing
    if (isValidDecimalInput(value)) {
      setInputValues((prevInputValue) => {
        const updatedInputValues = {
          ...prevInputValue,
          [name]: value,
        }
        // addition operations
        let newResult = 0
        for (const key in updatedInputValues) {
          const numericValue = parseFloat(updatedInputValues[key])
          if (!isNaN(numericValue)) {
            newResult += numericValue
          }
        }

        setResult(newResult === 0 ? '' : `${newResult}`)

        return updatedInputValues
      })
    }
  }

  const resetCalculatedAmount = () => {
    setResult('')
    setInputValues({})
  }

  const updateResult = (value: string) => {
    setResult(value)
  }

  const updateInputValues = (value: { [key: string]: string }) => {
    setInputValues(value)
  }

  return {
    result,
    inputValues,
    changeValueForCalculate,
    resetCalculatedAmount,
    updateResult,
    updateInputValues,
  }
}
