import React, { useEffect, useState } from 'react'
import { useBusinessContext } from './BusinessContext'
import { useLearningCenterContext } from './LearningCenterContext'
import { useBlocker } from 'react-router'
import { useCommunitiesContext } from './CommunitiesContext'

/**
 * Used within App.tsx with every <Outlet/> so navigation can be blocked by conditions within useBlocker
 *
 * @returns ReactFragment
 */
const ContextResetter: React.FC = () => {
  /**
   * Use one instance of useBlocker, pass the values
   * to other hooks to block or unblock.
   *
   * Trust me when I say moving this useBlocker to its own hook or making
   * hooks out of the useEffect conditions below is against the rule of hooks
   * and will cause troubles in tests, especially, but may "work" when running the app.
   *
   * To add a resetter:
   *  - Add a state variable
   *  - Set the state variable in the useBlocker function given the current and next locations
   *  - within the useEffect add an ELSE IF, call the resetter, call block.proceed()
   */

  const [resetBusinessContext, setResetBusinessContext] = useState(false)

  const [resetLearningCenterContext, setResetLearningCenterContext] =
    useState(false)

  const [resetCommunitiesContext, setResetCommunitiesContext] = useState(false)

  const block = useBlocker(({ currentLocation, nextLocation }) => {
    const isComingFromLearning = /^\/learning/i.test(
      `${currentLocation.pathname}`
    )
    const isGoingToLearning = /^\/learning/i.test(`${nextLocation.pathname}`)

    const isComingFromBusiness = /^\/business/i.test(
      `${currentLocation.pathname}`
    )
    const isGoingToBusiness = /^\/business/i.test(`${nextLocation.pathname}`)

    const isComingFromCommunities = /^\/communities/i.test(
      `${currentLocation.pathname}`
    )
    const isGoingToCommunities = /^\/communities/i.test(
      `${nextLocation.pathname}`
    )

    setResetLearningCenterContext(isGoingToLearning && !isComingFromLearning)
    setResetBusinessContext(isGoingToBusiness && !isComingFromBusiness)
    setResetCommunitiesContext(isGoingToCommunities && !isComingFromCommunities)

    return (
      (isGoingToBusiness && !isComingFromBusiness) ||
      (isGoingToLearning && !isComingFromLearning) ||
      (isGoingToCommunities && !isComingFromCommunities)
    )
  })

  /** If neither reset will be called, unblock */

  const { resetContextToDefaults: resetBusinessContextToDefaults } =
    useBusinessContext()
  const { resetContextToDefaults: resetLearningCenterContextToDefaults } =
    useLearningCenterContext()
  const { resetContextToDefaults: resetCommunitiesContextToDefaults } =
    useCommunitiesContext()

  /**
   * Check every change to the blocker or state variables for resetting
   * and call the appropriate reset methods.
   *
   * IMPORTANT HOW TO: To add a method, you MUST add an "ELSE IF" with the
   * reset condition and call the method to reset, or other logic therein.
   * DO NOT FORGET to proceed the blockage using `block.proceed` or navigation
   * will be stuck.
   */
  useEffect(() => {
    if (!!block && block.state === 'blocked') {
      if (
        !resetBusinessContext &&
        !resetLearningCenterContext &&
        !resetCommunitiesContext
      ) {
        block.proceed()
      } else if (resetBusinessContext) {
        /**
         * Then reset the search and filter and unblock
         */
        resetBusinessContextToDefaults()
        block.proceed()
      } else if (resetLearningCenterContext) {
        /**
         * Then reset the search and filter and unblock
         */
        resetLearningCenterContextToDefaults()
        block.proceed()
      } else if (resetCommunitiesContext) {
        /**
         * Then reset the search and filter and unblock
         */
        resetCommunitiesContextToDefaults()
        block.proceed()
      }
    }
  }, [
    block,
    resetBusinessContext,
    resetBusinessContextToDefaults,
    resetLearningCenterContext,
    resetLearningCenterContextToDefaults,
    resetCommunitiesContext,
    resetCommunitiesContextToDefaults,
  ])

  return <></>
}

export default ContextResetter
