/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface TuitionPayments1
 */
export interface TuitionPayments1 {
  /**
   * The unique identifier for the student the tuition payment if for
   * @type {number}
   * @memberof TuitionPayments1
   */
  studentKey: number
  /**
   * The unique identifier for the program the tuition payment if for
   * @type {number}
   * @memberof TuitionPayments1
   */
  programKey: number
  /**
   * The unique identifier for the tuition payment
   * @type {number}
   * @memberof TuitionPayments1
   */
  tuitionPaymentKey: number
}

export function TuitionPayments1FromJSON(json: any): TuitionPayments1 {
  return TuitionPayments1FromJSONTyped(json, false)
}

export function TuitionPayments1FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TuitionPayments1 {
  if (json === undefined || json === null) {
    return json
  }
  return {
    studentKey: json['studentKey'],
    programKey: json['programKey'],
    tuitionPaymentKey: json['tuitionPaymentKey'],
  }
}

export function TuitionPayments1ToJSON(value?: TuitionPayments1 | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    studentKey: value.studentKey,
    programKey: value.programKey,
    tuitionPaymentKey: value.tuitionPaymentKey,
  }
}
