import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  MaterialDesignContent,
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  closeSnackbar,
  useSnackbar,
} from 'notistack'
import { Router } from '@remix-run/router'
import { useMountEffect } from '../../hooks/useMountEffect'
import { Close } from '@mui/icons-material'
import { IconButton, useTheme } from '@mui/material'
import { styled } from '@mui/system'

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  () => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: '#EBF5F0',
      color: '#57ab82',
      whiteSpace: 'pre-wrap',
      width: '',
      fontFamily: 'sweet-sans-pro, sans-serif',
    },
    '&.notistack-MuiContent-error': {
      width: '',
      backgroundColor: '#f9eeec',
      color: '#C14F3C',
      whiteSpace: 'pre-wrap',
      fontFamily: 'sweet-sans-pro, sans-serif',
    },
  })
)

const defaultNotistackSnackbarKeyContextValue = {
  /** enqueueSnackbar from notistack returns a SnackbarKey, which we track so our provider closes it when the history listener is triggered */
  snackbarKeys: [] as SnackbarKey[],
  /** Provide a single SnackbarKey, or call enqueueSnackbar directly to add the key to the list to close */
  addSnackbarKey: (keyToAdd: SnackbarKey) => {
    console.warn(
      `The default value of NotistackSnackbarContext.addSnackbarKey was called with value: ${keyToAdd}. Did you forget to install a NotistackSnackbarProvider?`
    )
  },
  /** Wrapper around notistack's enqueueSnackbar to provide a custom dismiss action */
  enqueueSnackbar: (
    msg: SnackbarMessage,
    options?: OptionsObject
  ): SnackbarKey => {
    console.warn(
      `The default value of NotistackSnackbarContext.enqueueSnackbar was called with values: ${msg} and ${options}. Did you forget to install a NotistackSnackbarProvider?`
    )
    return ''
  },
}

export const NotistackSnackbarKeyContext = React.createContext(
  defaultNotistackSnackbarKeyContextValue
)
export const useNotistackSnackbarKeyContext =
  (): typeof defaultNotistackSnackbarKeyContextValue =>
    React.useContext(NotistackSnackbarKeyContext)

export type TestNotistackSnackbarKeyContextConfig = Partial<
  typeof defaultNotistackSnackbarKeyContextValue
>
interface NotistackSnackbarKeyContextProps extends PropsWithChildren {
  /** Used to subscribe to the navigation and handleClose each time we do navigate */
  router?: Router
  testConfig?: TestNotistackSnackbarKeyContextConfig
}

export const NotistackSnackbarKeyProvider: React.FC<
  NotistackSnackbarKeyContextProps
> = ({ router, testConfig, children }) => {
  const [snackbarKeys, setSnackbarKeys] = useState<SnackbarKey[]>([])
  const { enqueueSnackbar: enqueueSnackbarNotistack } = useSnackbar()

  const theme = useTheme()

  /**
   * Used in tandem with the return value of enqueueSnackbar from notistack.
   * @param keyToAdd SnackbarKey returned when adding a snackbar from enqueueSnackbar
   */
  const addSnackbarKey = (keyToAdd: SnackbarKey) => {
    setSnackbarKeys((currKeys) => [...currKeys, keyToAdd])
  }

  /** When snackbarKeys updates, update the subscriber and close the snackbars accordingly */
  const subscriber = useCallback(() => {
    router?.subscribe(() => {
      // Go through all keys we're tracking and close them
      for (const key of snackbarKeys) {
        /** Apparently we can dismiss by closeSnackbar() without a key. https://notistack.com/features/basic#dismiss-programmatically */
        closeSnackbar(key)
      }
      setSnackbarKeys([])
    })
  }, [router, snackbarKeys])

  useEffect(() => {
    /** Whenever we have keys */
    if (snackbarKeys.length > 0) {
      subscriber()
    }
  })

  /** When the location changes, do something https://github.com/remix-run/history/blob/dev/docs/api-reference.md#history.listen */
  useMountEffect(() => {
    router?.subscribe(() => {
      for (const key of snackbarKeys) {
        /** Apparently we can dismiss by closeSnackbar() without a key. https://notistack.com/features/basic#dismiss-programmatically */
        closeSnackbar(key)
      }
      if (snackbarKeys.length > 0) setSnackbarKeys([])
    })
  })

  const enqueueSnackbar = (
    msg: SnackbarMessage,
    options?: OptionsObject
  ): SnackbarKey => {
    return enqueueSnackbarNotistack(msg, {
      ...options,
      action: (snackbarId) => (
        <IconButton
          aria-label="close-notistack-snackbar"
          onClick={() => {
            closeSnackbar(snackbarId)
          }}
          sx={
            options?.variant === 'success'
              ? {
                  //Green.light
                  backgroundColor: theme.palette.secondary.light,
                  // green primary
                  color: theme.palette.secondary.main,
                  whiteSpace: 'pre-wrap',
                  width: '',
                  fontFamily: 'sweet-sans-pro, sans-serif',
                }
              : /** If it's not success, we only otherwise handle error */
                {
                  width: '',
                  // error lightest
                  backgroundColor: theme.palette.customBackground.error,
                  // error main
                  color: theme.palette.error.main,
                  whiteSpace: 'pre-wrap',
                  fontFamily: 'sweet-sans-pro, sans-serif',
                }
          }
        >
          <Close />
        </IconButton>
      ),
    })
  }

  return (
    <NotistackSnackbarKeyContext.Provider
      value={{
        snackbarKeys,
        addSnackbarKey,
        enqueueSnackbar,
        ...testConfig,
      }}
    >
      {children}
    </NotistackSnackbarKeyContext.Provider>
  )
}

export default NotistackSnackbarKeyProvider
