import { Box, Typography, useTheme } from '@mui/material'
import { PDFViewer } from '@react-pdf/renderer'
import TranscriptPDF from './TranscriptPDF/TranscriptPDF'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useFetchTranscriptInBatch } from '../../hooks/useFetchTranscriptInBatch'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import {
  TranscriptFlowStages,
  useTranscriptContext,
} from '../Context/TranscriptContext'
import { Page } from '../Elements/PageMargins'
import { useMountEffect } from '../../hooks/useMountEffect'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import { LoadingContext } from '../Context/LoadingContext'
import LoadingProgress from '../Elements/LoadingProgress'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { getCommaSeparatedList } from '../../utils/getCommaSeparatedList'
import studentKeyToStudentIdForTranscript from '../../utils/studentKeyToStudentIdForTranscript'
import { useAuth } from '../Routes/AuthProvider'
import { dateToSlashString } from '../../utils/dateUtility'
import { styled } from '@mui/system'
import { transcriptsApi } from '../../api/swagger'

import { useTranslation } from 'react-i18next'
import useEndpoint from '../../hooks/useEndpoint'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',

  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
  },
}))

export const csvHeaders =
  'Student First Name,Student Last Name,Student Id,Birth Date,Grad Year,Email Address'

export const PDFTranscriptsPreview: React.FC = () => {
  const { userDetails, permissionAbility } = useAuth()
  const canViewAdminButtons = permissionAbility.can('edit', 'Transcript')
  const { t } = useTranslation()
  const isShowOnDesktop = useShowOnDesktop()
  const { TranscriptFormCard } = useLoadingIds()

  const location = useLocation()
  const theme = useTheme()
  const { breadcrumbs, updateBreadcrumbs } = useTranscriptContext()
  const { loadingIds, addLoadingIds } = useContext(LoadingContext)
  const navigate = useNavigate()

  const transcriptKeys: number[] = location.state?.transcriptKeys
    ? location.state?.transcriptKeys
    : []

  const { transcripts, loadingId } = useFetchTranscriptInBatch({
    transcriptKeys,
  })

  const pageHeight = 'calc(90vh - 24px - 48px - 96px)'

  useMountEffect(() => {
    updateBreadcrumbs(TranscriptFlowStages.Print)
  })

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>()

  const markTranscriptsAsSentErrorMessage = t(
    'Transcripts.TranscriptFormCard.PdfPreview.markParchmentAsSent.DefaultError',
    'An error has occurred while marking transcripts as sent.'
  )

  useEndpoint({
    swaggerCall: () =>
      transcriptsApi.markParchmentAsSent({
        body: {
          transcriptKeys,
        },
      }),
    loadingId: TranscriptFormCard.markParchmentAsSent,
    successMessage: t(
      'Transcripts.TranscriptFormCard.PdfPreview.markParchmentAsSent.SuccessMessage',
      'Transcripts marked as sent.'
    ),

    genericErrorMessage: markTranscriptsAsSentErrorMessage,
    dontCall: () => !transcriptKeys,
  })

  useEffect(() => {
    if (!!transcripts) {
      const csvRows: string[] = [
        // Our initial CSV rows are just the headers
        csvHeaders,
        ...transcripts.map((transcript) => {
          return getCommaSeparatedList([
            `${transcript.student.firstName}`,
            `${transcript.student.lastName}`,
            studentKeyToStudentIdForTranscript(transcript.student.studentKey),
            transcript.studentDateOfBirth
              ? dateToSlashString(transcript.studentDateOfBirth)
              : '', // Birth Date
            `${transcript.dateGraduation?.getFullYear()}`, // Grad Year
            `${transcript.studentEmail}`, // Email Address
          ])
        }),
      ]

      const csvRowsWithBreaks = csvRows.join('\n')
      const blob = new Blob([csvRowsWithBreaks], {
        type: 'text/csv',
      })
      const url = URL.createObjectURL(blob)
      setDownloadUrl(url)
    }
  }, [transcripts])

  if (loadingIds.has(loadingId)) {
    return (
      <Page>
        <LoadingProgress />
      </Page>
    )
  }

  return (
    <Page>
      <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
      {/* Should we use more_vert? */}
      {canViewAdminButtons && (
        <ButtonsWrapper sx={{ display: 'flex', justifyContent: 'center' }}>
          <ContainedButton
            css={{
              width: isShowOnDesktop ? '35vw' : '100%',
            }}
            variant={ContainedButtonVariant.DownloadParchmentLearnerCsv}
            id="Download Parchment Learner CSV"
            // This just names the download, but we can provide something less generic
            download="download.csv"
            href={downloadUrl}
            disabled={!downloadUrl}
          />

          <ContainedButton
            css={{
              width: isShowOnDesktop ? '35vw' : '100%',
            }}
            variant={ContainedButtonVariant.MarkTranscriptsAsSent}
            id="Mark Transcripts as Sent"
            onClick={() =>
              addLoadingIds([TranscriptFormCard.markParchmentAsSent])
            }
            disabled={!transcriptKeys}
          />
        </ButtonsWrapper>
      )}
      <Box display="flex" justifyContent="center">
        {!!transcripts && transcripts.length !== 0 && downloadUrl ? (
          <PDFViewer
            style={{
              // Calc the 90% of the view height less the Dynamic Breadcrumbs Padding and the Page margins, and oc the button
              height: 'calc(90vh - 24px - 48px - 96px - 54px)',
              width: '70vw',
            }}
          >
            <TranscriptPDF
              transcripts={transcripts}
              actingAs={userDetails.actingAs}
            />
          </PDFViewer>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height={pageHeight}
            gap={theme.spacing(2)}
          >
            <Typography>No transcript information was found.</Typography>
            <OutlinedButton
              id="goBackPDF"
              variant={OutlinedButtonVariant.GoBack}
              onClick={() => navigate(-1)}
            />
          </Box>
        )}
      </Box>
    </Page>
  )
}
