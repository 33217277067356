import {
  endpoint,
  users,
  UserCommunity,
  UserProfile,
  UserProfileEditBody,
} from './swagger'
export type { UserCommunity, UserProfile } from './swagger'

export const fetchUser = async (
  userId?: string
): Promise<UserProfile | undefined> => {
  try {
    const result = await users.fetchUserProfile({
      userId: userId ?? 'me',
    })
    return result
  } catch (e) {
    throw e
  }
}

export const fetchUserRolesForCommunities = async (): Promise<
  UserCommunity[]
> => {
  try {
    const result = await users.fetchUserCommunities({ userId: 'me' })
    return result
  } catch {
    return []
  }
}

export const buildAvatarUrl = (user = 'me'): string =>
  endpoint(
    `/u/{userId}/image`.replace(
      `{${'userId'}}`,
      encodeURIComponent(String(user))
    )
  )

export const updateUserProfile = async (
  body: UserProfileEditBody
): Promise<void> => {
  try {
    const result = await users.editUserProfile({ body })
    return result
  } catch (e) {
    throw e
  }
}
