/* eslint-disable @typescript-eslint/no-unused-vars */
import { Ability } from '@casl/ability'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import login, { loginForInvite, updateLogin } from '../../api/login'
import confirmTokenForSignup from '../../api/signup'
import logout from '../../api/logout'
import {
  LoginForInviteRequestBodySignUpTypeEnum,
  Permission,
  PermissionFromJSON,
  RegionDiscourseUrl,
  SignUpConfirmTokenRequestBody,
} from '../../swagger'
import LoginForInviteResponse from '../Interfaces/LoginForInviteResponse'
import { LoginResponseFeatureDefaults } from '../../swagger'
import { LoginResponseFeatureDefaultsFromJSON } from '../../swagger'
import { useLearningCenterContext } from '../Context/LearningCenterContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useBusinessContext } from '../Context/BusinessContext'
import { useCommunitiesContext } from '../Context/CommunitiesContext'

const reloadLoginStateEventType = 'project1.event.login.reload'
const persistedLoginStateDidChange = (): void => {
  window.dispatchEvent(new Event(reloadLoginStateEventType))
}

const buildPermissionAbility = (permissions: Permission[]): Ability => {
  const rules = permissions.map(
    ({ resourceCode: subject, actionCode: action }) => ({ subject, action })
  )
  const ability = new Ability(rules)
  return ability
}

const buildFeatureAbility = (
  loadFeatureDefaults: LoginResponseFeatureDefaults[]
): Ability => {
  const rules = loadFeatureDefaults.map(
    ({ resourceCode: subject, actionCode: action }) => ({ subject, action })
  )
  const ability = new Ability(rules)
  return ability
}

/** The backend does not actually define this permission - it instead explicitly grants all defined permissions to admin - but Casl understands it as a blank check ability grant, and that list of permissions can change over time. This is convenient as a default when testing, because it matches the pre-permissions app behavior. */
const allowAllActionsOnAllResources: Permission = {
  actionCode: 'manage',
  resourceCode: 'all',
}
const adminPermissions = [allowAllActionsOnAllResources]
const adminPermissionAbility = buildPermissionAbility(adminPermissions)
const adminFeatureAbility = buildFeatureAbility([])

const defaultUserDetails: UserDetails = { actingAs: 'parent' }
const discourseUrl: RegionDiscourseUrl[] = [
  { discourseUrl: '', regionName: '' },
]
const defaultCountryInformation: CountryInformation = {
  countryOfCitizenship: '',
  countryOfResidence: '',
}

/** Provides default values for all info provided by `AuthContext`.
 *
 * Currently, all related types are derived from this exemplar, but if any properties were optional and null here, we would want to introduce an `interface AuthContextValue` to be able to specify the type when the property is not null. */
const defaultAuthContext = {
  isLoggedIn: false,
  permissions: adminPermissions,
  userDetails: defaultUserDetails,
  permissionAbility: adminPermissionAbility,
  featureAbility: adminFeatureAbility,
  discourseUrl: discourseUrl,
  countryInformation: defaultCountryInformation,

  signin: async (_credentials: {
    email: string
    password: string
  }): Promise<boolean> => {
    console.error(
      'XXX: Running the default signin. Did you forget to provide an AuthContext?'
    )
    return new Promise((resolve, _) => {
      setTimeout(() => resolve(true), 0)
    })
  },

  updateActingAs: async (actorKey?: number): Promise<boolean> => {
    console.error(
      'XXX: Runing the default updateActingAs. Did you forget to provide an AuthContext?'
    )
    return new Promise((resolve, _) => {
      setTimeout(() => resolve(true), 0)
    })
  },

  signup: async (_payload: SignUpConfirmTokenRequestBody): Promise<boolean> => {
    console.error(
      'XXX: Running the default signup. Did you forget to provide an AuthContext?'
    )
    return new Promise((resolve, _) => {
      setTimeout(() => resolve(true), 0)
    })
  },

  signout: () => {
    console.error(
      'XXX: Running the default signout. Did you forget to provide an AuthContext?'
    )
    return new Promise<void>((resolve, _) => {
      setTimeout(() => resolve(), 0)
    })
  },

  signinForInvite: async (
    _credentials: {
      username: string
      password: string
    },
    _signUpType: LoginForInviteRequestBodySignUpTypeEnum,
    _typeIdentifier: string
  ): Promise<LoginForInviteResponse> => {
    console.error(
      'XXX: Running the default signinForInvite. Did you forget to provide an AuthContext?'
    )
    return new Promise((resolve, _) => {
      setTimeout(() => resolve({ isAuthorized: true }), 0)
    })
  },
  persistedLoginStateDidChange: (): void => {
    console.error(
      'XXX: Running the default persistedLoginStateDidChange. Did you forget to provide an AuthContext?'
    )
  },
  /** Boolean determination of whether the user has the feature ability of viewDashboard */
  canViewDashboard: false as boolean,
  /** Path to navigate for logging in or redirecting when not on a known route for Account */
  defaultAccountPath: '/account/profile' as string,
}

export const signin = async (credentials: {
  email: string
  password: string
}): Promise<boolean> => {
  const {
    permissions,
    defaults,
    userKey,
    actingAs,
    discourseUrls,
    countryOfCitizenship,
    countryOfResidence,
  } = await login(credentials)
  // If we have permissions, we had to be authorized, even if they're empty
  const isAuthenticated = !!permissions
  localStorage.setItem('loggedIn', isAuthenticated.toString())
  localStorage.setItem('permissions', JSON.stringify(permissions))
  localStorage.setItem('defaults', JSON.stringify(defaults))
  localStorage.setItem('userDetails', JSON.stringify({ userKey, actingAs }))
  localStorage.setItem('discourseUrls', JSON.stringify(discourseUrls))
  localStorage.setItem(
    'countryInformation',
    JSON.stringify({ countryOfCitizenship, countryOfResidence })
  )
  persistedLoginStateDidChange()
  return isAuthenticated
}

export const updateActingAs = async (actorKey?: number): Promise<boolean> => {
  const { permissions, defaults, userKey, actingAs, discourseUrls } =
    await updateLogin(actorKey)
  // If we have permissions, we had to be authorized, even if they're empty
  const isAuthenticated = !!permissions
  localStorage.setItem('loggedIn', isAuthenticated.toString())
  localStorage.setItem('permissions', JSON.stringify(permissions))
  localStorage.setItem('defaults', JSON.stringify(defaults))
  localStorage.setItem('userDetails', JSON.stringify({ userKey, actingAs }))
  localStorage.setItem('discourseUrls', JSON.stringify(discourseUrls))
  clearBusinessCenterStorage()
  persistedLoginStateDidChange()
  return isAuthenticated
}

export const signup = async (
  payload: SignUpConfirmTokenRequestBody
): Promise<boolean> => {
  const { permissions, defaults, userKey, actingAs } =
    await confirmTokenForSignup(payload)
  const isAuthenticated = !!permissions
  localStorage.setItem('username', payload.credentials.username)
  localStorage.setItem('loggedIn', isAuthenticated.toString())
  localStorage.setItem('permissions', JSON.stringify(permissions))
  localStorage.setItem('defaults', JSON.stringify(defaults))
  localStorage.setItem('userDetails', JSON.stringify({ userKey, actingAs }))
  persistedLoginStateDidChange()
  return isAuthenticated
}

const clearBusinessCenterStorage = () => {
  // Clear previous Business Center searches and filters
  localStorage.removeItem('actorKey')
}

const clearLocalStorage = () => {
  // Clear authentication related items
  localStorage.removeItem('permissions')
  localStorage.removeItem('defaults')
  localStorage.removeItem('userDetails')
  localStorage.removeItem('discourseUrls')
  localStorage.removeItem('countryInformation')
  // Clear previous Learning/Business Center searches and filters
  clearBusinessCenterStorage()
}

export const signout = async (): Promise<boolean> => {
  const isLoggedOut = await logout()
  if (isLoggedOut) {
    localStorage.setItem('loggedIn', 'false')
    clearLocalStorage()
    persistedLoginStateDidChange()
  }
  return isLoggedOut
}

export const signinForInvite = async (
  credentials: {
    username: string
    password: string
  },
  signUpType: LoginForInviteRequestBodySignUpTypeEnum,
  typeIdentifier: string
): Promise<LoginForInviteResponse> => {
  const result = await loginForInvite(credentials, signUpType, typeIdentifier)

  const {
    permissions,
    defaults,
    isAuthorized,
    userKey,
    actingAs,
    discourseUrls,
    countryOfCitizenship,
    countryOfResidence,
  } = result

  const isAuthenticated = isAuthorized && !!permissions
  localStorage.setItem('loggedIn', isAuthenticated.toString())
  localStorage.setItem('permissions', JSON.stringify(permissions))
  localStorage.setItem('defaults', JSON.stringify(defaults))
  localStorage.setItem('userDetails', JSON.stringify({ userKey, actingAs }))
  localStorage.setItem('discourseUrls', JSON.stringify(discourseUrls))
  localStorage.setItem(
    'countryInformation',
    JSON.stringify({ countryOfCitizenship, countryOfResidence })
  )
  persistedLoginStateDidChange()
  return result
}

export const AuthContext = createContext(defaultAuthContext)
export const useAuth = (): typeof defaultAuthContext => {
  return useContext(AuthContext)
}

const initialIsLoggedIn = () => localStorage.getItem('loggedIn') === 'true'
const initialPermissions = (): Permission[] => {
  const json = localStorage.getItem('permissions')
  if (!json) {
    return []
  }

  try {
    const permissions = JSON.parse(json)
    if (!Array.isArray(permissions)) {
      return []
    }

    return permissions.map((it) => PermissionFromJSON(it))
  } catch (e) {
    return []
  }
}
const initialFeatureDefaults = (): LoginResponseFeatureDefaults[] => {
  const json = localStorage.getItem('defaults')

  if (!json) {
    return []
  }

  try {
    const defaults = JSON.parse(json)
    if (!Array.isArray(defaults)) {
      return []
    }

    return defaults.map((it) => LoginResponseFeatureDefaultsFromJSON(it))
  } catch (e) {
    return []
  }
}
export type UserDetails = {
  userKey?: number
  actingAs: number | 'parent'
}

export type CountryInformation = {
  countryOfCitizenship: string
  countryOfResidence: string
}
const initialUserDetails = (): UserDetails => {
  const json = localStorage.getItem('userDetails')
  //checking if actingAs is defined in json since for loginInvite and tokenSignup it's a string with value "{}"
  const actingAs = JSON.parse(json ?? '""').actingAs
  //Avoid error Unexpected end of JSON input
  if (!!json && !actingAs) {
    return { ...JSON.parse(json), actingAs: 'parent' }
  }

  try {
    const userDetails = JSON.parse(json ?? '')
    return userDetails
  } catch {
    return { actingAs: 'parent' }
  }
}

const initialDiscourseUrl = () => {
  const json = localStorage.getItem('discourseUrls')
  if (!json) {
    return []
  }
  try {
    const discourseUrl = JSON.parse(json)
    return discourseUrl
  } catch {
    return []
  }
}

const initialCountryInformation = () => {
  const json = localStorage.getItem('countryInformation')
  if (!json) {
    return {}
  }
  try {
    const countryInformation = JSON.parse(json)
    return countryInformation
  } catch {
    return {}
  }
}

const buildSyncEventListener = (handler: () => void) => {
  window.addEventListener(reloadLoginStateEventType, handler)
  return () => {
    window.removeEventListener(reloadLoginStateEventType, handler)
  }
}

export interface TestAuthConfig {
  loadLoggedIn: () => boolean
  loadPermissions: () => Permission[]
  loadFeatureDefaults: () => LoginResponseFeatureDefaults[]
  loadUserDetails: () => UserDetails
  loadDiscourseUrl: () => RegionDiscourseUrl[]
  countryInformation: () => CountryInformation
  /** Registers a handler to reload logged-in and permissions. Should be called when they change. Returns a function to unregister the listener. */
  buildChangeListener?: (handler: () => void) => () => void
  loadRealUserDetails: boolean
}

/** `testConfig` is provided to enable controlling the state seen by the context. signin/signout still continue to work with localStorage, as customizing them was not needed to isolate the core auth context data from _reading_ shared state. */
function useAuthContextValue(
  testConfig?: TestAuthConfig,
  /** Resets the context defaults for the learning center. */
  resetLearningCenterContextToDefaults?: () => void,
  /** Resets the context defaults for business center */
  resetBusinessContextToDefaults?: () => void,
  /** Resets the context defaults for communities */
  resetCommunitiesContextToDefaults?: () => void
): typeof defaultAuthContext {
  const loadLoggedIn = testConfig ? testConfig.loadLoggedIn : initialIsLoggedIn
  const loadPermissions = testConfig
    ? testConfig.loadPermissions
    : initialPermissions
  const buildChangeListener =
    testConfig && testConfig.buildChangeListener
      ? testConfig.buildChangeListener
      : buildSyncEventListener
  const loadFeatureDefaults = testConfig
    ? testConfig.loadFeatureDefaults
    : initialFeatureDefaults
  const loadUserDetails =
    testConfig && !testConfig?.loadRealUserDetails
      ? testConfig.loadUserDetails
      : initialUserDetails
  const loadDiscourseUrl = testConfig
    ? testConfig.loadDiscourseUrl
    : initialDiscourseUrl
  const loadCountryInformation = testConfig
    ? testConfig.countryInformation
    : initialCountryInformation

  // A good guess at our login state is loaded from localStorage.
  // If the session has in fact expired, this will be updated by ResumeSessionMiddleware on the first 401 Unauthorized response.
  const [isLoggedIn, setIsLoggedIn] = useState(loadLoggedIn)
  const [permissions, setPermissions] = useState(loadPermissions)
  const [defaults, setDefaults] = useState(loadFeatureDefaults)
  const [userDetails, setUserDetails] = useState(loadUserDetails)
  const [discourseUrl, setDiscourseUrl] = useState(loadDiscourseUrl)
  const [countryInformation, setCountryInformation] = useState(
    loadCountryInformation
  )
  const [permissionAbility, setPermissionAbility] = useState(
    buildPermissionAbility(permissions)
  )
  const [featureAbility, setFeatureAbility] = useState(
    buildFeatureAbility(defaults)
  )

  const [canViewDashboard, setCanViewDashboard] = useState(
    featureAbility.can('viewDashboard', 'Feature') &&
      permissionAbility.can('viewDirectorDashboard', 'User')
  )
  const [defaultAccountPath, setDefaultAccountPath] = useState(
    canViewDashboard ? '/account/dashboard' : '/account/profile'
  )

  const { AuthProvider } = useLoadingIds()

  /**
   * Add loading context for signout so we can use a spinner on the Invite flow.
   * We could probably do this for other areas but let's start small.
   */
  useLoadingContext({
    asyncFunction: async () => {
      await signout()
    },
    loadingId: AuthProvider.logout,
  })

  useEffect(() => {
    const reloadState = () => {
      setIsLoggedIn(loadLoggedIn)

      const permissions = loadPermissions()
      const defaults = loadFeatureDefaults()
      const userDetails = loadUserDetails()
      const discourse = loadDiscourseUrl()
      const permissionAbility = buildPermissionAbility(permissions)
      const featureAbility = buildFeatureAbility(defaults)
      const countryInfo = loadCountryInformation()
      setPermissions(permissions)
      setPermissionAbility(permissionAbility)
      setUserDetails(userDetails)
      setDiscourseUrl(discourse)
      setCountryInformation(countryInfo)
      setDefaults(defaults)
      setFeatureAbility(featureAbility)
      setCanViewDashboard(
        featureAbility.can('viewDashboard', 'Feature') &&
          permissionAbility.can('viewDirectorDashboard', 'User')
      )
      setDefaultAccountPath(
        featureAbility.can('viewDashboard', 'Feature') &&
          permissionAbility.can('viewDirectorDashboard', 'User')
          ? '/account/dashboard'
          : userDetails.actingAs === 'parent'
          ? '/account/billing'
          : '/account/profile'
      )
    }
    const unregisterHandler = buildChangeListener(reloadState)
    return unregisterHandler
  }, [
    loadLoggedIn,
    loadPermissions,
    loadFeatureDefaults,
    buildChangeListener,
    loadUserDetails,
    loadDiscourseUrl,
    loadCountryInformation,
  ])

  return {
    isLoggedIn,

    permissions: permissions,
    permissionAbility,
    featureAbility,

    userDetails,
    discourseUrl,
    countryInformation,

    signin,
    signup,
    updateActingAs: async (actorKey?: number) => {
      /**
       * This make sure the acting as process is finished before the reset
       * LC reset to avoid any cancelled network call or information not setting correctly in the LC reset
       */
      const updateActingAsRes = await updateActingAs(actorKey)
      /** Reset learning center context when changing actors */
      resetLearningCenterContextToDefaults?.()
      resetBusinessContextToDefaults?.()
      resetCommunitiesContextToDefaults?.()
      return updateActingAsRes
    },
    signinForInvite,
    async signout() {
      /** Reset learning center context and Account context when logging out */
      resetLearningCenterContextToDefaults?.()
      resetBusinessContextToDefaults?.()
      resetCommunitiesContextToDefaults?.()
      await signout()
    },
    persistedLoginStateDidChange,
    canViewDashboard,
    defaultAccountPath,
  }
}

interface AuthProviderProps extends PropsWithChildren {
  testConfig?: TestAuthConfig
}

const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
  children,
  testConfig,
}) => {
  const { resetContextToDefaults: resetLearningCenterContextToDefaults } =
    useLearningCenterContext()
  const { resetContextToDefaults: resetBusinessContextToDefaults } =
    useBusinessContext()
  const { resetContextToDefaults: resetCommunitiesContextToDefaults } =
    useCommunitiesContext()
  const auth = useAuthContextValue(
    testConfig,
    resetLearningCenterContextToDefaults,
    resetBusinessContextToDefaults,
    resetCommunitiesContextToDefaults
  )
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export default AuthProvider
