import Card from '@mui/material/Card'
import React from 'react'
import Header, { HeaderVariant } from '../Elements/Header'
import TableHeaders from '../Interfaces/TableHeaders'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import { TableHeader } from '../Table/TableHeader'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import { Box, Typography, useTheme } from '@mui/material'
import { useAccountContext } from '../Context/AccountContext'
import LoadingProgress from '../Elements/LoadingProgress'
import { LoadingContext } from '../Context/LoadingContext'
import { useNavigate } from 'react-router'
import { EnrollmentInvite } from '../../swagger'
import { useMountEffect } from '../../hooks/useMountEffect'
import { useAuth } from '../Routes/AuthProvider'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'
import { getCommaSeparatedList } from '../../utils/getCommaSeparatedList'
import { useUser } from '../../UserContext'

const PendingInvitationsCard: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    enrollmentInvites,
    updateSelectedEnrollmentInvite,
    fetchEnrollmentInvitesLoadingId,
    resetContextValuesForInvites,
  } = useAccountContext()
  const { loadingIds, addLoadingIds } = React.useContext(LoadingContext)
  const navigate = useNavigate()
  const { userDetails } = useAuth()
  const { user } = useUser()

  useMountEffect(() => {
    /**
     * We could restrict this to only call when a parent,
     * but we'll get nothing if we call with an actor anyway.
     */
    if (userDetails.actingAs === 'parent') {
      addLoadingIds([fetchEnrollmentInvitesLoadingId])
      /**
       * Values are reset whenever we come back to the PendingInvitationsCard
       * which means we're selecting a new invite. This catches the cases where
       * we select a new account tab then go back to Invites. It should leave out
       * the case where we select a new tab and navigate 'Back' via the browser.
       * At least we can save some state given an unexpected navigation.
       */
      resetContextValuesForInvites()
    }
  })

  const headers: TableHeaders[] = [
    {
      label: t('PendingInvitations.TableHeader.InviteDate', 'Invite Date'),
      align: 'left',
      cssProps: {
        background: theme.palette.background.paper,
      },
    },
    {
      label: t('PendingInvitations.TableHeader.OfferedSpots', 'Offered Spots'),
      align: 'left',
      cssProps: {
        background: theme.palette.background.paper,
      },
    },
    {
      label: t('PendingInvitations.TableHeader.Programs', 'Program(s)'),
      align: 'left',
      cssProps: {
        background: theme.palette.background.paper,
      },
    },
    {
      label: t('PendingInvitations.TableHeader.Community', 'Community'),
      align: 'left',
      cssProps: {
        background: theme.palette.background.paper,
      },
    },
    {
      id: 'blankHeader',
      label: '',
      align: 'center',
      cssProps: {
        background: theme.palette.background.paper,
      },
    },
  ]

  const handleCompleteInvitationClick = (invite: EnrollmentInvite): void => {
    if (user && !user.phone) {
      updateSelectedEnrollmentInvite(invite)
      navigate({ pathname: '/account/invites/update-parent-info' })

      return
    }
    updateSelectedEnrollmentInvite(invite)
    navigate({ pathname: '/account/invites/invitation-summary' })
  }

  if (loadingIds.has(fetchEnrollmentInvitesLoadingId)) {
    return <LoadingProgress />
  }
  return (
    <Card>
      <Box mt={2} ml={2}>
        <Header
          id="pendingInvitationsCardHeader"
          headerName={t(
            'PendingInvitations.Header.PendingInvitations',
            'Pending Invitations'
          )}
          component="h3"
          variant={HeaderVariant.Card}
        />
      </Box>
      <TableContainer aria-labelledby="pendingInvitationsCardHeader">
        <Table stickyHeader>
          {headers && (
            <TableHead>
              <TableHeader tableHeaders={headers} />
            </TableHead>
          )}
          <TableBody>
            {enrollmentInvites.length === 0 ? (
              <TableRow>
                <TableCell color={theme.palette.primary.main}>
                  <Typography variant="body1" component="p">
                    {t(
                      'PendingInvitations.Header.NoPendingInvitationsFound',
                      'No Pending Invitations Found'
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              enrollmentInvites.map((data, index) => {
                const offeredSpots = data.programs.reduce(
                  (acc, curr) => acc + curr.offeredSpots,
                  0
                )
                const programs = getCommaSeparatedList(
                  data.programs.map(
                    (program) =>
                      `${
                        program.programType
                      } (${dateToSlashStringReinterpretedAsLocal(
                        program.semesterOneStartDate as Date
                      )})`
                  )
                )

                return (
                  <TableRow key={index}>
                    <TableCell color={theme.palette.primary.main}>
                      <Typography variant="body1" component="p">
                        {dateToSlashStringReinterpretedAsLocal(
                          data.invitationDate
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell color={theme.palette.primary.main}>
                      <Typography variant="body1" component="p">
                        {offeredSpots}
                      </Typography>
                    </TableCell>
                    <TableCell color={theme.palette.primary.main}>
                      <Typography variant="body1" component="p">
                        {programs}
                      </Typography>
                    </TableCell>
                    <TableCell color={theme.palette.primary.main}>
                      <Typography variant="body1" component="p">
                        {data.communityName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextButton
                        id={`completeInvitation-${data.uuid}`}
                        variant={TextButtonVariant.CompleteInvitation}
                        onClick={() => handleCompleteInvitationClick(data)}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default PendingInvitationsCard
