import React, { useRef, useState } from 'react'
import BasicModal from './BasicModal'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { TFunction, useTranslation } from 'react-i18next'
import { Box, Typography, TypographyProps, styled } from '@mui/material'
import { MenuOption } from '../Menus/DropDown'
import PrepopulateDropDown, {
  PrepopulateDropDownValue,
} from '../Transcripts/PrepopulateDropDown'

import { useTranscriptYearsOptions } from '../../utils/useTranscriptYearsOptions'

export interface PrepopulateClassInformationModalProps {
  isOpen: boolean
  onPopulate: (
    transcriptYears: TranscriptYears,
    transcriptPrograms: TranscriptPrograms
  ) => void
  onCancel: () => void
  inputGrades: Grades[]
}
export interface TranscriptYears {
  academicYear1: string
  academicYear2: string
  academicYear3: string
  academicYear4: string
}

export interface TranscriptPrograms {
  transcriptProgram1: string
  transcriptProgram2: string
  transcriptProgram3: string
  transcriptProgram4: string
}

enum TranscriptProgramsVariant {
  ChallengeI = 'ChallengeI',
  ChallengeII = 'ChallengeII',
  ChallengeIII = 'ChallengeIII',
  ChallengeIV = 'ChallengeIV',
}

export enum Grades {
  Ninth = 'Ninth',
  Tenth = 'Tenth',
  Eleventh = 'Eleventh',
  Twelfth = 'Twelfth',
}

const getLabelForProgram = (
  variant: TranscriptProgramsVariant,
  t: TFunction
): string => {
  switch (variant) {
    case TranscriptProgramsVariant.ChallengeI:
      return t('PrepopulateModal.Label.ChallengeI', 'Challenge I')
    case TranscriptProgramsVariant.ChallengeII:
      return t('PrepopulateModal.Label.ChallengeII', 'Challenge II')
    case TranscriptProgramsVariant.ChallengeIII:
      return t('PrepopulateModal.Label.ChallengeIII', 'Challenge III')
    case TranscriptProgramsVariant.ChallengeIV:
      return t('PrepopulateModal.Label.ChallengeIV', 'Challenge IV')
  }
}

const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  margin: theme.spacing(1.5, 4),
})) as typeof Typography

const PrepopulateClassInformationModal: React.FC<
  PrepopulateClassInformationModalProps
> = ({ isOpen, onCancel, inputGrades, onPopulate }) => {
  const { t } = useTranslation()
  const yearsOptions = useTranscriptYearsOptions()
  const isSingleDropDown = inputGrades.length === 1

  const defaultTranscriptYears = useRef({
    academicYear1: '',
    academicYear2: '',
    academicYear3: '',
    academicYear4: '',
  })
  const defaultTranscriptPrograms = useRef({
    transcriptProgram1: '',
    transcriptProgram2: '',
    transcriptProgram3: '',
    transcriptProgram4: '',
  })

  const [transcriptYears, setTranscriptYears] = useState(
    defaultTranscriptYears.current
  )
  const [transcriptPrograms, setTranscriptPrograms] = useState(
    defaultTranscriptPrograms.current
  )

  const programOptions: MenuOption[] = [
    {
      name: getLabelForProgram(TranscriptProgramsVariant.ChallengeI, t),
      id: 1,
    },
    {
      name: getLabelForProgram(TranscriptProgramsVariant.ChallengeII, t),
      id: 2,
    },
    {
      name: getLabelForProgram(TranscriptProgramsVariant.ChallengeIII, t),
      id: 3,
    },
    {
      name: getLabelForProgram(TranscriptProgramsVariant.ChallengeIV, t),
      id: 4,
    },
  ]

  const handleSelection = (selection: string, id: string) => {
    const [idPrefix] = id.split(/(\d+)/)
    if (idPrefix === 'academicYear') {
      setTranscriptYears({
        ...transcriptYears,
        [id]: selection,
      })
    } else if (idPrefix === 'transcriptProgram') {
      setTranscriptPrograms({
        ...transcriptPrograms,
        [id]: selection,
      })
    }
  }

  const isAYearOrProgramSelected = (): boolean => {
    return Object.entries(transcriptPrograms).some(([, value]) => !!value)
  }

  const resetModal = () => {
    setTranscriptYears(defaultTranscriptYears.current)
    setTranscriptPrograms(defaultTranscriptPrograms.current)
  }

  const onModalCancel = () => {
    resetModal()
    onCancel()
  }

  const onFormSubmit = (
    transcriptYears: TranscriptYears,
    transcriptPrograms: TranscriptPrograms
  ) => {
    resetModal()
    onPopulate(transcriptYears, transcriptPrograms)
  }

  const dialogTitle = t(
    'PrepopulateClassInformationModal.Header',
    'Year and Program Coursework'
  )

  const dialogActions = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.Populate}
      secondaryButtonLabel={TextButtonVariant.Cancel}
      secondaryClick={onModalCancel}
      disablePrimaryButton={!isAYearOrProgramSelected()}
    />
  )

  const dialogContent = (
    <>
      <Box display="flex">
        <StyledTypography textAlign="center">
          {isSingleDropDown
            ? t(
                'Transcripts.PrepopulateClassInformationModal.Body',
                'Select a program for the current grade level and the coursework will prepopulate. Only selected values will display content. Any changes later will overwrite previous selections.'
              )
            : t(
                'Transcripts.PrepopulateClassInformationModal.Body',
                'Select a program for each grade level and the coursework will prepopulate. Only selected values will display content. Any changes later will overwrite previous selections.'
              )}
        </StyledTypography>
      </Box>
      {inputGrades.map((grade) => {
        const getDropDownValue = (g: Grades): PrepopulateDropDownValue => {
          switch (g) {
            case Grades.Ninth:
              return {
                yearValue: transcriptYears.academicYear1 ?? '',
                programValue: transcriptPrograms.transcriptProgram1 ?? '',
              }
            case Grades.Tenth:
              return {
                yearValue: transcriptYears.academicYear2 ?? '',
                programValue: transcriptPrograms.transcriptProgram2 ?? '',
              }
            case Grades.Eleventh:
              return {
                yearValue: transcriptYears.academicYear3 ?? '',
                programValue: transcriptPrograms.transcriptProgram3 ?? '',
              }
            case Grades.Twelfth:
              return {
                yearValue: transcriptYears.academicYear4 ?? '',
                programValue: transcriptPrograms.transcriptProgram4 ?? '',
              }
            default:
              return {
                yearValue: `${g}`,
                programValue: `${g}`,
              }
          }
        }
        return (
          <PrepopulateDropDown
            grade={grade as Grades}
            handleSelection={handleSelection}
            options={{ yearsOptions, programOptions }}
            dropDownValue={getDropDownValue(grade)}
            key={grade}
            hideDivider={isSingleDropDown}
          />
        )
      })}
    </>
  )

  return (
    <BasicModal
      isOpen={isOpen}
      dialogTitle={dialogTitle}
      dialogActions={dialogActions}
      dialogContent={dialogContent}
      handleFormSubmit={() => onFormSubmit(transcriptYears, transcriptPrograms)}
      maxWidth="sm"
    />
  )
}

export default PrepopulateClassInformationModal
