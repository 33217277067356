import i18next from 'i18next'

const getLocaleCurrencyForAmount = (
  value: number,
  currencyCode: string
): string => {
  const currencyFormatter = new Intl.NumberFormat(i18next.language, {
    style: 'currency',
    currency: currencyCode,
  })
  // Temporarily removing currency symbols to only display formatted numbers instead WF-1081245
  return (
    currencyFormatter?.format(value).replace(/[^0-9.-]+/g, '') ??
    `{value}\u00A0${currencyCode}`
  )
}

export default getLocaleCurrencyForAmount
