import React from 'react'
import TextField from '@mui/material/TextField'
import { InputBaseComponentProps } from '@mui/material/InputBase'
import FieldProps from '../Interfaces/FieldProps'

interface EmailFieldProps extends FieldProps {
  label: string
  inputProps: Partial<InputBaseComponentProps>
}

const EmailField: React.FunctionComponent<EmailFieldProps> = (props) => {
  return (
    <TextField
      value={props.value}
      error={props.error}
      helperText={props.helperText}
      inputProps={props.inputProps}
      fullWidth
      id={'email'}
      type={'email'}
      label={props.label}
      margin="normal"
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyPress={props.onKeyPress}
      variant="filled"
      InputProps={{
        ...props.InputProps,
      }}
    />
  )
}

export default EmailField
