/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface Css
 */
export interface Css {
  /**
   *
   * @type {string}
   * @memberof Css
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof Css
   */
  label?: string
  /**
   *
   * @type {Array<string>}
   * @memberof Css
   */
  visiblePages?: Array<string>
}

export function CssFromJSON(json: any): Css {
  return CssFromJSONTyped(json, false)
}

export function CssFromJSONTyped(json: any, ignoreDiscriminator: boolean): Css {
  if (json === undefined || json === null) {
    return json
  }
  return {
    email: !exists(json, 'email') ? undefined : json['email'],
    label: !exists(json, 'label') ? undefined : json['label'],
    visiblePages: !exists(json, 'visiblePages')
      ? undefined
      : json['visiblePages'],
  }
}

export function CssToJSON(value?: Css | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    email: value.email,
    label: value.label,
    visiblePages: value.visiblePages,
  }
}
