import { InputProps as StandardInputProps } from '@mui/material/Input'
import React from 'react'

export interface FieldProps {
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur: (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  onKeyPress: (event: React.KeyboardEvent) => void
  error: boolean
  helperText: string
  InputProps: Partial<StandardInputProps>
  value: string
}

export enum TextFieldVariant {
  Filled = 'filled',
  Outlined = 'outlined',
  Standard = 'standard',
}

export default FieldProps
