import { FetchAcademicYearsResponseAcademicYears } from '../swagger'

export const buildAcademicYearsFilterOptions = (
  academicYears: FetchAcademicYearsResponseAcademicYears[]
): Array<{ year: number; name: string; isDefault: boolean }> => {
  const academicYearMap = academicYears
    .sort((a, b) => b.year - a.year)
    .map((it) => {
      return {
        year: it.year,
        isDefault: it.isDefault ?? false,
        name: it.isCurrent
          ? [it.year, '(CURRENT)'].join(' ')
          : it.year.toString(),
      }
    })

  return academicYearMap
}
