/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  GradingScaleDetails,
  GradingScaleDetailsFromJSON,
  GradingScaleDetailsToJSON,
  GradingScaleWeight,
  GradingScaleWeightFromJSON,
  GradingScaleWeightToJSON,
} from './'

/**
 *
 * @export
 * @interface GradingScale
 */
export interface GradingScale {
  /**
   * The unique identifier for the grading scale.
   * @type {number}
   * @memberof GradingScale
   */
  gradingScaleKey: number
  /**
   * The name of the grading scale.
   * @type {string}
   * @memberof GradingScale
   */
  scaleName?: string
  /**
   * Each letter grade and it's correponding sortOrder, scaleRange and credit number .
   * @type {Array<GradingScaleDetails>}
   * @memberof GradingScale
   */
  gradingScaleDetails?: Array<GradingScaleDetails>
  /**
   * Weights for transcript course work, and how much they weigh.
   * @type {Array<GradingScaleWeight>}
   * @memberof GradingScale
   */
  gradingScaleWeight?: Array<GradingScaleWeight>
}

export function GradingScaleFromJSON(json: any): GradingScale {
  return GradingScaleFromJSONTyped(json, false)
}

export function GradingScaleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GradingScale {
  if (json === undefined || json === null) {
    return json
  }
  return {
    gradingScaleKey: json['gradingScaleKey'],
    scaleName: !exists(json, 'scaleName') ? undefined : json['scaleName'],
    gradingScaleDetails: !exists(json, 'gradingScaleDetails')
      ? undefined
      : (json['gradingScaleDetails'] as Array<any>).map(
          GradingScaleDetailsFromJSON
        ),
    gradingScaleWeight: !exists(json, 'gradingScaleWeight')
      ? undefined
      : (json['gradingScaleWeight'] as Array<any>).map(
          GradingScaleWeightFromJSON
        ),
  }
}

export function GradingScaleToJSON(value?: GradingScale | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    gradingScaleKey: value.gradingScaleKey,
    scaleName: value.scaleName,
    gradingScaleDetails:
      value.gradingScaleDetails === undefined
        ? undefined
        : (value.gradingScaleDetails as Array<any>).map(
            GradingScaleDetailsToJSON
          ),
    gradingScaleWeight:
      value.gradingScaleWeight === undefined
        ? undefined
        : (value.gradingScaleWeight as Array<any>).map(
            GradingScaleWeightToJSON
          ),
  }
}
