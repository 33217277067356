/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PublicCommunityLocation,
  PublicCommunityLocationFromJSON,
  PublicCommunityLocationToJSON,
  PublicContact,
  PublicContactFromJSON,
  PublicContactToJSON,
  PublicProgram,
  PublicProgramFromJSON,
  PublicProgramToJSON,
} from './'

/**
 *
 * @export
 * @interface PublicCommunity
 */
export interface PublicCommunity {
  /**
   *
   * @type {string}
   * @memberof PublicCommunity
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof PublicCommunity
   */
  id: string
  /**
   *
   * @type {PublicContact}
   * @memberof PublicCommunity
   */
  contact: PublicContact
  /**
   *
   * @type {PublicCommunityLocation}
   * @memberof PublicCommunity
   */
  location: PublicCommunityLocation
  /**
   *
   * @type {Array<PublicProgram>}
   * @memberof PublicCommunity
   */
  programs: Array<PublicProgram>
}

export function PublicCommunityFromJSON(json: any): PublicCommunity {
  return PublicCommunityFromJSONTyped(json, false)
}

export function PublicCommunityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PublicCommunity {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    id: json['id'],
    contact: PublicContactFromJSON(json['contact']),
    location: PublicCommunityLocationFromJSON(json['location']),
    programs: (json['programs'] as Array<any>).map(PublicProgramFromJSON),
  }
}

export function PublicCommunityToJSON(value?: PublicCommunity | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    id: value.id,
    contact: PublicContactToJSON(value.contact),
    location: PublicCommunityLocationToJSON(value.location),
    programs: (value.programs as Array<any>).map(PublicProgramToJSON),
  }
}
