import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { CanAccess } from '../../Elements/Access'
import TitleContext from '../../../TitleContext'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import RegionFormCard, {
  RegionFormCardVariants,
} from '../../Card/RegionFormCard'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'
import EmptyRegion from './EmptyRegion'
import {
  Region,
  RegionContractingHierarchy,
  RegionFeeSetting,
} from '../../../swagger'
import { regionsApi, extractedErrorObject } from '../../../api/swagger'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import { LoadingContext } from '../../Context/LoadingContext'
import useLoadingContext from '../../../hooks/useLoadingContext'

const RegionDetails: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const title = t('Regions.AddRegion.Header', 'Regions')
  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds, removeLoadingIds, loadingIds } =
    React.useContext(LoadingContext)

  const { regionKey } = useParams<{
    regionKey: string
  }>()

  const [regionDetails, setRegionDetails] = useState<Region>()
  const [regionFees, setRegionFees] = useState<RegionFeeSetting[]>()
  const [regionContractingHierarchies, setRegionContractingHierarchies] =
    useState<RegionContractingHierarchy[]>()
  const [errorMessage, setErrorMessage] = useState('')

  /**
   * We use this to trigger the useEffect for fetching data programmatically.
   * The value itself is not significant, however when the value changes it will trigger the useEffect
   */
  const [triggerRefetch, setTriggerRefetch] = useState(false)

  /**
   * Show loading spinner only when we're fetching a new region's details.
   * If updating an existing region, then we don't show the loading spinner.
   */
  const isLoading = loadingIds.has(availableLoadingIds.Regions.fetchRegion)
  /**
   * Fetch Region Details
   */
  useEffect(() => {
    if (triggerRefetch || regionKey !== '') {
      addLoadingIds([availableLoadingIds.Regions.fetchRegion])
    }
  }, [
    addLoadingIds,
    availableLoadingIds.Regions.fetchRegion,
    regionKey,
    triggerRefetch,
  ])

  const fetchRegionDetails = async () => {
    try {
      const requestParam = { regionKey: +`${regionKey}` }

      /** Ensure all 3 endpoints that feed into region details are successful */
      const [
        fetchedRegionDetails,
        fetchedRegionFees,
        fetchedRegionContractingHierarchies,
      ] = await Promise.all([
        regionsApi.fetchRegion(requestParam),
        regionsApi.fetchRegionFees(requestParam),
        regionsApi.fetchRegionContractingHierarchies(requestParam),
      ])

      setRegionDetails(fetchedRegionDetails)
      setRegionFees(fetchedRegionFees)
      setRegionContractingHierarchies(fetchedRegionContractingHierarchies)
    } catch (err) {
      const errorObject = (await extractedErrorObject(err)) ?? {
        code: 'UnknownError',
        message:
          (err as unknown as Error).message ??
          'Failed to fetch region details.',
      }
      setErrorMessage(errorObject.message)
    } finally {
      removeLoadingIds([availableLoadingIds.Regions.fetchRegion])
    }
  }

  // identifying this as 'fetchRegion' even though it calls 3 endpoints, will probably be updated with a convention for functions alike
  useLoadingContext({
    asyncFunction: fetchRegionDetails,
    loadingId: availableLoadingIds.Regions.fetchRegion,
  })

  /**
   * If error fetching region details, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  const handleSave = () => {
    // Programmatically trigger a refetch of region details
    setTriggerRefetch((prevState) => !prevState)
  }

  const navigateToRegions = () => {
    navigate(
      {
        pathname: '/admin/regions',
      },
      {
        /** Navigation Options */
      }
    )
  }

  const navigateToParentRegion = (regionId?: number) => {
    if (!regionId) return
    navigate(
      {
        pathname: `/admin/regions/region-details/${regionId.toString()}`,
      },
      {
        /** Navigation Options */
      }
    )
  }

  if (!regionDetails) {
    return (
      <CanAccess I="view" on="Region">
        <EmptyRegion isLoading={isLoading} />
      </CanAccess>
    )
  }

  return (
    <CanAccess I="accessRegionsSection" on="Admin">
      <CanAccess I="view" on="Region">
        <DynamicBreadcrumbs
          breadcrumbs={[
            {
              label: t('Regions.AddRegion.BreadCrumb.Regions', 'Regions'),
              onClick: navigateToRegions,
            },
            {
              label: regionDetails.parentRegionName,
              onClick: () =>
                navigateToParentRegion(regionDetails.parentRegionsKey),
            },
            {
              label: regionDetails.name,
            },
          ]}
        />
        <RegionFormCard
          variant={RegionFormCardVariants.EditRegion}
          regionDetails={regionDetails}
          regionFees={regionFees}
          regionContractingHierarchies={regionContractingHierarchies}
          handleSave={handleSave}
        />
      </CanAccess>
    </CanAccess>
  )
}

export default RegionDetails
