import React, { useEffect } from 'react'
import { contentApi, extractedErrorObject } from '../../../api/swagger'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../../Context/SnackbarContext'
import { useAuth } from '../../Routes/AuthProvider'
import { LoadingContext } from '../../Context/LoadingContext'
import { useTranslation } from 'react-i18next'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { FetchLearningCenterOptionsProgramTypeEnum } from '../../../swagger'
import { useLearningCenterContext } from '../../Context/LearningCenterContext'
import {
  FilterFileByChallengeLevel,
  LearningCenterTabs,
} from '../../../utils/searchAndFilterEnums'

export const stringToChallengeLevelMap = new Map<
  string,
  FilterFileByChallengeLevel
>([
  ['Challenge A', FilterFileByChallengeLevel.ChallengeA],
  ['Challenge B', FilterFileByChallengeLevel.ChallengeB],
  ['Challenge I', FilterFileByChallengeLevel.Challenge1],
  ['Challenge II', FilterFileByChallengeLevel.Challenge2],
  ['Challenge III', FilterFileByChallengeLevel.Challenge3],
  ['Challenge IV', FilterFileByChallengeLevel.Challenge4],
])

export const useLearningCenterOptions = (options: {
  /** If not provided, don't call the endpoint. */
  programType?: FetchLearningCenterOptionsProgramTypeEnum
}): void => {
  const { programType } = options
  const { t } = useTranslation()
  const { featureAbility } = useAuth()
  const { setSnackbarMessage, setSnackbarState, setSnackbarSeverity } =
    useSnackbarContext()
  const { addLoadingIds } = React.useContext(LoadingContext)
  const {
    updateLearningCenterOptions,
    optionsLoadingId: loadingId,
    level,
    selectedTabKey,
    welcomeCenterContent,
  } = useLearningCenterContext()
  const isChallengeTab = selectedTabKey.includes(LearningCenterTabs.Challenge)
  const fetchLearningCenterOptions = async () => {
    if (
      (level === undefined && !welcomeCenterContent && isChallengeTab) ||
      !programType
    )
      return

    const programTypeValue = (
      isChallengeTab ? level : programType
    ) as FetchLearningCenterOptionsProgramTypeEnum

    try {
      const { categories: fetchedCategories, filetypes: fetchedFiletypes } =
        await contentApi.fetchLearningCenterOptions({
          programType: programTypeValue,
        })

      /**
       * Update the options within the LearningCenterContext. No need to have state
       * variables within this hook since our context will hold the information
       * for child components.
       */
      updateLearningCenterOptions({
        categories: fetchedCategories,
        filetypes: fetchedFiletypes,
      })
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'Endpoints.FetchLearningCenterProgramTypes.Error',
          'An unknown error occurred fetching learning center options.'
        ),
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  useEffect(() => {
    /** Don't call the endpoint if we can't access the feature */
    if (
      (!!programType || (!!level && isChallengeTab)) &&
      !welcomeCenterContent &&
      featureAbility.can('learningCenter', 'Feature')
    ) {
      addLoadingIds([loadingId])
    }
  }, [
    addLoadingIds,
    featureAbility,
    loadingId,
    welcomeCenterContent,
    programType,
    level,
    isChallengeTab,
  ])

  useLoadingContext({
    asyncFunction: fetchLearningCenterOptions,
    loadingId,
  })
}
