/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Payment, PaymentFromJSON, PaymentToJSON } from './'

/**
 *
 * @export
 * @interface AddInternationalLicensingPaymentResponse
 */
export interface AddInternationalLicensingPaymentResponse {
  /**
   *
   * @type {Payment}
   * @memberof AddInternationalLicensingPaymentResponse
   */
  payment: Payment
}

export function AddInternationalLicensingPaymentResponseFromJSON(
  json: any
): AddInternationalLicensingPaymentResponse {
  return AddInternationalLicensingPaymentResponseFromJSONTyped(json, false)
}

export function AddInternationalLicensingPaymentResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddInternationalLicensingPaymentResponse {
  if (json === undefined || json === null) {
    return json
  }
  return {
    payment: PaymentFromJSON(json['payment']),
  }
}

export function AddInternationalLicensingPaymentResponseToJSON(
  value?: AddInternationalLicensingPaymentResponse | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    payment: PaymentToJSON(value.payment),
  }
}
