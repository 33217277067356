import { Box, TextField, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import BasicModal from './BasicModal'
import { UserAccountDetails } from '../../swagger'
import { TextButtonVariant } from '../Buttons/TextButton'
import { validateEmailText } from '../../helpers/validateEmail'
import useEmailMessage from '../../hooks/useEmailMessage'

interface UserAccountModalProps {
  isOpen: boolean
  onClose: () => void
  initialUserAccount: UserAccountDetails
  updateUserAccountForParent: (userAccount: UserAccountDetails) => void
}

const UserAccountModal: React.FC<UserAccountModalProps> = (props) => {
  const { isOpen, onClose, initialUserAccount, updateUserAccountForParent } =
    props
  const { t } = useTranslation()
  const theme = useTheme()
  const { validationMessages } = useEmailMessage()

  const [userAccount, setUserAccount] = useState(initialUserAccount)
  const [isContactEmailValid, setIsContactEmailValid] = useState(true)
  const [isUsernameValid, setIsUsernameValid] = useState(true)
  const [textEmailValid, setTextEmailValid] = useState<string[]>(['', ''])

  const handleFormSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    // Validate the email if it's empty.
    const ccEmailValid =
      userAccount.email === '' ||
      validateContactEmail(userAccount.email ?? '', 1)

    setIsContactEmailValid(ccEmailValid)

    // But the username should ALWAYS be there.
    const usernameValid = validateContactEmail(userAccount.username ?? '', 0)
    setIsUsernameValid(usernameValid)

    if (ccEmailValid && usernameValid) {
      updateUserAccountForParent(userAccount)
    }
  }

  const resetModalState = () => {
    setUserAccount(initialUserAccount)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAccount({
      ...userAccount,
      email: event.target.value.trim(),
    })
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAccount({
      ...userAccount,
      username: event.target.value.trim(),
    })
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAccount({
      ...userAccount,
      lastName: event.target.value,
    })
  }

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUserAccount({
      ...userAccount,
      firstName: event.target.value,
    })
  }

  const validateContactEmail = (email: string, index: number) => {
    const resultMsg = validateEmailText(email)
    textEmailValid[index] = resultMsg
    setTextEmailValid([...textEmailValid])
    return resultMsg === ''
  }

  return (
    <BasicModal
      isOpen={isOpen}
      handleFormSubmit={handleFormSubmit}
      maxWidth="sm"
      dialogContent={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row' }}
            width="100%"
          >
            <TextField
              id="firstNameModalField"
              label={t('UserAccountCard.Field.FirstName', 'First Name')}
              variant="filled"
              value={userAccount.firstName}
              sx={{
                marginBottom: theme.spacing(3),
                width: '100%',
                paddingRight: theme.spacing(2),
              }}
              onChange={handleFirstNameChange}
            />
            <TextField
              id="lastNameModalField"
              label={t('UserAccountCard.Field.LastName', 'Last Name')}
              variant="filled"
              value={userAccount.lastName}
              sx={{
                marginBottom: theme.spacing(3),
                width: '100%',
              }}
              onChange={handleLastNameChange}
            />
          </Box>
          <TextField
            id="loginEmailModalField"
            label={t('UserAccountCard.Field.LoginEmail', 'Login Email')}
            variant="filled"
            value={userAccount.username}
            sx={{
              minWidth: '100%',
              marginBottom: theme.spacing(3),
              [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
              },
            }}
            onChange={handleUsernameChange}
            error={!isUsernameValid}
            helperText={validationMessages(textEmailValid[0])}
          />
          <TextField
            id="classicalConversationsEmailModalField"
            label={t(
              'UserAccountCard.Field.ClassicalConversationsEmail',
              'Classical Conversations Email'
            )}
            variant="filled"
            value={userAccount.email}
            sx={{
              minWidth: '100%',
              marginBottom: theme.spacing(3),
              [theme.breakpoints.down('sm')]: {
                minWidth: '100%',
              },
            }}
            onChange={handleEmailChange}
            error={!isContactEmailValid}
            helperText={
              !isContactEmailValid && validationMessages(textEmailValid[1])
            }
          />
        </Box>
      }
      dialogActions={
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.Save}
          secondaryButtonLabel={TextButtonVariant.Cancel}
          secondaryClick={onClose}
        />
      }
      dialogTitle={t('UserAccountModal.Title', 'Edit User Information')}
      afterClose={resetModalState}
      labelledBy="user-account-form-title"
    />
  )
}

export default UserAccountModal
