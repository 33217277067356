import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { CanAccess } from '../../Elements/Access'
import TitleContext from '../../../TitleContext'
import RegionFormCard, {
  RegionFormCardVariants,
} from '../../Card/RegionFormCard'
import DynamicBreadcrumbs from '../../Elements/DynamicBreadcrumbs'

const AddRegion: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const title = t('Regions.AddRegion.Header', 'Regions')

  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const navigateToRegions = () => {
    navigate(
      {
        pathname: '/admin/regions',
      },
      {
        /** Navigation options */
      }
    )
  }

  const breadcrumbs = [
    {
      label: t('Regions.AddRegion.BreadCrumb.Regions', 'Regions'),
      onClick: navigateToRegions,
    },
    {
      label: t('Regions.AddRegion.BreadCrumb.CreateRegion', 'Create Region'),
    },
  ]

  return (
    <CanAccess I="accessRegionsSection" on="Admin">
      <CanAccess I="create" on="Region">
        <DynamicBreadcrumbs breadcrumbs={breadcrumbs} />
        <RegionFormCard
          variant={RegionFormCardVariants.AddRegion}
          handleCancel={navigateToRegions}
        />
      </CanAccess>
    </CanAccess>
  )
}

export default AddRegion
