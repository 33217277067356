import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import {
  CheckboxesTypes,
  HandleCheckboxesChangeProps,
} from './EnrollmentsTable'
import { useTranslation } from 'react-i18next'
import EnrollmentRowInfo from '../Interfaces/EnrollmentRowInfo'

export interface LicensingOwedCheckboxesProps {
  enrollment: EnrollmentRowInfo
  isChallenge: boolean
  isEnrollmentsTableEdit: boolean
  handleOnChange: (props: HandleCheckboxesChangeProps) => void
}

const LicensingOwedCheckboxes: React.FC<LicensingOwedCheckboxesProps> = ({
  enrollment,
  isChallenge,
  isEnrollmentsTableEdit,
  handleOnChange,
}) => {
  const { t } = useTranslation()
  const {
    semesterOneTuitionPaid,
    semesterTwoTuitionPaid,
    semesterOneLicensingOwed,
    semesterTwoLicensingOwed,
    studentKey,
    programKey,
  } = enrollment

  /*
   Validates that 
   1. if the Tuition Paid checkbox is unchecked, the Licensing Owed checkboxes should not be allowed to be checked. 
   2. if both checkboxes semesterOneTuitionPaid and semesterOneLicensingOwed are checked and then uncheck 
   semesterOneTuitionPaid the checkbox corresponding to semesterOneLicensingOwed must be disabled immediately
   to avoid its modification.
   */

  return (
    <FormGroup sx={{ flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <Checkbox
            name="licensingOwedCheckBox1stSemester"
            color="secondary"
            sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
          />
        }
        aria-label="1st Semester"
        label={
          isChallenge && (
            <Typography variant="caption" component="p">
              {t(
                'LicensingOwedCheckboxes.Checkbox.FirstSemester',
                '1st Semester'
              )}
            </Typography>
          )
        }
        disabled={!isEnrollmentsTableEdit || !semesterOneTuitionPaid}
        checked={semesterOneLicensingOwed}
        onChange={() =>
          handleOnChange({
            studentKey,
            programKey,
            checkboxesValues: CheckboxesTypes.semesterOneLicensingOwed,
          })
        }
      />
      {isChallenge && (
        <FormControlLabel
          control={
            <Checkbox
              name="licensingOwedCheckBox2ndSemester"
              color="secondary"
              sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
            />
          }
          aria-label="2nd Semester"
          label={
            <Typography variant="caption" component="p">
              {t(
                'LicensingOwedCheckboxes.Checkbox.SecondSemester',
                '2nd Semester'
              )}
            </Typography>
          }
          disabled={!isEnrollmentsTableEdit || !semesterTwoTuitionPaid}
          checked={semesterTwoLicensingOwed}
          onChange={() =>
            handleOnChange({
              studentKey,
              programKey,
              checkboxesValues: CheckboxesTypes.semesterTwoLicensingOwed,
            })
          }
        />
      )}
    </FormGroup>
  )
}

export default LicensingOwedCheckboxes
