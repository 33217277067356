import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Rating from '@mui/material/Rating'
import ShareLinkButton from '../Buttons/ShareLinkButton'
import FavoriteButton from '../Buttons/FavoriteButton'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

export interface ContentOverviewProps {
  assetKey: number
  icon: JSX.Element | null | undefined
  title: string
  iconBackground: 'light' | 'dark'
  description: string
  isFavorite: boolean
  starRating?: number | null
  starRatingOnChange?: (
    event: React.SyntheticEvent,
    value: number | null
  ) => void
  extraTextAfterRating?: string
  subText?: string
  extraSubText?: string
  actionArea?: JSX.Element
  mobileMedia?: JSX.Element
  isLearningPath?: boolean
  handleFavoriteButtonClicked?: ({
    isFavorite,
  }: {
    isFavorite: boolean
  }) => void
}
const ContentOverviewCard: React.FunctionComponent<ContentOverviewProps> = (
  props
) => {
  const theme = useTheme()
  const isDesktop = useShowOnDesktop()
  const {
    assetKey,
    icon,
    title,
    iconBackground,
    description,
    starRating,
    starRatingOnChange,
    extraTextAfterRating,
    subText,
    extraSubText,
    actionArea,
    mobileMedia,
    isLearningPath,
    isFavorite,
    handleFavoriteButtonClicked,
  } = props

  if (!isDesktop) {
    return (
      <Box>
        {!mobileMedia && (
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            mb={2.5}
          >
            <Box
              width="108px"
              height="108px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              sx={{
                background:
                  iconBackground === 'dark'
                    ? theme.palette.primary.main
                    : theme.palette.customBackground.logo,
              }}
            >
              {icon}
            </Box>
            <Box>
              <ShareLinkButton
                isOverviewCard={true}
                isLearningPath={isLearningPath ?? false}
                {...props}
              />
              {!isLearningPath && (
                <FavoriteButton
                  isFavorited={isFavorite}
                  assetKey={assetKey}
                  handleFavoriteButtonClicked={handleFavoriteButtonClicked}
                />
              )}
            </Box>
          </Box>
        )}

        <Typography
          variant="h6"
          component="h3"
          sx={{ ...(mobileMedia && { marginBottom: theme.spacing(2) }) }}
        >
          {title}
        </Typography>
        {mobileMedia && (
          <>
            {mobileMedia}
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              <Box
                component="fieldset"
                borderColor="transparent"
                display="flex"
              >
                <Rating
                  name="business content star rating"
                  value={starRating}
                  onChange={starRatingOnChange}
                  color={theme.palette.customBackground.event}
                />
                <Box mr={4} ml={1}>
                  <Typography
                    variant="body1"
                    component="p"
                    color={theme.palette.primary.dark}
                  >
                    {starRating?.toFixed(1)}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <ShareLinkButton
                  isOverviewCard={true}
                  isLearningPath={isLearningPath ?? false}
                  {...props}
                />
                {!isLearningPath && (
                  <FavoriteButton
                    isFavorited={isFavorite}
                    assetKey={assetKey}
                    handleFavoriteButtonClicked={handleFavoriteButtonClicked}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
        <Box>
          <Typography variant="body1" component="p">
            {subText}
          </Typography>
          <Typography variant="body1" component="p">
            {extraSubText}
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography
            variant="body1"
            component="p"
            color={theme.palette.primary.dark}
          >
            {description}
          </Typography>
        </Box>
        {actionArea && (
          <Box
            display="flex"
            flexDirection="column"
            mt={2.5}
            alignItems={'flex-end'}
          >
            {actionArea}
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Box display="flex" position="relative">
      <Box
        width="250px"
        height="250px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color="white"
        sx={{
          background:
            iconBackground === 'dark'
              ? theme.palette.primary.main
              : theme.palette.customBackground.logo,
        }}
      >
        {icon}
      </Box>

      <Box marginLeft={4} flex="1">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          <Box>
            <ShareLinkButton
              isOverviewCard={true}
              isLearningPath={isLearningPath ?? false}
              {...props}
            />
            {!isLearningPath && (
              <FavoriteButton
                isFavorited={isFavorite}
                assetKey={assetKey}
                handleFavoriteButtonClicked={handleFavoriteButtonClicked}
              />
            )}
          </Box>
        </Box>

        <Box display="flex" mt={1} color={theme.palette.primary.dark}>
          {!isLearningPath && (
            <>
              <Box component="fieldset" borderColor="transparent">
                <Rating
                  name="business content star rating"
                  value={starRating}
                  onChange={starRatingOnChange}
                  color={theme.palette.customBackground.event}
                />
              </Box>
              <Box mr={4} ml={1}>
                <Typography variant="body1" component="p">
                  {starRating?.toFixed(1)}
                </Typography>
              </Box>
            </>
          )}

          {extraTextAfterRating && (
            <Typography variant="body1" component="p">
              {extraTextAfterRating}
            </Typography>
          )}
        </Box>

        <Box mt={2} mb={3} color="primary.main">
          <Typography variant="body1" component="p">
            {description}
          </Typography>
        </Box>

        {subText && (
          <Typography
            variant="body1"
            component="p"
            color={theme.palette.primary.dark}
          >
            {subText}
          </Typography>
        )}

        {extraSubText && (
          <Typography
            variant="body1"
            component="p"
            color={theme.palette.primary.dark}
          >
            {extraSubText}
          </Typography>
        )}

        {actionArea && (
          <Box position="absolute" bottom={4} right={0}>
            {actionArea}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ContentOverviewCard
