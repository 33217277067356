import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { UserAccountListing } from '../../../swagger'
import fullName from '../../../utils/fullName'
import { getCommaSeparatedList } from '../../../utils/getCommaSeparatedList'
import { LoadingContext } from '../../Context/LoadingContext'
import UserAvatar from '../../Elements/UserAvatar'
import TableHeaders from '../../Interfaces/TableHeaders'
import TableFooterPagination from '../../Pagination/TableFooterPagination'
import NoResultsFound from '../../Table/NoResultsFound'
import TableHeader from '../../Table/TableHeader'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import LoadingProgress from '../../Elements/LoadingProgress'

const UserAccountsSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const ClickableRow = styled(TableRow)({
  '&:hover': {
    cursor: 'pointer',
  },
})

export enum RoleStatus {
  CurrentRoles = 'currentRoles',
  PendingRoles = 'pendingRoles',
}

interface RoleNameWithCount {
  roleName: string
  pendingCount: number
  currentCount: number
  recentCount: number
}

interface UserAccountsSummaryTableProps {
  userAccounts: UserAccountListing[]
  pageSize: number
  totalCount: number
  handleRowsPerPage: (rowAmount: number) => void
  onPageChange: (newPage: number) => void
}

const firstFooterPage = 0
const baseTen = 10

const UserAccountsSummaryTable: React.FC<UserAccountsSummaryTableProps> = ({
  userAccounts,
  pageSize,
  onPageChange,
  totalCount,
  handleRowsPerPage,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useTheme()
  const availableLoadingIds = useLoadingIds()
  const tableHeaders: TableHeaders[] = [
    {
      label: '',
      align: 'left',
      id: 'firstLetterOfLastName',
    },
    {
      label: t('UserAccounts.FullName.Header', 'Full Name'),
      align: 'left',
    },
    {
      label: t('UserAccounts.Email.Header', 'Email'),
      align: 'left',
    },
    {
      label: t('UserAccounts.Roles.Header', 'Roles'),
      align: 'left',
    },
    {
      label: t('UserAccounts.Status.Header', 'Status'),
      align: 'left',
    },
  ]

  const [tableFooterPage, setTableFooterPage] = useState(0)

  const { loadingIds } = React.useContext(LoadingContext)

  useEffect(() => {
    setTableFooterPage(firstFooterPage)
  }, [onPageChange, totalCount])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageChange(newPage + 1)
    setTableFooterPage(newPage)
  }

  const onChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleRowsPerPage(parseInt(event.target.value, baseTen))
  }

  const toUserAccountPage = (userId: number) => {
    navigate(
      {
        pathname: `/admin/user-accounts/${userId}`,
      },
      {
        /** Navigation Options */
      }
    )
  }

  let pendingRoles: string[] = []
  let currentRoles: string[] = []
  let recentRoles: string[] = []

  const pushToCountArray = (
    count: number,
    roleName: string,
    roles: string[]
  ) => {
    const arrayOfStrings: string[] = roles
    if (count > 1) {
      // Push the role name and a parenthetical number to arrayOfStrings
      arrayOfStrings.push(`${roleName}(${count})`)
    } else if (count === 1) {
      // Push just the role name to arrayOfStrings
      arrayOfStrings.push(roleName)
    }

    // Return an array of these strings
    return arrayOfStrings
  }

  const formatRoleForCells = (opt: string, data: string[]) => {
    const addComma = `${data[data.length - 1]}, `
    if (opt === RoleStatus.PendingRoles) {
      pendingRoles[data.length - 1] = addComma
      return
    }
    currentRoles[data.length - 1] = addComma
    return
  }

  return (
    <UserAccountsSection
      aria-labelledby={t('UserAccounts.Section.AriaLabel', 'User Accounts')}
    >
      {loadingIds.has(availableLoadingIds.UserAccounts.fetchUsers) && (
        <LoadingProgress />
      )}

      {!loadingIds.has(availableLoadingIds.UserAccounts.fetchUsers) && (
        <TableContainer
          component={Paper}
          style={{
            position: 'relative',
          }}
        >
          <Table
            aria-labelledby={t('UserAccounts.Table.AriaLabel', 'User Accounts')}
          >
            <TableHead>
              <TableHeader tableHeaders={tableHeaders} />
            </TableHead>
            <TableBody>
              {totalCount > 0 ? (
                userAccounts?.map((row) => {
                  // Clear names
                  pendingRoles = []
                  currentRoles = []
                  recentRoles = []

                  const rolesMap = new Map<
                    number,
                    {
                      roleName: string
                      pendingCount: number
                      currentCount: number
                      recentCount: number
                    }
                  >()

                  row.roles.forEach((role) => {
                    const today = new Date()
                    const isPending = today < role.validFrom
                    const isCurrent =
                      role.validFrom <= today && today < role.validTo
                    const isRecent = role.validTo <= today
                    const initialRoleCount: RoleNameWithCount = {
                      roleName: role.name,
                      pendingCount: 0,
                      currentCount: 0,
                      recentCount: 0,
                    }
                    if (!rolesMap.has(role.roleKey)) {
                      rolesMap.set(role.roleKey, {
                        ...initialRoleCount,
                        ...(isPending && { pendingCount: 1 }),
                        ...(isCurrent && { currentCount: 1 }),
                        ...(isRecent && { recentCount: 1 }),
                      })
                    } else {
                      const roleCount = rolesMap?.get(
                        role.roleKey
                      ) as RoleNameWithCount
                      const { pendingCount, currentCount, recentCount } =
                        roleCount

                      if (isPending) {
                        rolesMap.set(role.roleKey, {
                          ...roleCount,
                          pendingCount: pendingCount + 1,
                        })
                      } else if (isCurrent) {
                        rolesMap.set(role.roleKey, {
                          ...roleCount,
                          currentCount: currentCount + 1,
                        })
                      } else if (isRecent) {
                        rolesMap.set(role.roleKey, {
                          ...roleCount,
                          recentCount: recentCount + 1,
                        })
                      }
                    }
                  })
                  return (
                    <ClickableRow
                      key={row.userKey}
                      onClick={() => toUserAccountPage(row.userKey)}
                    >
                      <TableCell>
                        <UserAvatar
                          familyName={fullName({
                            firstName: row.firstName,
                            lastName: row.lastName,
                          })}
                        />
                      </TableCell>
                      <TableCell color={theme.palette.primary.main}>
                        <Typography variant="body1" component="p">
                          {fullName({
                            firstName: row.firstName,
                            lastName: row.lastName,
                          })}
                        </Typography>
                      </TableCell>
                      <TableCell color={theme.palette.primary.main}>
                        <Typography variant="body1" component="p">
                          {row.username}
                        </Typography>
                      </TableCell>
                      <TableCell color={theme.palette.primary.main}>
                        <Typography variant="body1" component="p">
                          <>
                            {!!row.roles && row.roles.length
                              ? rolesMap.forEach((role) => {
                                  pendingRoles = pushToCountArray(
                                    role.pendingCount,
                                    role.roleName,
                                    pendingRoles
                                  )
                                  currentRoles = pushToCountArray(
                                    role.currentCount,
                                    role.roleName,
                                    currentRoles
                                  )
                                  recentRoles = pushToCountArray(
                                    role.recentCount,
                                    role.roleName,
                                    recentRoles
                                  )
                                })
                              : t(
                                  'UserAccountsSummaryTable.Roles.None',
                                  'None'
                                )}
                            <span style={{ fontStyle: 'italic' }}>
                              <>
                                {pendingRoles.length > 0 &&
                                (currentRoles.length > 0 ||
                                  recentRoles.length > 0)
                                  ? formatRoleForCells(
                                      RoleStatus.PendingRoles,
                                      pendingRoles
                                    )
                                  : ''}
                                {getCommaSeparatedList(pendingRoles)}
                              </>
                            </span>
                            <span>
                              <>
                                {currentRoles.length > 0 &&
                                recentRoles.length > 0
                                  ? formatRoleForCells(
                                      RoleStatus.CurrentRoles,
                                      currentRoles
                                    )
                                  : ''}
                                {getCommaSeparatedList(currentRoles)}
                              </>
                            </span>
                            <span
                              style={{
                                textDecoration: 'line-through',
                              }}
                            >
                              {getCommaSeparatedList(recentRoles)}
                            </span>
                          </>
                        </Typography>
                      </TableCell>
                      <TableCell color={theme.palette.primary.main}>
                        <Typography variant="body1" component="p">
                          {row.isDeactivated
                            ? t(
                                'UserAccounts.Table.Status.Deactivated',
                                'Deactivated'
                              )
                            : t('UserAccounts.Table.Status.Active', 'Active')}
                        </Typography>
                      </TableCell>
                    </ClickableRow>
                  )
                })
              ) : (
                <NoResultsFound />
              )}
            </TableBody>
            {totalCount > pageSize && (
              <TableFooterPagination
                count={totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                page={tableFooterPage}
                rowsPerPage={pageSize}
                colSpan={tableHeaders.length}
              />
            )}
          </Table>
        </TableContainer>
      )}
    </UserAccountsSection>
  )
}

export default UserAccountsSummaryTable
