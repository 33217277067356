import React from 'react'
import List from '@mui/material/List'
import Mail from '@mui/icons-material/Mail'
import Phone from '@mui/icons-material/Phone'
import Room from '@mui/icons-material/Room'
import { useTranslation } from 'react-i18next'
import { Address } from '../../../swagger'
import formatFullAddress from '../../Address/formatFullAddress'
import Header, { HeaderVariant } from '../../Elements/Header'
import ContactInfoListItem from './ContactInfoListItem'
import Box from '@mui/material/Box'
import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'

const ContactLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

interface ContactInfoProps {
  phone?: string
  personalEmail?: string
  officialEmail?: string
  address?: Address
}

const ContactInfo: React.FC<ContactInfoProps> = (props) => {
  const { phone, personalEmail, officialEmail, address } = props
  const { t } = useTranslation()
  const theme = useTheme()

  const missingAllData =
    !address &&
    [phone, personalEmail, officialEmail].every(
      (it) => (it?.trim() ?? '') === ''
    )
  if (missingAllData) return <span />

  const generateLink = (
    to?: string,
    listItemText?: string,
    dataTestId?: string
  ) => {
    return (
      <ContactLink data-testid={dataTestId} key={to} href={to}>
        {listItemText}
      </ContactLink>
    )
  }

  const addressLabel = formatFullAddress(address)

  const emailListItem = () => {
    const officialMailLink = !!officialEmail
      ? generateLink(
          `mailto:${officialEmail}`,
          !!personalEmail ? `, ${officialEmail}` : officialEmail, // Add trailing comma if there are multiple emails
          'officialEmail'
        )
      : undefined
    const personaMailLink = !!personalEmail
      ? generateLink(`mailto:${personalEmail}`, personalEmail, 'personalEmail')
      : undefined
    return (
      <ContactInfoListItem
        listItemText={personaMailLink}
        secondaryText={officialMailLink}
        icon={
          <Mail
            sx={{
              fill: theme.palette.primary.main,
            }}
            titleAccess={t('ContactInfo.AltText.MailIcon', 'Email Address')}
          />
        }
      />
    )
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={4}
      maxWidth={500}
      flexGrow={1}
    >
      <Header
        id="contactInformation"
        headerName={t('ContactInfo.Header', 'Contact Information')}
        component="h3"
        variant={HeaderVariant.Card}
      />
      <List>
        <ContactInfoListItem
          listItemText={generateLink(`tel:${phone}`, phone)}
          icon={
            <Phone
              sx={{
                fill: theme.palette.primary.main,
              }}
              titleAccess={t('ContactInfo.AltText.PhoneIcon', 'Phone Number')}
            />
          }
          to={`tel:${phone}`}
        />
        {(!!officialEmail || !!personalEmail) && emailListItem()}
        <ContactInfoListItem
          listItemText={addressLabel}
          icon={
            <Room
              sx={{
                fill: theme.palette.primary.main,
              }}
              titleAccess={t('ContactInfo.AltText.RoomIcon', 'Home Address')}
            />
          }
        />
      </List>
    </Box>
  )
}

export default ContactInfo
