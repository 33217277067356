import { Address } from '../../swagger'

const isBlank = (it?: string) => (it?.trim() ?? '') === ''

const enlist = (things: (string | undefined)[], separator = ', ') => {
  return things.filter((it) => !isBlank(it)).join(separator)
}

const formatFullAddress = (address?: Address): string => {
  /*
  given user has non-blank city or state, then location row is present with icon, i18n, labeling, as last row of contact info list. if city or state is blank, but the other is not, then only the non-blank one is shown. if both are present, then they are listed as ${city}, ${state}.

given user has non-blank address, city, state, AND user is viewing their own profile or the user viewing the profile is the director or direct report, then location row will list the full address. 123 Main St. City, ST 12345
  */
  const cityStateZip = enlist(
    [enlist([address?.city, address?.state]), address?.zip],
    ' '
  )
  const formattedAddress = enlist([
    address?.streetAddress1,
    address?.streetAddress2,
    cityStateZip,
  ])
  return formattedAddress
}

export default formatFullAddress
