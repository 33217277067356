import { Box, Table, TableBody, TableHead } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLicensingPaymentBreakdownCalculations } from '../../../../hooks/useLicensingPaymentBreakdownCalculations'
import { LicensingBill } from '../../../../swagger'
import {
  BreakDownLedgerPDFVariants,
  BreakdownLedgerForPDF,
} from './BreakdownLedgerForPDF'
import { styles } from './licensingBillingPdfStyles'
import { LicensingFeeSubTotalBreakdown } from './LicensingFeeSubTotalBreakdown'
import { SemesterOneSubtotalBreakdown } from './SemesterOneSubtotalBreakdown'
import { SemesterTwoSubtotalBreakdown } from './SemesterTwoSubtotalBreakdown'
import {
  TableHeaderForPDF,
  TableHeaderForPDFProps,
} from '../../../Table/TableHeaderForPDF'

export interface LicensingBreakdownProps {
  licensingBill: LicensingBill
}

export const LicensingPaymentBreakdownForPDF: React.FC<
  LicensingBreakdownProps
> = ({ licensingBill }) => {
  const { t } = useTranslation()

  const programName = licensingBill._for.programType ?? ''
  const currencyCode = licensingBill.total.currencyCode
  const isChallenge = programName.includes('Challenge')

  const tableHeaders: TableHeaderForPDFProps['tableHeaders'] = [
    {
      label: programName,
      align: 'left',
      cssProps: styles.tableHeaderCell,
    },
    {
      label: t('LicensingPayment.Breakdown.Header.FeeAmount', 'Fee Amount'),
      align: 'right',
      cssProps: styles.tableHeaderCell,
    },
    {
      label: t(
        'LicensingPayment.Breakdown.Header.NumberOfStudents',
        '# of Students'
      ),
      align: 'right',
      cssProps: styles.tableHeaderCell,
    },
    {
      label: t('LicensingPayment.Breakdown.Header.Subtotal', 'Subtotal'),
      align: 'right',
      cssProps: styles.tableHeaderCell,
    },
  ]

  const {
    balance,
    licensingFeeAdditionalStudent,
    licensingFeeFirstStudent,
    licensingFeeSubtotal,
    owed,
    paid,
    semester1AdditionalStudent,
    semester1FirstStudent,
    semester1Subtotal,
    semester2AdditionalStudent,
    semester2FirstStudent,
    semester2Subtotal,
  } = useLicensingPaymentBreakdownCalculations({ licensingBill })

  return (
    <Box style={styles.table}>
      <Table style={styles.licensingPaymentBreakdownTable}>
        <TableHead>
          <TableHeaderForPDF tableHeaders={tableHeaders} />
        </TableHead>

        <TableBody>
          <LicensingFeeSubTotalBreakdown
            licensingFeeSubtotal={licensingFeeSubtotal}
            licensingFeeFirstStudent={licensingFeeFirstStudent}
            licensingFeeAdditionalStudent={licensingFeeAdditionalStudent}
          />
          <SemesterOneSubtotalBreakdown
            semester1AdditionalStudent={semester1AdditionalStudent}
            semester1FirstStudent={semester1FirstStudent}
            semester1Subtotal={semester1Subtotal}
            isChallenge={isChallenge}
          />
          <SemesterTwoSubtotalBreakdown
            semester2Subtotal={semester2Subtotal}
            semester2FirstStudent={semester2FirstStudent}
            semester2AdditionalStudent={semester2AdditionalStudent}
            isChallenge={isChallenge}
          />
          <BreakdownLedgerForPDF
            variant={BreakDownLedgerPDFVariants.Balance}
            totalOwed={owed}
            totalPaid={paid}
            balance={balance}
            currencyCode={currencyCode}
            programName={programName}
          />
        </TableBody>
      </Table>
    </Box>
  )
}
