import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EventEventTypeEnum,
  UserAccountListing,
  FetchEventsResponse,
} from '../../swagger/models'
import EventSummaryTable, { labelForSortOption } from './EventSummaryTable'
import Header from '../Elements/Header'
import EventCardMobile from '../Card/EventCardMobile'
import SearchBar from '../Search/SearchBar'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import TodaysEventCard from '../Card/TodaysEventCard'
import { Page } from '../Elements/PageMargins'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { createSpacetime, EventFilter, getTodaysEarliestEvent } from './Events'
import spacetime from 'spacetime'
import { TFunction } from 'i18next'
import Tooltip from '@mui/material/Tooltip'
import DateField, { DateFieldVariant } from '../Elements/DateField'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'
import { Box } from '@mui/material'
import { EventSortOptions } from './EventEnums'
import { Help } from '@mui/icons-material'
import fullName from '../../utils/fullName'
import DebouncedAutocomplete from '../Elements/DebouncedAutocomplete'

const labelForEventTypesFilter = (sortOption: string, t: TFunction) => {
  switch (sortOption) {
    case EventTypes.InformationMeeting:
      return t('Events.EventTypes.InformationMeeting', 'Information Meeting')
    case EventTypes.OpenHouse:
      return t('Events.EventTypes.OpenHouse', 'Open House')
    case EventTypes.Practicum:
      return t('Events.EventTypes.Practicum', 'Practicum')
    default:
      return t('Events.EventTypes.AllEventTypes', 'All Event Types')
  }
}

const StyledHeader = styled(Header)(({ theme }) => ({
  margin: theme.spacing(3, 'auto'),
  padding: 0,
}))

interface EventsTabProps {
  events: FetchEventsResponse[]
  isLoading: boolean
  minDateFilter: string
  dateToFilter: string
  pageSize: number
  totalCount: number
  resetTablePagination: (newPage: number) => void
  tableFooterPage: number
  handleEventFilterChange: <K extends keyof EventFilter>(
    filter: K,
    value: EventFilter[K],
    isMobile?: boolean,
    sortValue?: string
  ) => void
  eventCreators: UserAccountListing[]
  updateSearchResults: (s: string) => void
  resetEventCreators: () => void
}

const EventTypes = {
  AllEvents: 'All Event Types',
  ...EventEventTypeEnum,
}

export const EventsTab: React.FunctionComponent<EventsTabProps> = (props) => {
  const {
    events,
    isLoading,
    minDateFilter,
    dateToFilter,
    pageSize,
    totalCount,
    resetTablePagination,
    tableFooterPage,
    handleEventFilterChange,
    eventCreators,
    updateSearchResults,
    resetEventCreators,
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'))
  const [filterType, setFilterType] = useState('')
  const [paramValue, setParamValue] = useState('')

  const todayEvent = useMemo(() => getTodaysEarliestEvent(events), [events])

  let isNextEventToday = true

  if (todayEvent) {
    isNextEventToday = spacetime
      .now()
      .isSame(createSpacetime(todayEvent), 'day')
  }

  const translationText = t(
    'Events.AddTittleToolTip.TittleTranslation',
    'Search for events by date, past or future, to see all events on that date and after'
  )

  const dateOnOrAfterFilter = () => (
    <Box display="flex" alignItems="center">
      <DateField
        dateVariant={DateFieldVariant.EventsByDate}
        inputProps={{
          min: `${minDateFilter}`,
          ref: React.createRef(),
        }}
        variant="filled"
        type="date"
        fullWidth={true}
        onChangeForParent={handleDateChange}
        value={dateToFilter}
        onKeyDown={handleOnkeyDown}
      />
      <Tooltip title={translationText}>
        <Help />
      </Tooltip>
    </Box>
  )

  const handleDateChange = (Event: React.ChangeEvent<HTMLInputElement>) => {
    handleEventFilterChange('fromDate', Event.target.value, isMobileOrSmaller)
  }
  const handleOnkeyDown = (Event: React.KeyboardEvent<HTMLInputElement>) => {
    Event.preventDefault()
  }

  const handleViewMoreEvents = () => {
    handleEventFilterChange('pageSize', pageSize + 10)
  }

  const toEventDetailsPage = (id: number, eventName: string) => {
    navigate(
      {
        pathname: `/events/event-details/${id}`,
      },
      {
        state: { eventName },
      }
    )
  }

  const handleDropDownSelection = (
    selection: string,
    id: string,
    selectedOptionId: string | number
  ) => {
    switch (id) {
      case 'filterByEventType':
        setParamValue(selection)
        setFilterType('eventType')
        break
      case 'filterByEventCreator':
        setParamValue(selection)
        setFilterType('eventCreator')
        // When click on the clear icon, set the eventCreators array back to empty
        if (selectedOptionId === 'clear') {
          resetEventCreators()
        }
        break
    }
  }

  const handleChangeAutocomplete = (
    selection: UserAccountListing | null,
    reason: string
  ) => {
    const selectedValue = selection ? selection.username : 'All Event Creators'

    handleDropDownSelection(
      selectedValue,
      'filterByEventCreator',
      reason as string
    )
  }

  const handleSearch = (searchText: string) => {
    setFilterType('search')
    setParamValue(searchText)
  }

  const getEventCreatorsOptionLabel = (option: UserAccountListing) =>
    fullName({
      firstName: option.firstName,
      lastName: option.lastName,
    })

  const getEventCreatorsRenderOptions = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: UserAccountListing
  ) => (
    <Box component="li" {...props} key={option.userKey}>
      {option.firstName} {option.lastName}
    </Box>
  )

  useEffect(() => {
    handleEventFilterChange(
      filterType as keyof EventFilter,
      paramValue,
      isMobileOrSmaller
    )
  }, [handleEventFilterChange, isMobileOrSmaller, filterType, paramValue])

  return (
    <Page>
      {isMobileOrSmaller ? (
        <>
          <StyledHeader
            id="todayEventHeader"
            headerName={
              isNextEventToday
                ? t('Events.Card.Header.Today', "Today's Event")
                : t('Events.Card.Header.Upcoming', 'Upcoming Event')
            }
          />

          {todayEvent !== undefined ? (
            <EventCardMobile
              event={todayEvent}
              todaysEvent={true}
              isLoading={isLoading}
            />
          ) : null}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: theme.spacing(0, 'auto'),
            }}
          >
            <SearchBar handleSearch={handleSearch} />
          </Box>
          <section>
            <Box display="flex" justifyContent={'space-between'}>
              <DropDown
                id="filterByEventType"
                menuOptions={Object.values(EventTypes).map((option) => {
                  return {
                    name: labelForEventTypesFilter(option, t),
                    id: option,
                  }
                })}
                defaultValue={t('Events.FilterOption.EventTypes', 'All Events')}
                handleSelection={handleDropDownSelection}
                formControlProps={{
                  width: `calc(100% - ${theme.spacing(2)})`,
                  marginRight: theme.spacing(2),
                  [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    margin: theme.spacing(2),
                  },
                }}
                variant={DropDownVariant.SortAndFilter}
              />
            </Box>
            <Box display="flex" justifyContent={'space-between'}>
              <DebouncedAutocomplete
                id={'filterByEventCreator'}
                options={eventCreators}
                handleChange={handleChangeAutocomplete}
                noOptionsTextValue={t(
                  'Events.CreatorSearch.NoResults',
                  'No Event creator with the given name found'
                )}
                optionsLabel={getEventCreatorsOptionLabel}
                renderOptions={getEventCreatorsRenderOptions}
                updateSearchResults={updateSearchResults}
                textFieldLabelValue={t(
                  'Event.Label.EventCreator',
                  'Filter By Event Creator'
                )}
                textFieldStyle={{}}
              />
            </Box>
            <Box textAlign="center">{dateOnOrAfterFilter()}</Box>
          </section>
          <StyledHeader
            id="eventsHeader"
            headerName={t('Events.Card.Header.Events', 'Events')}
          />

          <Box textAlign={'center'}>
            <DropDown
              fullWidth
              cssProps={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: `calc(100vw - ${theme.spacing(4)})`,
              }}
              id="sort"
              menuOptions={Object.values(EventSortOptions).map((option) => {
                return {
                  name: labelForSortOption(option, t),
                  id: option,
                }
              })}
              defaultValue={t('Events.SortOption.Sort', 'Sort')}
              handleSelection={handleDropDownSelection}
              variant={DropDownVariant.SortAndFilter}
            />
          </Box>

          {events.map((event) =>
            event !== undefined ? (
              <EventCardMobile
                key={event.eventKey}
                event={event}
                isLoading={isLoading}
              />
            ) : null
          )}
          {/** Hide button once we are viewing all available Events */}
          {totalCount > pageSize ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: theme.spacing(6, 0),
              }}
            >
              <OutlinedButton
                data-testid="viewMoreButton"
                id="viewMoreDraftEvents"
                onClick={handleViewMoreEvents}
                variant={OutlinedButtonVariant.ViewMore}
              />
            </Box>
          ) : null}
        </>
      ) : (
        <>
          {todayEvent && (
            <TodaysEventCard
              isLoading={isLoading}
              onClick={() =>
                toEventDetailsPage(
                  (todayEvent.eventKey as number) ?? '',
                  todayEvent.name
                )
              }
              todaysEvent={todayEvent}
              headerLabel={
                isNextEventToday
                  ? t('Events.Card.Header.Today', "Today's Event")
                  : t('Events.Card.Header.Upcoming', 'Upcoming Event')
              }
            />
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SearchBar handleSearch={handleSearch} />
            <Box
              sx={{
                margin: theme.spacing(0, 3),
                padding: theme.spacing(1),
              }}
            >
              <DropDown
                id="filterByEventType"
                menuOptions={Object.values(EventTypes).map((option) => {
                  return {
                    name: labelForEventTypesFilter(option, t),
                    id: option,
                  }
                })}
                defaultValue={t(
                  'Events.FilterOption.EventTypes',
                  'All Event Types'
                )}
                handleSelection={handleDropDownSelection}
                variant={DropDownVariant.SortAndFilter}
              />
            </Box>
            <Box mr={theme.spacing(3)}>
              <DebouncedAutocomplete
                id={'filterByEventCreator'}
                options={eventCreators}
                handleChange={handleChangeAutocomplete}
                noOptionsTextValue={t(
                  'Events.CreatorSearch.NoResults',
                  'No Event creator with the given name found'
                )}
                optionsLabel={getEventCreatorsOptionLabel}
                renderOptions={getEventCreatorsRenderOptions}
                updateSearchResults={updateSearchResults}
                textFieldLabelValue={t(
                  'Event.Label.EventCreator',
                  'Filter By Event Creator'
                )}
              />
            </Box>
            <Box
              position={'relative'}
              textAlign="center"
              bottom={theme.spacing(1.2)}
            >
              {dateOnOrAfterFilter()}
            </Box>
          </Box>
          <EventSummaryTable
            events={events}
            isLoading={isLoading}
            pagination={true}
            pageSize={pageSize}
            totalCount={totalCount}
            resetTablePagination={resetTablePagination}
            tableFooterPage={tableFooterPage}
            handleEventFilterChange={handleEventFilterChange}
          />
        </>
      )}
    </Page>
  )
}

export default EventsTab
