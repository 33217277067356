import React from 'react'
import ConfirmationModal from './ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { Typography, TypographyProps } from '@mui/material'
import { OutlinedButtonVariant } from '../Buttons/OutlinedButton'

interface ConfirmWithButtonsModalProps {
  isOpen: boolean
  title: string
  question: string
  confirmVariant: ContainedButtonVariant
  cancelVariant:
    | TextButtonVariant
    | ContainedButtonVariant
    | OutlinedButtonVariant
  onConfirm: () => void
  onCancel: () => void
  questionProps?: TypographyProps
}

const ConfirmWithButtonsModal: React.FC<ConfirmWithButtonsModalProps> = ({
  isOpen,
  title,
  question,
  confirmVariant,
  cancelVariant,
  onConfirm,
  onCancel,
  questionProps,
}) => {
  return (
    <ConfirmationModal
      dialogTitle={title}
      dialogContent={<Typography {...questionProps}>{question}</Typography>}
      dialogActions={
        <ActionButtons
          primaryButtonLabel={confirmVariant}
          secondaryButtonLabel={cancelVariant}
          secondaryClick={onCancel}
        />
      }
      isOpen={isOpen}
      handleFormSubmit={onConfirm}
    />
  )
}

export default ConfirmWithButtonsModal
