import { GradingScaleWeight } from '../swagger'

export function removeCourseWeight(
  courseName: string,
  gradingScaleWeights?: GradingScaleWeight[]
): string {
  for (const { weightName } of gradingScaleWeights ?? []) {
    const weightString = `${weightName}: `
    if (courseName.startsWith(weightString))
      return courseName.replace(weightString, '')
  }
  return courseName
}
