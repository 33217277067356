import { ContentAsset } from '../../../api/swagger'

export interface Week {
  [key: string]: ContentAsset[]
}

interface CycleOrSemester {
  [key: string]: Week
}

export const groupByCycleOrSemesterAndWeek = (
  assetArray: ContentAsset[],
  isChallenge: boolean
): CycleOrSemester => {
  return assetArray.reduce((result, curr) => {
    // if no cycle, week, or semester # then do not include the asset
    if (
      !curr.cycle?.toString() ||
      !curr.week?.toString() ||
      !curr.semester?.toString()
    ) {
      return result
    }

    const currentOuterIndex = isChallenge
      ? curr.semester.toString()
      : curr.cycle.toString()
    const currentWeekIndex = curr.week.toString()

    // if cycle/semester # already exists as a key in our result obj then get a reference to it, else add it
    result[isChallenge ? curr.semester : curr.cycle] =
      result[currentOuterIndex] || {}

    const currentOuter = result[currentOuterIndex]
    if (!currentOuter[currentWeekIndex]) {
      // week # does not exist in this cycle so we add it
      currentOuter[currentWeekIndex] = []
    }
    // add content to this week # in this cycle
    currentOuter[currentWeekIndex].push(curr)

    return result
  }, {} as CycleOrSemester)
}

/**
 *
 * @param learningPathTitle
 * @param listingPriority
 * @returns boolean indicating if it is an entry point asset, true when it has a title and priority of one, otherwise false
 */
export const isFeaturedLearningPathAsset = ({
  learningPathTitle,
  listingPriority,
}: {
  learningPathTitle?: string
  listingPriority?: number
}): boolean => {
  return !!learningPathTitle && listingPriority === 1
}
