import { Font, StyleSheet } from '@react-pdf/renderer'
import { colors } from '../../../../stylesheet'

const headerFontSize = 12
const bodyFontSize = 10
const headerFontWeight = 'bold'
export const fontFamily = 'Open Sans'
export const borderDashed = '1px dashed lightgrey'

/**
 * To allow the PDF to use a regular and bold font weight,
 * we need to register the font with react-pdf.
 *
 * Documentation: https://react-pdf.org/fonts
 */
Font.register({
  family: fontFamily,
  fonts: [
    {
      src: '/fonts/openSansRegular.ttf',
    },
    {
      src: '/fonts/openSans600.ttf',
      fontWeight: 600,
    },
  ],
})

/**
 * react-pdf package does not support all CSS properties
 * and for some reason, using the styles defined in the theme
 * causes the PDF to not render properly.
 *
 * The react-pdf documentation recommends using the StyleSheet.create
 * method to define styles for the PDF and mix with inline styles
 * in cases where styles are based on props or dynamic data.
 *
 * Documentation: https://react-pdf.org/styling
 */
export const styles = StyleSheet.create({
  page: {
    paddingVertical: 25,
    paddingHorizontal: 20,
  },
  logo: {
    maxWidth: 150,
    height: 'auto',
    marginBottom: 10,
  },
  table: {
    border: `1px solid ${colors.lightBlue}`,
    padding: 2,
  },
  tableHeaderCell: {
    backgroundColor: colors.lightBlue,
    color: colors.royalBlue.main,
    fontSize: headerFontSize,
    fontWeight: headerFontWeight,
    paddingHorizontal: 4,
    paddingVertical: 8,
  },
  tableSubHeaderCell: {
    color: colors.royalBlue.main,
    paddingBottom: 3,
    paddingTop: 2,
    borderBottom: borderDashed,
  },
  tableBodyCell: {
    fontSize: bodyFontSize,
    padding: 4,
  },
  programLicensingPaymentBreakdownContainer: {
    marginBottom: 5,
  },
  billToContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 8,
  },
  billToLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily,
    marginRight: 5,
  },
  billToValue: {
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily,
  },
  communityName: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  licensingPaymentBreakdownTable: {
    border: '1px solid white',
  },
})
