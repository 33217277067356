import React from 'react'
import { useTheme } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Header from './Header'

export interface Breadcrumb {
  label?: string
  onClick?: () => void
}
interface DynamicBreadcrumbsProps {
  /** List of breadcrumbs to map into clickable links. */
  breadcrumbs: Breadcrumb[]
  /** Determines whether to use default breadcrumb styling or a header style */
  useHeaderStyles?: boolean
}

const DynamicBreadcrumbs: React.FC<DynamicBreadcrumbsProps> = ({
  breadcrumbs,
  useHeaderStyles,
}) => {
  const theme = useTheme()

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        ...(!useHeaderStyles && {
          color: theme.palette.primary.light,
          textTransform: 'capitalize',
          marginBottom: theme.spacing(3),
        }),
      }}
      separator={useHeaderStyles ? <Header headerName="/" /> : '/'}
    >
      {breadcrumbs.map(({ label, onClick }) => {
        return !!label ? (
          useHeaderStyles ? (
            <Header key={label} headerName={label} component={'h2'} />
          ) : (
            <Typography key={label} variant="button">
              {!!onClick ? (
                <Link
                  color="inherit"
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={onClick}
                  role="link"
                >
                  {label}
                </Link>
              ) : (
                label
              )}
            </Typography>
          )
        ) : null
      })}
    </Breadcrumbs>
  )
}

export default DynamicBreadcrumbs
