/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  CoordinatorLicensingBilling,
  CoordinatorLicensingBillingFromJSON,
  CoordinatorLicensingBillingToJSON,
  LicensingInternationalPayment,
  LicensingInternationalPaymentFromJSON,
  LicensingInternationalPaymentToJSON,
} from './'

/**
 * Contains licensing billing and payment information for coordinator directors.
 * @export
 * @interface FetchBillingResponseLicensingDirector
 */
export interface FetchBillingResponseLicensingDirector {
  /**
   * An array of licensing billing records for coordinator directors.
   * @type {Array<CoordinatorLicensingBilling>}
   * @memberof FetchBillingResponseLicensingDirector
   */
  billing?: Array<CoordinatorLicensingBilling>
  /**
   * An array of international licensing payments that have been applied to the coordinator.
   * @type {Array<LicensingInternationalPayment>}
   * @memberof FetchBillingResponseLicensingDirector
   */
  internationalPayments?: Array<LicensingInternationalPayment>
}

export function FetchBillingResponseLicensingDirectorFromJSON(
  json: any
): FetchBillingResponseLicensingDirector {
  return FetchBillingResponseLicensingDirectorFromJSONTyped(json, false)
}

export function FetchBillingResponseLicensingDirectorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FetchBillingResponseLicensingDirector {
  if (json === undefined || json === null) {
    return json
  }
  return {
    billing: !exists(json, 'billing')
      ? undefined
      : (json['billing'] as Array<any>).map(
          CoordinatorLicensingBillingFromJSON
        ),
    internationalPayments: !exists(json, 'internationalPayments')
      ? undefined
      : (json['internationalPayments'] as Array<any>).map(
          LicensingInternationalPaymentFromJSON
        ),
  }
}

export function FetchBillingResponseLicensingDirectorToJSON(
  value?: FetchBillingResponseLicensingDirector | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    billing:
      value.billing === undefined
        ? undefined
        : (value.billing as Array<any>).map(CoordinatorLicensingBillingToJSON),
    internationalPayments:
      value.internationalPayments === undefined
        ? undefined
        : (value.internationalPayments as Array<any>).map(
            LicensingInternationalPaymentToJSON
          ),
  }
}
