import React, { useCallback, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import BackToLoginButton from '../Buttons/BackToLoginButton'
import EmailField from './EmailField'
import { validateEmail } from '../../helpers/validateEmail'
import { useBeacon, openBeacon, prefillBeacon } from '../../api/beacon'
import Mountains from '../../Images/mountains.png'
import TextButton, { TextButtonVariant } from '../Buttons/TextButton'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import VerificationCodeCard from '../Card/VerificationCodeCard'
import BrandCard from '../Card/BrandCard'
import useEmailPasswordStates from '../../hooks/useEmailPasswordStates'
import { useNavigate, useLocation } from 'react-router'
import { extractedErrorObject } from '../../api/swagger'
import { changePassword, resetPassword } from '../../api/authentication'
import { OperationIds } from '../../swagger/operationIdEnum'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { Box } from '@mui/material'

const emailVerificationCodeLength = 16
const heightOfResetPasswordCard = 550

const filename = 'ResetPassword'

enum ResetPasswordStep {
  EnterEmail,
  EmailVerification,
}

const ResetPassword: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const params = new URLSearchParams(state).get('params')

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [emailVerificationToken, setEmailVerificationToken] = useState('')
  const resetPasswordLoadingId = `${filename}${OperationIds.ResetPassword}`
  const { removeLoadingIds } = React.useContext(LoadingContext)

  const [isLoading, setIsLoading] = useState(false)

  const [step, setStep] = useState<ResetPasswordStep>(
    ResetPasswordStep.EnterEmail
  )

  useBeacon(process.env.REACT_APP_HELP_SCOUT_BEACON_LOGIN_ID)

  const handleOpenPreFilledBeacon = () => {
    prefillBeacon(
      '',
      '',
      t('ResetPassword.Beacon.Prefill.Email', 'Email (for us to respond to)'),
      t(
        'ResetPassword.Beacon.Prefill.Text',
        'Hello, I seem to have forgotten my login email...'
      )
    )

    openBeacon()
  }

  const handleSendResetLink = async () => {
    if (validateEmail(email)) {
      try {
        await resetPassword(email)
      } catch (e) {
        const errorObj = (await extractedErrorObject(e)) ?? {
          code: 'Unknown',
          message:
            (e as unknown as Error).message ??
            t(
              'ResetPassword.SendResetLink.Error',
              'Something went wrong while sending reset link.'
            ),
        }
        setError(errorObj.message)
      }

      setStep(ResetPasswordStep.EmailVerification)
    } else {
      setIsEmailValid(false)
    }
  }

  const setEmailVerificationTokenForParent = (token: string) => {
    setError('')
    setEmailVerificationToken(token)
  }

  const handlePasswordResetConfirmVerificationCode = async () => {
    //FIXME: handle Confirm Verification Code in https://projekt202.atlassian.net/browse/CCP1-2132
    handleNext()
  }

  const backToEnterEmail = () => {
    // Go back to the Enter Email step
    setStep((step) => step - 1)
    // Reset email validation
    setIsEmailValid(true)
    // Don't retain email verification token
    setEmailVerificationToken('')
    // Clear any errors
    setError('')
    // Reset auth info back to original state
    setAuthenticationInfo({
      ...authenticationInfo,
      password: '',
      passwordConfirm: '',
    })
    // Reset password validation
    setPasswordInvalid?.(false)
    setPasswordsDontMatch?.(false)
    setEmptyPasswordConfirmField?.(false)
    setEmptyPasswordField?.(false)
  }

  const [authenticationInfo, setAuthenticationInfo] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
  })

  const handleAuthInfoUpdate = (id: string, value: string) => {
    setAuthenticationInfo({
      ...authenticationInfo,
      [id]: value,
    })
  }

  const handleKeyboardEvent = async () => {
    if (step === ResetPasswordStep.EmailVerification) {
      setPasswordsChecked(true)
    }
  }

  const emailPasswordStates = useEmailPasswordStates({
    includePasswordConfirm: true,
    handleAuthInfoUpdate,
    authenticationInfo,
    handleClickFromParent: async () => void {},
    handleKeyboardEvent,
  })

  const { passwordFieldProps, emailFieldStates } = emailPasswordStates

  const {
    password,
    emptyPasswordField,
    emptyPasswordConfirmField,
    passwordsDontMatch,
    passwordIsEmptyOrInvalid,
    setPasswordInvalid,
    setPasswordsDontMatch,
    setEmptyPasswordConfirmField,
    setEmptyPasswordField,
  } = passwordFieldProps

  const { handleClick } = emailFieldStates

  const inputFieldsEmpty = emptyPasswordField || emptyPasswordConfirmField
  const passwordIsValid = !passwordsDontMatch && !passwordIsEmptyOrInvalid

  const [passwordsChecked, setPasswordsChecked] = useState(false)

  const handleNext = () => {
    // Validate password states
    handleClick()

    // Set passwords checked so our password states are updated and we can verify if we move to login
    setPasswordsChecked(true)
  }

  const errorMessage = t(
    'ResetPassword.Error.ChangingPassword',
    'Error occurred changing password.'
  )

  const handleBackToLogin = useCallback(() => {
    navigate({
      pathname: params ? `${params}` : '/login',
    })
  }, [navigate, params])

  const onClickResendCode = async () => {
    try {
      await resetPassword(email)
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'ResetPassword.SendResetLink.Error',
            'Something went wrong while resending reset link.'
          ),
      }
      setError(errorObj.message)
    } finally {
      removeLoadingIds([resetPasswordLoadingId])
    }
  }

  useLoadingContext({
    asyncFunction: onClickResendCode,
    loadingId: resetPasswordLoadingId,
  })

  useEffect(() => {
    if (passwordsChecked) {
      const moveToLogin = async () => {
        if (passwordIsValid && !inputFieldsEmpty && !!emailVerificationToken) {
          setError('')
          try {
            setIsLoading(true)
            const { success } = await changePassword({
              resetToken: emailVerificationToken,
              newPassword: password,
            })
            setIsLoading(false)

            if (success) {
              // TODO: Set snackbar success with successfully updated password https://projekt202.atlassian.net/browse/CCP1-2148
              // Go to login (or invite if that is where they came from) so the user uses the new password
              handleBackToLogin()
            } else {
              setError(errorMessage)
              setPasswordsChecked(false)
              setIsLoading(false)
            }
          } catch (e) {
            const errorObj = (await extractedErrorObject(e)) ?? {
              code: 'Unknown',
              message: (e as unknown as Error).message ?? errorMessage,
            }
            setError(errorObj.message)
            setPasswordsChecked(false)
            setIsLoading(false)
          }
        } else {
          setPasswordsChecked(false)
          setError('')
        }
      }
      moveToLogin()
    }
  }, [
    handleBackToLogin,
    passwordsChecked,
    passwordIsValid,
    inputFieldsEmpty,
    errorMessage,
    emailVerificationToken,
    password,
  ])

  if (step === ResetPasswordStep.EnterEmail) {
    return (
      <Box
        sx={{
          backgroundImage: `url(${Mountains})`,
          backgroundColor: theme.palette.customBackground.searchbar.main,
          backgroundRepeat: 'repeat-x',
          backgroundPosition: 'bottom',
          width: '100vw !important',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BrandCard
          minimumScreenHeight={heightOfResetPasswordCard}
          additionalHeaderContent={
            <Box
              sx={{
                color: theme.palette.primary.main,
                width: '88%',
                margin: '0 auto',
              }}
            >
              <Typography margin={theme.spacing(2)} variant="h5">
                {t('resetPassword.title', 'Reset Password')}
              </Typography>
              <Typography variant="body1">
                {t(
                  'ResetPassword.Instructions',
                  'Enter the email associated with your account and we’ll email you a code to reset your password.'
                )}
              </Typography>
            </Box>
          }
          cardContent={
            <EmailField
              value={email}
              error={!isEmailValid}
              helperText={`${
                !isEmailValid
                  ? t(
                      'ResetPassword.EmailField.InvalidEmail',
                      'Email address is formatted incorrectly.'
                    )
                  : ''
              }`}
              inputProps={{}}
              label={t('login.emailField.label', 'Email')}
              onChange={(event) => setEmail(event.target.value)}
              onBlur={(event) => setEmail(event.target.value.trim())}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  handleSendResetLink()
                }
              }}
              InputProps={{}}
            />
          }
          cardActions={
            <>
              <ContainedButton
                id="sendResetLink"
                variant={ContainedButtonVariant.SendResetLink}
                onClick={handleSendResetLink}
                css={{
                  marginBottom: theme.spacing(2),
                }}
                fullWidth
              />
              <TextButton
                id="forgotEmailButton"
                variant={TextButtonVariant.ForgotEmail}
                onClick={handleOpenPreFilledBeacon}
                css={{
                  marginBottom: theme.spacing(2),
                }}
                fullWidth
              />
              <BackToLoginButton onClick={handleBackToLogin} />
            </>
          }
        />
      </Box>
    )
  }

  const isVerificationInputLongEnough =
    emailVerificationToken.length >= emailVerificationCodeLength

  if (step === ResetPasswordStep.EmailVerification) {
    return (
      <VerificationCodeCard
        error={error}
        setEmailVerificationTokenForParent={setEmailVerificationTokenForParent}
        handleSignUpConfirmVerificationCode={
          handlePasswordResetConfirmVerificationCode
        }
        isVerificationInputLongEnough={isVerificationInputLongEnough}
        goToPrevStep={backToEnterEmail}
        emailVerificationToken={emailVerificationToken}
        email={email}
        passwordReset
        passwordFieldProps={passwordFieldProps}
        isLoading={isLoading}
        onClickResendCode={onClickResendCode}
        resendLoadingId={resetPasswordLoadingId}
      />
    )
  }

  return null
}

export default ResetPassword
