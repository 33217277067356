import Card from '@mui/material/Card'
import React, {
  FormEvent,
  useEffect,
  useMemo,
  useState,
  useRef,
  ReactNode,
  ReactElement,
} from 'react'
import { useTranslation } from 'react-i18next'
import { TextButtonVariant } from '../Buttons/TextButton'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import Header, { HeaderVariant } from '../Elements/Header'
import CardFormHeader from './CardFormHeader'
import { todaysDateFormattedLocal } from '../../utils/todaysDateFormatted'
import {
  timeStringToNumber,
  timezoneTitleCase,
} from '../../utils/timeFormatUtilities'
import { useNavigate, useLocation } from 'react-router'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Address, EventEventTypeEnum } from '../../swagger'
import { events, extractedErrorObject } from '../../api/swagger'
import useValidationMessages from '../../hooks/useValidationMessages'
import RegistrantsTable from '../Events/RegistrantsTable'
import { Event } from '../../swagger'
import spacetime from 'spacetime'
import { Registrant } from '../../swagger/models/Registrant'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { validateEmailText } from '../../helpers/validateEmail'
import ErrorAlert from '../Alerts/ErrorAlert'
import { useAuth } from '../Routes/AuthProvider'
import ConfirmationModal from '../Modals/ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import { FieldValidity } from '../Interfaces/FieldValidity'
import Box from '@mui/material/Box'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { CardContent, SelectChangeEvent } from '@mui/material'
import { styled } from '@mui/system'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import LoadingProgress from '../Elements/LoadingProgress'
import {
  EventFormCardVariants,
  EventFormValidationMessageTypes,
} from '../Events/EventEnums'
import { dateToDashString, utcDateString } from '../../utils/dateUtility'
import { reinterpretYearMonthDayAsLocalTime } from '../../utils/reinterpretYearMonthDayAsLocalTime'
import EventInformationFields from '../Events/EventInformationFields'
import EventLocationFields from '../Events/EventLocationFields'
import { OutlinedButtonVariant } from '../Buttons/OutlinedButton'
import { TFunction } from 'i18next'

export enum EventLocationTypes {
  Local = 'Local',
  Global = 'Global',
}

export enum EventRegistrationTypes {
  Open = 'Open',
  Closed = 'Closed',
}

export const EventDetailsCard = styled(Card)(({ theme }) => ({
  color: theme.palette.primary.main,
}))
interface EventFormCardProps {
  variant: EventFormCardVariants
  eventDetails?: Event
  eventKey?: number
  updateEventName?: (eventName: string) => void
}
export interface EventFormValidFields {
  eventName: FieldValidity
  eventType: FieldValidity
  registration: FieldValidity
  description: FieldValidity
  additionalInformation: FieldValidity
  address: FieldValidity
  eventLocationType: FieldValidity
  onlineEventUrl: FieldValidity
  startDate: FieldValidity
  endDate: FieldValidity
  startTime: FieldValidity
  endTime: FieldValidity
  timezone: FieldValidity
  contactEmail: FieldValidity
  maxCapacity: FieldValidity
  hostName: FieldValidity
  registrationCloseDate: FieldValidity
}
const descriptionMaximumCharacters = 2000
const additionalInformationMaximumCharacters = 2000

type GetValidationMessages = {
  t: TFunction
}
 
export const getValidationMessages = ({ t }: GetValidationMessages):{
  field:string,
  message:string
}[] => [
  {
    field: EventFormValidationMessageTypes.Default,
    message: t(
      'Events.EventForm.ErrorMessage',
      'Something went wrong. Please make sure you have filled out the required fields.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.EventName,
    message: t(
      'Events.EventForm.ValidationMessage.EventName',
      'Event Name cannot be empty.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.EventType,
    message: t(
      'Events.EventForm.ValidationMessage.EventType',
      'Please select an Event Type.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.Registration,
    message: t(
      'Events.EventForm.ValidationMessage.Registration',
      'Please select a Registration Status.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.MaxCapacity,
    message: t(
      'Events.EventForm.ValidationMessage.MaxCapacity',
      'Max Capacity must be between 1 and 999.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.EventDescription,
    message: t(
      'Events.EventForm.ValidationMessage.EventDescription',
      'Event Description cannot be empty.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.EventDescriptionMaxCapacity,
    message: t(
      'Events.EventForm.ValidationMessage.EventDescriptionMaxCapacity',
      `Event Description cannot exceed {{descriptionMaximumCharacters}} characters.`,
      { descriptionMaximumCharacters }
    ),
  },
  {
    field:
      EventFormValidationMessageTypes.EventAdditionalInformationMaxCapacity,
    message: t(
      'Events.EventForm.ValidationMessage.EventAdditionalInformationMaxCapacity',
      'Additional Information cannot exceed {{additionalInformationMaximumCharacters}} characters.',
      { additionalInformationMaximumCharacters }
    ),
  },
  {
    field: EventFormValidationMessageTypes.EventLocationType,
    message: t(
      'Events.EventForm.ValidationMessage.EventLocationType',
      'Please select an Event Location Type.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.OnlineEventUrl,
    message: t(
      'Events.EventForm.ValidationMessage.OnlineEventUrl',
      'Online Event Url cannot be blank.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.Location,
    message: t(
      'Events.EventForm.ValidationMessage.Location',
      'Location cannot be empty.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.StartDate,
    message: t(
      'Events.EventForm.ValidationMessage.StartDate',
      'Please enter a valid Start Date.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.EndDate,
    message: t(
      'Events.EventForm.ValidationMessage.EndDate',
      'Please enter a valid End Date.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.BeforeMax,
    message: t(
      'Events.EventForm.ValidationMessage.BeforeMax',
      'Please enter a valid date on or before'
    ),
  },
  {
    field: EventFormValidationMessageTypes.AfterMin,
    message: t(
      'Events.EventForm.ValidationMessage.AfterMin',
      'Please enter a valid date on or after'
    ),
  },
  {
    field: EventFormValidationMessageTypes.StartTime,
    message: t(
      'Events.EventForm.ValidationMessage.StartTime',
      'Please enter a valid Start Time.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.EndTime,
    message: t(
      'Events.EventForm.ValidationMessage.EndTime',
      'Please enter a valid End Time.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.TimeZone,
    message: t(
      'Events.EventForm.ValidationMessage.TimeZone',
      'Please select a Time Zone.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.StartDateGreaterThanEndDate,
    message: t(
      'Events.EventForm.ValidationMessage.StartDateGreaterThanEndDate',
      'End Date must be on or after Start Date.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.StartTimeGreaterThanEndTime,
    message: t(
      'Events.EventForm.ValidationMessage.StartTimeGreaterThanEndTime',
      'End Time must be after Start Time.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.ContactEmail,
    message: t(
      'Events.EventForm.ValidationMessage.ContactEmail',
      'Please enter a valid email.'
    ),
  },
  {
    field: EventFormValidationMessageTypes.HostName,
    message: t(
      'Events.EventForm.ValidationMessage.HostName',
      'Host Name cannot be empty.'
    ),
  },
  {
    field:
      EventFormValidationMessageTypes.EndDateGreaterOrEqualThanRegistrationCloseDate,
    message: t(
      'Events.EventForm.ValidationMessage.EndDateGreaterOrEqualThanRegistrationCloseDate',
      'End date must be on or after registration close date.'
    ),
  },
]

export const EventFormCard: React.FunctionComponent<EventFormCardProps> = (
  props
) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const isMobileOrSmaller = useMediaQuery(theme.breakpoints.down('sm'))
  const { permissionAbility } = useAuth()
  const { variant, eventDetails: parentEventDetails, eventKey } = props
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()

  /** Other Objects */
  const isEditVariant = variant === EventFormCardVariants.EditEvent
  const isCreateVariant = variant === EventFormCardVariants.CreateEvent
  const defaultTimezone = spacetime(undefined, 'America/New_York').timezone()
    .name
 
  const defaultErrorMessage = t(
    'Events.EventForm.ErrorMessage',
    'Something went wrong. Please make sure you have filled out every field.'
  )

  const eventDeleteConfirmationButtons = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.YesDelete}
      secondaryButtonLabel={TextButtonVariant.NoCancel}
      secondaryClick={() => setIsDeleteEventModalOpen(false)}
    />
  )

  const localEventLinkConfirmationButtons = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.Ok}
      secondaryButtonLabel={TextButtonVariant.Cancel}
      secondaryClick={() => setIsLocalEventLinkModalOpen(false)}
    />
  )

  const canDeleteEvent = permissionAbility.can('delete', 'Event')
  const canCreateEvent = permissionAbility.can('create', 'Event')
  const canEditEvent = permissionAbility.can('edit', 'Event')

  const isRegistrationOpen = parentEventDetails?.registrationOpen
    ? t('Events.EventFormCard.RegistrationStatus.Open', 'Open')
    : t('Events.EventFormCard.RegistrationStatus.Closed', 'Closed')

  const startDateValue =
    parentEventDetails?.startDate.toISOString().slice(0, 10) ??
    todaysDateFormattedLocal()
  const endDateValue =
    parentEventDetails?.endDate?.toISOString().slice(0, 10) ??
    todaysDateFormattedLocal()
  const initialEventDetails = useRef({
    ...parentEventDetails,
    startDate: startDateValue,
    endDate: endDateValue,
    startTime: parentEventDetails?.startTime ?? '08:00',
    endTime: parentEventDetails?.endTime ?? '09:30',
    eventType: !!parentEventDetails ? parentEventDetails?.eventType : '',
    registration: isEditVariant
      ? isRegistrationOpen
      : EventRegistrationTypes.Open,
    capacity: parentEventDetails?.capacity,
    /** We set the state to an empty string as opposed to one of the EventLocationTypes so
     * that we force the user to choose Local or Global when filling out the form.
     */
    eventLocationType: parentEventDetails?.isOnline
      ? EventLocationTypes.Global
      : !parentEventDetails?.isOnline
      ? EventLocationTypes.Local
      : '',
    eventName: parentEventDetails?.name ?? '',
    description: parentEventDetails?.description ?? '',
    additionalInformation: parentEventDetails?.additionalInformation ?? '',
    timezone:
      timezoneTitleCase(parentEventDetails?.timezone ?? '') ?? defaultTimezone,
    onlineEventUrl: parentEventDetails?.onlineEventUrl ?? '',
    publicUrl: parentEventDetails?.publicUrl ?? '',
    hostName: parentEventDetails?.hostName ?? '',
    hostPhone: parentEventDetails?.hostPhone ?? '',
    contactName: parentEventDetails?.contactName ?? '',
    contactEmail: parentEventDetails?.contactEmail ?? '',
    owner: parentEventDetails?.owner ?? '',
    possibleNewOwners: parentEventDetails?.possibleNewOwners ?? [],
    actorkey: parentEventDetails?.actorkey ?? 0,
    registrationCloseDate: parentEventDetails?.registrationCloseDate
      ? dateToDashString(
          reinterpretYearMonthDayAsLocalTime(
            parentEventDetails?.registrationCloseDate
          )
        )
      : undefined,
    qRCodeImage: parentEventDetails?.qRCodeImage,
  })

  const initialAddress = useRef<Address>({
    locationName: parentEventDetails?.address?.locationName ?? '',
    streetAddress1: parentEventDetails?.address?.streetAddress1 ?? '',
    streetAddress2: parentEventDetails?.address?.streetAddress2 ?? '',
    city: parentEventDetails?.address?.city ?? '',
    state: parentEventDetails?.address?.state ?? '',
    zip: parentEventDetails?.address?.zip ?? '',
    countryCode: parentEventDetails?.address?.countryCode ?? '',
  })

  /** State Objects */
  const [address, setAddress] = useState<Address>(initialAddress.current)

  const [registrants, setRegistrants] = useState<Registrant[]>()

  const [eventDetails, setEventDetails] = useState(initialEventDetails.current)

  // This makes it easier than having to 'eventDetails.' the world
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    eventType,
    registration,
    capacity,
    eventLocationType,
    eventName,
    description,
    additionalInformation,
    timezone,
    onlineEventUrl,
    hostName,
    hostPhone,
    contactName,
    contactEmail,
    actorkey,
    registrationCloseDate,
  } = eventDetails

  /** Field Validity */
  const [eventFieldValidity, setEventFieldValidity] =
    useState<EventFormValidFields>({
      eventName: { input: true },
      eventType: { input: true },
      registration: { input: true },
      description: { input: true, afterMax: false },
      additionalInformation: { input: true, afterMax: false },
      address: { input: true },
      eventLocationType: { input: true },
      onlineEventUrl: { input: true },
      startDate: { input: true, afterMin: true, beforeMax: true },
      endDate: { input: true, afterMin: true, beforeMax: true },
      startTime: { input: true },
      endTime: { input: true },
      timezone: { input: true },
      contactEmail: { input: true },
      maxCapacity: { input: true },
      hostName: { input: true },
      registrationCloseDate: { input: true },
    })

  const [isFieldDisabled, setIsFieldDisabled] = useState(
    variant === EventFormCardVariants.EditEvent
  )
  const { addLoadingIds } = React.useContext(LoadingContext)

  const availableLoadingIds = useLoadingIds()

  const [loading, setLoading] = useState(false)

  const [isStartTimeGreaterThanEndTime, setIsStartTimeGreaterThanEndTime] =
    useState(false)

  const [isStartDateGreaterThanEndDate, setIsStartDateGreaterThanEndDate] =
    useState(false)

  const [
    isEndDateGreaterOrEqualThanRegistrationCloseDate,
    setIsEndDateGreaterOrEqualThanRegistrationCloseDate,
  ] = useState(false)

  const [
    isRegistrationCloseDateMinorOrEqualThanEndDate,
    setIsRegistrationCloseDateMinorOrEqualThanEndDate,
  ] = useState(false)

  const [isDeleteEventModalOpen, setIsDeleteEventModalOpen] = useState(false)

  const [isLocalEventLinkModalOpen, setIsLocalEventLinkModalOpen] =
    useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const [textErrorEmail, setTextErrorEmail] = useState<string>('')

  const [reset, setReset] = useState(false)

  useEffect(() => {
    if (!!location.state) {
      const eventFromLocation = location.state as unknown as Event & {
        eventLocationType: string
        registration: string
      }
      if (eventFromLocation.createdBy !== undefined) {
        setEventDetails((prevEventDetails) => {
          return {
            ...prevEventDetails,
            eventName: eventFromLocation.name,
            eventType: eventFromLocation.eventType,
            registration: eventFromLocation.registration,
            capacity: eventFromLocation.capacity,
            eventLocationType: eventFromLocation.eventLocationType,
            description: eventFromLocation.description,
            additionalInformation:
              eventFromLocation.additionalInformation ?? '',
            onlineEventUrl: eventFromLocation.onlineEventUrl ?? '',
            hostName: eventFromLocation.hostName ?? '',
            hostPhone: eventFromLocation.hostPhone ?? '',
            contactName: eventFromLocation.contactName ?? '',
            contactEmail: eventFromLocation.contactEmail ?? '',
            qRCodeImage: eventFromLocation.qRCodeImage,
          }
        })
        if (eventFromLocation.address !== undefined) {
          setEventDetails((prevEventDetails) => {
            return {
              ...prevEventDetails,
              address: eventFromLocation.address,
            }
          })
          setAddress(eventFromLocation.address)
        }
      }
    }
  }, [location])

  // Fetch Registrants...
  const errorMessageFetchRegistrants = t(
    'Events.EventFormCard.FetchRegistrants.ErrorMessage',
    'Something went wrong while loading event registrants. Please reload the page'
  )

  const fetchRegistrants = async () => {
    try {
      const fetchedRegistrants = await events.fetchEventRegistrants({
        eventKey: eventKey ?? 0,
      })
      setRegistrants(fetchedRegistrants.data)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Code',
        message:
          (e as unknown as Error).message ?? errorMessageFetchRegistrants,
      }
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarMessage(errorObject.message)
      setSnackbarState(true)
    }
  }

  const refetchRegistrants = () => {
    addLoadingIds([availableLoadingIds.Events.fetchEventRegistrants])
  }

  useLoadingContext({
    asyncFunction: fetchRegistrants,
    loadingId: availableLoadingIds.Events.fetchEventRegistrants,
  })

  useEffect(() => {
    // ...only when editing an Event
    if (isEditVariant) {
      addLoadingIds([availableLoadingIds.Events.fetchEventRegistrants])
    }
  }, [
    eventKey,
    isEditVariant,
    errorMessageFetchRegistrants,
    setSnackbarSeverity,
    setSnackbarMessage,
    setSnackbarState,
    addLoadingIds,
    availableLoadingIds.Events.fetchEventRegistrants,
  ])

  const listOfRegistrants = useMemo(() => {
    return registrants ?? []
  }, [registrants])

  const validationMessageMap = useValidationMessages(getValidationMessages({ t }))

  /** Methods */
  const resetFields = () => {
    setIsFieldDisabled(true)
    setEventDetails(initialEventDetails.current)
  }

  // When we need to reset the fields, do so.
  useEffect(() => {
    reset && resetFields()
  }, [reset])

  /** Handles 'Cancel' and 'Back' button actions */
  const handleCancelOrBack = () => {
    // Always reset our error message
    setErrorMessage('')
    if (!isEditVariant || (isEditVariant && isFieldDisabled)) {
      navigate({ pathname: '/events' })
    } else {
      setIsFieldDisabled(true)
      setReset(true)
    }
  }

  const handleEdit = () => {
    setIsFieldDisabled(false)
  }

  const handleCopyEvent = () => {
    navigate(
      {
        pathname: '/events/add-event',
      },
      {
        state: eventDetails,
      }
    )
  }

  /** Verify the validity of the field information */
  const checkFieldValidity = () => {
    const isOnlineEvent = eventLocationType === EventLocationTypes.Global
    setEventFieldValidity((prevEventFieldValidity) => ({
      ...prevEventFieldValidity,
      eventName: { input: !!eventName },
      eventType: { input: !!eventType },
      registration: { input: !!registration },
      description: { input: !!description },
      maxCapacity: {
        input: validateMaxCapacity(),
        afterMin: !!capacity && capacity > 0,
        beforeMax: !!capacity && capacity <= 999,
      },
      address: {
        input: (!!eventLocationType && isOnlineEvent) || !!address.city,
      },
      eventLocationType: { input: !!eventLocationType },
      onlineEventUrl: {
        input: !isOnlineEvent || (isOnlineEvent && !!onlineEventUrl),
      },
      // We have removed startDate and endDate from checkFieldValidity because their validity is handled in DateField
      startTime: { input: !!startTime },
      endTime: { input: !!endTime },
      timezone: { input: !!timezone },
      contactEmail: {
        input: !!contactEmail && validateContactEmail(contactEmail ?? ''),
      },
      hostName: { input: !!hostName },
    }))
  }

  let registrationCloseDateValue: Date | undefined
  if (registrationCloseDate && registrationCloseDate !== null) {
    registrationCloseDateValue = new Date(registrationCloseDate)
    registrationCloseDateValue.setHours(23)
    registrationCloseDateValue.setMinutes(59)
  }

  const createEvent = async () => {
    setLoading(true)
    try {
      await events.createEvent({
        body: {
          name: eventName ?? '',
          eventType: eventType as EventEventTypeEnum,
          description: description ?? '',
          additionalInformation: additionalInformation ?? '',
          startDate: new Date(startDate ?? ''),
          endDate: new Date(endDate ?? ''),
          startTime: startTime ?? '',
          endTime: endTime ?? '',
          timezone: timezone ?? '',
          isOnline: eventLocationType === EventLocationTypes.Global,
          onlineEventUrl: onlineEventUrl ?? '',
          address:
            eventLocationType === EventLocationTypes.Global
              ? undefined
              : (address as Address),
          registrationOpen: registration === EventRegistrationTypes.Open,
          requiresNda: false,
          // If the field is blank, capacity is "". Since empty string is not valid for the req body, we set it to 'undefined'.
          capacity: capacity || undefined,
          hostName,
          hostPhone,
          contactName,
          contactEmail,
          registrationCloseDate: registrationCloseDateValue,
        },
      })
      navigate(
        {
          pathname: '/events',
        },
        {
          state: {
            snackbarState: true,
            snackbarMessage: t(
              'Events.EventForm.Create.SuccessMessage',
              'Event successfully created.'
            ),
            snackbarSeverity: SnackbarSeverity.Success,
          },
        }
      )
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message ?? defaultErrorMessage,
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    } finally {
      setLoading(false)
    }
  }

  const updateEvent = async () => {
    setLoading(true)
    try {
      await events.updateEvent({
        body: {
          name: eventName ?? '',
          eventType: eventType as EventEventTypeEnum,
          description: description ?? '',
          additionalInformation: additionalInformation ?? '',
          startDate: new Date(startDate ?? ''),
          endDate: new Date(endDate ?? ''),
          startTime: startTime ?? '',
          endTime: endTime ?? '',
          timezone: timezone ?? '',
          isOnline: eventLocationType === EventLocationTypes.Global,
          onlineEventUrl: onlineEventUrl ?? '',
          address:
            eventLocationType === EventLocationTypes.Global
              ? undefined
              : (address as Address),
          eventKey,
          publicUrl: '',
          createdBy: '',
          registrationOpen: registration === EventRegistrationTypes.Open,
          requiresNda: false,
          // If the field is blank, capacity is "". Since empty string is not valid for the req body, we set it to 'undefined'.
          capacity: capacity || undefined,
          hostName,
          hostPhone,
          contactName,
          contactEmail,
          actorkey,
          registrationCloseDate: registrationCloseDateValue,
        },
      })
      setIsFieldDisabled(true)
      setSnackbarState(true)
      setSnackbarMessage(
        t(
          'Events.EventForm.Update.SuccessMessage',
          'Event successfully updated.'
        )
      )
      setSnackbarSeverity(SnackbarSeverity.Success)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message ?? defaultErrorMessage,
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
    setLoading(false)
  }

  const allCommonFieldsValid = () => {
    return (
      eventName !== '' &&
      eventType !== '' &&
      description !== '' &&
      eventLocationType !== '' &&
      startDate !== '' &&
      endDate !== '' &&
      startTime !== '' &&
      endTime !== '' &&
      timezone !== '' &&
      contactEmail !== '' &&
      validateContactEmail(contactEmail ?? '') &&
      hostName !== ''
    )
  }

  const areAllFieldsValidOnline = () => {
    return onlineEventUrl !== ''
  }

  const areAllFieldsValidInPerson = () => {
    return address.streetAddress1 !== '' && address.city !== ''
  }

  // Set min & max to two years past and ahead of todays date
  // since we know events wont exceed those limits.
  const maximumDate = useRef(
    new Date(Date.UTC(new Date().getFullYear() + 2, 11, 31))
  )
  const minimumDate = useRef(
    new Date(Date.UTC(new Date().getFullYear() - 2, 0, 1))
  )

  const eventStartDateHelperText = useMemo(() => {
    if (!eventFieldValidity.startDate.input) {
      return validationMessageMap.get(EventFormValidationMessageTypes.StartDate)
    } else if (!eventFieldValidity.startDate.beforeMax) {
      return `${validationMessageMap.get(
        EventFormValidationMessageTypes.BeforeMax
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(maximumDate.current)
      )}`
    } else if (!eventFieldValidity.startDate.afterMin) {
      return `${validationMessageMap.get(
        EventFormValidationMessageTypes.AfterMin
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(minimumDate.current)
      )}`
    } else {
      return null
    }
  }, [
    eventFieldValidity.startDate.afterMin,
    eventFieldValidity.startDate.beforeMax,
    eventFieldValidity.startDate.input,
    maximumDate,
    minimumDate,
    validationMessageMap,
  ])

  const eventEndDateHelperText = useMemo(() => {
    if (!eventFieldValidity.endDate.input) {
      return validationMessageMap.get(EventFormValidationMessageTypes.StartDate)
    } else if (!eventFieldValidity.endDate.beforeMax) {
      return `${validationMessageMap.get(
        EventFormValidationMessageTypes.BeforeMax
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(maximumDate.current)
      )}`
    } else if (!eventFieldValidity.endDate.afterMin) {
      return `${validationMessageMap.get(
        EventFormValidationMessageTypes.AfterMin
      )} ${dateToDashString(
        reinterpretYearMonthDayAsLocalTime(minimumDate.current)
      )}`
    } else if (isStartDateGreaterThanEndDate) {
      return validationMessageMap.get(
        EventFormValidationMessageTypes.StartDateGreaterThanEndDate
      )
    } else if (isEndDateGreaterOrEqualThanRegistrationCloseDate) {
      return validationMessageMap.get(
        EventFormValidationMessageTypes.EndDateGreaterOrEqualThanRegistrationCloseDate
      )
    } else {
      return null
    }
  }, [
    eventFieldValidity.endDate.input,
    eventFieldValidity.endDate.beforeMax,
    eventFieldValidity.endDate.afterMin,
    isStartDateGreaterThanEndDate,
    isEndDateGreaterOrEqualThanRegistrationCloseDate,
    validationMessageMap,
    maximumDate,
    minimumDate,
  ])

  const validateTime = () => {
    if (
      startDate === endDate &&
      timeStringToNumber(endTime as string) <=
        timeStringToNumber(startTime as string)
    ) {
      setIsStartTimeGreaterThanEndTime(true)
    } else {
      setIsStartTimeGreaterThanEndTime(false)
    }
  }

  const onAddressConfirmation = (address: Address) => {
    setEventDetails((prevEventDetails) => {
      return {
        ...prevEventDetails,
        address,
      }
    })
    setAddress(address)
  }

  const validateMaxCapacity = (): boolean => {
    // Capacity is valid if:
    // * falsy (except 0)
    // * is within range
    return (
      (!capacity && capacity !== 0) ||
      (!!capacity && capacity > 0 && capacity <= 999)
    )
  }

  const validateContactEmail = (email: string) => {
    const resultMsg = validateEmailText(email)
    setTextErrorEmail(resultMsg)
    return resultMsg === ''
  }
  const isLocalOnlineEvent = () => {
    return (
      eventLocationType === EventLocationTypes.Local &&
      areAllFieldsValidOnline()
    )
  }

  const handleSave = async () => {
    setIsLocalEventLinkModalOpen(false)

    validateTime()

    if (isEditVariant) {
      // Validate fields

      checkFieldValidity()
      if (
        eventLocationType === EventLocationTypes.Local &&
        areAllFieldsValidInPerson() &&
        validateMaxCapacity() &&
        validateContactEmail(contactEmail ?? '')
      ) {
        updateEvent()
        props.updateEventName?.(eventName)
      } else if (
        eventLocationType === EventLocationTypes.Global &&
        areAllFieldsValidOnline() &&
        validateMaxCapacity() &&
        validateContactEmail(contactEmail ?? '')
      ) {
        updateEvent()
        props.updateEventName?.(eventName)
      } else {
        const e = {
          code: 'UnknownError',
          message: defaultErrorMessage,
        }
        setSnackbarState(true)
        setSnackbarMessage((e as unknown as Error).message)
        setSnackbarSeverity(SnackbarSeverity.Error)
      }
    } else {
      // Validate fields
      checkFieldValidity()
      validateContactEmail(contactEmail ?? '')

      if (
        eventDetails.eventLocationType === EventLocationTypes.Local &&
        allCommonFieldsValid() &&
        areAllFieldsValidInPerson() &&
        validateMaxCapacity() &&
        validateContactEmail(contactEmail ?? '')
      ) {
        createEvent()
      } else if (
        eventLocationType === EventLocationTypes.Global &&
        allCommonFieldsValid() &&
        areAllFieldsValidOnline() &&
        validateMaxCapacity() &&
        validateContactEmail(contactEmail ?? '')
      ) {
        createEvent()
      } else {
        const e = {
          code: 'UnknownError',
          message: defaultErrorMessage,
        }
        setSnackbarState(true)
        setSnackbarMessage((e as unknown as Error).message)
        setSnackbarSeverity(SnackbarSeverity.Error)
      }
    }
  }

  const onOwnerChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    const actorKey: number = +((child as ReactElement).key
      ?.toString()
      .slice(2) as string)

    setEventDetails((prevEventDetails) => {
      return {
        ...prevEventDetails,
        actorkey: actorKey,
        owner: event.target.value as string,
      }
    })
  }

  const validateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.name) {
      case 'description':
        event.target.value.length >= descriptionMaximumCharacters
          ? setEventFieldValidity({
              ...eventFieldValidity,
              description: { input: true, afterMax: true },
            })
          : setEventFieldValidity({
              ...eventFieldValidity,
              description: { input: true, afterMax: false },
            })

        break
      case 'additionalInformation':
        event.target.value.length >= additionalInformationMaximumCharacters
          ? setEventFieldValidity({
              ...eventFieldValidity,
              additionalInformation: { input: true, afterMax: true },
            })
          : setEventFieldValidity({
              ...eventFieldValidity,
              additionalInformation: { input: true, afterMax: false },
            })

        break

      default:
        break
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setEventDetails((prevEventDetails) => {
      return {
        ...prevEventDetails,
        [name]: name === 'contactEmail' ? value.trim() : value,
      }
    })
    validateInputChange(event)
  }

  const handleRegistrationCloseDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
    validity?: FieldValidity
  ) => {
    setIsRegistrationCloseDateMinorOrEqualThanEndDate(
      // Since comparing string, '<=' or '>=' doesn't work
      !(event.target.value < endDate || event.target.value == endDate)
    )

    setEventDetails((prevEventDetails) => {
      return {
        ...prevEventDetails,
        [event.target.name]: value ?? event.target.value,
      }
    })

    setEventFieldValidity((prevEventFieldValidity) => {
      return {
        ...prevEventFieldValidity,
        [event.target.name]: validity ?? {
          input: true,
        },
      }
    })
  }

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string,
    validity?: FieldValidity
  ) => {
    setIsStartDateGreaterThanEndDate(
      event.target.name === 'startDate'
        ? new Date(event.target.value) > new Date(endDate)
        : new Date(startDate) > new Date(event.target.value)
    )
    if (event.target.name === 'endDate' && registrationCloseDate) {
      setIsEndDateGreaterOrEqualThanRegistrationCloseDate(
        // Since comparing string, '<=' or '>=' doesn't work
        !(
          registrationCloseDate < event.target.value ||
          registrationCloseDate == event.target.value
        )
      )
      if (isRegistrationCloseDateMinorOrEqualThanEndDate) {
        setIsRegistrationCloseDateMinorOrEqualThanEndDate(false)
      }
    }

    setEventDetails((prevEventDetails) => {
      return {
        ...prevEventDetails,
        [event.target.name]: value ?? event.target.value,
      }
    })

    setEventFieldValidity((prevEventFieldValidity) => {
      return {
        ...prevEventFieldValidity,
        [event.target.name]: validity ?? {
          input: true,
          afterMin: true,
          beforeMax: true,
        },
      }
    })
  }

  const handleYesDelete = async (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    setLoading(true)
    try {
      await events.deleteEvent({ eventKey: eventKey as number })
      navigate(
        {
          pathname: '/events',
        },
        {
          state: {
            snackbarState: true,
            snackbarMessage: t(
              'Events.EventForm.Delete.SuccessMessage',
              'Event successfully deleted.'
            ),
            snackbarSeverity: SnackbarSeverity.Success,
          },
        }
      )
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message ?? defaultErrorMessage,
      }

      setErrorMessage(errorObject.message)
    }
    setLoading(false)
  }

  const eventFormButtons = () => {
    let primaryButtonLabel = undefined
    let secondaryButtonLabel:
      | TextButtonVariant
      | ContainedButtonVariant
      | OutlinedButtonVariant
      | undefined = undefined
    let secondaryClick = undefined
    let tertiaryButtonLabel = undefined
    let tertiaryClick = undefined
    let quaternaryButtonLabel = TextButtonVariant.Back
    let quaternaryClick = handleCancelOrBack

    if (isEditVariant && !isFieldDisabled) {
      primaryButtonLabel = ContainedButtonVariant.Publish
      if (canCreateEvent && canEditEvent) {
        secondaryButtonLabel = OutlinedButtonVariant.CopyEvent
        secondaryClick = handleCopyEvent
      }
      if (listOfRegistrants.length === 0 && canDeleteEvent) {
        tertiaryButtonLabel = ContainedButtonVariant.Delete
        tertiaryClick = () => setIsDeleteEventModalOpen(true)
      }
      quaternaryButtonLabel = TextButtonVariant.Cancel
      quaternaryClick = handleCancelOrBack
    } else if (isCreateVariant) {
      primaryButtonLabel = ContainedButtonVariant.Publish
      quaternaryButtonLabel = TextButtonVariant.Cancel
      quaternaryClick = handleCancelOrBack
    } else {
      if (canEditEvent) {
        primaryButtonLabel = ContainedButtonVariant.Edit
        if (canCreateEvent) {
          secondaryButtonLabel = OutlinedButtonVariant.CopyEvent
          secondaryClick = handleCopyEvent
        }
      }
      if (listOfRegistrants.length === 0 && canDeleteEvent) {
        tertiaryButtonLabel = ContainedButtonVariant.Delete
        tertiaryClick = () => setIsDeleteEventModalOpen(true)
      }
    }

    const areTextLengthValid = !(
      !!eventFieldValidity.additionalInformation.afterMax ||
      !!eventFieldValidity.description.afterMax
    )

    const eventExpirationDateAndTime = spacetime(
      utcDateString(new Date(endDate)),
      timezone
    ).time(endTime)
    /* disables the edit button if the event has expired,
    validates taking into consideration the time zone of both
     the event and the user who wants to edit it*/
    const disablePrimaryButton =
      spacetime.now().isAfter(eventExpirationDateAndTime) ||
      !areTextLengthValid ||
      isRegistrationCloseDateMinorOrEqualThanEndDate

    return (
      <ActionButtons
        primaryButtonLabel={primaryButtonLabel}
        disablePrimaryButton={disablePrimaryButton}
        secondaryButtonLabel={secondaryButtonLabel}
        tertiaryButtonLabel={tertiaryButtonLabel}
        quaternaryButtonLabel={quaternaryButtonLabel}
        secondaryClick={secondaryClick}
        tertiaryClick={tertiaryClick}
        quaternaryClick={quaternaryClick}
      />
    )
  }

  if (loading) {
    return <LoadingProgress />
  }

  return (
    <>
      <ConfirmationModal
        isOpen={isDeleteEventModalOpen}
        handleFormSubmit={handleYesDelete}
        dialogContent={!!errorMessage && <ErrorAlert error={errorMessage} />}
        dialogActions={eventDeleteConfirmationButtons}
        dialogTitle={t(
          'Events.Delete.Confirmation',
          'Are you sure you want to delete this event?'
        )}
      />
      <ConfirmationModal
        isOpen={isLocalEventLinkModalOpen}
        handleFormSubmit={(event) => {
          event.preventDefault()
          isEditVariant && isFieldDisabled ? handleEdit() : handleSave()
        }}
        dialogContent={!!errorMessage && <ErrorAlert error={errorMessage} />}
        dialogActions={localEventLinkConfirmationButtons}
        dialogTitle={t(
          'Events.AddEvent.Link.Confirmation',
          'You have added a meeting link. This event will become a Local ONLINE event and the meeting link will be sent to the Registrant instead of the physical address.'
        )}
      />
      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          isEditVariant && isFieldDisabled
            ? handleEdit()
            : isLocalOnlineEvent()
            ? setIsLocalEventLinkModalOpen(true)
            : handleSave()
        }}
        noValidate
        autoComplete="off"
        aria-labelledby="eventDetailsHeader"
      >
        {isMobileOrSmaller && <Box>{eventFormButtons()}</Box>}
        <EventDetailsCard>
          {loading ? <LoadingProgress /> : null}
          <CardFormHeader
            headerContainerProps={{
              paddingTop: theme.spacing(1),
              marginX: theme.spacing(4),
              paddingBottom: theme.spacing(0),
              [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(2, 0, -1),
              },
            }}
            header={
              <Header
                headerName={t(
                  'Events.AddEvent.EventDetails.Header',
                  'Event Details'
                )}
                component="h2"
                variant={HeaderVariant.Card}
              />
            }
            buttons={
              <Box
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                  },
                  marginTop: theme.spacing(3),
                  marginX: theme.spacing(4),
                }}
              >
                {!isMobileOrSmaller && <Box>{eventFormButtons()}</Box>}
              </Box>
            }
          />
          <CardContent sx={{ padding: theme.spacing(0, 4) }}>
            <EventInformationFields
              eventInformationDetails={{ ...eventDetails }}
              eventFieldValidity={eventFieldValidity}
              handleInputChange={handleInputChange}
              isFieldDisabled={isFieldDisabled}
              onOwnerChange={onOwnerChange}
              variant={variant}
            />
          </CardContent>
        </EventDetailsCard>
      </form>
      <EventLocationFields
        eventLocationDetails={{
          ...eventDetails,
        }}
        eventFieldValidity={eventFieldValidity}
        eventStartDateHelperText={eventStartDateHelperText}
        eventEndDateHelperText={eventEndDateHelperText}
        handleDateChange={handleDateChange}
        handleInputChange={handleInputChange}
        isFieldDisabled={isFieldDisabled}
        isStartTimeGreaterThanEndTime={isStartTimeGreaterThanEndTime}
        onlineEventUrl={onlineEventUrl}
        textErrorEmail={textErrorEmail}
        onAddressConfirmation={onAddressConfirmation}
        handleRegistrationCloseDateChange={handleRegistrationCloseDateChange}
        isRegistrationCloseDateLessThanOrEqualToEndDate={
          isRegistrationCloseDateMinorOrEqualThanEndDate
        }
      />
      {isEditVariant ? (
        <RegistrantsTable
          {...props}
          eventRegistrants={
            listOfRegistrants.filter((registrant) => registrant.attending)
              .length
          }
          eventCapacity={capacity}
          registrants={listOfRegistrants as Registrant[]}
          refetch={refetchRegistrants}
        />
      ) : null}
    </>
  )
}

export default EventFormCard
