/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface RegionContractingHierarchyRequestBody
 */
export interface RegionContractingHierarchyRequestBody {
  /**
   * The unique identifier for the role that can invite the receiver role to be a member of the region.
   * @type {number}
   * @memberof RegionContractingHierarchyRequestBody
   */
  senderRoleKey: number
  /**
   * The unique identifier for the role that can be invited by the sender role to be a member of the region.
   * @type {number}
   * @memberof RegionContractingHierarchyRequestBody
   */
  receiverRoleKey: number
}

export function RegionContractingHierarchyRequestBodyFromJSON(
  json: any
): RegionContractingHierarchyRequestBody {
  return RegionContractingHierarchyRequestBodyFromJSONTyped(json, false)
}

export function RegionContractingHierarchyRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegionContractingHierarchyRequestBody {
  if (json === undefined || json === null) {
    return json
  }
  return {
    senderRoleKey: json['senderRoleKey'],
    receiverRoleKey: json['receiverRoleKey'],
  }
}

export function RegionContractingHierarchyRequestBodyToJSON(
  value?: RegionContractingHierarchyRequestBody | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    senderRoleKey: value.senderRoleKey,
    receiverRoleKey: value.receiverRoleKey,
  }
}
