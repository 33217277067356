import { useState } from 'react'
import { useMountEffect } from './useMountEffect'
import { useSnackbarContext } from './../components/Context/SnackbarContext'
import { fetchAcademicYears } from '../api/communities'
import { SnackbarSeverity } from '../components/Alerts/SnackbarAlert'
import { extractedErrorObject } from '../api/swagger'
import useLoadingContext from './useLoadingContext'
import { OperationIds } from '../swagger/operationIdEnum'
import { useLearningCenterContext } from '../components/Context/LearningCenterContext'
import { buildAcademicYearsFilterOptions } from '../helpers/buildAcademicYearsFilterOptions'

// Setting this value to 0 prevents an initial double fetch
const defaultAcademicYear = 0

export interface AcademicYears {
  year: number
  name: string
  isDefault: boolean
}
interface UseAcademicYears {
  selectedAcademicYear: number
  academicYears: Array<AcademicYears>
  setSelectedAcademicYear: (academicYear: number) => void
  loadingId: string
}

export interface UseAcademicYearsProps {
  errorMessage?: string
  isLC?: boolean
  defaultAY?: number
  /**
   * In case where we are calling this requires permissions to view, don't call
   * the endpoint.
   */
  preventFetch?: boolean
}

/**
 * This hook fetches academic years and uses the buildAcademicYearsFilterOptions function
 * to parse the array returned from the API, preparing it for the dropdown component.
 **/
export const useAcademicYears = ({
  errorMessage,
  isLC,
  defaultAY,
  preventFetch = false,
}: UseAcademicYearsProps): UseAcademicYears => {
  const [academicYears, setAcademicYears] = useState<Array<AcademicYears>>([])
  const [selectedAcademicYear, setSelectedAcademicYear] =
    useState(defaultAcademicYear)
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const loadingId = OperationIds.FetchAcademicYears
  const { updateAcademicYear } = useLearningCenterContext()

  const fetchAcademicYear = async () => {
    try {
      let requestParam = {}
      if (isLC) {
        requestParam = { isLC: isLC }
      }
      const academicYearsFromAPI = await fetchAcademicYears(requestParam)
      if (academicYearsFromAPI.academicYears.length > 0) {
        /**
         * include isDefault = true if the property is not present on the endpoint's response
         * temporary fix since the endpoint response should include this property for more info on BE fix WF-1368024
         */
        const properties = Object.keys(academicYearsFromAPI.academicYears[0])
        const includesIsDefaultProperty = properties.includes('isDefault')
        const includesIsCurrentProperty = properties.includes('isCurrent')

        if (!includesIsDefaultProperty && !includesIsCurrentProperty) {
          academicYearsFromAPI.academicYears[0].isDefault = true
        }

        const academicYearFilterOptions = buildAcademicYearsFilterOptions(
          academicYearsFromAPI.academicYears ?? []
        )

        setAcademicYears(academicYearFilterOptions)
        if (
          !defaultAY ||
          !academicYearsFromAPI.academicYears.some(
            (year) => year.year === defaultAY
          )
        ) {
          if (
            academicYearsFromAPI.academicYears?.some(
              (academicYears) => academicYears?.isDefault
            )
          ) {
            const academicYear = academicYearsFromAPI.academicYears?.filter(
              (academicYear) => academicYear.isDefault
            )[0].year
            setSelectedAcademicYear(academicYear)

            if (isLC) {
              updateAcademicYear(academicYear)
            }
          } else {
            const academicYear = academicYearsFromAPI.academicYears?.filter(
              (academicYear) => academicYear.isCurrent
            )[0].year
            setSelectedAcademicYear(academicYear)

            if (isLC) {
              updateAcademicYear(academicYear)
            }
          }
        } else {
          setSelectedAcademicYear(defaultAY)
        }
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarState?.(true)
    }
  }

  const { triggerFetch } = useLoadingContext({
    asyncFunction: fetchAcademicYear,
    loadingId,
  })

  useMountEffect(() => {
    if (!preventFetch) {
      triggerFetch()
    }
  })

  return {
    academicYears,
    selectedAcademicYear,
    setSelectedAcademicYear,
    loadingId,
  }
}
