import US from './US.json'
import Chile from './Chile.json'
import Canada from './Canada.json'
import Countries from './Countries.json'

export const countriesAndStates = {
  US,
  Chile,
  Canada,
  Countries,
}

export default countriesAndStates
