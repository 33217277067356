import React, { useState } from 'react'
import Box from '@mui/material/Box'
import SearchBar from '../Search/SearchBar'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import { useTranslation } from 'react-i18next'
import { buildFilterOptions } from '../../utils/buildFilterOptions'
import { styled, useTheme } from '@mui/system'
import {
  FilterFileByChallengeLevel,
  FilterFileType,
  LearningCenterTabs,
} from '../../utils/searchAndFilterEnums'
import {
  LearningCenterCategories,
  useLearningCenterContext,
} from '../Context/LearningCenterContext'
import { LoadingContext } from '../Context/LoadingContext'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { useNavigate } from 'react-router'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'

const StyledDropDown = styled(DropDown)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, 0, 2),
  },
}))

interface SearchAndFilterProps {
  academicYears: Array<{ year: number; name: string }>
  selectedYear: number
}

export const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
  academicYears,
  selectedYear,
}) => {
  const { t } = useTranslation()
  const {
    learningCenterOptions: filterOptions,
    searchQuery: searchValue,
    category: filterByCategory,
    contentType: filterByType,
    level,
    updateLevel,
    contentLoadingId,
    challengePrograms,
    selectedTabKey,
    includeOnlyFavorites,
    tabSpecificSearchAndFilter,
    updateTabSpecificSearchAndFilter,
    resetContextToDefaults,
    updateAcademicYear,
  } = useLearningCenterContext()
  const { addLoadingIds } = React.useContext(LoadingContext)
  const navigate = useNavigate()

  const categories = buildFilterOptions(
    filterOptions.categories,
    'All Categories'
  )

  const [isClearFilters, setIsClearFilters] = useState(false)

  const handleSearch = (searchText: string) => {
    updateTabSpecificSearchAndFilter({
      ...tabSpecificSearchAndFilter,
      [selectedTabKey]: {
        ...tabSpecificSearchAndFilter[selectedTabKey],
        searchQuery: searchText,
      },
    })
    // Recall fetchLearningCenterContent with the updated search query
    addLoadingIds([contentLoadingId])
  }

  const handleDropDownSelection = (selection: string, id: string) => {
    switch (id) {
      case 'filterByCategory':
        const category = selection as LearningCenterCategories
        updateTabSpecificSearchAndFilter({
          ...tabSpecificSearchAndFilter,
          [selectedTabKey]: {
            ...tabSpecificSearchAndFilter[selectedTabKey],
            category,
          },
        })
        break
      case 'filterByType':
        const contentType = selection as FilterFileType
        updateTabSpecificSearchAndFilter({
          ...tabSpecificSearchAndFilter,
          [selectedTabKey]: {
            ...tabSpecificSearchAndFilter[selectedTabKey],
            contentType,
          },
        })
        break
      case 'filterByLevel':
        const level = selection as FilterFileByChallengeLevel
        updateLevel(level)
        break
      case 'years':
        const yearSelected =
          academicYears.find((year) => year.name === selection)?.year ?? 0
        /**
         * The context is now to be reset on academicYear change.
         * If the academicYear changes, the user may have different available
         * programs. Cycles for Foundations and Essentials will also differ
         * between years, so content will update, too. There is no guarantee
         * that file types and categories (strands) will have the same overlap
         * between years so we must reset the search and filter.
         *
         * GOTCHA: Selecting the same academicYear as already selected would
         * reset the context values without calling fetchLearningCenterAvailableAcademicYears,
         * so we'll disable the currently selected year so that won't occur
         */
        resetContextToDefaults()
        /**
         * Afterwards, we can update the selected year and fetch the available
         * program types and content based on the selected year
         */
        updateAcademicYear(yearSelected)
        /**
         * since all Values was reset to default update the route to welcomeCenter to ovid
         * any route loading issue data
         */
        navigate({ pathname: '/learning/welcomeCenter' }, { replace: true })
        break
    }
    /**
     * Always attempt to call fetchLearningCenterContent
     */
    addLoadingIds([contentLoadingId])
  }

  const handleClearAllFilters = () => {
    updateTabSpecificSearchAndFilter({
      ...tabSpecificSearchAndFilter,
      [selectedTabKey]: {
        ...tabSpecificSearchAndFilter[selectedTabKey],
        contentType: FilterFileType.AllTypes,
        category: LearningCenterCategories.AllCategories,
        includeOnlyFavorites: false,
        searchQuery: '',
      },
    })
    setIsClearFilters(true)
    addLoadingIds([contentLoadingId])
  }

  const getLabelForFilterOption = (level?: string): string => {
    switch (level) {
      case FilterFileByChallengeLevel.ChallengeA:
        return t('LearningCenter.FilterOption.ChallengeA', 'Challenge A')
      case FilterFileByChallengeLevel.ChallengeB:
        return t('LearningCenter.FilterOption.ChallengeB.', 'Challenge B')
      case FilterFileByChallengeLevel.Challenge1:
        return t('LearningCenter.FilterOption.ChallengeI', 'Challenge I')
      case FilterFileByChallengeLevel.Challenge2:
        return t('LearningCenter.FilterOption.ChallengeII.', 'Challenge II')
      case FilterFileByChallengeLevel.Challenge3:
        return t('LearningCenter.FilterOption.ChallengeIII', 'Challenge III')
      case FilterFileByChallengeLevel.Challenge4:
        return t('LearningCenter.FilterOption.ChallengeIV.', 'Challenge IV')
      default:
        return t(
          'LearningCenter.FilterOption.NoneAvailable.',
          'No Available Levels'
        )
    }
  }

  const filetypes = buildFilterOptions(filterOptions.filetypes, 'All Types')

  /**
   * We only show the Challenge Level filter if we have availableProgramTypes
   * loaded from fetchLearningCenterAvailableProgramTypes, it is non-empty,
   * and we have selected the (first) level so as not to provide undefined to
   * a dropdown.
   */
  const showLevelFilter =
    !!challengePrograms &&
    challengePrograms.length > 0 &&
    level &&
    selectedTabKey.includes(LearningCenterTabs.Challenge)

  const showOnDesktop = useShowOnDesktop()
  const theme = useTheme()

  return (
    <Box
      id="search-and-filter-container"
      display="flex"
      flexDirection={showOnDesktop ? 'row' : 'column'}
      sx={{
        ...(showOnDesktop && {
          marginBottom: theme.spacing(2),
          '& .MuiSelect-outlined': {
            width: '100%',
          },
        }),
      }}
    >
      <SearchBar handleSearch={handleSearch} searchValue={searchValue} />
      {showLevelFilter && (
        <Box my={{ xs: 1, md: 0 }} ml={2}>
          <StyledDropDown
            id="filterByLevel"
            /**
             * Really, challengePrograms is defined because of showLevelFilter
             * but typescript doesn't want you to know that, so we include the
             * ? operator and a ?? for a default that will never be returned
             * given the proper check for the options.
             */
            menuOptions={
              challengePrograms?.map((level, index) => {
                return { name: getLabelForFilterOption(level), id: index - 1 }
              }) ?? []
            }
            defaultValue={getLabelForFilterOption(level)}
            handleSelection={handleDropDownSelection}
            variant={DropDownVariant.SortAndFilter}
            value={level}
          />
        </Box>
      )}
      <Box my={{ xs: 1, md: 0 }} ml={2}>
        <StyledDropDown
          id="filterByCategory"
          menuOptions={categories.map((category) => {
            return { name: category.name, id: category.id }
          })}
          handleSelection={handleDropDownSelection}
          variant={DropDownVariant.SortAndFilter}
          value={filterByCategory}
          isClearFilters={isClearFilters}
        />
      </Box>
      <Box my={{ xs: 1, md: 0 }} ml={2}>
        <StyledDropDown
          id="filterByType"
          menuOptions={filetypes.map((filetype) => {
            return { name: filetype.name, id: filetype.id }
          })}
          handleSelection={handleDropDownSelection}
          variant={DropDownVariant.SortAndFilter}
          value={filterByType}
          isClearFilters={isClearFilters}
        />
      </Box>
      <Box my={{ xs: 1, md: 0 }} ml={2}>
        <StyledDropDown
          id="years"
          menuOptions={academicYears.map(({ year, name }) => {
            return {
              name,
              id: year,
              /**
               * Disable the selected year because selecting it again would
               * reset context values without properly setting them again.
               */
              disabled: year === selectedYear,
            }
          })}
          value={
            academicYears.find((it) => +it.year === +selectedYear)?.name ?? ''
          }
          handleSelection={handleDropDownSelection}
          variant={DropDownVariant.SortAndFilter}
        />
      </Box>
      <Box my={{ xs: 1, md: 0 }} mx={{ xs: 0, md: 2 }}>
        <FormControlLabel
          control={
            <Switch
              color="secondary"
              inputProps={{
                'aria-label': `Include only My Favorite content`,
              }}
              name={`my-favorite-toggle`}
              checked={includeOnlyFavorites}
              value={includeOnlyFavorites}
              onChange={() => {
                updateTabSpecificSearchAndFilter({
                  ...tabSpecificSearchAndFilter,
                  [selectedTabKey]: {
                    ...tabSpecificSearchAndFilter[selectedTabKey],
                    includeOnlyFavorites: !includeOnlyFavorites,
                  },
                })
                addLoadingIds([contentLoadingId])
              }}
            />
          }
          label={
            <Typography variant="body1" component="p">
              {t(
                'SearchAndFilter.Label.MyFavorites',
                'Include Only My Favorites'
              )}
            </Typography>
          }
        />
      </Box>
      <Box>
        <OutlinedButton
          id="clearAllFilters"
          variant={OutlinedButtonVariant.ClearAllFilters}
          onClick={handleClearAllFilters}
        />
      </Box>
    </Box>
  )
}
