import React from 'react'
import IconButton from '@mui/material/IconButton'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import { copyToClipboard } from '../../utils/shareLink'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useTranslation } from 'react-i18next'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useTheme } from '@mui/material'
import { LearningCenterTabs } from '../../utils/searchAndFilterEnums'

interface ShareLinkProps {
  assetKey: number
  isLearningPath: boolean
  isLearningCenter?: boolean
  isOverviewCard?: boolean
}

const ShareLinkButton: React.FC<ShareLinkProps> = (props) => {
  const { t } = useTranslation()
  const { assetKey, isLearningPath, isLearningCenter, isOverviewCard } = props
  const { setSnackbarState, setSnackbarMessage, setSnackbarSeverity } =
    useSnackbarContext()
  const theme = useTheme()

  const updateSnackbarForShareLink = () => {
    setSnackbarState?.(true)
    setSnackbarMessage?.(
      t('ShareLinkButton.LinkCopySuccessMessage', 'Link copied to clipboard!')
    )
    setSnackbarSeverity?.(SnackbarSeverity.Success)
  }

  const handleShareLink = async (assetKey: number) => {
    if (isLearningPath) {
      if (isOverviewCard) {
        copyToClipboard(`${window.location.href}`, updateSnackbarForShareLink)
      } else {
        copyToClipboard(
          `${window.location.href}/learning-path/${assetKey.toString()}`,
          updateSnackbarForShareLink
        )
      }
    } else if (isLearningCenter) {
      /**  our URL includes TAB info we use LearningCenterTabs to remove it from URL
       *  to provide a link for any selected pice of content to the user
       */
      const replaceTheseStrings = new RegExp(
        Object.keys(LearningCenterTabs)
          .map((key) => `\\/${key}`)
          .join('|'),
        'i'
      )

      const replaceProgramsUrl = window.location.href.replace(
        replaceTheseStrings,
        ''
      )
      // provide a URL to the selected piece of content excluding the tabs related info
      copyToClipboard(
        `${replaceProgramsUrl}/learning-content/${assetKey.toString()}`,
        updateSnackbarForShareLink
      )
    } else if (isOverviewCard) {
      copyToClipboard(`${window.location.href}`, updateSnackbarForShareLink)
    } else {
      // This handles if the business card is being viewed in the Learning Path overview page
      if (window.location.href.includes('learning-path')) {
        copyToClipboard(
          `${
            window.location.origin
          }/business/business-content/${assetKey.toString()}`,
          updateSnackbarForShareLink
        )
      } else {
        copyToClipboard(
          `${window.location.href}/business-content/${assetKey.toString()}`,
          updateSnackbarForShareLink
        )
      }
    }
  }

  return (
    <IconButton
      aria-label="share link"
      sx={{ color: theme.palette.primary.dark }}
      onClick={() => handleShareLink(assetKey)}
    >
      <InsertLinkIcon />
    </IconButton>
  )
}

export default ShareLinkButton
