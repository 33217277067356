import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { SnackbarSeverity } from '../../Alerts/SnackbarAlert'
import {
  Role,
  UserAccountDetails,
  UserRoleAssignment as UserRoleAssignmentSchema,
  UserAccountListing,
  Region,
} from '../../../swagger'
import { dateToSlashStringReinterpretedAsLocal } from '../../../utils/dateUtility'
import { TextButtonVariant } from '../../Buttons/TextButton'
import Header, { HeaderVariant } from '../../Elements/Header'
import RoleModal, { EditRoleArgs, AssignRoleArgs } from '../../Modals/RoleModal'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardFormHeader from '../../Card/CardFormHeader'
import ContainedButton, {
  ContainedButtonVariant,
} from '../../Buttons/ContainedButton'
import { useShowOnDesktop } from '../../../hooks/useShowOnDesktop'
import {
  extractedErrorObject,
  regionsApi,
  userAccountsApi,
} from '../../../api/swagger'
import ConfirmationModal from '../../Modals/ConfirmationModal'
import ActionButtons from '../../Buttons/ActionButtons'
import { Can } from '@casl/react'
import { useAuth } from '../../Routes/AuthProvider'
import { useSnackbarContext } from '../../Context/SnackbarContext'
import { OperationIds } from '../../../swagger/operationIdEnum'
import { LoadingContext } from '../../Context/LoadingContext'
import { useNavigate } from 'react-router'
import { styled } from '@mui/system'
import { useLoadingIds } from '../../../hooks/useLoadingIds'
import useLoadingContext from '../../../hooks/useLoadingContext'
import { useMountEffect } from '../../../hooks/useMountEffect'
import {
  ActionableTable,
  ActionableTableColumn,
} from '../../Table/ActionableTable'
import { Action } from '../../Table/RowActions'
import { GridValueFormatterParams } from '@mui/x-data-grid'
import { useTheme } from '@mui/material'

const filename = 'RolesTable'

const RolesCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginLeft: theme.spacing(0),
  [theme.breakpoints.down('sm')]: {
    // Don't let the roles table get overlaid by buttons on mobile
    marginBottom: theme.spacing(12),
  },
}))

const RolesHeader = styled(Header)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(3),
}))

interface RolesTableProps {
  roles?: UserRoleAssignment[]
  roleOptions?: Role[]
  userAccounts: UserAccountListing[]
  userKey: number
  userAccount: UserAccountDetails
  removeRole: () => void
  triggerRefresh?: (actorKey?: number) => void
}

export interface UserRoleAssignment
  extends Omit<UserRoleAssignmentSchema, 'validTo'> {
  validTo?: Date
}

export const RolesTable: React.FC<RolesTableProps> = ({
  roles,
  roleOptions,
  userKey,
  userAccount,
  userAccounts,
  removeRole,
  triggerRefresh,
}) => {
  const theme = useTheme()
  const { permissionAbility } = useAuth()
  const { t } = useTranslation()
  const isShowOnDesktop = useShowOnDesktop()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const assignRoleLoadingId = `${filename}-${OperationIds.AssignRole}`
  const editRoleLoadingId = `${filename}-${OperationIds.EditRole}`
  const { removeLoadingIds } = React.useContext(LoadingContext)
  const navigate = useNavigate()

  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false)

  const errorMessage = t('UserAccount.Error', 'Something went wrong.')

  const [rolesRows, setRolesRows] = useState<UserRoleAssignment[]>([])

  const [rolesMap, setRolesMap] = useState(
    new Map<number /* actorKey */, UserRoleAssignment /* role information */>()
  )
  const [selectedRole, setSelectedRole] = useState<
    UserRoleAssignment | undefined
  >()

  const [roleChanged, setRoleChanged] = useState(true)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const { addLoadingIds } = React.useContext(LoadingContext)

  const availableLoadingIds = useLoadingIds()

  const [userRegionsForRoleModal, setUserRegionsForRoleModal] = useState<
    Region[]
  >([])

  const handleEditRoleClick = (actorKey: number) => {
    setIsRoleModalOpen(true)
    setSelectedRole(rolesMap.get(actorKey))
  }

  useMountEffect(() => {
    if (permissionAbility.can('view', 'Region')) {
      addLoadingIds([availableLoadingIds.Regions.fetchRegions])
    }
  })

  const fetchUserRegionsForRoleModal = async () => {
    try {
      const userRegionsForRoleModal = await regionsApi.fetchRegions({})

      setUserRegionsForRoleModal(userRegionsForRoleModal.regions)
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }
  }

  useLoadingContext({
    asyncFunction: fetchUserRegionsForRoleModal,
    loadingId: availableLoadingIds.Regions.fetchRegions,
  })

  useEffect(() => {
    if (!!roles) {
      const map = new Map<
        number /* actorKey */,
        UserRoleAssignment /* role information */
      >()
      roles.forEach((role) => {
        map.set(role.actorKey, role)
      })
      setRolesMap(map)
    }
  }, [roles])

  useEffect(() => {
    if (!!rolesMap || !!roleChanged) {
      const rows: UserRoleAssignment[] = []
      rolesMap.forEach((role) => {
        rows.push(role)
      })
      setRolesRows(rows)
      setRoleChanged(false)
    }
  }, [rolesMap, roleChanged])

  const updateRoleForParent = (role: UserRoleAssignment) => {
    rolesMap.set(role.actorKey, role)
    setRolesMap(rolesMap)
    setRoleChanged(true)
    setIsRoleModalOpen((isOpen) => !isOpen)
  }

  const closeRoleModal = () => {
    setIsRoleModalOpen(false)
    setSelectedRole(undefined)
    triggerRefresh?.()
  }

  const [actorKeyToRemove, setActorKeyToRemove] = useState(-1)

  const handleRemoveRole = (actorKey: number) => {
    setActorKeyToRemove(actorKey)
    setSelectedRole(rolesMap.get(actorKey))
    setShowConfirmationModal(true)
  }

  const handleAcceptConfirmation = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
    const roleActorKey = userAccount.roles.filter(
      (role) => role.actorKey === actorKeyToRemove
    )
    try {
      await userAccountsApi.unassignRole({
        userKey,
        actorKey: roleActorKey[0].actorKey,
      })
      removeRole()
      setShowConfirmationModal(false)
      setSnackbarState?.(true)
      setSnackbarMessage?.(
        t(
          'RolesTable.RoleModal.ValidationMessage.Success',
          'Successfully removed role.'
        )
      )
      setSnackbarSeverity?.(SnackbarSeverity.Success)
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'RolesTable.RoleModal.ValidationMessage.AddTransaction',
            'Something went wrong while removing the role.'
          ),
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObj.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }
  }

  const handleEditRole = async ({
    actorKey,
    roleKey,
    supervisorActorKey,
    supervisorName,
    roleName,
    validFrom,
    validTo,
    regionKey,
    region,
  }: EditRoleArgs) => {
    try {
      await userAccountsApi.editRole({
        userKey,
        actorKey,
        body: {
          supervisorActorKey: supervisorActorKey as number, // we guarantee this is defined by disabling save button if it isn't
          validFrom,
          validTo,
          regionKey,
        },
      })

      setSnackbarState?.(true)
      setSnackbarMessage?.(
        t('Roles.EditRole.SuccessMessage', 'Role successfully updated.')
      )
      setSnackbarSeverity?.(SnackbarSeverity.Success)

      // We only update the changes made
      updateRoleForParent({
        actorKey,
        roleKey,
        region,
        regionKey,
        name: roleName,
        supervisor: supervisorName,
        validFrom,
        validTo,
      })
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    } finally {
      closeRoleModal()
    }
  }

  const handleAssignRole = async ({
    roleKey,
    supervisorActorKey,
    supervisorName,
    roleName,
    validFrom,
    validTo,
    regionKey,
    region,
  }: AssignRoleArgs) => {
    try {
      const response = await userAccountsApi.assignRole({
        roleKey,
        userKey,
        body: {
          supervisorActorKey: supervisorActorKey as number, // we guarantee this is defined by disabling save button if it isn't
          validFrom,
          validTo,
          regionKey,
        },
      })

      setSnackbarState?.(true)
      setSnackbarMessage?.(
        t('Roles.AssignRole.SuccessMessage', 'Role successfully assigned.')
      )
      setSnackbarSeverity?.(SnackbarSeverity.Success)

      updateRoleForParent({
        actorKey: response.actorKey,
        roleKey,
        name: roleName,
        region,
        regionKey,
        supervisor: supervisorName,
        validFrom,
        validTo,
      })
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message ?? errorMessage,
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    } finally {
      closeRoleModal()
    }
  }

  const headerMessage = `${t(
    'RolesTable.ConfirmationModal.Header.RemoveRole',
    'You are about to remove the role {{selectedRole}} for this user.',
    { selectedRole: selectedRole?.name, interpolation: { escapeValue: false } }
  )}`

  const confirmationBodyMessage = (
    <Typography variant="body1" component="p" align="center">
      {t(
        'ConfirmationModal.ConfirmationModal.Body',
        'This action is not reversible and by confirming “Yes” an email will be sent informing the user, team leaders, HR and Accounting that the role has been ended.'
      )}
    </Typography>
  )

  const handleConfirmationCancel = async () => {
    setShowConfirmationModal(false)
  }

  const confirmationButtons = (
    <ActionButtons
      primaryButtonLabel={ContainedButtonVariant.YesRemove}
      secondaryButtonLabel={TextButtonVariant.NoCancel}
      secondaryClick={handleConfirmationCancel}
    />
  )

  const roleRemovalConfirmationProps = {
    isOpen: showConfirmationModal,
    dialogTitle: headerMessage,
    dialogContent: confirmationBodyMessage,
    dialogActions: confirmationButtons,
    handleFormSubmit: handleAcceptConfirmation,
  }

  const primaryButtonCallback = () => {
    !!selectedRole
      ? removeLoadingIds([editRoleLoadingId])
      : removeLoadingIds([assignRoleLoadingId])
  }

  const navigateToSupervisorAccountPage = (actorKey?: number) => {
    if (actorKey) {
      navigate({
        pathname: `/admin/user-accounts/${actorKey}`,
      })
    }
  }

  const navigateToTeam = (row: UserRoleAssignment | undefined) => {
    if (row) {
      navigate(
        {
          pathname: '/team',
        },
        {
          state: {
            userKey,
            actorKey: row.actorKey,
            firstName: userAccount.firstName,
            lastName: userAccount.lastName,
          },
        }
      )
    }
  }

  const minWidthOnDesktop = isShowOnDesktop ? { minWidth: 300 } : {}

  const actionableTableColumnsHeader: ActionableTableColumn[] = [
    {
      fieldName: 'name',
      columnHeaderName: t('UserAccounts.Roles.Table.Header.Role', 'Role'),
      ...{ ...minWidthOnDesktop },
    },
    {
      fieldName: 'actorKey',
      columnHeaderName: t(
        'UserAccounts.Roles.Table.Header.ActorKey',
        'Actor Key'
      ),
    },
    {
      fieldName: 'validFrom',
      columnHeaderName: t(
        'UserAccounts.Roles.Table.Header.StartDate',
        'Start Date'
      ),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return dateToSlashStringReinterpretedAsLocal(params.value)
      },
    },
    {
      fieldName: 'validTo',
      columnHeaderName: t(
        'UserAccounts.Roles.Table.Header.EndDate',
        'End Date'
      ),
      valueFormatter: (params: GridValueFormatterParams<Date>) => {
        return dateToSlashStringReinterpretedAsLocal(params.value)
      },
    },
    {
      fieldName: 'supervisor',
      columnHeaderName: t(
        'UserAccounts.Roles.Table.Header.TeamLeader',
        'Team Leader'
      ),
      valueFormatter: (
        params: GridValueFormatterParams<string | undefined>
      ) => {
        return params.value ?? t('Admin.RolesTable.Supervisor.None', 'None')
      },
    },
  ]

  const rowActions: Action<UserRoleAssignment>[] = [
    {
      actionName: t('Admin.RolesTable.RowAction.Edit', 'Edit'),
      actionKey: 'edit',
      actionFunction: (row) => handleEditRoleClick(row?.actorKey ?? 0),
      hide: (row) => {
        const isValidRole = (row?.validTo as Date) > new Date()
        const canEditExpiredRoles = permissionAbility.can(
          'editExpiredRoles',
          'User'
        )
        /** If we are not valid in the future or we don't have the ability to edit expired roles */
        return !isValidRole && !canEditExpiredRoles
      },
    },
    {
      actionName: t('Admin.RolesTable.RowAction.Remove', 'Remove'),
      actionKey: 'remove',
      actionFunction: (row) => handleRemoveRole(row?.actorKey ?? 0),
      hide: () => !permissionAbility.can('unassignRole', 'User'),
    },
    {
      actionName: t(
        'Admin.RolesTable.RowAction.ViewSupervisor',
        'View Supervisor'
      ),
      actionKey: 'viewSupervisor',
      actionFunction: (row) =>
        navigateToSupervisorAccountPage(row?.supervisorUserKey),
      hide: () => !permissionAbility.can('viewSupervisor', 'User'),
    },
    {
      actionName: t('Admin.RolesTable.RowAction.ViewTeam', 'View Team'),
      actionKey: 'viewTeam',
      actionFunction: (row) => navigateToTeam(row),
      hide: () => !permissionAbility.can('view', 'User'),
    },
  ]

  return (
    <>
      <ConfirmationModal {...roleRemovalConfirmationProps} />
      <RoleModal
        regions={userRegionsForRoleModal}
        isOpen={isRoleModalOpen}
        onClose={closeRoleModal}
        initialRole={selectedRole}
        roleOptions={roleOptions}
        userAccounts={userAccounts}
        handleEditRole={handleEditRole}
        handleAssignRole={handleAssignRole}
        primaryButtonLoadingId={
          !!selectedRole ? editRoleLoadingId : assignRoleLoadingId
        }
        primaryButtonCallback={primaryButtonCallback}
      />

      <RolesCard>
        <CardFormHeader
          header={
            <RolesHeader
              id="rolesTableHeader"
              headerName={t('UserAccountCard.Header.Roles', 'Roles')}
              component="h3"
              variant={HeaderVariant.Card}
            />
          }
          buttons={
            <Can I={'accessRolesTab'} on="Admin" ability={permissionAbility}>
              <Box mr={2} mt={3} ml={isShowOnDesktop ? 0 : 2}>
                <ContainedButton
                  id="assignRoleToUserButton"
                  variant={ContainedButtonVariant.AssignRole}
                  fullWidth={!isShowOnDesktop}
                  onClick={() => setIsRoleModalOpen(true)}
                />
              </Box>
            </Can>
          }
          useDivider
        />
        <ActionableTable
          columns={actionableTableColumnsHeader}
          rows={rolesRows}
          noResultsMessage={t(
            'UserAccount.Roles.Table.NoRoles',
            'No roles assigned to this user'
          )}
          rowActions={rowActions}
          customCssTable={{ paddingLeft: theme.spacing(0.8) }}
        />
      </RolesCard>
    </>
  )
}

export default RolesTable
