import { AgreementRecord, InviteTeamMemberOptions } from '../swagger'
import { teams } from './swagger'

export const inviteTeamMember = async (
  agreementId: string
): Promise<AgreementRecord> => {
  try {
    const result = await teams.inviteTeamMember({ body: { agreementId } })
    return result
  } catch (e) {
    throw new Error(
      `Error occurred while inviting a team member: ${
        (e as unknown as Error).message
      }`
    )
  }
}

export const fetchTeamInviteOptions =
  async (): Promise<InviteTeamMemberOptions> => {
    try {
      const result = await teams.fetchInviteTeamMemberOptions({})
      return result
    } catch {
      return {
        roleAgreementTemplates: [],
        roleAgreementDocuments: [],
        defaultRoleValidToDate: new Date(),
      }
    }
  }

export const expireTeamMember = async (actorKey: number): Promise<void> => {
  try {
    await teams.expireTeamMember({ body: { actorKey } })
  } catch (e) {
    throw e
  }
}
