import { useEffect } from 'react'
import { useSnackbarContext } from '../components/Context/SnackbarContext'
import { SnackbarSeverity } from '../components/Alerts/SnackbarAlert'

const useSetSnackbarProps = (options: {
  snackbarContents: {
    snackbarMessage: string
    snackbarSeverity: SnackbarSeverity
  }
}): { handleClose: () => void } => {
  const { snackbarContents } = options
  const { snackbarMessage, snackbarSeverity } = snackbarContents
  const {
    setSnackbarState,
    setSnackbarMessage,
    setSnackbarSeverity,
    handleClose,
  } = useSnackbarContext()

  /**
   * If error fetching enrollments, display snack bar alert
   */
  useEffect(() => {
    if (!!snackbarMessage && !!snackbarSeverity) {
      setSnackbarState?.(true)
      setSnackbarMessage?.(snackbarMessage)
      setSnackbarSeverity?.(snackbarSeverity)
    }
  }, [
    snackbarMessage,
    snackbarSeverity,
    setSnackbarState,
    setSnackbarMessage,
    setSnackbarSeverity,
  ])

  return { handleClose }
}

export default useSetSnackbarProps
