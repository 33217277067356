/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { ProgramType, ProgramTypeFromJSON, ProgramTypeToJSON } from './'

/**
 *
 * @export
 * @interface CoordinatorLicensingBilling
 */
export interface CoordinatorLicensingBilling {
  /**
   * The name of the community managed by the coordinator.
   * @type {string}
   * @memberof CoordinatorLicensingBilling
   */
  community: string
  /**
   *
   * @type {ProgramType}
   * @memberof CoordinatorLicensingBilling
   */
  program: ProgramType
  /**
   * The program unique identifier.
   * @type {number}
   * @memberof CoordinatorLicensingBilling
   */
  programKey?: number
  /**
   * The first name of the program director.
   * @type {string}
   * @memberof CoordinatorLicensingBilling
   */
  directorFirstName: string
  /**
   * The last name of the program director.
   * @type {string}
   * @memberof CoordinatorLicensingBilling
   */
  directorLastName: string
  /**
   * The start date of the program first semester.
   * @type {Date}
   * @memberof CoordinatorLicensingBilling
   */
  semesterOneStartDate: Date
  /**
   * The licensing amount owed by the director.
   * @type {number}
   * @memberof CoordinatorLicensingBilling
   */
  directorOwes: number
  /**
   * The licensing amount owed by the coordinator.
   * @type {number}
   * @memberof CoordinatorLicensingBilling
   */
  coordinatorOwes: number
}

export function CoordinatorLicensingBillingFromJSON(
  json: any
): CoordinatorLicensingBilling {
  return CoordinatorLicensingBillingFromJSONTyped(json, false)
}

export function CoordinatorLicensingBillingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CoordinatorLicensingBilling {
  if (json === undefined || json === null) {
    return json
  }
  return {
    community: json['community'],
    program: ProgramTypeFromJSON(json['program']),
    programKey: !exists(json, 'programKey') ? undefined : json['programKey'],
    directorFirstName: json['directorFirstName'],
    directorLastName: json['directorLastName'],
    semesterOneStartDate: new Date(json['semesterOneStartDate']),
    directorOwes: json['directorOwes'],
    coordinatorOwes: json['coordinatorOwes'],
  }
}

export function CoordinatorLicensingBillingToJSON(
  value?: CoordinatorLicensingBilling | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    community: value.community,
    program: ProgramTypeToJSON(value.program),
    programKey: value.programKey,
    directorFirstName: value.directorFirstName,
    directorLastName: value.directorLastName,
    semesterOneStartDate: value.semesterOneStartDate
      .toISOString()
      .substr(0, 10),
    directorOwes: value.directorOwes,
    coordinatorOwes: value.coordinatorOwes,
  }
}
