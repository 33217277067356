import Typography from '@mui/material/Typography'
import React from 'react'
import { SxProps, useTheme } from '@mui/material/styles'

interface AdjacentLabelsProps {
  leftLabel: string
  rightLabel: string
  leftStyle?: SxProps
  rightStyle?: SxProps
}

export const AdjacentLabels: React.FC<AdjacentLabelsProps> = (props) => {
  const theme = useTheme()
  const { leftLabel, rightLabel, leftStyle, rightStyle } = props

  return (
    <Typography
      sx={leftStyle}
      variant="subtitle2"
      color={theme.palette.primary.dark}
    >
      {leftLabel}:&nbsp;
      <Typography
        variant="body2"
        component="span"
        fontWeight={700}
        sx={rightStyle}
        color={theme.palette.primary.main}
      >
        {rightLabel}
      </Typography>
    </Typography>
  )
}

export default AdjacentLabels
