import React, { useContext } from 'react'
import { Page } from '../Elements/PageMargins'
import { useLocation, useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import ProgramFormCard, {
  ProgramFormCardVariants,
} from '../Card/ProgramFormCard'
import { Program, ProgramStatus, ProgramType } from '../../swagger'
import TitleContext from '../../TitleContext'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { Box } from '@mui/material'

const emptyProgramDetails: Program = {
  programsKey: -1,
  type: ProgramType.Foundations,
  status: ProgramStatus.Draft,
  communityId: -1,
  communityName: '',
  semesterOneStartDate: new Date(),
  semesterTwoStartDate: new Date(),
  address: {
    locationName: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    zip: '',
    countryCode: '',
  },
}

interface AddProgramProps {
  /**
   * Optional program details to make testing easier
   * By providing details during a test, we spend less computation selecting
   * data and typing it.
   */
  programDetails?: Program
}

const AddProgram: React.FC<AddProgramProps> = ({ programDetails }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const title = t('Programs.AddProgram.Header', 'Programs')
  const location: { search: string; state: { communityKey: string } } =
    useLocation()
  const communityId = new URLSearchParams(location.state).get('communityKey')
  const search = new URLSearchParams(location.search).toString()

  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const handleRedirect = () => {
    if (!!communityId) {
      navigate({
        pathname: `/communities/community-details/${communityId}/programs`,
        search,
      })
    } else {
      setSnackbarMessage?.(
        t(
          'AddProgram.Snackbar.Error',
          'Could not redirect to community-details'
        )
      )
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarState?.(true)
    }
  }

  return (
    <Page>
      <DynamicBreadcrumbs
        breadcrumbs={[
          {
            label: t('Programs.AddProgram.BreadCrumb', 'Programs'),
            onClick: handleRedirect,
          },
          {
            label: t('Programs.AddProgram.BreadCrumb', 'Add a Program'),
          },
        ]}
      />
      <Box maxWidth={1200}>
        <ProgramFormCard
          variant={ProgramFormCardVariants.AddProgram}
          handleCancel={handleRedirect}
          programDetails={programDetails ?? emptyProgramDetails}
        />
      </Box>
    </Page>
  )
}

export default AddProgram
