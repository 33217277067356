import {
  List,
  ListItemButton,
  ListItemText,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import privacyIcon from '../../Images/privacyoptions.svg'

const privacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL ?? ''
const privacyChoicesUrl = process.env.REACT_APP_PRIVACY_CHOICES ?? ''
const noticeAtCollectionUrl = process.env.REACT_APP_NOTICE_AT_COLLECTION ?? ''
const termsOfUseUrl = process.env.REACT_APP_TERMS_OF_USE_URL ?? ''

interface Link {
  label: string
  url: string
  testId: string
  sx?: React.CSSProperties
}

const ListWrapper = styled(List)({
  display: 'grid',
  gridTemplateColumns: '0.8fr 1fr',
  gap: '0.5rem',
  rowGap: '0',
})

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 4,
  padding: 0,
  '&:hover, &:focus': {
    background: theme.palette.hover.sidebar,
  },
  '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
    background: theme.palette.hover.main,
  },
}))

// Function to handle link click
const handleClick = (url: string) => () => {
  window.open(url, '_blank')
}

const ListPrivacyLinks: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const links: Link[] = [
    {
      label: 'Terms',
      url: termsOfUseUrl,
      testId: 'termsOfUse',
      sx: {
        textAlign: 'right',
      },
    },
    {
      label: 'Privacy Policy',
      url: privacyPolicyUrl,
      testId: 'privacyPolicy',
      sx: {
        textAlign: 'left',
      },
    },
    {
      label: 'Notice at Collection',
      url: noticeAtCollectionUrl,
      testId: 'NoticeCollection',
      sx: {
        textAlign: 'right',
      },
    },
    {
      label: 'Your Privacy Choices',
      url: privacyChoicesUrl,
      testId: 'privacyChoices',
      sx: {
        textAlign: 'left',
      },
    },
    // Add more links as needed
  ]

  return (
    <ListWrapper>
      {links.map(({ label, url, testId, sx = {} }) => (
        <StyledListItemButton
          key={testId}
          data-testid={testId}
          onClick={handleClick(url)}
          sx={sx}
        >
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                component="p"
                sx={{
                  fontSize: theme.spacing(1.1),
                }}
              >
                {t('Sidebar.Link.{{label}}', '{{label}}', {
                  label,
                })}
                {url === privacyChoicesUrl && (
                  <img
                    alt={t('privacy.link.AltText', 'CCPA Opt-Out Icon')}
                    src={privacyIcon}
                    style={{
                      width: theme.spacing(2.3),
                      height: theme.spacing(2.3),
                      marginLeft: theme.spacing(0.5),
                    }}
                  />
                )}
              </Typography>
            }
          />
        </StyledListItemButton>
      ))}
    </ListWrapper>
  )
}

export default ListPrivacyLinks
