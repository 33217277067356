import { useContext } from 'react'
import useLoadingContext from './useLoadingContext'
import { LoadingContext } from '../components/Context/LoadingContext'
import { useLoadingIds } from './useLoadingIds'
import { extractedErrorObject, transcriptsApi } from '../api/swagger'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'
import { useTranscriptContext } from '../components/Context/TranscriptContext'
import { useTranslation } from 'react-i18next'
import { useMountEffect } from './useMountEffect'

export interface FetchTranscriptHookProps {
  transcriptKey?: number
}

export const useFetchGradingScales = (): {
  loadingId: string
} => {
  const { UseFetchTranscripts } = useLoadingIds()
  const loadingId = UseFetchTranscripts.fetchGradingScales
  const { addLoadingIds } = useContext(LoadingContext)
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { updateGradingScales, updateTranscriptDetails, transcriptDetails } =
    useTranscriptContext()
  const { t } = useTranslation()
  const errorMessage = t(
    'Hooks.FetchGradingScales.Error.Default',
    'An error occurred while attempting to load grading scales'
  )
  const fetchGradingScales = async () => {
    try {
      const { gradingScales } = await transcriptsApi.fetchGradingScales({})
      updateGradingScales(gradingScales)
      // No gradingScaleKey? Set one!
      if (!transcriptDetails.gradingScaleKey) {
        updateTranscriptDetails({
          ...transcriptDetails,
          gradingScaleKey: gradingScales[0].gradingScaleKey,
        })
      }
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: fetchGradingScales,
    loadingId,
  })

  useMountEffect(() => {
    addLoadingIds([loadingId])
  })

  return { loadingId }
}
