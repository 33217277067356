import { EffectCallback, useEffect } from 'react'

/**
 * Provide a function to this hook which is returned within a useEffect to be
 * completed before the component unmounts.
 */
export const useUnmountEffect = (fn: EffectCallback): void =>
  useEffect(() => {
    return () => {
      fn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
