import React from 'react'
import Divider from '@mui/material/Divider'
import { SxProps, useTheme } from '@mui/material'

interface FormDividerProps {
  sx?: SxProps
}

export const FormDivider: React.FC<FormDividerProps> = ({ sx }) => {
  const theme = useTheme()

  return (
    <Divider
      variant="middle"
      sx={{
        margin: theme.spacing(3, 0),
        backgroundColor: theme.palette.customBackground.divider,
        gridColumn: '1 / span 2',
        ...sx,
      }}
    />
  )
}
