import { Box, Card, Grid, Switch, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Header, { HeaderVariant } from '../Elements/Header'
import CardFormHeader from './CardFormHeader'

const UserNotifications: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardFormHeader
        header={
          <Header
            variant={HeaderVariant.SettingsHeader}
            id="notifications"
            headerName={t('AccountSettingsTab.Notifications', 'Notifications')}
            component="h3"
          />
        }
        headerContainerProps={{ paddingX: 1 }}
      />
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item xs={2} />
          <Grid item xs={1}>
            <Typography variant="body1">
              {t('AccountSettingsTab.InAppNotification', 'In-App')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1">
              {t('AccountSettingsTab.EmailNotification', 'Email')}
            </Typography>
          </Grid>
          <Grid item xs={8} />
        </Grid>
        <UserNotificationsRow
          text={t('AccountSettingsTab.BusinessNotifications', 'Business')}
        />
        <UserNotificationsRow
          text={t(
            'AccountSettingsTab.LearningCenterNotications',
            'Learning Center'
          )}
        />
        <UserNotificationsRow
          text={t('AccountSettingsTab.ForumsNotifications', 'Forums')}
        />
        <UserNotificationsRow
          text={t('AccountSettingsTab.EventsNotifications', 'Events')}
        />
        <UserNotificationsRow
          text={t('AccountSettingsTab.BillingNotifications', 'Billing')}
        />
      </Box>
    </Card>
  )
}

export default UserNotifications

interface UserNotificationsProps {
  text: string
}

const UserNotificationsRow: React.FunctionComponent<UserNotificationsProps> = (
  props
) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={2}>
        <Typography variant="body1">{props.text}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Switch />
      </Grid>
      <Grid item xs={1}>
        <Switch />
      </Grid>
      <Grid item xs={8} />
    </Grid>
  )
}
