import {
  Box,
  Card,
  Grid,
  TableContainer,
  Typography,
  useTheme,
} from '@mui/material'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import CardFormHeader from './CardFormHeader'
import Header, { HeaderVariant } from '../Elements/Header'
import { useMountEffect } from '../../hooks/useMountEffect'
import {
  InviteTabFlowStages,
  useAccountContext,
} from '../Context/AccountContext'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { LoadingContext } from '../Context/LoadingContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { EditStudentRequestBody, Student } from '../../swagger'
import LedgerTable, { LedgerRow } from '../Table/LedgerTable'
import TableHeaders from '../Interfaces/TableHeaders'
import MonthDropDown from '../Menus/MonthDropDown'
import { addLeadingZero } from '../../helpers/monthsAndDays'
import YearDropDown from '../Menus/YearDropDown'
import LoadingProgress from '../Elements/LoadingProgress'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useNavigate } from 'react-router'
import { extractedErrorObject, studentApi } from '../../api/swagger'
import { useNotistackSnackbarKeyContext } from '../Context/NotistackSnackbarKeyProvider'

const UpdateChildrenInfoCard: React.FC = () => {
  const { t } = useTranslation()
  const isDesktop = useShowOnDesktop()
  const navigate = useNavigate()
  const { UpdateChildrenInfoCard } = useLoadingIds()
  const theme = useTheme()

  const { acceptedPrograms, students, updateBreadcrumbs, updateStudents } =
    useAccountContext()

  const { loadingIds, addLoadingIds } = useContext(LoadingContext)

  const { addSnackbarKey, enqueueSnackbar } = useNotistackSnackbarKeyContext()

  const [draftStudents, setDraftStudents] = useState<Student[]>([])

  const saveChildrenInfoLoadingId = UpdateChildrenInfoCard.editStudent

  useMountEffect(() => {
    updateBreadcrumbs(InviteTabFlowStages.UpdateChildrenInfo)
  })

  useEffect(() => {
    const assignedStudents = students.filter((student) =>
      acceptedPrograms.some(
        (program) => program.studentKey === student.studentKey
      )
    )

    setDraftStudents(assignedStudents)
  }, [students, acceptedPrograms])

  const isFormCompleted = useMemo(() => {
    return draftStudents.every(
      (student) =>
        student.birthMonth !== undefined && student.birthYear !== undefined
    )
  }, [draftStudents])

  const handleSelection = useCallback(
    (selected: { name: string; id: string; dropDownId: string }): void => {
      const { id, dropDownId } = selected
      const [key, studentKey] = dropDownId.split('-')

      setDraftStudents((prevDraftStudents) =>
        prevDraftStudents.map((student) => {
          if (student.studentKey === Number(studentKey)) {
            return {
              ...student,
              [key]: Number.isNaN(Number(id)) ? undefined : parseInt(id),
            }
          }
          return student
        })
      )
    },
    [setDraftStudents]
  )

  const tableHeaders: TableHeaders[] = [
    {
      label: t(
        'UpdateChildrenInfoCard.TableHeaders.StudentName',
        'Student Name'
      ),
      align: 'left',
      cssProps: { padding: 0, width: '60%' },
    },
    {
      label: t('UpdateChildrenInfoCard.TableHeaders.BirthMonth', 'Birth Month'),
      align: 'center',
      cssProps: { padding: 0 },
    },
    {
      label: t('UpdateChildrenInfoCard.TableHeaders.BirthYear', 'Birth Year'),
      align: 'center',
      cssProps: { padding: 0 },
    },
  ]

  const rows: LedgerRow[] = draftStudents.map((student) => {
    return {
      cells: [
        {
          content: (
            <Typography
              variant="subtitle2"
              color={theme.palette.textOrIcon.tableHeader}
              fontWeight={'bold'}
            >
              {`${student.firstName}`}
            </Typography>
          ),
          align: 'left',
          cellCssProps: { width: '60%' },
        },
        {
          content: (
            <Typography
              variant="subtitle2"
              color={theme.palette.textOrIcon.tableHeader}
              fontWeight={'bold'}
            >
              <MonthDropDown
                id={`birthMonth-${student.studentKey}`}
                value={`${addLeadingZero(Number(student.birthMonth) + 1)}`}
                handleSelection={handleSelection}
              />
            </Typography>
          ),
          align: 'left',
        },
        {
          content: (
            <Typography
              variant="subtitle2"
              color={theme.palette.textOrIcon.tableHeader}
              fontWeight={'bold'}
            >
              <YearDropDown
                id={`birthYear-${student.studentKey}`}
                value={student.birthYear?.toString()}
                handleSelection={handleSelection}
              />
            </Typography>
          ),
          align: 'left',
        },
      ],
    }
  })

  const handleSubmission = () => {
    addLoadingIds([saveChildrenInfoLoadingId])
  }

  const saveChildrenInfo = async () => {
    const updateChildrenResults = await Promise.allSettled(
      draftStudents.map(async (student) => {
        try {
          return await studentApi.editStudent({
            body: {
              birthMonth: Number(student.birthMonth),
              birthYear: Number(student.birthYear),
              studentKey: student.studentKey,
              firstName: student.firstName,
            },
          })
        } catch (e) {
          const errorObj = (await extractedErrorObject(e)) ?? {
            code: 'Unknown',
            message:
              (e as unknown as Error).message ??
              t(
                'UpdateChildrenInfoCard.Error.SaveChanges',
                'An unknown error occurred attempting to save children info.'
              ),
          }

          throw new Error(errorObj.message).message
        }
      })
    )

    const updateRejections = updateChildrenResults.filter(
      (it) => it.status === 'rejected'
    )
    const updateFulfillments = updateChildrenResults.filter(
      (it) => it.status === 'fulfilled'
    ) as PromiseFulfilledResult<EditStudentRequestBody>[]

    if (updateRejections.length === 0) {
      // Update the students with the new birth month and year
      updateStudents(
        students.map((student) => {
          const updatedStudent = updateFulfillments.find(
            (it) => it.value.studentKey === student.studentKey
          )

          if (updatedStudent) {
            return {
              ...student,
              birthMonth: updatedStudent.value.birthMonth,
              birthYear: updatedStudent.value.birthYear,
            }
          }

          return student
        })
      )
      navigate({ pathname: '/account/invites/enrollment-summary' })
    }

    const errorMessages: string[] = []
    for (const rejection of updateRejections) {
      errorMessages.push(`${(rejection as PromiseRejectedResult).reason} \n`)
    }
    for (const msg of errorMessages) {
      addSnackbarKey(enqueueSnackbar(msg, { variant: 'error' }))
    }
  }

  useLoadingContext({
    asyncFunction: saveChildrenInfo,
    loadingId: saveChildrenInfoLoadingId,
  })

  const disableContinueButton =
    loadingIds.has(saveChildrenInfoLoadingId) || !isFormCompleted

  if (loadingIds.has(saveChildrenInfoLoadingId)) {
    return <LoadingProgress />
  }

  return (
    <Card>
      <CardFormHeader
        header={
          <Header
            headerName={t(
              'UpdateChildrenInfoCard.Header.Title',
              'Update Birth Month & Year'
            )}
            component="h3"
            variant={HeaderVariant.Card}
          />
        }
      />

      <Box m={3}>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmission()
          }}
        >
          <Grid container spacing={3}>
            <Grid item container>
              <Grid item xs={12} lg={8} xl={6}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    textAlign: isDesktop ? 'left' : 'center',
                  }}
                >
                  {t(
                    'UpdateParentInfoCard.Form.SubTitle',
                    'In order to enroll these children we need some additional information.'
                  )}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={12}>
                <TableContainer
                  aria-labelledby="update-birth-month-and-year"
                  sx={{
                    width: 'auto',
                  }}
                >
                  <LedgerTable
                    ariaLabel="UpdateBirthMonthAndYear"
                    tableHeaders={tableHeaders}
                    rows={rows}
                  />
                </TableContainer>
              </Grid>
            </Grid>

            <Grid item display="flex" xs={12} justifyContent="right">
              <ContainedButton
                id="continueButton"
                variant={ContainedButtonVariant.Continue}
                disabled={disableContinueButton}
                type="submit"
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </Card>
  )
}

export default UpdateChildrenInfoCard
