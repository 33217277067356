import { StyleSheet } from '@react-pdf/renderer'
import { fontFamily } from '../../Account/Billing/BillingPDF/licensingBillingPdfStyles'

export const border = '1px solid black'
export const genericBoxWidth = '260px'
export const informationRowTextWidth = '185px'

export const transcriptPdfStyles = StyleSheet.create({
  box: {
    border: border,
    fontSize: '10px',
    width: genericBoxWidth,
    margin: '6px',
    paddingHorizontal: '4px',
    paddingVertical: '2px',
  },
  informationRow: {
    flexDirection: 'row',
    marginVertical: '1px',
  },
  documentDirection: {
    flexDirection: 'row',
  },
  tableDiv: {
    margin: '6px',
    fontSize: '8px',
    width: '160px',
  },
  tableData: {
    borderRight: border,
    paddingLeft: '1px',
    alignItems: 'center',
  },
  weightedText: {
    fontFamily,
    fontWeight: 'bold',
  },
})

export const informationOffset = 16
