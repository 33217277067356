import React from 'react'
import BasicModal from './BasicModal'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SentHistoryInfo } from '../../swagger'
import ViewSentHistoryTable from '../Table/ViewSentHistoryTable'

interface ViewSentHistoryModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  sentHistory: Array<SentHistoryInfo>
}

const ViewSentHistoryModal: React.FC<ViewSentHistoryModalProps> = ({
  isOpen,
  setIsOpen,
  sentHistory,
}) => {
  const { t } = useTranslation()
  const closeModal = () => setIsOpen(false)

  return (
    <BasicModal
      isOpen={isOpen}
      maxWidth="xs"
      handleFormSubmit={(e) => e.preventDefault()}
      ariaLabel="sent history"
      dialogContent={
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography component="p" variant="h5" mb={5}>
            {t('ViewSentHistory.Modal.Header', 'Sent History')}
          </Typography>

          {sentHistory.length ? (
            <ViewSentHistoryTable sentHistory={sentHistory} />
          ) : (
            <Typography component="p" variant="subtitle1" mb={5}>
              {t('ViewSentHistory.Modal.NoContent', 'No Sent History')}
            </Typography>
          )}
        </Box>
      }
      dialogActions={
        <Box display="flex" justifyContent="center" width="100%">
          <ContainedButton
            variant={ContainedButtonVariant.Close}
            id="viewSentHistoryClose"
            onClick={closeModal}
          />
        </Box>
      }
    />
  )
}

export default ViewSentHistoryModal
