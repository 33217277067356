/**
 * Takes input from a TextField and provides a cash friendly float
 * value.
 *
 * @param value input of the TextField
 * @returns string of cash amount to two decimal places
 */
export const amountFromInput = (value: string): string => {
  // Replace anything but numbers, and periods.
  const updatedValue = value.replace(/[^.0-9]/g, '')
  /**
   * Force decimal placing.
   *
   * If we receive an initial input of 1, this becomes 0.01.
   * Input right of the decimal shifts the amount left. Input is 0.012 and becomes 0.12
   * Input left of the decimal remains unmodified.
   */

  // Determine the index of decimal place
  const indexOfDecimal = updatedValue.indexOf('.')
  // Set the refined value initially to nothing
  let refinedValue = updatedValue

  // If we get 0, or '', let's just return 0.00
  if (!updatedValue) {
    return '0.00'
  }

  if (indexOfDecimal > 0) {
    // If a decimal exists, shift accordingly.
    const [left, right] = updatedValue.split('.')
    let newRight = right

    // If more than 2 decimals we already exclude anything past the second decimal

    // If we have more than two digits past the first decimal, get rid of the trailing digits
    if (right.length > 2) {
      newRight = right.substring(0, 2)
    }
    refinedValue = `${left}.${newRight}`
  } else if (indexOfDecimal === 0) {
    // Provide a leading 0 if we have a decimal first (such as .50)
    refinedValue = `0${updatedValue}`
  } else {
    // If the decimal was not found, add it and a leading 0, such as
    refinedValue = `${updatedValue}.00`
  }
  return refinedValue
}
