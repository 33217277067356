import React, { CSSProperties } from 'react'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import BaseButton from './BaseButton'
import { useTheme } from '@mui/material/styles'

export enum ContainedButtonVariant {
  Accept = 'accept',
  Add = 'add',
  AddAccount = 'addAccount',
  AddDiscount = 'addDiscount',
  AddFamily = 'addFamily',
  AddPayment = 'addPayment',
  AddProgram = 'addProgram',
  AddReason = 'AddReason',
  Assign = 'assign',
  AssignRole = 'assignRole',
  ClickForDetails = 'clickForDetails',
  Close = 'close',
  CompleteInvitation = 'completeInvitation',
  Confirm = 'confirm',
  ConfirmEnrollment = 'confirmEnrollment',
  Continue = 'continue',
  Create = 'create',
  CreateAccount = 'createAccount',
  Deactivate = 'deactivate',
  Delete = 'delete',
  Done = 'done',
  DownloadParchmentLearnerCsv = 'downloadParchmentLearnerCsv',
  Edit = 'edit',
  EditAddress = 'editAddress',
  EmailFamilies = 'emailFamilies',
  EmailPeopleISupport = 'emailPeopleISupport',
  GoToYourAccount = 'goToYourAccount',
  InviteToAProgram = 'inviteToAProgram',
  Invoice = 'invoice',
  LaunchFullscreen = 'launchFullscreen',
  Login = 'login',
  MakeAPayment = 'makeAPayment',
  Next = 'next',
  Ok = 'ok',
  Pay = 'pay',
  PayEnrollment = 'payEnrollment',
  Populate = 'populate',
  Publish = 'publish',
  Save = 'save',
  SaveRole = 'saveRole',
  SaveChanges = 'saveChanges',
  SaveChildren = 'saveChildren',
  Send = 'send',
  SendInvitation = 'sendInvitation',
  SendInvoice = 'sendInvoice',
  SendResetLink = 'sendResetLink',
  SendVerificationCode = 'sendVerificationCode',
  StartLearning = 'startLearning',
  TransferIn = 'transferIn',
  UpdateDiscount = 'updateDiscount',
  UpdatePayment = 'updatePayment',
  UpdateRefund = 'updateRefund',
  UseAnyway = 'useAnyway',
  UserAccount = 'userAccount',
  ViewEnrollmentSummary = 'viewEnrollmentSummary',
  ViewFamily = 'viewFamily',
  ViewTeam = 'viewTeam',
  YesCancel = 'yesCancel',
  YesDelete = 'yesDelete',
  YesDrop = 'yesDrop',
  YesImSure = 'yesImSure',
  YesProceed = 'yesProceed',
  YesPushAddressToAllPrograms = 'yesPushAddressToAllPrograms',
  YesRemove = 'yesRemove',
  YesResend = 'yesResend',
  YesSend = 'yesSend',
  YesTransfer = 'yesTransfer',
  MarkTranscriptsAsSent = 'MarkTranscriptsAsSent',
}

interface ContainedButtonProps {
  id: string
  variant: ContainedButtonVariant
  onClick?: () => void
  fullWidth?: boolean
  css?: CSSProperties
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  to?: string
  /**
   *  A boolean to determine if we use the BaseButton component for async behavior or the button within ContainedButton
   *  THIS IS A TEMPORARY FIX SO WE DON"T HAVE TO BREAK ALL BUTTONS AT ONCE. The idea behind this boolean is to slowly
   *  transition buttons to the async behavior in BaseButton without a massive overhaul. This will eventually be removed
   *  so DO NOT based anything on this other than the ternary, for now.
   */
  useBaseButton?: boolean
  /**
   * LoadingId of the primary button. This will be the operationId of the api call being made
   * concatenated with the Component's name. This is optional only now because we are using useBaseButton.
   * FIXME: When we no longer need base button, require this property.
   *
   * e.g. If this is from AuthFormCard calling the signin endpoint the loadingId will be
   *
   *    signingAuthFormCard
   */
  loadingId?: string
  /** The download prop's assignment. Currently used for downloading a CSV */
  download?: unknown
  /** Allow a button to act like a link. Combined with download means it'll download the object from the url*/
  href?: string
}

const labelForVariant = (variant: ContainedButtonVariant, t: TFunction) => {
  switch (variant) {
    case ContainedButtonVariant.Accept:
      return t('Buttons.Label.Accept', 'Accept')
    case ContainedButtonVariant.Add:
      return t('Buttons.Label.Add', 'Add')
    case ContainedButtonVariant.AddAccount:
      return t('Buttons.Label.AddAccount', 'Add Account')
    case ContainedButtonVariant.AddDiscount:
      return t('Buttons.Label.AddDiscount', 'Add Discount')
    case ContainedButtonVariant.AddFamily:
      return t('Buttons.Label.AddFamily', '+ Family')
    case ContainedButtonVariant.AddPayment:
      return t('Buttons.Label.AddPayment', 'Add Payment')
    case ContainedButtonVariant.AddProgram:
      return t('Buttons.Label.AddProgram', '+ Program')
    case ContainedButtonVariant.AddReason:
      return t('Buttons.Label.AddReason', 'Add Reason')
    case ContainedButtonVariant.Assign:
      return t('Buttons.Label.Assign', 'Assign')
    case ContainedButtonVariant.AssignRole:
      return t('Buttons.Label.AssignRole', 'Assign Role')
    case ContainedButtonVariant.ClickForDetails:
      return t('Buttons.Label.ClickForDetails', 'Click For Details')
    case ContainedButtonVariant.Close:
      return t('Buttons.Label.Close', 'Close')
    case ContainedButtonVariant.CompleteInvitation:
      return t('Buttons.Label.CompleteEnrollment', 'Complete Invitation')
    case ContainedButtonVariant.Confirm:
      return t('Buttons.Label.Confirm', 'Confirm')
    case ContainedButtonVariant.ConfirmEnrollment:
      return t('Buttons.Label.ConfirmEnrollment', 'Confirm Enrollment')
    case ContainedButtonVariant.Continue:
      return t('Buttons.Label.Continue', 'Continue')
    case ContainedButtonVariant.Create:
      return t('Buttons.Label.Create', 'Create')
    case ContainedButtonVariant.CreateAccount:
      return t('Buttons.Label.CreateAccount', 'Create Account')
    case ContainedButtonVariant.Deactivate:
      return t('Buttons.Label.Deactivate', 'Deactivate')
    case ContainedButtonVariant.Delete:
      return t('Buttons.Label.Delete', 'Delete')
    case ContainedButtonVariant.Done:
      return t('Buttons.Label.Done', 'Done')
    case ContainedButtonVariant.DownloadParchmentLearnerCsv:
      /** Buttons make these capital anyway, but CSV is Csv because of the button test */
      return t('Buttons.Label.Done', 'Download Parchment Learner Csv')
    case ContainedButtonVariant.Edit:
      return t('Buttons.Label.EditButton', 'Edit')
    case ContainedButtonVariant.EditAddress:
      return t('Buttons.Label.EditAddress', 'Edit Address')
    case ContainedButtonVariant.EmailFamilies:
      return t('Buttons.Label.EmailFamilies', 'Email Families')
    case ContainedButtonVariant.EmailPeopleISupport:
      return t('Buttons.Label.EmailPeopleISupport', 'Email People I Support')
    case ContainedButtonVariant.GoToYourAccount:
      return t('Buttons.Label.GoToYourAccount', 'Go To Your Account')
    case ContainedButtonVariant.InviteToAProgram:
      return t('Buttons.Label.InviteToAProgram', 'Invite To A Program')
    case ContainedButtonVariant.Invoice:
      return t('Buttons.Label.Invoice', 'Invoice')
    case ContainedButtonVariant.LaunchFullscreen:
      return t('Buttons.Label.LaunchFullscreen', 'Launch Fullscreen')
    case ContainedButtonVariant.Login:
      return t('Buttons.Label.Login', 'Login')
    case ContainedButtonVariant.MakeAPayment:
      return t('Buttons.Label.MakeAPayment', 'Make A Payment')
    case ContainedButtonVariant.Next:
      return t('Buttons.Label.Next', 'Next')
    case ContainedButtonVariant.Ok:
      return t('Buttons.Label.OK', 'Ok')
    case ContainedButtonVariant.Pay:
      return t('Buttons.Label.Pay', 'Pay')
    case ContainedButtonVariant.PayEnrollment:
      return t('Buttons.Label.PayEnrollment', 'Pay Enrollment')
    case ContainedButtonVariant.Populate:
      return t('Buttons.Label.Populate', 'Populate')
    case ContainedButtonVariant.Publish:
      return t('Buttons.Label.PublishButton', 'Publish')
    case ContainedButtonVariant.Save:
      return t('Buttons.Label.SaveButton', 'Save')
    case ContainedButtonVariant.SaveChanges:
      return t('Buttons.Label.SaveChanges', 'Save Changes')
    case ContainedButtonVariant.SaveChildren:
      return t('Buttons.Label.SaveChildren', 'Save Children')
    case ContainedButtonVariant.SaveRole:
      return t('Buttons.Label.SaveRole', 'Save Role')
    case ContainedButtonVariant.Send:
      return t('Buttons.Label.Send', 'Send')
    case ContainedButtonVariant.SendInvitation:
      return t('Buttons.Label.SendInvitation', 'Send Invitation')
    case ContainedButtonVariant.SendInvoice:
      return t('Buttons.Label.SendInvoice', 'Send Invoice')
    case ContainedButtonVariant.SendResetLink:
      return t('Buttons.Label.SendResetLink', 'Send Reset Link')
    case ContainedButtonVariant.SendVerificationCode:
      return t('Buttons.Label.SendVerificationCode', 'Send Verification Code')
    case ContainedButtonVariant.StartLearning:
      return t('Buttons.Label.StartLearning', 'Start Learning')
    case ContainedButtonVariant.TransferIn:
      return t('Buttons.Label.TransferIn', 'Transfer In')
    case ContainedButtonVariant.UpdateDiscount:
      return t('Buttons.Label.UpdateDiscount', 'Update Discount')
    case ContainedButtonVariant.UpdatePayment:
      return t('Buttons.Label.UpdatePayment', 'Update Payment')
    case ContainedButtonVariant.UpdateRefund:
      return t('Buttons.Label.UpdateRefund', 'Update Refund')
    case ContainedButtonVariant.UseAnyway:
      return t('Buttons.Label.UseAnyway', 'Use Anyway')
    case ContainedButtonVariant.UserAccount:
      return t('Buttons.Label.UserAccount', 'User Account')
    case ContainedButtonVariant.ViewEnrollmentSummary:
      return t('Buttons.Label.ViewEnrollmentSummary', 'View Enrollment Summary')
    case ContainedButtonVariant.ViewFamily:
      return t('Buttons.Label.ViewFamily', 'View Family')
    case ContainedButtonVariant.ViewTeam:
      return t('Buttons.Label.ViewTeam', 'View Team')
    case ContainedButtonVariant.YesCancel:
      return t('Buttons.Label.YesCancel', 'Yes, Cancel')
    case ContainedButtonVariant.YesDelete:
      return t('Buttons.Label.YesDelete', 'Yes, Delete')
    case ContainedButtonVariant.YesDrop:
      return t('Buttons.Label.YesCancel', 'Yes, Drop')
    case ContainedButtonVariant.YesImSure:
      return t('Buttons.Label.YesImSure', "Yes, I'm Sure")
    case ContainedButtonVariant.YesProceed:
      return t('Buttons.Label.YesProceed', 'Yes, Proceed')
    case ContainedButtonVariant.YesPushAddressToAllPrograms:
      return t(
        'Buttons.Label.yesPushToPrograms',
        'Yes, Push Address To All Programs'
      )
    case ContainedButtonVariant.YesRemove:
      return t('Buttons.Label.YesRemove', 'Yes, Remove')
    case ContainedButtonVariant.YesResend:
      return t('Buttons.Label.CancelAndResend', 'Yes, Resend')
    case ContainedButtonVariant.YesSend:
      return t('Buttons.Label.YesSend', 'Yes, Send')
    case ContainedButtonVariant.YesTransfer:
      return t('Buttons.Label.TransferIn', 'Yes, Transfer')
    case ContainedButtonVariant.MarkTranscriptsAsSent:
      return t(
        'Buttons.Label.MarkTranscriptsAsSent',
        'Mark Transcripts As Sent'
      )
    default:
      return t('Buttons.Label.Default', 'Unknown Button Variant')
  }
}

const ContainedButton: React.FC<ContainedButtonProps> = ({
  id,
  variant,
  onClick = undefined,
  fullWidth,
  css,
  type = 'button',
  disabled,
  to = undefined,
  useBaseButton = false,
  loadingId,
  download,
  href,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  let containedClass = {} as CSSProperties
  switch (variant) {
    case ContainedButtonVariant.Deactivate:
    case ContainedButtonVariant.Delete:
      containedClass = {
        color: theme.palette.textOrIcon.deactivateButton,
        background: theme.palette.customBackground.deactivateButton,
        margin: theme.spacing(0, 1),
        '&:hover': {
          backgroundColor: '#e9dddb',
        },
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(0.5, 0),
        },
      } as { [k: string]: unknown } as CSSProperties
      break
    default:
      containedClass = {
        fontSize: '14px',
        margin: theme.spacing(0, 1),
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(0.5, 0),
        },
      }
  }

  return useBaseButton ? (
    <BaseButton
      id={id}
      color="secondary"
      variant={'contained'}
      // OnClick is then handled in a useEffect where the async behavior occurs. The base button just sets the loadingContext value
      fullWidth={fullWidth}
      type={type}
      disabled={disabled}
      label={labelForVariant(variant, t)}
      css={{
        ...containedClass,
        ...css,
      }}
      to={to}
      download={download}
      loadingId={loadingId}
      href={href}
    />
  ) : (
    <Button
      id={id}
      color="secondary"
      variant="contained"
      onClick={onClick}
      sx={{
        ...containedClass,
        ...css,
      }}
      fullWidth={fullWidth}
      type={type}
      disabled={disabled}
      component={!!to ? Link : 'button'}
      to={to}
      download={download}
      href={href}
    >
      {labelForVariant(variant, t)}
    </Button>
  )
}

export default ContainedButton
