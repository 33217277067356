/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Describes a feature that can be granted to a role.
 *
 * Note the split between the code properties, intended as a controlled vocabulary for computer consumption, and the other properties, intended for display to humans. Each `[actionCode, resourceCode]` pair should be unique. (The codes could also be used to drive client-side localization of the other human-centric fields, which are only provided in English.)
 * @export
 * @interface LoginResponseFeatureDefaults
 */
export interface LoginResponseFeatureDefaults {
  /**
   * The action name used when checking if an actor is allowed to take this action on the targeted resource.
   * @type {string}
   * @memberof LoginResponseFeatureDefaults
   */
  actionCode: string
  /**
   * The type name of the resource used when checking if an actor is allowed to take this action on the targeted resource. This will ALWAYS be Feature
   * @type {string}
   * @memberof LoginResponseFeatureDefaults
   */
  resourceCode: string
}

export function LoginResponseFeatureDefaultsFromJSON(
  json: any
): LoginResponseFeatureDefaults {
  return LoginResponseFeatureDefaultsFromJSONTyped(json, false)
}

export function LoginResponseFeatureDefaultsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LoginResponseFeatureDefaults {
  if (json === undefined || json === null) {
    return json
  }
  return {
    actionCode: json['actionCode'],
    resourceCode: json['resourceCode'],
  }
}

export function LoginResponseFeatureDefaultsToJSON(
  value?: LoginResponseFeatureDefaults | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    actionCode: value.actionCode,
    resourceCode: value.resourceCode,
  }
}
