import { auth } from './swagger'
import { LoginResponseAuthorizationEnum } from '../swagger'

export const resetPassword = async (username: string): Promise<void> => {
  try {
    await auth.resetPassword({ body: { username } })
  } catch (e) {
    throw e
  }
}

export const changePassword = async (body: {
  oldPassword?: string
  resetToken?: string
  newPassword: string
}): Promise<{ success: boolean }> => {
  try {
    const result = await auth.changePassword({ body })
    const success =
      result.authorization === LoginResponseAuthorizationEnum.Success

    return { success }
  } catch (e) {
    throw e
  }
}
