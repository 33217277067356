import React from 'react'
import { useTheme } from '@mui/material'
import { SentHistoryInfo } from '../../swagger'
import { dateToSlashStringReinterpretedAsLocal } from '../../utils/dateUtility'
import TableHeaders from '../Interfaces/TableHeaders'
import LedgerTable, { LedgerRow } from './LedgerTable'
import { useTranslation } from 'react-i18next'
import getLocaleCurrencyForAmount from '../../utils/getLocaleCurrencyForAmount'

interface ViewSentHistoryTableProps {
  sentHistory: Array<SentHistoryInfo>
}

const ViewSentHistoryTable: React.FC<ViewSentHistoryTableProps> = ({
  sentHistory,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const commonLeftTableHeader: TableHeaders = {
    label: '',
    align: 'left',
    cssProps: {
      color: theme.palette.primary.main,
      paddingLeft: 0,
      borderLeft: '1px solid #fff',
      borderRight: '1px solid #fff',
    },
  }

  const commonInvoiceTableHeader: TableHeaders = {
    ...commonLeftTableHeader,
    align: 'center',
    cssProps: {
      ...commonLeftTableHeader.cssProps,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.hover.main,
    },
  }

  const commonCellCssProps = {
    padding: theme.spacing(2),
    width: '20%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  const rows: LedgerRow[] = sentHistory.map(
    ({ sentDate, totalAmount: { amount, currencyCode } }) => {
      return {
        cells: [
          {
            content: dateToSlashStringReinterpretedAsLocal(sentDate),
            align: 'center',
            color: 'medium',
            cellCssProps: commonCellCssProps,
          },
          {
            content: getLocaleCurrencyForAmount(amount, currencyCode),
            align: 'center',
            color: 'main',
            cellCssProps: commonCellCssProps,
          },
        ],
      }
    }
  )

  return (
    <LedgerTable
      cssProps={{ marginBottom: theme.spacing(0) }}
      striped
      tableHeaders={[
        {
          ...commonInvoiceTableHeader,
          label: t('Invoice.Header.SentDate', 'Sent Date'),
        },
        {
          ...commonInvoiceTableHeader,
          label: t('Invoice.Header.Amount', 'Amount'),
        },
      ]}
      rows={rows}
    />
  )
}

export default ViewSentHistoryTable
