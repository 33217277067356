import React from 'react'
import OutlinedButton, { OutlinedButtonVariant } from './OutlinedButton'
import { styled } from '@mui/system'

const ViewMoreButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(6),
}))

interface ViewMoreButtonProps {
  handleViewMoreClick: () => void
}

export const ViewMoreButton: React.FC<ViewMoreButtonProps> = ({
  handleViewMoreClick,
}) => {
  return (
    <ViewMoreButtonContainer>
      <OutlinedButton
        id="viewMoreButton"
        onClick={handleViewMoreClick}
        variant={OutlinedButtonVariant.ViewMore}
      />
    </ViewMoreButtonContainer>
  )
}

export default ViewMoreButton
