import { areDatesInRange, dateToDashString } from '../utils/dateUtility'

/**
 * Provides the opportunity to set min and max dates to validate a date is within
 * the range provided using validateDateRange.
 */
export const useDateRangeValidity = (options: {
  minDate: Date
  maxDate: Date
}): {
  validateDateRange: (date: Date) => boolean
  minDateFormatted: string
  maxDateFormatted: string
} => {
  const { minDate, maxDate } = options

  // FIXME: These dates may not be formatted for all locales
  const minDateFormatted = dateToDashString(minDate)
  const maxDateFormatted = dateToDashString(maxDate)

  const validateDateRange = (date: Date) => {
    // TODO: Validate the dates properly and hopefully set values for validity
    return areDatesInRange({ start: minDate, end: maxDate }, date)
  }

  return {
    validateDateRange,
    minDateFormatted,
    maxDateFormatted,
  }
}
