/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  PossibleNewOwner,
  PossibleNewOwnerFromJSON,
  PossibleNewOwnerToJSON,
} from './'

/**
 *
 * @export
 * @interface Event
 */
export interface Event {
  /**
   * Name of the event.
   * @type {string}
   * @memberof Event
   */
  name: string
  /**
   * The type of event:  Information Meeting, Open House, Practicum
   * @type {string}
   * @memberof Event
   */
  eventType: EventEventTypeEnum
  /**
   * Description of the event.
   * @type {string}
   * @memberof Event
   */
  description: string
  /**
   * Any additional event information. This will be appended to the end of the event registration confirmation email
   * @type {string}
   * @memberof Event
   */
  additionalInformation?: string
  /**
   * The starting date for the event in date format: `yyyy-MM-dd`.
   * @type {Date}
   * @memberof Event
   */
  startDate: Date
  /**
   * The ending date for the event in date format: `yyyy-MM-dd`. To support multi-day events.
   * @type {Date}
   * @memberof Event
   */
  endDate?: Date
  /**
   * The starting time for the event using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof Event
   */
  startTime: string
  /**
   * The ending time for the event using ISO standard 24 hour format: `hh:mm`.
   * @type {string}
   * @memberof Event
   */
  endTime: string
  /**
   * The timezone in which the event date/time is taking place. The timezone is in the IANA time zone format.
   * @type {string}
   * @memberof Event
   */
  timezone: string
  /**
   * Is this an online event? If true, then provide an event link in onlineEventUrl.
   * @type {boolean}
   * @memberof Event
   */
  isOnline: boolean
  /**
   * If an online event then provide a URL for the event e.g. a Zoom link.
   * @type {string}
   * @memberof Event
   */
  onlineEventUrl?: string
  /**
   *
   * @type {Address}
   * @memberof Event
   */
  address?: Address
  /**
   * Event unique identifier
   * @type {number}
   * @memberof Event
   */
  eventKey?: number
  /**
   * Unique identifier for the actor.
   * @type {number}
   * @memberof Event
   */
  actorkey?: number
  /**
   * The marketing site URL for the event so the user can promote it on social media.
   * @type {string}
   * @memberof Event
   */
  publicUrl?: string
  /**
   * Name of the person that created the event within the app.
   * @type {string}
   * @memberof Event
   */
  createdBy?: string
  /**
   * When false, registrants can no longer register for an event. Defaults to true.
   * @type {boolean}
   * @memberof Event
   */
  registrationOpen: boolean
  /**
   * When false, an NDA is not required to register for an event. Defaults to false. When true, the user is required to agree to an NDA upon registration.
   * @type {boolean}
   * @memberof Event
   */
  requiresNda: boolean
  /**
   * The maximum amount of people allowed to attend an event. When null, there are capacity limitations e.g. online events may not have a capacity.
   * @type {number}
   * @memberof Event
   */
  capacity?: number
  /**
   * The amount of people attending to an event.
   * @type {number}
   * @memberof Event
   */
  attendees?: number
  /**
   * A computed value concludes whether or not an event is still accepting registrations. If today's date <= startDate and registration is open and we are not overcapacity, the event is still accepting registrations.
   * @type {boolean}
   * @memberof Event
   */
  acceptingRegistrations?: boolean
  /**
   * The name of the host for the event, which will be displayed on the marketing site.
   * @type {string}
   * @memberof Event
   */
  hostName: string
  /**
   * The phone number of the host, which will be displayed on the marketing site.
   * @type {string}
   * @memberof Event
   */
  hostPhone?: string
  /**
   * The contact name for the event, which will be displayed on the marketing site.
   * @type {string}
   * @memberof Event
   */
  contactName?: string
  /**
   * The email for the contact, which will be displayed on the marketing site.
   * @type {string}
   * @memberof Event
   */
  contactEmail: string
  /**
   * The full name of the current owner of the event, typically the user who created the event initially.
   * @type {string}
   * @memberof Event
   */
  owner?: string
  /**
   * Array of possible new owners from logged in user's downline
   * @type {Array<PossibleNewOwner>}
   * @memberof Event
   */
  possibleNewOwners?: Array<PossibleNewOwner>
  /**
   * The date on which the event is closed. In date-time format. The time should always at time 23:59.date time format: `yyyy-MM-dd :H:mm`. or it can be null
   * @type {Date}
   * @memberof Event
   */
  registrationCloseDate?: Date
  /**
   * The QRCode image with a logo for an event publicUrl
   * @type {string}
   * @memberof Event
   */
  qRCodeImage?: string
}

/**
 * @export
 * @enum {string}
 */
export enum EventEventTypeEnum {
  InformationMeeting = 'Information Meeting',
  OpenHouse = 'Open House',
  Practicum = 'Practicum',
}

export function EventFromJSON(json: any): Event {
  return EventFromJSONTyped(json, false)
}

export function EventFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Event {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    eventType: json['eventType'],
    description: json['description'],
    additionalInformation: !exists(json, 'additionalInformation')
      ? undefined
      : json['additionalInformation'],
    startDate: new Date(json['startDate']),
    endDate: !exists(json, 'endDate') ? undefined : new Date(json['endDate']),
    startTime: json['startTime'],
    endTime: json['endTime'],
    timezone: json['timezone'],
    isOnline: json['isOnline'],
    onlineEventUrl: !exists(json, 'onlineEventUrl')
      ? undefined
      : json['onlineEventUrl'],
    address: !exists(json, 'address')
      ? undefined
      : AddressFromJSON(json['address']),
    eventKey: !exists(json, 'eventKey') ? undefined : json['eventKey'],
    actorkey: !exists(json, 'actorkey') ? undefined : json['actorkey'],
    publicUrl: !exists(json, 'publicUrl') ? undefined : json['publicUrl'],
    createdBy: !exists(json, 'createdBy') ? undefined : json['createdBy'],
    registrationOpen: json['registrationOpen'],
    requiresNda: json['requiresNda'],
    capacity: !exists(json, 'capacity') ? undefined : json['capacity'],
    attendees: !exists(json, 'attendees') ? undefined : json['attendees'],
    acceptingRegistrations: !exists(json, 'acceptingRegistrations')
      ? undefined
      : json['acceptingRegistrations'],
    hostName: json['hostName'],
    hostPhone: !exists(json, 'hostPhone') ? undefined : json['hostPhone'],
    contactName: !exists(json, 'contactName') ? undefined : json['contactName'],
    contactEmail: json['contactEmail'],
    owner: !exists(json, 'owner') ? undefined : json['owner'],
    possibleNewOwners: !exists(json, 'possibleNewOwners')
      ? undefined
      : (json['possibleNewOwners'] as Array<any>).map(PossibleNewOwnerFromJSON),
    registrationCloseDate: !exists(json, 'registrationCloseDate')
      ? undefined
      : new Date(json['registrationCloseDate']),
    qRCodeImage: !exists(json, 'QRCodeImage') ? undefined : json['QRCodeImage'],
  }
}

export function EventToJSON(value?: Event | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    eventType: value.eventType,
    description: value.description,
    additionalInformation: value.additionalInformation,
    startDate: value.startDate.toISOString().substr(0, 10),
    endDate:
      value.endDate === undefined
        ? undefined
        : value.endDate.toISOString().substr(0, 10),
    startTime: value.startTime,
    endTime: value.endTime,
    timezone: value.timezone,
    isOnline: value.isOnline,
    onlineEventUrl: value.onlineEventUrl,
    address: AddressToJSON(value.address),
    eventKey: value.eventKey,
    actorkey: value.actorkey,
    publicUrl: value.publicUrl,
    createdBy: value.createdBy,
    registrationOpen: value.registrationOpen,
    requiresNda: value.requiresNda,
    capacity: value.capacity,
    attendees: value.attendees,
    acceptingRegistrations: value.acceptingRegistrations,
    hostName: value.hostName,
    hostPhone: value.hostPhone,
    contactName: value.contactName,
    contactEmail: value.contactEmail,
    owner: value.owner,
    possibleNewOwners:
      value.possibleNewOwners === undefined
        ? undefined
        : (value.possibleNewOwners as Array<any>).map(PossibleNewOwnerToJSON),
    registrationCloseDate:
      value.registrationCloseDate === undefined
        ? undefined
        : value.registrationCloseDate.toISOString(),
    QRCodeImage: value.qRCodeImage,
  }
}
