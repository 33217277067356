/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
/**
 *
 * @export
 * @interface GradingScaleDetails
 */
export interface GradingScaleDetails {
  /**
   * Highest letter grade is represented by the lowest number.
   * @type {number}
   * @memberof GradingScaleDetails
   */
  sortOrder: number
  /**
   *
   * @type {string}
   * @memberof GradingScaleDetails
   */
  letterGrade: string
  /**
   * The scale range of the letter grade.
   * @type {string}
   * @memberof GradingScaleDetails
   */
  scaleRange: string
  /**
   * Number of credits for the course.
   * @type {number}
   * @memberof GradingScaleDetails
   */
  credit?: number
}

export function GradingScaleDetailsFromJSON(json: any): GradingScaleDetails {
  return GradingScaleDetailsFromJSONTyped(json, false)
}

export function GradingScaleDetailsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GradingScaleDetails {
  if (json === undefined || json === null) {
    return json
  }
  return {
    sortOrder: json['sortOrder'],
    letterGrade: json['letterGrade'],
    scaleRange: json['scaleRange'],
    credit: !exists(json, 'credit') ? undefined : json['credit'],
  }
}

export function GradingScaleDetailsToJSON(
  value?: GradingScaleDetails | null
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    sortOrder: value.sortOrder,
    letterGrade: value.letterGrade,
    scaleRange: value.scaleRange,
    credit: value.credit,
  }
}
