export const timeStringToNumber = (time: string): number => {
  const hoursMinutes = time.split(/[.:]/)
  const hours = parseInt(hoursMinutes[0], 10)
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0
  return hours + minutes / 60
}

export const timezoneTitleCase = (str: string): string => {
  if (str) {
    str = str[0].toUpperCase() + str.substr(1)
    str = str.replace(/[/_]([a-z])/gi, (s) => {
      return s.toUpperCase()
    })
  }
  return str
}
