import {
  EnrollmentInfo,
  EnrollmentStatusUpdate,
  EnrollmentInviteCompleteRequestBody,
  EnrollmentStatusesResponse,
  TransferEnrollmentRequestParams,
  FetchEnrollmentsRequest,
} from '../swagger'
import { enrollments } from './swagger'

export const fetchEnrollments = async ({
  programKey,
  year,
  search,
  tutorActorKey,
  page,
  pageSize,
  orderBy,
  enrollmentStatus,
}: FetchEnrollmentsRequest): Promise<EnrollmentInfo> => {
  try {
    return await enrollments.fetchEnrollments({
      programKey,
      //default year is undefined when value is not set
      year: year ? year : undefined,
      enrollmentStatus,
      search,
      tutorActorKey,
      page,
      pageSize,
      orderBy,
    })
  } catch (e) {
    throw e
  }
}

export const fetchOnlyTransferEnrollments = async (
  programKey?: number,
  transfersOnly?: boolean,
  year?: number,
  search?: string
): Promise<EnrollmentInfo> => {
  try {
    return await enrollments.fetchEnrollments({
      programKey,
      transfersOnly,
      year,
      search,
    })
  } catch (e) {
    throw e
  }
}

export const transferEnrollment = async (
  body: TransferEnrollmentRequestParams
): Promise<void> => {
  try {
    return await enrollments.transferEnrollment({ body })
  } catch (e) {
    throw e
  }
}

export const fetchEnrollmentStatuses = async (
  programKey?: number,
  year?: number
): Promise<EnrollmentStatusesResponse> => {
  try {
    return await enrollments.fetchEnrollmentStatuses({
      programKey,
      year,
    })
  } catch (e) {
    throw e
  }
}

export const updateEnrollmentStatus = async (
  body: EnrollmentStatusUpdate
): Promise<void> => {
  try {
    return await enrollments.updateEnrollmentStatus({ body })
  } catch (e) {
    throw e
  }
}

export const completeEnrollmentInvites = async (opts: {
  body: EnrollmentInviteCompleteRequestBody
  enrollmentInviteUuid: string
}): Promise<void> => {
  try {
    const { body, enrollmentInviteUuid } = opts
    await enrollments.completeEnrollmentInvite({
      body,
      enrollmentInviteUuid,
    })
  } catch (e) {
    throw e
  }
}
