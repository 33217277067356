/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Address,
  AddressFromJSON,
  AddressToJSON,
  CommunityListingSupportRepresentative,
  CommunityListingSupportRepresentativeFromJSON,
  CommunityListingSupportRepresentativeToJSON,
} from './'

/**
 *
 * @export
 * @interface CommunityListing
 */
export interface CommunityListing {
  /**
   * The user ID assigned by the system when the community was first created.
   * @type {number}
   * @memberof CommunityListing
   */
  id: number
  /**
   * Name of the community.
   * @type {string}
   * @memberof CommunityListing
   */
  name: string
  /**
   *
   * @type {Address}
   * @memberof CommunityListing
   */
  address: Address
  /**
   *
   * @type {CommunityListingSupportRepresentative}
   * @memberof CommunityListing
   */
  supportRepresentative: CommunityListingSupportRepresentative
  /**
   *
   * @type {Array<string>}
   * @memberof CommunityListing
   */
  programs: Array<CommunityListingProgramsEnum>
}

/**
 * @export
 * @enum {string}
 */
export enum CommunityListingProgramsEnum {
  Scribblers = 'scribblers',
  Foundations = 'foundations',
  Essentials = 'essentials',
  Challenge = 'challenge',
}

export function CommunityListingFromJSON(json: any): CommunityListing {
  return CommunityListingFromJSONTyped(json, false)
}

export function CommunityListingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CommunityListing {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    address: AddressFromJSON(json['address']),
    supportRepresentative: CommunityListingSupportRepresentativeFromJSON(
      json['supportRepresentative']
    ),
    programs: json['programs'],
  }
}

export function CommunityListingToJSON(value?: CommunityListing | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    name: value.name,
    address: AddressToJSON(value.address),
    supportRepresentative: CommunityListingSupportRepresentativeToJSON(
      value.supportRepresentative
    ),
    programs: value.programs,
  }
}
