import { Box, Card, TextField, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OutlinedButton, {
  OutlinedButtonVariant,
} from '../Buttons/OutlinedButton'
import Header, { HeaderVariant } from '../Elements/Header'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { changePassword } from '../../api/authentication'
import { extractedErrorObject } from '../../api/swagger'
import ChangePasswordModal from '../Modals/ChangePasswordModal'
import CardFormHeader from './CardFormHeader'
import { useNavigate } from 'react-router-dom'
import { useSnackbarContext } from '../Context/SnackbarContext'

const UserPasswordCard: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)

  const handleChangePassword = async (
    password: string,
    newPassword?: string
  ) => {
    try {
      const { success } = await changePassword({
        oldPassword: password,
        newPassword: newPassword ?? '',
      })

      if (success) {
        navigate(
          {
            pathname: '/login',
          },
          {
            state: { hasChangedPassword: true },
          }
        )
      } else {
        setSnackbarSeverity?.(SnackbarSeverity.Error)
        setSnackbarMessage?.(
          t(
            'ChangePassword.ErrorMessage',
            'Unable to change password at this time.'
          )
        )
      }
      setSnackbarState?.(true)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    } finally {
      setIsPasswordModalOpen(false)
    }
  }

  return (
    <>
      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => {
          setIsPasswordModalOpen(false)
        }}
        handleChangePassword={handleChangePassword}
      />
      <Card>
        <CardFormHeader
          headerContainerProps={{ paddingX: 1 }}
          menuContainerCss={{ padding: 2 }}
          header={
            <Header
              variant={HeaderVariant.SettingsHeader}
              id="password"
              headerName={t('AccountSettingsTab.Password', 'Password')}
              component="h3"
            />
          }
          buttons={
            <OutlinedButton
              id="changePassword"
              variant={OutlinedButtonVariant.ChangePassword}
              onClick={() => setIsPasswordModalOpen(true)}
            />
          }
        />
        <Box sx={{ padding: theme.spacing(0, 3, 3) }}>
          <Box width="50%" pr={4}>
            <TextField
              fullWidth
              id="currentPasswordField"
              label={t(
                'AccountSettingsTab.CurrentPassword',
                'Current Password'
              )}
              variant="filled"
              disabled={true}
              defaultValue="Testinggg"
              type="password"
            />
          </Box>
        </Box>
      </Card>
    </>
  )
}

export default UserPasswordCard
