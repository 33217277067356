import React, { useContext } from 'react'
import { Page } from '../Elements/PageMargins'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import TitleContext from '../../TitleContext'
import TeamMemberCard from '../Card/TeamMemberCard'
import { useAuth } from '../Routes/AuthProvider'
import NoPermission from '../Elements/NoPermission'
import DynamicBreadcrumbs from '../Elements/DynamicBreadcrumbs'

const AddTeamMember: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const title = t('Team.AddTeamMember.Header', 'Team')
  const auth = useAuth()

  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  const navigateToTeam = () => {
    navigate(
      {
        pathname: '/team',
      },
      {
        /** Navigation Options */
      }
    )
  }

  const canInviteTeamMember = auth.permissions.some(
    (permission) => permission.resourceCode === 'TeamInvite'
  )

  return (
    <Page>
      {canInviteTeamMember ? (
        <>
          <DynamicBreadcrumbs
            breadcrumbs={[
              {
                label: t('Team.AddTeamMember.BreadCrumb', 'My Team'),
                onClick: navigateToTeam,
              },
              {
                label: t('Team.AddTeamMember.BreadCrumb', 'Add A Team Member'),
              },
            ]}
          />
          <TeamMemberCard
            title={t('Team.AddTeamMember.Header', 'Add a Team Member')}
          />
        </>
      ) : (
        <NoPermission
          isLoading={false}
          content={t(
            'Team.NoPermission',
            'Sorry, you do not have permission to view this content.'
          )}
        />
      )}
    </Page>
  )
}

export default AddTeamMember
