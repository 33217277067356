import { useContext, useEffect } from 'react'
import useLoadingContext from './useLoadingContext'
import { LoadingContext } from '../components/Context/LoadingContext'
import { useLoadingIds } from './useLoadingIds'
import { extractedErrorObject, transcriptsApi } from '../api/swagger'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'
import { useTranscriptContext } from '../components/Context/TranscriptContext'
import { useTranslation } from 'react-i18next'

export interface FetchTranscriptHookProps {
  transcriptKey?: number
}

export const useFetchTranscript = ({
  transcriptKey,
}: FetchTranscriptHookProps): { loadingId: string } => {
  const { UseFetchTranscripts } = useLoadingIds()
  const loadingId = UseFetchTranscripts.fetchTranscripts
  const { addLoadingIds } = useContext(LoadingContext)
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { updateTranscriptDetails, updateInitialTranscriptDetails } =
    useTranscriptContext()
  const { t } = useTranslation()
  const errorMessage = t(
    'Hooks.FetchTranscript.Error.Default',
    'An error occurred while attempting to load transcripts'
  )
  const fetchTranscripts = async () => {
    // We should never get here, but this is a better check than typecasting.
    if (!transcriptKey) {
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
      return
    }
    try {
      const { transcript } = await transcriptsApi.fetchTranscript({
        transcriptKey,
      })
      updateTranscriptDetails(transcript)
      /** And update the initial value for cancelling */
      updateInitialTranscriptDetails(transcript)
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: fetchTranscripts,
    loadingId,
  })

  useEffect(() => {
    if (!!transcriptKey) {
      addLoadingIds([loadingId])
    }
  }, [transcriptKey, addLoadingIds, loadingId])

  return { loadingId }
}
