import React from 'react'
import ConfirmationModal from './ConfirmationModal'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface DuplicateChildrenModalProps {
  handleFormSubmit: (event: React.FormEvent<HTMLDivElement>) => void
  isOpen?: boolean
  handleCancel?: () => void
}

const DuplicateChildrenModal: React.FC<DuplicateChildrenModalProps> = ({
  isOpen = false,
  handleFormSubmit,
  handleCancel,
}) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      dialogActions={
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.YesProceed}
          secondaryButtonLabel={TextButtonVariant.NoCancel}
          secondaryClick={handleCancel}
          alwaysStack
        />
      }
      dialogContent={
        <Typography variant="body1" component="p" align="center">
          {t(
            'DuplicateChildrenModal.Content.Label',
            'Oops! The same name is listed twice (the name might have been entered on a previous occasion). Did you mean to do that?'
          )}
        </Typography>
      }
      dialogTitle={t(
        'DuplicateChildrenModal.Title.Label',
        'You are about to add new children'
      )}
      isOpen={isOpen}
      handleFormSubmit={handleFormSubmit}
    />
  )
}

export default DuplicateChildrenModal
