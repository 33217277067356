import { useTranslation } from 'react-i18next'
import useValidationMessages from './useValidationMessages'

enum EmailFieldsValidationMessageTypes {
  Default = 'default',
  NoAtSign = 'noAtSign',
  NoExtension = 'noExtension',
  NoSpaces = 'noSpace',
  NoEmail = 'noEmail',
  NoEmailNoSign = 'noEmailNoSign',
}

interface UseMessageEmail {
  validationMessages: (codeError: string) => string
}

export const useEmailMessage = (): UseMessageEmail => {
  const { t } = useTranslation()

  const validationMessageEmailMap = useValidationMessages([
    {
      field: EmailFieldsValidationMessageTypes.Default,
      message: t(
        'Email.EmailForm.ErrorMessage',
        'Email must be in a valid form (e.g. email@domain.com).'
      ),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoAtSign,
      message: t('Email.EmailForm.NOAtSign', 'The email must contain @.'),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoExtension,
      message: t(
        'Email.EmailForm.NoExtension',
        'The extension is missing (e.g. .com).'
      ),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoSpaces,
      message: t('Email.EmailForm.NoSpaces', 'Must not contain spaces.'),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoEmail,
      message: t(
        'Email.EmailForm.NoEmail',
        'Please enter a valid email address.'
      ),
    },
    {
      field: EmailFieldsValidationMessageTypes.NoEmailNoSign,
      message: t(
        'Email.EmailForm.NoEmailNoSign',
        'The email must contain @ and extension is missing (e.g. .com).'
      ),
    },
  ])

  const validationMessages = (codeError: string) => {
    switch (codeError) {
      case 'default':
        return `${validationMessageEmailMap.get(
          EmailFieldsValidationMessageTypes.Default
        )}`

      case 'noAtSign':
        return `${validationMessageEmailMap.get(
          EmailFieldsValidationMessageTypes.NoAtSign
        )}`

      case 'noExtension':
        return `${validationMessageEmailMap.get(
          EmailFieldsValidationMessageTypes.NoExtension
        )}`

      case 'noSpace':
        return `${validationMessageEmailMap.get(
          EmailFieldsValidationMessageTypes.NoSpaces
        )}`

      case 'noEmailNoSign':
        return `${validationMessageEmailMap.get(
          EmailFieldsValidationMessageTypes.NoEmailNoSign
        )}`

      case 'noEmail':
        return `${validationMessageEmailMap.get(
          EmailFieldsValidationMessageTypes.NoEmail
        )}`

      default:
        return ''
    }
  }
  return { validationMessages }
}

export default useEmailMessage
