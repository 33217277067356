import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  AcceptedEnrollmentInviteProgram,
  LicensingDue,
  ProgramDashboard,
  EnrollmentInvite,
  Student,
  DashboardTextColor,
  Dashboard,
} from '../../swagger'
import {
  enrollments,
  extractedErrorObject,
  users,
  families,
} from '../../api/swagger'
import { SnackbarSeverity, useSnackbarContext } from './SnackbarContext'
import { useTranslation } from 'react-i18next'
import { OperationIds } from '../../swagger/operationIdEnum'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from './LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useAuth } from '../Routes/AuthProvider'
import { Breadcrumb } from '../Elements/DynamicBreadcrumbs'
import { useNavigate } from 'react-router'
import { MenuOption } from '../Menus/DropDown'
import { DashboardStatistics } from '../../swagger'

type StudentOptionsMap = Map<
  number /**program key */,
  MenuOption[] /**student options for program */
>

export const defaultAccountContextValue = {
  /** this information relates ONLY to the programs that the director directs. */
  directorDashboard: {
    /** a list of enrollments of a program which status is `Enrolled` and `Pending` */
    outstandingTuition: undefined as Dashboard[] | undefined,

    pendingEnrollments: undefined as Dashboard[] | undefined,

    /** a list of enrollments invitations of a program with `InProgress` status */
    outstandingInvitations: undefined as Dashboard[] | undefined,

    /** a list of all `Published` programs */
    myPrograms: undefined as ProgramDashboard[] | undefined,

    /** total Licensing due amount */
    licensing: undefined as LicensingDue | undefined,

    /**
     * - These values will be used for director dashboard view where totals are displayed
     **/
    dashboardStatistics: {
      myProgramsTotal: 0,
      outstandingInvitationsTotal: 0,
      outstandingTuitionTotal: 0,
      pendingEnrollmentsTotal: 0,
    } as DashboardStatistics | undefined,

    dashboardTextColor: {
      outstandingTuitionTextColor: undefined,
      pendingEnrollmentsTextColor: undefined,
    } as DashboardTextColor | undefined,

    /** get all director dashboard information */
    fetchDirectorDashboardInformation: async (): Promise<void> => {
      console.warn(
        `The AccountContext.fetchDirectorDashboardInformation was called. Did you forget to use a AccountContextProvider?`
      )
    },
  },
  /**
   * Used to reset the default values of the context, generally when
   * logging out or changing the actingAs.
   */
  resetContextToDefaults: (): void => {
    console.warn(
      `The AccountContext.resetContextToDefaults was called. Did you forget to use a AccountContextProvider?`
    )
  },
  students: [] as Student[],
  updateStudents: (newStudents: Student[]): void => {
    console.warn(
      `The AccountContext.updateStudents was called with value ${newStudents}. Did you forget to use a AccountProvider?`
    )
  },

  /** Enrollments for a given user, generally for the parent only. */
  enrollmentInvites: [] as EnrollmentInvite[],
  updateEnrollmentInvites: (enrollmentInvites: EnrollmentInvite[]): void => {
    console.warn(
      `The AccountContext.updateEnrollmentInvites was called with value ${enrollmentInvites}. Did you forget to use a AccountProvider?`
    )
  },

  /** LoadingId to fetchStudentsForFamily */
  fetchStudentsForFamilyLoadingId: OperationIds.FetchStudentsForFamily,

  /** LoadingId to fetchEnrollmentInvites */
  fetchEnrollmentInvitesLoadingId: OperationIds.FetchEnrollmentInvites,

  /**
   * The enrollment invite selected on the Invites tab to complete
   * This value is used throughout the enrollment completion process
   */
  selectedEnrollmentInvite: undefined as EnrollmentInvite | undefined,

  updateSelectedEnrollmentInvite: (
    invite: EnrollmentInvite | undefined
  ): void => {
    console.warn(
      `The AccountContext.updateSelectedEnrollmentInvite was called with value ${invite}. Did you forget to use a AccountContextProvider?`
    )
  },

  breadcrumbs: [{ label: 'My Invitations' }] as Breadcrumb[],
  updateBreadcrumbs: (stage: InviteTabFlowStages): void => {
    console.warn(
      `The AccountContext.updateBreadcrumbs was called with value ${stage}. Did you forget to use a AccountContextProvider?`
    )
  },

  acceptedPrograms: [] as AcceptedEnrollmentInviteProgram[],
  updateAcceptedPrograms: (
    programs: AcceptedEnrollmentInviteProgram[]
  ): void => {
    console.warn(
      `The AccountContext.updateAcceptedPrograms was called with value ${programs}. Did you forget to use a AccountContextProvider?`
    )
  },
  resetContextValuesForInvites: (): void => {
    console.warn(
      `The AccountContext.resetContextValuesForInvites was called. Did you forget to use a AccountContextProvider?`
    )
  },
  resetContextValuesForStudents: (): void => {
    console.warn(
      `The AccountContext.resetContextValuesForStudents was called. Did you forget to use a AccountContextProvider?`
    )
  },
  studentAssignments: new Map<number, Map<number, number>>(),
  updateStudentAssignments: (
    assignments: Map<number, Map<number, number>>
  ): void => {
    console.warn(
      `The AccountContext.updateStudentAssignments was called with value ${assignments}. Did you forget to use a AccountContextProvider?`
    )
  },
  studentOptionsForProgram: new Map<
    number /**program key */,
    MenuOption[] /**student options for program */
  >(),
  updateStudentOptionsForProgram: (
    newStudentOptionsForProgram: Map<
      number /**program key */,
      MenuOption[] /**student options for program */
    >
  ): void => {
    console.warn(
      `The AccountContext.updateStudentOptionsForProgram was called with value ${newStudentOptionsForProgram}. Did you forget to use a AccountContextProvider?`
    )
  },
}

export enum InviteTabFlowStages {
  PendingInvitations = 'pendingInvitations',
  InvitationSummary = 'invitationSummary',
  AssignStudents = 'assignStudents',
  EnrollmentSummary = 'enrollmentSummary',
  AddStudents = 'addStudents',
  UpdateParentInfo = 'updateParentInfo',
  UpdateChildrenInfo = 'updateChildrenInfo',
}

export const InviteTabAddChildrenBreadcrumbFlow = [
  InviteTabFlowStages.PendingInvitations,
  InviteTabFlowStages.InvitationSummary,
  InviteTabFlowStages.AddStudents,
  // Goes back to PendingInvitations after this
]

export const InviteTabUpdateParentInfoBreadcrumbFlow = [
  InviteTabFlowStages.PendingInvitations,
  InviteTabFlowStages.UpdateParentInfo,
]

export const AccountContext = React.createContext(defaultAccountContextValue)

export const useAccountContext = (): typeof defaultAccountContextValue =>
  React.useContext(AccountContext)

export type TestAccountContextConfig = typeof defaultAccountContextValue

export interface TestAccountContextProps extends PropsWithChildren {
  testConfig?: Partial<TestAccountContextConfig>
}

export const AccountProvider: React.FC<TestAccountContextProps> = ({
  testConfig,
  children,
}) => {
  const { t } = useTranslation()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { userDetails } = useAuth()
  const navigate = useNavigate()

  /** Director Dashboard states */
  const [outstandingTuition, setOutstandingTuition] = useState<
    Dashboard[] | undefined
  >()
  const [pendingEnrollments, setPendingEnrollments] = useState<
    Dashboard[] | undefined
  >()
  const [outstandingInvitations, setOutstandingInvitations] = useState<
    Dashboard[] | undefined
  >()
  const [myPrograms, setMyPrograms] = useState<ProgramDashboard[] | undefined>()
  const [licensing, setLicensing] = useState<LicensingDue | undefined>()

  const [dashboardStatistics, setDashboardStatistics] = useState<
    DashboardStatistics | undefined
  >(defaultAccountContextValue.directorDashboard.dashboardStatistics)

  const [dashboardTextColor, setDashboardTextColor] = useState<
    DashboardTextColor | undefined
  >(defaultAccountContextValue.directorDashboard.dashboardTextColor)

  const defaultDirectorDashboardErrorMessage = t(
    'Account.DirectorDashboard.Context.ErrorMessage',
    'Something went wrong while loading dashboard data. Please reload the page.'
  )

  const fetchDirectorDashboardInformation = async (): Promise<void> => {
    try {
      const { directorDashboard } = await users.fetchDashboard({})

      if (!!directorDashboard) {
        setOutstandingTuition(directorDashboard.outstandingTuition)
        setPendingEnrollments(directorDashboard.pendingEnrollments)
        setOutstandingInvitations(directorDashboard.outstandingInvitations)
        setMyPrograms(directorDashboard.myPrograms)
        setLicensing(directorDashboard.licensing)
        setDashboardStatistics(directorDashboard.statistics)
        setDashboardTextColor(directorDashboard.textColor)
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown Code',
        message:
          (e as unknown as Error).message ??
          defaultDirectorDashboardErrorMessage,
      }
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(errorObject.message)
      setSnackbarState?.(true)
    }
  }

  const availableLoadingIds = useLoadingIds()

  const [students, setStudents] = useState<Student[]>([])
  const updateStudents = (newStudents: Student[]) => {
    // Filter out hidden students, every time we update the students.
    const visibleStudents = newStudents.length
      ? newStudents.filter((student) => !student.hidden)
      : []
    setStudents(visibleStudents)
  }

  const [enrollmentInvites, setEnrollmentInvites] = useState<
    EnrollmentInvite[]
  >([])
  const updateEnrollmentInvites = (enrollmentInvites: EnrollmentInvite[]) => {
    setEnrollmentInvites(enrollmentInvites)
  }

  const [selectedEnrollmentInvite, setSelectedEnrollmentInvite] =
    useState<EnrollmentInvite>()

  const [studentAssignments, setStudentAssignments] = useState(
    new Map<number, Map<number, number>>()
  )

  const updateStudentAssignments = (
    assignments: Map<number, Map<number, number>>
  ) => {
    setStudentAssignments(assignments)
  }

  const updateSelectedEnrollmentInvite = (
    invite: EnrollmentInvite | undefined
  ) => {
    setSelectedEnrollmentInvite(invite)
  }

  const { addLoadingIds } = React.useContext(LoadingContext)
  const fetchStudentsForFamily = async () => {
    try {
      const fetchedStudents = await families.fetchStudentsForFamily({
        userId: 'me',
      })
      updateStudents(fetchedStudents.students)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'Endpoints.FetchStudentsForFamily.Error',
          'An unknown error occurred fetching students for family..'
        ),
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  const fetchStudentsForFamilyLoadingId =
    availableLoadingIds.AccountContext.fetchStudentsForFamily

  useLoadingContext({
    loadingId: fetchStudentsForFamilyLoadingId,
    asyncFunction: async () => {
      if (userDetails.actingAs === 'parent') {
        await fetchStudentsForFamily()
      }
    },
  })

  const getBreadcrumbForStage = (stage: InviteTabFlowStages) => {
    switch (stage) {
      case InviteTabFlowStages.AddStudents:
        return {
          label: t('AccountContext.Breadcrumb.AddChildren', 'Add Children'),
          onClick: () => {
            navigate({ pathname: '/account/invites/add-children' })
          },
        }
      case InviteTabFlowStages.AssignStudents:
        return {
          label: t(
            'AccountContext.Breadcrumb.AssignChildren',
            'Assign Children'
          ),
          onClick: () => {
            navigate({ pathname: '/account/invites/assign-children' })
          },
        }
      case InviteTabFlowStages.EnrollmentSummary:
        return {
          label: t(
            'AccountContext.Breadcrumb.EnrollmentSummary',
            'Enrollment Summary'
          ),
          onClick: () => {
            navigate({ pathname: '/account/invites/enrollment-summary' })
          },
        }
      case InviteTabFlowStages.InvitationSummary:
        return {
          label: t(
            'AccountContext.Breadcrumb.InvitationSummary',
            'Invitation Summary'
          ),
          onClick: () => {
            navigate({ pathname: '/account/invites/invitation-summary' })
          },
        }
      case InviteTabFlowStages.UpdateChildrenInfo:
        return {
          label: t(
            'AccountContext.Breadcrumb.UpdateChildrenInformation',
            'Update Birth Month & Year'
          ),
          onClick: () => {
            navigate({ pathname: '/account/invites/update-children-info' })
          },
        }
      case InviteTabFlowStages.UpdateParentInfo:
        return {
          label: t(
            'AccountContext.Breadcrumb.UpdateAccountInformation',
            'Update Account Information'
          ),
          onClick: () => {
            navigate({ pathname: '/account/invites/update-parent-info' })
          },
        }
      case InviteTabFlowStages.PendingInvitations:
      default:
        return {
          label: t('AccountContext.BreadCrumb.MyInvitations', 'My Invitations'),
          onClick: () => {
            resetContextValuesForInvites()
            navigate({ pathname: '/account/invites' })
          },
        }
    }
  }

  const breadcrumbSelections = Object.values(InviteTabFlowStages).reduce(
    (acc, curr) => {
      acc.set(curr, getBreadcrumbForStage(curr))
      return acc
    },
    new Map()
  )

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([
    breadcrumbSelections.get(InviteTabFlowStages.PendingInvitations),
  ])

  const updateBreadcrumbs = (stage: InviteTabFlowStages) => {
    let inviteStages = Object.values(InviteTabFlowStages)

    if (stage === InviteTabFlowStages.AddStudents) {
      inviteStages = InviteTabAddChildrenBreadcrumbFlow
    }

    if (stage === InviteTabFlowStages.UpdateParentInfo) {
      inviteStages = InviteTabUpdateParentInfoBreadcrumbFlow
    }

    if (stage === InviteTabFlowStages.UpdateChildrenInfo) {
      inviteStages = [
        InviteTabFlowStages.PendingInvitations,
        InviteTabFlowStages.InvitationSummary,
        InviteTabFlowStages.AssignStudents,
        InviteTabFlowStages.UpdateChildrenInfo,
      ]
    }

    // Stop reducing at the last index by default.
    let stoppingIndex = inviteStages.length
    const newBreadcrumbs = inviteStages.reduce<Breadcrumb[]>(
      (acc, curr, index) => {
        if (index < stoppingIndex) {
          /**
           * If we are updating the breadcrumbs for a given stage and this is that
           * stage, then update the index to stop.
           */
          if (stage === curr) {
            stoppingIndex = index
          }

          return [...acc, breadcrumbSelections.get(curr)]
        }
        return acc
      },
      []
    )
    setBreadcrumbs(newBreadcrumbs)
  }

  const fetchEnrollmentInvites = async () => {
    try {
      const fetchedEnrollmentInvites = await enrollments.fetchEnrollmentInvites(
        {}
      )
      if (fetchedEnrollmentInvites.enrollmentInvites) {
        updateEnrollmentInvites(fetchedEnrollmentInvites.enrollmentInvites)
      }
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: t(
          'Endpoints.FetchEnrollmentInvites.Error',
          'An unknown error occurred fetching enrollment invites.'
        ),
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  const fetchEnrollmentInvitesLoadingId =
    availableLoadingIds.AccountContext.fetchEnrollmentInvites

  useLoadingContext({
    loadingId: fetchEnrollmentInvitesLoadingId,
    asyncFunction: fetchEnrollmentInvites,
  })

  useEffect(() => {
    /**
     * This ensures that when switching between actors, the update happens correctly.
     * We are not refreshing the state from the AuthProvider because the AuthProvider
     * does not have access to accountContext due to the hierarchy.
     */
    if (userDetails.actingAs === 'parent') {
      addLoadingIds([fetchStudentsForFamilyLoadingId])
    } else {
      updateStudents([])
    }
  }, [addLoadingIds, fetchStudentsForFamilyLoadingId, userDetails.actingAs])

  const [acceptedPrograms, setAcceptedPrograms] = useState<
    AcceptedEnrollmentInviteProgram[]
  >([])

  const updateAcceptedPrograms = (
    programs: AcceptedEnrollmentInviteProgram[]
  ) => {
    setAcceptedPrograms(programs)
  }

  const [studentOptionsForProgram, setStudentOptionsForProgram] =
    useState<StudentOptionsMap>(new Map<number, MenuOption[]>())

  const updateStudentOptionsForProgram = (
    newStudentOptionsForProgram: StudentOptionsMap
  ) => {
    setStudentOptionsForProgram(newStudentOptionsForProgram)
  }

  const resetContextValuesForInvites = () => {
    updateSelectedEnrollmentInvite(
      defaultAccountContextValue.selectedEnrollmentInvite
    )
    updateAcceptedPrograms(defaultAccountContextValue.acceptedPrograms)
    setBreadcrumbs(defaultAccountContextValue.breadcrumbs)
    updateStudentAssignments(defaultAccountContextValue.studentAssignments)
    updateStudentOptionsForProgram(
      defaultAccountContextValue.studentOptionsForProgram
    )
  }

  const resetContextValuesForStudents = () => {
    updateStudents([])
  }

  const resetContextToDefaults = () => {
    setOutstandingInvitations(
      defaultAccountContextValue.directorDashboard.outstandingInvitations
    )
    setMyPrograms(defaultAccountContextValue.directorDashboard.myPrograms)
    setLicensing(defaultAccountContextValue.directorDashboard.licensing)
    setDashboardStatistics(
      defaultAccountContextValue.directorDashboard
        .dashboardStatistics as DashboardStatistics
    )
    setDashboardTextColor(
      defaultAccountContextValue.directorDashboard.dashboardTextColor
    )
    updateStudents(defaultAccountContextValue.students)
    updateEnrollmentInvites(defaultAccountContextValue.enrollmentInvites)
    updateSelectedEnrollmentInvite(
      defaultAccountContextValue.selectedEnrollmentInvite
    )
    updateBreadcrumbs(InviteTabFlowStages.PendingInvitations)
    updateAcceptedPrograms(defaultAccountContextValue.acceptedPrograms)
    updateStudentAssignments(defaultAccountContextValue.studentAssignments)
    updateStudentOptionsForProgram(
      defaultAccountContextValue.studentOptionsForProgram
    )
    setOutstandingTuition(
      defaultAccountContextValue.directorDashboard.outstandingTuition
    )
    setPendingEnrollments(
      defaultAccountContextValue.directorDashboard.pendingEnrollments
    )
  }

  const value = {
    directorDashboard: {
      outstandingTuition,
      pendingEnrollments,
      outstandingInvitations,
      myPrograms,
      licensing,
      fetchDirectorDashboardInformation,
      dashboardStatistics,
      dashboardTextColor,
    },
    students,
    updateStudents,
    enrollmentInvites,
    updateEnrollmentInvites,
    fetchStudentsForFamilyLoadingId,
    fetchEnrollmentInvitesLoadingId,
    resetContextToDefaults,
    selectedEnrollmentInvite,
    updateSelectedEnrollmentInvite,
    breadcrumbs,
    updateBreadcrumbs,
    acceptedPrograms,
    updateAcceptedPrograms,
    resetContextValuesForInvites,
    studentAssignments,
    updateStudentAssignments,
    studentOptionsForProgram,
    updateStudentOptionsForProgram,
    resetContextValuesForStudents,
    ...testConfig,
  } as typeof defaultAccountContextValue
  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  )
}

export default AccountProvider
