import { Box, IconButton } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { ControlPointDuplicate, Clear, Delete, Edit } from '@mui/icons-material'
import LedgerTable, { LedgerRow } from '../Table/LedgerTable'
import TableHeaders from '../Interfaces/TableHeaders'
import { TranscriptYearCourseWork } from '../../swagger'

const classInformationHeaders: TableHeaders[] = [
  {
    id: 'class',
    label: 'Class',
    align: 'left',
  },
  {
    id: 'grade',
    label: 'Grade',
    align: 'left',
  },
  {
    id: 'credits',
    label: 'Credits',
    align: 'left',
  },
  {
    id: 'actions',
    label: '',
    align: 'left',
  },
]

interface ClassInformationTableProps {
  classInformation?: TranscriptYearCourseWork[]
  isFieldDisabled?: boolean
  onDeleteCourseWork: (courseWorkKey: number) => void
  onEditCourseWork: (courseWorkKey: TranscriptYearCourseWork) => void
  onClear: () => void
  onPrepopulate: () => void
  onAdd: () => void
}

const ClassInformationTable: React.FC<ClassInformationTableProps> = ({
  classInformation,
  isFieldDisabled,
  onDeleteCourseWork,
  onEditCourseWork,
  onClear,
  onPrepopulate,
  onAdd,
}) => {
  const [classInformationRows, setClassInformationRows] = useState<LedgerRow[]>(
    []
  )

  const generateClassInformationRows = useCallback(() => {
    const newRows: LedgerRow[] = []
    if (!classInformation) {
      setClassInformationRows(newRows)
      return
    }
    for (const courseWork of classInformation) {
      if (Object.keys(courseWork).length !== 0) {
        newRows.push({
          cells: [
            {
              content: courseWork.courseName ?? '',
              align: 'left',
              cssTextProps: {
                paddingLeft: '15px',
              },
            },
            {
              content: courseWork.letterGrade ?? '',
              align: 'left',
            },
            {
              content: courseWork.credits?.toString() ?? '',
              align: 'left',
            },
            {
              content: isFieldDisabled ? null : (
                <>
                  <IconButton
                    aria-label="Edit Row"
                    onClick={() => onEditCourseWork(courseWork)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="Delete Row"
                    onClick={() =>
                      onDeleteCourseWork(
                        courseWork.transcriptYearCourseWorkKey as number
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </>
              ),
              align: 'center',
              cssTextProps: {
                boxSizing: 'border-box',
                height: '35px',
              },
            },
          ],
        })
      }
    }
    setClassInformationRows(newRows)
  }, [classInformation, isFieldDisabled, onDeleteCourseWork, onEditCourseWork])

  useEffect(() => {
    generateClassInformationRows()
  }, [classInformation, generateClassInformationRows])

  return (
    <>
      {!isFieldDisabled && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            aria-label="Clear All Rows"
            onClick={onClear}
            disabled={isFieldDisabled}
            sx={{ color: 'black' }}
          >
            <Clear />
          </IconButton>
          <IconButton
            aria-label="Prepopulate Table"
            onClick={onPrepopulate}
            disabled={isFieldDisabled}
            sx={{ color: 'black' }}
          >
            <ControlPointDuplicate />
          </IconButton>
          <IconButton
            aria-label="Add Row"
            onClick={onAdd}
            disabled={isFieldDisabled}
            sx={{
              color: 'black',
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      )}
      <Box>
        <LedgerTable
          tableHeaders={classInformationHeaders}
          rows={classInformationRows}
          cssProps={{
            marginBottom: '14px',
          }}
          emptyRowCssProps={{
            padding: '28px',
          }}
          displayEmptyTable
        />
      </Box>
    </>
  )
}

export default ClassInformationTable
