import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { LedgerRow } from '../../../Table/LedgerTable'
import { styles } from './licensingBillingPdfStyles'

export const BreakdownForPDF: React.FC<{
  breakdownRows: LedgerRow[]
  headerLabel?: string
}> = ({ breakdownRows, headerLabel }) => {
  return (
    <>
      {!!headerLabel && (
        <TableRow style={{...styles.tableBodyCell, padding: 0}}>
          <TableCell style={styles.tableSubHeaderCell}>{headerLabel}</TableCell>
        </TableRow>
      )}
      {breakdownRows.map(({ cells }, rowIndex) => (
        <TableRow key={rowIndex}>
          {cells.map(
            (
              { content, align, color = 'medium', cssTextProps },
              index
            ) => {         
              return (
                <TableCell
                  style={{
                    ...styles.tableBodyCell,
                    textAlign: align,
                    color,
                    ...(cssTextProps && { ...cssTextProps }),                
                  }}
                  key={index}
                >
                  {content}
                </TableCell>
              )
            }
          )}
        </TableRow>
      ))}
    </>
  )
}
