import React, { useEffect } from 'react'
import CardMedia from '@mui/material/CardMedia'
import EpubViewer from '../Viewer/EpubViewer'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { useSnackbarContext } from '../Context/SnackbarContext'
import EmptyPage from '../Elements/EmptyPage'

interface FullScreenAssetProps {
  /** Generic link to the content */
  embedUrl?: string
  /** The type of file within the content asset e.g. video, pdf, document, excel */
  fileType?: string
}

/**
 * Displays a resource in fullscreen mode.
 *
 * It can be used by providing the props defined in {@link FullScreenAssetProps}.
 * If these props are not provided, the component will search on `localStorage` for the data
 * stored with the key `dataFullScreenAsset` to render the resource.
 *
 * If no data is provided in any way, an error message will be displayed
 * indicating to the user that there was a problem opening the content.
 */
const FullScreenAsset: React.FunctionComponent<FullScreenAssetProps> = ({
  embedUrl,
  fileType,
}) => {
  const { t } = useTranslation()
  const { setSnackbarMessage, setSnackbarState, setSnackbarSeverity } =
    useSnackbarContext()

  // if no data or no embedUrl, show an error snackbar
  useEffect(() => {
    if (!fileType || !embedUrl) {
      setSnackbarMessage(
        t(
          'FullScreenAsset.AssetData.Error',
          'There was a problem opening your content, please try again.'
        )
      )
      setSnackbarState(true)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }, [
    fileType,
    embedUrl,
    setSnackbarMessage,
    setSnackbarSeverity,
    setSnackbarState,
    t,
  ])

  if (!fileType || !embedUrl) {
    return (
      <EmptyPage
        message={
          <Typography variant="subtitle1" component="p">
            {t('FullScreenAsset.EmptyState.Message', 'Something went wrong.')}
          </Typography>
        }
      />
    )
  }

  return (
    <Box height={'100vh'}>
      {fileType === 'ebook' ? (
        <EpubViewer url={embedUrl} height="100%" />
      ) : (
        <Box
          height={'100%'}
          display="flex"
          justifyContent="flex-end"
          data-testid="full-screen-asset"
        >
          <CardMedia component="iframe" src={embedUrl} allowFullScreen={true} />
        </Box>
      )}
    </Box>
  )
}
export default FullScreenAsset
