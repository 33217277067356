import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { extractedErrorObject } from '../../api/swagger'
import { OperationIds } from '../../swagger/operationIdEnum'
import { LoadingContext } from '../Context/LoadingContext'
import PartialPageLoadingSpinner from '../Elements/PartialPageLoadingSpinner'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import { DropDownVariant, MenuOption } from '../Menus/DropDown'
import IdDropDown from '../Menus/IdDropDown'
import { useAuth } from '../Routes/AuthProvider'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useUser } from '../../UserContext'
import { AccountTabs } from '../Account/Account'

export const updateActingAsLoadingId = `${OperationIds.UpdateLogin}.ActorSelector`
export const fetchRolesLoadingId = `${OperationIds.FetchUserCommunities}.ActorSelector`

const ActorSelector: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const auth = useAuth()
  const { defaultAccountPath, canViewDashboard, userDetails } = auth

  const { roles: actorMenuOptions } = useUser()

  const [selectedMenuOption, setSelectedMenuOption] = useState<MenuOption>()

  const { addLoadingIds, removeLoadingIds, loadingIds } =
    React.useContext(LoadingContext)

  const getActorById = useCallback(
    (value: number | 'parent') =>
      actorMenuOptions.find((item) => item.id === value),
    [actorMenuOptions]
  )

  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()

  const errorMessage = t(
    'ActorSelector.Dropdown.Error',
    'An Error occurred while attempting load Actors'
  )

  const [actingAsChanged, setActingAsChanged] = useState(false)

  const changeActorSession = async (id: string | number) => {
    try {
      let requestParam
      if (id === 'parent') {
        requestParam = undefined
      } else if (typeof id === 'number') {
        requestParam = id
      } else {
        throw new Error(
          t(
            'ActorSelector.UpdateLogin.Error',
            'Could not determine the selected actor id.'
          )
        )
      }
      addLoadingIds([updateActingAsLoadingId])

      const result = await auth.updateActingAs(requestParam)

      if (!!result) {
        const result = getActorById(id)

        setSelectedMenuOption(result)

        setActingAsChanged(true)
        setSnackbarMessage?.(
          t(
            'ActorSelector.UpdateLogin.Success',
            'Successfully switched to the role: {{- name}}',
            { name: result?.name }
          )
        )
        setSnackbarSeverity?.(SnackbarSeverity.Success)
        setSnackbarState?.(true)
      }
    } catch (error) {
      const errorObj = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage?.(errorObj.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarState?.(true)
    }
    removeLoadingIds([updateActingAsLoadingId])
  }

  useEffect(() => {
    if (actingAsChanged) {
      setActingAsChanged(false)
      navigate(
        {
          pathname: defaultAccountPath,
        },
        {
          state: {
            actingAsChange: true,
            selectedTab: !canViewDashboard
              ? userDetails.actingAs === 'parent'
                ? AccountTabs.Billing
                : AccountTabs.Profile
              : AccountTabs.Dashboard,
          },
        }
      )
    }
  }, [
    actingAsChanged,
    defaultAccountPath,
    navigate,
    canViewDashboard,
    userDetails,
  ])

  useEffect(() => {
    setSelectedMenuOption(getActorById(auth.userDetails.actingAs ?? 'parent'))
  }, [actorMenuOptions, auth.userDetails.actingAs, getActorById])

  const isParent = actorMenuOptions.some((item) => item.name === 'Parent')
  const isJustOneRole = actorMenuOptions.length === 1
  const showDropdown = !(isParent && isJustOneRole)
  const disableDropdown = isJustOneRole

  if (!showDropdown || actorMenuOptions.length === 0 || !selectedMenuOption) {
    return null
  }

  const isLoading =
    loadingIds.has(updateActingAsLoadingId) ||
    loadingIds.has(fetchRolesLoadingId)

  return (
    <PartialPageLoadingSpinner isLoading={isLoading} height={225}>
      <IdDropDown
        cssProps={
          {
            borderRadius: 0,
            '& fieldset': {
              borderRight: 'none',
            },
            '& .MuiDisabled:hover': {
              cursor: 'not-allowed',
            },
            '& svg.MuiDisabled': {
              display: 'none',
            },
          } as CSSProperties
        }
        handleSelection={(id: string | number) => changeActorSession(id)}
        menuOptions={actorMenuOptions}
        variant={DropDownVariant.SortAndFilter}
        value={selectedMenuOption}
        disabled={disableDropdown || isLoading}
        id={`${selectedMenuOption?.id}`}
        includeTooltip={true}
      />
    </PartialPageLoadingSpinner>
  )
}

export default ActorSelector
