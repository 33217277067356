import React from 'react'
import { Page } from './PageMargins'
import { Typography } from '@mui/material'

export const TestRoute: React.FC = () => {
  return (
    <Page>
      <Typography variant="body1">
        Look at you, you've found a secret!
      </Typography>
    </Page>
  )
}

export default TestRoute
