import React from 'react'
import { useTranslation } from 'react-i18next'
import { Student } from '../../swagger'
import { DynamicFieldStates } from '../Interfaces/DynamicFieldStates'
import MonthDropDown from '../Menus/MonthDropDown'
import { addLeadingZero } from '../../helpers/monthsAndDays'

interface ChildrenBirthMonthFieldProps {
  kid: Student
  isEditMode: boolean
  updateForParent: (name: string, updatedValue: string) => void
  childBirthMonthStates: DynamicFieldStates
  isMobile?: boolean
}

const ChildrenBirthMonthField: React.FC<ChildrenBirthMonthFieldProps> = ({
  kid,
  isEditMode,
  updateForParent,
  childBirthMonthStates,
}) => {
  const { t } = useTranslation()

  const monthId =
    childBirthMonthStates[`${kid.studentKey}-birthMonth`]?.state ?? ''
  const monthValue =
    monthId !== '' ? `${addLeadingZero(Number(monthId) + 1)}` : ''

  return (
    <MonthDropDown
      label={t('ChildrenSummaryCard.Children.Label.birthMonth', 'Birth Month')}
      id={`${kid.studentKey}-birthMonth`}
      value={monthValue}
      required={childBirthMonthStates[`${kid.studentKey}-birthMonth`]?.required}
      handleSelection={(selected: {
        name: string
        id: string
        dropDownId: string
      }): void => {
        const { name: updatedValue } = selected
        const monthValueForEndpoint = Number(updatedValue) - 1
        return updateForParent(
          `${kid.studentKey}-birthMonth`,
          monthValueForEndpoint.toString()
        )
      }}
      isDropdown={isEditMode}
      disabled={!isEditMode}
      isValid={childBirthMonthStates[`${kid.studentKey}-birthMonth`]?.isValid}
    />
  )
}

export default ChildrenBirthMonthField
