import React, { PropsWithChildren } from 'react'

const defaultValue = {
  title: 'TitleSansProvider',
  setTitle: (title: string) => {
    console.warn(
      `The default value of TitleContext.setTitle was called with title=${title}. Did you forget to install a TitleProvider?`
    )
  },
  useTitleEffect: (title: string) => {
    console.warn(
      `The default value of TitleContext.useTitleEffect was called with title=${title}. Did you forget to install a TitleProvider?`
    )
  },
}

export const TitleContext = React.createContext(defaultValue)
TitleContext.displayName = 'TitleContext'

interface TitleProviderProps extends PropsWithChildren {
  initialTitle?: string
}

export const TitleProvider: React.FunctionComponent<TitleProviderProps> = ({
  initialTitle,
  children,
}) => {
  /*
      Intentionally use a state object for the entire value
      to avoid injecting a new object each render and forcing all consumers to re-render,
      even when the title hasn't changed.

      See: https://reactjs.org/docs/context.html#caveats
      */
  const [titleContextValue, setTitleContextValue] = React.useState(() => {
    const setTitle = (title: string) => {
      // (jsherman/2021-01-05)???: Should we make this smarter and have it useEffect on behalf of the caller?
      // There's a lot of boilerplate right now around using this.
      // Then we could even clobber document.title if we wanted, to set the browser page title, too.
      // Could split the difference and include a `useTitleEffect` hook that wraps that up, so this is still available.
      setTitleContextValue((titleContextValue) => {
        const isUnchanged = Object.is(title, titleContextValue.title)
        return isUnchanged ? titleContextValue : { ...titleContextValue, title }
      })
    }
    return {
      title: initialTitle ?? 'TitleProvider',
      setTitle,
      useTitleEffect(title: string) {
        React.useEffect(() => {
          setTitle(title)
        }, [title])
      },
    }
  })
  return (
    <TitleContext.Provider value={titleContextValue}>
      {children}
    </TitleContext.Provider>
  )
}

export default TitleContext
