import Card from '@mui/material/Card'
import React, { ReactElement, useEffect, useState } from 'react'
import Header, { HeaderVariant } from '../Elements/Header'
import TableHeaders from '../Interfaces/TableHeaders'
import { useTranslation } from 'react-i18next'
import { TextButtonVariant } from '../Buttons/TextButton'
import {
  Box,
  Divider,
  TableContainer,
  Typography,
  useTheme,
} from '@mui/material'
import {
  InviteTabFlowStages,
  useAccountContext,
} from '../Context/AccountContext'
import LoadingProgress from '../Elements/LoadingProgress'
import { LoadingContext } from '../Context/LoadingContext'
import LedgerTable, { LedgerCell, LedgerRow } from '../Table/LedgerTable'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import CardFormHeader from './CardFormHeader'
import ActionButtons from '../Buttons/ActionButtons'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import ConfirmNavigationAway from '../Modals/ConfirmNavigationAwayModal'
import { useNavigate } from 'react-router'
import { useMountEffect } from '../../hooks/useMountEffect'
import {
  CreateStudents,
  Student,
  extractedErrorObject,
  families,
} from '../../api/swagger'
import { useAuth } from '../Routes/AuthProvider'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import DuplicateChildrenModal from '../Modals/DuplicateChildrenModal'
import AddChildrenFields from '../Invites/AddChildrenFields'
import { useFormFieldReducer } from '../../hooks/useFormFieldReducer'
import IconTextButton, {
  IconTextButtonVariant,
} from '../Buttons/IconTextButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'

const AddChildrenCard: React.FC = () => {
  const navigate = useNavigate()
  const { userDetails } = useAuth()
  const { setSnackbarSeverity, setSnackbarMessage, setSnackbarState } =
    useSnackbarContext()
  const theme = useTheme()
  const { t } = useTranslation()
  const { students, updateStudents, updateBreadcrumbs } = useAccountContext()
  const { loadingIds, addLoadingIds } = React.useContext(LoadingContext)
  const saveChildrenLoadingId = 'saveChildren'
  const showOnDesktop = useShowOnDesktop()

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isDuplicateChildrenModalOpen, setIsDuplicateChildrenModalOpen] =
    useState(false)

  const initialDateTime = new Date().getTime()
  const [syncFields, setSyncFields] = useState(true)
  const { fields, editFieldValue, addField, removeField } = useFormFieldReducer(
    new Set([
      <AddChildrenFields
        key={`new-child-${initialDateTime}`}
        dateTime={initialDateTime}
        firstName=""
        birthMonth=""
        birthYear=""
        updateFields={() => {
          setSyncFields(true)
        }}
      />,
    ])
  )
  const [newChildrenFields, setNewChildrenFields] = useState<ReactElement[]>([])

  useEffect(() => {
    if (syncFields) {
      const synchronizedFields = [...Array.from(fields)]
      synchronizedFields.forEach((field, index) => {
        synchronizedFields[index] = {
          ...field,
          props: {
            ...field.props,
            editFieldValue,
            removeField,
          },
        }
      })
      setNewChildrenFields(synchronizedFields)
      setSyncFields(false)
    }
  }, [editFieldValue, fields, removeField, syncFields])

  const addFields = () => {
    const dateTime = new Date().getTime()
    addField(
      <AddChildrenFields
        key={`new-child-${dateTime}`}
        dateTime={dateTime}
        firstName=""
        birthMonth=""
        birthYear=""
        editFieldValue={editFieldValue}
        updateFields={() => {
          setSyncFields(true)
        }}
        removeField={removeField}
      />
    )
    setSyncFields(true)
  }

  const childrenRows: LedgerRow[] = students.map((student) => {
    return {
      cells: [
        {
          content: student.firstName,
          align: 'left',
          cssTextProps: { pl: theme.spacing(2) },
        } as LedgerCell,
      ],
    }
  })

  const saveChildren = async () => {
    try {
      const createdStudents = await families.createStudent({
        body: {
          createStudents: newChildrenFields.map((field) => {
            const { firstName, birthMonth, birthYear } = field.props
            return {
              firstName,
              // We subtract 1 because the API must receive a number between 0 and 11
              birthMonth: birthMonth - 1,
              birthYear,
              parentUserKey: userDetails.userKey,
            } as CreateStudents
          }),
        },
      })
      updateStudents([
        ...students,
        ...(createdStudents.listStudent as Student[]),
      ])
    } catch (e) {
      const errorObj = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message:
          (e as unknown as Error).message ??
          t(
            'Account.Invites.AddChild.Error',
            'Something went wrong while creating the student(s).'
          ),
      }
      setSnackbarState?.(true)
      setSnackbarMessage?.(errorObj.message)
      setSnackbarSeverity?.(SnackbarSeverity.Error)
    }

    navigate({ pathname: '/account/invites/invitation-summary' })
  }

  const headers: TableHeaders[] = [
    {
      label: t('AddChildren.TableHeader.Children', 'Children'),
      align: 'left',
      render: (
        <Typography
          fontWeight="bold"
          variant="subtitle2"
          color={theme.palette.textOrIcon.onBackground}
          component="p"
        >
          {t(`AddChildren.TableHeader.Children`, 'Children')}
        </Typography>
      ),
    },
  ]

  useLoadingContext({
    asyncFunction: saveChildren,
    loadingId: saveChildrenLoadingId,
  })

  const handleSubmitConfirmationModal = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()

    setIsConfirmationModalOpen(false)

    navigate({ pathname: '/account/invites/invitation-summary' })
  }

  const handleBackClick = () => {
    if (newChildrenFields.some((field) => !!field.props.firstName)) {
      setIsConfirmationModalOpen(true)
    } else {
      navigate({ pathname: '/account/invites/invitation-summary' })
    }
  }

  const handleDuplicateChildrenModalSubmission = (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    event.preventDefault()

    setIsDuplicateChildrenModalOpen(false)
    addLoadingIds([saveChildrenLoadingId])
  }

  const handleSubmission = () => {
    const newKidsNames = newChildrenFields.map(({ props }) =>
      props.firstName.toLowerCase()
    )
    const existingKidsNames = students.map(({ firstName }) =>
      firstName.toLowerCase()
    )
    const setOfKids = new Set([...existingKidsNames, ...newKidsNames])

    /**
     * If we get a set of the names of the kids and it's not equal
     * to the length of the old and new kids, we've got a duplicate name
     *
     * If we have duplicates within the new children, require confirmation
     * If we have duplicates ONLY in the existing children, don't require confirmation
     * If some kid in the new kids matches one in the old, require confirmation
     */
    if (
      setOfKids.size !== [...existingKidsNames, ...newKidsNames].length &&
      (new Set([...newKidsNames]).size !== newKidsNames.length ||
        !(
          new Set([...existingKidsNames]).size !== students.length &&
          new Set([...newKidsNames]).size === newKidsNames.length
        ) ||
        existingKidsNames.some((kid) => newKidsNames.includes(kid)))
    ) {
      setIsDuplicateChildrenModalOpen(true)
    } else {
      addLoadingIds([saveChildrenLoadingId])
    }
  }

  useMountEffect(() => {
    updateBreadcrumbs(InviteTabFlowStages.AddStudents)
  })

  const isSaveChildrenButtonDisabled =
    newChildrenFields.length === 0 ||
    !newChildrenFields.every(
      ({ props }) =>
        !!props.firstName &&
        !!props.birthMonth &&
        !!props.birthYear &&
        Number.isInteger(Number(props.birthYear)) &&
        Number.isInteger(Number(props.birthMonth))
    )

  if (loadingIds.size > 0) {
    return <LoadingProgress />
  }

  return (
    <Card>
      <ConfirmNavigationAway
        isOpen={isConfirmationModalOpen}
        handleFormSubmit={handleSubmitConfirmationModal}
        handleConfirmationCancel={() => setIsConfirmationModalOpen(false)}
      />
      <DuplicateChildrenModal
        isOpen={isDuplicateChildrenModalOpen}
        handleFormSubmit={handleDuplicateChildrenModalSubmission}
        handleCancel={() => setIsDuplicateChildrenModalOpen(false)}
      />
      <CardFormHeader
        header={
          <Header
            id="childrenHeader"
            headerName={t('AddChildren.Header.Children', 'Children')}
            component="h3"
            variant={HeaderVariant.Card}
          />
        }
      />
      <TableContainer sx={{ mx: theme.spacing(3), width: 'auto' }}>
        <LedgerTable
          ariaLabel="children-table"
          tableHeaders={headers}
          rows={childrenRows}
        />
      </TableContainer>
      <CardFormHeader
        header={
          <Header
            id="addNewChildrenHeader"
            headerName={t(
              'AddChildren.Header.AddNewChildren',
              'Add New Children'
            )}
            component="h3"
            variant={HeaderVariant.Card}
          />
        }
      />
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmission()
        }}
      >
        <Box
          display="flex"
          sx={{ mx: theme.spacing(3) }}
          flexDirection="column"
        >
          {newChildrenFields}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="left"
          sx={{ mx: 3, my: 2 }}
        >
          <IconTextButton
            id="addChildButton"
            variant={IconTextButtonVariant.AddAnotherChild}
            startIcon={
              <AddCircleIcon
                sx={{
                  height: '32px',
                  width: '32px',
                }}
              />
            }
            onClick={addFields}
          />
        </Box>
        <Divider variant="middle" sx={{ mx: 3, my: 2 }} />
        <Box margin={3} justifyContent={'center'} display={'flex'}>
          <Box width={showOnDesktop ? '50%' : '100%'}>
            <ActionButtons
              primaryButtonLabel={ContainedButtonVariant.SaveChildren}
              secondaryButtonLabel={TextButtonVariant.Back}
              secondaryClick={handleBackClick}
              disablePrimaryButton={isSaveChildrenButtonDisabled}
              alwaysStack
            />
          </Box>
        </Box>
      </form>
    </Card>
  )
}

export default AddChildrenCard
