import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Typography from '@mui/material/Typography'
import {
  CheckboxesTypes,
  HandleCheckboxesChangeProps,
} from './EnrollmentsTable'
import EnrollmentRowInfo from '../Interfaces/EnrollmentRowInfo'
import { useTranslation } from 'react-i18next'

export interface TuitionPaidCheckboxesProps {
  enrollment: EnrollmentRowInfo
  canRemoveTuitionPayment: boolean
  isEnrollmentsTableEdit: boolean
  isChallenge: boolean
  handleOnChange: (props: HandleCheckboxesChangeProps) => void
}

const TuitionPaidCheckboxes: React.FC<TuitionPaidCheckboxesProps> = ({
  enrollment,
  canRemoveTuitionPayment,
  isEnrollmentsTableEdit,
  isChallenge,
  handleOnChange,
}) => {
  const { t } = useTranslation()

  const semesterOneDisabled =
    (enrollment.semesterOneTuitionPaid && !canRemoveTuitionPayment) ||
    !isEnrollmentsTableEdit

  const semesterTwoDisabled =
    (enrollment.semesterTwoTuitionPaid && !canRemoveTuitionPayment) ||
    !isEnrollmentsTableEdit

  return (
    <FormGroup sx={{ flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <Checkbox
            name="tuitionPaidCheckBox1stSemester"
            color="secondary"
            sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
          />
        }
        aria-label="1st Semester"
        label={
          isChallenge && (
            <Typography variant="caption" component="p">
              {t(
                'TuitionPaidCheckboxes.Checkbox.FirstSemester',
                '1st Semester'
              )}
            </Typography>
          )
        }
        disabled={semesterOneDisabled}
        checked={enrollment.semesterOneTuitionPaid}
        onChange={() =>
          handleOnChange({
            studentKey: enrollment.studentKey,
            programKey: enrollment.programKey,
            checkboxesValues: CheckboxesTypes.semesterOneTuitionPaid,
          })
        }
      />
      {isChallenge && (
        <FormControlLabel
          control={
            <Checkbox
              name="tuitionPaidCheckBox2ndSemester"
              color="secondary"
              sx={{ color: 'rgba(0, 0, 0, 0.26)' }}
            />
          }
          aria-label="2nd Semester"
          label={
            <Typography variant="caption" component="p">
              {t(
                'TuitionPaidCheckboxes.Checkbox.SecondSemester',
                '2nd Semester'
              )}
            </Typography>
          }
          disabled={semesterTwoDisabled}
          checked={enrollment.semesterTwoTuitionPaid}
          onChange={() =>
            handleOnChange({
              studentKey: enrollment.studentKey,
              programKey: enrollment.programKey,
              checkboxesValues: CheckboxesTypes.semesterTwoTuitionPaid,
            })
          }
        />
      )}
    </FormGroup>
  )
}

export default TuitionPaidCheckboxes
