import {
  Communities,
  FetchAcademicYearsRequest,
  FetchAcademicYearsResponse,
} from '../swagger'
import { CommunityDetail } from '../swagger/models/CommunityDetail'
import { CommunityOptions } from '../swagger/models/CommunityOptions'
import { communities, users } from './swagger'
// FIXME: We should probably push the error to the UI and, I dunno, run an alert or toast or something with the message it sent down?
// For now, just pretend we got nothing.
export const fetchCommunities = async (args: {
  page: number
  pageSize: number
  academicYear: number
  orderBy?: Array<string>
  search?: string
}): Promise<Communities> => {
  const { page, pageSize, academicYear, orderBy, search } = args
  try {
    const result = await users.fetchCommunitiesForUser({
      userId: 'me',
      page: page,
      pageSize: pageSize,
      orderBy: orderBy,
      academicYear: academicYear,
      search: search,
    })
    return result
  } catch {
    return {
      managedCommunities: [],
      memberCommunities: [],
    }
  }
}

export const fetchCommunity = async (
  communityKey: number
): Promise<CommunityDetail | undefined> => {
  try {
    const result = await communities.fetchCommunity({ communityKey })
    return result
  } catch {
    return
  }
}

export const fetchCommunityOptions = async (): Promise<CommunityOptions> => {
  try {
    const result = await communities.fetchCommunityOptions({})
    return result
  } catch {
    return { regions: [], tutorModels: [] }
  }
}

export const fetchAcademicYears = async (
  requestParam: FetchAcademicYearsRequest = {}
): Promise<FetchAcademicYearsResponse> => {
  try {
    const result = await communities.fetchAcademicYears(requestParam)
    return result
  } catch {
    return {
      academicYears: [{ year: new Date().getFullYear(), isCurrent: true }],
    }
  }
}
