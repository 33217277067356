import { useContext, useState } from 'react'
import { FetchTranscriptResponse, Transcript } from '../swagger'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../components/Context/SnackbarContext'
import { extractedErrorObject, transcriptsApi } from '../api/swagger'
import { useTranslation } from 'react-i18next'
import { useMountEffect } from './useMountEffect'
import { enqueueSnackbar } from 'notistack'
import { useNotistackSnackbarKeyContext } from '../components/Context/NotistackSnackbarKeyProvider'
import useLoadingContext from './useLoadingContext'
import { LoadingContext } from '../components/Context/LoadingContext'

export interface FetchTranscriptInBatchHookProps {
  transcriptKeys: number[]
}

export const useFetchTranscriptInBatch = ({
  transcriptKeys,
}: FetchTranscriptInBatchHookProps): {
  transcripts?: Transcript[]
  loadingId: string
} => {
  const [transcripts, setTranscripts] = useState<Transcript[]>()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()
  const { addSnackbarKey } = useNotistackSnackbarKeyContext()
  const { addLoadingIds } = useContext(LoadingContext)
  const { t } = useTranslation()
  const errorMessage = t(
    'Hooks.FetchTranscriptInBatch.Error.Default',
    'An error occurred while attempting to load transcripts'
  )

  const loadingId = 'fetchTranscriptsInBatch'

  const fetchTranscriptsInBatch = async () => {
    try {
      const results = await Promise.allSettled(
        transcriptKeys.map((transcriptKey) =>
          transcriptsApi.fetchTranscript({
            transcriptKey,
          })
        )
      )
      // Separate rejections from success messages
      const rejections = results.filter((it) => it.status === 'rejected')
      const errorMessage: Set<string> = new Set()

      for (const rejection of rejections) {
        errorMessage.add(
          `${
            (
              await extractedErrorObject(
                (rejection as PromiseRejectedResult).reason
              )
            )?.message
          } \n`
        )
      }

      for (const msg of errorMessage) {
        addSnackbarKey(enqueueSnackbar(msg, { variant: 'error' }))
      }

      const successes = results.filter((it) => it.status === 'fulfilled')
      const resultArray: Transcript[] = []
      for (const success of successes) {
        resultArray.push(
          (success as PromiseFulfilledResult<FetchTranscriptResponse>).value
            .transcript
        )
      }
      setTranscripts(resultArray)
    } catch (error) {
      const errorObject = (await extractedErrorObject(error)) ?? {
        code: 'Unknown',
        message: (error as unknown as Error).message ?? errorMessage,
      }
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
      setSnackbarState(true)
    }
  }

  useLoadingContext({
    asyncFunction: fetchTranscriptsInBatch,
    loadingId,
  })

  useMountEffect(() => {
    addLoadingIds([loadingId])
  })

  return { transcripts, loadingId }
}
