import React, { ReactElement } from 'react'
import { EmptyPageCenter } from './PageMargins'
import { useTranslation } from 'react-i18next'
import CC_Seal from '../../Images/Branding/cc-seal.png'
import LoadingProgress from './LoadingProgress'
interface EmptyPageProps {
  message: ReactElement
  isLoading?: boolean
}

export const EmptyPage: React.FC<EmptyPageProps> = ({ message, isLoading }) => {
  const { t } = useTranslation()

  return (
    <EmptyPageCenter adjustForMargins>
      {isLoading ? (
        <LoadingProgress />
      ) : (
        <>
          <img
            src={CC_Seal}
            alt={t(
              'EmptyPage.EmptyState.AltText',
              'Classical Conversations Seal'
            )}
          />
          {message}
        </>
      )}
    </EmptyPageCenter>
  )
}

export default EmptyPage
