import React from 'react'
import { LicensingBill } from '../../../../swagger'
import { styles } from './licensingBillingPdfStyles'
import { LicensingPaymentBreakdownForPDF } from './LicensingPaymentBreakdownForPDF'
import { Box, Typography } from '@mui/material'

export const LicensingBillingForPDF: React.FC<{
  licensingBills: LicensingBill[]
}> = ({ licensingBills }) => {
  const licensingGroupedByCommunity = licensingBills.reduce(
    (acc, licensingBill) => {
      const community = licensingBill._for.communityName as string
      if (!acc[community]) {
        acc[community] = []
      }
      acc[community].push(licensingBill)
      return acc
    },
    {} as Record<string, LicensingBill[]>
  )

  return (
    <>
      {Object.keys(licensingGroupedByCommunity).map((community) => {
        return (
          <Box
            key={community}
            aria-label={`licensing-for-community-${community}`}
          >
            <Typography style={styles.communityName}>{community}</Typography>
            {licensingGroupedByCommunity[community].map((licensingBill) => {
              return (
                <Box
                  key={licensingBill._for.programKey}
                  style={styles.programLicensingPaymentBreakdownContainer}
                >
                  <LicensingPaymentBreakdownForPDF
                    licensingBill={licensingBill}
                  />
                </Box>
              )
            })}
          </Box>
        )
      })}
    </>
  )
}
