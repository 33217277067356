import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import ActionButtons from '../Buttons/ActionButtons'
import { ContainedButtonVariant } from '../Buttons/ContainedButton'
import { TextButtonVariant } from '../Buttons/TextButton'
import ConfirmationModal from './ConfirmationModal'

// TODO: Consolidate useful modal props into general interface https://projekt202.atlassian.net/browse/CCP1-822
interface ConfirmNavigationAwayProps {
  isOpen: boolean
  handleFormSubmit: (event: React.FormEvent<HTMLDivElement>) => Promise<void>
  handleConfirmationCancel: () => void
}

const ConfirmNavigationAway: React.FC<ConfirmNavigationAwayProps> = (props) => {
  const { t } = useTranslation()
  const { isOpen, handleFormSubmit, handleConfirmationCancel } = props

  const confirmationBody = (
    <Typography variant="body1" component="p" align="center">
      {t(
        'ConfirmNavigationAway.ConfirmationModal.Body.PleaseConfirm',
        'Please confirm that this is the action you intend to complete.'
      )}
    </Typography>
  )

  return (
    <ConfirmationModal
      isOpen={isOpen}
      handleFormSubmit={handleFormSubmit}
      dialogActions={
        <ActionButtons
          primaryButtonLabel={ContainedButtonVariant.YesImSure}
          secondaryButtonLabel={TextButtonVariant.NoCancel}
          secondaryClick={handleConfirmationCancel}
        />
      }
      dialogContent={confirmationBody}
      dialogTitle={t(
        'ConfirmNavigationAway.ConfirmationModal.Title.AboutToDrop',
        'You are about to navigate away. Your progress may not be saved.'
      )}
    />
  )
}

export default ConfirmNavigationAway
