import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useLocation } from 'react-router'
import TitleContext from '../../TitleContext'
import { useAuth } from '../Routes/AuthProvider'
import { Page } from '../Elements/PageMargins'
import NoPermission from '../Elements/NoPermission'
import { TuitionInvoice, SendTuitionInvoiceRequestBody } from '../../swagger'
import { extractedErrorObject, invoicesApi } from '../../api/swagger'
import { SnackbarSeverity } from '../Alerts/SnackbarAlert'
import InvoiceCard from '../Card/InvoiceCard'
import EmptyInvoice from './EmptyInvoice'
import { useSnackbarContext } from '../Context/SnackbarContext'
import { useLoadingIds } from '../../hooks/useLoadingIds'
import { LoadingContext } from '../Context/LoadingContext'
import useLoadingContext from '../../hooks/useLoadingContext'
import { useMountEffect } from '../../hooks/useMountEffect'

const InvoiceDetails: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { permissionAbility, userDetails, updateActingAs } = useAuth()
  const title = t('Families.Title', 'Families')
  const { setSnackbarMessage, setSnackbarState, setSnackbarSeverity } =
    useSnackbarContext()
  const availableLoadingIds = useLoadingIds()
  const { addLoadingIds } = React.useContext(LoadingContext)
  const location = useLocation()

  const { useTitleEffect } = useContext(TitleContext)
  useTitleEffect(title)

  let { userId } = useParams<{
    userId: string
  }>()
  const { invoiceId } = useParams<{
    invoiceId?: string
  }>()

  const isParent = userDetails.actingAs === 'parent'
  const isParentLocation = !location.pathname.includes('family-profile')

  /**
   * If user is not acting as parent, and is on the invoice parent location, switch to parent
   */
  useMountEffect(() => {
    if (isParentLocation && !isParent) {
      updateActingAs(undefined)
    }
  })

  if (isParent) {
    userId = `${userDetails.userKey}`
  }
  const {
    state,
  }: {
    state: {
      billingUserKey: number
      isTutorInvoice: boolean
      showSendInvoiceButton: false
    }
  } = useLocation()

  const { billingUserKey, isTutorInvoice, showSendInvoiceButton } = state || {}
  const [isLoadingInvoiceData, setIsLoadingInvoiceData] = useState(true)
  const [triggerRefetchOnParent, setTriggerRefetchOnParent] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [tuitionInvoice, setTuitionInvoice] = useState<TuitionInvoice>()
  const [isSubLicensedCommunity, setIsSubLicensedCommunity] = useState(false)

  const canSendTuitionInvoice = permissionAbility.can(
    'sendTuitionInvoice',
    'Payment'
  )

  /**
   * Fetch invoice data
   */
  useEffect(() => {
    if ((!!userId && canSendTuitionInvoice) || isParent) {
      addLoadingIds([availableLoadingIds.InvoiceDetails.fetchTuitionInvoice])
    }
  }, [
    userId,
    invoiceId,
    billingUserKey,
    isTutorInvoice,
    canSendTuitionInvoice,
    isParent,
    addLoadingIds,
    availableLoadingIds.InvoiceDetails.fetchTuitionInvoice,
  ])

  const fetchInvoiceData = async () => {
    try {
      const fetchedTuitionInvoice = await invoicesApi.fetchTuitionInvoice({
        parentUserKey: +`${userId}`,
        billingUserKey,
        isTutorInvoice,
        invoiceKey: !!invoiceId ? +invoiceId : 0,
      })
      setTuitionInvoice(fetchedTuitionInvoice)
      setIsSubLicensedCommunity(
        fetchedTuitionInvoice.isSubLicensedCommunity ?? false
      )
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'Unknown',
        message: (e as unknown as Error).message,
      }
      setErrorMessage(errorObject.message)
    } finally {
      setIsLoadingInvoiceData(false)
    }
  }

  useLoadingContext({
    asyncFunction: fetchInvoiceData,
    loadingId: availableLoadingIds.InvoiceDetails.fetchTuitionInvoice,
  })

  /**
   * If error fetching, display snack bar alert
   */
  useEffect(() => {
    if (!!errorMessage) {
      setSnackbarState(true)
      setSnackbarMessage(errorMessage)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  })

  const sendInvoice = async (
    body: SendTuitionInvoiceRequestBody,
    callback?: () => void
  ) => {
    if (!userId) return

    try {
      await invoicesApi.sendTuitionInvoice({
        parentUserKey: +`${userId}`,
        billingUserKey,
        body: {
          ...body,
          isTutorInvoice,
        },
      })
      /**
       * It should be noted navigate processing before snackbar state will allow testing
       * to validate navigating to a route AND THEN seeing an alert. Putting snackbar
       * before navigation will not show the alert.
       */
      navigateToFamilyProfile()
      setSnackbarMessage(
        t('Families.SendInvoice.SuccessMessage', 'Invoice successfully sent.')
      )
      setSnackbarSeverity(SnackbarSeverity.Success)
      setSnackbarState(true)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message,
      }
      setErrorMessage(errorObject.message)
      callback?.()
    } finally {
      setTriggerRefetchOnParent(true)
    }
  }

  const navigateToFamilyProfile = () => {
    navigate(
      {
        pathname: `/family-profile/${userId}`,
      },
      {
        /** Navigation Options */
        state: {
          triggerRefetch: triggerRefetchOnParent,
        },
      }
    )
  }

  const onCloseInvoice = () => {
    const pathname =
      userDetails.actingAs === 'parent'
        ? '/account/billing'
        : `/family-profile/${userId}`
    navigate({
      pathname,
    })
  }

  return (
    <Page>
      {showSendInvoiceButton && !canSendTuitionInvoice && !isParent ? (
        <NoPermission
          content={t(
            'GeneralError.NoPermission',
            'Sorry, you do not have permission to view this content.'
          )}
        />
      ) : !tuitionInvoice ? (
        <EmptyInvoice isLoading={isLoadingInvoiceData} />
      ) : (
        <>
          <InvoiceCard
            isSubLicensedCommunity={isSubLicensedCommunity}
            showSendInvoiceButton={showSendInvoiceButton}
            tuitionInvoice={tuitionInvoice}
            onCloseInvoice={onCloseInvoice}
            sendInvoice={sendInvoice}
          />
        </>
      )}
    </Page>
  )
}

export default InvoiceDetails
