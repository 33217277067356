/**
 * Glues together a user's name for presentation.
 * (In future, maybe it will be locale or preference sensitive.)
 *
 * @param user something with a firstName and lastName
 * @param defaultValue used when both first and last names are missing
 */
export const fullName = (
  user?: { firstName?: string; lastName?: string },
  defaultValue = ''
): string => {
  const first = user?.firstName?.trim() ?? ''
  const last = user?.lastName?.trim() ?? ''
  const name = first && last ? `${first} ${last}` : defaultValue
  return name
}

export default fullName
