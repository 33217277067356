import {
  AgreementTemplateFormFields,
  AgreementStatus,
  AgreementSigningUrl,
  AgreementCreate,
  AgreementPreview,
  AgreementRecord,
  CompleteAgreementResponse,
} from '../swagger'
import { agreements } from './swagger'

export const fetchAgreementTemplateFormFields = async (
  agreementTemplateId: string,
  agreementDocumentId: string
): Promise<AgreementTemplateFormFields> => {
  try {
    const result = await agreements.fetchAgreementTemplateFormFields({
      agreementTemplateId,
      agreementDocumentId,
    })
    return result
  } catch (e) {
    throw e
  }
}

export const fetchAgreementStatus = async (
  agreementId: string
): Promise<AgreementStatus> => {
  try {
    const result = await agreements.fetchAgreementStatus({ agreementId })
    return result
  } catch {
    return { status: '' }
  }
}

export const fetchAgreementSigningUrl = async (
  agreementId: string
): Promise<AgreementSigningUrl> => {
  try {
    const result = await agreements.fetchAgreementSigningUrl({ agreementId })
    return result
  } catch (e) {
    throw e
  }
}

export const createAgreement = async (
  body: AgreementCreate
): Promise<AgreementRecord> => {
  try {
    const result = await agreements.createAgreement({ body })
    return result
  } catch (e) {
    throw e
  }
}

export const fetchAgreementPreview = async (
  agreementId: string
): Promise<AgreementPreview> => {
  try {
    const result = await agreements.fetchAgreementPreview({ agreementId })
    return result
  } catch (e) {
    throw e
  }
}

export const deleteAgreement = async (agreementId: string): Promise<void> => {
  try {
    const result = await agreements.deleteAgreement({ agreementId })
    return result
  } catch (e) {
    return
  }
}

export const completeAgreement = async (
  uuid: string
): Promise<CompleteAgreementResponse> => {
  try {
    const result = await agreements.completeAgreement({ uuid })
    return result
  } catch (e) {
    throw e
  }
}
