import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  LicensingBill,
  LicensingFeePerRateBreakdown,
  LicensingFeePerRateBreakdownFeeEnum,
} from '../../../swagger'
import useBreakdownDataLicensingFee from '../../../hooks/useBreakdownDataLicensingFee'
import {
  BreakDownLedgerVariants,
  BreakdownLedger,
  BreakdownLedgerContainer,
} from './BreakdownLedger'
import TableHeaders from '../../Interfaces/TableHeaders'
import { Box, useTheme } from '@mui/material'

interface LicensingBreakdownProps {
  licensingBill: LicensingBill
  programName: string
  currencyCode: string
  showDetails: boolean
}

/**
 * TODO: Refactor this component to use the useLicensingPaymentBreakdownCalculations hook
 */
export const LicensingPaymentBreakdown: React.FC<LicensingBreakdownProps> = ({
  licensingBill,
  programName,
  currencyCode,
  showDetails,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isChallenge = programName.includes('Challenge')

  const licensingFeeApplicationLabel = t(
    'Licensing.Table.Breakdown.Header.LicensingFee',
    'Licensing Fee Application'
  )
  const licensingTuitionLabel = t(
    'Licensing.Table.Breakdown.Header.LicensingTuition',
    'Licensing Tuition'
  )
  const firstSemesterLicensingTuitionLabel = t(
    'Licensing.Table.Breakdown.Header.FirstSemesterLicensingTuition',
    '1st Semester Licensing Tuition'
  )
  const secondSemesterLicensingTuitionLabel = t(
    'Licensing.Table.Breakdown.Header.SecondSemesterLicensingTuition',
    '2nd Semester Licensing Tuition'
  )

  const licensingBillBreakdown = licensingBill.fees.map(
    (breakdown) => breakdown.breakdown
  )[0]

  const balance = licensingBill.total.amount
  const [{ owed, paid }] = licensingBill.fees.map((fee) => {
    return {
      paid: fee.paid.amount,
      owed: fee.owed.amount,
    }
  })

  const breakdownInitialValue: LicensingFeePerRateBreakdown = {
    count: 0,
    amount: {
      amount: 0,
      currencyCode: '',
    },
  }

  const breakdown = licensingBillBreakdown.reduce(
    (acc, curr) => {
      return {
        semester1FirstStudentsInfo:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.Sem1FirstStudent
            ? { ...acc.semester1FirstStudentsInfo, ...curr }
            : acc.semester1FirstStudentsInfo,
        semester1AdditionalStudentInfo:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.Sem1AdditionalStudent
            ? { ...acc.semester1AdditionalStudentInfo, ...curr }
            : acc.semester1AdditionalStudentInfo,
        semester2FirstStudentsInfo:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.Sem2FirstStudent
            ? { ...acc.semester2FirstStudentsInfo, ...curr }
            : acc.semester2FirstStudentsInfo,
        semester2AdditionalStudentInfo:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.Sem2AdditionalStudent
            ? { ...acc.semester2AdditionalStudentInfo, ...curr }
            : acc.semester2AdditionalStudentInfo,
        firstSpotInvitation:
          curr.fee === LicensingFeePerRateBreakdownFeeEnum.FirstSpotInvitation
            ? { ...acc.firstSpotInvitation, ...curr }
            : acc.firstSpotInvitation,
        additionalSpotsInvitation:
          curr.fee ===
          LicensingFeePerRateBreakdownFeeEnum.AdditionalSpotsInvitation
            ? { ...acc.additionalSpotsInvitation, ...curr }
            : acc.additionalSpotsInvitation,
      }
    },
    {
      semester1FirstStudentsInfo: breakdownInitialValue,
      semester1AdditionalStudentInfo: breakdownInitialValue,
      semester2FirstStudentsInfo: breakdownInitialValue,
      semester2AdditionalStudentInfo: breakdownInitialValue,
      firstSpotInvitation: breakdownInitialValue,
      additionalSpotsInvitation: breakdownInitialValue,
    }
  )

  const {
    semester1FirstStudentsInfo,
    semester1AdditionalStudentInfo,
    semester2FirstStudentsInfo,
    semester2AdditionalStudentInfo,
    firstSpotInvitation,
    additionalSpotsInvitation,
  } = breakdown

  const licensingDueBreakDownCSSProps = {
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 0),
    borderBottom: 0,
    minWidth: '88px',
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent',
    },
    paddingLeft: 0,
  }

  const {
    firstStudent: licensingFeeFirstStudent,
    additionalStudent: licensingFeeAdditionalStudent,
    subTotal: licensingFeeSubtotal,
    totalOfStudents: licensingFeeTotalOfStudents,
  } = useBreakdownDataLicensingFee({
    semesterStudentInfo: firstSpotInvitation,
    semesterAdditionalStudentInfo: additionalSpotsInvitation,
  })

  const {
    firstStudent: semester1FirstStudent,
    additionalStudent: semester1AdditionalStudent,
    subTotal: semester1Subtotal,
    totalOfStudents: semester1TotalOfStudents,
  } = useBreakdownDataLicensingFee({
    semesterStudentInfo: semester1FirstStudentsInfo,
    semesterAdditionalStudentInfo: semester1AdditionalStudentInfo,
  })

  const {
    firstStudent: semester2FirstStudent,
    additionalStudent: semester2AdditionalStudent,
    subTotal: semester2Subtotal,
    totalOfStudents: semester2TotalOfStudents,
  } = useBreakdownDataLicensingFee({
    semesterStudentInfo: semester2FirstStudentsInfo,
    semesterAdditionalStudentInfo: semester2AdditionalStudentInfo,
  })

  const tableHeaders: TableHeaders[] = [
    {
      label: t('LicensingPayment.Breakdown.Header.FeeName', 'Fee Name'),
      align: 'left',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
    {
      label: t('LicensingPayment.Breakdown.Header.FeeAmount', 'Fee Amount'),
      align: 'right',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
      id: 'amount-first-students',
    },
    {
      label: t(
        'LicensingPayment.Breakdown.Header.NumberOfStudents',
        '# of Invited Students'
      ),
      align: 'right',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
    {
      label: t('LicensingPayment.Breakdown.Header.Subtotal', 'Subtotal'),
      align: 'right',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
  ]

  const previewHeaderLabels: TableHeaders[] = [
    {
      label: t('BreakdownLedger.Preview.Header.FeeName', 'Fee Name'),
      align: 'left',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
    {
      label: '',
      align: 'left',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
      id: 'amount',
    },
    {
      label: t(
        'BreakdownLedger.Preview.Header.NumberOfStudents',
        '# of Invited Students'
      ),
      align: 'right',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
    {
      label: t('BreakdownLedger.Preview.Header.Subtotal', 'Subtotal'),
      align: 'right',
      cssProps: {
        ...licensingDueBreakDownCSSProps,
      },
    },
  ]

  const previewRows = [
    {
      label: licensingFeeApplicationLabel,
      numberStudents: licensingFeeTotalOfStudents,
      subtotal: licensingFeeSubtotal,
      currencyCode: currencyCode,
    },
    {
      label: isChallenge
        ? firstSemesterLicensingTuitionLabel
        : licensingTuitionLabel,
      numberStudents: semester1TotalOfStudents,
      subtotal: semester1Subtotal,
      currencyCode: currencyCode,
    },
    {
      label: isChallenge
        ? secondSemesterLicensingTuitionLabel
        : licensingTuitionLabel,
      numberStudents: semester2TotalOfStudents,
      subtotal: semester2Subtotal,
      currencyCode: currencyCode,
    },
  ]

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          width: '60%',
          [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: 700,
          },
        }}
      >
        {showDetails ? (
          <BreakdownLedgerContainer headers={tableHeaders}>
            {licensingFeeSubtotal > 0 ? (
              <BreakdownLedger
                type={BreakDownLedgerVariants.Normal}
                headerLabel={licensingFeeApplicationLabel}
                firstStudent={licensingFeeFirstStudent}
                additionalStudent={licensingFeeAdditionalStudent}
                subtotal={licensingFeeSubtotal}
                isChallenge={isChallenge}
              />
            ) : (
              <></>
            )}
            {semester1Subtotal > 0 ? (
              <BreakdownLedger
                type={BreakDownLedgerVariants.Normal}
                headerLabel={
                  isChallenge
                    ? firstSemesterLicensingTuitionLabel
                    : licensingTuitionLabel
                }
                firstStudent={semester1FirstStudent}
                additionalStudent={semester1AdditionalStudent}
                subtotal={semester1Subtotal}
                isChallenge={isChallenge}
              />
            ) : (
              <></>
            )}
            {isChallenge && semester2Subtotal > 0 ? (
              <BreakdownLedger
                type={BreakDownLedgerVariants.Normal}
                headerLabel={
                  isChallenge
                    ? secondSemesterLicensingTuitionLabel
                    : licensingTuitionLabel
                }
                firstStudent={semester2FirstStudent}
                additionalStudent={semester2AdditionalStudent}
                subtotal={semester2Subtotal}
                isChallenge={isChallenge}
              />
            ) : (
              <></>
            )}
            <BreakdownLedger
              type={BreakDownLedgerVariants.Balance}
              totalOwed={owed}
              totalPaid={paid}
              balance={balance}
              currencyCode={currencyCode}
            />
          </BreakdownLedgerContainer>
        ) : (
          <BreakdownLedgerContainer headers={previewHeaderLabels}>
            <BreakdownLedger
              type={BreakDownLedgerVariants.Preview}
              rows={previewRows}
              isChallenge={isChallenge}
            />
            <BreakdownLedger
              type={BreakDownLedgerVariants.Balance}
              totalOwed={owed}
              totalPaid={paid}
              balance={balance}
              currencyCode={currencyCode}
            />
          </BreakdownLedgerContainer>
        )}
      </Box>
    </Box>
  )
}
