import React from 'react'
import { Address } from '../../swagger'
import { useTranslation } from 'react-i18next'
import AddressCard from './AddressCard'
import SchoolInformationHeader from '../Transcripts/SchoolInformationHeader'
import { useTranscriptContext } from '../Context/TranscriptContext'
import { Alert, Typography } from '@mui/material'
import { useTheme } from '@mui/material'
import { ErrorIcon } from '../Alerts/ErrorAlert'

export interface SchoolInformationCardProps {
  /** Passed for testing purposes primarily, though it is better to pass in using the context value, also. */
  address: Address
  /**
   * Determines if fields should be editable, and, if Edit button for addresses are enabled
   */
  isFieldDisabled?: boolean
}

export const schoolInformationCardFieldProps: {
  [k: string]: { [k: string]: string }
} = {
  schoolAddress: {
    headerId: 'school-address-header',
    buttonId: 'edit-school-address',
  },
}

export const SchoolInformationCard: React.FC<SchoolInformationCardProps> = ({
  address,
  isFieldDisabled,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    updateTranscriptDetails,
    transcriptDetails,
    transcriptValidity,
    updateFormValidity,
  } = useTranscriptContext()

  const onAddressConfirm = (newAddress: Address) => {
    updateFormValidity({
      ...transcriptValidity,
      schoolAddress: { input: true },
    })
    updateTranscriptDetails({
      ...transcriptDetails,
      schoolAddress: newAddress,
      /**
       * When studentAddressSameAsSchool = true, update both addresses when
       * updating one.
       */
      ...(transcriptDetails.studentAddressSameAsSchool && {
        studentAddress: newAddress,
      }),
    })
  }

  return (
    <AddressCard
      address={address}
      headerProps={{
        id: schoolInformationCardFieldProps.schoolAddress.headerId,
        headerName: t(
          'Transcripts.SchoolInformationCard.Header.SchoolAddress',
          'School Address *'
        ),
      }}
      isEditMode={!isFieldDisabled}
      /**
       * Address validity is reset on the confirmation of the address
       */
      onAddressConfirm={onAddressConfirm}
      buttonLabel={schoolInformationCardFieldProps.schoolAddress.buttonId}
      skipModalAddressReset
      formHeading={
        <SchoolInformationHeader isFieldDisabled={isFieldDisabled} />
      }
      addressMessage={
        !transcriptValidity.schoolAddress.input ? (
          <Alert
            sx={{
              color: theme.palette.error.main,
            }}
            elevation={6}
            severity={'error'}
            icon={<ErrorIcon />}
          >
            <Typography variant="subtitle2" component="p">
              {t(
                'Transcripts.SchoolInformationCard.Error.SchoolAddress',
                'School Address is required.'
              )}
            </Typography>
          </Alert>
        ) : undefined
      }
    />
  )
}

export default SchoolInformationCard
