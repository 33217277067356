import React from 'react'
import { useAuth } from '../Routes/AuthProvider'
import { Can } from '@casl/react'
import TextButton, { TextButtonVariant } from './TextButton'
import ContainedButton, { ContainedButtonVariant } from './ContainedButton'
import OutlinedButton, { OutlinedButtonVariant } from './OutlinedButton'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import { ProgramStatus } from '../../swagger'

interface ProgramFormCardButtonsProps {
  isFieldDisabled: boolean
  isDraftProgram: boolean
  useAddProgramVariant: boolean
  handleEdit: () => void
  handleCancel: () => void
  handleActions: (action: Action, status?: ProgramStatus) => void
  hasEnrollment?: boolean
  isArchiveProgram?: boolean
  hasChangeFees?: boolean
  handleShowModalFee?: ()=> void
}

/**
 * @export
 * @enum {string}
 */
export enum Action {
  Publish = 'Publish',
  Draft = 'Draft',
  Edit = 'Edit',
  Archived = 'Archive',
}

export const ProgramFormCardButtons: React.FC<ProgramFormCardButtonsProps> = (
  props
) => {
  const { permissionAbility } = useAuth()
  const showOnDesktop = useShowOnDesktop()
  const canPublishPrograms = permissionAbility.can('publish', 'Program')
  const canArchivePrograms = permissionAbility.can('archive', 'Program')
  const {
    handleEdit,
    isDraftProgram,
    handleCancel,
    handleActions,
    hasEnrollment,
    useAddProgramVariant,
    isArchiveProgram,
    hasChangeFees,
    handleShowModalFee,
  } = props

  return props.isFieldDisabled ? (
    <Can I="edit" on="Program" ability={permissionAbility}>
      <ContainedButton
        id="edit"
        variant={ContainedButtonVariant.Edit}
        onClick={handleEdit}
      />
    </Can>
  ) : (
    <>
      {showOnDesktop && (
        <TextButton
          id="cancel"
          onClick={handleCancel}
          variant={TextButtonVariant.Cancel}
        />
      )}
      {isDraftProgram || isArchiveProgram ? (
        <>
          {canArchivePrograms && !useAddProgramVariant && (
            <OutlinedButton
              id="archive"
              variant={OutlinedButtonVariant.Archive}
              onClick={() =>
                handleActions(Action.Archived, ProgramStatus.Archived)
              }
            />
          )}
          {canPublishPrograms && (
            <>
              <OutlinedButton
                id="draft"
                variant={OutlinedButtonVariant.Draft}
                onClick={() =>
                  handleActions(
                    useAddProgramVariant ? Action.Draft : Action.Edit
                  )
                }
              />
              <ContainedButton
                id="publish"
                variant={ContainedButtonVariant.Publish}
                onClick={() =>
                  handleActions(
                    useAddProgramVariant ? Action.Publish : Action.Edit,
                    ProgramStatus.Published
                  )
                }
              />
            </>
          )}
        </>
      ) : (
        <>
          {!hasEnrollment && canPublishPrograms && (
            <OutlinedButton
              id="revertToDraft"
              variant={OutlinedButtonVariant.RevertToDraft}
              onClick={() => handleActions(Action.Edit)}
            />
          )}
          <ContainedButton
            id="save"
            variant={ContainedButtonVariant.Save}
            onClick={() => hasChangeFees ? handleShowModalFee?.() : handleActions(Action.Edit, ProgramStatus.Published)}
          />
        </>
      )}
      {!showOnDesktop && (
        <TextButton
          id="cancel"
          onClick={handleCancel}
          variant={TextButtonVariant.Cancel}
        />
      )}
    </>
  )
}

export default ProgramFormCardButtons
