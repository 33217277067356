import { ProgramType } from '../swagger/models/ProgramType'

export const programTypeMap = new Map([
  ['Scribblers', ProgramType.Scribblers],
  ['Foundations', ProgramType.Foundations],
  ['Essentials', ProgramType.Essentials],
  ['Challenge A', ProgramType.ChallengeA],
  ['Challenge B', ProgramType.ChallengeB],
  ['Challenge I', ProgramType.ChallengeI],
  ['Challenge II', ProgramType.ChallengeIi],
  ['Challenge III', ProgramType.ChallengeIii],
  ['Challenge IV', ProgramType.ChallengeIv],
])

export default programTypeMap
