import { useMemo } from 'react'
import { MenuOption } from '../components/Menus/DropDown'

export const useTranscriptYearsOptions = (): MenuOption[] => {
  // We take the fixed date of 2010 (Roger felt like it), and go until the current year + 4
  const yearsOptions: MenuOption[] = useMemo(() => {
    const yearOptionsToUpdate: MenuOption[] = []
    const startYear = 2010

    // Option for no year
    yearOptionsToUpdate.push({ name: '-- Select Year --', id: '' })

    const endYear = new Date().getUTCFullYear() + 4
    for (let i = startYear; i <= endYear; i++) {
      const yearToAdd = i.toString()
      yearOptionsToUpdate.push({
        name: yearToAdd,
        id: yearToAdd,
      })
    }
    return yearOptionsToUpdate
  }, [])
  return yearsOptions
}
