export const standardSort = (
  item1: string | number | Date,
  item2: string | number | Date
): number => {
  if (item1 < item2) {
    return -1
  }
  if (item1 > item2) {
    return 1
  }
  return 0
}
