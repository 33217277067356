import React from 'react'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import DropDown, { DropDownVariant } from '../Menus/DropDown'
import TeamTable from './TeamTable'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'
import TeamCard from './TeamCard'
import Box from '@mui/material/Box'
import { UserAccountListing } from '../../swagger'
import Divider from '@mui/material/Divider'
import ContainedButton, {
  ContainedButtonVariant,
} from '../Buttons/ContainedButton'
import SpaceBetweenSection from '../Elements/SpaceBetweenSection'
import { UserListingRoleAssignment } from '../../swagger/models/UserListingRoleAssignment'
import { styled } from '@mui/system'
import { Paper } from '@mui/material'
import { BlueHelpIcon } from '../Admin/Roles/Roles'

export enum SortOptions {
  lastNameASC = 'Sort By: Last Name (ASCENDING)',
  lastNameDESC = 'Sort By: Last Name (DESCENDING)',
  emailASC = 'Sort By: Email (ASCENDING)',
  emailDESC = 'Sort By: Email (DESCENDING)',
  roleASC = 'Sort By: Role (ASCENDING)',
  roleDESC = 'Sort By: Role (DESCENDING)',
}

/**
 * !!! Attempting to use component prop on Typography results in an error in the
 * form of TypeScript limitation regarding argument interface and overload
 * function signatures
 *
 * See known issues + workaround https://github.com/mui/material-ui/issues/15759#issuecomment-493994852
 */
const HeaderTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.textOrIcon.tableHeader,
  fontSize: '14px',
  lineHeight: '16px',
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(3, 0),
  },
})) as typeof Typography

interface TeamSummaryTableProps {
  mySupport?: UserAccountListing[]
  peopleISupport?: UserAccountListing[]
  searchedTeam?: UserAccountListing[]
  updateUserSelection: (opts: {
    userKey: number
    name: string
    actorKey: number
  }) => void
  nameOfUserForTeam: string
  searchQuery?: string
  isViewingDownline: boolean
  selectedRoleAndUserName?: string
  handleRowsPerPage: (rowAmount: number) => void
  onPageChange: (newPage: number) => void
  totalCount: number
  pageSize: number
  tableFooterPage: number
  handleDropDownSelection: (selection: string) => void
  sortOptions: {
    name: string
    id: SortOptions
  }[]
  sortAccountsOrderAndLabel: string
}

export const TeamSummaryTable: React.FC<TeamSummaryTableProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    mySupport,
    peopleISupport,
    searchedTeam,
    updateUserSelection,
    searchQuery = '',
    nameOfUserForTeam,
    isViewingDownline,
    selectedRoleAndUserName,
    pageSize,
    onPageChange,
    handleRowsPerPage,
    totalCount,
    tableFooterPage,
    handleDropDownSelection,
    sortOptions,
    sortAccountsOrderAndLabel,
  } = props

  const showOnDesktop = useShowOnDesktop()

  const handleEmailTeam = () => {
    return (window.location.href = `mailto:${peopleISupport?.map(
      (it) => it.username
    )}`)
  }

  const mySupportProps = {
    ariaLabelledBy: 'mySupport',
  }

  const peopleISupportProps = {
    ariaLabelledBy: 'peopleISupport',
  }

  const searchedTeamProps = {
    ariaLabelledBy: 'searchResults',
  }

  const textTypesForRoles = (
    role: UserListingRoleAssignment
  ): React.CSSProperties => {
    // Pending role
    if (role.validFrom.getTime() > new Date().getTime()) {
      return { fontStyle: 'italic' }
    }
    // Current role
    return {}
  }

  return (
    <>
      <div>
        <DropDown
          formControlProps={{
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              margin: theme.spacing(2, 0),
            },
          }}
          id="sort"
          menuOptions={sortOptions}
          defaultValue={t('Team.SortOption.Sort', 'sort')}
          handleSelection={handleDropDownSelection}
          variant={DropDownVariant.SortAndFilter}
          value={sortAccountsOrderAndLabel}
        />
      </div>
      {isViewingDownline && (
        <Paper
          style={{
            marginTop: 24,
            height: 36,
            marginBottom: theme.spacing(3),
          }}
          component="section"
          aria-labelledby="legend-label"
        >
          <Typography
            variant="body2"
            id="legend-label"
            component="h2"
            display="inline"
          >
            <BlueHelpIcon
              style={{
                color: theme.palette.customBackground.onPrimary,
                margin: theme.spacing(1),
                marginInlineStart: `${theme.spacing(1.5)}`,
              }}
            />
            {t('UserAccounts.Table.Legend.Title', 'KEY:')}
            <Box
              style={{
                display: 'inline',
                color: 'primary.main',
                fontFamily: 'plantin, sans-serif',
              }}
            >
              <Typography
                component="span"
                style={{
                  fontStyle: 'italic',
                  marginLeft: theme.spacing(1.5),
                }}
              >
                {t('UserAccounts.Table.Legend.PendingRole', 'Pending Role, ')}
              </Typography>
              <Typography component="span">
                {t('UserAccounts.Table.Legend.CurrentRole', ' Current Role ')}
              </Typography>
            </Box>
          </Typography>
        </Paper>
      )}

      {searchedTeam && (
        <>
          <Box mt={2}>
            <HeaderTypography id="searchResults" variant="button" component="p">
              {t(
                'TeamSummaryTable.Header.SearchResults',
                `Search Results for "{{searchQuery}}"`,
                { searchQuery }
              )}
            </HeaderTypography>
            {showOnDesktop ? (
              <TeamTable
                onPageChange={onPageChange}
                handleRowsPerPage={handleRowsPerPage}
                totalCount={totalCount}
                pageSize={pageSize}
                tableFooterPage={tableFooterPage}
                support={searchedTeam}
                updateUserSelection={updateUserSelection}
                textTypesForRoles={textTypesForRoles}
                {...searchedTeamProps}
              />
            ) : (
              <Box my={2}>
                <TeamCard
                  support={searchedTeam}
                  textTypesForRoles={textTypesForRoles}
                  updateUserSelection={updateUserSelection}
                  {...searchedTeamProps}
                />
              </Box>
            )}
          </Box>
          <Divider />
        </>
      )}
      {mySupport && (
        <Box mt={2}>
          <HeaderTypography id="mySupport" variant="button" component="p">
            {!!nameOfUserForTeam
              ? t(
                  'Team.Header.SupportOfUser',
                  "{{selectedRoleAndUserName}}'s Supervisor",
                  {
                    selectedRoleAndUserName,
                    interpolation: { escapeValue: false },
                  }
                )
              : t('Team.Header.MySupport', 'My Support')}
          </HeaderTypography>
          {showOnDesktop ? (
            <TeamTable
              onPageChange={onPageChange}
              tableFooterPage={tableFooterPage}
              handleRowsPerPage={handleRowsPerPage}
              totalCount={totalCount}
              pageSize={pageSize}
              support={mySupport}
              isClickable={false} // This row shouldn't be clickable. It won't take you anywhere.
              textTypesForRoles={textTypesForRoles}
              {...mySupportProps}
            />
          ) : (
            <Box my={2}>
              <TeamCard
                support={mySupport}
                textTypesForRoles={textTypesForRoles}
                {...mySupportProps}
              />
            </Box>
          )}
        </Box>
      )}
      {peopleISupport && (
        <>
          <SpaceBetweenSection
            isCentered
            left={
              <HeaderTypography
                id="peopleISupport"
                variant="button"
                component="p"
              >
                {!!nameOfUserForTeam
                  ? t(
                      'Team.Header.PeopleAUserSupports',
                      'People {{selectedRoleAndUserName}} Supports',
                      {
                        selectedRoleAndUserName,
                        interpolation: { escapeValue: false },
                      }
                    )
                  : t('Team.Header.PeopleISupport', 'People I Support')}
              </HeaderTypography>
            }
            right={
              peopleISupport.length > 0
                ? isViewingDownline && (
                    <ContainedButton
                      fullWidth
                      id="addProgramButton"
                      variant={ContainedButtonVariant.EmailPeopleISupport}
                      onClick={handleEmailTeam}
                      css={{
                        [theme.breakpoints.down('sm')]: {
                          margin: theme.spacing(1, 0, 2.5, 0),
                        },
                      }}
                    />
                  )
                : null
            }
          ></SpaceBetweenSection>
          {showOnDesktop ? (
            <TeamTable
              onPageChange={onPageChange}
              handleRowsPerPage={handleRowsPerPage}
              isPaginated={true}
              pageSize={pageSize}
              tableFooterPage={tableFooterPage}
              totalCount={totalCount}
              support={peopleISupport}
              textTypesForRoles={textTypesForRoles}
              updateUserSelection={updateUserSelection}
              {...peopleISupportProps}
            />
          ) : (
            <Box my={2}>
              <TeamCard
                support={peopleISupport}
                textTypesForRoles={textTypesForRoles}
                updateUserSelection={updateUserSelection}
                {...peopleISupportProps}
                showPagination={true}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default TeamSummaryTable
