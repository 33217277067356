import React from 'react'
import {
  BreakdownLedgerForPDF,
  BreakDownLedgerPDFVariants,
} from './BreakdownLedgerForPDF'
import { useLicensingPaymentBreakdownCalculations } from '../../../../hooks/useLicensingPaymentBreakdownCalculations'
import { useTranslation } from 'react-i18next'

type LicensingFeeSubTotalBreakdownProps = Pick<
  ReturnType<typeof useLicensingPaymentBreakdownCalculations>,
  | 'licensingFeeAdditionalStudent'
  | 'licensingFeeFirstStudent'
  | 'licensingFeeSubtotal'
>

export const LicensingFeeSubTotalBreakdown: React.FC<
  LicensingFeeSubTotalBreakdownProps
> = ({
  licensingFeeAdditionalStudent,
  licensingFeeFirstStudent,
  licensingFeeSubtotal,
}) => {
  const { t } = useTranslation()

  const licensingFeeApplicationLabel = t(
    'Licensing.PDF.Breakdown.Header.LicensingFee',
    'Licensing Fee - Application'
  )

  return (
    <BreakdownLedgerForPDF
      variant={BreakDownLedgerPDFVariants.Normal}
      headerLabel={licensingFeeApplicationLabel}
      firstStudent={licensingFeeFirstStudent}
      additionalStudent={licensingFeeAdditionalStudent}
      subtotal={licensingFeeSubtotal}
    />
  )
}
