import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useMatch, Outlet } from 'react-router'
import { extractedErrorObject, userAccountsApi, users } from '../../api/swagger'
import useLoadingContext from '../../hooks/useLoadingContext'
import useValidationMessages from '../../hooks/useValidationMessages'
import { UserAccountListing } from '../../swagger'
import { OperationIds } from '../../swagger/operationIdEnum'
import { useUser } from '../../UserContext'
import fullName from '../../utils/fullName'
import { LoadingContext } from '../Context/LoadingContext'
import {
  SnackbarSeverity,
  useSnackbarContext,
} from '../Context/SnackbarContext'
import { Page, EmptyPageCenter } from '../Elements/PageMargins'
import { useAuth } from '../Routes/AuthProvider'
import TeamAgreementsTable from './TeamAgreementsTable'
import { useMountEffect } from '../../hooks/useMountEffect'
import LoadingProgress from '../Elements/LoadingProgress'
import TeamSummaryTable, { SortOptions } from './TeamSummaryTable'
import SearchBar from '../Search/SearchBar'
import ResponsiveFab from '../Buttons/ResponsiveFab'
import AddIcon from '@mui/icons-material/Add'
import { addIconFudgeFactor } from '../../utils/addIconFudgeFactor'
import DynamicBreadcrumbs, { Breadcrumb } from '../Elements/DynamicBreadcrumbs'
import NoPermission from '../Elements/NoPermission'
import TitleContext from '../../TitleContext'
import { TFunction } from 'i18next'
import { defaultPageSize } from '../Pagination/TableFooterPagination'

enum TeamTabEnum {
  Team = 'team',
  Invites = 'invites',
}

export const minSearchChars = 3

enum TeamValidationMessageTypes {
  FetchTeamForUser = 'fetchTeamForUser',
  NoActorsFoundForUser = 'noActorsFoundForUser',
  FetchUsers = 'fetchUsers',
  Search = 'search',
}
interface BreadcrumbTrail {
  before?: number /** ActorKey of reportsTo breadcrumb */
  after?: number /** ActorKey of directReport breadcrumb */
  current: number /** ActorKey of current breadcrumb */
  breadcrumb: Breadcrumb
  userKey: number /** UserId of current breadcrumb */
  role?: string
}

export type acceptableTeamOrderByValues =
  | ['lastName ASC, firstName ASC']
  | ['lastName DESC, firstName DESC']
  | ['name ASC'] //roleName
  | ['name DESC'] //roleName
  | ['username ASC']
  | ['username DESC']

enum SortByTeamOptions {
  lastNameASC = 'lastName ASC',
  firstNameASC = 'firstName ASC',
  lastNameDESC = 'lastName DESC',
  firstNameDESC = 'firstName DESC',
  EmailASC = 'username ASC',
  EmailDESC = 'username DESC',
  RoleASC = 'name ASC',
  RoleDESC = 'name DESC',
}

const labelForSortOption = (sortOption: SortOptions, t: TFunction): string => {
  switch (sortOption) {
    case SortOptions.emailASC:
      return t('Team.SortOptions.Email.ASC', 'Sort By: Email (ASCENDING)')
    case SortOptions.emailDESC:
      return t('Team.SortOptions.Email.DESC', 'Sort By: Email (DESCENDING)')
    case SortOptions.roleASC:
      return t('Team.SortOptions.Role.ASC', 'Sort By: Role (ASCENDING)')
    case SortOptions.roleDESC:
      return t('Team.SortOptions.Role.DESC', 'Sort By: Role (DESCENDING)')
    case SortOptions.lastNameDESC:
      return t('Team.SortOptions.Role.DESC', 'Sort By: Last Name (DESCENDING)')
    case SortOptions.lastNameASC:
      return t(
        'Team.SortOptions.lastName.ASC',
        'Sort By: Last Name (ASCENDING)'
      )
    default:
      return t(
        'Team.SortOptions.Default.LastName.DESC',
        'Sort By: Last Name (DESCENDING)'
      )
  }
}

const getIndexOfSearchResultsBreadcrumb = (trail: BreadcrumbTrail[]) => {
  return trail.findIndex(
    (t) => new RegExp('Search Results', 'i').test(t.breadcrumb.label as string) // ???: Will this be a problem with translation?
  )
}

const firstPage = 1
const firstFooterPage = 0

export const TeamTab: React.FC = () => {
  /** Hooks */
  const { t } = useTranslation()
  const match = useMatch('/team')
  const navigate = useNavigate()
  const { user } = useUser()
  const { setSnackbarMessage, setSnackbarSeverity, setSnackbarState } =
    useSnackbarContext()

  const title = t('Team.Title', 'Team')
  const { useTitleEffect } = React.useContext(TitleContext)
  useTitleEffect(title)

  const auth = useAuth()
  const hasNoPermissions = auth.permissions.length === 0
  const { addLoadingIds, loadingIds } = React.useContext(LoadingContext)

  const filename = 'TeamTab'
  const fetchTeamForUserLoadingId = `${filename}-${OperationIds.FetchTeamForUser}`
  const fetchUsersLoadingId = `${filename}-${OperationIds.FetchUsers}`
  const fetchTeamAgreementsForUserLoadingId = `${filename}-${OperationIds.FetchTeamAgreementsForUser}`

  const location = useLocation()
  const { userKey, actorKey, firstName, lastName } = (location.state ?? {
    userKey: undefined,
    actorKey: undefined,
    firstName: undefined,
    lastName: undefined,
  }) as {
    userKey?: number
    actorKey?: number
    firstName?: string
    lastName?: string
  }

  /**
   * If we are passing state of another user to view their team, indicate so.
   */
  const isViewingTeamFromUserAccounts =
    !!userKey && !!actorKey && !!firstName && !!lastName

  /**
   * The userKey of the selected actor row in the People I Support table.
   * Used when calling fetchTeamForUser. Defaults to the currently logged in user's id.
   */
  const [selectedUserKey, setSelectedUserKey] = useState(userKey ?? user?.id)

  /**
   * The actorKey of the selected actor row in the People I Support table.
   * Used optionally when calling fetchTeamForUser. Defaults to -1 for the logged in user.
   */
  const [selectedActorKey, setSelectedActorKey] = useState(actorKey ?? -1)

  /**
   * String by which to search down-line users. The existence of non-empty search
   * let's us know when to add a 'Search Results' breadcrumb trail.
   */
  const [teamSearchQuery, setTeamSearchQuery] = useState('')

  /**
   * String by which to search down-line users. The existence of non-empty search
   * let's us know when to add a 'Search Results' breadcrumb trail.
   */
  const [invitesSearchQuery, setInvitesSearchQuery] = useState('')

  /**
   * String of the previous search. This updates when a user/actor row is clicked
   * and updates teamSearchQuery if 'Search Results' breadcrumb is clicked. The removal
   * of the 'Search Results' breadcrumb also resets this to empty string.
   */
  const previousSearchQuery = useRef('')

  const [page, setPage] = useState(firstPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [totalCount, setTotalCount] = useState(defaultPageSize)
  const [sortAccountsOrderAndLabel, setSortAccountsOrderAndLabel] = useState({
    name: labelForSortOption(SortOptions.lastNameASC, t),
    order: [SortByTeamOptions.lastNameASC, SortByTeamOptions.firstNameASC],
  })
  const [tableFooterPage, setTableFooterPage] = useState(firstFooterPage)

  /** State */
  const [selectedTab, setSelectedTab] = useState(() => {
    if (location.pathname.match(/\/invites/)) {
      return TeamTabEnum.Invites
    } else {
      return TeamTabEnum.Team
    }
  })

  /**
   * The full name of the selected user. Used in breadcrumbs and TeamSummaryTable.
   * Defaults to empty string for the logged in user.
   */
  const [selectedName, setSelectedName] = useState(
    isViewingTeamFromUserAccounts
      ? fullName({
          firstName,
          lastName,
        })
      : ''
  )

  const [selectedRoleAndUserName, setSelectedRoleAndUserName] = useState('')

  const [directReports, setDirectReports] = useState<UserAccountListing[]>()
  const [supervisors, setSupervisors] = useState<UserAccountListing[]>()

  /** Other variables */

  const validationMessagesMap = useValidationMessages([
    {
      field: TeamValidationMessageTypes.FetchTeamForUser,
      message: t(
        'Team.FetchTeamMembers.UnknownError',
        'Unknown Error occurred fetching team members.'
      ),
    },
    {
      field: TeamValidationMessageTypes.NoActorsFoundForUser,
      message: t(
        'Team.FetchTeamMembers.NoActorsFoundForUser',
        'No actors were found for user.'
      ),
    },
    {
      field: TeamValidationMessageTypes.FetchUsers,
      message: t(
        'Team.FetchUsers.UnknownError',
        'Unknown Error occurred fetching users.'
      ),
    },
    {
      field: TeamValidationMessageTypes.Search,
      message: t(
        'TeamTab.Search.MinimumLength',
        'Search should contain at least {{minSearchChars}} characters.',
        { minSearchChars }
      ),
    },
  ])

  const errorMessageFetchTeam = validationMessagesMap.get(
    TeamValidationMessageTypes.FetchTeamForUser
  )

  const errorMessageFetchUsers = validationMessagesMap.get(
    TeamValidationMessageTypes.FetchUsers
  )

  const teamTabTitle = t('TeamTab.TabTitle.Team', 'Team')
  const invitesTabTitle = t('TeamTab.TabTitle.Invites', 'Invites')

  const teamTab = (
    <Tab label={teamTabTitle} value={TeamTabEnum.Team} key={teamTabTitle} />
  )
  const invitesTab = (
    <Tab
      label={invitesTabTitle}
      value={TeamTabEnum.Invites}
      key={invitesTabTitle}
    />
  )

  const tabs = [teamTab, invitesTab]

  /** Methods */
  const updateSelectedTab = (tabKey: string) => {
    switch (tabKey) {
      case TeamTabEnum.Invites:
        navigate(`${match?.pathname}/invites`, {
          state: {
            userKey,
            actorKey,
            firstName,
            lastName,
          },
        })
        setSelectedTab(TeamTabEnum.Invites)
        break
      case TeamTabEnum.Team:
      default:
        navigate('/team', {
          state: {
            userKey,
            actorKey,
            firstName,
            lastName,
          },
        })
        setSelectedTab(TeamTabEnum.Team)
        break
    }
  }

  /**
   * Set the selected user and actor for the Team view.
   *
   * Empty params will reset to the current user and no actor
   *
   * @param opts containing optional actorKey and userKey
   */
  const updateUserActorSelection = useCallback(
    (opts: {
      actorKey?: number
      userKey?: number
      name?: string
      refetch?: boolean
      role?: string
      updatedTab?: TeamTabEnum
    }) => {
      const {
        actorKey = -1,
        userKey = user?.id,
        name = '',
        refetch = false,
        role = '',
        updatedTab = selectedTab,
      } = opts
      setSelectedUserKey(userKey)
      setSelectedActorKey(actorKey)
      setSelectedName(name)
      //state passed to TeamSummaryTable for table headers
      setSelectedRoleAndUserName(`${name} - ${role}`)
      if (refetch) {
        const loadingId =
          updatedTab === TeamTabEnum.Team
            ? fetchTeamForUserLoadingId
            : fetchTeamAgreementsForUserLoadingId
        addLoadingIds([loadingId])
      }
    },
    [
      addLoadingIds,
      fetchTeamForUserLoadingId,
      user?.id,
      selectedTab,
      fetchTeamAgreementsForUserLoadingId,
    ]
  )

  const loadTeamForUser = async () => {
    try {
      const {
        directReports: fetchedDirectReports,
        supervisors: fetchedSupervisors,
        pagination,
      } = await users.fetchTeamForUser({
        // If we're requesting an actor that the current user also has in their downline and can act as, don't provide 'me'
        userId: `${
          selectedUserKey === user?.id && selectedActorKey < 0
            ? 'me'
            : selectedUserKey
        }`,
        actorKey:
          !!selectedActorKey && selectedActorKey > 0
            ? selectedActorKey
            : undefined,
        page,
        pageSize,
        orderBy: sortAccountsOrderAndLabel.order as acceptableTeamOrderByValues,
      })
      setDirectReports(fetchedDirectReports)
      setSupervisors(fetchedSupervisors)
      setTotalCount(!!pagination?.totalCount ? pagination?.totalCount : 0)
      setPage(page)
      setPageSize(pagination?.pageSize ?? defaultPageSize)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message ?? errorMessageFetchTeam,
      }

      if (errorObject.code === 'NoActorsFoundForUser') {
        errorObject.message = validationMessagesMap.get(
          TeamValidationMessageTypes.NoActorsFoundForUser
        ) as string
      }
      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  const refetchUserTeamData = useMemo(
    () => () => {
      // Trigger refetching data unless we already are.
      addLoadingIds([fetchTeamForUserLoadingId])
    },
    [addLoadingIds, fetchTeamForUserLoadingId]
  )

  const onPageChange = useCallback(
    (nextPage: number): void => {
      setPage(nextPage + 1)
      setTableFooterPage(nextPage)
      // Trigger refresh when changing page
      refetchUserTeamData()
    },
    [refetchUserTeamData]
  )

  const sortOptions = Object.values(SortOptions).map((option) => {
    return {
      name: labelForSortOption(option, t),
      id: option,
    }
  })

  const handleDropDownSelection = useCallback(
    (selection: string) => {
      if (selection.includes(SortOptions.emailASC)) {
        setSortAccountsOrderAndLabel({
          name: labelForSortOption(SortOptions.emailASC, t),
          order: [SortByTeamOptions.EmailASC],
        })
        refetchUserTeamData()
      } else if (selection.includes(SortOptions.emailDESC)) {
        setSortAccountsOrderAndLabel({
          name: labelForSortOption(SortOptions.emailDESC, t),
          order: [SortByTeamOptions.EmailDESC],
        })
        refetchUserTeamData()
      } else if (selection.includes(SortOptions.roleASC)) {
        setSortAccountsOrderAndLabel({
          name: labelForSortOption(SortOptions.roleASC, t),
          order: [SortByTeamOptions.RoleASC],
        })
        refetchUserTeamData()
      } else if (selection.includes(SortOptions.roleDESC)) {
        setSortAccountsOrderAndLabel({
          name: labelForSortOption(SortOptions.roleDESC, t),
          order: [SortByTeamOptions.RoleDESC],
        })
        refetchUserTeamData()
      } else if (selection.includes(SortOptions.lastNameASC)) {
        setSortAccountsOrderAndLabel({
          name: labelForSortOption(SortOptions.lastNameASC, t),
          order: [
            SortByTeamOptions.lastNameASC,
            SortByTeamOptions.firstNameASC,
          ],
        })
        refetchUserTeamData()
      } else if (selection.includes(SortOptions.lastNameDESC)) {
        setSortAccountsOrderAndLabel({
          name: labelForSortOption(SortOptions.lastNameDESC, t),
          order: [
            SortByTeamOptions.lastNameDESC,
            SortByTeamOptions.firstNameDESC,
          ],
        })
        refetchUserTeamData()
      }
    },
    [refetchUserTeamData, t]
  )

  const handleRowsPerPage = (rowAmount: number): void => {
    setPageSize(rowAmount)
    // Trigger refresh on rows per page change
    refetchUserTeamData()
  }

  /**
   * Given user information exists, we have selected at least one user, are not
   * currently fetching team information, and either have not loaded the team or
   * require a refresh of the selected team per the selectedUserKey, call fetchTeamForUser
   */
  useMountEffect(() => {
    addLoadingIds([fetchTeamForUserLoadingId])
  })

  useLoadingContext({
    loadingId: fetchTeamForUserLoadingId,
    asyncFunction: loadTeamForUser,
  })

  useLoadingContext({
    loadingId: fetchUsersLoadingId,
    asyncFunction: () => fetchUserForSearch(teamSearchQuery),
  })

  const [userAccountsForSearch, setUserAccountsForSearch] =
    useState<UserAccountListing[]>()

  // Linked list of breadcrumbs. Enables easy fetching of team and modifying the current breadcrumbs at ease.
  const [breadcrumbTrail, setBreadcrumbTrail] = useState<BreadcrumbTrail[]>([])

  const canInviteTeamMember = auth.permissions.some(
    (permission) => permission.resourceCode === 'TeamInvite'
  )

  /**
   * Reset teamSearchQuery, searched users object, and breadcrumb trail when clearing
   * the search.
   */
  const clearAndHandleSearch = () => {
    // Reset the search string
    if (selectedTab === TeamTabEnum.Team) {
      setTeamSearchQuery('')
    } else {
      setInvitesSearchQuery('')
    }
    // Remove any search results
    setUserAccountsForSearch(undefined)
    // Remove the 'Search Results' breadcrumb from the trail
    setBreadcrumbTrail((currTrail) =>
      updateBreadcrumbTrailOnClearAndHandleSearch(currTrail)
    )
  }

  /**
   * Do exactly as it says, call the fetchUser endpoint when we are searching.
   *
   * This does not support viewing another user's downline. Utilize User Accounts
   * to search for users outside one's own downline
   */
  const fetchUserForSearch = async (search: string) => {
    try {
      const { userAccounts: userAccountsForSearch } =
        await userAccountsApi.fetchUsers({
          // This is always true in the case of Teams. If you want to fetch more than downline, get access to UserAccounts
          downlineOnly: true,
          // Retrieve members with active roles.
          validNowOnly: true,
          search,
        })
      setUserAccountsForSearch(userAccountsForSearch)
    } catch (e) {
      const errorObject = (await extractedErrorObject(e)) ?? {
        code: 'UnknownError',
        message: (e as unknown as Error).message ?? errorMessageFetchUsers,
      }

      setSnackbarState(true)
      setSnackbarMessage(errorObject.message)
      setSnackbarSeverity(SnackbarSeverity.Error)
    }
  }

  const [searched, setSearched] = useState(false)

  /**
   * Take the submission of the search and update previous values for later clicking 'Search Results' breadcrumb,
   * The current search query so when we click we can determine if we are clicking during a search,
   * and fetch the users for the current user's downline with the search query provided.
   *
   * This resets the current selected user to the logged in user. We are always going to search
   * the current user's downline.
   *
   * Optionally clears the search if we empty the search bar and submit.
   */
  const handleSearch = (str: string, updatedTab?: TeamTabEnum) => {
    // If we have yet to reach the minimum length required to search, inform the user.
    if (str.length < minSearchChars && str !== '') {
      setSnackbarSeverity?.(SnackbarSeverity.Error)
      setSnackbarMessage?.(
        validationMessagesMap.get(TeamValidationMessageTypes.Search) as string
      )
      setSnackbarState?.(true)
      return
    }

    // Only update the previous ref if the query is valid for search
    previousSearchQuery.current = str

    /**
     * Only reset userAccounts to display on Team tab if we are searching our team.
     * Invitation searching is purely client-side, for now.
     */
    if ((updatedTab ?? selectedTab) === TeamTabEnum.Team) {
      setTeamSearchQuery(str)
      if (!isViewingTeamFromUserAccounts) {
        // Each time we search we want to be under the current user. Empty params will reset to the current user and no actor
        updateUserActorSelection({})
      } else {
        const name = fullName({
          firstName,
          lastName,
        })
        // And refetch their initial downline
        updateUserActorSelection({ userKey, actorKey, name })
      }

      // If we are emptying
      if (str === '') {
        // Clear search if submitting nothing
        clearAndHandleSearch()
        // Remove results if submitting nothing
        setUserAccountsForSearch(undefined)
        return
      }

      setBreadcrumbTrail((currTrail) =>
        updateBreadcrumbTrailForSearch(currTrail, str)
      )
    } else {
      setInvitesSearchQuery(str)
    }
    setSearched(true)
  }

  useEffect(() => {
    if (searched) {
      const loadingId =
        selectedTab === TeamTabEnum.Team
          ? fetchUsersLoadingId
          : fetchTeamAgreementsForUserLoadingId

      addLoadingIds([loadingId])
      setSearched(false)
    }
  }, [
    addLoadingIds,
    fetchUsersLoadingId,
    searched,
    selectedTab,
    fetchTeamAgreementsForUserLoadingId,
  ])

  /**
   * Given a breadcrumbTrail, modify the trail by existing and non-existing entries.
   *
   * @param opts the current breadcrumb and information regarding the user selection
   * @returns a modified trail of breadcrumbs based on the selections and existing breadcrumbs
   */
  const updateBreadcrumbTrailForUserSelection = (opts: {
    userKey: number
    actorKey: number
    name: string
    currTrail: BreadcrumbTrail[]
    role?: string
    updatedTab?: TeamTabEnum
  }) => {
    const {
      currTrail,
      actorKey,
      userKey,
      name,
      role,
      updatedTab = selectedTab,
    } = opts
    let trail = [...currTrail]

    // Check if we've recently added Search Results
    const indexOfSearchResults = getIndexOfSearchResultsBreadcrumb(trail)

    // And see if what we have clicked already exists.
    const existingIndex = trail.findIndex((trail) => trail.current === actorKey)

    if (indexOfSearchResults > 0 || existingIndex === 0) {
      // Clear out the search query if we have one and we are clicking a row.
      // Search will retain on the 'Search Results' breadcrumb view.
      if (updatedTab === TeamTabEnum.Team) {
        setTeamSearchQuery('')
      } else {
        setInvitesSearchQuery('')
      }
      // If we have a search query and are clicking on something, just clear the search results...
      setUserAccountsForSearch(undefined)
    }

    // If the 'current' actorKey is already in the list (including -1 for 'me' and -2 for 'Search Results')
    if (existingIndex >= 0) {
      // If we are clicking on search results, search the previous query
      if (indexOfSearchResults > 0 && indexOfSearchResults === existingIndex) {
        handleSearch(previousSearchQuery.current, updatedTab)
      }
      // If 'Search Results' is a valid breadcrumb AND we did not click 'Search Results'
      // Greater than zero is valid here since 'Search Results' should never be 0 index
      if (
        indexOfSearchResults > 0 &&
        indexOfSearchResults !== existingIndex &&
        existingIndex > 0
      ) {
        // Take our user and the Search Results breadcrumbs.
        const frontTrail = trail.slice(0, 2)

        // And append the existing user, modifying the necessary before/after
        trail = [
          frontTrail[0], // Our user's breadcrumbTrail
          {
            ...frontTrail[1], // Search Results breadcrumbTrail with modified after
            after: trail[existingIndex].current,
          },
          {
            ...trail[existingIndex],
            before: -2, // Current of 'Search Results',
          },
        ]
      } else {
        // If clicking on the first index, ensure we don't have a teamSearchQuery anymore
        if (existingIndex === 0) {
          if (selectedTab === TeamTabEnum.Team) {
            setTeamSearchQuery('')
          } else {
            setInvitesSearchQuery('')
          }
        }
        // Slice anything beyond the existing selection
        return trail.slice(0, existingIndex + 1)
      }
    } else {
      // If we do have 'Search Results', remove all but the existing user.
      if (indexOfSearchResults > 0 && !!teamSearchQuery) {
        // Take our user and the Search Results breadcrumbs.
        const frontTrail = trail.slice(0, 2)
        trail = [...frontTrail]
      }

      // Otherwise push it to the end of the list
      trail[trail.length - 1].after = actorKey

      // Then push the selected actorKey onto the breadcrumb trail
      const label = `${name} - ${role}`
      trail.push({
        before: trail[trail.length - 1].current,
        current: actorKey,
        breadcrumb: {
          label: label,
        },
        userKey,
        role,
      })
    }
    return trail
  }

  /**
   * Given clearing and handling and empty search, update the breadcrumbs accordingly.
   * @param currTrail trail of breadcrumbs to be modified
   * @returns modified breadcrumb trail
   */
  const updateBreadcrumbTrailOnClearAndHandleSearch = (
    currTrail: BreadcrumbTrail[]
  ) => {
    let trail = [...currTrail]
    const indexOfSearchResults = getIndexOfSearchResultsBreadcrumb(trail)
    // Remove the search results
    trail.splice(indexOfSearchResults, 1)
    // And correct the first and second before/afters
    if (trail.length > 1) {
      const firstBreadcrumb = trail[0]
      const secondBreadcrumb = trail[1]
      const backTrail = trail.slice(2)
      trail = [
        {
          ...firstBreadcrumb,
          after: secondBreadcrumb.current,
        },
        {
          ...secondBreadcrumb,
          before: firstBreadcrumb.current,
        },
        ...backTrail,
      ]
    }
    return trail
  }

  /**
   * Given submitting a teamSearchQuery of proper length, update the breadcrumb trail to be
   * the current user + 'Search Results'
   *
   * @param currTrail the current breadcrumb trail
   * @param searchString the string currently being searched
   * @returns a modified breadcrumb trail given searching
   */
  const updateBreadcrumbTrailForSearch = (
    currTrail: BreadcrumbTrail[],
    searchString: string
  ) => {
    let trail = [...currTrail]
    // By first finding if we have the index
    const indexOfSearchResults = getIndexOfSearchResultsBreadcrumb(trail)

    // Replace the breadcrumb trail that exists. Since we put the search string in the breadcrumb, it always needs an update.
    // Grab just the first breadcrumb
    // We don't want the rest of the trail because we are searching the CURRENT user's downline, NOT their downline's downline.
    const frontTrail = trail.slice(0, 1)

    // If we are viewing our own team
    if (!isViewingTeamFromUserAccounts) {
      // This means we need to set the selectedUserKey to user?.id
      // and the actorKey to -1
      // And the name to be empty
      updateUserActorSelection({})
    }

    const searchResultsBreadcrumb: BreadcrumbTrail = {
      before:
        isViewingTeamFromUserAccounts && !!actorKey
          ? actorKey
          : (user?.id as number),
      current: -2, // Can't be -1, which denotes the front of the list, so why not -2 ¯\_(ツ)_/¯
      breadcrumb: {
        label: t(
          'Team.Breadcrumb.SearchResultsForUser',
          `Search Results for "{{searchString}}"`,
          { searchString }
        ),
      },
      userKey: userKey as number, // This will always be a number if we can select another user
    }
    trail = [
      ...frontTrail,
      searchResultsBreadcrumb, // We will always add this breadcrumb if it doesn't exist and it needs to.
    ]
    // But still slice off anything beyond 'Search Results' if we are searching again
    if (indexOfSearchResults > 0) {
      // If we do have the 'Search Results' breadcrumb and we are searching again, just slice off anything beyond 'Search Results'
      trail = trail.slice(0, 2)
    }
    return trail
  }

  /**
   * Update user information surrounding row selection. Provides information so
   * breadcrumbs can be maintained.
   *
   * Sets the selected user and actor keys to the selected row. Updates the breadcrumb
   * trail to include search results based on whether we have submitted a search query
   * and chain selections if clicking the downline members, among other combination of actions.
   *
   * @param opts contains userKey, actorKey and name of selected row. Contains searchResultSelection
   *   to determine if the selection is via search results or anything else.
   */
  const updateUserSelection = (opts: {
    userKey: number
    name: string
    actorKey: number
    role?: string
    updatedTab?: TeamTabEnum
  }) => {
    const { userKey, actorKey, name, role, updatedTab } = opts
    // Update the userKey, actorKey and breadcrumb
    // Update the name to empty if the user id is the current user, otherwise the selected name
    // Inform the page we need to refresh the team for user selection
    // on property name we remove anything after "-" and get just the name, we will add the role on
    // updateUserActorSelection, this avoids getting duplicate roles when navigating back using breadcrumbs.
    updateUserActorSelection({
      userKey,
      actorKey,
      name: userKey === user?.id ? '' : name.split('-')[0],
      refetch: true,
      role,
      updatedTab,
    })
    setBreadcrumbTrail((currTrail) =>
      updateBreadcrumbTrailForUserSelection({ currTrail, ...opts })
    )
  }

  /**
   * Given we have user information and no breadcrumbs, initialize the breadcrumbs
   * to be the first and last name of the current user.
   *
   * If we have a userKey and actorKey, then set the first breadcrumb to that user
   */
  useEffect(() => {
    if (
      !!userKey &&
      !!actorKey &&
      !!firstName &&
      !!lastName &&
      breadcrumbTrail.length === 0
    ) {
      updateUserActorSelection({ userKey, actorKey, name: selectedName })
      setBreadcrumbTrail([
        {
          current: actorKey,
          breadcrumb: {
            label: fullName({
              firstName,
              lastName,
            }),
          },
          userKey,
        },
      ])
    } else {
      if (user && breadcrumbTrail.length === 0) {
        updateUserActorSelection({ actorKey })
        setBreadcrumbTrail([
          {
            current: -1,
            breadcrumb: {
              label: fullName({
                firstName: user.firstName ?? '',
                lastName: user.lastName ?? '',
              }),
            },
            userKey: user.id,
          },
        ])
      }
    }
  }, [
    user,
    breadcrumbTrail,
    userKey,
    actorKey,
    firstName,
    lastName,
    isViewingTeamFromUserAccounts,
    updateUserActorSelection,
    selectedName,
  ])

  const isViewingDownline = user?.id === selectedUserKey && selectedActorKey < 0

  const searchPlaceholderForTab = () => {
    switch (selectedTab) {
      case TeamTabEnum.Invites:
        return t(
          'Team.Search.SearchInvitations',
          'Search invitations ({{minSearchChars}} character minimum)',
          { minSearchChars }
        )
      case TeamTabEnum.Team:
      default:
        return t(
          'Team.Search.SearchDownline',
          'Search my downline ({{minSearchChars}} character minimum)',
          { minSearchChars }
        )
    }
  }

  const FabSearchAndBreadcrumbs = (
    <>
      {canInviteTeamMember && (
        <ResponsiveFab
          to="/team/add-team-member"
          icon={AddIcon}
          spacingFudgeFactor={addIconFudgeFactor}
          iconLabel={t('Team.AddTeamMember.Button.IconLabel', 'Add')}
          textLabel={t('Team.AddTeamMember.Button.TextLabel', 'Team Member')}
          fullLabel={t(
            'Team.AddTeamMember.Button.FullLabel',
            'Add Team Member'
          )}
        />
      )}

      <DynamicBreadcrumbs
        breadcrumbs={[
          ...breadcrumbTrail.map((it, index) => {
            const labelForBreadcrumb = it.breadcrumb.label
            return {
              ...it.breadcrumb,
              label:
                index === 0 && selectedTab === TeamTabEnum.Invites
                  ? t(
                      'Team.TeamTab.InvitesForActor.Label',
                      'Invites for {{labelForBreadcrumb}}',
                      { labelForBreadcrumb }
                    )
                  : labelForBreadcrumb,
              onClick:
                selectedTab === TeamTabEnum.Invites
                  ? undefined
                  : () => {
                      updateSelectedTab(TeamTabEnum.Team)
                      // If before the search results breadcrumb, should we remove the previous query? Probably not, there's no way to get back...
                      updateUserSelection({
                        userKey: it.userKey,
                        name: it.breadcrumb.label as string,
                        actorKey: it.current,
                        role: isViewingTeamFromUserAccounts
                          ? selectedRoleAndUserName
                          : it.role,
                        updatedTab: TeamTabEnum.Team,
                      })
                    },
            }
          }),
        ]}
        /** Only show different styles on the Invites tab */
        useHeaderStyles={selectedTab === TeamTabEnum.Invites}
      />
      {!isViewingTeamFromUserAccounts && (
        <SearchBar
          searchValue={
            selectedTab === TeamTabEnum.Team
              ? teamSearchQuery
              : invitesSearchQuery
          }
          handleSearch={handleSearch}
          clearAndHandleSearch={clearAndHandleSearch}
          placeholder={searchPlaceholderForTab()}
        />
      )}
    </>
  )

  return location.pathname === '/team' ||
    location.pathname === '/team/invites' ? (
    <TabContext value={selectedTab}>
      <TabList
        value={selectedTab}
        onChange={(_, newValue) => updateSelectedTab(newValue)}
        textColor="primary"
      >
        {tabs}
      </TabList>
      <TabPanel value={TeamTabEnum.Team}>
        <Page withinTab>
          {hasNoPermissions ? (
            <Page>
              <NoPermission
                isLoading={
                  loadingIds.has(fetchTeamForUserLoadingId) ||
                  loadingIds.has(fetchUsersLoadingId)
                }
                content={t(
                  'Team.NoPermission',
                  'Sorry, you do not have permission to view this content.'
                )}
              />
            </Page>
          ) : (
            <>
              {FabSearchAndBreadcrumbs}
              {loadingIds.has(fetchTeamForUserLoadingId) ||
              loadingIds.has(fetchUsersLoadingId) ? (
                <EmptyPageCenter adjustForMargins>
                  <LoadingProgress />
                </EmptyPageCenter>
              ) : (
                <TeamSummaryTable
                  sortAccountsOrderAndLabel={sortAccountsOrderAndLabel.name}
                  sortOptions={sortOptions}
                  handleDropDownSelection={handleDropDownSelection}
                  pageSize={pageSize}
                  tableFooterPage={tableFooterPage}
                  onPageChange={onPageChange}
                  totalCount={totalCount}
                  handleRowsPerPage={handleRowsPerPage}
                  mySupport={supervisors}
                  peopleISupport={directReports}
                  searchedTeam={userAccountsForSearch}
                  updateUserSelection={updateUserSelection}
                  searchQuery={teamSearchQuery}
                  nameOfUserForTeam={selectedName}
                  isViewingDownline={isViewingDownline}
                  selectedRoleAndUserName={selectedRoleAndUserName}
                />
              )}
            </>
          )}
        </Page>
      </TabPanel>
      <TabPanel value={TeamTabEnum.Invites}>
        {/* TODO: Breadcrumbs coming in another ticket https://classicalconversations.my.workfront.com/task/6345d1ff00186bcf344a8355e132498c/updates */}
        {/* TODO: Searchbar coming in another ticket https://classicalconversations.my.workfront.com/task/6345d1bf0018592f8d3352e29e283627/updates */}
        <Page withinTab>
          {FabSearchAndBreadcrumbs}
          {!selectedUserKey ? (
            <EmptyPageCenter adjustForMargins>
              <LoadingProgress />
            </EmptyPageCenter>
          ) : (
            <TeamAgreementsTable
              userKey={selectedUserKey}
              actorKey={selectedActorKey}
              fetchTeamAgreementsForUserLoadingId={
                fetchTeamAgreementsForUserLoadingId
              }
              searchQuery={invitesSearchQuery}
            />
          )}
        </Page>
      </TabPanel>
    </TabContext>
  ) : (
    <Outlet />
  )
}

export default TeamTab
