import { useTheme } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { Box } from '@mui/material'

interface PageProps extends PropsWithChildren {
  withinTab?: boolean
}

/**
 * Provides space and content positioning
 *
 * This component is intended to be rendered only once per page.
 * The Page component must never be a child of another Page component.
 *
 * @component
 * @param {boolean} withinTab If is true reduces the top space in order to render the tab components
 *
 * @returns {JSX.Element} returns the page component
 */
export const Page: React.FC<PageProps> = ({ children, withinTab = false }) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        color: theme.palette.primary.main,
        padding: theme.spacing(5, 2, 12),
        [theme.breakpoints.up('sm')]: {
          paddingTop: withinTab ? theme.spacing(4) : theme.spacing(6),
        },
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(6, 4, 12),
        },
      }}
    >
      {children}
    </Box>
  )
}

interface EmptyPageCenterProps extends PropsWithChildren {
  adjustForMargins?: boolean
}

export const EmptyPageCenter: React.FC<EmptyPageCenterProps> = ({
  children,
  adjustForMargins = false,
}) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        minHeight: adjustForMargins
          ? `calc(100vh - ${theme.spacing(7)} - 2 * ${theme.spacing(3)})`
          : `calc(100vh - ${theme.spacing(7)})`,
        [theme.breakpoints.up('sm')]: {
          minHeight: adjustForMargins
            ? `calc(100vh - ${theme.spacing(8)} - 2 * ${theme.spacing(6)})`
            : `calc(100vh - ${theme.spacing(8)})`,
        },
      }}
    >
      {children}
    </Box>
  )
}
