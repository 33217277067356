/* tslint:disable */
/* eslint-disable */
/**
 * ProjectOne Swagger
 * API for Classical Conversations content and communities.  # Service URLs In local dev: - [The **local backend**](http://0.0.0.0:8080/) defaults to running on port 8080. - [The **local frontend**](http://0.0.0.0:3000/) defaults to running on port 3000.  In the DEV environment: - [The **DEV backend**](https://dev-ccp1-api.classicaltesting.net/) runs at https://dev-ccp1-api.classicaltesting.net/. - [The **DEV frontend**](https://dev-ccp1.classicaltesting.net/) runs at https://dev-ccp1.classicaltesting.net/.  The TEST environment is similar, but with `test-` instead of `dev-`.
 *
 * The version of the OpenAPI document: 0225eb75 (Fri Nov 15 18:58:29 UTC 2024)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime'
import { Grant, GrantFromJSON, GrantToJSON } from './'

/**
 * Describes a named collection of permissions that the role grants to users holding the role. Each permission has an associated scope to which the grant applies (globally, or restricted by the reporting hierarchy to those transitively reporting to the current user with regard to the role).
 *
 * The set of roles is _not_ fixed; roles can come, go, and be renamed at any time. A user gains permissions solely by being granted a role.
 * @export
 * @interface Role
 */
export interface Role {
  /**
   * Role unique identifier
   * @type {number}
   * @memberof Role
   */
  roleKey?: number
  /**
   * User-chosen name for the role. Names are unique, non-blank, and have neither leading nor trailing whitespace.
   * @type {string}
   * @memberof Role
   */
  name: string
  /**
   * Descriptive text for the role. Might be empty or blank. It’s whatever text an administrator wants to stuff in there.
   * @type {string}
   * @memberof Role
   */
  notes: string
  /**
   * Permissions granted, and with what scope. This list might be empty, such as when a role has been newly created. This list might also contain obsolete resource-action pairs that were valid when assigned to the role but have since disappeared; any such pairs should be ignored.
   * @type {Array<Grant>}
   * @memberof Role
   */
  grants: Array<Grant>
}

export function RoleFromJSON(json: any): Role {
  return RoleFromJSONTyped(json, false)
}

export function RoleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Role {
  if (json === undefined || json === null) {
    return json
  }
  return {
    roleKey: !exists(json, 'roleKey') ? undefined : json['roleKey'],
    name: json['name'],
    notes: json['notes'],
    grants: (json['grants'] as Array<any>).map(GrantFromJSON),
  }
}

export function RoleToJSON(value?: Role | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    roleKey: value.roleKey,
    name: value.name,
    notes: value.notes,
    grants: (value.grants as Array<any>).map(GrantToJSON),
  }
}
