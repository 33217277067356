import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import React, { ReactElement, ReactNode, useMemo } from 'react'
import { AgreementTemplateFormFieldInputType } from '../../swagger'
import useValidationMessages from '../../hooks/useValidationMessages'
import { useTranslation } from 'react-i18next'
import { DynamicFormFieldIsValid } from '../Interfaces/DynamicFieldStates'
import { MenuOption } from '../Menus/DropDown'
import { SelectChangeEvent, Typography, useTheme } from '@mui/material'

export interface DynamicFormFieldProps {
  id: string
  label: string
  dropdownOptions?: MenuOption[]
  updateForParent: <T = number>(
    name: string,
    state: string,
    required: boolean,
    isOverMaxLength: boolean,
    isUnderMinLength: boolean,
    option?: T
  ) => void
  value: string
  isValid: DynamicFormFieldIsValid
  required: boolean
  inputType?: string
  maxLength?: number
  minLength?: number
  validationMessage?: string
  name: string
  fullWidth?: boolean
  disabled?: boolean
  shrink?: boolean
  minDate?: Date
  maxDate?: Date
}

enum DynamicFormValidationMessageTypes {
  CannotBeEmpty = 'cannotBeEmpty',
  ValidDate = 'validDate',
  GreaterThanLength = 'greaterThanLength',
  LessThanLength = 'lessThanLength',
  HasNoOptions = 'hasNoOptions',
  Email = 'email',
  OutOfDateRange = 'outOfDateRange',
}

const setupHelperText = (opts: {
  validationMessage?: string
  inputType: string
  isValid: DynamicFormFieldIsValid
  isEmpty: boolean
  validationMessageMap: Map<string, string>
  label: string
  minLength?: number
  maxLength?: number
  disabled?: boolean
}): string => {
  const {
    validationMessage,
    inputType,
    isValid,
    isEmpty,
    validationMessageMap,
    label,
    minLength,
    maxLength,
    disabled,
  } = opts

  if (validationMessage) return validationMessage
  // Check if it is a date
  else if (
    inputType === AgreementTemplateFormFieldInputType.Date &&
    !isValid.input
  ) {
    return `${label} ${validationMessageMap.get(
      DynamicFormValidationMessageTypes.ValidDate
    )}`
  }
  // Check if it is an empty dropdown
  else if (
    inputType === AgreementTemplateFormFieldInputType.Dropdown &&
    isEmpty &&
    disabled
  ) {
    return `${label} ${validationMessageMap.get(
      DynamicFormValidationMessageTypes.HasNoOptions
    )}`
  }
  // Check valid input
  else if (!!isValid) {
    if (!isValid.input) {
      return `${label} ${validationMessageMap.get(
        DynamicFormValidationMessageTypes.CannotBeEmpty
      )}`
    }
    // Check if past max length
    else if (!isValid.maxLength) {
      return `${label} ${validationMessageMap.get(
        DynamicFormValidationMessageTypes.LessThanLength
      )} ${maxLength}`
    }
    // Check if before min length
    else if (!isValid.minLength) {
      return `${label} ${validationMessageMap.get(
        DynamicFormValidationMessageTypes.GreaterThanLength
      )} ${minLength}`
    } else if (!isValid.email) {
      return `${validationMessageMap.get(
        DynamicFormValidationMessageTypes.Email
      )}`
    } else if (
      inputType === AgreementTemplateFormFieldInputType.Date &&
      !isValid.dateRange
    ) {
      return `${validationMessageMap.get(
        DynamicFormValidationMessageTypes.OutOfDateRange
      )}`
    }
  }

  return ''
}

export const DynamicFormField: React.FC<DynamicFormFieldProps> = ({
  id,
  label,
  dropdownOptions,
  updateForParent,
  value,
  isValid,
  required,
  inputType = AgreementTemplateFormFieldInputType.Text,
  maxLength,
  minLength,
  validationMessage,
  name,
  fullWidth = false,
  disabled,
  shrink,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isDropdown = inputType === AgreementTemplateFormFieldInputType.Dropdown

  const isEmpty = isDropdown
    ? !!dropdownOptions && dropdownOptions.length === 0
    : false

  const isDateField = inputType === AgreementTemplateFormFieldInputType.Date

  const validationMessageMap = useValidationMessages([
    {
      field: DynamicFormValidationMessageTypes.ValidDate,
      message: t(
        'DynamicFormField.ValidationMessage.ValidDate',
        'must be a valid date in the form mm-dd-yyyy.'
      ),
    },
    {
      field: DynamicFormValidationMessageTypes.CannotBeEmpty,
      message: t(
        'DynamicFormField.ValidationMessage.CannotBeEmpty',
        'cannot be empty.'
      ),
    },
    {
      field: DynamicFormValidationMessageTypes.GreaterThanLength,
      message: t(
        'DynamicFormField.ValidationMessage.GreaterThanLength',
        'must have length greater than'
      ),
    },
    {
      field: DynamicFormValidationMessageTypes.LessThanLength,
      message: t(
        'DynamicFormField.ValidationMessage.LessThanLength',
        'cannot exceed length'
      ),
    },
    {
      field: DynamicFormValidationMessageTypes.HasNoOptions,
      message: t(
        'DynamicFormField.ValidationMessage.HasNoOptions',
        'has no available options.'
      ),
    },
    {
      field: DynamicFormValidationMessageTypes.Email,
      message: t(
        'DynamicFormField.ValidationMessage.InvalidEmail',
        'Email address is formatted incorrectly.'
      ),
    },
    {
      field: DynamicFormValidationMessageTypes.OutOfDateRange,
      message: t(
        'DynamicFormField.ValidationMessage.OutOfDateRange',
        'Date is outside of the acceptable range.'
      ),
    },
  ])

  const helperText = useMemo(
    () =>
      setupHelperText({
        validationMessage,
        inputType,
        isValid,
        isEmpty,
        validationMessageMap,
        label,
        maxLength,
        minLength,
        disabled,
      }),
    [
      inputType,
      isValid,
      isEmpty,
      validationMessageMap,
      label,
      validationMessage,
      maxLength,
      minLength,
      disabled,
    ]
  )

  const type = useMemo(() => {
    switch (inputType) {
      case AgreementTemplateFormFieldInputType.Dropdown:
        return 'select'
      case AgreementTemplateFormFieldInputType.Date:
        return 'date'
      default:
        return 'text'
    }
  }, [inputType])

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.value
    const isOverMaxLength = !!maxLength && updatedValue.length > maxLength,
      isUnderMinLength = !!minLength && updatedValue.length < minLength
    updateForParent(
      name,
      event.target.value,
      required,
      isDateField ? false : isOverMaxLength,
      isDateField ? false : isUnderMinLength
    )
  }

  const onSelectChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    const updatedValue = event.target.value as string

    // Get the key as a React Key, stringify it, and remove the .$ for React Keys, then convert to a number
    const option: number = +((child as ReactElement).key
      ?.toString()
      .slice(2) as string)

    const isOverMaxLength = !!maxLength && updatedValue.length > maxLength,
      isUnderMinLength = !!minLength && updatedValue.length < minLength
    updateForParent(
      name,
      updatedValue,
      required,
      isDateField ? false : isOverMaxLength,
      isDateField ? false : isUnderMinLength,
      option
    )
    return
  }

  const isError =
    isValid &&
    (!isValid.maxLength ||
      !isValid.minLength ||
      !isValid.input ||
      !isValid.email)

  const isDisabled = disabled
    ? true
    : isDropdown
    ? !dropdownOptions || isEmpty
    : false

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      variant="filled"
      select={isDropdown && !isEmpty}
      value={value}
      onChange={isDropdown ? () => void {} : onValueChange}
      error={isError}
      helperText={helperText}
      type={type}
      InputLabelProps={type === 'date' ? { shrink: true } : { shrink: shrink }}
      disabled={isDisabled}
      SelectProps={isDropdown ? { onChange: onSelectChange } : {}}
      fullWidth={fullWidth}
    >
      {isDropdown &&
        dropdownOptions?.map((option) => (
          <MenuItem
            key={option.id}
            value={option.name}
            disabled={option.disabled}
          >
            <Typography
              variant="button"
              color={theme.palette.textOrIcon.dropDownOptions}
            >
              {option.name}
            </Typography>
          </MenuItem>
        ))}
    </TextField>
  )
}

export default DynamicFormField
