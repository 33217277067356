import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import TableHeaders from '../Interfaces/TableHeaders'
import TableHeader from '../Table/TableHeader'
import NoResultsFound from '../Table/NoResultsFound'
import UserAvatar from '../Elements/UserAvatar'
import { useAuth } from '../Routes/AuthProvider'
import { Family, Program1 } from '../../swagger'
import TableFooterPagination, {
  defaultPageSize,
} from '../Pagination/TableFooterPagination'
import { Box, Card, useTheme } from '@mui/material'
import { sortFamilyByFamilyLastName } from '../../utils/sortArrays'
import { styled } from '@mui/system'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.primary.main,
}))

interface FamiliesTableProps {
  title: string
  families: Family[]
}

const FamiliesTable: React.FC<FamiliesTableProps> = (props) => {
  const { t } = useTranslation()
  const { title, families } = props
  const theme = useTheme()
  const navigate = useNavigate()
  const { permissionAbility, userDetails } = useAuth()

  //Page of table pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize)

  useEffect(() => {
    /** Reset table pagination to first page when data changes to ensure we're always showing results */
    setPage(0)
  }, [families])

  families.sort(sortFamilyByFamilyLastName)

  const toFamilyDetails = (userId: number) => {
    navigate(
      {
        pathname: `/family-profile/${userId}`,
      },
      {
        /** Navigation Options */
      }
    )
  }

  const tableHeaders: TableHeaders[] = [
    {
      label: '',
      align: 'left',
    },
    {
      label: t('Families.Table.Header.FamilyName', 'Family Name'),
      align: 'left',
    },
    {
      label: t('Families.Table.Header.ContactName', 'Contact Name'),
      align: 'left',
    },
    {
      label: t('Families.Table.Header.Community', 'Community'),
      align: 'left',
    },
    {
      label: t('Families.Table.Header.Program', 'Program(s)'),
      align: 'left',
    },
    {
      label: t('Families.Table.Header.PhoneNumber', 'Phone Number'),
      align: 'left',
    },
    {
      label: t('Families.Table.Header.Email', 'Email'),
      align: 'left',
    },
  ]

  const canViewFamilies = permissionAbility.can('view', 'Family')

  const programsForFamily = (
    programs: Program1[],
    communityKey: number
  ): string => {
    return programs
      .filter((program) => program.communityKey === communityKey)
      .map((program) => program.programName)
      .join(', ')
  }

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      {families.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label={title}>
            <TableHead>
              <TableHeader tableHeaders={tableHeaders} />
            </TableHead>
            <TableBody>
              {families.length > 0 ? (
                families
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                    <TableRow
                      key={index}
                      onClick={
                        canViewFamilies
                          ? () => toFamilyDetails(row.primaryContactId)
                          : () => null
                      }
                      sx={{
                        ...(canViewFamilies && {
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }),
                      }}
                    >
                      <StyledTableCell>
                        <UserAvatar familyName={row.familyLastName} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1" component="p">
                          {row.familyLastName}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1" component="p">
                          {row.primaryContactName}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1" component="p">
                          {row.communityName}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          maxWidth: 250,
                        }}
                      >
                        <Typography variant="body1" component="p">
                          {programsForFamily(row.programs, row.communityKey)}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1" component="p">
                          {row.phoneNumber}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1" component="p">
                          {row.email}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  ))
              ) : (
                <NoResultsFound />
              )}
            </TableBody>
            {families.length > defaultPageSize ? (
              <TableFooterPagination
                count={families.length}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                colSpan={tableHeaders.length}
              />
            ) : null}
          </Table>
        </TableContainer>
      ) : (
        <Card
          component={Paper}
          sx={{
            padding: theme.spacing(3, 4),
            margin: theme.spacing(1.5, 0),
          }}
        >
          <Box textAlign="center">
            <Typography variant="subtitle1" component="p">
              {userDetails.actingAs === 'parent'
                ? t(
                    'Communities.CommunityDetails.Families.NoFamilies.Parent',
                    'This Community currently has no families who have shared their profile.'
                  )
                : t(
                    'Communities.CommunityDetails.Families.NoFamilies.Actor',
                    'This Community currently has no families attached to it.'
                  )}
            </Typography>
            {permissionAbility.can('invite', 'Family') && (
              <Typography variant="subtitle1" component="p">
                {t(
                  'Communities.CommunityDetails.Families.InviteAFamily',
                  'Click or tap on "+ Family" to invite one.'
                )}
              </Typography>
            )}
          </Box>
        </Card>
      )}
    </>
  )
}

export default FamiliesTable
