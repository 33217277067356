import Box from '@mui/material/Box'
import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import { useShowOnDesktop } from '../../hooks/useShowOnDesktop'

interface SpaceBetweenSectionProps extends PropsWithChildren {
  left?: ReactNode
  right?: ReactNode
  center?: ReactNode
  isCentered?: boolean
  marginRight?: number
  marginLeft?: number
  containerStyles?: CSSProperties
}

export const SpaceBetweenSection: React.FC<SpaceBetweenSectionProps> = ({
  left = <></>,
  right = <></>,
  center = <></>,
  isCentered,
  marginRight = 0,
  marginLeft = 0,
  containerStyles,
  children,
}) => {
  const showOnDesktop = useShowOnDesktop()

  const defaultChildren = (
    <>
      <Box ml={!!marginLeft ? marginLeft : 0} aria-label="left-item">
        {left}
      </Box>
      {!!center && <Box aria-label="center-item">{center}</Box>}
      <Box
        aria-label="right-item"
        display="flex"
        flexDirection={showOnDesktop ? 'row' : 'column'}
        mr={!!marginRight ? marginRight : 0}
        ml={!showOnDesktop ? marginLeft : 0}
      >
        {right}
      </Box>
    </>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: isCentered && showOnDesktop ? 'center' : 'initial',
        flexDirection: showOnDesktop ? 'row' : 'column',
        ...containerStyles,
      }}
    >
      {children ? children : defaultChildren}
    </Box>
  )
}

export default SpaceBetweenSection
