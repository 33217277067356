import { Box } from '@mui/material'
import React from 'react'
import UserEmailAddressCard from '../Card/UserEmailAddressCard'
import UserPasswordCard from '../Card/UserPasswordCard'
import UserTwoFactorAuthenticationCard from '../Card/UserTwoFactorAuthenticationCard'
import UserNotifications from '../Card/UserNotifications'
import { UserProfile } from '../../swagger'

interface AccountSettingsTabProps {
  userInfo: UserProfile
}

export const AccountSettingsTab: React.FunctionComponent<
  AccountSettingsTabProps
> = (props) => {
  return (
    <>
      <Box mb={4} mt={4}>
        <UserEmailAddressCard userInfo={props.userInfo} />
      </Box>
      <Box mb={4} mt={4}>
        <UserPasswordCard />
      </Box>
      <Box mb={4} mt={4}>
        <UserTwoFactorAuthenticationCard />
      </Box>
      {/* Hidden User Account Notification Settings Until Implemented wf[975152]*/}
      <Box mb={4} mt={4} hidden={true}>
        <UserNotifications />
      </Box>
    </>
  )
}

export default AccountSettingsTab
