import React, { ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { InviteFlowButtons } from '../Buttons/InviteFlowButtons'
import BrandCard from './BrandCard'
import ErrorAlert from '../Alerts/ErrorAlert'
import PasswordFields, { PasswordFieldsProps } from '../Login/PasswordFields'
import { CircularProgress, InputAdornment, useTheme } from '@mui/material'
import CheckCircle from '@mui/icons-material/CheckCircle'

interface VerificationCodeCardProps {
  error: string
  setEmailVerificationTokenForParent: (token: string) => void
  handleSignUpConfirmVerificationCode: () => void
  isVerificationInputLongEnough: boolean
  /** Provide as InviteFlowButtons onClickBack method to enable going to a previous step */
  goToPrevStep?: () => void
  emailVerificationToken: string
  stepper?: ReactElement
  passwordReset?: boolean
  email?: string
  passwordFieldProps?: PasswordFieldsProps
  isLoading?: boolean
  onClickResendCode?: () => void
  verificationCodeHasBeenResent?: boolean
  resendLoadingId?: string
  primaryLoadingId?: string
}

const heightOfEmailVerificationCard = 500

export const VerificationCodeCard: React.FC<VerificationCodeCardProps> = (
  props
) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    error,
    setEmailVerificationTokenForParent,
    handleSignUpConfirmVerificationCode,
    isVerificationInputLongEnough,
    emailVerificationToken,
    stepper,
    passwordReset = false,
    email = '',
    passwordFieldProps,
    isLoading = false,
    onClickResendCode,
    verificationCodeHasBeenResent,
    resendLoadingId = '',
    primaryLoadingId = '',
    goToPrevStep,
  } = props

  const updateEmailVerificationToken = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailVerificationTokenForParent(event.target.value)
  }

  const pasteHandler = (event: React.ClipboardEvent<HTMLInputElement>) => {
    // Trim pasted text
    setEmailVerificationTokenForParent(
      event.clipboardData.getData('text').trim()
    )
    event.preventDefault()
  }

  const handleVerificationCodeKeyboardEvent = (
    event: React.KeyboardEvent<Element>,
    condition: boolean
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (condition) {
        handleSignUpConfirmVerificationCode()
      }
    }
  }

  const sharedFieldProps = {
    value: emailVerificationToken,
    inputProps: {
      maxLength: 16,
      style: { background: 'none !important' },
    },
    label: t(
      'VerificationCodeCard.Field.SignUpVerification',
      '16 character code'
    ),
    onChange: updateEmailVerificationToken,
    onPaste: pasteHandler,
    onKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => {
      handleVerificationCodeKeyboardEvent(e, isVerificationInputLongEnough)
    },
    id: 'emailVerificationField',
    InputLabelProps: { shrink: true },
  }

  return (
    <>
      <BrandCard
        minimumScreenHeight={heightOfEmailVerificationCard}
        additionalHeaderContent={
          <Box m={theme.spacing(0, 3)}>
            {stepper}
            {verificationCodeHasBeenResent && (
              <Box
                role="alert"
                aria-atomic="true"
                sx={{
                  color: theme.palette.success.main,
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  background: theme.palette.customBackground.greenBackground,
                  cursor: 'default',
                  minHeight: theme.spacing(6),
                }}
              >
                <InputAdornment
                  position="start"
                  sx={{
                    marginInlineStart: '18px',
                  }}
                >
                  <CheckCircle
                    sx={{
                      fill: theme.palette.success.main,
                    }}
                  />
                </InputAdornment>
                <Typography
                  variant="subtitle1"
                  component="h3"
                  justifyContent="flex-start"
                >
                  {t(
                    'FamilyInvite.ResendCode.Success',
                    'Verification code successfully resent to email.'
                  )}
                </Typography>
              </Box>
            )}
            <Typography
              variant="h6"
              component="h2"
              sx={{
                color: theme.palette.primary.main,
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(2),
                display: 'block',
              }}
            >
              {!passwordReset
                ? t(
                    'VerificationCodeCard.Title.SignUpVerification',
                    'Enter the verification code found in your email'
                  )
                : t(
                    'VerificationCodeCard.Title.ResetPasswordVerification',
                    'Copy and paste the code found in your email and enter a new password.'
                  )}
            </Typography>
          </Box>
        }
        cardContent={
          <>
            {passwordReset ? (
              <Typography variant="body1" color={theme.palette.primary.main}>
                {t(
                  'VerificationCodeCard.Body.ResetPasswordVerification.Instructions.Part1',
                  'If you do not see the email sent to'
                )}
                &nbsp;
                {email}
                {t(
                  'VerificationCodeCard.Body.ResetPasswordVerification.CodeWillExpire',
                  ', please check your spam folder. Do not close this window. The code will expire in 10 minutes.'
                )}
              </Typography>
            ) : (
              <Box mt={2}>
                <Typography variant="body1" color={theme.palette.primary.main}>
                  {t(
                    'VerificationCodeCard.Body.SignUpVerification',
                    'Copy and paste the code below.'
                  )}
                </Typography>
              </Box>
            )}
            <Box padding={theme.spacing(3)}>
              {isLoading && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              )}
              {!!error && <ErrorAlert error={error} />}
              {!passwordReset ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="baseline"
                >
                  <Typography
                    variant="subtitle1"
                    marginRight={theme.spacing(1)}
                  >
                    CC -{' '}
                  </Typography>
                  <TextField
                    sx={{
                      background: 'none !important',
                    }}
                    variant="standard"
                    {...sharedFieldProps}
                  />
                </Box>
              ) : (
                <TextField variant="filled" {...sharedFieldProps} fullWidth />
              )}
              {passwordFieldProps && <PasswordFields {...passwordFieldProps} />}
            </Box>
          </>
        }
        cardActions={
          <InviteFlowButtons
            onClickNext={handleSignUpConfirmVerificationCode}
            onClickBack={!!goToPrevStep ? goToPrevStep : undefined}
            isNextDisabled={!isVerificationInputLongEnough}
            onClickResendCode={onClickResendCode}
            resendLoadingId={resendLoadingId}
            primaryLoadingId={primaryLoadingId}
            primaryUseBaseButton={!!primaryLoadingId}
          />
        }
      />
    </>
  )
}

export default VerificationCodeCard
